import { useNavigate } from 'react-router-dom'
import Icon from '~/components/atomic/Icon'
import { useConfirmDialog } from '~/components/dialogs/ConfirmDialog'
import { Dropdown } from '~/components/ui-custom/Dropdown'
import { DropdownMenuItem } from '~/components/ui/dropdown-menu'
import { LandProject } from '~/models'
import { getProjectsPathWithStoredFilters } from '~/routes/projects/projectsFilters'
import { removeProject } from '~/services/db'

export function DeleteProjectItem(props: { landProject: LandProject }) {
  const { landProject } = props
  const navigate = useNavigate()
  const hasDeletePermission = landProject.permissions?.includes('editUser')
  const { setConfirmDialog, closeConfirmDialog } = useConfirmDialog()

  const deleteProject = async () => {
    await removeProject(landProject._id)
    closeConfirmDialog()
    navigate(getProjectsPathWithStoredFilters())
  }

  const onClickDeleteProject = () => {
    if (!hasDeletePermission) return
    setConfirmDialog({
      content: `Are you sure you want to delete ${landProject?.name}?`,
      onConfirm: () => deleteProject(),
      confirmText: 'Delete',
      confirmVariant: 'destructive',
    })
  }

  return (
    <Dropdown.Item
      className={hasDeletePermission && 'text-red-500'}
      onClick={onClickDeleteProject}
      disabled={!hasDeletePermission}
      icon='delete'
    >
      Delete Project
    </Dropdown.Item>
  )
}
