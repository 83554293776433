import Avatar from '~/components/atomic/Avatar'
import { Time } from '~/components/atomic/Time'
import { UpdatedBy } from '~/models/authedUser'

import { Row } from '~/styles'

type Props = {
  updatedBy: UpdatedBy
}

export function UpdatedByInfo(props: Props) {
  const { updatedBy } = props
  return (
    <Row className='row-vcenter gap-x-10'>
      <Avatar name={updatedBy.userFirstName} />
      <span>{updatedBy.userFirstName}</span>
      <Time time={updatedBy.timestamp} relative />
    </Row>
  )
}
