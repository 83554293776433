import { useEffect, useState } from 'react'
import { PageTitle } from '~/styles/main'
import qs from 'qs'
import { fetchDB } from '~/services/db'
import { projectionsToGraphSeries } from '../project/carbon/arr/ARRCarbonView'
import { landProjectStatToProjection, Projection } from '~/models/projection'
import { LandProjectStats } from '~/models/landProjectStats'
import { Serie } from '@nivo/line'
import { MultiProjectionsGraph } from '../project/carbon/arr/components/MultiProjectionsGraph'
import { Pressable } from '~/components/atomic/Pressable'

const getProjectionData = async (): Promise<
  {
    series: Serie[]
    projectName: string
    projectUName: string
  }[]
> => {
  const queryString = qs.stringify({
    models: ['arr_scoping', 'reforestation_literature'].join(','),
  })
  const { landProjectStats } = await fetchDB<{ landProjectStats: LandProjectStats[] }>(
    `landProjectStats?${queryString}`,
    {},
    'GET'
  )

  const landProjectStatsById: Record<string, LandProjectStats[]> = {}
  landProjectStats.forEach((landProject) => {
    const { landProjectId } = landProject
    if (!landProjectStatsById[landProjectId]) {
      landProjectStatsById[landProjectId] = [landProject]
    } else {
      landProjectStatsById[landProjectId].push(landProject)
    }
  })

  const filteredLandProjectStatsById = Object.values(landProjectStatsById).filter(
    (landProject: []) => landProject.length >= 2
  )

  const landProjectIds = filteredLandProjectStatsById.map(
    (landProject) => landProject[0].landProjectId
  )

  const idsQueryString = qs.stringify({
    landProjectIds: landProjectIds.join(','),
  })
  const { landProjects } = await fetchDB(`landProjects?${idsQueryString}`, {}, 'GET')

  const projectionData: {
    projections: Projection[]
    projectName: string
    projectUName: string
  }[] = filteredLandProjectStatsById.map((landProjectStats) => {
    const landProject = landProjects.filter(
      (landProject) => landProject._id === landProjectStats[0].landProjectId
    )[0]
    landProject.xStats = landProjectStats.find(
      (stat) => stat.snapshotMainInterventionKey === 'arr_scoping'
    )

    const projections = landProjectStats.map((landProjectStat) =>
      landProjectStatToProjection(landProjectStat, landProject)
    )

    return {
      projections,
      projectName: landProject.name,
      projectUName: landProject.uName,
    }
  })

  const series = projectionData.map((projection) => ({
    series: projectionsToGraphSeries(projection.projections),
    projectName: projection.projectName,
    projectUName: projection.projectUName,
  }))

  return series
}

const ArrModelsComparisonPage = () => {
  const [data, setData] = useState([])

  useEffect(() => {
    const getData = async () => {
      const data = await getProjectionData()
      setData(data)
    }

    getData()
  }, [])

  return (
    <div className='m-40'>
      <PageTitle className='mx-16'>ARR Models Comparison</PageTitle>
      <div className='flex flex-row flex-between'>
        <div className='flex flex-row mx-16'>
          <div
            className='bg-[#f46d43] mr-8 rounded-md'
            style={{ width: '1.25rem', height: '1.25rem' }}
          ></div>
          Literature Based
        </div>
        <div className='flex flex-row mx-16'>
          <div
            className='bg-[#abdda4] mr-8 rounded-md'
            style={{ width: '1.25rem', height: '1.25rem' }}
          ></div>
          ARR Scoping
        </div>
      </div>
      <div className='flex flex-wrap'>
        {data.map((d) => (
          <div key={`graph-${d.projectUName}`} className='w-1/2'>
            <div className='mx-16 my-32'>
              <div className='flex flex-row justify-between my-8'>
                <div className='text-20 font-bold font-space-grotesk'>
                  {d.projectName}
                </div>
                <Pressable
                  icon='launch'
                  href={`/project/${d.projectUName}/summary`}
                  openInNewTab
                >
                  Project Page
                </Pressable>
              </div>
              <MultiProjectionsGraph series={d.series} className='h-[400px] w-full' />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export { ArrModelsComparisonPage }
