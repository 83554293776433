import { LandProject, ProjectType, getLandProjectArea } from '~/models/landProject'
import { getPredictedCarbonPrice } from '~/data/financials/carbonPricePrediction'
import {
  getProjectCarbonEstimates,
  getProjectFinancialVariables,
} from '~/routes/project/financials/financialsStore'
import {
  VCUS,
  CalculateVCUSParams,
  TypeOfCreditIssuance,
  IssuanceParams,
} from '~/types/vcus'
import { fillArray } from '~/utils'
import { DEFAULT_PROJET_PERIOD } from '~/utils/constants'
import { formatPercent } from '~/utils/units'

export function getDefaultVCUSRates(projectType: ProjectType) {
  return {
    baselineReassessmentRate: projectType === 'redd' ? 0.05 : 0,
    estimatesUncertaintyRate: projectType === 'redd' ? 0.3 : 0.1,
    projectEfficiencyRate: getDefaultProjectEfficiencyRates(
      projectType === 'redd' ? 0.3 : 0
    ),
    leakageDisccountRate: projectType === 'redd' ? 0.15 : 0.14,
    riskBufferRate: projectType === 'redd' ? 0.15 : 0.2,
  }
}

export function calculateLandProjectVCUS(landProject: LandProject): VCUS {
  const { projectType } = landProject

  const {
    startYear,
    carbonPriceScenario,
    riskBufferRate,
    baselineReassessmentRate,
    leakageDisccountRate,
    estimatesUncertaintyRate,
    projectEfficiencyRate,
  } = getProjectFinancialVariables(landProject)

  const firstIssuanceCalendarYear = startYear + 3
  const carbonEstimates = getProjectCarbonEstimates(landProject)

  if (projectType === 'redd') {
    return calculateREDDVCUs({
      projectType,
      carbonPriceScenario,
      firstIssuanceCalendarYear,
      startYear,
      baselineReassessmentRate,
      projectEfficiencyRate,
      carbonEstimates,
      leakageDisccountRate,
      riskBufferRate,
      estimatesUncertaintyRate,
    })
  } else if (projectType === 'arr') {
    return calculateARRVCUs({
      projectType,
      carbonPriceScenario,
      firstIssuanceCalendarYear,
      startYear,
      baselineReassessmentRate,
      carbonEstimates,
      leakageDisccountRate,
      riskBufferRate,
      estimatesUncertaintyRate,
      projectEfficiencyRate,
    })
  }
}

export function calculateREDDVCUs(params: CalculateVCUSParams): VCUS {
  const {
    projectType,
    carbonPriceScenario,
    firstIssuanceCalendarYear,
    startYear,
    baselineReassessmentRate,
    projectEfficiencyRate,
    carbonEstimates,
    leakageDisccountRate,
    riskBufferRate,
    estimatesUncertaintyRate,
  } = params

  const uncertainityOnBaselineRates = getUncertainityOnBaselineRates(
    baselineReassessmentRate
  )

  const calc = carbonEstimates.reduce((prev, baselineEmissions, year) => {
    const calendarYear = year + startYear

    // Baseline - GHG Emissions (tCO2e) -> baselineEmissions

    // Uncertainty on baseline rates
    const uncertainityOnBaselineRatesPerYear = Number(
      formatPercent(uncertainityOnBaselineRates[year], 2)
    )

    // Baseline after Reassessment Uncertainty (TCO2e)
    const baselineAfterUncertainty =
      baselineEmissions * (1 - uncertainityOnBaselineRatesPerYear)

    // Estimates Uncertainty (30%)
    const estimatesUncertainty = baselineAfterUncertainty * estimatesUncertaintyRate

    // Baseline after Uncertainty from Activity Data and Emission Factors (TCO2e)
    const baselineAfterUncertaintyAndEmissions =
      baselineAfterUncertainty - estimatesUncertainty

    // Project Efficiency
    const projectEfficiency = Number(formatPercent(projectEfficiencyRate[year], 2))

    // Project Emissions (tCO2e)
    const projectEmissions =
      baselineAfterUncertaintyAndEmissions * (1 - projectEfficiency)

    // Leakage Discount (tCO2e)
    const leakageDiscount = baselineAfterUncertaintyAndEmissions * leakageDisccountRate

    // Net GHG Emission Reductions (tCO2e)
    const netGHGEmissionReductions =
      baselineAfterUncertaintyAndEmissions - projectEmissions - leakageDiscount

    // Estimated Non-Permanence Risk Buffer Contribution (tCO2e)
    const riskBuffer = netGHGEmissionReductions * riskBufferRate

    // Ex-ante Total GHG Emission Reductions/potential VCUs (tCO2e)
    const annualEmissionReductions = netGHGEmissionReductions - riskBuffer

    // Ex-ante Carbon Projection per year
    let cumulativeEmissionReductions =
      annualEmissionReductions + prev[year - 1]?.cumulativeEmissionReductions ||
      annualEmissionReductions

    // VCUs per vintage (tCO2e)
    const issuanceYear = getTypeOfCreditIssuanceYear({
      currentYear: calendarYear,
      firstIssuanceCalendarYear,
      startYear,
    })

    const issued = calculateIssuance({
      issuanceYear,
      calendarYear,
      startYear,
      prev,
      year,
      annualEmissionReductions,
    })

    // TODO: Are we using or planning on using this?
    const carbonPrice = getPredictedCarbonPrice(
      calendarYear,
      projectType,
      carbonPriceScenario
    )

    const revenue = issued * carbonPrice
    return [
      ...prev,
      {
        year: year + 1,
        yearDate: calendarYear, // TODO rename to calendarYear
        baselineEmissions,
        uncertainityOnBaselineRatesPerYear,
        baselineAfterUncertainty,
        estimatesUncertainty,
        baselineAfterUncertaintyAndEmissions,
        projectEfficiency,
        projectEmissions,
        leakageDiscount,
        netGHGEmissionReductions,
        riskBuffer,
        annualEmissionReductions,
        cumulativeEmissionReductions,
        issued,
        revenue,
      },
    ]
  }, [])

  return calc
}

export function calculateARRVCUs(params: CalculateVCUSParams): VCUS {
  const {
    startYear,
    baselineReassessmentRate,
    carbonEstimates,
    leakageDisccountRate,
    riskBufferRate,
    estimatesUncertaintyRate,
  } = params

  const calc = carbonEstimates.reduce((prev, tCO2, year) => {
    const calendarYear = year + startYear

    // Ex-ante Lower Confidence Interval
    const lowerConfidenceInterval = tCO2['tCO2Min']

    // Ex-ante Upper Confidence Interval
    const upperConfidenceInterval = tCO2['tCO2Max']

    // Ex-ante Cumulative Carbon Projection
    const cumulativeCarbonProjection = tCO2['tCO2']

    // Ex-ante Carbon Projection per year
    let cumulativeCarbonProjectionPerYear =
      cumulativeCarbonProjection - prev[year - 1]?.cumulativeCarbonProjection ||
      cumulativeCarbonProjection

    // Baseline - GHG Emissions (tCO2)
    const baselineEmissions = year === 0 ? baselineReassessmentRate : 0

    // Baseline Uncertainty
    // Using VERRA Standard calculation
    const baselineUncertainty =
      Math.max(
        Math.pow(
          Math.pow(estimatesUncertaintyRate * cumulativeCarbonProjection, 2),
          0.5
        ) *
          (1 / cumulativeCarbonProjection) -
          0.15,
        0
      ) || 0

    // Net GHG Emission Reductions by Year (tCO2e)
    const netGHGEmissionReductions =
      cumulativeCarbonProjectionPerYear *
        (1 - leakageDisccountRate) *
        (1 - baselineUncertainty) -
        baselineEmissions || 0

    // Cumulative Net GHG Emission Reductions (tCO2e)
    let cumulativeNetGHGEmissionReductions =
      netGHGEmissionReductions + prev[year - 1]?.cumulativeNetGHGEmissionReductions ||
      netGHGEmissionReductions

    // Risk Buffer (20%)
    const riskBuffer = cumulativeNetGHGEmissionReductions * riskBufferRate

    // Cumulative Net GHG Emission Reductions (tCO2e) - Buffer
    const cumulativeNetGHGEmissionReductionsBuffer =
      cumulativeNetGHGEmissionReductions - riskBuffer

    return [
      ...prev,
      {
        year: year + 1,
        yearDate: calendarYear, // TODO rename to calendarYear
        lowerConfidenceInterval,
        upperConfidenceInterval,
        cumulativeCarbonProjection,
        cumulativeCarbonProjectionPerYear,
        estimatesUncertaintyRate,
        leakageDisccountRate,
        baselineEmissions,
        baselineUncertainty,
        netGHGEmissionReductions,
        cumulativeNetGHGEmissionReductions,
        riskBuffer,
        cumulativeNetGHGEmissionReductionsBuffer,
      },
    ]
  }, [])

  return calc
}

function getUncertainityOnBaselineRates(baselineReassessmentRate: number) {
  // Baseline rates start increasing by its value on year 6
  const brr = baselineReassessmentRate
  // prettier-ignore
  const uncertainityOnBaselineRates = [
    0, 0, 0, 0, 0, 0,
    brr, brr, brr, brr, brr, brr,
    brr * 2, brr * 2, brr * 2, brr * 2, brr * 2, brr * 2,
    brr * 3, brr * 3, brr * 3, brr * 3, brr * 3, brr * 3,
    brr * 4, brr * 4, brr * 4, brr * 4, brr * 4, brr * 4,
  ]
  return uncertainityOnBaselineRates
}

const getDefaultProjectEfficiencyRates = (projectEfficiencyRate) => {
  // Init rates with default values
  const maxProjectEfficiencyRate = 0.95
  const yearsToMaxProjectEfficiencyRate = 14
  const yearsPerProjectEfficiencyUpdate = 2

  let projectEfficiencyRates = fillArray(30, (_) => maxProjectEfficiencyRate)

  // Init rates with the max Project Efficiency 0.95

  for (let i = 0; i < yearsToMaxProjectEfficiencyRate; i += 2) {
    // Bi-Yearly Update
    if (i % yearsPerProjectEfficiencyUpdate === 0) {
      projectEfficiencyRates[i] = projectEfficiencyRate
      projectEfficiencyRates[i + 1] = projectEfficiencyRate
      projectEfficiencyRate += 0.1
    }
  }

  return projectEfficiencyRates
}

export function getTypeOfCreditIssuanceYear({
  startYear,
  currentYear,
  firstIssuanceCalendarYear,
}: TypeOfCreditIssuance) {
  const yearFromStart = currentYear - startYear

  // The first year of issuance is usually at the year #2
  const isFirsIssuanceYear = currentYear === firstIssuanceCalendarYear

  // Projects issue credits every two years
  const firstIssuanceYear = firstIssuanceCalendarYear - startYear
  const isBiYearlyIssuance = (yearFromStart - firstIssuanceYear) % 2 === 0

  // Exception: Year 28 is added to the last year and not considered a isIssuanceYear.
  const isSkippedYear = yearFromStart === 28
  const isIssuanceYear =
    currentYear > firstIssuanceCalendarYear && isBiYearlyIssuance && !isSkippedYear

  // On the last year, all the remaining credits of the past 3 years are issued.
  const isLastYear = yearFromStart === DEFAULT_PROJET_PERIOD - 1

  if (isLastYear) return 'last-year'
  else if (isFirsIssuanceYear) return 'first-issuance'
  else if (isIssuanceYear) return 'issuance-year'
  else return 'no-issuance'
}

const calculateIssuance = (params: IssuanceParams) => {
  const { issuanceYear, calendarYear, startYear, prev, year, annualEmissionReductions } =
    params
  let issued = 0

  if (issuanceYear === 'first-issuance') {
    // Adds up the first 3 years worth of emission: 0, 1 and 2
    const yearsToGroup = calendarYear - startYear

    for (let i = 0; i < yearsToGroup; i++) {
      issued += prev[i].annualEmissionReductions
    }
    issued += annualEmissionReductions
  } else if (issuanceYear === 'issuance-year') {
    // After the first issuance year it will add up the prior 2 years
    // whenever the year matches the issuance frequency e.g. every two years.
    issued = prev[year - 1].annualEmissionReductions + annualEmissionReductions
  } else if (issuanceYear === 'last-year') {
    // If it's the last year add prior 2 years and current year annualEmissionReductions
    issued += prev[year - 1].annualEmissionReductions
    issued += prev[year - 2].annualEmissionReductions
    issued += annualEmissionReductions
  }

  return issued
}

export const calculateLandProjectVCUStats = (landProject: LandProject, vcus: VCUS) => {
  const projectTypeTotalVcusKey =
    landProject.projectType === 'arr'
      ? 'cumulativeNetGHGEmissionReductionsBuffer'
      : 'cumulativeEmissionReductions'

  const totalVCUs = vcus[vcus.length - 1]?.[projectTypeTotalVcusKey]
  const yearly = totalVCUs / DEFAULT_PROJET_PERIOD

  const polygonArea = getLandProjectArea(landProject)
  const perHaYearly = totalVCUs / polygonArea / DEFAULT_PROJET_PERIOD

  return [
    {
      label: 'Total VCUs over 30 years',
      value: totalVCUs,
    },
    {
      label: 'VCUs per year:',
      value: yearly,
      unitRight: 'tCO2e/year',
    },
    {
      label: 'Annual per hecatare',
      value: perHaYearly,
      unitRight: 'tCO2e/year/ha',
      decimals: 2,
    },
  ]
}
