import { proxy, useSnapshot } from 'valtio'
import { Ecosystem } from '~/models/ecosystem'
import { standardDeviation } from '~/utils'
import baselineData from '~/assets/data/baseline.json'
import {
  LandCover,
  landCovers,
  LandCoversData,
  LandCoverTypeFilters,
  TotalBaselineCarbon,
} from '~/models/landCover'

type BaselineStore = LandCoversData

export const baselineStore = proxy<BaselineStore>({
  degradedForest: {
    area: 0,
    ecosystem: 'savanna',
    continent: '',
    country: '',
    climate: '',
  },
  agricultureGrassland: {
    area: 0,
    ecosystem: 'pasture',
    continent: '',
    country: '',
    climate: '',
  },
})

export function initLandCoverBaselineStore(landCoversData: LandCoversData) {
  if (!landCoversData) return

  landCovers.forEach((landCover) => {
    if (!landCoversData?.[landCover]) return

    baselineStore[landCover] = landCoversData[landCover]
  })
}

export function useBaselineStore() {
  const snap = useSnapshot(baselineStore)
  return snap as BaselineStore
}

export function setFilters(
  entityType: LandCover,
  filters: Partial<LandCoverTypeFilters>
) {
  const newFilters = {
    ...baselineStore[entityType],
    ...filters,
  }
  baselineStore[entityType] = newFilters
}

export function calculateLandCoverCarbonPerHa(applicableData) {
  const midpointKey = 'Midpoint or mean reported tC/ha'
  const midpoints = applicableData?.map((item) => item[midpointKey]) || [0]

  const mean = midpoints.reduce((a, b) => a + b, 0) / midpoints.length
  const stDev = standardDeviation(midpoints)
  const stError = stDev / Math.sqrt(midpoints.length)

  const mean_tCPerHa = mean
  const lower_95Ci_tCPerHa = mean - 1.98 * stError
  const upper_95Ci_tCPerHa = mean + 1.98 * stError

  return {
    mid: {
      mean,
      stDev,
      stError,
    },
    carbonTPerHa: {
      mean: mean_tCPerHa,
      lower_95Ci: lower_95Ci_tCPerHa,
      upper_95Ci: upper_95Ci_tCPerHa,
    },
    tCO2PerHa: {
      mean: (mean_tCPerHa * 44) / 12,
      lower_95Ci: (lower_95Ci_tCPerHa * 44) / 12,
      upper_95Ci: (upper_95Ci_tCPerHa * 44) / 12,
    },
  }
}

export function calculateTotalBaselineCarbon(landCoverData: LandCoversData) {
  let results = {} as TotalBaselineCarbon

  if (!landCoverData) return null

  landCovers.forEach((landCover) => {
    const applicableData = getApplicableDataFromFilters(landCoverData[landCover])
    const landCoverCarbonPerHa = calculateLandCoverCarbonPerHa(applicableData)

    const area = landCoverData[landCover].area
    const baselineCarbon = {
      area,
      mean: landCoverCarbonPerHa.tCO2PerHa.mean * area,
      lower_95Ci: landCoverCarbonPerHa.tCO2PerHa.lower_95Ci * area,
      upper_95Ci: landCoverCarbonPerHa.tCO2PerHa.upper_95Ci * area,
    }

    results[landCover] = baselineCarbon
  })

  return results
}

type FilterParams = {
  ecosystem: Ecosystem
  continent: string
  country: string
  climate: string
}

export function getApplicableDataFromFilters(params: FilterParams) {
  const { ecosystem, continent, country, climate } = params

  const filtered = baselineData.filter((item) => {
    const hasMatch =
      ecosystem === item['Ecosystem type'].toLowerCase() &&
      checkMatch(item, continent, 'Continent') &&
      checkMatch(item, country, 'Country') &&
      checkMatch(item, climate, 'Climate Region')

    return hasMatch
  })

  return filtered
}

export function checkMatch(item: any, value: any, key: string) {
  if (!value) return true
  if (item[key] === value) return true
  return item[key] === value
}
