import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import Input from '~/components/atomic/Input'
import { Pressable } from '~/components/atomic/Pressable'
import { Button } from '~/components/atomic/Button'
import AuthLayout from '~/components/auth/AuthLayout'
import AuthForm from '~/components/auth/AuthForm'
import { setAuthedUser, useAuthedRedirect } from '~/state/auth'
import { AuthedUser } from '~/models/authedUser'
import { fetchDB } from '~/services/db'
import { getFormData } from '~/utils'
import * as analytics from '~/services/analytics'

type OnLoginResponse = {
  valid: number
  user: AuthedUser
  msg: string
}

function LogIn() {
  const navigate = useNavigate()

  const location = useLocation()
  const redirectUrl = (location.state as any)?.redirectUrl || '/projects'

  const [error, setError] = useState('')
  const [submitting, setSubmitting] = useState(false)

  useAuthedRedirect('/projects')

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    const data = getFormData(e.currentTarget)
    logIn(data)
  }

  async function logIn(data) {
    const isValid = data.email && data.password

    if (!isValid) return setError('Please fill out all fields and try again.')

    setSubmitting(true)
    setError('')

    const response: OnLoginResponse = await fetchDB<OnLoginResponse>('login', data)
    onLogin(response)
  }

  function onLogin(data: OnLoginResponse) {
    const { valid, user, msg } = data
    if (valid === 1 && user) {
      if (user._id && user._id.length > 0) {
        setAuthedUser(user)
        analytics.track('Log In')
        navigate(redirectUrl)
      } else {
        setError(msg.length > 0 ? msg : 'Invalid login, please try again')
      }
    } else {
      setError(msg.length > 0 ? msg : 'Invalid fields, please try again')
    }

    setSubmitting(false)
  }

  return (
    <AuthLayout title='Log In'>
      <AuthForm onSubmit={onSubmit}>
        <Input name='email' type='email' label='Email' placeholder='your@email.com' />
        <Input name='password' type='password' label='Password' placeholder='Password' />

        <Button
          disabled={submitting}
          variant='primary'
          type='submit'
          submitting={submitting}
        >
          Log In
        </Button>

        <Pressable to='/forgot-password'>Forgot Password?</Pressable>
        {error && <div className='text-14 font-medium text-red-500'>{error}</div>}

        <Pressable to='/signup' state={{ redirectUrl: redirectUrl }}>
          No account? Sign up.
        </Pressable>
      </AuthForm>
    </AuthLayout>
  )
}

export { LogIn }
