import LogoIcon from '~/components/core/LogoIcon'

import { Row } from '~/styles'

type Props = {
  className?: string
  fill?: string
}

export default function Logo(props: Props) {
  const { className, fill = 'black' } = props

  return (
    <Row className={`row-vcenter shrink-0 ${className}`}>
      <LogoIcon className='w-auto h-32' />
      <div className='ml-8 text-16 font-bold'>LandOS</div>
    </Row>
  )
}
