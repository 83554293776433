import { FillLayer, Layer, LineLayer } from 'react-map-gl'

type Props = {
  variant?: 'satellite' | 'default'
  layout?: any
}

export function CountriesBounds(props: Props) {
  const { variant = 'default', layout } = props

  const layerData: LineLayer = {
    id: 'admin-0-boundary',
    type: 'line',
    source: 'composite',
    'source-layer': 'admin',
    filter: [
      'all',
      ['==', ['get', 'admin_level'], 0],
      ['==', ['get', 'disputed'], 'false'],
      ['==', ['get', 'maritime'], 'false'],
      ['match', ['get', 'worldview'], ['all', 'US'], true, false],
    ],
    layout: { 'line-cap': 'round', ...layout },
    paint: paints[variant],
  }

  return <Layer {...layerData} />
}

const paints: any = {
  satellite: {
    'line-color': '#d4d2c4',
    'line-width': ['interpolate', ['linear'], ['zoom'], 3, 0.5, 12, 0.75],
    'line-dasharray': [10, 0],
    'line-opacity': ['interpolate', ['linear'], ['zoom'], 3, 1, 12, 0.5],
  },
  default: {
    'line-color': 'hsla(76, 57%, 13%, 0.41)',
    'line-dasharray': [10, 0],
    'line-width': 0.5,
  },
}

export function MaskedCountryFillLayer(props: { code3: string; color?: string }) {
  const { code3, color = 'hsla(0, 0%, 100%, 0.5)' } = props

  const layerData: FillLayer = {
    id: 'country-mask',
    type: 'fill',
    source: 'countryBoundaries',
    'source-layer': 'country_boundaries',
    filter: [
      'all',
      ['==', ['get', 'disputed'], 'false'],
      ['any', ['==', 'all', ['get', 'worldview']], ['in', 'US', ['get', 'worldview']]],
      ['match', ['get', 'iso_3166_1_alpha_3'], [code3], false, true],
    ],
    paint: { 'fill-color': color },
  }

  return <Layer {...layerData} />
}
