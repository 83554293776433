import { ErrorMessage } from '~/components/atomic/ErrorMessage'
import { Spreadsheet } from '~/components/core/Spreadsheet'
import {
  updateStoreVariable,
  useFinancialsStore,
} from '~/routes/project/financials/financialsStore'
import { useActiveLandProject } from '~/state'
import { Column, Row } from '~/styles'
import { Section } from '~/styles/main'
import { fillArray, isNumber } from '~/utils'
import { formatNumber, parseNumber } from '~/utils/units'
import { getLandProjectCarbonEstimates } from '~/models/landProject'
import { DEFAULT_PROJET_PERIOD } from '~/utils/constants'

export function CarbonEstimatesOverride() {
  const { landProject } = useActiveLandProject()
  const scopedCarbonEstimates = getLandProjectCarbonEstimates(landProject)

  const { variables } = useFinancialsStore()
  const { startYear, carbonEstimates } = variables

  const parsedCarbonEstimates = carbonEstimates
    ? carbonEstimates.slice(0, DEFAULT_PROJET_PERIOD)
    : fillArray(30, () => 0)

  const data = parsedCarbonEstimates.map((d, i) => [
    { value: i, readOnly: true, className: 'text-center' },
    { value: i + startYear, readOnly: true, className: 'text-center' },
    {
      value: formatNumber(scopedCarbonEstimates?.[i] || 0),
      readOnly: true,
      className: 'text-number text-right',
    },
    { value: formatNumber(d), className: 'text-number text-right' },
  ])

  const isValid = data.length === 30

  const columnLabels = ['Year #', 'Year Date', 'Automatic', 'Manual']

  function onChange(newData) {
    const newBaselineGHGEmissions = parseTableToArray(newData)
    if (JSON.stringify(carbonEstimates) != JSON.stringify(newBaselineGHGEmissions)) {
      updateStoreVariable(newBaselineGHGEmissions, 'carbonEstimates')
    }
  }

  return (
    <Column>
      <Row className='row-vcenter mb-20 space-x-20'>
        <Section.Subtitle className='mb-0'>Carbon Estimates Override</Section.Subtitle>

        <ErrorMessage
          error={!isValid && 'Invalid values. There should be exactly 30 values'}
        />
      </Row>

      <Row>
        <Spreadsheet
          columnLabels={columnLabels}
          data={data}
          hideRowIndicators={true}
          onChange={onChange}
        />
      </Row>
    </Column>
  )
}

function parseTableToArray(data) {
  return data.map((row) => {
    const scopedEstimate = parseNumber(row[2]?.value)
    const overridenEstimate = parseNumber(row[3]?.value)
    return isNumber(overridenEstimate) ? overridenEstimate : scopedEstimate
  })
}
