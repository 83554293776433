import { Layer } from 'react-map-gl'

type Props = {
  fill?: string
}

export function WaterLayer(props: Props) {
  const { fill = '#FDF9F4' } = props

  return (
    <Layer
      id='water'
      type='fill'
      source='composite'
      source-layer='water'
      paint={{ 'fill-color': fill }}
    />
  )
}
