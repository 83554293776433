export const graphTheme = {
  axis: {
    ticks: {
      line: {
        opacity: 0,
      },
      text: {
        fontFamily: 'Inter',
        fontSize: 11,
        opacity: 0.75,
      },
    },
  },
  grid: {
    line: {
      strokeWidth: 1,
      strokeDasharray: '4 4',
    },
  },
}
