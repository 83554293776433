import { Column } from '~/styles'

import {
  updateStoreVariable,
  useFinancialsStore,
} from '~/routes/project/financials/financialsStore'
import { ErrorMessage } from '~/components/atomic/ErrorMessage'
import { Spreadsheet } from '~/components/core/Spreadsheet'
import { sumArray } from '~/utils'
import { DEFAULT_PROJET_PERIOD } from '~/utils/constants'

export function InvestmentDistributions() {
  const { variables } = useFinancialsStore()
  const investmentDistributions = variables.investmentDistributions

  const data = investmentDistributions.slice(0, DEFAULT_PROJET_PERIOD).map((d, i) => [
    { value: variables.startYear + i, readOnly: true, className: 'text-center' },
    { value: d * 100, unit: '%', className: 'text-right' },
  ])

  const isValid = getIsValid(data)
  const columnLabels = ['Year #', 'Investment %']

  function onChange(newData) {
    const newInvestmentDistributions = parseTableToArray(newData)
    if (
      JSON.stringify(investmentDistributions) !=
      JSON.stringify(newInvestmentDistributions)
    ) {
      updateStoreVariable(newInvestmentDistributions, 'investmentDistributions')
    }
  }

  return (
    <Column>
      <div className='font-bold mb-8'>Investment Distribution</div>

      <Spreadsheet
        columnLabels={columnLabels}
        data={data}
        hideRowIndicators={true}
        onChange={onChange}
      />
      <ErrorMessage
        error={!isValid && 'Invalid values. Each value should be betwen 0 and 100'}
      />
      <span className='mt-8 text-13 text-ink-500 text-right'>
        {sumArray(investmentDistributions) * 100}%
      </span>
    </Column>
  )
}

function getIsValid(data) {
  return parseTableToArray(data).every((v) => (v < 0 || v > 100 ? false : true))
}

function parseTableToArray(data) {
  return data.map((row) => Number(row[1].value / 100))
}
