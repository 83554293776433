import React from 'react'
import { Numerical } from '~/components/core/Numerical'

import { isNumber } from '~/utils'
import { cn } from '~/utils/cn'

type Props = {
  headers?: string[]
  rows: any[]
  className?: string
  classes?: {
    headTr?: string
    headTh?: string
    bodyTr?: string
    bodyTd?: string
  }
}

const padding = 'py-4 px-8'

export function StaticTable(props: Props) {
  const { headers, rows, className, classes } = props
  const { headTr, headTh, bodyTr, bodyTd } = classes || {}

  return (
    <table className={cn('border-separate border-spacing-0  rounded-md', className)}>
      <thead className='group'>
        <tr className={cn('bg-ink-100', headTr)}>
          {headers?.map((header, i) => {
            const alignment = isNumber(rows?.[0][i]) ? 'text-right' : 'text-left'
            return (
              <th
                key={`th-${header}-${i}`}
                className={cn(
                  padding,
                  alignment,
                  'font-medium',
                  'border-l border-b border-t last-of-type:border-r border-ink-200',
                  'first-of-type:rounded-tl-md last-of-type:rounded-tr-md ',
                  headTh
                )}
              >
                {header}
              </th>
            )
          })}
        </tr>
      </thead>
      <tbody className=''>
        {rows.map((row, rowIndex) => (
          <tr key={`tr-${row}-${rowIndex}`} className={cn('group', bodyTr)}>
            {row.map((cell, cellIndex) => {
              const alignment = isNumber(row[cellIndex]) ? 'text-right' : 'text-left'
              const isHeadingRow = cellIndex === 0 && !isNumber(cell)

              return (
                <td
                  key={`td-${cell}-${cellIndex}`} // TODO use a better key
                  className={cn(
                    padding,
                    alignment,
                    isHeadingRow && 'bg-ink-100',
                    'whitespace-nowrap text-right px-8',
                    'group-last-of-type:first:rounded-bl-md group-last-of-type:last:rounded-br-md ',
                    'border-l border-b last-of-type:border-r border-ink-200',
                    bodyTd
                  )}
                >
                  {isNumber(cell) ? (
                    <Numerical value={cell} decimals={2} />
                  ) : isHeadingRow ? (
                    <span className='font-medium'>{cell}</span>
                  ) : (
                    cell
                  )}
                </td>
              )
            })}
          </tr>
        ))}
      </tbody>
    </table>
  )
}
