import { useState, useEffect } from 'react'

import ESSocket from '../../utils/ESSocket'
import { ToFixed } from '~/services/lodash'
import { Button } from '~/components/atomic/Button'
import Select from '~/components/atomic/Select'
import { Content } from '~/styles/main'
import { Column } from '~/styles'
import { ResponsiveLine } from '@nivo/line'
import MapPolygons from '~/components/map/MapPolygons'
import { UploadPolygonButton } from '~/components/forms/UploadPolygonButton'

const _routeIds = []

function ParcelAnalyze() {
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [parcelsAnalysis, setParcelsAnalysis] = useState([])
  const [formVals, setFormVals] = useState({
    analysisTypes: ['forestNonForestImage'],
    fireBreaks: 1,
    fileData: null,
    fileType: '',
    autoGenerateParcelsGeojson: 1,
    nonForestThreshold: 75,
    nonForestMinAreaMeters: 1000,
    nonForestMaxMetersPerPixel: 5,
    nonForestMaxTiles: 100,
    parcels: [],
  })
  let parcelsAnaylzedCount = 0

  useEffect(() => {
    // _routeIds['parcelsAnalyze'] = ESSocket.onRoute('parcelsAnalyze', (res) => {
    _routeIds['onParcelAnalyze'] = ESSocket.onRoute('onParcelAnalyze', (res) => {
      const data = res['data']
      if (res.data.valid === 1) {
        // setMessage(JSON.stringify(data))
        if (data.metaInfo?.bulkTotal) {
          parcelsAnaylzedCount += 1
          setMessage(
            `${parcelsAnaylzedCount} of ${data.metaInfo.bulkTotal} parcels analyzed..`
          )
          if (parcelsAnaylzedCount >= data.metaInfo.bulkTotal) {
            setLoading(false)
          }
        }
        setParcelsAnalysis((prevArray) => [...prevArray, { data: data }])
      } else {
        setMessage('error')
      }
    })

    _routeIds['generateGeojson'] = ESSocket.onRoute('generateGeojson', (res) => {
      const data = res['data']
      console.log(data)
      // TODO
    })

    return function cleanup() {
      ESSocket.offRouteIds(_routeIds)
    }
  }, [])

  // const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   // const file = e.currentTarget.elements['geoData'].files[0]
  //   const file = e.currentTarget.files[0]
  //   let pos = file.name.lastIndexOf('.')
  //   let extension = file.name.slice(pos + 1, file.name.length)
  //   var reader = new FileReader()
  //   var fileByteArray = []
  //   reader.readAsArrayBuffer(file)
  //   reader.onloadend = function (evt) {
  //     if (evt.target.readyState == FileReader.DONE) {
  //       let arrayBuffer: any = evt.target.result
  //       let array = new Uint8Array(arrayBuffer)
  //       for (var i = 0; i < array.length; i++) {
  //         fileByteArray.push(array[i])
  //       }
  //       formVals.fileData = fileByteArray
  //       formVals.fileType = extension
  //     }
  //   }
  // }

  function onUploadCoordinates(parcels) {
    setFormVals((formVals) => {
      formVals.parcels = parcels
      return formVals
    })
  }

  // function DownloadParcels(parcels) {
  //   const data = {
  //     parcels: parcels,
  //   }
  //   ESSocket.emit('generateGeojson', data)
  // }

  function Analyze(evt) {
    evt.preventDefault()
    console.log('data', formVals)
    if (formVals.parcels.length > 0) {
      setMessage('Analyzing, this may take a minute or more..')
      setLoading(true)
      setParcelsAnalysis([])
      parcelsAnaylzedCount = 0
      ESSocket.emit('parcelsAnalyze', formVals)
    } else {
      setMessage('At least 1 parcel is required')
    }
  }

  function ParcelsAnalysis(props) {
    const parcelsInfos = parcelsAnalysis.map((info, index) => {
      if (!info.data) {
        return null
      }
      const data = info.data
      if (!data.valid) {
        return null
      }
      const polygons = data.forestNonForestImage?.polygonsLngLatsNonForest || null
      const bounds = data.forestNonForestImage?.bounds || null
      return (
        <div key={index}>
          <div>Total area: {ToFixed(data.areaHa)} Ha</div>
          {data.forestNonForestImage && (
            <div>
              <div>
                Non-Forest: {ToFixed(data.forestNonForestImage.nonForestHectares)} Ha (
                {ToFixed(data.forestNonForestImage.nonForestPercent)}%) at{' '}
                {ToFixed(data.forestNonForestImage.metersPerPixel)} meters per pixel
                {data.forestNonForestImage.polygonsNonForest?.fileUrl && (
                  <span>
                    &nbsp;
                    <a
                      href={data.forestNonForestImage.polygonsNonForest.fileUrl}
                      download
                    >
                      Download Intervention Zones
                    </a>
                  </span>
                )}
              </div>
            </div>
          )}
          {data.forestNonForest && (
            <div>
              <div>
                Forest: {ToFixed(data.forestNonForest.forestHectares)} Ha (
                {ToFixed(data.forestNonForest.forestPercent)}%)
                {data.forestNonForest.polygonsForest?.fileUrl && (
                  <span>
                    &nbsp;
                    <a href={data.forestNonForest.polygonsForest.fileUrl} download>
                      Download Parcels
                    </a>
                  </span>
                )}
              </div>
              <div>
                Non-Forest (reforestable):{' '}
                {ToFixed(data.forestNonForest.anthropicHectares)} Ha (
                {ToFixed(data.forestNonForest.anthropicPercent)}%)
                {data.forestNonForest.polygonsAnthropic?.fileUrl && (
                  <span>
                    &nbsp;
                    <a href={data.forestNonForest.polygonsAnthropic.fileUrl} download>
                      Download Parcels
                    </a>
                  </span>
                )}
              </div>
            </div>
          )}
          {data.carbonProjections?.CO2Years && data.carbonProjections?.valid && (
            <div>
              <div>Carbon Projections</div>
              <GraphCO2Years CO2Years={data.carbonProjections.CO2Years} />
            </div>
          )}
          {data.fireRisk?.tCO2Fire && data.fireRisk?.valid && (
            <div>
              <div>Fire Risk (Carbon Projections after fire)</div>
              <GraphCO2Fire tCO2Fire={data.fireRisk.tCO2Fire} />
            </div>
          )}
          {polygons && (
            <div style={{ height: 400 }}>
              <MapPolygons polygons={polygons} bounds={bounds} />
            </div>
          )}
        </div>
      )
    })
    return <div>{parcelsInfos}</div>
  }

  function GraphCO2Years(props) {
    const data = props.CO2Years.map((d, index) => ({
      x: d.year || index + 1,
      y: d.tCO2 || 0,
    }))
    const series = [
      {
        id: 'tons',
        color: '#488877',
        data: data,
      },
    ]
    return (
      <div className='h-[400px] min-h-[400px] w-full'>
        <ResponsiveLine
          curve='catmullRom'
          data={series}
          colors={['#488877']}
          margin={{ top: 12, right: 20, bottom: 60, left: 60 }}
          xScale={{ type: 'point' }}
          yScale={{
            type: 'linear',
            min: 'auto',
            max: 'auto',
            stacked: true,
            reverse: false,
          }}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'years',
            legendOffset: 36,
            legendPosition: 'middle',
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'tco2',
            legendOffset: -40,
            legendPosition: 'middle',
          }}
          useMesh={true}
          isInteractive={true}
          pointLabelYOffset={-12}
        />
      </div>
    )
  }

  function GraphCO2Fire(props) {
    const data = props.tCO2Fire.map((d, index) => ({
      x: index + 1,
      y: d || 0,
    }))
    const series = [
      {
        id: 'tons',
        color: '#488877',
        data: data,
      },
    ]
    return (
      <div className='h-[400px] min-h-[400px] w-full'>
        <ResponsiveLine
          curve='catmullRom'
          data={series}
          colors={['#488877']}
          margin={{ top: 12, right: 20, bottom: 60, left: 60 }}
          xScale={{ type: 'point' }}
          yScale={{
            type: 'linear',
            min: 'auto',
            max: 'auto',
            stacked: true,
            reverse: false,
          }}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'years',
            legendOffset: 36,
            legendPosition: 'middle',
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'tco2',
            legendOffset: -40,
            legendPosition: 'middle',
          }}
          useMesh={true}
          isInteractive={true}
          pointLabelYOffset={-12}
        />
      </div>
    )
  }

  function onChangeFormVals(evt) {
    let value = evt.target.value
    if (evt.target.name === 'analysisTypes') {
      value = []
      for (let ii = 0; ii < evt.target.options.length; ii++) {
        const opt = evt.target.options[ii]
        if (opt.selected) {
          value.push(opt.value || opt.text)
        }
      }
    }
    if (evt.target.name === 'nonForestThreshold') {
      value = parseInt(value)
      if (value < 25) {
        value = 25
      }
      if (value > 150) {
        value = 150
      }
    }
    if (evt.target.name === 'nonForestMinAreaMeters') {
      value = parseInt(value)
      if (value < 10) {
        value = 10
      }
    }
    if (evt.target.name === 'nonForestMaxMetersPerPixel') {
      value = parseInt(value)
      if (value < 1) {
        value = 1
      }
    }
    if (evt.target.name === 'nonForestMaxTiles') {
      value = parseInt(value)
      if (value < 1) {
        value = 1
      }
    }
    setFormVals((formVals) => {
      formVals[evt.target.name] = value
      return formVals
    })
  }

  const optsAnalysisTypes = [
    { value: 'forestNonForestImage', label: 'forestNonForestImage' },
    { value: 'fireRisk', label: 'fireRisk' },
    { value: 'carbonProjections', label: 'carbonProjections' },
    { value: 'forestNonForest', label: 'forestNonForest' },
    { value: 'subdivide', label: 'subdivide' },
  ]
  const optsFireBreaks = [
    { value: 1, label: 'Fire Breaks' },
    { value: 0, label: 'No Fire Breaks' },
  ]
  return (
    <Content>
      <Column>
        <div className='mb-20 text-18 font-bold'>Upload parcel(s) to analyze</div>
        <UploadPolygonButton onUploaded={onUploadCoordinates} />
        {/* <input
          type="file"
          name="geoData"
          className="text-14 mb-16"
          accept=".kmz, .geojson, .kml"
          onChange={onChangeFile}
        /> */}
        Non Forest Threshold:{' '}
        <input
          type='number'
          name='nonForestThreshold'
          defaultValue={formVals.nonForestThreshold}
          onChange={onChangeFormVals}
        />
        Non Forest Min Area Meters:{' '}
        <input
          type='number'
          name='nonForestMinAreaMeters'
          defaultValue={formVals.nonForestMinAreaMeters}
          onChange={onChangeFormVals}
        />
        Non Forest Max Meters Per Pixel:{' '}
        <input
          type='number'
          name='nonForestMaxMetersPerPixel'
          defaultValue={formVals.nonForestMaxMetersPerPixel}
          onChange={onChangeFormVals}
        />
        Non Forest Max Tiles:{' '}
        <input
          type='number'
          name='nonForestMaxTiles'
          defaultValue={formVals.nonForestMaxTiles}
          onChange={onChangeFormVals}
        />
        <Select
          name='analysisTypes'
          defaultValue={formVals.analysisTypes}
          options={optsAnalysisTypes}
          multiple
          onChange={onChangeFormVals}
          style={{
            height: Math.max(36, 20 + optsAnalysisTypes.length * 18),
          }}
        />
        {/* <Select name='fireBreaks' defaultValue={formVals.fireBreaks} options={optsFireBreaks}
          onChange={onChangeFormVals}
          style={{
            height: 36,
          }}
        /> */}
        <Button
          disabled={loading}
          variant='primary'
          onClick={Analyze}
          submitting={loading}
        >
          Analyze
        </Button>
      </Column>
      {message && <div>{message}</div>}
      <div>
        {' '}
        <ParcelsAnalysis />
      </div>
    </Content>
  )
}

export { ParcelAnalyze }
