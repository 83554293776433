import { useEffect, useRef, useState } from 'react'
import { BaseMap } from '~/components/map/BaseMap'
import { MaskedCountryFillLayer } from '~/components/map/layers/CountriesBounds'
import { MapSources } from '~/components/map/layers/MapSources'
import {
  LandProjectSchedule,
  calculateCarbonStats,
  getLandProjectCountry,
  getLandProjectPolygonArea,
} from '~/models/landProject'
import { SlideShow } from '~/routes/project/reports/SlideShow'
import { fitMap, landProjectParcelsToMapLayer, useMapLayers } from '~/services/mapbox'
import { useActiveLandProject } from '~/state'

import * as turf from '@turf/turf'
import { MapRef } from 'react-map-gl'
import { Numerical } from '~/components/core/Numerical'
import { GradientLegend, MapLegend } from '~/components/map/MapControls'
import { RasterLayers } from '~/components/map/layers/RasterLayers'
import { WaterLayer } from '~/components/map/layers/WaterLayer'
import { ProjectCarbonProjection } from '~/components/profile/ProjectCarbonProjection'
import { H2, Title } from '~/components/profile/shared'
import { calculateLandProjectVCUStats } from '~/data/financials/vcus'
import { Dataset, datasets } from '~/models/dataset'
import { EligibilityStats, fetchEligibilityStats } from '~/models/landProjectArrStats'
import { landProjectStatToProjection } from '~/models/projection'
import { useInitArrCarbonStore } from '~/routes/project/carbon/arr/arrCarbonStore'
import {
  useFinancialsStore,
  useInitFinancialStore,
} from '~/routes/project/financials/financialsStore'
import {
  fetchAndStoreRasterMapLayers,
  showOnlyLayersArray,
  useLandStore,
} from '~/routes/project/land/landStore'
import { ProjectPolygonAreaLayers } from '~/routes/project/reports/components/ProjectPolygonAreaLayers'
import { Slide } from '~/routes/project/reports/components/Slide'
import { Citation } from '~/routes/project/reports/components/shared'
import { getCountryCode3ByName, getCountryGeometryByName } from '~/services/countries'
import { Column, Row } from '~/styles'
import { Divider, Section } from '~/styles/main'
import { snakeCaseToLabel } from '~/utils'
import { defaultBackgroundImage } from '~/utils/constants'
import { getFeatureCollectionFromParcels } from '~/utils/geo'
import { getDiscounts } from '../vcus/components/DiscountsSection'
import { VCUsARRTable } from '../vcus/components/VCUsARRTable'

export function ARRSlideShowReport() {
  const { landProject, parcels } = useActiveLandProject()
  const countryName = getLandProjectCountry(landProject)
  const polygonArea = getLandProjectPolygonArea(landProject)
  const countryGeometry = getCountryGeometryByName(countryName)
  const projetPolygonFeatureCollection = getFeatureCollectionFromParcels(parcels)

  const schedule = landProject.schedule
  const scheduleArray = getScheduleArray(schedule)

  const buffered = turf.buffer(projetPolygonFeatureCollection, 20, { units: 'miles' })
  const bufferedBBox = turf.bbox(buffered)

  const chosenProjection = landProjectStatToProjection(
    {
      ...landProject.xStats,
      snapshotMainInterventionKey: landProject.chosenModelKey,
    },
    landProject
  )

  const [eligibilityStats, setEligibilityStats] = useState<EligibilityStats>(null)

  useEffect(() => {
    const fetchData = async () => {
      const fetchedEligibilityStats = await fetchEligibilityStats(landProject['_id'])
      setEligibilityStats(fetchedEligibilityStats)

      const landCoverDataset =
        fetchedEligibilityStats?.computeModelInfo?.dataset || 'worldcover_dynamicworld'

      const currentDateRange = (datasets[landCoverDataset] as Dataset)
        .currentDateRange as any
      const priorDateRange = (datasets[landCoverDataset] as Dataset).priorDateRange as any

      fetchAndStoreRasterMapLayers([
        { datasetId: 'potential_biomass' },
        {
          datasetId: landCoverDataset,
          layerId: `${landCoverDataset}_2015`,
          fetchParams: {
            urlParams: `?dateRange=${priorDateRange}`,
          },
        },
        {
          datasetId: landCoverDataset,
          layerId: `${landCoverDataset}_2023`,
          fetchParams: {
            urlParams: `?dateRange=${currentDateRange}`,
          },
        },
      ])
    }

    fetchData()
  }, [landProject])

  function onShowPaddedFit(map, layers) {
    fitMap(map, buffered, {
      padding: { left: 400, right: 140, bottom: 140, top: 140 },
    })
  }

  const landCoverDatasetId =
    eligibilityStats?.computeModelInfo?.dataset || 'worldcover_dynamicworld'

  const landCover10YearPriorRasterId = landCoverDatasetId + '_2015'
  const landCoverCurrentRasterId = landCoverDatasetId + '_2023'

  const slides = [
    {
      slide: MapSlide,
      slideOverlay: Cover,
      onShow: (map) => {
        fitMap(map, countryGeometry, { padding: 200, duration: 0 })
        setTimeout(() => {
          fitMap(map, countryGeometry)
        }, 20)
      },
    },
    {
      slide: MapSlide,
      title: `Global context`,
      content: <div>Country: {countryName}</div>,
      onShow: (map) => {
        fitMap(map, countryGeometry)
      },
    },
    {
      slide: MapSlide,
      title: 'Project Area',
      content: (
        <div>
          <Row>
            <span className='font-bold mr-8'>Project Polygon area:</span>{' '}
            <Numerical value={polygonArea} type='area' />
          </Row>
        </div>
      ),
      onShow: (map, layers) => {
        fitMap(map, projetPolygonFeatureCollection, { padding: 100 })
      },
    },

    {
      slide: MapSlide,
      title: 'Concessions',
      layerId: ['gfw_mining_concessions', 'gfw_managed_forests', 'gfw_oil_palm'],
      onShow: onShowPaddedFit,
    },

    {
      slide: MapSlide,
      title: 'Protected areas',
      layerId: 'wdpa_protected_areas',
      onShow: onShowPaddedFit,
    },
    {
      slide: MapSlide,
      title: 'Carbon storage potential',
      layerId: 'potential_biomass',
      onShow: (map, layers) => {
        fitMap(map, projetPolygonFeatureCollection, { padding: 100, maxZoom: 10 })
      },
      layers: {
        projectAreaLine: { paint: { 'line-color': 'black', 'line-width': 3 } },
        projectArea: { paint: { 'fill-color': 'transparent' } },
      },
    },
    {
      slide: MapSlide,
      title: 'Land Cover Cover 10 year prior',
      layerId: landCover10YearPriorRasterId,
      interactive: true,
      maxBounds: bufferedBBox,
      content: (
        <Citation className='mt-12'>
          *dataset: {snakeCaseToLabel(landCover10YearPriorRasterId)}
        </Citation>
      ),
      layers: {
        projectAreaLine: { paint: { 'line-color': 'black', 'line-width': 3 } },
        projectArea: { paint: { 'fill-color': 'transparent' } },
      },
      onShow: (map, layers) => {
        fitMap(map, projetPolygonFeatureCollection, { padding: 100 })
      },
    },
    {
      slide: MapSlide,
      title: 'Land Cover Cover 2023',
      layerId: landCoverCurrentRasterId,
      interactive: true,
      maxBounds: bufferedBBox,
      content: (
        <Citation className='mt-12'>
          *dataset: {snakeCaseToLabel(landCoverCurrentRasterId)}
        </Citation>
      ),
      layers: {
        projectAreaLine: { paint: { 'line-color': 'black', 'line-width': 3 } },
        projectArea: { paint: { 'fill-color': 'transparent' } },
      },
      onShow: (map, layers) => {
        fitMap(map, projetPolygonFeatureCollection, { padding: 100 })
      },
    },
    // eligibilityStats
    //   ? {
    //       slide: MapSlide,
    //       title: '10 year land eligibility',
    //       layerId: landCover10YearPriorRasterId,
    //       maxBounds: bufferedBBox,
    //       layers: {
    //         projectAreaLine: { paint: { 'line-color': 'black', 'line-width': 1 } },
    //       },
    //       interactive: true,
    //       content: (
    //         <div>
    //           {eligibilityStats && (
    //             <Column className=''>
    //               <Row className='text-15'>
    //                 <span className='font-semibold mr-8'>Eligible Area:</span>
    //                 <Numerical
    //                   type='area'
    //                   value={
    //                     eligibilityStats?.reforestableAreaResults?.reforestableArea
    //                       ?.reforestableHa
    //                   }
    //                 />
    //                 <span className='ml-4'>(</span>
    //                 <Numerical
    //                   type='percent'
    //                   value={
    //                     eligibilityStats?.reforestableAreaResults?.reforestableArea
    //                       ?.reforestablePercent / 100
    //                   }
    //                 />
    //                 )
    //               </Row>
    //               <p className='mt-8'>Overlap with green areas indicate ineligibility</p>

    //               <Citation className='mt-12'>
    //                 *dataset:{' '}
    //                 {snakeCaseToLabel(eligibilityStats?.computeModelInfo?.dataset)}
    //               </Citation>
    //             </Column>
    //           )}

    //           <InterventionSchedule scheduleArray={scheduleArray} />
    //         </div>
    //       ),
    //       onShow: (map: Map, layers) => {
    //         fitMap(map as any, projetPolygonFeatureCollection, { padding: 100 })
    //       },
    //     }
    //   : null,

    {
      slide: MapSlide,
      slideOverlay: CarbonCurves,
    },
    {
      slide: MapSlide,
      slideOverlay: VCUsSlide,
    },
    {
      slide: MapSlide,
      slideOverlay: VCUsTableSlide,
    },
  ].filter((slide) => slide !== null)

  // const hasRecommendations = landProject.data?.['scoping-recommendations']?.value

  // if (hasRecommendations) {
  //   slides.push({ slide: MapSlide, slideOverlay: RecommendationsSlide })
  // }

  return <SlideShow initialSlide={0} slides={slides} />
}

function Cover() {
  const { landProject } = useActiveLandProject()
  return (
    <div className='w-full h-full center'>
      <img
        alt='Background'
        className='absolute inset-0 h-full w-full bg-green-100 object-cover'
        src={defaultBackgroundImage}
      />
      <Column className='relative center z-10'>
        <div className='font-space-grotesk text-[72px] font-bold text-white leading-none'>
          {landProject.name}
        </div>
        <div className='font-space-grotesk text-40 font-bold text-white mt-60'>
          ARR Scoping Report
        </div>
      </Column>
    </div>
  )
}

function MapSlide(props) {
  const { currentSlide, slidesData } = props
  const mapRef = useRef<MapRef>()
  const { landProject, parcels } = useActiveLandProject()

  const landStoreSnap = useLandStore()

  const countryName = getLandProjectCountry(landProject)
  const countryCode3 = getCountryCode3ByName(countryName)

  const currentSlideData = slidesData[currentSlide]
  const SlideOverlay = currentSlideData?.slideOverlay

  const initialLayers = []
  if (parcels?.length)
    initialLayers.push({ ...landProjectParcelsToMapLayer(parcels), theme: 'white' })

  const { layers } = useMapLayers(initialLayers)

  const currentLayerIds =
    typeof currentSlideData?.layerId === 'string'
      ? [currentSlideData?.layerId]
      : currentSlideData?.layerId

  console.log('currentLayerIds: ', currentLayerIds)
  const activeLayers = landStoreSnap.mapLayers.filter((i) =>
    currentLayerIds?.includes(i.id)
  )
  console.log('activeLayers: ', activeLayers)

  const countryGeometry = getCountryGeometryByName(countryName)
  const center = turf.centroid(countryGeometry.geometry)
  const coordinates = center.geometry.coordinates

  const initialViewState = {
    latitude: coordinates[1],
    zoom: currentSlide === 1 ? 4 : 8,
  }

  useEffect(() => {
    // Layer Visibility Controls

    function func(map) {
      // Alwayas resize map on slide change
      showOnlyLayersArray(activeLayers)
      map.resize()

      // on Show custom logic per slide
      if (currentSlideData.onShow) {
        currentSlideData.onShow(map, layers)
      }
    }

    if (mapRef.current) func(mapRef.current)
    else setTimeout(() => func(mapRef.current), 100)
  }, [currentSlide])

  return (
    <>
      {SlideOverlay && (
        <Column className='absolute inset-0 z-10 bg-white flex-1 center'>
          <SlideOverlay />
        </Column>
      )}
      <Column className='absolute max-w-[320px] left-24 top-24 z-10 space-y-8'>
        {currentSlideData?.title && (
          <SlideShow.MapPanel>
            <div className='text-18 font-semibold'>{currentSlideData.title}</div>
          </SlideShow.MapPanel>
        )}
        {currentSlideData?.content && (
          <SlideShow.MapPanel>{currentSlideData.content}</SlideShow.MapPanel>
        )}
        {activeLayers.length > 0 &&
          activeLayers.map((layer) => {
            if (!layer.legend) return null

            return (
              <SlideShow.MapPanel key={layer.id}>
                <div className='text-12 font-bold uppercase tracking-wide text-ink-400 mb-8'>
                  Legend:
                </div>
                {layer.legend.type === 'discrete' && <MapLegend layer={layer} />}
                {layer.legend.type === 'gradient' && <GradientLegend layer={layer} />}
              </SlideShow.MapPanel>
            )
          })}
      </Column>
      <Row className='w-full h-full'>
        <BaseMap
          ref={mapRef}
          initialCountryCode3={countryCode3}
          dragRotate={false}
          scrollZoom={currentSlideData.interactive}
          dragPan={currentSlideData.interactive}
          initialViewState={initialViewState}
          maxBounds={currentSlideData.maxBounds}
          maxZoom={13}
        >
          <MapSources composite countryBoundaries />
          <MapSources.Satellite {...currentSlideData?.layers?.satellite} />
          <WaterLayer fill='rgba(0,0,0,0.8)' />
          <RasterLayers layers={landStoreSnap.mapLayers} />

          <MaskedCountryFillLayer code3={countryCode3} color='rgba(0,0,0,0.5)' />

          <ProjectPolygonAreaLayers
            featureCollection={landProjectParcelsToMapLayer(parcels).featureCollection}
            fillPaint={currentSlideData?.layers?.projectArea?.paint}
            linePaint={currentSlideData?.layers?.projectAreaLine?.paint}
          />

          {/* <PolygonLayers layers={layers} /> */}
        </BaseMap>
        {currentSlideData?.rightContent && (
          <Column className='h-full p-20 w-1/2 flex-shrink-0 space-y-20'>
            {currentSlideData.rightContent}
          </Column>
        )}
      </Row>
    </>
  )
}

function VCUsSlide() {
  const { landProject } = useActiveLandProject()
  useInitFinancialStore()
  const { vcus, variables } = useFinancialsStore()

  const discounts = getDiscounts(variables, 'arr')
  const vcusTotals = calculateLandProjectVCUStats(landProject, vcus)

  // TODO Average annual carbon dioxide removal per hectare
  // TODO Total annual carbon dioxide removal per hectare

  return (
    <Slide>
      <H2>VCUs</H2>

      <Row className='gap-x-80 gap-y-80 mt-40 text-14'>
        {vcusTotals.map((item, i) => (
          <Column className='flex-wrap' key={item.label + i}>
            <Title className='mb-20'>{item.label}</Title>
            <Numerical
              value={item.value}
              unitRight={item.unitRight}
              decimals={item.decimals}
              valueClassName='font-bold text-40 tracking-tighter'
            />
          </Column>
        ))}
      </Row>

      <Column className='mt-80'>
        <H2>Discounts</H2>
        {discounts.map((discount, i) => (
          <Row className='row-vcenter' key={discount.label + i}>
            <Title className='mr-8 mb-0'>{discount.label}: </Title>
            <Numerical
              value={discount.value * 100}
              unitRight={'%'}
              valueClassName='font-bold text-20 tracking-tighter'
            />
          </Row>
        ))}
      </Column>
    </Slide>
  )
}

function VCUsTableSlide() {
  return (
    <Section className='h-full'>
      <H2>VCUs Table</H2>
      <VCUsARRTable withColumnVisiblity={false} />
    </Section>
  )
}

function CarbonCurves() {
  useInitArrCarbonStore()
  const { landProject } = useActiveLandProject()

  const chosenProjection = landProjectStatToProjection(
    {
      ...landProject.xStats,
      snapshotMainInterventionKey: landProject.chosenModelKey,
    },
    landProject
  )

  const { totalProduction30yrs, averageAnual, avgPerHaPerYear } =
    calculateCarbonStats(landProject)

  return (
    <Section className='w-full h-full px-60 mb-80'>
      <Row className='flex-1 gap-40 items-start'>
        <Column className='flex-1 h-full'>
          <H2 className='mt-0'>Ex-ante GHG Removals (tCO2e) (without discounts)</H2>

          <Column className='mb-12 space-y-8'>
            <div>
              <span className='font-semibold'>Total GHG Removals:</span>{' '}
              <Numerical value={totalProduction30yrs} unitRight='tCO2e' />
            </div>
            <div>
              <span className='font-semibold'>Average annual:</span>{' '}
              <Numerical value={averageAnual} unitRight='tCO2e/year' />
            </div>
            <div>
              <span className='font-semibold'>Average per hectare per year:</span>{' '}
              <Numerical value={avgPerHaPerYear} unitRight='tCO2e/ha/year' />
            </div>
          </Column>
          <ProjectCarbonProjection
            className='w-full max-w-[1200px] h-[480px] max-h-[60vh] flex-1'
            showBounds={true}
            hideBoundsCheckbox
          />
          <p className='text-ink-600 mt-12 max-w-[1000px]'>
            The data that was used to create the scoping carbon curve has an average
            planting density of 1,758 trees/ha (range is wide, 25 to 5,000) and an average
            standing density at time of measurement of 605 trees/ha (range 124 to 1,740).
            So on average about 1/3 of the trees planted survived.
          </p>
        </Column>
      </Row>
    </Section>
  )
}

function getScheduleArray(schedule: LandProjectSchedule) {
  const scheduleArray = []
  for (let year = 0; year <= 29; year++) {
    if (!schedule?.[year]?.areaHa) continue
    scheduleArray.push({
      year,
      ...schedule[year],
    })
  }
  return scheduleArray
}

function InterventionSchedule({ scheduleArray }) {
  if (scheduleArray?.length === 0) return null
  return (
    <>
      <Divider className='my-12' />
      <Column>
        <Slide.SubtTitle>Planting Schedule</Slide.SubtTitle>
        {scheduleArray.map((s) => (
          <Row
            className='flex-1 text-right border-b py-4 first:pt-0 last:pb-0 last:border-none'
            key={s.year}
          >
            <span className='font-medium mr-8'>Year {s.year + 1}</span>{' '}
            <Numerical value={s.areaHa} type='area' className='flex-1' />
          </Row>
        ))}
      </Column>
    </>
  )
}
