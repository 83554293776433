import {
  FormField,
  FormFieldKeys,
  FormFieldOption,
  getIsNonAnswerableFieldType,
} from './formField'
import { allFields } from '~/data/forms'
import { ES_Form, FormId, getFormById } from '~/models/form'
import { getLandProjectType, LandProject } from '~/models/landProject'
import isEmpty from 'lodash.isempty'
import { UpdatedBy } from '~/models/authedUser'

export type DatumField = ProjectDatum & FormField

export type DatumKey = FormFieldKeys

export type ProjectDatum = {
  value?: any
  updatedBy?: UpdatedBy
}

export type LandProjectDatums = {
  [Key in FormFieldKeys]?: ProjectDatum
}

export function getProjectDataFieldsByFormId(
  formId: FormId,
  landProject: LandProject,
  hideManual?: boolean,
  skipNonValueFields?: boolean
): DatumField[] {
  const form = getFormById(formId)
  const projectType = getLandProjectType(landProject)

  const formFields = []

  form.fields.forEach((formField: FormField) => {
    // If the field is referencing the id of another replace the field with the right data
    if (formField.referenceFieldId) {
      formField = allFields.find((field) => field.id === formField.referenceFieldId)
      if (!formField) return
    }
    // filter out datums that are meant for internal usage. They're hidden during the intake form
    if (hideManual && formField?.isManual) return

    // Filter formFields that have a project type that is not the same as the current project
    if (
      formField.exclusiveToProjectType &&
      !formField.exclusiveToProjectType.includes(projectType)
    )
      return

    if (skipNonValueFields && getIsNonAnswerableFieldType(formField.type)) return

    formFields.push(formField)
  })

  return getDatumFields(formFields, landProject)
}

export function getDatumFieldsByKey(
  landProject: LandProject,
  fieldKeys: FormFieldKeys[]
) {
  const datumFields = fieldKeys.map((key) => {
    const datumField = allFields.find((i) => i.name === key)
    return datumField
  })

  return getDatumFields(datumFields, landProject)
}

export function getDatumFields(
  formFields: FormField[],
  landProject: LandProject,
  checkConditions?: boolean
): DatumField[] {
  const datumFields = formFields.map((fromField) => {
    // Desconstruct attributes that are not going to be used in fields
    const { isManual, ...rest } = fromField
    const field: Partial<DatumField> = { ...rest }

    const datum = parseProjectDatum(landProject.data?.[fromField.name])
    let { value, updatedBy } = datum

    // If the input allows for multiple options add the multiple attribute
    if (fromField.type === 'multiple-options') {
      field.multiple = true
      // Check if value is a string, if so, convert to an array
      // so values can propagate properly in the <select></select> components
      value = value ? (value instanceof Array ? value : [value]) : []
    }

    // Converts options from our config data to an array of objects
    if (fromField.options && typeof fromField.options === 'string') {
      field.options = fromField.options
    }

    // If the input doesn't allow for multiple make sure it has only one value
    if (fromField.type === 'options' && typeof value === 'object' && value?.[0]) {
      value = value[0]
    }

    const datumField = {
      ...field,
      value,
      updatedBy,
      id: fromField.id || fromField.name, // TODO use a single id
    }

    return datumField
  })

  return datumFields
}

export function getLandProjectDatums(
  landProject: LandProject,
  datumKeys: DatumKey[]
): DatumField[] {
  return datumKeys.reduce((prev, datumKey) => {
    const datum = getLandProjectDatum(landProject, datumKey)
    return datum ? [...prev, datum] : prev
  }, [])
}

export function getLandProjectDatum(
  landProject: LandProject,
  datumKey: DatumKey
): DatumField {
  const formField = allFields?.find((d) => d.name === datumKey)
  if (!formField) return null

  const projectDatum = parseProjectDatum(landProject.data?.[formField.name])

  const datumField = {
    ...formField,
    ...projectDatum,
  }

  return datumField
}

function parseProjectDatum(datum: ProjectDatum | string): ProjectDatum {
  if (!datum) return { value: null }
  if (typeof datum === 'string' || typeof datum === 'number') {
    return { value: datum }
  }
  if (datum instanceof Array) {
    return { value: datum }
  } else return datum
}

export function parseValueOptionsAsString(datumField: DatumField) {
  if (!datumField.value) return null

  let value = datumField.value

  if (isOptionsType(datumField)) {
    value = parseValueOptionsAsOptions(datumField)
    value = value.map((op) => op?.label).join(', ')
  }

  return value
}

export function parseValueOptionsAsOptions(datumField: DatumField): FormFieldOption[] {
  if (!datumField.value) return null

  let value = datumField.value
  if (isOptionsType(datumField)) {
    if (!Array.isArray(value)) value = [value]

    value = value.map((val) => {
      return datumField.options.find((op) => op.value === val)
    })
  }
  return value
}

function isOptionsType(datumField: DatumField) {
  return datumField.type === 'options' || datumField.type === 'multiple-options'
}

export function getChosenOption(datumField: DatumField) {
  if (!datumField.options) throw `This field doesn't have optiosn, review...`

  const chosenOption = datumField.options.find((o) => o.value == datumField.value)
  return chosenOption
}

export function getScoreForOption(datumField: DatumField) {
  if (!datumField.options) throw `This field doesn't have optiosn, review...`

  const chosenOption = datumField.options.find((o) => o.value == datumField.value)
  const optionScore = chosenOption?.score || 0
  return optionScore
}

export function getDatumFieldById(datumFields: DatumField[], datumFieldId: string) {
  return datumFields.find((f) => f.id === datumFieldId)
}

export function matchesCondition(targetField: DatumField, field: DatumField): boolean {
  const targetValue = targetField.value
  const condition = field.condition

  switch (condition.state) {
    case 'equals':
      const value =
        targetField.type === 'multiple-options'
          ? targetField.value?.[0]
          : targetField.value
      return value === condition.value
    case 'includes':
      return Array.isArray(condition.value)
        ? condition.value.some((v) => targetValue?.includes(v))
        : targetValue?.includes(condition.value)
    case 'not-empty':
      return targetValue?.length > 0
    default:
      return false
  }
}

export function getDatumFieldsTotalScore(datumFields: DatumField[]) {
  const totalScore = datumFields.reduce((prev, datumField) => {
    const score = datumField.options ? getScoreForOption(datumField) : 0
    return prev + score
  }, 0)
  return totalScore
}

export function getTotalPossibleScore(datumFields: DatumField[]) {
  let totalMin = 0
  let totalMax = 0
  datumFields.forEach((datumField: DatumField) => {
    if (!datumField.options) return
    let min = 0
    let max = 0
    datumField.options.forEach((option) => {
      if (!option.score) return

      if (option.score < 0) {
        min =
          datumField.type == 'multiple-options'
            ? min + option.score
            : Math.min(min, option.score)
      } else if (option.score > 0) {
        max =
          datumField.type == 'multiple-options'
            ? max + option.score
            : Math.max(max, option.score)
      }
    })

    totalMin += min
    totalMax += max
  })

  return { min: totalMin, max: totalMax }
}

export function getFormsCompletion(forms: ES_Form[], landProject: LandProject) {
  const totalStats = {
    completedQuestions: 0,
    totalQuestions: 0,
  }

  const formCompletions = forms.map((form) => {
    const { total, completed } = getFormCompletionRate(form, landProject)
    totalStats.completedQuestions += completed
    totalStats.totalQuestions += total

    return {
      ...form,
      total,
      completed,
      percent: (completed * 100) / total,
    }
  })

  return {
    ...totalStats,
    percent: (totalStats.completedQuestions * 100) / totalStats.totalQuestions,
    forms: formCompletions,
  }
}

export function getFormCompletionRate(form: ES_Form, landProject: LandProject) {
  const datumFields = getProjectDataFieldsByFormId(form.id, landProject, false, true)
  const filtered = filterConditionalDatumFields(datumFields)
  const formFieldsCount = filtered.length

  const completedDatumFields = filtered.reduce((prev, curr) => {
    return getIsFieldCompleted(curr) ? prev + 1 : prev
  }, 0)

  return {
    completed: completedDatumFields,
    total: formFieldsCount,
  }
}

function getIsFieldCompleted(datumField: DatumField) {
  return !isEmpty(datumField?.value)
}

function filterConditionalDatumFields(datumFields: DatumField[]) {
  return datumFields.filter((datumField) => {
    if (!datumField.condition) return true

    const targetDatumField = getDatumFieldById(
      datumFields,
      datumField.condition.targetFieldId
    )
    if (!targetDatumField) return true

    return matchesCondition(targetDatumField, datumField)
  })
}
