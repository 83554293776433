import { useSnapshot } from 'valtio'
import { ErrorMessage } from '~/components/atomic/ErrorMessage'
import { Spreadsheet } from '~/components/core/Spreadsheet'
import {
  financialsStore,
  updateStoreVariable,
} from '~/routes/project/financials/financialsStore'
import { Column, Row } from '~/styles'
import { Section } from '~/styles/main'
import { DEFAULT_PROJET_PERIOD } from '~/utils/constants'
import { formatNumber } from '~/utils/units'

export function ProjectEfficiency() {
  const snap = useSnapshot(financialsStore)
  const startYear = snap.variables.startYear
  const projectEfficiency = snap.variables.projectEfficiencyRate

  const data = projectEfficiency.slice(0, DEFAULT_PROJET_PERIOD).map((d, i) => [
    { value: i, readOnly: true, className: 'text-center' },
    { value: i + startYear, readOnly: true, className: 'text-center' },
    { value: formatNumber(d * 100, 0), unit: '%', className: 'text-right' },
  ])

  const isValid = getIsValid(data)
  // TODO: Create manual option
  const columnLabels = ['Year #', 'Year Date', 'Efficiency Factor']

  const onChange = (newData) => {
    const newProjectEfficiency = parseTableToArray(newData)
    if (JSON.stringify(projectEfficiency) !== JSON.stringify(newProjectEfficiency)) {
      updateStoreVariable(newProjectEfficiency, 'projectEfficiencyRate')
    }
  }

  return (
    <Column>
      <Row className='row-vcenter mb-20 space-x-20'>
        <Section.Subtitle className='mb-0'>Project Efficiency</Section.Subtitle>

        <ErrorMessage
          error={!isValid && 'Invalid values. Each value should be betwen 0 and 100'}
        />
      </Row>

      <Row>
        <Spreadsheet
          columnLabels={columnLabels}
          data={data}
          hideRowIndicators={true}
          onChange={onChange}
        />
      </Row>
    </Column>
  )
}

function getIsValid(data) {
  return parseTableToArray(data).every((v) => (v < 0 || v > 100 ? false : true))
}

function parseTableToArray(data) {
  const efficiencyArray = data.map((row) => Number(row[2].value) / 100)
  return efficiencyArray
}
