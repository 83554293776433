import Input from '~/components/atomic/Input'
import { LiteratureSite } from '../types'

type DecimalDegreesProps = {
  data: LiteratureSite
}

const DecimalDegrees = (props: DecimalDegreesProps) => {
  const { data } = props

  return (
    <div className='group my-16 grid grid-cols-3 gap-16'>
      <Input
        name='centerLatDecimalDeg'
        label='Latitude'
        placeholder='0'
        defaultValue={data.centerLatDecimalDeg}
        required
      />
      <Input
        name='centerLonDecimalDeg'
        label='Longitude'
        placeholder='0'
        defaultValue={data.centerLonDecimalDeg}
        required
      />
    </div>
  )
}

export { DecimalDegrees }
