import { cn } from '~/utils/cn'

import { MapControls } from '~/components/map/MapControls'
import {
  MapSourceIds,
  mapSourceIdToPaintLayerVariant,
  paintLayerVariants,
} from '~/components/map/style'

import { Row } from '~/styles'
import { getRGBA } from '~/utils/color'

type Props = {
  className?: string
  sources: {
    label: string
    id: MapSourceIds
  }[]
}

export function MapLegend(props: Props) {
  const { className, sources } = props

  return (
    <MapControls.Card className={cn('space-y-12', className)}>
      {sources.map((source) => {
        const mapStyles = paintLayerVariants[mapSourceIdToPaintLayerVariant(source.id)]
        return <Item text={source.label} mapStyles={mapStyles} key={source.id} />
      })}
    </MapControls.Card>
  )
}

function Item(props) {
  const { mapStyles, text } = props
  const { fill, fillOpacity, line, lineOpacity } = mapStyles

  return (
    <Row>
      <div
        className='mr-12 h-20 w-20 rounded-sm border'
        style={{
          background: getRGBA(fill, fillOpacity),
          borderColor: getRGBA(line, lineOpacity),
        }}
      />
      {text}
    </Row>
  )
}
