import ObjectId from 'bson-objectid'
import { cn } from '~/utils/cn'
import { useState } from 'react'
import { Checkbox } from '~/components/atomic/Checkbox'
import Input, { Textarea } from '~/components/atomic/Input'
import { Pressable } from '~/components/atomic/Pressable'
import Select from '~/components/atomic/Select'
import { ProjectTypeBadge } from '~/components/core/ProjectTypeBadge'
import SelectCountry from '~/components/core/SelectCountry'
import { FormField, FormFieldType, formFieldTypes } from '~/models/formField'
import { ConditionalPopover } from '~/routes/admin/formBuilder/ConditionalPopover'
import { OptionsList } from '~/routes/admin/formBuilder/OptionsList'
import { Column, Row } from '~/styles'
import { capitalizeFirstLetter } from '~/utils'

const inputOptions = formFieldTypes.map((i) => ({
  value: i,
  label: capitalizeFirstLetter(i),
}))

type Props = {} & FormField

const subtleInputClasses = `border-0 shadow-none resize-none bg-transparent hover:ring-indigo-500 hover:border-1 hover:border-indigo-500 hover:ring-ink-200 hover:bg-ink-100`

const exampleField = 'mt-4 pointer-events-none cursor:default'

export function InputConfigItem(props: Props) {
  const {
    id = ObjectId().toHexString(),
    name,
    label,
    description,
    withNotes,
    placeholder,
    condition,
    exclusiveToProjectType,
    options,
  } = props
  const [type, setType] = useState(props.type)

  const separatorOrTitle = ['title', 'section'].includes(type)
  const [showDescription, setShowDescription] = useState<boolean>(!!description)

  const titleComponent = (
    <Textarea
      name='label'
      placeholder='Input title'
      defaultValue={label}
      minRows={1}
      className='-mx-8 flex-1'
      textareaClassName={cn(
        subtleInputClasses,
        'font-medium ',
        { '!text-[24px] font-extrabold bg-green-100': type == 'section' },
        { '!text-[20px] !font-semibold  bg-green-100': type == 'title' }
      )}
      required
    />
  )

  const descriptionComponent = showDescription && (
    <Textarea
      name='description'
      placeholder='Input description (optional)'
      defaultValue={description}
      minRows={1}
      className={`-mx-8 -mt-4 mb-4 text-13 text-ink-600`}
      textareaClassName={`${subtleInputClasses}`}
    />
  )

  const multipleOptions = type == 'multiple-options'

  const isOptionsType = ['options', 'multiple-options'].includes(type)

  const optionsComponent = isOptionsType && (
    <OptionsList options={options} multiple={multipleOptions} />
  )

  const previewComponent = (
    <Column>
      {type == 'paragraph' && (
        <Textarea
          className={`${exampleField}`}
          placeholder={placeholder || 'Long answer...'}
        />
      )}
      {['short-text', 'number', 'percent', 'currency'].includes(type) && (
        <Input
          className={`${exampleField}`}
          placeholder={placeholder || 'Short answer ...'}
        />
      )}

      {type == 'country' && <SelectCountry />}

      {optionsComponent}

      {withNotes && (
        <Textarea
          className={`mt-8 ${exampleField}`}
          placeholder={placeholder || 'Please explain your answer...'}
        />
      )}
    </Column>
  )

  const typeComponent = (
    <Select
      name='type'
      defaultValue={type}
      options={inputOptions}
      placeholder='Input type'
      className='min-w-[220px]'
      onChange={(e) => setType(e.currentTarget.value as FormFieldType)}
    />
  )

  const withNotesComponent = !separatorOrTitle && (
    <Checkbox
      name='with-notes'
      label='With Notes'
      defaultChecked={withNotes}
      className={cn('-mx-8 !rounded-md !p-8 hover:bg-inka-100')}
    />
  )

  const nameComponent = !separatorOrTitle && (
    <Row className='row-vcenter mt-4'>
      <div className='mr-4 font-mono text-12 font-bold'>NAME:</div>
      <Input
        name='name'
        placeholder='Input name'
        defaultValue={name || ObjectId().toHexString()}
        className='flex-1'
        inputClassName='!text-13 text-ink-500 border-none shadow-none !required:border-1 required:!border-red-500'
        required
      />
    </Row>
  )

  const addDescriptionComponent = !separatorOrTitle && !showDescription && (
    <Pressable icon='short_text' onClick={() => setShowDescription(true)}>
      add description
    </Pressable>
  )

  const separator = !separatorOrTitle && (
    <div className='absolute left-32 right-20 top-[-1px] h-1 border-b border-dashed border-ink-200 group-hover:opacity-0' />
  )

  return (
    <fieldset
      id={id}
      data-exclusive-to-project-type={exclusiveToProjectType}
      data-condition={JSON.stringify(condition)}
      className={cn(
        'row fieldset group relative -ml-32 -mr-20 rounded-lg p-8 hover:z-10 hover:bg-white hover:shadow-xl hover:ring-1 hover:ring-ink-200'
      )}
    >
      {separator}
      <Row
        className={cn(
          'w-full items-start space-y-12 p-12 pl-24',
          { 'mt-20': type == 'section' },
          { 'mt-12': type == 'title' }
        )}
      >
        <Column className='flex-1'>
          <Row className='row-vcenter w-full flex-1'>
            {type == 'checkbox' && <Checkbox className='!mr-20' />}

            <Column className='flex-1'>
              <Row className='row-vcenter gap-8'>
                {titleComponent}
                <ProjectTypeBadges exclusiveToProjectType={exclusiveToProjectType} />
                {condition && <ConditionalPopover condition={condition} />}
              </Row>
              {descriptionComponent}
              {previewComponent}
            </Column>
          </Row>
        </Column>
      </Row>

      <div className='invisible border-l border-dashed border-ink-300 p-12 group-hover:visible'>
        <Column className='relative ml-24 mr-12 space-y-4'>
          {nameComponent}
          {typeComponent}
          {addDescriptionComponent}
          {withNotesComponent}
        </Column>
      </div>
    </fieldset>
  )
}

function ProjectTypeBadges({ exclusiveToProjectType }) {
  if (Array.isArray(exclusiveToProjectType))
    return (
      <>
        {exclusiveToProjectType.map((pt) => (
          <ProjectTypeBadge key={pt} projectType={pt} size='small' />
        ))}
      </>
    )
  else return <ProjectTypeBadge projectType={exclusiveToProjectType} size='small' />
}
