import { useState, useRef, useEffect } from 'react'
import { Page } from '~/components/layouts/Page'
import { getFormData } from '~/utils'
import { Button } from '~/components/atomic/Button'
import { toast } from '~/services/toaster'
import { ScienceNav } from './Nav'
import { LiteraturePublicationComponent } from './LiteraturePublication'
import { LiteratureSiteComponent } from './LiteratureSite'
import { DEFAULT_PUBLICATION_DATA } from './types'
import { getPublicationById, savePublication, updatePublicationById } from './utils'
import { useParams } from 'react-router-dom'
import { state } from '~/state'

const Entry = () => {
  const [publicationId, setPublicationId] = useState(undefined)
  const [entry, setEntry] = useState<any>(DEFAULT_PUBLICATION_DATA)
  const [hasChanged, setHasChanged] = useState(false)
  const formRef = useRef<HTMLFormElement>(null)
  const { id } = useParams()

  useEffect(() => {
    const setup = async () => {
      if (id) {
        try {
          const publication = await getPublicationById(id)
          if (publication) {
            setEntry(publication)
            setPublicationId(id)
          }
        } catch (error) {
          toast.error(`Unable to load publication with ID "${id}". Error: ${error.message}`)
        }
      }
    }

    setup()
  }, [id])

  const title = publicationId ? `Publication ${publicationId}` : 'New Publication'

  const onSaveClick = () => {
    formRef.current.dispatchEvent(new Event('submit'))
  }

  const saveButton = (
    <Button
      variant={!hasChanged ? 'disabled' : 'primary'}
      onClick={onSaveClick}
      disabled={!hasChanged}
    >
      {publicationId ? 'Update' : 'Save'}
    </Button>
  )

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const createdBy = {
      firstName: state.authedUser?.first_name,
      userId: state.authedUser?._id,
    }
    const formData: Record<any, any> = getFormData(event.currentTarget)
    const data = {
      ...formData,
      createdBy,
    }

    try {
      if (id) {
        await updatePublicationById({
          existingData: entry,
          updatedData: data,
          publicationId: id,
        })
      } else {
        const newPublication = await savePublication(data)
        setPublicationId(newPublication.literaturePublication._id)
      }

      toast.info('Publication saved')
      setHasChanged(false)
    } catch (error) {
      console.error('Unable to save publication', error)
      toast.error(`Unable to save publication. Error: ${error.message}`)
    }
  }

  const onChange = (event: React.FormEvent<HTMLFormElement>) => {
    setHasChanged(true)
  }

  return (
    <Page title={title}>
      <form onSubmit={onSubmit} onChange={onChange} ref={formRef}>
        <ScienceNav subtitle={title} actions={saveButton}></ScienceNav>
        <main className='m-auto max-w-screen-lg p-16 md:p-20'>
          <LiteraturePublicationComponent data={entry.literaturePublication} />
          {entry.literatureSites.map((literatureSite, index) => (
            <LiteratureSiteComponent
              data={literatureSite}
              key={`literatureSite-${index}`}
            />
          ))}
        </main>
      </form>
    </Page>
  )
}

export { Entry }
