import { useEffect, useState } from 'react'
import { CenteredLoader } from '~/components/atomic/CenteredLoader'
import Icon from '~/components/atomic/Icon'
import { Pressable } from '~/components/atomic/Pressable'
import { Time } from '~/components/atomic/Time'
import { fetchGoogleFolder } from '~/services/googleDrive'

import { Column, Row } from '~/styles'
import { Divider, Section } from '~/styles/main'
import { LandProjectAutoSavingInput } from '~/components/core/LandProjectAutoSavingInput'
import { InputItem } from '~/routes/project/summary/SummarySection'
import { useActiveLandProject } from '~/state'
import Input from '~/components/atomic/Input'

type Document = {
  name: string
  id: string
  url: string
  downloadUrl: string
  createdDate: string
  iconUrl: string
}

type Props = {
  googleDriveFolderUrl: string
}

export function Documents(props: Props) {
  const { googleDriveFolderUrl } = props
  const [documents, setDocuments] = useState(null)
  const { landProject } = useActiveLandProject()

  useEffect(() => {
    ;(async () => {
      if (!googleDriveFolderUrl) return
      const folderId = googleDriveFolderUrl.split('folders/')[1]

      const data = await fetchGoogleFolder(folderId)
      const documents = data.items.map((item) => {
        return {
          name: item.title || item.originalFilename,
          id: item.id,
          downloadUrl: item.downloadUrl,
          url: item.alternateLink,
          createdDate: item.createdDate,
          iconUrl: item.iconLink,
        }
      })
      setDocuments(documents)
    })()
  }, [googleDriveFolderUrl])

  const title = (
    <Row className='row-vcenter gap-x-24 mb-16 '>
      <Section.Subtitle className='mb-0'>Documents</Section.Subtitle>
      {googleDriveFolderUrl && (
        <a href={googleDriveFolderUrl} target='_blank' rel='noreferrer'>
          <Pressable iconRight='launch'>Link</Pressable>
        </a>
      )}
    </Row>
  )

  const urlInput = (
    <Column className='mb-12'>
      <Input.Label className='w-full max-w-[140px] mb-8'>Google Drive Folder</Input.Label>
      <LandProjectAutoSavingInput
        type='string'
        valueKey='googleDriveFolderUrl'
        defaultValue={landProject?.googleDriveFolderUrl}
        landProjectId={landProject._id}
      />
    </Column>
  )

  if (!googleDriveFolderUrl)
    return (
      <div>
        {title}
        {urlInput}
      </div>
    )

  if (!documents)
    return (
      <div>
        {title}
        <CenteredLoader />
      </div>
    )

  if (documents.length === 0)
    return (
      <div>
        {title}
        {urlInput}
        No Documents found
      </div>
    )

  return (
    <div>
      {title}
      {urlInput}
      <Divider className='my-12' />
      <Column className='gap-y-2'>
        {documents.map((document) => {
          return <DocumentItem key={document.id} document={document} />
        })}
      </Column>
    </div>
  )
}

function DocumentItem({ document }: { document: Document }) {
  return (
    <Pressable
      href={document.url}
      openInNewTab
      className='border mx-0 border-ink-200 rounded-md p-8 px-16 items-start justify-start hover:bg-[#C9E6FC]'
    >
      {document.iconUrl ? (
        <img src={document.iconUrl} width={16} height={16} className='mr-12' />
      ) : (
        <Icon icon='insert_drive_file' className='mr-12 text-[#3871E0]' />
      )}

      <Column className='items-start'>
        <div className='font-medium text-14'>{document.name}</div>
        {/* <div>{document.id}</div> */}
        {/* <div>{document.downloadUrl}</div> */}
        <span className='opacity-50 text-12'>
          Added <Time time={document.createdDate} className='' relative />
        </span>
      </Column>
      {/* <div className='flex-1' />
      <Pressable
        icon='file_download'
        iconSize={20}
        className='p-12 text-opacity-60'
        href={document.downloadUrl}
        openInNewTab
      /> */}
    </Pressable>
  )
}
