import * as SwitchPrimitive from '@radix-ui/react-switch'
import { cn } from '~/utils/cn'

type Props = {
  label?: string
} & SwitchPrimitive.SwitchProps

export function Switch(props: Props) {
  const { name, value, label, className, ...rest } = props

  const switchComponent = (
    <SwitchPrimitive.Root
      name={name}
      value={value}
      className={cn(
        'group',
        'radix-state-checked:bg-highlight',
        'radix-state-unchecked:bg-ink-300',
        'relative inline-flex h-[24px] w-[44px] flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out',
        'focus:outline-none focus-visible:ring focus-visible:ring-highlight/50',
        { 'ml-8': label },
        !label && className
      )}
      {...rest}
    >
      <SwitchPrimitive.Thumb
        className={cn(
          'group-radix-state-checked:translate-x-20',
          'group-radix-state-unchecked:translate-x-0',
          'pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out'
        )}
      />
    </SwitchPrimitive.Root>
  )

  if (label) {
    return (
      <label
        htmlFor={name}
        className={`row row-vcenter cursor-pointer select-none text-14 font-medium ${className}`}
      >
        {label}
        {switchComponent}
      </label>
    )
  } else {
    return switchComponent
  }
}
