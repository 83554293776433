import { useEffect, useState } from 'react'

import { LandProjectImage, LandProjectImageTags } from '~/models/landProject'
import { cn } from '~/utils/cn'

let usedImages = []

export type Props = {
  images: LandProjectImage[]
  tags: LandProjectImageTags | LandProjectImageTags[]
  className?: string
}

export function ProjectImage(props: Props) {
  const { className, tags, images } = props
  const [image, setImage] = useState<LandProjectImage>(null)

  useEffect(() => {
    const image = getImage(images, typeof tags === 'string' ? [tags] : tags)
    setImage(image)

    return () => {
      unregsiterImage(image?.url)
    }
  }, [])

  if (!image) return null

  return <img src={image.url} className={cn('object-cover', className)} />
}

export function getImage(images: LandProjectImage[], tags: LandProjectImageTags[]) {
  let image = null

  for (const tag in tags) {
    image = getImageFromTag2(tags[tag], images)
    if (image) break
  }

  return image
}

function getImageFromTag2(tag: LandProjectImageTags, images: LandProjectImage[]) {
  const imagesWithTag = images.filter((i) => i.tag === tag)

  let chosenImage

  imagesWithTag.some((image) => {
    const isUsed = getIsImageUsed(image.url)

    if (!isUsed) {
      regsiterImage(image.url)
      return (chosenImage = image)
    }
  })
  return chosenImage
}

// Useed images pool methods
function getIsImageUsed(imageUrl: string) {
  return usedImages.find((i) => i == imageUrl)
}

function regsiterImage(imageUrl: string) {
  usedImages = [...usedImages, imageUrl]
}

function unregsiterImage(imageUrl: string) {
  usedImages = usedImages.filter((i) => i == imageUrl)
}
