import { Outlet, useSearchParams } from 'react-router-dom'
import { ErrorBox } from '~/components/atomic/ErrorBoundary'
import ProjectsNav from '~/routes/projects/components/ProjectsNav'
import { useFetchLandProjects } from '~/routes/projects/hooks'
import { Column } from '~/styles'

export function ProjectsLayout() {
  const [searchParams] = useSearchParams()
  const projectType = searchParams.get('projectType')
  const landProjectsData = useFetchLandProjects(projectType)

  return (
    <Column className='h-full overflow-hidden'>
      <ProjectsNav />

      <div className='min-h-full overflow-y-auto'>
        {landProjectsData.error ? (
          <ErrorBox error={landProjectsData?.error} />
        ) : (
          <Outlet context={landProjectsData} />
        )}
      </div>
    </Column>
  )
}
