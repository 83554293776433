import { useEffect } from 'react'
import { proxy } from 'valtio'
import { LandProject } from '~/models'
import {
  REDDAssumptions,
  REDDComputeModelKeys,
  REDDModelsDataset,
  defaultREDDAssumptions,
  defaultWorldDatasets,
  getLandProjectREDDStats,
} from '~/models/landProjectReddStats'
import { ComputeModelInfo } from '~/models/landProjectStats'
import { logger } from '~/services/logger'
import { state } from '~/state'
import ESSocket from '~/utils/ESSocket'
import { DEFAULT_START_YEAR } from '~/utils/constants'

type Store = {
  chosenDatasets: REDDModelsDataset
  assumptions: REDDAssumptions
}

export const reddCarbonStore = proxy<Store>({
  chosenDatasets: defaultWorldDatasets,
  assumptions: defaultREDDAssumptions,
})

export function useInitREDDStore(landProject: LandProject) {
  useEffect(() => {
    const reddStats = getLandProjectREDDStats(landProject)

    if (reddStats?.assumptions) reddCarbonStore.assumptions = reddStats.assumptions

    if (reddStats?.computeModelsInfo) {
      reddStats.computeModelsInfo.forEach((computeModelInfo: ComputeModelInfo) => {
        reddCarbonStore.chosenDatasets[computeModelInfo.computeMethod] =
          computeModelInfo.dataset
      })
    }
  }, [landProject])
}

export function computeREDDScopingStatsWithDefaults(landProject, parcels) {
  const computeModelsInfo = getModelsInfoFromDatasets(defaultWorldDatasets)
  const avoidedEmissionsPayload = {
    landProjectId: landProject._id,
    startYear: landProject.startYear,
    snapshotMainInterventionKey: REDDComputeModelKeys.vm0007,
    computeModelsInfo,
    assumptions: defaultREDDAssumptions,
    parcels,
  }

  computeREDDScopingStats(avoidedEmissionsPayload)
}

export function computeREDDScopingStatsFromStore(
  landProject,
  parcels,
  referenceRegionCoordinates,
  referenceRegionCoordinates5Km
) {
  const computeModelsInfo = getModelsInfoFromDatasets(reddCarbonStore.chosenDatasets)
  const avoidedEmissionsPayload = {
    landProjectId: landProject._id,
    startYear: landProject?.startYear || DEFAULT_START_YEAR,
    snapshotMainInterventionKey: REDDComputeModelKeys.vm0007,
    computeModelsInfo,
    assumptions: reddCarbonStore.assumptions,
    parcels,
    referenceRegionCoordinates,
    referenceRegionCoordinates5Km,
  }

  computeREDDScopingStats(avoidedEmissionsPayload)
}

export function computeREDDScopingStats(avoidedEmissionsPayload) {
  logger.info(`emitting -> computeProjectAvoidedEmissions`, avoidedEmissionsPayload)
  ESSocket.emit('computeProjectAvoidedEmissions', avoidedEmissionsPayload)

  state.activeComputations.avoidedEmissions = true
}

function getModelsInfoFromDatasets(datasets: REDDModelsDataset): ComputeModelInfo[] {
  const computeModelsInfo = Object.entries(datasets).map((chosenDataset) => {
    const computeModelInfo: ComputeModelInfo = {
      computeMethod: chosenDataset[0],
      dataset: chosenDataset[1],
    }
    return computeModelInfo
  })
  return computeModelsInfo
}
