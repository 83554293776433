import { Fields, ProjectDataForm } from '~/components/forms/FormRenderer'
import { ProjectType } from '~/models/landProject'
import {
  DatumKey,
  getDatumFieldsByKey,
  getProjectDataFieldsByFormId,
} from '~/models/projectDatum'
import { useActiveLandProject } from '~/state'
import { Section } from '~/styles/main'

type Props = {}

function getFieldsIds(projectType: ProjectType): DatumKey[] {
  return [
    'region',
    'potential-expansion-area',
    `intervention-type-${projectType}`,
    'status',
    'verification-standard',
    'implementation-timeline',
    'applicable-sdgs',
  ]
}

export function DataShortProfile(props: Props) {
  const {} = props
  const { landProject } = useActiveLandProject()

  if (!landProject) return null

  const fields = getDatumFieldsByKey(landProject, getFieldsIds(landProject.projectType))

  const extraFields = getProjectDataFieldsByFormId('short-profile', landProject)

  return (
    <div>
      <ProjectDataForm saveTarget='datums'>
        <Section.Title>Short Profile</Section.Title>
        <Fields fields={[...fields, ...extraFields]} />
      </ProjectDataForm>
    </div>
  )
}
