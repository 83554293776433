import { Layer, LayerProps, Source } from 'react-map-gl'

type Data = {
  sources: any
  country: string
}

export enum MapSourceIds {
  'projectArea' = 'projectArea',
  'referenceRegion' = 'referenceRegion',
}

function getLayers(data: Data) {
  const { country } = data

  return [
    {
      id: 'background',
      type: 'background',
      paint: {
        'background-color': 'rgb(4,7,14)',
      },
    },
    {
      id: 'satellite',
      type: 'raster',
      source: 'mapbox',
      'source-layer': 'mapbox_satellite_full',
      paint: {
        'raster-saturation': 0,
        // 'raster-opacity': 0.1,
      },
    },
    {
      id: 'countries',
      source: 'country-boundaries',
      'source-layer': 'country_boundaries',
      type: 'fill',
      filter: ['!=', ['get', 'name'], country],
      paint: {
        'fill-color': 'rgba(255,255,255, 0.8)',
      },
    },
  ]
}
export function getMapStyle(data: Data) {
  const { country, sources } = data
  return {
    version: 8,
    name: 'Mapbox Satellite',
    metadata: {
      'mapbox:autocomposite': true,
      'mapbox:type': 'default',
    },
    sources: {
      mapbox: {
        type: 'raster',
        url: 'mapbox://mapbox.satellite',
        tileSize: 512,
      },
      'country-boundaries': {
        type: 'vector',
        url: 'mapbox://mapbox.country-boundaries-v1',
      },
      parcels: {
        type: 'geojson',
        data: sources.parcels,
      },
    },
    center: [0, 0],
    zoom: 3,
    sprite: 'mapbox://sprites/mapbox/satellite-v9',
    glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
    layers: getLayers(data),
  }
}

export function getWorldMaskLayers(country: string): any {
  return (
    <>
      <Layer {...getCountryFill(country)} />
    </>
  )
}

function getCountryFill(country: string): any {
  return {
    id: 'world-mask-fill',
    source: 'country-boundaries',
    'source-layer': 'country_boundaries',
    type: 'fill',
    filter: ['!=', ['get', 'name'], country],
    paint: {
      'fill-color': 'rgba(255,255,255, 0.8)',
    },
  }
}

function getCountryLine(country: string): any {
  return {
    id: 'world-mask-line',
    source: 'country-boundaries',
    'source-layer': 'country_boundaries',
    type: 'line',
    filter: ['==', ['get', 'name'], country],
    paint: {
      'line-color': 'rgba(0,0,0,0.2)',
      'line-width': 4,
    },
  }
}

export function getWaterSourceAndLayer() {
  return (
    <Source id='mapbox-streets' type='vector' url='mapbox://mapbox.mapbox-streets-v8'>
      <Layer
        {...{
          id: 'water',
          type: 'fill',
          source: 'mapbox-streets',
          'source-layer': 'water',
          layout: {},
          paint: {
            'fill-color': '#B7D0D0',
          },
        }}
      />
    </Source>
  )
}

const labelLayer: LayerProps = {
  id: 'labels',
  type: 'symbol',
  source: 'native-forest',
  layout: {
    'text-field': ['get', 'name'],
    'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
    'text-radial-offset': 0.5,
    'text-justify': 'auto',
  },
}

export const fillStyle: any = {
  type: 'fill',
  layout: {},
  paint: {
    //prettier-ignore
    'fill-color': [ 'case',
        ['boolean', ['feature-state', 'hover'], false], '#e0ffdc', '#a7ec9e',
    ],
    //prettier-ignore
    'fill-opacity': ['case',
      ['boolean', ['feature-state', 'selected'], false], 0.25,
      ['boolean', ['feature-state', 'hover'], false], 0.15,
      0
    ],
  },
}

export const lineStyle: any = {
  type: 'line',
  layout: {},
  paint: {
    'line-color': '#ABEDAD',
    'line-width': ['case', ['boolean', ['feature-state', 'selected'], false], 7, 1.5],

    'line-opacity': 1,
  },
}

const defaultLineStyles = {
  line: '#ABEDAD',
  width: 1.5,
  widthSelected: 7,
  opacity: 0,
}

export const getLineStyle = (styles): any => {
  styles = { ...defaultLineStyles, ...styles }
  const { line, width, widthSelected, opacity } = styles
  return {
    type: 'line',
    layout: {},
    paint: {
      'line-color': line,
      //prettier-ignore
      'line-width': ['case', ['boolean', ['feature-state', 'selected'], false], widthSelected, width],
      'line-opacity': opacity,
    },
  }
}

const defaultFillStyles = {
  fill: '#e0ffdc',
  fillHover: '#a7ec9e',
  opacity: 0,
  opacityHover: 0.15,
  opacitySelected: 0.25,
}
export const getFillStyle = (styles): any => {
  styles = { ...defaultFillStyles, ...styles }
  const { fill, fillHover, opacity, opacityHover, opacitySelected } = styles
  return {
    type: 'fill',
    layout: {},
    paint: {
      //prettier-ignore
      'fill-color': [ 'case',
        ['boolean', ['feature-state', 'hover'], false], fillHover, fill,
    ],
      //prettier-ignore
      'fill-opacity': ['case',
      ['boolean', ['feature-state', 'selected'], false], opacitySelected,
      ['boolean', ['feature-state', 'hover'], false], opacityHover,
      opacity
    ],
    },
  }
}

type PaintLayerProps = {
  sourceId: MapSourceIds | string
  variant?: 'green' | 'red' | 'blue'
}
export function PaintLayers(props: PaintLayerProps) {
  const { sourceId } = props

  const variant =
    props.variant || mapSourceIdToPaintLayerVariant(sourceId as any) || 'green'
  const color = paintLayerVariants[variant]

  const fillStyle = getFillStyle({
    fill: color.fill,
    opacity: color.fillOpacity,
  })
  const lineStyle = getLineStyle({
    line: color.line,
    opacity: color.lineOpacity,
  })

  return (
    <>
      <Layer source={sourceId} id={`${sourceId}-fill`} {...fillStyle} />
      <Layer source={sourceId} id={`${sourceId}-line`} {...lineStyle} />
    </>
  )
}

export const paintLayerVariants = {
  green: {
    fill: '#70d692',
    fillOpacity: 0.2,
    line: '#70d692',
    lineOpacity: 0.8,
  },
  red: {
    fill: '#DB736B',
    fillOpacity: 0.2,
    line: '#D48D87',
    lineOpacity: 1,
  },
  blue: {
    fill: '#6b8ddb',
    fillOpacity: 0.5,
    line: '#879ad4',
    lineOpacity: 1,
  },
  white: {
    fill: '#ffffff',
    fillOpacity: 0.2,
    line: '#ffffff',
    lineOpacity: 1,
  },
}

export function mapSourceIdToPaintLayerVariant(sourceId: MapSourceIds) {
  switch (sourceId) {
    case MapSourceIds.referenceRegion:
      return 'red'
    default:
    case MapSourceIds.projectArea:
      return 'green'
  }
}
