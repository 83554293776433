import { cn } from '~/utils/cn'

import { Link, useParams } from 'react-router-dom'
import { Button } from '~/components/atomic/Button'
import { CenteredLoader } from '~/components/atomic/CenteredLoader'
import { ProjectImage, Props as ProjectImageProps } from '~/components/core/ProjectImage'
import { Page } from '~/components/layouts/Page'
import { CountryMap } from '~/components/map/CountryMap'
import { SiteLocationMap } from '~/components/map/SiteLocationMap'
import { AdditionalityPermanenceLeakage } from '~/components/profile/AdditionalityPermanenceLeakage'
import { CarbonStats } from '~/components/profile/CarbonStats'
import { LandStats } from '~/components/profile/LandStats'
import { ProjectDetails } from '~/components/profile/ProjectDetails'
import { ProjectSummary } from '~/components/profile/ProjectSummary'
import { SDGBenefits } from '~/components/profile/SDGBenefits'
import {
  FullBleedSection,
  Heading,
  P,
  TextSection,
  Title,
  WideSection,
} from '~/components/profile/shared'
import { Timeline } from '~/components/profile/Timeline'
import { getLandMeta, getLandProjectName } from '~/models/landProject'
import { getLandProjectDatums } from '~/models/projectDatum'
import { PortfolioNav } from '~/routes/portfolio/PortfolioNav'
import { useFetchLandProject } from '~/services/db'
import { useActiveLandProject } from '~/state'
import { Column, Row } from '~/styles'
import '~/styles/fonts/spaceGrotesk.css'
import { Divider, ResponsiveRow } from '~/styles/main'
import {
  defaultBackgroundImage,
  PATH,
  REGION_DESCRIPTION_IS_TOO_LONG,
} from '~/utils/constants'
import { formatArea } from '~/utils/units'

export function ShortProfile() {
  const params = useParams()
  const uName = params.project_slug
  const { landProject } = useActiveLandProject(uName)

  useFetchLandProject(uName, { skipParcels: true })

  if (!landProject) return <CenteredLoader />

  if (landProject.projectType === 'arr' && !landProject.chosenModelKey)
    throw 'No projection found for this project. Please go to Carbon tab and choose a projection'

  const name = getLandProjectName(landProject)
  const { region } = getLandMeta(landProject)

  return (
    <Page title={`Project Overview - ${name}`}>
      <PortfolioNav />
      <Column className='center pb-80'>
        <Cover className='mb-40' />

        <TextSection>
          <ProjectSummary />
          <Divider />
          <LandStats />
        </TextSection>

        <WideSection className='flex-col items-center'>
          <div className='flex w-full flex-col gap-2 sm:flex-row'>
            <CountryMap className='h-[320px] w-full' landProject={landProject} />
            <SiteLocationMap className='h-[320px] w-full' landProject={landProject} />
          </div>
          {region && region.length > REGION_DESCRIPTION_IS_TOO_LONG && (
            <TextSection className='!p-0'>
              <div className='mt-8 text-ink-500'>{region}</div>
            </TextSection>
          )}
        </WideSection>

        <TextSection>
          <Heading>Project Details</Heading>

          <ProjectDetails
            datumKeys={[
              'status',
              'verification-standard',
              `intervention-type-${landProject.projectType}`,
              'carbon-rights',
              'proj-structure',
              'desired-upfront-finance',
              'credit-issuance-projections',
              'indicative-carbon-price',
            ]}
          />
        </TextSection>

        <FullBleedSection>
          <TextSection>
            <ResponsiveRow className='mb-24 items-baseline'>
              <Heading className='mb-0'>Carbon Estimated Outcomes</Heading>
            </ResponsiveRow>

            <CarbonStats hideTitle />
          </TextSection>
        </FullBleedSection>

        <TextSection>
          <Heading>Project Development Timeline</Heading>
          <Timeline />
        </TextSection>

        <FullBleedImage tags='Panorama' images={landProject.images} />

        <TextSection>
          <Heading>Involved Parties</Heading>
          <InvolvedParties />
        </TextSection>

        <FullBleedImage tags='Panorama' images={landProject.images} />

        <TextSection>
          <Heading>Additionality, Permanence & Leakage</Heading>

          <AdditionalityPermanenceLeakage />
        </TextSection>

        <FullBleedImage tags='Panorama' images={landProject.images} />

        <TextSection>
          <Heading>SDG Benefits Beyond Carbon </Heading>

          <div className='flex flex-col lg:flex-row'>
            <SDGBenefits />
            <Column className='-mx-20 space-y-2 pt-24 lg:ml-40 lg:min-w-[400px] lg:pt-0'>
              <ProjectImage
                tags={['Nature', 'People']}
                images={landProject.images}
                className='max-h-[320px]'
              />
              <ProjectImage
                tags={['People', 'Nature']}
                images={landProject.images}
                className='max-h-[320px] object-cover'
              />
              <ProjectImage
                tags={['Nature', 'People']}
                images={landProject.images}
                className='max-h-[320px] object-cover'
              />
            </Column>
          </div>
        </TextSection>
        <Footer />
      </Column>
    </Page>
  )
}

function Cover({ className }) {
  const { landProject } = useActiveLandProject()
  const { name, country, area } = getLandMeta(landProject)

  const cover = landProject?.images?.find((i) => i.tag === 'Featured') || {
    url: defaultBackgroundImage,
  }

  return (
    <div
      className={cn(
        'flex h-[100vh] max-h-[900px] w-full flex-col overflow-hidden bg-[#29372F] print:max-h-[100vh] lg:flex-row',
        className
      )}
    >
      <Column className='center h-full w-full items-start p-20 text-white lg:w-[50%]'>
        <div className=' mb-40 text-center font-space-grotesk text-[55px] font-bold leading-[110%] tracking-tight'>
          {name}
        </div>
        <Row className='space-x-40 opacity-60'>
          <div className='text-[24px] leading-tight'>{country}</div>
          <div className='text-[24px] leading-tight'>{formatArea(area)}</div>
        </Row>
      </Column>

      <div className='h-[100%] w-full bg-inka-200 lg:w-[50%]'>
        {cover && <img src={cover.url} className='h-full w-full object-cover' />}
      </div>
    </div>
  )
}

function InvolvedParties() {
  const { landProject } = useActiveLandProject()

  const parties = getLandProjectDatums(landProject, [
    'involved-party-1',
    'involved-party-2',
    'involved-party-3',
    'involved-party-4',
  ])

  return (
    <div className='space-y-40'>
      {parties.map((p, key) => {
        if (!p.value) return null
        const text = p.value

        const name = text.slice(0, text.indexOf('\n'))
        const description = text.slice(text.indexOf('\n') + 1)

        return (
          <Column key={key}>
            <Title className='mr-12'>{name}</Title>
            <P className='whitespace-pre-line'>{description}</P>
          </Column>
        )
      })}
    </div>
  )
}

const FullBleedImage = ({ className, ...rest }: ProjectImageProps) => (
  <ProjectImage className={cn('mb-60 max-h-[400px] w-full', className)} {...rest} />
)

function Footer() {
  const { landProject } = useActiveLandProject()

  return (
    <footer className='center w-full border-t border-ink-150 pt-40'>
      <TextSection className='center'>
        <Link to={PATH['/portfolio']} state={{ from: landProject.uName }}>
          <Button
            variant='primary'
            className='px-24 py-16 !text-20 font-bold'
            icon='arrow-backward'
          >
            Back to all projects
          </Button>
        </Link>
      </TextSection>
    </footer>
  )
}
