import { cn } from '~/utils/cn'

import Icon from '~/components/atomic/Icon'
import { ActiveMatchTypes, Pressable } from '~/components/atomic/Pressable'
import classed from '~/styles/classed'

import { Divider, Section } from '~/styles/main'

export type SideOption = {
  to?: string
  type?: 'divider' | 'title' | unknown
  label?: string | any
  activeMatch?: ActiveMatchTypes
  icon?: string
  index?: number
}

export type SideOptions = SideOption[]

type Props = {
  className?: string
  options: SideOptions
}

export function SideMenu(props: Props) {
  const { options, className } = props

  return (
    <div
      className={cn(
        'flex flex-row md:flex-col gap-32 md:gap-0 overflow-x-scroll md:overflow-visible space-y-1',
        className
      )}
    >
      {options.map((option, index) => {
        if (!option) return null
        const { type, label, to, activeMatch } = option

        if (type === 'divider')
          return <Divider key={index} className='hidden md:flex !my-10' />
        if (type === 'title')
          return (
            <SideMenu.Heading key={index} className='!mt-20 first:!mt-0 !mb-4'>
              {label}
            </SideMenu.Heading>
          )

        let list = null

        if (option.icon)
          list = <Icon size={16} icon={option.icon} className='opacity-60' />
        else if (option.index)
          list = (
            <span className='hidden md:flex text-left opacity-60 self-start'>
              {option.index}.
            </span>
          )
        else
          list = (
            <span className='hidden md:inline -mt-[3px] text-center opacity-60'>·</span>
          )

        return (
          <Pressable key={index} to={to} activeMatch={activeMatch || 'includes'}>
            <div className='flex w-[18px] mr-8 center'>{list}</div>
            {label}
          </Pressable>
        )
      })}
    </div>
  )
}

SideMenu.Title = classed(Section.Subtitle, '!mb-24')
SideMenu.Heading = classed('div', 'text-14 font-[450] text-ink-600 pb-8')
