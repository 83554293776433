import * as ProgressPrimitive from '@radix-ui/react-progress'
import React, { useEffect } from 'react'
import { cn } from '~/utils/cn'

interface Props {
  value: number
  duration?: number
  className?: string
  progressClassName?: string
}

export function Progress(props: Props) {
  const { value, duration = 300, className, progressClassName } = props

  return (
    <ProgressPrimitive.Root
      value={value}
      className={cn(
        'h-12 w-full overflow-hidden rounded-full bg-ink-100 dark:bg-gray-900',
        className
      )}
    >
      <ProgressPrimitive.Indicator
        style={{ width: `${value}%`, transitionDuration: `${duration}ms` }}
        className={cn(
          'h-full bg-highlight ease-in-out dark:bg-white transition-all',
          progressClassName
        )}
      />
    </ProgressPrimitive.Root>
  )
}
