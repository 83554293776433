import { useEffect } from 'react'
import { Button } from '~/components/atomic/Button'
import { Row } from '~/styles'
import { Pagination as Props } from '~/types/table'

export default function Pagination(props: Props) {
  const {
    pageIndex,
    previousPage,
    getCanPreviousPage,
    nextPage,
    getCanNextPage,
    getPageCount,
    setPageSize,
    pageSize,
  } = props

  useEffect(() => {
    setPageSize(pageSize)
  }, [pageSize])

  return (
    <Row className='flex-1 gap-6 mt-12 pb-20'>
      <Button
        className='rounded border p-1'
        onClick={previousPage}
        disabled={!getCanPreviousPage()}
        variant={!getCanPreviousPage() ? 'disabled' : 'secondary'}
        icon='navigate_before'
      />
      {Array.from(Array(getPageCount), (_, index) => (
        <Button key={index} className='rounded border p-1' disabled={true}>
          {pageIndex + 1}
        </Button>
      ))}

      <Button
        className='rounded border p-1'
        onClick={nextPage}
        disabled={!getCanNextPage()}
        variant={!getCanNextPage() ? 'disabled' : 'secondary'}
        icon='navigate_next'
      />
    </Row>
  )
}
