import { ProjectDataFormView } from '~/components/forms/ProjectDataFormView'
import { useParams } from 'react-router-dom'
import { FormId, getFormById } from '~/models/form'

type Props = {
  formId?: FormId
}
export function DataEngagementView(props: Props) {
  const params = useParams()
  const formId = props.formId || (params.formId as FormId)
  const form = getFormById(formId)

  return <ProjectDataFormView form={form} isIntake={false} />
}
