import { FillLayer, Layer, LineLayer, Source } from 'react-map-gl'
import { FeatureCollection } from '~/utils/geo'

type Props = {
  featureCollection: FeatureCollection
  fillPaint?: FillLayer
  linePaint?: LineLayer
}

export function ReferenceRegionPolygonLayers(props: Props) {
  const { featureCollection, fillPaint, linePaint } = props

  return (
    <Source id='referenceRegion' type='geojson' data={featureCollection}>
      <Layer
        id='referenceRegion-line'
        type='line'
        paint={{
          'line-color': '#ff8550',
          'line-opacity': 1,
          'line-width': 2,
          'line-dasharray': [2, 2],
          ...linePaint,
        }}
      />
      <Layer
        id='referenceRegion-fill'
        type='fill'
        paint={{
          'fill-color': '#ff8550',
          'fill-opacity': 0,
          ...fillPaint,
        }}
      />
    </Source>
  )
}
