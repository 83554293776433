import { getModelByKey } from '~/models/landProjectArrStats'
import {
  useFinancialsStore,
  useInitFinancialStore,
} from '~/routes/project/financials/financialsStore'
import { useActiveLandProject } from '~/state'
import { Column } from '~/styles'
import { Section } from '~/styles/main'
import { VCUsARRTable } from '../components/VCUsARRTable'
import { HeaderSection } from '../components/VCUsHeader'
import { VCUsREDDTable } from '../components/VCUsREDDTable'
import { calculateLandProjectVCUStats } from '~/data/financials/vcus'

export function VCUsCalculations() {
  useInitFinancialStore()
  const { landProject } = useActiveLandProject()
  const { vcus } = useFinancialsStore()

  const { projectType, chosenModelKey } = useActiveLandProject().landProject
  const isREDD = projectType === 'redd'

  const chosenModelKeyLabel =
    getModelByKey(chosenModelKey) && getModelByKey(chosenModelKey)?.label

  const getTotalVCUs = calculateLandProjectVCUStats(landProject, vcus)

  return (
    <Section title='VCUs Calculations'>
      <Column>
        {chosenModelKeyLabel && (
          <Section.Subtitle>{chosenModelKeyLabel} Calculations</Section.Subtitle>
        )}
        <HeaderSection totalVCUs={getTotalVCUs} />
        {isREDD ? <VCUsREDDTable /> : <VCUsARRTable />}
      </Column>
    </Section>
  )
}
