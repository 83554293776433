import { Layer } from 'react-map-gl'

type Props = {
  countryCode2: string
}

export function CountryAdminLayer({ countryCode2 }: Props) {
  return (
    <Layer
      id={`admin-lines`}
      {...{
        type: 'line',
        source: 'composite',
        'source-layer': 'admin',

        paint: {
          'line-color': 'white',
          'line-opacity': 0.15,
          'line-width': 1,
        },
      }}
    />
  )
}
