import React from 'react'

import * as TooltipPrimitive from '@radix-ui/react-tooltip'

import { cn } from '~/utils/cn'

type Props = {
  children: React.ReactNode
  content: string | React.ReactNode
  delayDuration?: number
  className?: string
  maxw?: number
  open?: boolean
} & Omit<TooltipPrimitive.PopperContentProps, 'content'>

export function Tooltip(props: Props) {
  const {
    content,
    children,
    delayDuration,
    open,
    className,
    maxw,
    side = 'top',
    ...rest
  } = props

  return (
    <TooltipPrimitive.Root open={open} delayDuration={delayDuration || 100}>
      <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
      {content && (
        <TooltipPrimitive.Portal>
          <TooltipPrimitive.Content
            style={{ maxWidth: maxw || 360 }}
            className={cn(
              'rounded-md bg-white px-8 py-6 text-14 shadow-md ring-1 ring-inka-100 pointer-events-none',
              className
            )}
            sideOffset={4}
            side={side}
            {...rest}
          >
            {content}
          </TooltipPrimitive.Content>
        </TooltipPrimitive.Portal>
      )}
    </TooltipPrimitive.Root>
  )
}
