import { Circle } from '~/components/atomic/Circle'
import { SimpleTable } from '~/components/atomic/table/SimpleTable'
import TableLayout from '~/components/atomic/table/TableLayout'
import { Tabs } from '~/components/atomic/Tabs'
import { ProjectionTableData } from '~/routes/project/carbon/arr/ARRCarbonView'

import { Column, Row } from '~/styles'
import { Numerical } from '~/components/core/Numerical'
import { ResponsiveRow } from '~/styles/main'

type Props = {
  tables: ProjectionTableData[]
}

const columns = [
  {
    Header: 'Year',
    accessor: 'year',
    Cell: (p) => p.row.index + 1,
  },
  {
    Header: 'tCO2eq/ha',
    accessor: 'tCO2PerHa',
    Cell: (p) => <TableLayout.NumericalCell {...p} />,
  },
  {
    Header: 'Annual tCO2eq',
    accessor: 'annualtCO2',
    Cell: (p) => <TableLayout.NumericalCell {...p} />,
  },
  {
    Header: 'Cumulative tCO2eq',
    accessor: 'tCO2',
    Cell: (p) => <TableLayout.NumericalCell {...p} />,
  },
]

export function MultiProjectionsTables(props: Props) {
  const { tables } = props

  if (tables?.length === 0) return null

  const tabs = tables.map((table) => ({
    value: table.modelKey,
    label: (
      <Row className='row-vcenter' key={table.modelKey}>
        <Circle size={12} color={table.color} className='mr-8' />

        {table.label}
      </Row>
    ),
  }))

  const content = tables.map((table) => {
    const byYear = table.data

    return (
      <Column key={table.modelKey}>
        <ResponsiveRow className='gap-x-40 gap-y-12 mt-12 text-16'>
          <Row>
            <span className='mr-8'>30-year total CO2 sequestered</span>
            <Numerical
              value={byYear?.[byYear.length - 1]?.tCO2PerHa}
              unitRight='tCO2eq/ha'
              decimals={2}
              valueClassName='font-bold'
            />
          </Row>
          <Row>
            <span className='mr-8'>Average annual CO2 sequestered</span>
            <Numerical
              value={byYear?.[byYear.length - 1]?.tCO2PerHa / 30}
              unitRight='tCO2eq/ha'
              decimals={2}
              valueClassName='font-bold'
            />
          </Row>
        </ResponsiveRow>

        <SimpleTable
          key={table.modelKey}
          columns={columns}
          data={byYear}
          className='mt-20 w-[600px] rounded-md border-2 border-ink-200'
        />
      </Column>
    )
  })

  return (
    <Column className='w-full'>
      {tables.length > 1 ? <Tabs tabs={tabs} content={content} /> : content}
    </Column>
  )
}

export function ProjectionTableHeader(props) {
  const { byYear } = props

  return (
    <ResponsiveRow className='gap-x-40 gap-y-12 mt-12 text-16'>
      <Row>
        <span className='mr-8'>30-year total CO2 sequestered</span>
        <Numerical
          value={byYear?.[30]?.tCO2PerHa}
          unitRight='tCO2eq/ha'
          decimals={2}
          valueClassName='font-bold'
        />
      </Row>
      <Row>
        <span className='mr-8'>Average annual CO2 sequestered</span>
        <Numerical
          value={byYear?.[30]?.tCO2PerHa / 30}
          unitRight='tCO2eq/ha'
          decimals={2}
          valueClassName='font-bold'
        />
      </Row>
    </ResponsiveRow>
  )
}
