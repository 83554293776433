export type DatasetType = 'biomass' | 'landcover'

type FetchMapParams = {
  type: DatasetType
  name?: string
  urlParams?: string
}

type RasterMapLegend = {
  type: 'gradient' | 'discrete'
  items: {
    color: string
    text: string
    id: string
  }[]
}

export type Dataset = {
  label: string
  id: string
  type?: string
  fetchParams: FetchMapParams
  legend?: RasterMapLegend
  priorDateRange?: string
  currentDateRange?: string
}

export const esaLegendItems = [
  { color: '#387565', text: 'Forest', id: 'Forest' },
  { color: '#4687CE', text: 'Wetland', id: 'Wetland' },
  { color: '#5D6A35', text: 'Shrubland', id: 'Shrubland' },
  { color: '#AFDAFB', text: 'Water', id: 'Water' },
  { color: '#CCAB6F', text: 'Agriculture', id: 'Agriculture' },
  { color: '#CFDB9F', text: 'Grassland', id: 'Grassland' },
  { color: '#D3C99D', text: 'Sparse vegetation', id: 'Sparse vegetation' },
  { color: '#D75339', text: 'Settlement', id: 'Settlement' },
  { color: '#F5F0EB', text: 'Bare', id: 'Bare' },
]

export const dynamicWorldLegendItems = [
  // { color: '#419bdf', id: 'water', text: 'Water' },
  { color: '#397d49', id: 'trees', text: 'Trees' },
  { color: '#88b053', id: 'grass', text: 'Grass' },
  // { color: '#7a87c6', id: 'flooded_vegetation', text: 'Flooded Vegetation' },
  { color: '#e49635', id: 'crops', text: 'Crops' },
  { color: '#dfc35a', id: 'shrub_and_scrub', text: 'Shrub and Scrub' },
  // { color: '#c4281b', id: 'built', text: 'Built' },
  { color: '#a59b8f', id: 'bare', text: 'Bare' },
  // { color: '#b39fe1', id: 'snow_and_ice', text: 'Snow and Ice' },
]

export const biomassLegendItems = [
  { color: '#f7fcf5', text: '0' },
  { color: '#7A994F', text: '' },
  { color: '#2A4E12', text: '900' },
]

export const datasets = {
  potential_biomass: {
    label: 'Potential Biomass',
    id: 'potential_biomass',
    type: 'biomass',
    fetchParams: {
      type: 'biomass',
      urlParams: '?vis={"bands":["pot_biomass"]}',
    },
    legend: {
      type: 'gradient',
      items: biomassLegendItems,
    },
  },

  worldcover_esa: {
    label: 'ESA',
    id: 'worldcover_esa',
    fetchParams: { type: 'landcover', name: 'esa' },
    legend: {
      type: 'discrete',
      items: esaLegendItems,
    },
  },
  worldcover_dynamicworld: {
    label: 'Dynamic World',
    id: 'worldcover_dynamicworld',
    type: 'landcover',
    fetchParams: {
      type: 'landcover',
      name: 'dynamic_world',
      urlParams: '?dateRange=2022-06-01,2023-06-01',
    },
    // TODO this is terrible the ranges are super wide, we should have SimEarth be smart enough to return this info
    priorDateRange: '2015-01-01,2018-06-01',
    currentDateRange: '2021-06-01,2023-06-01',
    legend: {
      type: 'discrete',
      items: dynamicWorldLegendItems,
    },
  },
  worldcover_palsar: {
    label: 'Palsar',
    id: 'worldcover_palsar',
    type: 'landcover',
    fetchParams: { type: 'landcover', name: 'palsar' },
    legend: {
      type: 'discrete',
      items: [
        { color: '#4B7D4E', text: 'Forest' },
        { color: '#5D9DC5', text: 'Water' },
        { color: '#9DA18C', text: 'Urban' },
      ],
    },
  },
  landcover_panama: {
    label: 'Panama Regional',
    id: 'landcover_panama',
    type: 'landcover',
    fetchParams: { type: 'landcover', name: 'panama' },
  },
  landcover_mapbiomas_brazil: {
    label: 'Brazil MapBiomas',
    id: 'landcover_mapbiomas_brazil',
    type: 'landcover',
    priorDateRange: '2015-01-01,2016-03-01',
    currentDateRange: '2019-01-01,2021-01-01',
    fetchParams: { type: 'landcover', name: 'mapbiomas_brazil' },
    legend: {
      type: 'discrete',
      items: [
        { color: '#129912', text: 'Forest' },
        { color: '#006400', text: 'Forest formation' },
        { color: '#EE8C6B', text: 'Non Vegetaed area' },
        { color: '#af2a2a', text: 'Urban area' },
      ],
    },
  },
  landcover_mapbiomas_amazonia: {
    label: 'Amazonia MapBiomas',
    id: 'landcover_mapbiomas_amazonia',
    type: 'landcover',
    fetchParams: { type: 'landcover', name: 'mapbiomas_amazonia' },
  },
  landcover_jrcAnnualChange_tropical: {
    label: 'JRC Tropical Anual Change',
    id: 'landcover_jrcAnnualChange_tropical',
    type: 'landcover',
    fetchParams: { type: 'landcover', name: 'jrcAnnualChange_tropical' },
  },
  worldBiomass_walker: {
    label: 'Biomass - Walker',
    id: 'worldBiomass_walker',
    type: 'biomass',
    fetchParams: { type: 'biomass', name: 'walker' },
    legend: {
      type: 'gradient',
      items: biomassLegendItems,
    },
  },
  worldBiomass_spawn: {
    label: 'Biomass - Spawn',
    id: 'worldBiomass_spawn',
    type: 'biomass',
    fetchParams: { type: 'biomass', name: 'spawn' },
    legend: {
      type: 'gradient',
      items: biomassLegendItems,
    },
  },
} as const

export type AvailableDatasetsIds = keyof typeof datasets
