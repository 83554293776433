import React from 'react'

import { Column, Row } from '~/styles'

type Props = {
  progress: number
  size?: number
  stroke?: number
  className?: string
  background?: string // TODO force tailwind color tokens
  color?: string // TODO force tailwind color tokens
  rounded?: boolean
}

export function ProgressRing(props: Props) {
  const {
    progress,
    size = 24,
    stroke = 4,
    className = '',
    background = 'text-ink-300',
    color = 'text-highlight',
    rounded = true,
  } = props

  const radius = size / 2 - stroke
  const circumference = radius * 2 * Math.PI
  const strokeDashoffset = circumference - (progress / 100) * circumference

  return (
    <svg
      width={size}
      height={size}
      className={`transform translate-x-1 translate-y-1 -rotate-90 ${className}`}
      aria-hidden='true'
    >
      <circle
        className={background}
        strokeWidth={stroke}
        stroke='currentColor'
        fill='transparent'
        r={radius}
        cx={radius + stroke}
        cy={radius + stroke}
      />
      <circle
        className={color}
        strokeWidth={stroke}
        strokeDasharray={circumference}
        strokeDashoffset={strokeDashoffset}
        strokeLinecap={rounded ? 'round' : 'square'}
        stroke='currentColor'
        fill='transparent'
        r={radius}
        cx={radius + stroke}
        cy={radius + stroke}
      />
    </svg>
  )
}
