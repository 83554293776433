import { useState } from 'react'
import { SegmentedControl } from '~/components/atomic/SegmentedControl'
import { GraphWrapper, LineGraph } from '~/components/core/Graphs'
import {
  carbonPricePrdictions,
  CarbonPricePrediction,
  CarbonPriceScenarios,
  getCarbonScenarioPredictions,
} from '~/data/financials/carbonPricePrediction'
import { getProjectTypeLabel, ProjectType } from '~/models/landProject'
import { lowMedHighColors } from '~/styles/colors'
import { Section } from '~/styles/main'
import { useActiveLandProject } from '~/state'
import { Serie } from '@nivo/line'

const carbonPriceScenarioProjectTypeOptions = [
  { value: 'arr', label: 'ARR' },
  { value: 'redd', label: 'REDD' },
]

export function CarbonPriceScenariosSection() {
  const { landProject } = useActiveLandProject()
  const [chosenProjectType, setChosenProjectType] = useState<ProjectType>(
    landProject.projectType
  )

  const predictions = carbonPricePrdictions[chosenProjectType] as CarbonPricePrediction

  const data = []
  Object.keys(predictions).forEach((scenario: CarbonPriceScenarios) => {
    data.push(getCarbonPricePredictionSeries(chosenProjectType, scenario))
  })

  return (
    <Section>
      <GraphWrapper>
        <Section.Subtitle className='text-16 font-bold'>
          {getProjectTypeLabel(chosenProjectType)} Carbon Price Prediction (USD per tonne)
          <SegmentedControl
            onValueChange={(value: ProjectType) => setChosenProjectType(value)}
            label='Project Type'
            name='chosenProjectType'
            options={carbonPriceScenarioProjectTypeOptions}
            defaultValue={chosenProjectType}
            className='ml-20'
          />
        </Section.Subtitle>
        <LineGraph
          data={data}
          curve='linear'
          colors={lowMedHighColors}
          axisLeft={{
            format: (value) => `$${value}`,
          }}
          axisBottom={{
            tickRotation: 45,
          }}
        />
      </GraphWrapper>
    </Section>
  )
}

function getCarbonPricePredictionSeries(
  projectType: ProjectType,
  priceScenario: CarbonPriceScenarios
) {
  return {
    id: priceScenario,
    data: getCarbonScenarioPredictions(projectType, priceScenario).map((d, i) => ({
      x: i + carbonPricePrdictions.startYear,
      y: d,
    })),
  } as Serie
}
