import { VCUSRates } from '~/types/vcus'
import { ProjectType } from '~/models/landProject'
import { strings } from '~/data/strings'
import { InputsGroup, InputItem } from '~/routes/project/financials/FinancialInputs'
import { useFinancialsStore } from '~/routes/project/financials/financialsStore'
import { useActiveLandProject } from '~/state'
import { Column } from '~/styles'
import { Section } from '~/styles/main'
import { average } from '~/utils'

export type DiscountType = Array<InputItem>

export function DiscountsSection() {
  const { landProject } = useActiveLandProject()
  const { variables } = useFinancialsStore()

  const discounts = getDiscounts(variables, landProject.projectType)

  return (
    <Column>
      <Section.Subtitle>Discounts</Section.Subtitle>
      <InputsGroup items={discounts} />
    </Column>
  )
}

export const getDiscounts = (variables: VCUSRates, projectType: ProjectType) => {
  const discounts: DiscountType = [
    {
      name: 'baselineReassessmentRate',
      label: 'Baseline Reassessment',
      type: projectType === 'redd' ? 'percent' : 'number',
      value: variables?.baselineReassessmentRate,
      tooltip: strings.definitions.baselineReassessment,
    },
    {
      name: 'estimatesUncertaintyRate',
      label: 'Estimates Uncertainty',
      type: 'percent',
      value: variables?.estimatesUncertaintyRate,
      tooltip: strings.definitions.estimatesUncertainity,
    },
    {
      name: 'leakageDisccountRate',
      label: 'Leakage Factor',
      type: 'percent',
      value: variables?.leakageDisccountRate,
      tooltip: strings.definitions.leakageFactor,
    },
    {
      name: 'riskBufferRate',
      label: 'Permanence Risk Buffer',
      type: 'percent',
      value: variables?.riskBufferRate,
      tooltip: strings.definitions.riskRating,
    },
  ]

  if (projectType === 'redd')
    discounts.push({
      name: 'projectEfficiencyRate',
      label: 'Project Efficiency Average',
      type: 'percent',
      value: average(variables?.projectEfficiencyRate),
      isDisabled: true,
    })

  return discounts
}
