import { useEffect, useState } from 'react'
import { fileUpload } from '~/services/db'
import { logger } from '~/services/logger'

export function ESInputImage(props) {
  const [uploadProgress, setUploadProgress] = useState(1)
  const [storedImages, setStoredImages] = useState([])
  const [files, setFiles] = useState([])
  const addUntaggedImages = props.addUntaggedImages

  useEffect(() => {
    const tempImages = []
    if (files) {
      let tempProgress = 0
      setUploadProgress(0)
      const fileCount = files.length
      const promises = []
      Array.from(files).forEach((file) => {
        const promise = new Promise(async (resolve) => {
          let mime = file.type
          let formData = new FormData()
          formData.append('mime', mime)
          formData.append('keyType', 'imageUpload')
          formData.append('file', file)
          const res = await fileUpload('fileUpload', formData)
          if (res.valid === 1) {
            tempImages.push({ url: res.url, name: file.name })
          } else {
            logger.error('saveImageData -> Error:', 'error uploading images', res.msg)
          }
          tempProgress += 1 / fileCount
          resolve('image uploaded', tempImages)
        })
        promises.push(promise)
      })

      Promise.all(promises).then(() => {
        setStoredImages(tempImages)
        setUploadProgress(tempProgress)
      })
    }
  }, [files])

  useEffect(() => {
    addUntaggedImages((prev) => [...(prev ?? []), ...storedImages])
  }, [storedImages])

  const onChange = (evt) => {
    evt.preventDefault()
    setFiles([...evt.target.files])
  }

  return (
    <input
      type='file'
      name='images'
      className='mb-16 text-14'
      accept='.jpg, .png, .jpeg'
      onChange={onChange}
      multiple
    />
  )
}
