import { useNavigate, useParams } from 'react-router-dom'
import { CenteredLoader } from '~/components/atomic/CenteredLoader'
import { ARRSlideShowReport } from '~/routes/project/reports/ARRSlideShowReport'
import { REDDSlideShowReport } from '~/routes/project/reports/REDDSlideShowReport'
import { useFetchLandProject } from '~/services/db'
import { useActiveLandProject } from '~/state'
import { PATH } from '~/utils/constants'

type Props = {}

export function CarbonReport(props: Props) {
  const {} = props
  const params = useParams()
  const uName = params.project_slug
  useFetchLandProject(uName)
  const { landProject } = useActiveLandProject(uName)
  const navigate = useNavigate()

  if (!uName) navigate(PATH['/projects'])

  if (!landProject) return <CenteredLoader />

  if (landProject.projectType === 'redd') return <REDDSlideShowReport />
  else return <ARRSlideShowReport />
}
