import { isNil, isNumber } from '~/utils'

export function formatNumber(num, decimals = 0): string {
  num = parseFloat(num) // make sure the format is a number
  if (isNil(num)) return ''
  return `${parseFloat(num.toFixed(decimals)).toLocaleString('en')}`
}

export function parseNumber(en_formattedNumber) {
  if (isNil(en_formattedNumber)) return null
  return Number(en_formattedNumber.replace(/\,/g, ''))
}

export const formatCurrency = (num, million = false) => {
  let number
  if (million) {
    number = (num / 1_000_000).toFixed(1)
  } else {
    number = Math.round(num)
  }

  return `$ ${number.toLocaleString('en')}${million ? 'M' : ''}`
}

export function formatPercent(percent: number | string, decimals = 0): string {
  if (percent === undefined || percent === null) return ''
  percent = Number(percent)

  return percent.toFixed(isNumber(decimals) ? decimals : formatDecimals(percent))
}

export const formatArea = (areaHa: number, withUnit = true) =>
  areaHa ? `${Math.round(areaHa).toLocaleString('en')}${withUnit ? ' ha' : ''}` : ''

export const formatCO2 = (co2: number) =>
  co2 ? `${(Math.round(co2 * 10) / 10).toLocaleString('en')} tC02eq` : ''

function formatDecimals(num: number) {
  const rawDecimals = Number(countDecimals(num))
  return rawDecimals > 1 ? Math.min(rawDecimals - 2, 2) : 0
}

function countDecimals(num: number) {
  if (Math.floor(num.valueOf()) === num.valueOf()) return 0

  const str = num.toString()
  if (str.indexOf('.') !== -1 && str.indexOf('-') !== -1) {
    return str.split('-')[1] || 0
  } else if (str.indexOf('.') !== -1) {
    return str.split('.')[1].length || 0
  }
  return str.split('-')[1] || 0
}

export function percentToDiscountFactor(percent: number) {
  if (percent > 100 || percent < 0 || isNil(percent)) throw 'pct value is incorrect'
  percent = parseFloat((percent / 100).toFixed(2))
  return 1 - percent
}

export function carbonToTCO2e(AGBAndBGB: number) {
  return (AGBAndBGB * 44) / 12
}

export function cleanStringNumber(str) {
  // If a value is  like 3,000,000 it will remove the commas so it can be properly parsed
  return parseFloat(str.replace(/,/g, ''))
}

export function abbrebiateNumber(num: any, digits = 1) {
  num = parseFloat(num) // make sure the format is a number
  if (isNil(num)) return ''

  return Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: digits,
  }).format(num)
}

export function computeReforestablePercent(tCO2eq: number, reforestablePercent: number) {
  return tCO2eq * (reforestablePercent / 100 || 1)
}
