const ecosystemsMap = {
  savanna: 'Savanna',
  pasture: 'Pasture',
}
export type Ecosystem = keyof typeof ecosystemsMap
export const ecosystems = Object.keys(ecosystemsMap)
export const ecosystemsOptions = Object.entries(ecosystemsMap).map((entry) => ({
  value: entry[0] as Ecosystem,
  label: entry[1],
}))
export const getProjectStageLabel = (stage: Ecosystem) => ecosystemsMap[stage]
