import { useState, useEffect } from 'react'
import { Dialog } from '../atomic/Dialog'
import { ShareProjectInvite } from './ShareProjectInvite'
import { LandProjectUserList } from '~/components/project/LandProjectUsersList'
import { useSnapshot } from 'valtio'
import { state, storeLandProject, useActiveLandProject } from '~/state'
import { fetchDB } from '~/services/db'
import { toast } from '~/services/toaster'
import { CenteredLoader } from '../atomic/CenteredLoader'
import { logger } from '~/services/logger'
import { Column } from '~/styles'
import { CopyToClipboardButton } from '~/components/core/CopyToClipboardButton'
import { CopyIntakeLinkButton } from '~/components/project/CopyIntakeLinkButton'

export function ShareProjectDialog(props) {
  const { children } = props

  return (
    <Dialog triggerComponent={children} className='!max-w-[520px]'>
      <ShareProjectContent />
    </Dialog>
  )
}

function ShareProjectContent() {
  const [landProjectUsers, setLandProjectUsers] = useState([])
  const snap = useSnapshot(state)
  const authedUser = snap.authedUser
  const landProjectId = snap.activeLandProjectId
  const { landProject } = useActiveLandProject()
  const [mayEditUsers, setMayEditUsers] = useState(false)

  useEffect(() => {
    getLandProjectUsers()
    setMayEditUsers(
      landProject.permissions?.includes('editUser') ||
        landProject.permissions?.includes('lead')
    )
    logger.log(
      'has self permission -> ',
      landProject.permissions?.includes('editUser') ||
        landProject.permissions?.includes('lead')
    )
  }, [landProject])

  async function getLandProjectUsers() {
    const data = { landProjectId, authUserId: authedUser._id, fillUserInfo: 1 }
    const res = await fetchDB('getLandProjectUsers', data)
    if (res.valid) {
      setLandProjectUsers(res['landProjectUsers'])
      logger.log('GetLandProjectUsers -> ', res['landProjectUsers'])
    }
  }

  function onNewInvitedUser(newLandProjectUser) {
    const existingUserIndex = landProjectUsers.findIndex(
      (landProjectUser) => landProjectUser._id === newLandProjectUser._id
    )
    const newLandProjectUsers = [...landProjectUsers]

    if (existingUserIndex !== -1) {
      newLandProjectUsers.splice(existingUserIndex, 1, newLandProjectUser)
    } else {
      newLandProjectUsers.push(newLandProjectUser)
    }
    setLandProjectUsers(newLandProjectUsers)
  }

  async function onLandProjectUserPermissionUpdate(landProjectUser) {
    const data = { landProjectUser, authUserId: authedUser._id }
    const res = await fetchDB('saveLandProjectUser', data)

    if (res.valid) {
      const newLandProjectUsers = landProjectUsers.map((user) => {
        if (user._id === landProjectUser._id) {
          return landProjectUser
        } else {
          return user
        }
      })
      setLandProjectUsers(newLandProjectUsers)

      // Update self-permissions in landProject if updating self
      if (landProjectUser._id === authedUser._id) {
        updateLandProjectPermissions(landProjectUser.permissions)
      }
    } else {
      return toast.warning(res.msg)
    }
  }

  async function onLandProjectUserRemoved(landProjectUser) {
    const data = { landProjectUser, authUserId: authedUser._id }
    const res = await fetchDB('saveLandProjectUser', data)

    if (res.valid) {
      let newLandProjectUsers = [...landProjectUsers]
      newLandProjectUsers = newLandProjectUsers.filter(
        (user) => user._id !== landProjectUser._id
      )
      setLandProjectUsers(newLandProjectUsers)

      // Update self-permissions in landProject if updating self
      if (landProjectUser._id === authedUser._id) {
        updateLandProjectPermissions([])
      }
    } else {
      return toast.warning(res.msg)
    }
  }

  function updateLandProjectPermissions(newPermissions) {
    const newLandProject = { ...landProject, permissions: newPermissions }
    storeLandProject(newLandProject)
  }

  if (landProjectUsers.length < 1) return <CenteredLoader />

  return (
    <Column>
      <Dialog.CloseButton />
      {mayEditUsers ? (
        <>
          <Dialog.Title>Share access to this project</Dialog.Title>
          <Dialog.Description>
            <ol>1. Type the user email and click "invite".</ol>
            <ol>
              2. (if it's a new user) click copy invite and send that in an email. The
              proponent will have to signup and then will be sent to the intake form page.
            </ol>
          </Dialog.Description>
          <ShareProjectInvite
            landProjectUsers={landProjectUsers}
            onInviteSuccess={onNewInvitedUser}
            onLandProjectUserRemoved={onLandProjectUserRemoved}
          />
        </>
      ) : (
        <div>You don't have permission to share this project</div>
      )}
      <Dialog.Divider className='my-16' />
      <LandProjectUserList
        landProjectUsers={landProjectUsers}
        onLandProjectUserPermissionUpdate={onLandProjectUserPermissionUpdate}
        onLandProjectUserRemoved={onLandProjectUserRemoved}
      />
      {mayEditUsers && <Footer />}
    </Column>
  )
}

function Footer() {
  const { landProject } = useActiveLandProject()

  return (
    <>
      <Dialog.Divider className='my-24' />
      <CopyIntakeLinkButton landProject={landProject} className='-mb-8' />
    </>
  )
}
