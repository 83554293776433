import { Defs } from '@nivo/core'
import { ResponsiveLine, Serie } from '@nivo/line'
import { cn } from '~/utils/cn'
import { area, curveMonotoneX } from 'd3-shape'
import { useState } from 'react'
import { Checkbox } from '~/components/atomic/Checkbox'
import { Circle } from '~/components/atomic/Circle'
import { arrComputeModels } from '~/models/landProjectArrStats'
import { Row } from '~/styles'
import { graphTheme } from '~/utils/graphs'
import { abbrebiateNumber } from '~/utils/units'

type Props = {
  series: Serie[]
  hideBoundsCheckbox?: boolean
  showBounds?: boolean
  className?: string
}

export function MultiProjectionsGraph(props: Props) {
  const { series, hideBoundsCheckbox, className } = props
  const [showBounds, setShowBounds] = useState<boolean>(props.showBounds)

  const yScaleMax = showBounds ? getYScaleMax(series) : 'auto'
  let layers: any = [
    'grid',
    'markers',
    'axes',
    'areas',
    'crosshair',
    'lines',
    'points',
    'slices',
    'mesh',
    'legends',
  ]
  if (showBounds) layers = [AreaLayer, ...layers]

  return (
    <div className={cn('h-full w-full rounded-md border-2 border-ink-200', className)}>
      {!hideBoundsCheckbox && (
        <Row className='justify-end p-12 pb-0'>
          <Checkbox label='Show Bounds' onChange={() => setShowBounds(!showBounds)} />
        </Row>
      )}
      <ResponsiveLine
        colors={(d) => d.color}
        data={series}
        margin={{ top: 20, right: 20, bottom: hideBoundsCheckbox ? 50 : 80, left: 60 }}
        xScale={{ type: 'point' }}
        yScale={{
          type: 'linear',
          min: 0,
          max: yScaleMax,
          reverse: false,
        }}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'years',
          legendOffset: 36,
          legendPosition: 'middle',
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'tCO2e',
          legendOffset: -48,
          legendPosition: 'middle',
          format: (value) => abbrebiateNumber(value),
        }}
        theme={graphTheme}
        enableGridX={false}
        useMesh={true}
        isInteractive={true}
        enablePoints={false}
        pointLabelYOffset={-12}
        layers={layers}
        tooltip={({ point }) => {
          const { data, serieId } = point
          return (
            <div className='rounded-md border border-inka-150 bg-white px-12 py-8 text-13 shadow-md'>
              <Row className='row-vcenter'>
                <Circle color={point.color} className='mr-8' />
                <div className='font-bold'>{serieId}</div>
              </Row>
              <div>
                <span className='font-medium'>Year:</span>{' '}
                <span className='text-number'>{data.x as number}</span>
              </div>
              <div>
                <span className='font-medium'>tCO2e: </span>
                <span className='text-number'>{abbrebiateNumber(data.y as number)}</span>
              </div>
            </div>
          )
        }}
      />
    </div>
  )
}

const AreaLayer = ({ series, xScale, yScale, innerHeight }) => {
  const areaGenerator = area()
    .x((d: any) => {
      return xScale(d.data.x)
    })
    .y0((d: any) => Math.min(innerHeight, yScale(d.data.min)))
    .y1((d: any) => yScale(d.data.max))
    .curve(curveMonotoneX)

  return series.map((serie) => {
    const patternId = `pattern-${serie.modelKey}`
    const model = arrComputeModels.find((i) => i.key === serie.modelKey)

    if (model.skipBounds) return null

    return (
      <g key={patternId}>
        <Defs
          defs={[
            {
              id: patternId,
              type: 'patternLines',
              color: serie.color,
              background: 'transparent',
              lineWidth: 0.7,
              spacing: 4,
              rotation: -45,
            },
          ]}
        />
        <path
          d={areaGenerator(serie.data)}
          fill={`url(#${patternId})`}
          fillOpacity={0.3}
        />
        <path d={areaGenerator(serie.data)} fill={serie.color} fillOpacity={0.1} />
      </g>
    )
  })
}

function getYScaleMax(series: Serie[]) {
  let max = 0
  series.forEach((serie) => {
    const model = arrComputeModels.find((i) => i.key === serie.modelKey)

    serie.data.forEach((d) => {
      // If we don't need to skips bound then see if max is available, otherwise default to the mean
      const dMax = (!model.skipBounds && d.max) || d.y
      if (dMax > max) max = dMax
    })
  })

  return max
}
