import { saveAs } from 'file-saver'
import { Button } from '~/components/atomic/Button'
import { useConfirmDialog } from '~/components/dialogs/ConfirmDialog'
import { Dropdown } from '~/components/ui-custom/Dropdown'
import { saveProjectWithParcels } from '~/services/db'
import { useActiveLandProject } from '~/state'
import ESHttp from '~/utils/ESHttp'
import { cn } from '~/utils/cn'

type Props = {
  className?: string
}

export function LandPopover(props: Props) {
  const { className } = props

  return (
    <Dropdown content={<Content />}>
      <Button className={cn('!p-4', className)} icon='more_horiz' />
    </Dropdown>
  )
}

function Content() {
  const { landProject, parcels } = useActiveLandProject()

  return (
    <>
      <DownloadButton fileName={landProject.name} parcels={parcels} />
      <ClearParcelsButton />
    </>
  )
}

function DownloadButton({ fileName, parcels }) {
  const onClickDownloadParcel = (evt) => {
    evt.preventDefault()
    const data = { parcels: parcels, fileName }
    ESHttp.Go('POST', 'generateGeojson', data)
      .then((res) => res.json())
      .then((url) => {
        if (url['valid'] === 1) {
          saveAs(url.fileUrl, fileName)
        }
      })
  }
  return (
    <Dropdown.Item icon='file_download' onClick={onClickDownloadParcel}>
      Download All Parcels
    </Dropdown.Item>
  )
}

function ClearParcelsButton() {
  const { landProject } = useActiveLandProject()

  const { setConfirmDialog, closeConfirmDialog } = useConfirmDialog()

  const deleteParcels = async () => {
    await saveProjectWithParcels(landProject, [])
    // Hack to force re-render of ParcelsList
    // TODO create a endpoint to delete all parcels
    // OR think about how we're currently not updating/storing parcels on onProjectSaved()
    // if the parcels return as an empty array. Which is what were' deliberately doing here.
    window.location.reload()
    closeConfirmDialog()
  }

  const onClickDeleteProject = () => {
    setConfirmDialog({
      content: `Are you sure you want to clear all parcels?`,
      onConfirm: () => deleteParcels(),
      confirmText: 'Delete',
      confirmVariant: 'destructive',
    })
  }

  return (
    <Dropdown.Item icon='delete' className='text-red-500' onClick={onClickDeleteProject}>
      Delete all parcels
    </Dropdown.Item>
  )
}
