import { Navigate, Route, Routes } from 'react-router-dom'
import '~/common/styles/display.css'
import '~/common/styles/flexbox.css'
import '~/common/styles/spacing.css'
import { LayoutWithSideMenu } from '~/components/core/LayoutWithSideMenu'
import { ImageFilesSection } from '~/components/firebaseBiome/sections/ImageFilesSection'
import { TreesMapSection } from '~/components/firebaseBiome/sections/TreesMapSection'
import { Page } from '~/components/layouts/Page'
import { PATH } from '~/utils/constants'

const menu = [
  { to: 'trees-map', label: 'Trees map' },
  { to: 'image-files', label: 'Image files' },
]

export function FirebaseBiome() {
  return (
    <Page title='Biome'>
      <LayoutWithSideMenu title='Biome' menu={menu}>
        <Routes>
          <Route index element={<Navigate to={PATH['trees-map']} />} />
          <Route path={PATH['trees-map']} element={<TreesMapSection />} />
          <Route path={PATH['image-files']} element={<ImageFilesSection />} />
        </Routes>
      </LayoutWithSideMenu>
    </Page>
  )
}
