import { cn } from '~/utils/cn'
import { useEffect, useMemo, useRef, useState } from 'react'
import Map, { Marker } from 'react-map-gl'
import { MapDot } from '~/components/map/MapDot'
import { MAPBOX_TOKEN } from '~/services/mapbox'

import { Row } from '~/styles'
import * as turf from '@turf/turf'
import { MapPopup } from '~/components/map/MapPopup'
import { formatNumber } from '~/utils/units'
import { easings } from '~/utils'
import { Tree } from '~/models/biome'
import { MapSources } from '~/components/map/layers/MapSources'
import { BaseMap } from '~/components/map/BaseMap'
import { CountryLabelsLayer } from '~/components/map/layers/CountryLabelsLayer'
import { CountriesBounds } from '~/components/map/layers/CountriesBounds'

type Props = {
  treesData: any
  className?: string
}

export function TreesMap(props: Props) {
  const { treesData, className } = props

  const mapRef = useRef<any>()

  const trees = treesData.treeInfos

  const [selectedTree, setSelectedTree] = useState(null)

  function onLoad() {
    if (!mapRef.current) return
    fitTrees()
  }

  function fitTrees() {
    const bbox = getTreesBounds(trees)
    mapRef.current.fitBounds(bbox, {
      padding: 100,
      animate: true,
      ...{ duration: 1800, easing: easings.easeInQuart },
    })
  }

  useEffect(() => {
    if (!mapRef.current) return null
    fitTrees()
  }, [trees])

  const pins = useMemo(
    () =>
      trees?.map((marker, index) => {
        return (
          <Marker
            key={`marker-${index}`}
            longitude={marker.longitude}
            latitude={marker.latitude}
            anchor='center'
          >
            <MapDot
              color='red'
              onClick={() => {
                setTimeout(() => {
                  setSelectedTree({
                    ...trees.find((t) => t.uuid == marker.uuid),
                    lat: marker.latitude,
                    long: marker.longitude,
                  })
                }, 50)
              }}
            />
          </Marker>
        )
      }),
    [trees]
  )

  return (
    <div className={cn('h-full w-full bg-ink-200', className)}>
      <BaseMap
        ref={mapRef}
        // controls={controls}
        onLoad={onLoad}
        initialCountryCode3={'PAN'}
      >
        <MapSources satellite composite />

        {pins}

        {selectedTree && (
          <MapPopup
            anchor='bottom'
            longitude={selectedTree.longitude}
            latitude={selectedTree.latitude}
            closeButton={false}
            focusAfterOpen={false}
            onClose={() => setSelectedTree(null)}
          >
            <TreePopup tree={selectedTree} />
          </MapPopup>
        )}
        {/* <RasterLayers layers={landStore.mapLayers} /> */}

        <CountryLabelsLayer />
        <CountriesBounds variant='satellite' />
        {/* <PolygonLayers layers={layefrs} /> */}
      </BaseMap>

      {/* <Map
        id='main'
        ref={mapRef}
        attributionControl={false}
        fadeDuration={100}
        mapboxAccessToken={MAPBOX_TOKEN}
        style={{ width: '100%', height: '100%', opacity: 1 }}
        mapStyle='mapbox://styles/mapbox/satellite-v9'
        cursor={'default'}
        maxZoom={20}
        onLoad={onLoad}
        initialViewState={{ zoom: 1 }}
      >
        {pins}

        {selectedTree && (
          <MapPopup
            anchor='bottom'
            longitude={selectedTree.longitude}
            latitude={selectedTree.latitude}
            closeButton={false}
            focusAfterOpen={false}
            onClose={() => setSelectedTree(null)}
          >
            <TreePopup tree={selectedTree} />
          </MapPopup>
        )}
      </Map> */}
    </div>
  )
}

function getTreeImageUrl(tree: Tree, isMask = false) {
  const type = isMask ? 'nn-mask-invalid.png' : 'profile.jpg'
  // const imageUrl = `v${tree.appVersion}%2F${tree.uuid}%2F${type}`
  const imageUrl = `eco-venao/${tree.uuid}/${type}`

  return `https://firebasestorage.googleapis.com/v0/b/biome-app-2.appspot.com/o/eco-venao%2F${tree.uuid}%2F${type}?alt=media&token=fb099c24-cd6e-44c2-a5ef-c2a7771a6964`
  // return `https://storage.googleapis.com/download/storage/v1/b/biome-app-2.appspot.com/o/${imageUrl}?alt=media`
}

function TreePopup(props: { tree: Tree }) {
  const [showMask, setShowMask] = useState(false)
  const { tree } = props

  const imageUrl = getTreeImageUrl(tree)
  const maskUrl = getTreeImageUrl(tree, true)
  console.log('maskUrl: ', maskUrl)

  return (
    <MapPopup.Content className='p-12 text-14'>
      <div
        className='relative mb-8 h-[200px] w-[200px] overflow-hidden rounded-sm bg-ink-200'
        onClick={() => setShowMask(!showMask)}
      >
        <img src={imageUrl} className='absolute h-full w-full' />

        <img
          src={maskUrl}
          className='absolute h-full w-full mix-blend-screen'
          style={{
            opacity: showMask ? 0.7 : 0,
            filter:
              'invert() brightness(0.3) sepia(1) saturate(10) hue-rotate(-394deg) brightness(1.4)',
          }}
        />
      </div>

      <Row className='space-x-4'>
        <strong>DBH:</strong> <span>{formatNumber(tree.diameterCalculated, 2)} cm</span>
      </Row>

      {!!tree.heightCalculated && (
        <Row className='space-x-4'>
          <strong>Height:</strong> <span>{formatNumber(tree.heightCalculated, 2)} m</span>
        </Row>
      )}
      {/* <Row className='space-x-4'>
        <strong>Pixel Width:</strong> <span>{formatNumber(tree.pixelWidth, 2)}</span>
      </Row> */}
      {/* <Row className='space-x-4'>
        <strong>Distance:</strong> <span>{formatNumber(tree.distance, 2)} m</span>
      </Row> */}
    </MapPopup.Content>
  )
}

function getTreesBounds(trees) {
  if (!trees) return null
  const pointsPolygon = turf.featureCollection(
    trees.map((t) => turf.point([t.longitude, t.latitude]))
  )
  const bounds = turf.bbox(pointsPolygon) as any
  return bounds
}
