import { cn } from '~/utils/cn'

type CircleProps = {
  size?: number
  color?: string
  className?: string
}
export function Circle(props: CircleProps) {
  const { size = 12, color = 'black', className, ...rest } = props

  return (
    <div
      className={cn('rounded-full', className)}
      style={{
        width: size,
        height: size,
        minWidth: size,
        minHeight: size,
        background: color,
      }}
      {...rest}
    />
  )
}
