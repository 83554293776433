import { cn } from '~/utils/cn'
import React from 'react'
import TextareaAutosize, { TextareaAutosizeProps } from 'react-textarea-autosize'
import classed from '~/styles/classed'

export type Props = {
  label?: string
  className?: string
  inputClassName?: string
  unitRight?: string
  type?: string | number
} & Partial<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
>
export default function Input(props: Props) {
  const {
    label,
    name,
    className = '',
    inputClassName,
    unitRight,
    disabled,
    ...rest
  } = props

  return (
    <div className={`flex flex-col ${className}`}>
      {label && (
        <InputLabel className='mb-4' htmlFor={name}>
          {label}
        </InputLabel>
      )}
      <div className={`center relative flex flex-1 flex-row ${inputTextClasses}`}>
        <input
          disabled={disabled}
          name={name}
          className={cn(
            inputBoxClasses,
            inputTextClasses,
            { 'pr-48 text-right': unitRight },
            inputClassName,
            disabled && 'text-ink-400'
          )}
          style={{
            paddingRight: unitRight ? `${12 + 8 + unitRight.length * 7}px` : undefined,
          }}
          {...rest}
        />
        <span className='pointer-events-none absolute right-12 text-13 opacity-50'>
          {unitRight}
        </span>
      </div>
    </div>
  )
}

export const inputTextClasses = 'text-14 placeholder:text-ink-400'

export const focusClasses = 'ring-indigo-500 border-indigo-500'
export const errorClasses = 'ring-red-500 border-red-500'

export const inputBoxClasses =
  'p-8 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full border border-ink-300 rounded-md'

export const inputClasses = `${inputBoxClasses} ${inputTextClasses}`

const InputLabel = classed('label', `block text-14 font-medium text-ink-700 `)
Input.Label = InputLabel

type TextareaProps = {
  id?: string
  name: string
  label?: string
  className?: string
  textareaClassName?: string
  defaultValue?: string
  placeholder?: string
  minRows?: number
  ref?: any
} & Omit<TextareaAutosizeProps, 'ref'>

export const Textarea = (React.forwardRef as any)((props: TextareaProps, ref) => {
  const { label, className = '', name, textareaClassName, minRows = 3, ...rest } = props

  return (
    <div className={`group flex flex-col ${className}`}>
      {label && (
        <InputLabel className='mr-4 mb-8' htmlFor={name}>
          {label}
        </InputLabel>
      )}
      <TextareaAutosize
        minRows={minRows}
        name={name}
        className={cn(inputBoxClasses, inputTextClasses, textareaClassName)}
        {...rest}
        ref={ref}
      />
    </div>
  )
})
