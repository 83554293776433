import React from 'react'
import { Column } from '~/styles'
import { Option } from '~/types'

export type OnChange = (option: { name: string; value: string }) => void

type Props = {
  defaultValue: string
  name: string
  options: Option[]
  label?: string
  onChange?: OnChange
}

export function RadioGroup(props: Props) {
  const { name, options, label, defaultValue, onChange, ...rest } = props

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target
    if (onChange) {
      onChange({ value, name })
    }
  }

  return (
    <fieldset name={name} className='relative' {...rest}>
      <legend className='sr-only'>{label}</legend>
      <Column className='divide-y rounded-md border'>
        {options.map((option, key) => {
          const { value, label } = option
          const checked = value === defaultValue

          const id = `${name}_${value}`

          return (
            <label
              key={key}
              className='row p-8 duration-100 hover:bg-ink-500 hover:bg-opacity-10'
              htmlFor={id}
            >
              <input
                type='radio'
                id={id}
                name={name}
                value={value}
                defaultChecked={checked}
                onChange={handleChange}
                className='mt-2 h-16 w-16 border-ink-300 text-brand focus:ring-brand'
              />
              <label htmlFor={id} className='ml-12 select-none text-14'>
                {label}
              </label>
            </label>
          )
        })}
      </Column>
    </fieldset>
  )
}
