import { createColumnHelper } from '@tanstack/react-table'
import { ComplexTable } from '~/components/atomic/table/ComplexTable'
import TableLayout from '~/components/atomic/table/TableLayout'
import { REDDLandProjectYearlyAllScenarios } from '~/models/landProjectReddStats'
import { ReportSectionData } from '~/types'

type Props = {
  sectionData: ReportSectionData
}

const columnHelper = createColumnHelper<REDDLandProjectYearlyAllScenarios>()

export function AnnualDeforestationTable(props: Props) {
  const { table } = props.sectionData

  const columns = [
    {
      id: 'yearDetails',
      header: '',
      meta: { align: 'center' },
      columns: [
        columnHelper.accessor('year', {
          header: 'Year',
          cell: (props) => {
            return (
              <TableLayout.NumericalCell
                type='unformatted'
                value={props.getValue()}
                className={'font-bold'}
              />
            )
          },
        }),
        columnHelper.accessor('yearDate', {
          header: 'Year Date',
          cell: (props) => {
            return (
              <TableLayout.NumericalCell
                type='unformatted'
                value={props.getValue()}
                className={'font-bold'}
              />
            )
          },
        }),
      ],
    },
    {
      header: 'Forest Cover (ha)',
      meta: { align: 'center' },
      columns: [
        columnHelper.accessor('annualForestCoverHa', {
          id: 'avg.',
          header: 'Avg. Rate',
          meta: { align: 'center' },
          cell: (props) => (
            <TableLayout.NumericalCell
              type='number'
              value={props.getValue()}
              className={'italic border-l'}
            />
          ),
        }),
        columnHelper.accessor('annualForestCoverHaUnderProjectAreaRate', {
          header: 'Project Area Rate',
          meta: { align: 'center' },
          cell: (props) => (
            <TableLayout.NumericalCell
              type='number'
              value={props.getValue()}
              className={'text-orange-700'}
            />
          ),
        }),
        columnHelper.accessor('annualForestCoverHaUnderReferenceRegionRate', {
          header: '20km Reference Region Rate',
          meta: { align: 'center' },
          cell: (props) => (
            <TableLayout.NumericalCell
              type='number'
              value={props.getValue()}
              className={'text-purple-900 border-r'}
            />
          ),
        }),
        columnHelper.accessor('annualForestCoverHaUnderReferenceRegionRate5km', {
          header: '5km Reference Region Rate',
          meta: { align: 'center' },
          cell: (props) => (
            <TableLayout.NumericalCell
              type='number'
              value={props.getValue()}
              className={'text-purple-900 border-r'}
            />
          ),
        }),
      ],
    },
    {
      header: 'Forest Loss (ha)',
      meta: { align: 'center' },
      columns: [
        columnHelper.accessor('annualDeforestationHa', {
          header: 'Avg. Rate',
          meta: { align: 'center' },
          cell: (props) => (
            <TableLayout.NumericalCell
              type='number'
              value={props.getValue()}
              className={'italic border-l'}
            />
          ),
        }),
        columnHelper.accessor('annualDeforestationHaUnderProjectAreaRate', {
          header: 'Project Area Rate',
          meta: { align: 'center' },
          cell: (props) => (
            <TableLayout.NumericalCell
              type='number'
              value={props.getValue()}
              className={'text-orange-700'}
            />
          ),
        }),
        columnHelper.accessor('annualDeforestationHaUnderReferenceRegionRate', {
          header: '20km Reference Region Rate',
          meta: { align: 'center' },
          cell: (props) => (
            <TableLayout.NumericalCell
              type='number'
              value={props.getValue()}
              className={'text-purple-900 border-r'}
            />
          ),
        }),
        columnHelper.accessor('annualDeforestationHaUnderReferenceRegionRate5km', {
          header: '5km Reference Region Rate',
          meta: { align: 'center' },
          cell: (props) => (
            <TableLayout.NumericalCell
              type='number'
              value={props.getValue()}
              className={'text-purple-900 border-r'}
            />
          ),
        }),
      ],
    },
    {
      header: 'Annual GHG Emissions (tCO2)',
      meta: { align: 'center' },
      colSpan: 3,
      columns: [
        columnHelper.accessor('tCO2AnnualAvoidedEmissions', {
          header: 'Avg. Rate',
          meta: { align: 'center' },
          cell: (props) => (
            <TableLayout.NumericalCell
              type='number'
              value={props.getValue()}
              className={'italic border-l'}
            />
          ),
        }),
        columnHelper.accessor('tCO2AnnualAvoidedEmissionsUnderProjectAreaRate', {
          header: 'Project Area Rate',
          meta: { align: 'center' },
          cell: (props) => (
            <TableLayout.NumericalCell
              type='number'
              value={props.getValue()}
              className={'text-orange-700'}
            />
          ),
        }),
        columnHelper.accessor('tCO2AnnualAvoidedEmissionsUnderReferenceRegionRate', {
          header: '20km Reference Region Rate',
          meta: { align: 'center' },
          cell: (props) => (
            <TableLayout.NumericalCell
              type='number'
              value={props.getValue()}
              className={'text-purple-900 border-r'}
            />
          ),
        }),
        columnHelper.accessor('tCO2AnnualAvoidedEmissionsUnderReferenceRegionRate5km', {
          header: '5km Reference Region Rate',
          meta: { align: 'center' },
          cell: (props) => (
            <TableLayout.NumericalCell
              type='number'
              value={props.getValue()}
              className={'text-purple-900 border-r'}
            />
          ),
        }),
      ],
    },
    {
      id: 'Cumulative Emissions',
      header: () => (
        <TableLayout.HeaderWithTooltip tooltip='Cummulative annual emissions based in average, project area, or reference region deforestation rates'>
          Cumulative Emissions (tCO2)
        </TableLayout.HeaderWithTooltip>
      ),
      meta: { align: 'center' },
      colSpan: 3,
      columns: [
        columnHelper.accessor('tCO2', {
          header: 'Avg. Rate',
          meta: { align: 'center' },
          cell: (props) => (
            <TableLayout.NumericalCell
              type='number'
              value={props.getValue()}
              className={'italic border-l'}
            />
          ),
        }),
        columnHelper.accessor('tCO2UnderProjectAreaRate', {
          header: 'Project Area Rate',
          meta: { align: 'center' },
          cell: (props) => (
            <TableLayout.NumericalCell
              type='number'
              value={props.getValue()}
              className={'text-orange-700'}
            />
          ),
        }),
        columnHelper.accessor('tCO2UnderReferenceRegionRate', {
          header: '20km Reference Region Rate',
          meta: { align: 'center' },
          cell: (props) => (
            <TableLayout.NumericalCell
              type='number'
              value={props.getValue()}
              className={'text-purple-900 border-r'}
            />
          ),
        }),
        columnHelper.accessor('tCO2UnderReferenceRegionRate5km', {
          header: '5km Reference Region Rate',
          meta: { align: 'center' },
          cell: (props) => (
            <TableLayout.NumericalCell
              type='number'
              value={props.getValue()}
              className={'text-purple-900 border-r'}
            />
          ),
        }),
      ],
    },
  ]
  return <ComplexTable columns={columns} data={table.data} />
}
