import { cn } from '~/utils/cn'
import React, { Children, PropsWithChildren, useEffect, useRef, useState } from 'react'
import Avatar from '~/components/atomic/Avatar'
import { Button } from '~/components/atomic/Button'
import { CheckboxGroup } from '~/components/atomic/CheckboxGroup'
import Input, { Textarea } from '~/components/atomic/Input'
import { RadioGroup } from '~/components/atomic/RadioGroup'
import SelectCountry from '~/components/core/SelectCountry'
import { useUnsavedBlockerPrompt } from '~/components/dialogs/ConfirmDialog'
import { LandProject } from '~/models'
import { UpdatedBy, getUpdatedByMetaData } from '~/models/authedUser'
import { getProjectTypeLabel } from '~/models/landProject'
import {
  DatumField,
  getDatumFieldById,
  LandProjectDatums,
  matchesCondition,
} from '~/models/projectDatum'
import { saveProjectData, saveProjectDatums } from '~/services/db'
import { useActiveLandProject } from '~/state/index'
import { Column, Row } from '~/styles'
import classed from '~/styles/classed'
import { getFormData } from '~/utils'
import { timeDifference } from '~/utils/times'

type ProjectDataFormProps = PropsWithChildren<{
  parseFormData?: (
    form: HTMLFormElement,
    landProject: LandProject
  ) => Partial<LandProject>
  saveTarget: 'root' | 'datums'
}>

let formStateSnapshot: string // Strningified form state

export function ProjectDataForm(props: ProjectDataFormProps) {
  const { children, parseFormData, saveTarget } = props

  const { landProject } = useActiveLandProject()
  const [formHasChanges, setFormHasChanges] = useState<boolean>(false)
  const [saving, setSaving] = useState<boolean>(false)
  const [isMounted, setIsMounted] = useState<boolean>(false)
  const formRef = useRef<HTMLFormElement>(null)

  useEffect(() => {
    setIsMounted(true)
    resetFormState()
    formRef.current.reset()

    return () => {
      setIsMounted(false)
    }
  }, [])

  // We want to prvent users from leaving the page without saving their changes
  // This hook triggers when the user navigates away from the current location
  // If the form has changes from the last snapshot, it will prompt the user to save
  useUnsavedBlockerPrompt({
    onConfirm: async () => {
      await saveFormData(formRef.current)
    },
    onCancel: async () => {
      // Don't trigger local state changes if the component is not mounted
      if (!isMounted) return
      await updateFormStateSnapshot(formRef.current)
    },
    shouldShowPrompt: formHasChanges,
  })

  async function onBlur(e: React.FocusEvent<HTMLFormElement>) {
    // Takes snapshot of form state and compares with the last snapshot
    // If the form state has changed, set formHasChanges to true (the usal term is "dirty" state)
    const currentData = getFormStateSnapshot(formRef.current)
    if (formStateSnapshot != currentData) {
      setFormHasChanges(true)
    }
  }

  async function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    saveFormData(e.currentTarget)
  }

  async function saveFormData(form: HTMLFormElement) {
    setSaving(true)

    switch (saveTarget) {
      case 'root':
        const partialLandProject = getFormData(form)
        await saveProjectData(partialLandProject, landProject._id)
        break
      case 'datums':
        const datums = parseDatumsForm(form, landProject)
        await saveProjectDatums(datums, landProject._id)
        break
    }

    resetFormState()
  }

  function updateFormStateSnapshot(form: HTMLFormElement) {
    formStateSnapshot = getFormStateSnapshot(form)
    setFormHasChanges(false)
  }

  function getFormStateSnapshot(form: HTMLFormElement) {
    // Stringifies the form state so we can do an easy deep comparison
    return JSON.stringify(getFormData(form))
  }

  function resetFormState() {
    updateFormStateSnapshot(formRef.current)
    setSaving(false)
  }

  return (
    <form ref={formRef} onSubmit={onSubmit} onBlur={onBlur}>
      <Column className='p-16 md:m-24 md:gap-32 pb-80'>{children}</Column>

      <SaveRow submitting={saving} needsSaving={formHasChanges} />
    </form>
  )
}

function parseDatumsForm(form: HTMLFormElement, landProject: LandProject) {
  const formData = getFormData(form) as { [key: string]: any }
  const datums: LandProjectDatums = {}

  for (const datumKey in formData) {
    const value = formData[datumKey]

    const hasChanged = landProject.data?.[datumKey]?.value != value

    datums[datumKey] = {
      ...landProject.data?.[datumKey],
      value,
    }

    if (hasChanged) {
      datums[datumKey].updatedBy = getUpdatedByMetaData()
    }
  }

  return datums
}

export function Fields(props: { fields: DatumField[]; children?: any }) {
  const { children } = props

  const [datumFields, setDatumFields] = useState(props.fields)

  useEffect(() => {
    setDatumFields(props.fields)
  }, [props.fields])

  function onChangeField(newField: DatumField) {
    const newFields = datumFields.map((field) =>
      field.id === newField.id ? newField : field
    )
    setDatumFields(newFields)
  }

  return (
    <Column className='divide-y divide-dashed'>
      {Children.map(children, (child) => {
        return (
          <FieldContainer key={child.props.id || child.props.name}>
            {child}
          </FieldContainer>
        )
      })}

      {datumFields.map((datumField: DatumField) => {
        const key = datumField.id || datumField.name || datumField.label

        // Evaluate if the datumField should be skipped based on condition parameters
        // eg. this field should only show if another one has a value
        if (datumField.condition) {
          const targetField = getDatumFieldById(
            datumFields,
            datumField.condition.targetFieldId
          )
          const shouldSkipField =
            targetField && !matchesCondition(targetField, datumField)
          if (shouldSkipField) return null
        }

        return (
          <FieldContainer key={key}>
            <Field datumField={datumField} onChangeField={onChangeField} />
          </FieldContainer>
        )
      })}
    </Column>
  )
}

const FieldContainer = classed('div', 'group py-16')

export function Field(props: {
  datumField: DatumField
  onChangeField?: (datumField: DatumField) => void
}) {
  const { datumField, onChangeField } = props
  const {
    id,
    placeholder,
    type,
    name,
    label,
    value,
    options,
    updatedBy,
    exclusiveToProjectType,
  } = datumField

  const inputProps = {
    id,
    placeholder,
    name,
    defaultValue: value,
  }

  const fieldGroupProps = {
    name,
    label: label,
    description: datumField.description,
    updatedBy,
    exclusiveToProjectType,
  }

  function onChange(newValue: any) {
    const newField: DatumField = { ...datumField }
    newField.value = newValue
    onChangeField(newField)
  }

  const isShort = type === 'number' || type === 'percentage'

  const unit = datumField.unit || (type === 'percentage' ? '%' : '' || null)

  if (type === 'separator') {
    return <div className='border border-b border-ink-100' />
  }
  if (type === 'section') {
    return <h2 className='mt-40 text-32 font-bold group-first:mt-0'>{label}</h2>
  }

  if (type === 'title') {
    return <h2 className='text-24 font-semibold'>{label}</h2>
  }

  if (type === 'country' || name === 'country' || name === 'countryCode') {
    // TODO unify these into a single type
    return (
      <FieldGroup {...fieldGroupProps}>
        <SelectCountry {...inputProps} />
      </FieldGroup>
    )
  }

  if (type === 'paragraph') {
    return (
      <FieldGroup {...fieldGroupProps}>
        <Textarea {...inputProps}></Textarea>
      </FieldGroup>
    )
  }

  if (options) {
    const optionsProps = {
      name: name,
      options: options,
      defaultValue: datumField.value,
    }

    return (
      <FieldGroup {...fieldGroupProps}>
        <Column>
          {datumField.multiple || type === 'multiple-options' ? (
            // TODO stop using multiple props
            <CheckboxGroup onChange={onChange} {...optionsProps} />
          ) : (
            <RadioGroup onChange={(option) => onChange(option.value)} {...optionsProps} />
          )}
        </Column>
      </FieldGroup>
    )
  }

  const isNumber = ['percentage', 'number'].includes(type)

  return (
    <FieldGroup {...fieldGroupProps}>
      <Input
        {...inputProps}
        className={cn('!flex-row', { 'max-w-[180px]': isShort })}
        inputClassName={cn({ 'font-mono !text-right': isNumber })}
        onChange={(e) => onChange(e.currentTarget.value)}
        autoComplete='off'
        type={isNumber ? 'number' : 'text'}
        unitRight={unit}
        step='0.0001'
      />
    </FieldGroup>
  )
}

type SaveRowProps = {
  submitting: boolean
  needsSaving?: boolean
  onClick?: (e) => void
}

export function SaveRow({ submitting, onClick, needsSaving }: SaveRowProps) {
  return (
    <Row className='row-vcenter fixed bottom-0 w-full border-t border-t-ink-200 bg-white p-16 md:px-40 md:py-20'>
      <Button submitting={submitting} variant='primary' type='submit' onClick={onClick}>
        {submitting ? 'Saving' : 'Save'}
      </Button>
      {needsSaving && <div className='ml-20'>Save to keep your changes</div>}
    </Row>
  )
}

export function FieldGroup(props: PropsWithChildren<DatumField>) {
  const { name, description, label, exclusiveToProjectType, children } = props
  return (
    <div className='col md:grid md:grid-cols-[4fr,7fr] gap-8 md:gap-20'>
      <Column>
        <Input.Label className='mt-8 mb-0' htmlFor={name}>
          {label}
        </Input.Label>
        {description && (
          <p className='mt-4 text-14 leading-relaxed text-ink-500'>{description}</p>
        )}
        {exclusiveToProjectType && (
          <span className='text-13 text-ink-500 mt-8'>
            (Only for {getProjectTypeLabel(exclusiveToProjectType)})
          </span>
        )}

        <FieldUpdated updatedBy={props.updatedBy} />
      </Column>
      <Column>{children}</Column>
    </div>
  )
}

function FieldUpdated(props: { updatedBy?: UpdatedBy }) {
  if (!props.updatedBy) return null

  const { timestamp, userName, userFirstName } = props.updatedBy
  const name = userFirstName || userName

  const diff = timeDifference(new Date().getTime(), new Date(timestamp).getTime())

  return (
    <Row
      className='row-vcenter invisible mt-4
        -mb-12 self-start rounded-md bg-white p-4 px-6 text-12 text-ink-500 ring-1 ring-ink-200 group-hover:visible'
    >
      {/* <span className="mr-4">Updated</span> */}
      {diff && <time>{diff}</time>}
      <span className='ml-4'>by</span>
      <Avatar className='mx-6' size={16} name={name} />
      {name}
    </Row>
  )
}
