import React, { useState } from 'react'
import { ErrorMessage } from '~/components/atomic/ErrorMessage'
import { Button } from '~/components/atomic/Button'
import { getLandProjectPolygonArea } from '~/models/landProject'
import { DialogContentProps } from '~/routes/project/carbon/arr/dialogs/ProjectionInputsDialogs'
import { state, useActiveLandProject } from '~/state'
import { toggleDialog, storeProjection } from '~/routes/project/carbon/arr/arrCarbonStore'
import { Column } from '~/styles'
import { landProjectStatToProjection } from '~/models/projection'
import { fetchDB, fileUpload } from '~/services/db'
import { Dialog } from '~/components/atomic/Dialog'

export function CommunityLandModelDialog(props: DialogContentProps) {
  const { carbonModelKey } = props

  function UploadCLMProjection(props: { onFileUploaded?: () => void }) {
    const { landProject } = useActiveLandProject()
    const { onFileUploaded } = props
    const [error, setError] = useState(null)
    const inputRef = React.useRef<HTMLInputElement>(null)
    const [uploading, setUploading] = React.useState(false)
    const [formVals, setFormVals] = useState({
      landProjectId: state.activeLandProjectId,
      landProjectArea: String(getLandProjectPolygonArea(landProject)),
      fileData: null,
      fileType: '',
    })
    // console.log(formVals)  // for debugging

    function onClickUpload(e: React.MouseEvent<HTMLButtonElement>) {
      e.preventDefault()
      inputRef.current.click()
    }

    // Retain file info from user-selected file and post file to backend
    async function onUploadCLMHistory(e) {
      e.preventDefault()

      const file = e.target.files[0]
      const extension = getFileExtension(file.name)
      setFormVals((formVals) => {
        formVals.fileType = extension
        formVals.fileData = file
        return formVals
      })

      const formData = new FormData()
      formData.append('mime', 'application/x-netcdf')
      formData.append('keyType', 'clmHistoryUpload')
      formData.append('file', file)
      formData.append('landProjectId', formVals.landProjectId)
      formData.append('landProjectArea', formVals.landProjectArea)

      try {
        setUploading(true)

        await fileUpload('fileUpload', formData)

        const projectStatsData = await fetchDB('getLandProjectStatsCache', {
          landProjectId: formVals.landProjectId,
          withYears: true,
        })

        const communityLandProjectStats = projectStatsData.landProjectStats.find(
          (obj) => obj.snapshotMainInterventionKey == 'reforestation_communityLandModel'
        )
        const projection = landProjectStatToProjection(
          communityLandProjectStats,
          landProject
        )
        storeProjection(projection)
        setUploading(false)
        Dialog.close()
      } catch (error) {
        setUploading(false)
        setError(error.toString())
      }
    }

    return (
      <>
        <input
          ref={inputRef}
          id='uploadCLMHistory'
          type='file'
          name='CLMHistoryFile'
          accept='.nc'
          hidden={true}
          onChange={(e) => onUploadCLMHistory(e)}
        />
        <Button
          icon='file_upload'
          variant='primary'
          submitting={uploading}
          onClick={(e) => onClickUpload(e)}
        >
          Upload CLM history file
        </Button>
        {error && <ErrorMessage error={error} className='mt-12' />}
      </>
    )
  }

  return (
    <Column>
      <div className='mb-12 text-16 font-medium '>
        Please upload CLM history file to show projection
      </div>
      <UploadCLMProjection onFileUploaded={() => toggleDialog(carbonModelKey)} />
    </Column>
  )
}

function getFileExtension(filename: string) {
  const pos = filename.lastIndexOf('.')
  const extension = filename.slice(pos + 1, filename.length)
  return extension
}
