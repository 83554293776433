// TODO: Move this list to the backend
enum REFORESTATION_SUB_TYPE {
  'Cropland',
  'Abandoned',
  'Afforestation',
  'Agroforestry',
  'Agriculture',
  'Agri-horticulture',
  'Agri-silvi-horticulture',
  'Agrisilvoculture',
  'Agrisilvopasture',
  'Agroforestry - parkland',
  'Agroforestry - pasture',
  'Agroforestry Fallow (N fixing)',
  'Alleycropping (N fixing)-Alleycropping (Non N fixing)',
  'Alley cropping',
  'Alleycropping (N fixing)',
  'Alleycropping (N fixing)- agroforestry Fallow (N fixing)',
  'Alleycropping (Non N fixing)',
  'Alleycropping (Non N fixing)- agroforestry Fallow (Non N fixing)',
  'Bare',
  'Biodynamic system',
  'Burned',
  'Control',
  'Crops',
  'Exclosure',
  'Experimental',
  'Exploited',
  'Exploited - cut and carry',
  'Exploited - slash and burn',
  'Fallow',
  'Fallow - bush',
  'Fallow - coppice',
  'Fallow - tree',
  'Fodder bank',
  'Forest',
  'Forest - degraded or exploited',
  'Forest - fragmented',
  'Forest - native or natural',
  'Forest - old growth',
  'Forest - primary',
  'Forest - protected',
  'Forest - restored',
  'Forest - secondary',
  'Forest - successional',
  'Garden',
  'Grazed',
  'Hedgerow/live fence',
  'Hedgerow with intercropping',
  'Horticulture',
  'Horti-pasture',
  'Intercropping',
  'Irrigated',
  'Living Fences or Hedgerows',
  'Native vegetation',
  'Fence/stone wall',
  'Parklands',
  'Pasture or grassland',
  'Pasture - degraded',
  'Plantation/orchard',
  'Rangeland',
  'Riparian',
  'Scattered Trees',
  'Shade',
  'Shelterbelt',
  'Shrub',
  'Silvofishery',
  'Silvopasture,'
}

export { REFORESTATION_SUB_TYPE }