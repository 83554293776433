import {
  CarbonPriceScenarios,
  getPredictedCarbonPrice,
} from '~/data/financials/carbonPricePrediction'
import { VCUS } from '~/types/vcus'
import { ProjectType } from '~/models/landProject'
import { DEFAULT_PROJET_PERIOD } from '~/utils/constants'

export type EarthshotCashflow = {
  data: CashflowData[]
  investment: number
  totalShareOfCredits: number
  totalCreditsRevenue: number
  totalBrokerage: number
  totalIncome: number
  profit: number
}

type CashflowData = {
  year: number
  calendarYear: number
  shareOfVCUs: number
  carbonPrice: number
  creditsRevenue: number
  income: number
  costs: number
  profit: number
  balance: number
  investment: number
  creditShare: number
  brokerage: number
}

type CommonParams = {
  projectType: ProjectType
  startYear: number
  earthshotCreditShare: number
  vcus: VCUS
  carbonPriceScenario: CarbonPriceScenarios
  brokerageFee: number
  investment: number
  investmentDistributions: number[]
}

export function calculateEarthshotCashflow(params: CommonParams): EarthshotCashflow {
  const {
    projectType,
    startYear,
    earthshotCreditShare,
    vcus,
    carbonPriceScenario,
    investment,
    investmentDistributions,
    brokerageFee,
  } = params

  const data: CashflowData[] = []
  const cashflow = []

  let totalCreditsRevenue = 0
  let totalShareOfCredits = 0
  let totalBrokerage = 0
  let totalIncome = 0
  let profit = 0

  for (let year = 0; year < DEFAULT_PROJET_PERIOD; year++) {
    const calendarYear = year + startYear
    const carbonPrice = getPredictedCarbonPrice(
      calendarYear,
      projectType,
      carbonPriceScenario
    )

    // Income ------
    // VCUS
    const vcusIssued = vcus[year]?.issued || 0
    const creditShare = earthshotCreditShare
    const shareOfVCUs = vcusIssued * creditShare
    const creditsRevenue = shareOfVCUs * carbonPrice

    // Brokerage
    const investmentDistribution = (investmentDistributions[year] || 0) * investment
    const brokerage = investmentDistribution * brokerageFee

    const income = creditsRevenue + brokerage

    const balance = income

    const yearRow: CashflowData = {
      year,
      calendarYear,
      carbonPrice,
      creditShare,
      shareOfVCUs,
      creditsRevenue,
      investment: investmentDistribution,
      brokerage,
      income,
      costs: 0,
      profit: income,
      balance,
    }

    cashflow.push(balance)
    data.push(yearRow)

    profit += balance
    totalShareOfCredits += shareOfVCUs
    totalCreditsRevenue += creditsRevenue
    totalBrokerage += brokerage
    totalIncome += income
  }

  return {
    investment,
    profit,
    totalShareOfCredits,
    totalCreditsRevenue,
    totalBrokerage,
    totalIncome,
    data,
  }
}

// // Converts the investorCashflow data structure to be used in a table
// export function transposeInvestorCashflowForTable(
//   investorCashflows: EarthshotCashflow[],
//   startYear: number
// ) {
//   const rows = []

//   for (let year = 0; year < period; year++) {
//     const row = {
//       year,
//       yearDate: year + startYear,
//     }

//     investorCashflows.forEach((cashflow, index) => {
//       row[`investment${index}`] = cashflow.data[year].balance
//     })

//     rows.push(row)
//   }
//   return rows
// }
