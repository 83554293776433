import { Badge } from '~/components/atomic/Badge'
import { Pressable } from '~/components/atomic/Pressable'
import {
  countryRatingToBadgeVariant,
  CountryRegulatoryAssessment,
} from '~/models/countryRegulation'
import { Row } from '~/styles'
import { getRelativeTime } from '~/utils/times'

type Props = {
  countryAssessment: CountryRegulatoryAssessment
  showLearnMore?: boolean
}

export function CountryAssessmentMeta(props: Props) {
  const { countryAssessment, showLearnMore } = props

  if (!countryAssessment) return <span>Unknown</span>

  const { rating, url, updated_at } = countryAssessment

  return (
    <Row className='row-vcenter text-13'>
      <Badge variant={countryRatingToBadgeVariant(rating)}>{rating}</Badge>

      {updated_at && (
        <time className='ml-12 text-ink-600'>Updated: {getRelativeTime(updated_at)}</time>
      )}
      {showLearnMore && url && (
        <Pressable icon='info' className='ml-8' href={url} openInNewTab>
          Learn more
        </Pressable>
      )}
    </Row>
  )
}
