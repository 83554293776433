import { useSnapshot } from 'valtio'
import isEqual from 'lodash.isequal'

import { Button } from '~/components/atomic/Button'
import { StaticTable } from '~/components/atomic/table/StaticTable'
import {
  getLandCoverTypeLabel,
  LandCover,
  LandCoverCarbonBaselineData,
  LandCoversData,
  TotalBaselineCarbon,
} from '~/models/landCover'
import { LandCoverTypeCarbonSelector } from '~/routes/project/land/sections/baseline/LandCoverTypeCarbonSelector'
import {
  baselineStore,
  calculateTotalBaselineCarbon,
  initLandCoverBaselineStore,
} from '~/routes/project/land/sections/baseline/baselineStore'
import { Column, Row } from '~/styles'
import { Section } from '~/styles/main'
import { saveProjectData } from '~/services/db'
import { useActiveLandProject } from '~/state'
import { getUpdatedByMetaData, UpdatedBy } from '~/models/authedUser'
import { useEffect, useState } from 'react'
import { logger } from '~/services/logger'
import Avatar from '~/components/atomic/Avatar'
import { Time } from '~/components/atomic/Time'
import { UpdatedByInfo } from '~/components/core/UpdatedByInfo'
import { toast } from '~/services/toaster'

export function BaselineSection() {
  const { landProject } = useActiveLandProject()
  const [saving, setSaving] = useState<boolean>(false)

  const snap = useSnapshot(baselineStore)

  const projectCarbonBaselineData = landProject?.landCoverCarbonBaseline

  useEffect(() => {
    initLandCoverBaselineStore(projectCarbonBaselineData?.landCovers)
  }, [])

  const projectLandCoverBaselineCarbonPerHa = calculateTotalBaselineCarbon(
    projectCarbonBaselineData?.landCovers
  )
  const currentLandCoverBaselineCarbonPerHa = calculateTotalBaselineCarbon(snap)
  const isDifferent = isCurrentDifferentFromProject(
    projectLandCoverBaselineCarbonPerHa,
    currentLandCoverBaselineCarbonPerHa
  )

  return (
    <Section className='gap-y-40' title='Baseline calculator'>
      <Column>
        <Row className='row-vcenter mb-8 gap-x-40'>
          <div className='text-16 font-medium'>Project's Baseline Carbon</div>
          <Button variant='primary' onClick={save} submitting={saving}>
            Save
          </Button>
          {isDifferent && (
            <Row className='row-vcenter gap-8'>
              The active selection is different from the one saved by
              <UpdatedByInfo updatedBy={projectCarbonBaselineData.updatedBy} />
            </Row>
          )}
        </Row>
        <Row className='row-vcenter gap-x-40'>
          <StaticTable
            headers={[
              '',
              'Area',
              'Mean tCO2e',
              'Lower 95% CI tCO2e',
              'Upper 95% CI tCO2e',
            ]}
            rows={landCoverBaselineCarbonPerHaToTableRows(
              currentLandCoverBaselineCarbonPerHa
            )}
            className='w-[800px]'
          />
        </Row>
      </Column>

      <Column className='gap-y-40'>
        <LandCoverTypeCarbonSelector landCoverType='degradedForest' />
        <LandCoverTypeCarbonSelector landCoverType='agricultureGrassland' />
      </Column>
    </Section>
  )

  async function save() {
    setSaving(true)
    const currentLandCoversData = JSON.parse(JSON.stringify(snap)) as LandCoversData
    const landCoverCarbonBaseline: LandCoverCarbonBaselineData = {
      updatedBy: getUpdatedByMetaData(),
      landCovers: currentLandCoversData,
    }
    logger.info('Saving baseline data', landCoverCarbonBaseline)

    await saveProjectData({ landCoverCarbonBaseline }, landProject._id)
    setSaving(false)
  }
}

function landCoverBaselineCarbonPerHaToTableRows(data: TotalBaselineCarbon) {
  const baseRows = Object.keys(data).map((key: LandCover) => {
    return [
      getLandCoverTypeLabel(key),
      data[key].area,
      data[key].mean,
      data[key].lower_95Ci,
      data[key].upper_95Ci,
    ]
  })

  const totalRow = [
    'Total',
    baseRows.reduce((acc, row) => acc + (row[1] as number), 0),
    baseRows.reduce((acc, row) => acc + (row[2] as number), 0),
    baseRows.reduce((acc, row) => acc + (row[3] as number), 0),
    baseRows.reduce((acc, row) => acc + (row[4] as number), 0),
  ]

  return [...baseRows, totalRow]
}

function isCurrentDifferentFromProject(
  currentLandCoversData: TotalBaselineCarbon,
  projectLandCoversData: TotalBaselineCarbon
) {
  if (!currentLandCoversData || !projectLandCoversData) return false
  return !isEqual(currentLandCoversData, projectLandCoversData)
}
