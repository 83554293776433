import { useEffect } from 'react'
import { Pressable } from '~/components/atomic/Pressable'
import { useMap } from 'react-map-gl'

import { Column, Row } from '~/styles'
import { PropsWithChildren } from 'react'
import { cn } from '~/utils/cn'
import { cardClasses } from '~/styles/main'
import { FeatureCollection, getAreaHa } from '~/utils/geo'
import { saveFile } from '~/utils'
import { Tooltip } from '~/components/atomic/Tooltip'
import { fitMap, MapLayer } from '~/services/mapbox'
import classed from '~/styles/classed'
import { Numerical } from '~/components/core/Numerical'
import LayerInfoDialog from './LayerInfoDialog'

type Props = PropsWithChildren<{}>

export function MapControls(props: Props) {
  const { children } = props

  return (
    <ControlGroup bottom left className='row space-x-12'>
      <ZoomControls />
      {children}
    </ControlGroup>
  )
}

type LayersProps = {
  layers: MapLayer[]
  onChange: (e) => void
}
export function Layers(props: LayersProps) {
  const { layers, onChange } = props

  const mapRef = useMap().default

  if (layers?.length == 0) return null

  function onToggleVisibility(layer, visible) {
    onChange({ ...layer, visible })
  }

  return (
    <ControlCard className={`col min-w-[240px] px-16 py-8 pr-12`}>
      <div className='mt-4 mb-4 text-12 font-bold uppercase tracking-wider text-ink-500'>
        Polygons
      </div>
      {layers?.map((layer) => {
        const isVisible = layer.featureCollection && layer.visible !== false

        const layerArea = getAreaHa(layer.featureCollection)

        return (
          <Row
            key={layer.id}
            className={cn('row-vcenter group gap-12', {
              'opacity-50': !isVisible,
            })}
          >
            <Tooltip content='Toggle visibility'>
              <Pressable
                icon={isVisible ? 'visibility' : 'visibility_off'}
                onClick={(e) => onToggleVisibility(layer, !isVisible)}
                className='flex-1 mr-4'
                iconClasses='opacity-100'
              >
                {layer.label}
              </Pressable>
            </Tooltip>

            <Tooltip content={<Numerical type='area' value={layerArea} />}>
              <div>
                <Numerical type='area' value={layerArea} abbreviated />
              </div>
            </Tooltip>

            <Row className='space-x-8'>
              <Tooltip content='Center'>
                <Pressable
                  icon='filter_center_focus'
                  className='invisible group-hover:visible'
                  onClick={() => fitMap(mapRef, layer.featureCollection)}
                />
              </Tooltip>

              <Tooltip content='Download'>
                <Pressable
                  icon='cloud_download'
                  className='invisible group-hover:visible'
                  onClick={() => {
                    saveFile(`${layer.label}.geojson`, layer.featureCollection)
                  }}
                />
              </Tooltip>
            </Row>
          </Row>
        )
      })}
    </ControlCard>
  )
}

function RasterLayersControls(props: any) {
  const { layers, onChange } = props

  if (layers?.length === 0) return null

  function onToggleVisibility(layer, visible) {
    onChange({ ...layer, visible })
  }

  return (
    <>
      <ControlCard className={`col w-[100%] px-16 py-8 pr-12`}>
        <div className='mt-4 mb-4 text-12 font-bold uppercase tracking-wider text-ink-500'>
          Layers
        </div>
        {layers?.map((layer) => {
          const isVisible = layer.visible

          return (
            <div key={layer.id} className='group gap-12'>
              <Row
                className={cn('row-vcenter ', {
                  'opacity-50': !isVisible,
                })}
              >
                <Tooltip content='Toggle visibility' align='start'>
                  <Pressable
                    icon={isVisible ? 'visibility' : 'visibility_off'}
                    onClick={(e) => onToggleVisibility(layer, !isVisible)}
                    className='flex-1 mr-2'
                    iconClasses='opacity-100'
                  >
                    {layer.label}
                  </Pressable>
                </Tooltip>
                {(layer.metadata || layer.metadataUrl) && (
                  <LayerInfoDialog layer={layer} />
                )}
              </Row>
              {isVisible && layer.legend?.length > 0 && (
                <div className='ml-16'>
                  {layer.legend?.map((legend) => (
                    <Row
                      key={`legend-${layer.id}-${legend.id}`}
                      className='flex items-center ml-2 my-6'
                    >
                      <div
                        className='mr-8 rounded-xl'
                        style={{
                          minWidth: '0.875rem',
                          minHeight: '0.875rem',
                          backgroundColor: legend.color,
                        }}
                      ></div>
                      <span className='text-ink-600'>{legend.text || legend.id}</span>
                    </Row>
                  ))}
                </div>
              )}
            </div>
          )
        })}
      </ControlCard>
    </>
  )
}

type FitMapControlProps = {
  featureCollectionToFit?: FeatureCollection
}

export function FitMapControl(props: FitMapControlProps) {
  const { featureCollectionToFit } = props
  const mapRef = useMap().default

  useEffect(() => {
    if (featureCollectionToFit) fitMap(mapRef, featureCollectionToFit)
  }, [featureCollectionToFit])

  return (
    <div className={cardClasses}>
      <Pressable
        className='h-[36px] !px-20'
        onClick={() => fitMap(mapRef, featureCollectionToFit)}
      >
        Center
      </Pressable>
    </div>
  )
}

export function ZoomControls() {
  const map = useMap()

  const zoomIn = () => map.default.zoomIn()
  const zoomOut = () => map.default.zoomOut()

  return (
    <Row className={cn(cardClasses, 'px-8')}>
      <Pressable iconSize={24} icon='add' iconClasses='opacity-100' onClick={zoomIn} />
      <Pressable
        iconSize={24}
        icon='remove'
        iconClasses='opacity-100'
        onClick={zoomOut}
        className='ml-8'
      />
    </Row>
  )
}

const mapPadding = 12

const ControlCard = classed('div', `p-12 ${cardClasses}`)

const ControlCardButton = ({ className = '', ...p }) => (
  <ControlCard className={`center p-4 ${className}`}>
    <Pressable className={`center`} {...p} />
  </ControlCard>
)

const ControlGroup = (props) => {
  const { left, right, bottom, top, className = '', ...rest } = props
  const style = {
    left: left ? mapPadding : null,
    right: right ? mapPadding : null,
    bottom: bottom ? mapPadding : null,
    top: top ? mapPadding : null,
  }
  return <div className={` absolute z-10 ${className}`} style={style} {...rest} />
}

export const FloatingButton = ({ className, ...rest }: any) => (
  <Pressable
    iconClasses='opacity-100'
    style={{
      textShadow:
        '1px 1px 0 rgba(0,0,0,0.1), -1px 1px 0 rgba(0,0,0,0.1), 1px -1px 0 rgba(0,0,0,0.1), -1px -1px 0 rgba(0,0,0,0.1)',
    }}
    className={`!font-semibold text-white hover:bg-black/50 ${className}`}
    {...rest}
  />
)

MapControls.FitMap = FitMapControl
MapControls.Zoom = ZoomControls
MapControls.Layers = Layers
MapControls.RasterLayers = RasterLayersControls
MapControls.Group = ControlGroup
MapControls.Card = ControlCard
MapControls.CardButton = ControlCardButton
MapControls.FloatingButton = FloatingButton

export function MapLegend(props) {
  const { layer } = props
  return layer.legend?.items?.map((item) => (
    <Row
      key={`legend-${layer.id}-${item.id || item.text}`}
      className='flex items-center ml-2 my-6'
    >
      <div
        className='mr-8 rounded-xl'
        style={{
          minWidth: '0.875rem',
          minHeight: '0.875rem',
          backgroundColor: item.color,
        }}
      ></div>
      <span className='text-ink-600'>{item.text || item.id}</span>
    </Row>
  ))
}

export function GradientLegend(props) {
  const { layer } = props

  // function that given an array of colors, it creates the linear gradient
  const gradient = colorsArrayToGradientString(layer.legend?.items.map((i) => i.color))
  console.log('gradient: ', gradient)
  const styles = {
    background: gradient,
  }

  return (
    <Column className='w-full'>
      <div className='h-10 w-full rounded-full' style={styles} />
      <Row className=' font-mono flex-1 mt-4 text-13'>
        <Row className='justify-between flex-1'>
          {layer.legend?.items.map((i, index) => (
            <span key={index}>{i.text}</span>
          ))}
        </Row>
        <span className='ml-4'>tc/ha</span>
      </Row>
    </Column>
  )
}

function colorsArrayToGradientString(colors) {
  const steps = colors.map((color, index) => {
    return `${color} ${(index * 100) / (colors.length - 1)}%`
  })
  console.log('steps: ', steps)

  return `linear-gradient(90deg, ${steps.join(',')})`
}
