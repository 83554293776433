import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getSortedRowModel,
  SortingState,
} from '@tanstack/react-table'
import { useEffect, useState } from 'react'
import TableLayout from '~/components/atomic/table/TableLayout'
import { Table as Props, Meta } from '~/types/table'
import { cn } from '~/utils/cn'
import Icon from '~/components/atomic/Icon'
import { useLocation } from '~/utils/useLocation'
import { Column, Row } from '~/styles'
import { ColumnVisibility } from '~/components/atomic/table/ColumnVisibility'
import { ProjectsTableFilters } from '~/routes/projects/components/ProjectsTableFilters'

const defaultColumnVisibility = {
  umbrellaPartner: false,
  carbonStatus: false,
  financingType: false,
  partnershipLead: false,
  polygonArea: false,
  vcus: false,
  vcusPerYear: false,
  potentialRevenue: false,
  npv: false,
  npvha: false,
  created_at: true,
  forestCoverHa: false,
  annualDeforestationHa: false,
  'tCO2e/ha': false,
  avoidedEmissionsYearOne: false,
  // historicalTrend: false,
}

export const ProjectsTable = (props: Props) => {
  const { data, columns } = props
  const [sorting, setSorting] = useState<SortingState>([])

  const table = useReactTable({
    data,
    columns,
    initialState: {
      columnVisibility: defaultColumnVisibility,
    },
    state: {
      sorting,
    },
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  })

  const location = useLocation()
  const showDeforestationRateColumn =
    location.search.includes('projectType=redd') || location.search === ''

  useEffect(() => {
    table.setColumnVisibility((state) => ({
      ...state,
      deforestationRates: showDeforestationRateColumn,
    }))
  }, [showDeforestationRateColumn, table])

  return (
    <>
      <Row className='flex-wrap px-20 py-12 gap-x-12 gap-y-12 row-vcenter !text-13 text-ink-700 sticky top-0 bg-white z-10'>
        <ProjectsTableFilters />
        <ColumnVisibility table={table} />
      </Row>

      <Column className='overflow-x-auto'>
        <TableLayout>
          <TableLayout.THead>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableLayout.TR key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  const align = (header.column.columnDef.meta as Meta)?.align || 'right'
                  return (
                    <TableLayout.TH
                      key={header.id}
                      className={cn('text-' + align, 'sticky top-0')}
                    >
                      {header.isPlaceholder ? null : (
                        <Row
                          {...{
                            className: header.column.getCanSort()
                              ? 'cursor-pointer select-none w-max'
                              : '',
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: (
                              <Icon
                                icon={'arrow_upward'}
                                size={12}
                                className='opacity-50 ml-6'
                              />
                            ),
                            desc: (
                              <Icon
                                icon={'arrow_downward'}
                                size={12}
                                className='opacity-50 ml-6'
                              />
                            ),
                          }[header.column.getIsSorted() as string] ?? null}
                        </Row>
                      )}
                    </TableLayout.TH>
                  )
                })}
              </TableLayout.TR>
            ))}
          </TableLayout.THead>
          <TableLayout.TBody>
            {table.getRowModel().rows.map((row) => (
              <TableLayout.TR className='hover:bg-ink-100' key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  const align = (cell.column.columnDef.meta as Meta)?.align || 'right'
                  return (
                    <TableLayout.TD key={cell.id} className={'text-' + align}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableLayout.TD>
                  )
                })}
              </TableLayout.TR>
            ))}
          </TableLayout.TBody>
        </TableLayout>
      </Column>
    </>
  )
}
