import { useEffect, useState } from 'react'
import { getLandProjectCountry, LandProject } from '~/models/landProject'
import { fetchDB } from '~/services/db'
import { toast } from '~/services/toaster'
import { ColorVariants } from '~/styles/colors'

const DAYS_BEFORE_COUNTRY_ASSESSMENT_NEEDS_UPDATE = 60

export type CountryRating = 'high' | 'medium' | 'low' | 'unclear' | 'unknown'
type AssessmentProgress = string // TODO add typings, maybe automatically pull from DB

export type CountryRegulatoryAssessment = {
  countryName?: string
  rating: CountryRating
  progress?: AssessmentProgress
  url?: string
  updated_at?: string
}

export type CountryRegulatoryAssessmentsMap = {
  [countryName: string]: CountryRegulatoryAssessment
}

type Notion_CountryRegulatoryDatabaseItem = {
  // The notion database has more values that might change overtime
  // Only the used values are typed
  properties: {
    Country: {
      title: {
        plain_text: string
      }
    }
    Rating: {
      status: {
        name: string
      }
    }
  }
  url: string
  last_edited_time: string
}

export function useGetCountryRegulatoryMap(): CountryRegulatoryAssessmentsMap {
  const [list, setList] = useState<CountryRegulatoryAssessmentsMap>(null)

  useEffect(() => {
    async function get() {
      const res = await fetchDB<{ list: Notion_CountryRegulatoryDatabaseItem[] }>(
        'getCountriesRegulatoryList'
      )
      const countryRegulatoryMap = parseRegulatoryListNotionResult(res.list || [])
      setList(countryRegulatoryMap)
    }

    get()
  }, [])

  return list
}

export function useGetLandProjectCountryRegulatoryAssessment(landProject: LandProject) {
  const countryName = getLandProjectCountry(landProject)
  const countryAssessment = useGetCountryRegulatoryAssessment(countryName)
  return countryAssessment
}

export function useGetCountryRegulatoryAssessment(country: string) {
  const [countryAssessment, setCountryAssessment] =
    useState<CountryRegulatoryAssessment>(null)

  useEffect(() => {
    async function get() {
      if (!country) {
        return setCountryAssessment({ rating: 'unknown' })
      }

      const res = await fetchDB('getCountryRegulatoryAssessment', { country: country })

      if (res.valid) {
        const countryRegulatoryAssessment = parseRegulatoryItemFromNotionResult(
          res.assessment
        )
        setCountryAssessment(countryRegulatoryAssessment)
      } else {
        setCountryAssessment({ rating: 'unknown' })
      }
    }

    get()
  }, [country])

  return countryAssessment
}

// Notion parsers --------------------

function parseRegulatoryListNotionResult(
  countryRegulatoryList: Notion_CountryRegulatoryDatabaseItem[]
): CountryRegulatoryAssessmentsMap {
  const countryRegulatoryMap = {}
  countryRegulatoryList.forEach((item) => {
    if (!item) return
    const data = parseRegulatoryItemFromNotionResult(item)
    countryRegulatoryMap[data.countryName] = data
  })
  return countryRegulatoryMap
}

function parseRegulatoryItemFromNotionResult(item): CountryRegulatoryAssessment {
  const countryName = item.properties?.Country?.title?.[0]?.plain_text
  const rating = item.properties?.Rating?.status?.name?.toLowerCase()
  const progress = item.properties?.Progress?.select?.name

  if (!countryName) {
    toast.error(
      `Error parsing country regulatory assessment from Notion for ${item.url} `
    )
  }

  return {
    ...parseNotionProperties(item.properties),
    countryName,
    rating,
    updated_at: item.last_edited_time,
    progress,
    url: item.url,
  }
}

// Helpers --------------------

export function countryRatingToBadgeVariant(rating?: string): ColorVariants {
  switch (rating?.toLowerCase()) {
    case 'high':
      return 'green'
    case 'medium':
      return 'yellow'
    case 'low':
      return 'red'
    case 'unclear':
      return 'violet'
    default:
      return 'gray'
  }
}

export function getIsCountryAssessmentOutdated(
  countryAssessment: CountryRegulatoryAssessment
) {
  const lastUpdated = new Date(countryAssessment.updated_at).getTime()
  const now = new Date().getTime()
  const diff = now - lastUpdated
  const diffInDays = diff / (1000 * 60 * 60 * 24)
  const isOutaded = diffInDays > DAYS_BEFORE_COUNTRY_ASSESSMENT_NEEDS_UPDATE
  return isOutaded
}

export function getIsCountryAssessmentCompleted(
  countryAssessment: CountryRegulatoryAssessment
) {
  if (!countryAssessment) return null

  if (countryAssessment.rating === 'unclear' || countryAssessment.rating === 'unknown') {
    return false
  }

  if (getIsCountryAssessmentOutdated(countryAssessment)) return false

  return true
}

function parseNotionProperties(properties: any) {
  const propertyNames = Object.keys(properties)
  const parsedProperties = {}

  propertyNames.forEach((name) => {
    const property = properties[name]
    const key = name.replaceAll(' ', '_')
    const values = findPropertyValues(property)
    const id = property.id
    const parsedProperty = { id, key, type: property.type, label: name, ...values }
    parsedProperties[id] = parsedProperty
  })
  return parsedProperties
}

function findPropertyValues(property: any) {
  const type = property.type
  const data = property[type]

  if (type === 'title') return { value: data.plain_text }
  if (type === 'rich_text') return { value: data?.[0]?.plain_text }
  if (type === 'text') return { value: data?.[0].plain_text }
  if (type === 'last_edited_time') return { value: data.last_edited_time }
  if (type === 'select' || type === 'status')
    return { value: data.name, color: data.color }
  else throw Error('property not found')
}

export const NOTION_COLUMN_IDS = {
  rating: { id: String.raw`O\\RC`, type: 'paragraph' },
  reportingProgress: { id: 'AHeI', type: 'short-text' },
  knownContext: { id: String.raw`I\\_x`, type: 'paragraph' },
  climatePledges: { id: 'JAk;', type: 'paragraph' },
  countryCode: { id: 'SNoT', type: 'paragraph' },
  precedenceVcmActivity: { id: 'XAVZ', type: 'paragraph' },
  reportLink: { id: ']N_w', type: 'link' },
  economicAndPoliticalEnvironment: { id: 'aD[L', type: 'paragraph' },
  tenureSystemsAndCarbonRights: { id: 'yyVI', type: 'paragraph' },
  legalFrameworksAndRatifiedPolicy: { id: '|vcY', type: 'paragraph' },
  readinessProgramsAndResults: { id: String.raw`~\\?H`, type: 'paragraph' },
  country: { id: 'title', type: 'short-text' },
  rightToActionContext: { id: '86bc5686-e5ce-491c-aeb3-16c5814b98a5', type: 'paragraph' },
  feasibility: { id: '878f1b8e-c05c-4934-947c-35a8e1d2fb42', type: 'paragraph' },
  notesOnRights: { id: '91b9b879-8187-4ac0-8768-e0f23f69011e', type: 'paragraph' },
  precedent: { id: 'b272b114-9c4b-47d7-9ff4-94e2bf567bc4', type: 'paragraph' },
  notesOnVerraRelationship: {
    id: 'ccd54ef6-e832-4bd8-bae9-3d3ba0f67e71',
    type: 'paragraph',
  },
}
