import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from '~/components/atomic/Button'
import Input from '~/components/atomic/Input'
import AuthForm from '~/components/auth/AuthForm'
import AuthLayout from '~/components/auth/AuthLayout'
import { fetchDB } from '~/services/db'
import { setAuthedUser } from '~/state/auth'
import { AuthedUser } from '~/models/authedUser'
import { getFormData } from '~/utils'
import { logger } from '~/services/logger'

type OnEmailVerifyResponse = {
  valid: number
  user: AuthedUser
  msg: string
}

function EmailVerify() {
  const searchParams = new URLSearchParams(window.location.search)
  const urlData = Object.fromEntries(searchParams)

  const navigate = useNavigate()
  const [error, setError] = useState('')
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    emailVerify(urlData)
  }, [])

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    const data = getFormData(e.currentTarget)
    emailVerify(data)
  }

  async function emailVerify(data) {
    if (data.email && data.key) {
      setSubmitting(true)
      setError('')

      const response: OnEmailVerifyResponse = await fetchDB('emailVerify', data)
      onEmailVerify(response)
    } else {
      setError('Please fill out all fields and try again.')
    }
  }

  function onEmailVerify(data: OnEmailVerifyResponse) {
    const { valid, user, msg } = data
    if (valid === 1 && user?._id) {
      logger.info('onEmailVerify. Valid')
      setAuthedUser(user)
      navigate('/projects')
    } else {
      const error = msg || 'Invalid key, please check your email and try again.'
      logger.error('onEmailVerify', error)
      setError(error)
    }
    setSubmitting(false)
  }

  return (
    <AuthLayout title='Verify Email'>
      <AuthForm onSubmit={onSubmit}>
        <Input
          name='email'
          type='email'
          label='Email'
          placeholder='your@email.com'
          defaultValue={urlData?.email}
        />
        <Input
          name='key'
          label='Verification Key'
          placeholder='verificationKeyHere'
          defaultValue={urlData?.key}
        />

        <Button
          disabled={submitting}
          variant='primary'
          type='submit'
          submitting={submitting}
        >
          Verify Email
        </Button>

        {error && <div className='text-14 font-medium text-red-500'>{error}</div>}
      </AuthForm>
    </AuthLayout>
  )
}

export { EmailVerify }
