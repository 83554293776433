import { Layer, LayerProps } from 'react-map-gl'

type Props = {
  saturation?: number
  opacity?: number
}

export default function TerrainMask(props: Props) {
  const { saturation = -0.2, opacity = 0.2 } = props

  const layerProps: LayerProps = {
    id: 'satellite-2',
    type: 'raster',
    source: 'mapbox',
    'source-layer': 'mapbox_satellite_full',
    paint: {
      'raster-saturation': saturation,
      'raster-opacity': opacity,
    },
  }

  return (
    <>
      <Layer {...layerProps} />
    </>
  )
}
