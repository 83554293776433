import React, { PropsWithChildren } from 'react'
import { NavLink } from 'react-router-dom'
import Icon from '~/components/atomic/Icon'
import { isNil } from '~/utils'
import { cn } from '~/utils/cn'

export type ActiveMatchTypes = 'exact' | 'includes' | 'endsWith' | 'startsWith'

type Props = PropsWithChildren<{
  isActive?: boolean
  variant?: 'small' | 'default' | 'icon'
  onClick?: (e) => void
  className?: string
  style?: React.CSSProperties
  icon?: any
  iconRight?: any
  iconSize?: number
  iconClasses?: string
  state?: any
  disabled?: boolean

  // Link Props
  to?: string
  activeMatch?: ActiveMatchTypes

  // Anchor props
  href?: string
  /** Will apply _blank to the anchor tag */
  openInNewTab?: boolean
}>

export const Pressable = React.forwardRef<any, Props>((props: Props, ref: any) => {
  const {
    className,
    children,
    icon,
    iconRight,
    iconSize = 18,
    iconClasses,
    variant = 'default',
    isActive: isActiveProp,
    disabled,

    to,
    activeMatch,

    href,
    openInNewTab,
    ...rest
  } = props

  const classes = cn(
    'group row row-vcenter px-8 py-6 font-medium -mx-8 rounded-md',
    variants[!children ? 'icon' : variant],
    { 'hover:bg-inka-100': !disabled },
    { 'opacity-40': disabled },
    { 'cursor-not-allowed select-none pointer-events-none': disabled },
    { 'bg-inka-100': props['data-state'] === 'open' ? true : isActiveProp },
    className
  )

  const content = (
    <>
      {icon && (
        <Icon
          icon={icon}
          size={iconSize}
          className={cn({ 'mr-6 opacity-50': children }, iconClasses)}
        />
      )}
      {children}
      {iconRight && (
        <Icon
          icon={iconRight}
          size={iconSize}
          className={cn({ 'ml-6 opacity-50': children }, iconClasses)}
        />
      )}
    </>
  )

  if (href) {
    const anchorProps = openInNewTab ? { href, target: '_blank' } : { href }
    return (
      <a ref={ref} {...anchorProps} className={classes} {...rest}>
        {content}
      </a>
    )
  } else if (to) {
    return (
      <NavLink
        ref={ref}
        to={to}
        className={({ isActive }) =>
          cn(isActive && isNil(isActiveProp) && 'bg-ink-100', classes)
        }
        {...rest}
      >
        {content}
      </NavLink>
    )
  } else {
    return (
      <button ref={ref} className={classes} {...rest}>
        {content}
      </button>
    )
  }
})

const variants = {
  default: '',
  icon: '!px-6',
  small: '!px-6 !py-4 -mx-6',
  primary: 'text-blue-500',
}
