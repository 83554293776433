import { useRef } from 'react'
import { Popup, PopupProps } from 'react-map-gl'
import classed from '~/styles/classed'
import { cardClasses } from '~/styles/main'

type Props = {
  className?: string
} & PopupProps

export function MapPopup(props: Props) {
  return <Popup {...props} />
}

function MapPopupWithAutoClose(props) {
  const { onClose, children, ...rest } = props

  const timoutRef = useRef<any>(null)

  const popupBehaviorProps = {
    onMouseEnter: () => {
      clearTimeout(timoutRef.current)
    },
    onMouseLeave: () => {
      timoutRef.current = setTimeout(() => {
        onClose()
      }, 500)
    },
  }

  return (
    <MapPopup {...rest}>
      <div {...popupBehaviorProps}>{children}</div>
    </MapPopup>
  )
}

const MapPopupContent = classed('div', cardClasses)

MapPopup.WithAutoClose = MapPopupWithAutoClose
MapPopup.Content = MapPopupContent
