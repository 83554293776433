import React from 'react'
import { useParams } from 'react-router-dom'
import { ProjectDataFormView } from '~/components/forms/ProjectDataFormView'
import { FormId, getFormById } from '~/models/form'

export function ProjectIntakeView() {
  const params = useParams()
  const formId = params.formId as FormId
  const form = getFormById(formId)

  return <ProjectDataFormView form={form} isIntake={true} />
}
