import { Outlet } from 'react-router-dom'
import { CenteredLoader } from '~/components/atomic/CenteredLoader'
import { SearchDialog } from '~/components/core/SearchDialog'
import { useAuthedUser, useRequireAuth } from '~/state/auth'
import { Column } from '~/styles'

export function Root() {
  const authedUser = useAuthedUser()
  useRequireAuth()

  return authedUser ? (
    <>
      <SearchDialog />
      <Outlet />
    </>
  ) : (
    <Column className='h-full overflow-y-auto'>
      <CenteredLoader />
    </Column>
  )
}
