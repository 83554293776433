import { useState } from 'react'
import { Button } from '~/components/atomic/Button'
import { InfoBox } from '~/components/atomic/InfoBox'
import Select from '~/components/atomic/Select'
import { saveLandProjectRootValues } from '~/models/landProject'
import { estimatesBaseKeysOptions } from '~/models/landProjectReddStats'
import { saveFinancialEstimates } from '~/routes/project/financials/financialsStore'
import { CarbonEstimatesOverride } from '~/routes/project/vcus/components/CarbonEstimatesOverride'
import { DiscountsSection } from '~/routes/project/vcus/components/DiscountsSection'
import { ProjectEfficiency } from '~/routes/project/vcus/components/ProjectEfficiency'
import { useActiveLandProject } from '~/state'

import { Row } from '~/styles'
import { Section } from '~/styles/main'
import { SavingComponent, useSaving } from '~/utils/useSaving'

export function VCUsInputs() {
  const { landProject } = useActiveLandProject()

  return (
    <Section>
      <Row className='row-vcenter gap-40 mb-40'>
        <Section.Title className='mb-0'>VCUs Inputs</Section.Title>
        <SaveEstimatesButton />
      </Row>

      {landProject.projectType === 'redd' && <ChosenProjectionInput />}

      <Row className='flex-wrap gap-x-80 gap-y-40'>
        {landProject.projectType === 'redd' && (
          <>
            <CarbonEstimatesOverride />
            <ProjectEfficiency />
          </>
        )}
        <DiscountsSection />
      </Row>
    </Section>
  )
}

function ChosenProjectionInput() {
  const { landProject } = useActiveLandProject()
  const { saving, saved, onSaving, onSaved } = useSaving()

  async function onChange(e) {
    onSaving()
    const value = e.currentTarget.value
    await saveLandProjectRootValues(landProject, { chosenREDDProjectionKey: value })
    onSaved()
  }

  return (
    <Row className='row-vcenter gap-x-20 mb-20'>
      <Section.Subtitle className='mb-0'>Chosen Projection</Section.Subtitle>
      <Select
        className='w-[200px]'
        name='estimatesBaseKey'
        defaultValue={landProject.chosenREDDProjectionKey}
        options={estimatesBaseKeysOptions}
        onChange={onChange}
      />
      <SavingComponent saving={saving} saved={saved} />
    </Row>
  )
}

function SaveEstimatesButton() {
  const [submitting, setsubmitting] = useState<boolean>(false)
  async function onSave() {
    setsubmitting(true)
    await saveFinancialEstimates()
    setsubmitting(false)
  }
  return (
    <Button variant='primary' submitting={submitting} onClick={onSave}>
      {submitting ? 'Saving...' : 'Save Inputs'}
    </Button>
  )
}
