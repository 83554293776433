import { useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useSnapshot } from 'valtio'
import { Badge } from '~/components/atomic/Badge'
import { Button } from '~/components/atomic/Button'
import Icon from '~/components/atomic/Icon'
import Link from '~/components/atomic/Link'
import { Pressable } from '~/components/atomic/Pressable'
import { NavAuthButton } from '~/components/auth/NavAuthButton'
import { Nav, NavItem } from '~/components/layouts/Nav'
import { ProjectMenu } from '~/components/menus/ProjectMenu'
import { ShareProjectDialog } from '~/components/project/ShareProjectButton'
import { Dropdown } from '~/components/ui-custom/Dropdown'
import { LandProject } from '~/models'
import { state } from '~/state'
import { Column, Row } from '~/styles'
import { Divider } from '~/styles/main'
import { capitalizeFirstLetter } from '~/utils'
import { PATH, ProcessingState } from '~/utils/constants'

type Props = {
  landProject: LandProject
  className?: string
}

const getMenus = (slug: string): { mainNav: NavItem[]; outputsList: NavItem[] } => {
  const root = PATH['/project'] + `/${slug}/`

  const mainNav: NavItem[] = [
    {
      icon: 'info_outline',
      to: root + PATH['summary'],
      activeMatch: 'includes',
      label: 'Summary',
    },
    // {
    //   icon: 'search',
    //   to: root + PATH['assessments'],
    //   activeMatch: 'includes',
    //   label: 'Assessments',
    // },
    {
      icon: 'landscape',
      to: root + PATH.land,
      activeMatch: 'includes',
      label: 'Land',
    },
    {
      icon: 'trending_up',
      to: root + PATH.carbon,
      activeMatch: 'includes',
      label: 'Carbon',
    },
    {
      icon: 'database',
      to: root + PATH.vcus,
      activeMatch: 'includes',
      label: 'VCUs',
    },
    // {
    //   icon: 'attach_money',
    //   to: root + PATH.financials,
    //   activeMatch: 'includes',
    //   label: 'Financials',
    // },
    {
      icon: 'chrome_reader_mode',
      to: root + PATH.data,
      activeMatch: 'includes',
      label: 'Data',
    },
  ]

  const outputsList: NavItem[] = [
    {
      href: `${PATH['/project']}/${slug}/reports/${PATH['carbon']}`,
      icon: 'link',
      activeMatch: 'includes',
      label: 'Carbon Report',
    },
    {
      href: `${PATH['/portfolio']}/${slug}`,
      icon: 'link',
      activeMatch: 'includes',
      label: 'Short Profile',
    },
  ]

  return {
    mainNav,
    outputsList,
  }
}

export default function ProjectNav(props: Props) {
  const { landProject } = props
  const [isFlyoutMenuOpen, setOpenFlyoutMenu] = useState<boolean>(false)
  const params = useParams()
  const slug = params.project_slug

  const location = useLocation()
  const tabName = capitalizeFirstLetter(location.pathname.split('/')?.[3])

  const menus = getMenus(slug)

  const toggleMenu = () => setOpenFlyoutMenu(!isFlyoutMenuOpen)

  return (
    <Nav>
      <NavOverlay open={isFlyoutMenuOpen} menus={menus} onToggle={toggleMenu} />

      <Row className='w-full row md:hidden'>
        <Pressable className='mr-16' onClick={toggleMenu}>
          <Icon icon='menu' />
        </Pressable>

        <Row className='row-vcenter flex-1'>
          <Pressable>
            <Nav.ProjectTitle landProject={landProject} />
          </Pressable>
          <div className='mx-16 opacity-30'>/</div>
          <Pressable activeMatch='exact'>{tabName}</Pressable>
        </Row>
      </Row>

      <Row className='hidden md:flex flex-row row-vcenter m-auto w-full justify-between'>
        <Row className='row-vcenter mr-20 flex-1 gap-x-8'>
          <Nav.HomeButton />

          <div className='h-16 w-1 mx-12 bg-ink-300' />

          {landProject?.name && (
            <>
              <Dropdown content={<ProjectMenu landProject={landProject} />}>
                <Pressable iconRight='keyboard_arrow_down'>
                  <Nav.ProjectTitle landProject={landProject} />
                </Pressable>
              </Dropdown>

              <ShareProjectDialog>
                <Button
                  icon='person_add'
                  variant='secondary-square'
                  iconSize={16}
                  className='ml-12'
                >
                  Share
                </Button>
              </ShareProjectDialog>
            </>
          )}
        </Row>

        <Row className='row row-vcenter mx-auto flex-1 gap-24 self-center px-20 print:hidden'>
          {menus.mainNav.map((option) => (
            <Pressable key={option.label} {...option}>
              {option.label}
            </Pressable>
          ))}
          <Dropdown content={<OutputsMenu items={menus.outputsList} />}>
            <Pressable iconRight='keyboard_arrow_down'>Outputs</Pressable>
          </Dropdown>
        </Row>
        <div className='flex-1' />

        <Row className='row-vcenter gap-x-24'>
          <ProcessingBadge />

          <NavAuthButton />
        </Row>
      </Row>
    </Nav>
  )
}

function OutputsMenu({ items }) {
  return (
    <>
      {items.map(({ label, href, ...rest }, key: string) => (
        <Link href={href} target='_blank'>
          <Dropdown.Item key={key} {...rest}>
            {label}
          </Dropdown.Item>
        </Link>
      ))}
    </>
  )
}

export function ProcessingBadge() {
  const snap = useSnapshot(state)
  const landProjectProcessing = snap.landProjectProcessing

  if (landProjectProcessing) {
    return (
      <Badge variant={ProcessingState.badgeStyle(landProjectProcessing)}>
        {landProjectProcessing}
      </Badge>
    )
  } else {
    return null
  }
}

export function NavOverlay({ open, menus, onToggle }) {
  return (
    <>
      <div
        style={{ pointerEvents: open ? 'all' : 'none', opacity: open ? 1 : 0 }}
        className='absolute inset-0 bg-black/10 z-[200] transition-opacity'
        onClick={onToggle}
      />
      <Column
        style={{
          '--container-padding': '12px',
          transform: !open && 'translateX(-200px)',
        }}
        className='fixed left-0 top-0 p-12 bottom-0 bg-white w-[200px] z-[201] overflow-y-scroll transition-transform'
        onClick={onToggle}
      >
        <Pressable className='mr-16 mt-[1.5px]' onClick={onToggle}>
          <Icon icon='menu' />
        </Pressable>

        <Divider className='my-12' />
        <Pressable icon='home' to='/projects'>
          All projects
        </Pressable>
        <Divider className='my-12' />

        <Column className='gap-4'>
          {menus.mainNav.map((option) => (
            <Pressable key={option.label} {...option}>
              {option.label}
            </Pressable>
          ))}
        </Column>

        <div className='flex-1' />
        <Divider className='my-12' />

        <Row className='row-vcenter justify-center gap-x-24'>
          <ShareProjectDialog>
            <Button variant='primary'>Share</Button>
          </ShareProjectDialog>
          <NavAuthButton />
        </Row>
      </Column>
    </>
  )
}
