import { PropsWithChildren, useState } from 'react'
import Popover from '~/components/atomic/Popover'
import { Option } from '~/types'

type Props<ValueType> = PropsWithChildren<{
  onClickOption?: (option: Option<ValueType>) => void
  CheckboxGroupComponent: any
  defaultValues: any[]
}>

export function PopoverWithCheckboxGroup<ValueType>(props: Props<ValueType>) {
  const { children, CheckboxGroupComponent, onClickOption, defaultValues } = props
  const [open, setOpen] = useState(false)

  return (
    <Popover
      open={open}
      onOpenChange={(newOpen) => setOpen(newOpen)}
      side='left'
      align='start'
      content={
        <CheckboxGroupComponent
          onClickOption={onClickOption}
          defaultValue={defaultValues}
        />
      }
    >
      {children}
    </Popover>
  )
}
