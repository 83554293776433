export function RandomString(length = 16) {
  let text = ''
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charsLen = chars.length
  let counter = 0
  const min = 0
  while (counter < length) {
    const index = Math.floor(Math.random() * (charsLen - 0) + 0)
    text = text + chars[index]
    counter += 1
  }
  return text
}

export function ToFixed(value, decimals = 2) {
  return parseFloat(value).toFixed(decimals)
}
