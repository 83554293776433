import { getRelativeTime } from '~/utils/times'

type Props = {
  relative?: boolean
  time?: string
  className?: string
}

export function Time(props: Props) {
  const { time, relative, className } = props

  const timeStr = relative ? getRelativeTime(time) : time

  return <time className={className}>{timeStr}</time>
}
