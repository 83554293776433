import { Fragment } from 'react'
import { Numerical } from '~/components/core/Numerical'
import { getFiancialValueType } from '~/routes/project/financials/financialsStore'

import { Column } from '~/styles'

type Props = { values: any }

export function BreakdownTooltip(props: Props) {
  const { values } = props

  return (
    <Column className='grid grid-cols-2 text-13'>
      {Object.entries(values).map((entry, i) => {
        const [name, value] = entry
        return (
          <Fragment key={name + i}>
            <span className='text-right opacity-50 mr-4'>{name}:</span>
            <Numerical
              type={getFiancialValueType(name)}
              className='font-medium'
              value={value as any}
            />
          </Fragment>
        )
      })}
    </Column>
  )
}
