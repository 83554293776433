import { ErrorBoundary as ErrorBoundaryPrimitive } from 'react-error-boundary'
import { cn } from '~/utils/cn'

type Props = {
  children: any
}

export function ErrorBoundary(props: Props) {
  const { children } = props

  return (
    <ErrorBoundaryPrimitive FallbackComponent={ErrorBox} onReset={() => {}}>
      {children}
    </ErrorBoundaryPrimitive>
  )
}

export function ErrorBox(props: FallbackProps) {
  const { error, resetErrorBoundary, className } = props
  return (
    <div
      role='alert'
      className={cn(
        'col roundedw-full center h-full flex-1 border-2 border-red-400 bg-pink-200 p-24',
        className
      )}
    >
      <p className='mb-8 text-16 font-bold'>Something went wrong:</p>
      <pre>{error.toString()}</pre>
    </div>
  )
}

interface FallbackProps {
  error: Error
  resetErrorBoundary?: (...args: Array<unknown>) => void
  className?: string
}
