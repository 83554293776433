import { useState } from 'react'
import { Pressable } from '~/components/atomic/Pressable'
import { PopoverWithCheckboxGroup } from '~/components/core/PopoverWithCheckboxGroup'
import RolesSelect from '~/components/core/RolesSelect'
import { fetchDB } from '~/services/db'
import { Badge } from '../atomic/Badge'
import { UserRoles } from '~/types/user'
import { Row } from '~/styles'
import { Option } from '~/types'

type Props = {
  roles: UserRoles[]
  userId: string
}

export function RolesInput(props: Props) {
  const { userId, roles: userRoles } = props
  const [roles, setRoles] = useState(userRoles)

  const onChangeRoles = (option: Option<UserRoles>) => {
    const selectedRole = option.value

    let newRoles = []

    if (option.checked) {
      if (!roles.includes(selectedRole)) {
        newRoles = [...roles, selectedRole]
      }
    } else {
      newRoles = roles.filter((role) => role !== selectedRole)
    }

    if (roles !== newRoles) {
      setRoles(newRoles)
      postNewRoles(newRoles)
    }
  }

  const postNewRoles = async (roles) => {
    const res = await fetchDB('updateUser', {
      userId,
      query: { roles },
    })

    if (res.modified_count && res.valid !== 1) {
      console.error('Could not update user role')
    }
  }

  return (
    <PopoverWithCheckboxGroup<UserRoles>
      CheckboxGroupComponent={RolesSelect}
      onClickOption={onChangeRoles}
      defaultValues={roles}
    >
      <Pressable className='row-vcenter self-start'>
        <Row className='gap-8'>
          {roles.map((role, index) => (
            <Badge key={`${index}-${role}`} className='capitalize'>
              {role}
            </Badge>
          ))}
        </Row>
      </Pressable>
    </PopoverWithCheckboxGroup>
  )
}
