import { cn } from '~/utils/cn'
import React, { useCallback, useRef } from 'react'
import ReactSpreadsheet, { Props as ReactSpreadsheetProps } from 'react-spreadsheet'

type Props = {} & ReactSpreadsheetProps<any>

export function Spreadsheet(props: Props) {
  const { columnLabels, data, onChange, ...rest } = props

  return (
    <ReactSpreadsheet
      columnLabels={columnLabels}
      data={data}
      onChange={onChange}
      DataEditor={DataEditor}
      DataViewer={DataViewer}
      {...rest}
    />
  )
}

const DataEditor = (props) => {
  const { onChange, cell } = props
  const inputRef = useRef<HTMLInputElement>(null)

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange({ ...cell, value: event.target.value })
    },
    [onChange, cell]
  )

  const value = cell?.value ?? ''

  return (
    <div className='Spreadsheet__data-editor'>
      <input
        ref={inputRef}
        type='text'
        onChange={handleChange}
        onFocus={(e) => e.currentTarget.select()}
        value={value}
        autoFocus
        className='text-right'
      />
    </div>
  )
}

const DataViewer = (props) => {
  if (!props.cell) return null
  const { value, unit, readOnly, className } = props.cell

  return (
    <div
      className={cn(
        'px-4',
        { 'pointer-events-none': readOnly },
        { 'text-number': Number(value) },
        className
      )}
    >
      {value}
      {unit}
    </div>
  )
}

Spreadsheet.DataEditor = DataEditor
