import { cn } from '~/utils/cn'
import Avatar from '~/components/atomic/Avatar'
import { Button } from '~/components/atomic/Button'
import Popover from '~/components/atomic/Popover'
import { Pressable } from '~/components/atomic/Pressable'
import { UserProfileDialog } from '~/components/dialogs/UserProfileDialog'
import { toggleUserProfileDialog } from '~/state'
import { useAuthedUser } from '~/state/auth'

type Props = {
  hideName?: boolean
}

export function NavAuthButton(props: Props) {
  const { hideName } = props
  const user = useAuthedUser()

  return user ? (
    <ProfileInNav hideName={hideName} />
  ) : (
    <Button variant='primary' className='mx-8' onClick={() => {}}>
      Sign in
    </Button>
  )
}

function ProfileInNav(props) {
  const { hideName } = props
  const user = useAuthedUser()

  const dropdownContent = (
    <Popover.Menu>
      <Popover.MenuItem onClick={toggleUserProfileDialog}>Profile</Popover.MenuItem>
      <Popover.MenuItem to='/logout'>Log out</Popover.MenuItem>
    </Popover.Menu>
  )

  return (
    <>
      <UserProfileDialog />
      <Popover content={dropdownContent}>
        <Pressable
          className={cn('row row-vcenter px-8 py-8', {
            '!rounded-full': hideName,
          })}
        >
          {!hideName && (
            <span className='mr-6 hidden min-[1400px]:flex'>{user?.first_name}</span>
          )}
          <Avatar name={user?.first_name} />
        </Pressable>
      </Popover>
    </>
  )
}
