import Icon from '~/components/atomic/Icon'
import { Tooltip } from '~/components/atomic/Tooltip'
import { Column, Row } from '~/styles'
import { formatArea, formatCurrency, formatNumber, formatPercent } from '~/utils/units'
import { cn } from '~/utils/cn'

type Formats = 'number' | 'percent' | 'percentOver100' | 'area' | 'currency'

export type StatValues = {
  label?: string
  value: number | string
  unit?: string
  tooltip?: string
  format?: Formats
  decimals?: number
}

type Props = StatValues & {
  unitLeft?: string
  className?: string
  labelClasses?: string
  valueClasses?: string
  unitClasses?: string
}

export function Stat(props: Props) {
  const { label, value, unit, unitLeft, className } = props

  return (
    <div className={className}>
      {label && <div className='text-13 text-ink-400'>{label}</div>}
      {unitLeft && <span className='mr-8'>{unitLeft}</span>}

      <span className='text-16 font-medium'>
        {typeof value == 'number' ? value.toLocaleString() : value}
      </span>
      {unit && <span className='ml-8'>{unit}</span>}
    </div>
  )
}

export function StatItems(props: Props) {
  let {
    label,
    value,
    unit,
    unitLeft,
    format,
    decimals = 0,
    labelClasses,
    valueClasses,
    unitClasses,
  } = props

  if (format == 'percent') unit = '%'

  return (
    <>
      <div className={cn('text-16 opacity-60', labelClasses)}>{label}</div>
      <Row className='items-baseline text-16'>
        {unitLeft && <span className={cn('mr-8', unitClasses)}>{unitLeft}</span>}

        <span className={valueClasses}>{formatValue(value, format, decimals)}</span>
        {unit && <span className={cn('ml-6 opacity-60', unitClasses)}>{unit}</span>}
      </Row>
    </>
  )
}

function formatValue(value: string | number, format: Formats, decimals?: number) {
  switch (format) {
    case 'number':
      return formatNumber(value, decimals)
    case 'area':
      return formatArea(value as number, false)
    case 'percent':
      return formatPercent(value, decimals)
    case 'currency':
      return formatCurrency(value)
    default:
      return value
  }
}

export function VerticalStat(props: Props) {
  const { label, value, unit, tooltip, className } = props

  return (
    <div className={cn('flex flex-col', className)}>
      <Row>
        <span className='mr-6 mb-8 text-14 font-medium opacity-60'>{label}</span>
        {tooltip && (
          <Tooltip content={tooltip} side='top'>
            <div>
              <Icon
                size={16}
                icon='info_outline'
                className='relative -top-1 cursor-help opacity-60'
              />
            </div>
          </Tooltip>
        )}
      </Row>
      <Row className='items-baseline'>
        <div className='text-[26px]'>{value}</div>
        <span className='ml-4 text-16 opacity-60'>{unit}</span>
      </Row>
    </div>
  )
}

export function HorizontalStats(props: { stats: StatValues[]; className?: string }) {
  const { stats, className } = props
  return (
    <div className={cn('grid grid-cols-[max-content,1fr] gap-x-20 gap-y-12', className)}>
      {stats.map((stat, key) => (
        <StatItems key={stat.label + key} {...stat} />
      ))}
    </div>
  )
}

Stat.Vertical = VerticalStat
