import { Badge } from '~/components/atomic/Badge'
import { Button } from '~/components/atomic/Button'
import { CenteredLoader } from '~/components/atomic/CenteredLoader'
import { InfoBox } from '~/components/atomic/InfoBox'
import { Tabs } from '~/components/atomic/Tabs'
import { Time } from '~/components/atomic/Time'
import {
  countryRatingToBadgeVariant,
  getIsCountryAssessmentOutdated,
  NOTION_COLUMN_IDS,
} from '~/models/countryRegulation'
import { BerkeleyProjects } from '~/routes/project/land/sections/countryRegulation/BerkeleyProjects'
import { VerraProjects } from '~/routes/project/land/sections/countryRegulation/VerraProjects'
import { Row } from '~/styles'
import { Section } from '~/styles/main'
import { CountryRegulatoryAssessment } from '~/models/countryRegulation'

type Props = {
  country: string
  countryAssessment: CountryRegulatoryAssessment
}

export function CountryRegulationInformation(props: Props) {
  const { country, countryAssessment } = props

  if (!countryAssessment) return <CenteredLoader>Getting country data</CenteredLoader>

  const { rating, updated_at, progress } = countryAssessment
  const isCountryAssessmentOutdated = getIsCountryAssessmentOutdated(countryAssessment)
  const reportLink = countryAssessment[NOTION_COLUMN_IDS.reportLink.id]

  return (
    <>
      {isCountryAssessmentOutdated && (
        <InfoBox variant='red' icon='warning'>
          This country assessment needs to be updated, please get in touch with Oliver
          (oliver@earthshot.eco)
        </InfoBox>
      )}

      <Row className='gap-x-40 row-vcenter mb-24'>
        <Section.Subtitle className='mb-0'>{country}</Section.Subtitle>

        <Row className='row-vcenter space-x-12'>
          <div>Rating:</div>
          <Badge variant={countryRatingToBadgeVariant(rating)}>{rating}</Badge>
        </Row>

        <Row className='space-x-12'>
          <div>Last Updated:</div>
          <Time time={updated_at} relative />
        </Row>

        {reportLink.value && (
          <Button title='View Report Slide' href={reportLink.value} openInNewTab>
            View Report Slide
          </Button>
        )}
      </Row>

      <div className='divide-y ring-1 ring-ink-200 rounded-md shadow-md max-w-[600px]'>
        {Object.keys(countryAssessment).map((key, index) => {
          return <RenderRegulationItem item={countryAssessment[key]} key={index + key} />
        })}
      </div>

      <Tabs
        tabs={[
          { value: 'verra', label: 'Verra Project' },
          { value: 'berkeley', label: 'Berkeley Voluntary Projects' },
        ]}
        className='mt-24'
        contentClassName='mt-20'
        content={[
          <VerraProjects countryName={country} />,
          <BerkeleyProjects countryName={country} />,
        ]}
      />
    </>
  )
}

function RenderRegulationItem(props) {
  const { item } = props
  if (!item || !item.label || !item.value) return null

  if (itemKeysToExclude.includes(item?.type) || itemKeysToExclude.includes(item?.key))
    return null

  let content = <span className='my-8'>{item?.value || '-'}</span>
  if (item?.color)
    content = (
      <Badge variant={item?.color} className='my-8'>
        {item?.value}
      </Badge>
    )

  return (
    <Row className='space-x-12 pl-12'>
      <div className='w-[140px] flex-shrink-0 font-medium border-r border-ink-200 py-8'>
        {item?.label}
      </div>
      {content}
    </Row>
  )
}

const itemKeysToExclude = [
  'last_edited_time',
  'countryCode',
  'title',
  'Report_Link',
  'Rating',
]
