import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  flexRender,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
} from '@tanstack/react-table'
import { useCallback, useState } from 'react'
import TableLayout from '~/components/atomic/table/TableLayout'
import { GlobalFilter } from '~/components/atomic/table/GlobalFilter'
import Pagination from '~/components/atomic/table/Pagination'
import { Table as Props, Meta } from '~/types/table'
import { cn } from '~/utils/cn'
import Icon from '~/components/atomic/Icon'
import { ColumnVisibility } from './ColumnVisibility'
import { Row } from '~/styles'

export const ComplexTable = (props: Props) => {
  const [globalFilter, setGlobalFilter] = useState('')
  const {
    data,
    columns,
    withGlobalFilter,
    withPagination,
    withColumnVisiblity,
    defaultColumnVisibility,
    resultsPerPage,
    className,
  } = props
  const [sorting, setSorting] = useState<SortingState>([])

  const table = useReactTable({
    data,
    columns,
    initialState: {
      columnVisibility: defaultColumnVisibility,
    },
    state: {
      globalFilter,
      sorting,
    },
    onGlobalFilterChange: setGlobalFilter,
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: withPagination && getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  })

  // workaround for active react-table TypeError: https://github.com/TanStack/table/issues/4280
  const handleFilter = useCallback((value: string) => {
    if (typeof value !== 'number') {
      setGlobalFilter(value)
    }
  }, [])

  return (
    <>
      {withGlobalFilter && (
        <GlobalFilter filterValue={globalFilter} handleFilter={handleFilter} />
      )}

      {withColumnVisiblity && (
        <Row className='flex-wrap py-12 text-13 text-ink-700 sticky top-0 justify-end'>
          <ColumnVisibility table={table} />
        </Row>
      )}

      <TableLayout className={className}>
        <TableLayout.THead>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableLayout.TR key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const align = (header.column.columnDef.meta as Meta)?.align || 'right'
                const width = (header.column.columnDef.meta as Meta)?.width || 'initial'
                return (
                  <TableLayout.TH
                    key={header.id}
                    colSpan={header.colSpan}
                    className={cn('text-' + align, width, 'sticky top-0')}
                  >
                    {header.isPlaceholder ? null : (
                      <Row
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none'
                            : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: (
                            <Icon
                              icon={'arrow_upward'}
                              size={12}
                              className='opacity-50 ml-6'
                            />
                          ),
                          desc: (
                            <Icon
                              icon={'arrow_downward'}
                              size={12}
                              className='opacity-50 ml-6'
                            />
                          ),
                        }[header.column.getIsSorted() as string] ?? null}
                      </Row>
                    )}
                  </TableLayout.TH>
                )
              })}
            </TableLayout.TR>
          ))}
        </TableLayout.THead>
        <TableLayout.TBody>
          {table.getRowModel().rows.map((row) => (
            <TableLayout.TR className='hover:bg-ink-100' key={row.id}>
              {row.getVisibleCells().map((cell) => {
                const align = (cell.column.columnDef.meta as Meta)?.align || 'right'
                return (
                  <TableLayout.TD key={cell.id} className={'text-' + align}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableLayout.TD>
                )
              })}
            </TableLayout.TR>
          ))}
        </TableLayout.TBody>
        <TableLayout.TFooter>
          {table.getFooterGroups().map((footerGroup) => (
            <TableLayout.TR key={footerGroup.id}>
              {footerGroup.headers.map((cell) => {
                const align = (cell.column.columnDef.meta as Meta)?.align || 'right'

                return (
                  <TableLayout.TD key={cell.id} className={'text-' + align}>
                    {cell.isPlaceholder
                      ? null
                      : flexRender(cell.column.columnDef.footer, cell.getContext())}
                  </TableLayout.TD>
                )
              })}
            </TableLayout.TR>
          ))}
        </TableLayout.TFooter>
      </TableLayout>

      {withPagination && (
        <Pagination
          pageIndex={table.getState().pagination.pageIndex}
          previousPage={table.previousPage}
          getCanPreviousPage={table.getCanPreviousPage}
          nextPage={table.nextPage}
          getCanNextPage={table.getCanNextPage}
          getPageCount={table.getPageCount}
          setPageSize={table.setPageSize}
          pageSize={resultsPerPage}
        />
      )}
    </>
  )
}
