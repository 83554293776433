import { ScienceNav } from './Nav'
import { Button } from '~/components/atomic/Button'
import { SimpleTable } from '~/components/atomic/table/SimpleTable'
import { PATH } from '~/utils/constants'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { getPublications, getSourceUrl } from './utils'

const columns = [
  {
    Header: 'Publication Title',
    accessor: 'title',
    align: 'left',
    className: 'max-w-md',
    Cell: ({ value }) => {
      return <div className='my-24 truncate'>{value}</div>
    },
  },
  {
    Header: 'Journal',
    accessor: 'journal',
    align: 'left',
    Cell: ({ value }) => <div>{value}</div>,
  },
  {
    Header: 'Year',
    accessor: 'year',
    align: 'center',
    Cell: ({ value }) => <div>{value}</div>,
  },
  {
    Header: 'Source Link',
    accessor: 'sourceUrl',
    align: 'center',
    Cell: ({ value }) => (
      <div className='flex justify-center'>
        <Button onClick={() => window?.open(getSourceUrl(value))} icon='link' />
      </div>
    ),
  },
  {
    Header: 'Authors',
    accessor: 'authors',
    align: 'left',
    className: 'max-w-[200px]',
    Cell: ({ value }) => <div className='truncate'>{value}</div>,
  },
  {
    Header: '',
    accessor: '_id',
    align: 'right',
    Cell: ({ value }) => (
      <div className='flex justify-end'>
        <Button icon='edit' to={`${PATH['/science-database/publication']}/${value}`}>
          Edit
        </Button>
      </div>
    ),
  },
]

const ScienceDatabase = () => {
  const [data, setData] = useState([])

  const navigate = useNavigate()
  const onNewPublicationClick = () => navigate(PATH['/science-database/publication'])

  const addPublicationButton = (
    <Button variant='primary' onClick={onNewPublicationClick}>
      Add New Publication
    </Button>
  )

  useEffect(() => {
    const setup = async () => {
      const data = await getPublications()
      setData(data.map((d) => d.literaturePublication))
    }

    setup()
  }, [])

  return (
    <div>
      <ScienceNav actions={addPublicationButton}></ScienceNav>
      <main>
        <SimpleTable columns={columns} data={data} stickyHeader />
      </main>
    </div>
  )
}

export { ScienceDatabase }
