import { PropsWithChildren, useEffect } from 'react'
import { ErrorBoundary } from '~/components/atomic/ErrorBoundary'

import { useActiveLandProject } from '~/state'
import { Helmet } from 'react-helmet'
import { getLandProjectName } from '~/models/landProject'
import * as analytics from '~/services/analytics'

type Props = PropsWithChildren<{
  title?: string
}>

export function ProjectTab(props: Props) {
  const { title, children } = props
  const { landProject } = useActiveLandProject()
  const name = getLandProjectName(landProject)

  useEffect(() => {
    analytics.trackView(`Tab: ${title}`)
  }, [])

  return (
    <ErrorBoundary>
      <Helmet>
        <title>{title ? `${title} / ${name}` : 'LandOS'}</title>
      </Helmet>
      {children}
    </ErrorBoundary>
  )
}
