import TagImageView from '~/components/project/TagImageView'
import { ESInputImage } from '~/components/forms/ESInputImage'
import { useState } from 'react'
import { useActiveLandProject } from '~/state/index'
import { Column } from '~/styles/box'
import { saveProjectData } from '~/services/db'
import { SaveRow } from '~/components/forms/FormRenderer'
import { Section } from '~/styles/main'

export function DataPhotoView() {
  const { landProject } = useActiveLandProject()

  const [projectImages, setProjectImages] = useState(landProject.images)
  const [saving, setSaving] = useState(false)

  async function onSave(e) {
    e.preventDefault()

    setSaving(true)
    let newData
    newData = {
      ...landProject,
      images: projectImages,
    }

    await saveProjectData(newData)
    setSaving(false)
  }

  return (
    <Section title='Project Photos'>
      <div className='m-40 pb-40'>
        <ESInputImage addUntaggedImages={setProjectImages} />
        <TagImageView images={projectImages} onChangeImages={setProjectImages} />
      </div>
      <SaveRow submitting={saving} onClick={onSave} />
    </Section>
  )
}
