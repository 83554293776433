import { Row } from '~/styles'
import { PropsWithChildren } from 'react'
import Icon from '~/components/atomic/Icon'
import { badgeNoTextVariants, badgeVariants, ColorVariants } from '~/styles/colors'
import { cn } from '~/utils/cn'

type Props = PropsWithChildren<{
  icon?: string
  variant?: ColorVariants
  className?: string
}>

export function InfoBox(props: Props) {
  const { icon = 'info', variant = 'default', className, children } = props

  return (
    <Row
      className={cn(
        'rounded-md p-12 ',
        { [badgeVariants[variant]]: children },
        { [badgeNoTextVariants[variant]]: !children },
        className
      )}
    >
      {icon && <Icon icon={icon} className='mr-8' />}
      {children}
    </Row>
  )
}
