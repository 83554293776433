import { useState, useEffect } from 'react'

import ESHttp from '~/utils/ESHttp'

const _routeIds = []

function E2EUser() {
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState([])

  useEffect(() => {
    GetUser()
  }, [])

  function GetUser() {
    const authedUser = JSON.parse(localStorage.getItem('authedUser') as any)
    ESHttp.Go('POST', 'e2eUser', { userId: authedUser._id })
      .then((res) => res.json())
      .then((data) => {
        setUser(data.user)
      })
  }

  function RenderUser() {
    const userInfo = Object.keys(user).map((key, index) => {
      return (
        <div key={index}>
          <label>
            {key}
            <input placeholder={key} defaultValue={user[key]} />
          </label>
        </div>
      )
      // return <div key={index}><label>{ key }<input placeholder={ key } /></label></div>
      // return <div key={index}><span>{ key }</span> <span className={ key }>{ user[key] }</span></div>
    })
    return (
      <div>
        <div>E2E User</div>
        {userInfo}
      </div>
    )
  }

  return (
    <div>
      <RenderUser />
    </div>
  )
}

export { E2EUser }
