import { cn } from '~/utils/cn'
import { PropsWithChildren } from 'react'
import { ActiveMatchTypes, Pressable } from '~/components/atomic/Pressable'
import Logo from '~/components/core/Logo'

type Props = PropsWithChildren<{
  className?: string
}>

export type NavItem = {
  icon: string
  to?: string
  href?: string
  activeMatch: ActiveMatchTypes
  label: string
}

export function Nav(props: Props) {
  const { className, children } = props

  return (
    <>
      <div
        className={cn(
          'border-black-100 flex h-[60px] min-h-[60px] w-full flex-row items-center border-b bg-white px-12 md:pl-20 md:pr-16',
          className
        )}
      >
        {children}
      </div>
    </>
  )
}

const LogoButton = ({ to = '/' }) => (
  <Pressable to={to} className='shrink-0' isActive={false}>
    <Logo />
  </Pressable>
)

const HomeButton = (p) => (
  <Pressable icon='home' to='/projects' className={`-mx-8 !p-8 !px-8`} {...p} />
)

const ProjectTitle = ({ landProject, className = '' }) => (
  <h1 className={`max-w-[50vw] md:max-w-[25vw] truncate text-15 font-bold ${className}`}>
    {landProject?.name}
  </h1>
)

Nav.LogoButton = LogoButton
Nav.HomeButton = HomeButton
Nav.ProjectTitle = ProjectTitle
