import { Button } from '~/components/atomic/Button'
import Input, {
  errorClasses,
  focusClasses,
  inputBoxClasses,
} from '~/components/atomic/Input'
import { DialogContentProps } from '~/routes/project/carbon/arr/dialogs/ProjectionInputsDialogs'
import { LandProjectStats, LandProjectStatsByYear } from '~/models/landProjectStats'
import { ArrComputeModelKeys } from '~/models/landProjectArrStats'
import { Projection } from '~/models/projection'
import { logger } from '~/services/logger'
import { state, useSnapshot } from '~/state'
import {
  carbonState,
  saveCustomProjection,
} from '~/routes/project/carbon/arr/arrCarbonStore'
import { getFormData, isNil, makeArray } from '~/utils'
import { cleanStringNumber } from '~/utils/units'
import { useActiveLandProject } from '~/state'
import { getLandProjectArea } from '~/models/landProject'
import { ErrorMessage } from '~/components/atomic/ErrorMessage'
import { Column, Row } from '~/styles'
import { useRef, useState } from 'react'
import { cn } from '~/utils/cn'
import { getUpdatedByMetaData } from '~/models/authedUser'
import { DEFAULT_PROJET_PERIOD } from '~/utils/constants'

type FormData = {
  meanTCO2e: string
  minTCO2e: string
  maxTCO2e: string
  documentationLink: string
}
export function LiteratureModelDialog(props: DialogContentProps) {
  const { landProject } = useActiveLandProject()
  const area = getLandProjectArea(landProject)
  const [errors, setErrors] = useState<any>(null)
  const snap = useSnapshot(carbonState)

  const literatureBasedProjection = snap.projections.find(
    (i) => i.snapshotMainInterventionKey === ArrComputeModelKeys.reforestation_literature
  ) as Projection

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    const formData = getFormData<FormData>(e.currentTarget)
    const { meanTCO2e, minTCO2e, maxTCO2e, documentationLink } = formData

    const meanTCO2eValues = parseAndValidateGroup(meanTCO2e).values
    const minTCO2eValues = parseAndValidateGroup(minTCO2e).values
    const maxTCO2eValues = parseAndValidateGroup(maxTCO2e).values

    const byYear: LandProjectStatsByYear = meanTCO2eValues.map((tCO2PerHa, index) => {
      const tCO2 = tCO2PerHa * area

      return {
        year: index + 1,
        tCO2: tCO2,
        tCO2Min: minTCO2eValues?.[index] * area || null,
        tCO2Max: maxTCO2eValues?.[index] * area || null,
        tCO2PerHa,
        tCO2PerHaMin: minTCO2eValues?.[index] || null,
        tCO2PerHaMax: maxTCO2eValues?.[index] || null,
      }
    })

    const payload: Partial<LandProjectStats> = {
      byYear,
      areaHa: area,
      landProjectId: state.activeLandProjectId,
      snapshotMainInterventionKey: ArrComputeModelKeys.reforestation_literature,
      documentationLink,
      updatedBy: getUpdatedByMetaData(),
    }

    logger.info('LiteratureModelDialog -> onSubmit payload ', payload)

    saveCustomProjection(payload, landProject).catch(logger.error)
  }

  function onChange(e: React.ChangeEvent<HTMLFormElement>) {
    const inputName = e.target.name
    const inputValue = e.target.value
    const setErrorMessage = (message: string) =>
      setErrors((state) => ({ ...state, [inputName]: message }))

    const { values, isValid } = parseAndValidateGroup(inputValue)

    if (isNil(values)) return setErrorMessage(null)
    if (values.length > DEFAULT_PROJET_PERIOD)
      return setErrorMessage(`${values.length - DEFAULT_PROJET_PERIOD} extra values`)
    if (values.length < DEFAULT_PROJET_PERIOD)
      return setErrorMessage(`Missing ${DEFAULT_PROJET_PERIOD - values.length} values`)
    if (!isValid) return setErrorMessage('Invalid values')
    if (values.length === DEFAULT_PROJET_PERIOD) return setErrorMessage(null)
  }

  if (!area) return <ErrorMessage error='Please upload polygons to calculate area' />

  return (
    <form
      onSubmit={onSubmit}
      onChange={onChange}
      className='w-full h-[80vh] overflow-auto'
    >
      <div className='mb-12 text-16 font-medium'>
        Please paste tCO2eq/ha data from the literature review of this project
      </div>

      <Input
        name='documentationLink'
        defaultValue={literatureBasedProjection?.documentationLink}
        label='Link to documentation (public access to .pdf file)'
        placeholder='e.g. https://drive.google.com....'
        className='mb-24'
      />

      <Row className='space-x-24'>
        <CustomTextarea
          label='Mean tCO2e/ha*'
          required
          name='meanTCO2e'
          defaultValue={projectionTCO2PerHaToString(
            literatureBasedProjection,
            'tCO2PerHa'
          )}
          errors={errors}
        />
        <CustomTextarea
          label='Min tCO2e/ha'
          name='minTCO2e'
          errors={errors}
          defaultValue={projectionTCO2PerHaToString(
            literatureBasedProjection,
            'tCO2PerHaMin'
          )}
        />
        <CustomTextarea
          label='Max tCO2e/ha'
          name='maxTCO2e'
          errors={errors}
          defaultValue={projectionTCO2PerHaToString(
            literatureBasedProjection,
            'tCO2PerHaMax'
          )}
        />
      </Row>

      <Button type='submit' variant='primary' className='mt-20'>
        Save
      </Button>
    </form>
  )
}

const CustomTextarea = (props) => {
  const { errors, name, label, ...rest } = props
  const ref = useRef<HTMLTextAreaElement>(null)
  const [focused, setFocused] = useState(false)

  const error = errors?.[name]

  function onFocus(e) {
    ref.current?.select()
    setFocused(true)
  }
  function onBlur() {
    setFocused(false)
  }

  return (
    <Column>
      <Input.Label>{label}</Input.Label>
      <Row
        className={cn(inputBoxClasses, 'flex relative justify-between p-0', {
          [focusClasses]: focused,
          [errorClasses]: error,
        })}
      >
        <Column className='text-number border-1 border-r py-2 pl-12 pr-12 text-right text-ink-500'>
          {makeArray(DEFAULT_PROJET_PERIOD).map((_, index) => (
            <span
              key={index}
              className='group pointer-events-none text-center leading-relaxed'
            >
              {index + 1}
              <hr className='absolute left-0 right-0 group-last:hidden' />
            </span>
          ))}
        </Column>
        <textarea
          ref={ref}
          onFocus={onFocus}
          onBlur={onBlur}
          name={name}
          className='text-number h-full w-full resize-none overflow-y-hidden rounded-md border-none p-0 py-2 pr-12 text-right text-14 leading-relaxed focus:ring-0'
          {...rest}
        />
      </Row>
      <ErrorMessage className='mt-8' error={error} />
    </Column>
  )
}

function parseAndValidateGroup(group: string) {
  if (isNil(group) || group == '') return { values: null, isValid: false }

  let isValid = true

  const values = group.split('\n').map((v) => {
    const value = cleanStringNumber(v)

    if (isValid && isNaN(value)) isValid = false
    return value
  })

  return {
    values,
    isValid,
  }
}

function projectionTCO2PerHaToString(
  projection: Projection,
  key: 'tCO2PerHa' | 'tCO2PerHaMin' | 'tCO2PerHaMax'
) {
  const mean = []
  if (!projection?.byYear) return null

  projection.byYear.forEach((d) => {
    mean.push(d[key]).toFixed(2)
  })

  return mean.join('\n')
}
