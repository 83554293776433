import { useRef } from 'react'
import Map, { MapProvider } from 'react-map-gl'
import { Button } from '~/components/atomic/Button'
import { Textarea } from '~/components/atomic/Input'
import { fitMapToLayers, MAPBOX_TOKEN, MapLayer } from '~/services/mapbox'

import { Column, Row } from '~/styles'
import * as turf from '@turf/turf'
import { MapControls } from '~/components/map/MapControls'
import { FeatureCollection, unionizeFeatureCollection } from '~/utils/geo'
import { PageTitle } from '~/styles/main'
import { PolygonLayers } from '~/components/map/layers/PolygonLayers'
import { useMapLayers } from '~/services/mapbox'

export function PolygonsToolPage() {
  const { layers, upsertLayer } = useMapLayers([])

  const sourceLayer = layers?.find((l) => l.id == 'source')

  function union() {
    const unioned = unionizeFeatureCollection(sourceLayer.featureCollection)
    const featureCollection = turf.featureCollection([unioned])

    upsertLayer({
      id: 'unioned',
      label: 'Unioned',
      featureCollection,
      theme: 'red',
      visible: true,
    })
  }

  function simplify() {
    const unioned = unionizeFeatureCollection(sourceLayer.featureCollection)
    const featureCollection = turf.featureCollection([unioned])
    const simplified = turf.simplify(featureCollection as any, {
      tolerance: 0.0005,
      highQuality: true,
    })

    upsertLayer({
      id: 'simplified',
      label: 'simplified',
      featureCollection: simplified,
      theme: 'blue',
      visible: true,
    })
  }

  function buffer() {
    const unioned = unionizeFeatureCollection(sourceLayer.featureCollection)
    const featureCollection = turf.featureCollection([unioned])
    const buffered = turf.buffer(featureCollection as any, 2000, { units: 'meters' })

    upsertLayer({
      id: 'buffered',
      label: 'buffered',
      featureCollection: buffered as any,
      theme: 'blue',
      visible: true,
    })
  }

  function difference() {
    const unioned = layers?.find((l) => l.id === 'unioned')?.featureCollection.features[0]

    const toDifference = layers?.find((l) => l.id === 'source')?.featureCollection
      .features[0]

    const difference = turf.difference(unioned as any, toDifference as any)

    upsertLayer({
      id: 'buffered',
      label: 'buffered',
      featureCollection: difference as any,
      theme: 'blue',
      visible: true,
    })
  }

  function onSourceChange(e) {
    if (!e.currentTarget.value) return
    const featureCollection = JSON.parse(e.currentTarget.value) as FeatureCollection
    upsertLayer({
      id: 'source',
      label: 'Source',
      featureCollection,
      theme: 'green',
      visible: true,
    })
  }

  return (
    <Row className='h-full max-h-full overflow-hidden'>
      <Column className='z-20 h-full max-h-full w-[600px] space-y-24 overflow-y-auto p-24 shadow-2xl'>
        <PageTitle>Polygons Tool</PageTitle>
        <Column className='space-y-12'>
          <div className='text-16 font-semibold'>Paste FeatureCollection as text</div>
          <Textarea
            placeholder='paste featureCollection...'
            onChange={onSourceChange}
            className='!h-full max-h-[400px]'
          />
        </Column>

        {sourceLayer && (
          <>
            <Column className='space-y-12'>
              <div className='text-16 font-semibold'>Actions</div>

              <Button icon='add_circle_outline' onClick={union}>
                Unionize
              </Button>
              <Button icon='photo_size_select_small' onClick={simplify}>
                Unionize and Simplify
              </Button>
              <Button icon='space_bar' onClick={buffer}>
                Unionize and Buffer
              </Button>

              <Button icon='remove_circle_outline' onClick={difference}>
                Difference
              </Button>
            </Column>
          </>
        )}
      </Column>

      <div className='h-full w-full relative'>
        <MapSection layers={layers} onLayerChange={(layer) => upsertLayer(layer)} />
      </div>
    </Row>
  )
}

function MapSection(props: { layers: MapLayer[]; onLayerChange: (layer) => void }) {
  const { layers, onLayerChange } = props

  const mapRef = useRef(null)

  function onLoad() {
    fitMapToLayers(mapRef.current, layers)
  }

  return (
    <MapProvider>
      <MapControls.Layers layers={layers} onChange={onLayerChange} />
      <MapControls>
        <MapControls.FitMap
          featureCollectionToFit={
            layers?.find((l) => l.id == 'source')?.featureCollection
          }
        />
      </MapControls>

      <Map
        ref={mapRef}
        attributionControl={false}
        fadeDuration={100}
        mapboxAccessToken={MAPBOX_TOKEN}
        style={{ width: '100%', height: '100%' }}
        cursor={'pointer'}
        onLoad={onLoad}
        mapStyle='mapbox://styles/mapbox/satellite-v9'
      >
        <PolygonLayers layers={layers} />
      </Map>
    </MapProvider>
  )
}
