import { Link } from 'react-router-dom'
import { useSnapshot } from 'valtio'
import { CenteredLoader } from '~/components/atomic/CenteredLoader'
import { Page } from '~/components/layouts/Page'
import { ProjectsMap } from '~/components/map/ProjectsMap'
import { ProjectCard } from '~/components/projects/ProjectCard'
import { getLandProjectName, LandProject } from '~/models/landProject'
import { PortfolioNav } from '~/routes/portfolio/PortfolioNav'
import { useGetPortfolioLandProjects } from '~/services/db'
import { state } from '~/state'
import { Column, Row } from '~/styles'
import { isNil } from '~/utils'

function sortProjectsByCountry(lpa: LandProject, lpb: LandProject) {
  const lpaName = getLandProjectName(lpa).toLowerCase()
  const lpbName = getLandProjectName(lpb).toLowerCase()

  // As requested by ops, they want Kijani, and azuero projects to be at the top
  if (lpaName.includes('panama') && !lpbName.includes('panama')) return -1
  if (!lpaName.includes('panama') && lpbName.includes('panama')) return 1
  if (lpaName.includes('kijani') && !lpbName.includes('kijani')) return -1
  return lpa?.countryCode < lpb?.countryCode ? -1 : 1
}

export function PortfolioPage() {
  const snap = useSnapshot(state)
  const landProjects = snap.landProjects as LandProject[]

  useGetPortfolioLandProjects()

  if (isNil(landProjects) || !landProjects.length) return <CenteredLoader />

  const all = [...landProjects].sort(sortProjectsByCountry)
  const published = all.filter((lp) => lp.publishState === 'published')
  const unlisted = all.filter((lp) => lp.publishState === 'unlisted')

  return (
    <Page title='Projects Portfolio'>
      <Column className='h-full'>
        <PortfolioNav className='relative' />
        <Row className='h-full overflow-hidden'>
          <div className='w-full max-w-[600px] space-y-16 overflow-auto px-20 py-16 '>
            {published.map((landProject) => (
              <ProjectCardItem landProject={landProject} key={landProject._id} />
            ))}

            {!!unlisted.length && (
              <>
                <div className='pt-40 text-16'>
                  <span className='font-bold'>Unlisted</span>
                  <span className='ml-12 opacity-50'>(Only visible to admins)</span>
                </div>

                {unlisted.map((landProject) => (
                  <ProjectCardItem landProject={landProject} key={landProject._id} />
                ))}
              </>
            )}
          </div>

          <div className='mx-20 my-16 ml-0 flex-1 overflow-hidden rounded-lg border border-ink-200'>
            <ProjectsMap landProjects={all} />
          </div>
        </Row>
      </Column>
    </Page>
  )
}

function ProjectCardItem({ landProject }) {
  return (
    <div>
      <Link to={`${landProject.uName}`}>
        <ProjectCard landProject={landProject} />
      </Link>
    </div>
  )
}
