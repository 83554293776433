import React from 'react'
import { useLandProjectsContext } from '~/routes/projects/hooks'

import { groupBy, sumArray } from '~/utils'
import { CenteredLoader } from '~/components/atomic/CenteredLoader'
import { BarGraph } from '~/components/core/Graphs'
import { LandProject } from '~/models'
import { getFormsCompletion } from '~/models/projectDatum'
import { getScopingAssessmentForms } from '~/models/form'
import { formatPercent } from '~/utils/units'
import { SimpleTable } from '~/components/atomic/table/SimpleTable'
import { Numerical } from '~/components/core/Numerical'
import { Section } from '~/styles/main'

export function ProjectsStats() {
  const { landProjects, loading } = useLandProjectsContext()

  if (loading) return <CenteredLoader />

  const goNoGoForms = getScopingAssessmentForms()

  landProjects.forEach((lp) => ({}))

  const byLead = groupBy(landProjects, (lp) => lp.projectLead)

  const grouped = Object.entries(byLead).map((entries) => {
    const [key, lps] = entries as [string, LandProject[]]
    const byPriority = groupBy(lps, (lp) => lp.priority)

    const completionRates = lps.map((lp) => {
      const formCompletion = getFormsCompletion(goNoGoForms, lp)
      return formCompletion.percent
    })

    return {
      projectLead: key,
      count: lps.length,
      'no-priority': byPriority['undefined']?.length,
      low: byPriority['3']?.length,
      med: byPriority['2']?.length,
      high: byPriority['1']?.length,
      avgCompletionRate: sumArray(completionRates) / completionRates.length,
    }
  })

  return (
    <Section title='Project Stats' className='pb-80'>
      <BarGraph
        data={grouped}
        keys={['count']}
        indexBy='projectLead'
        enableLabel={true}
        label={(d) =>
          `count: ${d.data.count} |: ${formatPercent(d.data.avgCompletionRate)}%`
        }
      />

      <BarGraph
        data={grouped}
        keys={['no-priority', 'low', 'med', 'high']}
        indexBy='projectLead'
        enableLabel={true}
        colors={{ scheme: 'spectral' }}
        label={(d) => d.data[d.id]}
      />

      <SimpleTable
        columns={[
          { accessor: 'projectLead', Header: 'Project Lead' },
          { accessor: 'count', Header: 'Count' },
          { accessor: 'no-priority', Header: 'No-priority' },
          { accessor: 'low', Header: 'Low' },
          { accessor: 'medium', Header: 'Medium' },
          { accessor: 'high', Header: 'High' },
          {
            accessor: 'avgCompletionRate',
            Header: 'Avg. Completion %',
            Cell: ({ cell: { value } }) => (
              <Numerical type='percent' value={value / 100} />
            ),
          },
        ]}
        data={grouped}
      />
    </Section>
  )
}
