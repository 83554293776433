import Input from '~/components/atomic/Input'
import { SegmentedControl } from '~/components/atomic/SegmentedControl'
import {
  updateStoreVariable,
  useFinancialsStore,
} from '~/routes/project/financials/financialsStore'
import { Row } from '~/styles'

import { capitalizeFirstLetter } from '~/utils'

const carbonPriceScenarioOptions = ['low', 'medium', 'high'].map((i) => ({
  value: i,
  label: capitalizeFirstLetter(i),
}))

export function CarbonScenarioSelector() {
  const carbonPriceScenario = useFinancialsStore().variables.carbonPriceScenario

  return (
    <Row className='row-vcenter'>
      <Input.Label className='mr-12'>Carbon Price</Input.Label>
      <SegmentedControl
        name='carbonPriceScenario'
        defaultValue={carbonPriceScenario}
        options={carbonPriceScenarioOptions}
        onValueChange={(value) => updateStoreVariable(value, 'carbonPriceScenario')}
      />
    </Row>
  )
}
