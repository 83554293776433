import { Loader } from '~/components/atomic/Loader'

export const CenteredLoader = ({ className, children, ...rest }: any) => (
  <div className={`center flex h-[30vh] w-full ${className}`} {...rest}>
    {children ? (
      <div className='center flex-col'>
        <Loader />
        <div className='mt-20 text-14 font-medium text-center'>{children}...</div>
      </div>
    ) : (
      <Loader />
    )}
  </div>
)
