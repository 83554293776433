import { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { useSnapshot } from 'valtio'
import { LandProject } from '~/models'
import { getProjectsFiltersInUrl } from '~/routes/projects/projectsFilters'
import { fetchDB } from '~/services/db'
import { logger } from '~/services/logger'
import { state } from '~/state'

let lastFiltersWhenFetching = {}

export function useFetchLandProjects(projectType?: string) {
  const snap = useSnapshot(state)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const authedUser = snap.authedUser
  const landProjects = snap.landProjects

  useEffect(() => {
    async function fetch() {
      const sameFilters =
        JSON.stringify(getProjectsFiltersInUrl()) ===
        JSON.stringify(lastFiltersWhenFetching)

      if (!sameFilters || state.landProjects.length === 0) {
        setLoading(true)
        state.landProjects = []
      }

      lastFiltersWhenFetching = getProjectsFiltersInUrl()

      const payload = {
        userId: authedUser._id,
        withPermissions: 1,
        withStats: 1,
        projectType,
      }

      logger.info('fetchLandProjects', payload)
      try {
        const data = await fetchDB<{ landProjects: LandProject[] }>(
          'getLandProjects',
          payload
        )
        const { valid, msg, landProjects } = data

        if (valid === 1) {
          state.landProjects = landProjects
          logger.info('onFetchLandProjects', landProjects)
        } else {
          throw msg || 'error fetching land projects'
        }
      } catch (error) {
        logger.error('onFetchLandProjects', error)
        setError(error)
      } finally {
        setLoading(false)
      }
    }

    if (authedUser) fetch()
  }, [authedUser, projectType])

  return { loading, landProjects, error }
}

type LandProjectsContext = {
  landProjects: LandProject[]
  loading: boolean
  error: any
}

export function useLandProjectsContext() {
  return useOutletContext<LandProjectsContext>()
}
