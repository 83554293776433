import { Column, Row } from '~/styles'
import { useActiveLandProject } from '~/state'
import { P, Title } from '~/components/profile/shared'
import { cn } from '~/utils/cn'
import { getLandProjectDatum } from '~/models/projectDatum'
import { capitalizeFirstLetter } from '~/utils'

// Additionality Permanence Leakage
type AddPermLeakRatings = 'very-low' | 'low' | 'medium' | 'high' | 'very-high'
type AddPermLeakKeys = 'permanence' | 'additionality' | 'leakage'

export function AdditionalityPermanenceLeakage() {
  return (
    <Column className='space-y-40'>
      <AdditionalityPermanenceLeakageItem type='permanence' />
      <AdditionalityPermanenceLeakageItem type='additionality' />
      <AdditionalityPermanenceLeakageItem type='leakage' />
    </Column>
  )
}

function AdditionalityPermanenceLeakageItem(props: { type: AddPermLeakKeys }) {
  const { type } = props
  const { landProject } = useActiveLandProject()

  const descriptionDatum = getLandProjectDatum(landProject, `${type}-description`)
  const ratingDatum = getLandProjectDatum(landProject, `${type}-rating`)

  const ratingValue = ratingDatum?.value as AddPermLeakRatings
  const hasInversedColoring = type === 'leakage'
  const colors = hasInversedColoring
    ? ratingToColorsInversed[ratingValue]
    : ratingToColors[ratingValue]

  const title = capitalizeFirstLetter(type)

  return (
    <Column>
      <Row className='mb-4 items-baseline'>
        <Title className='mr-12'>{title}</Title>
        <div className={cn(colors, 'rounded-full px-12 py-4 font-medium')}>
          {ratingToLabel(ratingValue)}
        </div>
      </Row>
      <P>{descriptionDatum?.value}</P>
    </Column>
  )
}

function ratingToLabel(rating: AddPermLeakRatings) {
  return capitalizeFirstLetter(rating?.replace('-', ' '))
}

const colors = {
  red: 'bg-[#FFB1B1] text-[#4D0915] border border-red-300',
  yellow: 'bg-[#FFEDAD] text-[#4D3A09] border border-yellow-400',
  green: 'bg-[#CCEBAE] text-[#3C5E1A] border border-lime-500',
}

const ratingToColors = {
  'very-low': colors.red,
  low: colors.red,
  medium: colors.yellow,
  high: colors.green,
  'very-high': colors.green,
}

const ratingToColorsInversed = {
  'very-low': colors.green,
  low: colors.green,
  medium: colors.yellow,
  high: colors.red,
  'very-high': colors.red,
}
