import { H3, VerticalStatsRow } from '~/components/profile/shared'
import { calculateCarbonStats } from '~/models/landProject'
import { useActiveLandProject } from '~/state'

import { Column, Row } from '~/styles'
import { formatNumber } from '~/utils/units'

type Props = {
  className?: string
  hideTitle?: boolean
}

export function CarbonStats(props: Props) {
  const { className, hideTitle } = props
  const { landProject } = useActiveLandProject()

  const { totalProduction30yrs, averageAnual, avgPerHaPerYear } =
    calculateCarbonStats(landProject)

  const carbonStats = [
    {
      label: 'Over 30 years',
      value: formatNumber(totalProduction30yrs),
      unit: 'tCO2eq',
    },
    {
      label: 'Average per year',
      value: formatNumber(averageAnual),
      unit: 'tCO2eq/yr',
    },
    {
      label: 'Annual per hecatare',
      value: formatNumber(avgPerHaPerYear, 2),
      unit: 'tCO2eq/ha/yr',
      decimals: 2,
    },
  ]

  return (
    <Column className={className}>
      <Row>{!hideTitle && <H3>Carbon Credit Production</H3>}</Row>
      <VerticalStatsRow stats={carbonStats} />
    </Column>
  )
}
