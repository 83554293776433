import { CenteredLoader } from '~/components/atomic/CenteredLoader'
import { Fields, ProjectDataForm } from '~/components/forms/FormRenderer'
import { ES_Form } from '~/models/form'
import { useActiveLandProject } from '~/state'
import { Section } from '~/styles/main'
import { getFormData } from '~/utils'

type Props = {
  form: ES_Form
  isIntake: boolean
}
export function ProjectDataFormView(props: Props) {
  const { form, isIntake } = props
  const { landProject } = useActiveLandProject()

  if (!landProject) return <CenteredLoader />

  const fields = form.getFieldsWithProjectData(landProject, isIntake)

  return (
    <ProjectDataForm saveTarget='datums' parseFormData={(form) => getFormData(form)}>
      <Section.Title>{form.label}</Section.Title>
      <Fields fields={fields} />
    </ProjectDataForm>
  )
}
