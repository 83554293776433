import { useEffect, useState } from 'react'
import { Content } from '~/styles/main'
import { LandProjectImage, LandProjectImageTags } from '~/models/landProject'
import { Button } from '~/components/atomic/Button'
import Select from '../atomic/Select'
import { Row } from '~/styles'
import { Checkbox } from '~/components/atomic/Checkbox'
import { Option } from '~/types'

export default function TagImageView(props: any) {
  const { images = [] } = props
  const [taggedImages, setTaggedImages] = useState([])
  const [selectedImages, setSelectedImages] = useState([])
  const onChangeImages = props.onChangeImages

  useEffect(() => {
    setTaggedImages(
      images.map((image) => {
        return { url: image.url, tag: image.tag ?? '' }
      })
    )
  }, [images])

  function onTagChangeCallback(tag: string, newImage: LandProjectImage) {
    const index = taggedImages.findIndex((image) => image.url === newImage.url)
    newImage['tag'] = tag as LandProjectImageTags

    const newTaggedImages = [...taggedImages]
    setTaggedImages([
      ...newTaggedImages.slice(0, index),
      newImage,
      ...newTaggedImages.slice(index + 1),
    ])

    onChangeImages(newTaggedImages)
  }

  function onDeleteClick() {
    const newTaggedImages = [...taggedImages]
    const imagesToDelete = newTaggedImages.filter((images) =>
      selectedImages.includes(images.url)
    )
    imagesToDelete.forEach((image) =>
      newTaggedImages.splice(newTaggedImages.indexOf(image), 1)
    )

    setTaggedImages(newTaggedImages)
    onChangeImages(newTaggedImages)
    setSelectedImages([])
  }

  function onSelectChangeCallback(imageUrl) {
    let newSelectedImages = [...selectedImages]

    const wasSelected = selectedImages.includes(imageUrl)
    wasSelected === true
      ? (newSelectedImages = selectedImages.filter((urls) => urls !== imageUrl))
      : (newSelectedImages = [...newSelectedImages, imageUrl])

    setSelectedImages(newSelectedImages)
  }

  return (
    <Content className='grid-col-4 grid'>
      {taggedImages.length > 0 && (
        <ul>
          {taggedImages.map((image: LandProjectImage, index) => {
            return (
              <EditableImage
                image={image}
                onTagChangeCallback={onTagChangeCallback}
                onSelectChangeCallback={onSelectChangeCallback}
                key={image.url}
              />
            )
          })}
        </ul>
      )}
      <Button variant='secondary' onClick={onDeleteClick}>
        Delete {selectedImages.length} {selectedImages.length > 1 ? 'Photos' : 'Photo'}
      </Button>
    </Content>
  )
}

function EditableImage(props) {
  const image = props.image
  const [isSelected, setIsSelected] = useState(false)

  enum Tags {
    None = 'None',
    People = 'People',
    Nature = 'Nature',
    Panorama = 'Panorama',
    Featured = 'Featured',
    Default = 'Other',
  }

  const tagOptions: Option[] = Object.entries(Tags).map((tag) => ({
    value: tag[1],
    label: tag[1],
  }))

  function toggleSelect() {
    setIsSelected(!isSelected)
    props.onSelectChangeCallback(image.url)
  }

  return (
    <div className='m-4 inline-block space-y-4 rounded-md border border-ink-200 p-8 '>
      <img src={image.url} alt={image.url} width={250} />
      <Row className='row-vcenter place-content-between'>
        <Row className='row-vcenter space-x-6 '>
          <label htmlFor={image.url}>Tag:</label>
          <Select
            options={tagOptions}
            onChange={(e) => props.onTagChangeCallback(e.target.value, image)}
            defaultValue={image.tag}
          ></Select>
        </Row>
        <Checkbox checked={isSelected} onChange={toggleSelect} />
      </Row>
    </div>
  )
}
