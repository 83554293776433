import { forms } from '~/data/forms'
import { LandProject } from '~/models'
import { FormField } from './formField'
import { getProjectDataFieldsByFormId } from '~/models/projectDatum'

//prettier-ignore
const formNames = [ 'basic-info', 'initial-questionnaire', 'project-info', 'project-summary', 'short-profile', 'baseline', 'leakage', 'additionality', 'permanence', 'carbon', 'certification', 'community-gng', 'financials', 'implementation', 'regulatory', 'sdg-benefits', 'project-proponent', 'scoping-evaluation' ] as const

export type FormId = typeof formNames[number]

export class ES_Form {
  id: FormId
  name: FormId
  tags: string[]
  label: string
  fields: FormField[]

  constructor(form: Partial<ES_Form>) {
    return Object.assign(this, form)
  }

  getFieldsWithProjectData(landProject: LandProject, showManual: boolean) {
    return getProjectDataFieldsByFormId(this.id, landProject, showManual)
  }
}

export function getFormById(formId: FormId) {
  return forms.find((form) => form.name === formId)
}

export function getFormsByTag(tag: string) {
  return forms.filter((form) => form.tags?.includes(tag))
}

export function getInitalAssessmentForms() {
  return getFormsByTag('initial-assessment')
}

export function getScopingAssessmentForms() {
  return getFormsByTag('scoping-assessment')
}
