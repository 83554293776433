import { useActiveLandProject } from '~/state'
import { ARRCarbonView } from '~/routes/project/carbon/arr/ARRCarbonView'
import { REDDCarbonView } from '~/routes/project/carbon/redd/REDDCarbonView'
import { ProjectTab } from '~/components/layouts/ProjectTab'
import { Section } from '~/styles/main'
import { InfoBox } from '~/components/atomic/InfoBox'

export function CarbonTab() {
  const { landProject } = useActiveLandProject()

  return tabs[landProject.projectType] || <NonApplicable />
}

const tabs = {
  arr: <ARRCarbonView />,
  redd: <REDDCarbonView />,
}

function NonApplicable() {
  return (
    <ProjectTab title='Carbon'>
      <Section>
        <InfoBox variant='yellow'>
          We don't support automatic carbon projections for this project type
        </InfoBox>
      </Section>
    </ProjectTab>
  )
}
