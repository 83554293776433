import {
  CarbonPriceScenarios,
  getPredictedCarbonPrice,
} from '~/data/financials/carbonPricePrediction'
import { VCUS } from '~/types/vcus'
import { ProjectType } from '~/models/landProject'
import { DEFAULT_PROJET_PERIOD } from '~/utils/constants'
import { IRR } from '~/utils/irr'

export type InvestorCashflow = {
  investment: number
  irr: number
  profit: number
  totalBrokerage: number
  data: CashflowData[]
  totalInvestorVcus: number
  totalRevenue: number
  totalCosts: number
}

type CashflowData = {
  year: number
  calendarYear: number
  investorVCUs: number
  carbonPrice: number
  revenue: number
  balance: number
  investment: number
  creditShare: number
  brokerage: number
  costs: number
}

type CommonParams = {
  investorExitYear: number
  investorCreditSharePostExit: number
  startYear: number
  carbonPriceScenario: CarbonPriceScenarios
  investorCreditShare: number
  investmentDistributions: number[]
  brokerageFee: number
  landPurchase: number
  vcus: VCUS
  projectType: ProjectType
}

type Params = {
  investmentAmounts: number[]
} & CommonParams

export function calculateInvestorCashflows(params: Params): InvestorCashflow[] {
  const { investmentAmounts, ...commonParams } = params
  const investorCashflows = investmentAmounts.map((investment) => {
    return calculateInvestorCashflow({ investment, ...commonParams })
  })

  return investorCashflows
}

export function calculateInvestorCashflow(
  params: { investment: number } & CommonParams
): InvestorCashflow {
  const {
    startYear,
    projectType,
    investment,
    investorExitYear,
    investorCreditShare,
    investorCreditSharePostExit,
    vcus,
    brokerageFee,
    investmentDistributions,
    carbonPriceScenario,
    landPurchase,
  } = params

  const data: CashflowData[] = []
  const cashflow = []

  let profit = 0
  let totalInvestorVcus = 0
  let totalRevenue = 0
  let totalBrokerage = 0
  let totalCosts = 0

  for (let year = 0; year < DEFAULT_PROJET_PERIOD; year++) {
    const calendarYear = year + startYear
    const carbonPrice = getPredictedCarbonPrice(
      calendarYear,
      projectType,
      carbonPriceScenario
    )

    const investmentDistribution = (investmentDistributions[year] || 0) * investment
    const brokerage = investmentDistribution * brokerageFee
    const vcusIssued = vcus[year]?.issued || 0
    let creditShare = investorCreditShare
    if (investorExitYear)
      creditShare =
        year > investorExitYear ? investorCreditSharePostExit : investorCreditShare

    const investorVCUs = vcusIssued * creditShare

    const costs = investmentDistribution + brokerage

    const revenue = investorVCUs * carbonPrice

    let balance = -costs + revenue

    if (year == 0) {
      balance -= +landPurchase
    }

    const yearRow: CashflowData = {
      year,
      calendarYear,
      investorVCUs,
      carbonPrice,
      costs,
      revenue,
      balance,
      brokerage,
      creditShare,
      investment: investmentDistribution,
    }

    cashflow.push(balance)
    data.push(yearRow)

    profit += balance
    totalInvestorVcus += investorVCUs
    totalBrokerage += brokerage
    totalRevenue += revenue
    totalCosts += costs
  }

  const irr = IRR(cashflow)

  return {
    investment,
    profit,
    totalInvestorVcus,
    totalBrokerage,
    totalRevenue,
    totalCosts,
    irr,
    data,
  }
}

// Converts the investorCashflow data structure to be used in a table
export function transposeInvestorCashflowForTable(
  investorCashflows: InvestorCashflow[],
  startYear: number
) {
  const rows = []

  for (let year = 0; year < DEFAULT_PROJET_PERIOD; year++) {
    const row = {
      year,
      yearDate: year + startYear,
    }

    investorCashflows.forEach((cashflow, index) => {
      row[`investment${index}`] = cashflow.data[year].balance
    })

    rows.push(row)
  }
  return rows
}
