import { useCallback, useEffect, useState } from 'react'
import { useSnapshot } from 'valtio'
import { Button, ButtonVariants } from '~/components/atomic/Button'
import { Dialog } from '~/components/atomic/Dialog'
import { BlockingListener, useNavigationBlocker } from '~/router'
import { state } from '~/state'
import { Row } from '~/styles'

export type ConfirmDialogProps = {
  content: any
  onCancel?: () => void
  onConfirm: () => void
  confirmText: string
  cancelText?: string
  confirmVariant?: ButtonVariants
  cancelVariant?: ButtonVariants
  closeAfterConfirm?: boolean
}

export function ConfirmDialog() {
  const { confirming, setConfirming, confirmDialogData, closeConfirmDialog } =
    useConfirmDialog()

  const onClickConfirmAction = () => {
    setConfirming(true)
    confirmDialogData.onConfirm()
    confirmDialogData.closeAfterConfirm && closeConfirmDialog()
  }

  const onClickCancel = () => {
    if (confirmDialogData.onCancel) {
      confirmDialogData.onCancel()
    } else {
      closeConfirmDialog()
    }
  }

  if (!confirmDialogData) return null

  const {
    content,
    confirmText,
    confirmVariant = 'primary',
    cancelVariant,
    cancelText,
  } = confirmDialogData

  return (
    <Dialog
      open={!!confirmDialogData}
      onClickOutside={closeConfirmDialog}
      className='!w-auto !max-w-[600px]'
    >
      <div className='text-16 font-medium'>{content}</div>
      <Row className='mt-12 justify-between space-x-20'>
        <Button variant={cancelVariant || 'secondary'} onClick={onClickCancel}>
          {cancelText || 'Cancel'}
        </Button>

        <Button
          variant={confirmVariant}
          submitting={confirming}
          onClick={onClickConfirmAction}
        >
          {confirmText || 'Confirm'}
        </Button>
      </Row>
    </Dialog>
  )
}

export function useConfirmDialog() {
  const [confirming, setConfirming] = useState<boolean>(false)
  const snap = useSnapshot(state)
  const confirmDialogData = snap.confirmDialogData

  useEffect(() => {
    setConfirming(false)
  }, [confirmDialogData])

  return {
    confirming,
    confirmDialogData,
    setConfirming,
    setConfirmDialog: (confirmDialog: ConfirmDialogProps) => {
      state.confirmDialogData = confirmDialog
    },
    closeConfirmDialog: () => {
      state.confirmDialogData = null
    },
  }
}

type Config = {
  onCancel?: () => void
  onConfirm: () => void
  shouldShowPrompt: boolean
} & Partial<Omit<ConfirmDialogProps, 'onCofirm' | 'onCancel'>>

export function useUnsavedBlockerPrompt(config: Config) {
  const { onCancel, onConfirm, shouldShowPrompt, ...rest } = config
  const { setConfirmDialog, closeConfirmDialog } = useConfirmDialog()

  const blocker: BlockingListener = useCallback(async () => {
    return new Promise((resolve, reject) => {
      setConfirmDialog({
        content: `You are about to leave with unsaved changes`,
        cancelText: `Leave without saving`,
        confirmText: `Save now`,
        confirmVariant: 'primary',
        onConfirm: async () => {
          await onConfirm()
          closeConfirmDialog()
          resolve(true)
        },
        onCancel: async () => {
          await onCancel()
          closeConfirmDialog()
          resolve(true)
        },
        ...rest,
      })
    })
  }, [shouldShowPrompt])

  useNavigationBlocker(shouldShowPrompt, blocker)
}
