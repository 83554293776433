const anrDefaults = {
  id: '123',
  type: 'assistedNaturalRegeneration',
  computeMethod: '',
  name: 'Assisted Natural Regeneration',
  carbonPrice: 12,
  carbonPriceMultiplierPerYear: 1,

  creditType: 'removal',
  // creditStartDate: ''
  // creditEndDate: ''
  // biodiversityBenefits: ''
  // communityBenefits: ''
  // otherBenefits: ''
  // preferredFundingType: ''
  // fundingStart: ''
  // upfrontFundingNeeded: 'Number'
  // otherFundingSources: ''

  costs: {
    registration: 10000,
    validation: 5000,
    verification: 5000,
    consultancyServices: 400000,
    validationServices: 75000,
    verificationServices: 150000,
    carbonCreditIssuancePerTon: 0.05,
    labelPerTon: 0.05,
    managementPerHaPerYear: 136,
  },

  treeSpacingMeters: 3,
  speciesNamesAndPercents: [
    { name: 'enterolobium_cyclocarpum', percent: 20 },
    { name: 'gliricidia_sepium', percent: 20 },
    { name: 'guazuma_ulmifolia', percent: 20 },
    { name: 'manilkara_zapota', percent: 20 },
    { name: 'crescentia_alata', percent: 10 },
    { name: 'myrospermum_frutescens', percent: 10 },
  ],
}

const reforestationProfileDefaults = {
  type: 'reforestation',
  computeMethod: '',
  name: 'one',
  carbonPrice: 12,
  carbonPriceMultiplierPerYear: 1,
  treeSpacingMeters: 3,
  speciesNamesAndPercents: [
    { name: 'enterolobium_cyclocarpum', percent: 20 },
    { name: 'gliricidia_sepium', percent: 20 },
    { name: 'guazuma_ulmifolia', percent: 20 },
    { name: 'manilkara_zapota', percent: 20 },
    { name: 'crescentia_alata', percent: 10 },
    { name: 'myrospermum_frutescens', percent: 10 },
  ],
  costs: {
    registration: 10000,
    validation: 5000,
    verification: 5000,
    consultancyServices: 400000,
    validationServices: 75000,
    verificationServices: 150000,
    carbonCreditIssuancePerTon: 0.05,
    labelPerTon: 0.05,
    managementPerHaPerYear: 136,
    sitePreparationPerHaPerYear: 22,
    seedCollectionPerHaPerYear: 22,
    nursuryPerHaPerYear: 231,
    outplantingPerHaPerYear: 109,
    postPlantingPerHaPerYear: 65,
    consumablesPerHaPerYear: 45,
    plantingYears: 5,
  },
}

export class InterventionProfile {
  id: string
  type: 'reforestation' | 'assistedNaturalRegeneration'
  computeMethod: '' | 'ipccTier1'
  name: string
  carbonPrice: number
  carbonPriceMultiplierPerYear: number

  creditType: 'removal' | 'avoidedEmissions'
  creditStartDate: string
  creditEndDate: string
  biodiversityBenefits: string
  communityBenefits: string
  otherBenefits: string
  preferredFundingType: string
  fundingStart: string
  upfrontFundingNeeded: number
  otherFundingSources: string

  costs: {
    registration: number
    validation: number
    verification: number
    consultancyServices: number
    validationServices: number
    verificationServices: number
    carbonCreditIssuancePerTon: number
    labelPerTon: number
    managementPerHaPerYear: number
  }

  constructor(data?: Partial<InterventionProfile>) {
    return Object.assign(this, {
      ...anrDefaults,
      ...data,
    })
  }
}
