import { createColumnHelper } from '@tanstack/react-table'
import { useEffect, useState } from 'react'
import { CenteredLoader } from '~/components/atomic/CenteredLoader'
import { ComplexTable } from '~/components/atomic/table/ComplexTable'
import { Numerical } from '~/components/core/Numerical'
import { Column, Row } from '~/styles'
import { Section } from '~/styles/main'
import { lazyJSONImport } from '~/utils'

type Props = {
  countryName: string
  className?: string
}

type VerraProject = any // TOD improve types

const columnHelper = createColumnHelper<VerraProject>()

export function VerraProjects(props: Props) {
  const { countryName, className } = props

  const [projects, setProjects] = useState(null)

  useEffect(() => {
    async function loadJsonAsync() {
      const projects = await lazyJSONImport('verra-projects')
      const projectsInCountry = projects.filter(
        (project: any) => project['Country/Area'] === countryName
      )
      setProjects(projectsInCountry)
    }
    loadJsonAsync()
  }, [countryName])

  const columns = [
    {
      accessor: 'Name',
      className: 'max-w-[300px] py-8 font-semibold',
    },
    {
      accessor: 'Proponent',
      className: 'py-8',
    },
    { accessor: 'Project Type' },
    { accessor: 'AFOLU Activities' },
    { accessor: 'Status' },
    {
      accessor: 'Estimated Annual Emission Reductions',
      meta: { align: 'right' },
      cell: (props) => <Numerical value={props.getValue()} />,
    },
    { accessor: 'Project Registration Date', meta: { align: 'right' } },
    { accessor: 'Crediting Period Start Date', meta: { align: 'right' } },
    { accessor: 'Crediting Period End Date', meta: { align: 'right' } },
  ].map((i) =>
    columnHelper.accessor(i.accessor, {
      id: i.accessor,
      header: i.accessor,
      meta: { align: 'left' },
      ...i,
    })
  )

  return (
    <Column className={className}>
      <Row className='gap-20'>
        <Section.Subtitle>Verra Projects</Section.Subtitle>
        <div>Total Projects: {projects?.length}</div>
        <div>Last Updated: January 26th, 2023</div>
      </Row>

      {projects ? (
        <ComplexTable data={projects} columns={columns} />
      ) : (
        <CenteredLoader>Loading Projects</CenteredLoader>
      )}
    </Column>
  )
}
