import { useEffect } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { CenteredLoader } from '~/components/atomic/CenteredLoader'
import { ErrorBoundary } from '~/components/atomic/ErrorBoundary'
import { Head } from '~/components/core/Head'
import { ProjectNavProponents } from '~/components/layouts/ProjectNavProponents'
import ProjectNav from '~/routes/project/ProjectNav'
import { useFetchLandProject } from '~/services/db'
import { logger } from '~/services/logger'
import { toast } from '~/services/toaster'
import { state, storeLandProjectStatsData, useActiveLandProject } from '~/state'
import { useAuthedUser } from '~/state/auth'
import { Column } from '~/styles'
import { Project } from '~/types'
import { PATH } from '~/utils/constants'
import { useSocket } from '~/utils/useSocket'

type Props = {}

export type ProjectContext = {
  project: Project
}

export function ProjectPage(props: Props) {
  const params = useParams()
  const uName = params.project_slug
  useFetchLandProject(uName)
  const { landProject } = useActiveLandProject(uName)
  const navigate = useNavigate()

  useEffect(() => {
    return () => {
      // Reset individual project spcific state
      state.activeLandProjectId = null
      state.parcels = null
    }
  }, [])

  useSocket('onProjectAvoidedEmissionsComputed', {
    onRoute: (res) => {
      state.activeComputations.avoidedEmissions = false
      toast.info(`computed avoided emissions`)
      const { data } = res
      if (data.valid) {
        const landProjectStats = data.landProjectStats
        storeLandProjectStatsData(landProjectStats)
      } else {
        toast.error(data.msg)
        logger.log('onProjectAvoidedEmissionsComputed error', data)
      }
    },
    onCleanup: () => {},
  })

  const authedUser = useAuthedUser()

  if (!uName) navigate(PATH['/projects'])

  return (
    <Column className='h-full overflow-hidden'>
      <Head title={landProject?.name} />

      <ErrorBoundary>
        {authedUser.isAdmin ? (
          <ProjectNav landProject={landProject} />
        ) : (
          <ProjectNavProponents landProject={landProject} />
        )}
      </ErrorBoundary>

      <ErrorBoundary>
        {landProject ? (
          <div className='h-full overflow-y-auto flex-grow relative'>
            <Outlet />
          </div>
        ) : (
          <CenteredLoader>Loading Project</CenteredLoader>
        )}
      </ErrorBoundary>
    </Column>
  )
}
