import { cn } from '~/utils/cn'

import Avatar from '~/components/atomic/Avatar'
import Icon from '~/components/atomic/Icon'
import { Row } from '~/styles'

type Props = {
  name: string
  size?: number
  className?: string
}

export function AvatarAndName(props: Props) {
  const { name, size = 18, className = '' } = props

  const isUndefined =
    !name ||
    name.toLowerCase() === 'unassigned' ||
    name.toLowerCase() === 'all' ||
    name.toLowerCase() === ''

  return (
    <Row className={`row-vcenter ${className}`}>
      {isUndefined ? (
        <Icon size={size} icon='person_outline' className='text-ink-400' />
      ) : (
        <Avatar size={20} name={name} />
      )}
      <span className={cn('ml-8', { 'text-13 text-ink-400': isUndefined })}>{name}</span>
    </Row>
  )
}
