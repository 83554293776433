import React, { useState } from 'react'
import { Button } from '~/components/atomic/Button'
import { InfoBox } from '~/components/atomic/InfoBox'
import Input from '~/components/atomic/Input'
import AuthForm from '~/components/auth/AuthForm'
import AuthLayout from '~/components/auth/AuthLayout'
import { fetchDB } from '~/services/db'
import { getFormData } from '~/utils'

export function ForgotPassword() {
  const [error, setError] = useState('')
  const [success, setSuccess] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    const data = getFormData(e.currentTarget)
    forgotPassword(data)
  }

  async function forgotPassword(data) {
    if (data.email) {
      setError('')
      setSuccess(false)
      setSubmitting(true)
      const res = await fetchDB('forgotPassword', {
        email: data.email,
      })

      if (res.valid) {
        setSuccess(true)
      } else {
        setError('Something went wrong. Please try again or contact support.')
      }
    } else {
      setError('Please enter your email and try again.')
    }
    setSubmitting(false)
  }

  return (
    <AuthLayout title='Recover Password'>
      <AuthForm onSubmit={onSubmit}>
        <Input name='email' type='email' label='Email' placeholder='your@email.com' />

        {error && <div className='text-14 font-medium text-red-500'>{error}</div>}
        {success && (
          <InfoBox icon='check-circle'>
            An email has been sent with instructions on how to recover your password
          </InfoBox>
        )}

        <Button type='submit' variant='primary' submitting={submitting}>
          Recover password
        </Button>
      </AuthForm>
    </AuthLayout>
  )
}
