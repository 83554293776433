import { Outlet, useParams } from 'react-router-dom'
import { CenteredLoader } from '~/components/atomic/CenteredLoader'
import { LayoutWithSideMenu } from '~/components/core/LayoutWithSideMenu'
import { SideMenu } from '~/components/core/SideMenu'
import { ProjectTab } from '~/components/layouts/ProjectTab'
import { getScopingAssessmentForms } from '~/models/form'
import { useFetchLandProject } from '~/services/db'
import { useActiveLandProject } from '~/state'

import { Column } from '~/styles'

const menuOptions = [
  { type: 'title', label: 'Scoping Assessment' },

  ...getScopingAssessmentForms().map((form) => ({ to: form.name, label: form.label })),

  { type: 'divider' },
  { type: 'title', label: 'Land Assessment' },
  { to: 'polygons', label: 'Project boundries' },
  { type: 'divider' },
  { type: 'title', label: 'Other' },
  { to: 'photos', label: 'Project Photos' },
]

export function ProjectIntakeLayout() {
  const params = useParams()
  useFetchLandProject(params.project_slug)
  const { landProject } = useActiveLandProject()

  if (!landProject) return <CenteredLoader />

  const titleComponent = (
    <div className='mb-12 font-space-grotesk text-20 font-bold text-highlight'>
      Project Onboarding
    </div>
  )

  return (
    <ProjectTab title='Intake'>
      <LayoutWithSideMenu title='Data' menu={menuOptions} titleComponent={titleComponent}>
        <Outlet />
      </LayoutWithSideMenu>
    </ProjectTab>
  )
}

export function IntakeSideBar(props) {
  const { menu } = props

  return (
    <Column className='sticky top-0 h-full w-[280px] overflow-hidden border-r border-inka-50 bg-lime-50'>
      <Column className='flex-1 p-20'>
        <SideMenu options={menu} />
      </Column>
    </Column>
  )
}
