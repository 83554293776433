import { pluralize } from '~/utils'
import Popover from '../Popover'
import { Pressable } from '../Pressable'
import { ColumnVisiblity } from '~/types/table'
import { Checkbox } from '~/components/atomic/Checkbox'

export const ColumnVisibility = (props: ColumnVisiblity) => {
  const { table, className = '' } = props
  const hiddenColumns = table.getAllLeafColumns().filter((c) => !c.getIsVisible())
  const hasHiddenColumns = hiddenColumns?.length > 0

  return (
    <Popover content={<Content table={table} />}>
      <Pressable
        icon={hasHiddenColumns ? 'visibility_off' : 'visibility'}
        className={`ring-1 ring-ink-200 !-mx-0 ${className}`}
      >
        {hasHiddenColumns
          ? `${hiddenColumns.length} hidden ${pluralize('column', hiddenColumns.length)}`
          : 'Hide Columns'}
      </Pressable>
    </Popover>
  )
}

const Content = ({ table }) => (
  <Popover.Container>
    {table.getAllLeafColumns().map((column) => (
      <div key={column.id} className='px-1'>
        <Checkbox
          checked={column.getIsVisible()}
          onChange={column.getToggleVisibilityHandler()}
          label={
            column.columnDef?.meta?.header?.toString() ||
            column.columnDef.header.toString()
          }
        />
      </div>
    ))}
  </Popover.Container>
)
