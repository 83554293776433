import * as turf from '@turf/turf'
import { useRef } from 'react'
import Map, { MapProvider, MapRef, Source } from 'react-map-gl'
import CountryMask from '~/components/map/CountryMask'
import { MapControls } from '~/components/map/MapControls'
import { MapLegend } from '~/components/map/controls/MapLegend'
import TerrainMask from '~/components/map/TerrainMask'
import { LandProject, Parcel } from '~/models'
import { getCountryNameByCode } from '~/services/countries'
import { mapboxBaseStyle, MAPBOX_TOKEN } from '~/services/mapbox'
import { easings } from '~/utils'
import {
  getCountryBounds,
  getFeatureCollectionFromParcels,
  getFeatureCollectionFromReferenceRegionCoordinates,
} from '~/utils/geo'
import { MapSourceIds, PaintLayers } from './style'
import { MapSources } from '~/components/map/layers/MapSources'
import { CountryLabelsLayer } from '~/components/map/layers/CountryLabelsLayer'
import { CountriesBounds } from '~/components/map/layers/CountriesBounds'
import { cn } from '~/utils/cn'

type Props = {
  landProject: LandProject
  parcels: Parcel[]
  referenceRegionCoordinates?: any[]
  className?: string
  mapProps?: any
  withLegend?: boolean
}

const animationParams = { duration: 1200, easing: easings.easeInQuart }

export function LandMap(props: Props) {
  const {
    landProject,
    parcels,
    className = '',
    mapProps,
    withLegend,
    referenceRegionCoordinates,
  } = props

  const mapRef = useRef<MapRef>(null)
  const countryCode = landProject.countryCode
  const countryName = getCountryNameByCode(countryCode)
  const parcelsGeoJSON = getFeatureCollectionFromParcels(parcels)
  const referenceRegionsGeoJSON = getFeatureCollectionFromReferenceRegionCoordinates(
    referenceRegionCoordinates
  )

  const outerBounds = referenceRegionCoordinates
    ? referenceRegionsGeoJSON
    : parcelsGeoJSON

  const initialViewState = {
    bounds: getCountryBounds(countryName),
    fitBoundsOptions: {
      ...animationParams,
    },
  }

  const onLoad = (e) => {
    const bbox = turf.bbox(outerBounds)
    e.target.fitBounds(bbox as any, {
      padding: 100,
      ...animationParams,
    })
  }

  return (
    <div className={cn(`relative h-full w-full`, className)}>
      <MapProvider>
        <MapControls.Group top right>
          {withLegend && (
            <MapLegend
              sources={[
                {
                  label: 'Project area',
                  id: MapSourceIds.projectArea,
                },
                {
                  label: 'Reference region',
                  id: MapSourceIds.referenceRegion,
                },
              ]}
            />
          )}
        </MapControls.Group>
        <MapControls>
          <MapControls.FitMap featureCollectionToFit={outerBounds} />
        </MapControls>

        <Map
          ref={mapRef}
          attributionControl={false}
          mapboxAccessToken={MAPBOX_TOKEN}
          initialViewState={initialViewState}
          style={{ width: '100%', height: '100%', background: 'white' }}
          onLoad={onLoad}
          mapStyle={mapboxBaseStyle}
          {...mapProps}
        >
          <MapSources satellite composite />
          <TerrainMask />
          <ReferenceRegionsLayers referenceRegionGeoJSON={referenceRegionsGeoJSON} />

          <Source id={MapSourceIds.projectArea} type='geojson' data={parcelsGeoJSON}>
            <PaintLayers sourceId={MapSourceIds.projectArea} />
          </Source>
          <CountryLabelsLayer />
          <CountriesBounds variant='satellite' />
        </Map>
      </MapProvider>
    </div>
  )
}

function ReferenceRegionsLayers(props: { referenceRegionGeoJSON: any }) {
  const { referenceRegionGeoJSON } = props

  if (!referenceRegionGeoJSON) return null

  return (
    <Source
      id={MapSourceIds.referenceRegion}
      type='geojson'
      data={referenceRegionGeoJSON}
    >
      <PaintLayers sourceId={MapSourceIds.referenceRegion} />
    </Source>
  )
}
