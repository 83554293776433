import { DEFAULT_PROJET_PERIOD } from '~/utils/constants'
import {
  CarbonPriceScenarios,
  getPredictedCarbonPrice,
} from '~/data/financials/carbonPricePrediction'
import { VCUS } from '~/types/vcus'
import { ProjectType } from '~/models/landProject'
import { adjustedCostFactors } from '~/routes/project/financials/financialsStore'

type CashflowData = {
  year: number
  calendarYear: number
  projectVCUs: number
  cost: number
  carbonPrice: number
  revenue: number
  investmentDistribution: number
  creditShare: number
  communityBenefits: number
  earningsBeforeTax: number
  balance: number
}

export type ProjectCashflow = {
  investment: number
  cost: number
  projectBalance: number
  proponentBalance: number
  communityBalance: number
  cashflowPositive: boolean
  data: CashflowData[]
}

export type ProjectCashflows = ProjectCashflow[][]

type CommonProjectCashflowParams = {
  operationsStartYear: number
  investorExitYear: number
  startYear: number
  projectCreditShare: number
  projectCreditSharePostExit: number
  investmentDistributions: number[]
  vcus: VCUS
  carbonPriceScenario: CarbonPriceScenarios
  communityRevenueShare: number
  projectType: ProjectType
}

type Params = {
  investmentAmounts: number[]
  costAmounts: number[]
} & CommonProjectCashflowParams

export function calculateProjectCashflows(params: Params): ProjectCashflow[][] {
  const { investmentAmounts, costAmounts, ...commonParams } = params
  const projectCashflows = investmentAmounts.map((investment) => {
    return costAmounts.map((cost) => {
      return calculateProjectCashflow({ cost, investment, ...commonParams })
    })
  })

  return projectCashflows
}

type ProjectCashflowParams = {
  investment: number
  cost: number
} & CommonProjectCashflowParams

export function calculateProjectCashflow(params: ProjectCashflowParams): ProjectCashflow {
  const {
    operationsStartYear,
    investorExitYear,
    startYear,
    investment,
    cost,
    projectCreditShare,
    projectCreditSharePostExit,
    vcus,
    investmentDistributions,
    carbonPriceScenario,
    communityRevenueShare,
    projectType,
  } = params

  const data = []
  let projectBalance = 0
  let proponentBalance = 0
  let communityBalance = 0
  let cashflowPositive = true

  for (let year = 0; year < DEFAULT_PROJET_PERIOD; year++) {
    const calendarYear = year + startYear

    // Revenues
    const vcusIssued = vcus[year]?.issued || 0
    const carbonPrice = getPredictedCarbonPrice(
      calendarYear,
      projectType,
      carbonPriceScenario
    )

    let creditShare = projectCreditShare
    if (investorExitYear)
      creditShare =
        year > investorExitYear + 1 ? projectCreditSharePostExit : projectCreditShare

    const investmentDistribution = (investmentDistributions[year] || 0) * investment

    const projectVCUs = vcusIssued * creditShare
    const revenue = projectVCUs * carbonPrice

    // Costs
    const costAdjustmentFactor =
      calendarYear < operationsStartYear ? 0 : adjustedCostFactors[year]
    const adjustedCost = cost * costAdjustmentFactor

    const earningsBeforeTax = revenue + investmentDistribution - adjustedCost

    const communityBenefits =
      earningsBeforeTax > 0 ? earningsBeforeTax * communityRevenueShare : 0
    const afterCommunitySahre = earningsBeforeTax - communityBenefits

    projectBalance += earningsBeforeTax
    proponentBalance += afterCommunitySahre
    communityBalance += communityBenefits

    if (proponentBalance < 0) {
      cashflowPositive = false
    }

    const yearRow: CashflowData = {
      year,
      calendarYear,
      carbonPrice,
      investmentDistribution,
      projectVCUs,
      revenue,
      cost: adjustedCost,
      earningsBeforeTax,
      communityBenefits,
      balance: proponentBalance,
      creditShare,
    }
    data.push(yearRow)
  }

  return {
    cost,
    investment,
    cashflowPositive,
    projectBalance,
    proponentBalance,
    communityBalance,
    data,
  }
}

// Converts the investorCashflow data structure to be used in a table
export function transposeProjectCashflowForTable(
  projectCashflows: ProjectCashflow[],
  startYear: number
) {
  const rows = []

  for (let year = 0; year < DEFAULT_PROJET_PERIOD; year++) {
    const row = {
      year,
      yearDate: year + startYear,
    }

    projectCashflows.forEach((cashflow, index) => {
      row[`cost${index}`] = cashflow.data[year].balance
    })

    rows.push(row)
  }
  return rows
}
