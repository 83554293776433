import { Column, TableState, useSortBy, useTable } from 'react-table'
import TableLayout from '~/components/atomic/table/TableLayout'
import { cn } from '~/utils/cn'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

type Props = {
  columns: Column[]
  data: any
  stickyHeader?: boolean
  className?: string
  classes?: {
    thead?: string
    tfoot?: string
    tbody?: string
    tr?: string
    th?: string
    td?: string
  }
  initialState?: Partial<TableState>
  useControlledState?: any
}
export function SimpleTable(props: Props) {
  const {
    columns,
    data,
    className,
    initialState,
    useControlledState,
    classes = {},
  } = props
  const { thead, tfoot, tbody, tr, th, td } = classes

  const location = useLocation()
  const showDeforestationRateColumn = location.search.includes('redd')

  // TODO: check how important is to memoize colums and data
  // const memoizedColumns = React.useMemo(columns)
  // const memoizedData = React.useMemo(data)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    toggleHideColumn,
  } = useTable(
    {
      columns: columns,
      data: data,
      initialState: { hiddenColumns: [] },
      useControlledState,
    },
    useSortBy
  )

  // Handle Deforestation Rates column visibility
  useEffect(() => {
    if (showDeforestationRateColumn) {
      toggleHideColumn('Deforestation Rates', false)
    } else {
      toggleHideColumn('Deforestation Rates', true)
    }
  }, [showDeforestationRateColumn])

  // TODO improve typing for specific config
  // see: https://stackoverflow.com/questions/48822022/how-to-change-css-of-columns-reacttable
  return (
    <div className={className}>
      <TableLayout {...getTableProps()}>
        <TableLayout.THead className={thead}>
          {headerGroups.map((headerGroup) => (
            <TableLayout.TR {...headerGroup.getHeaderGroupProps()} className={tr}>
              {headerGroup.headers.map((column: any) => {
                const align = (column as any).align || 'right'
                const alignClass = align ? 'text-' + align : ''
                return (
                  <TableLayout.TH
                    className={cn(alignClass, th)}
                    {...column.getHeaderProps([
                      column.canSort && column.getSortByToggleProps(),
                      { className: column.headerClassName },
                    ])}
                  >
                    {column.render('Header')}
                  </TableLayout.TH>
                )
              })}
            </TableLayout.TR>
          ))}
        </TableLayout.THead>
        <TableLayout.TBody {...getTableBodyProps()} className={tbody}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <TableLayout.TR {...row.getRowProps()} className={tr}>
                {row.cells.map((cell: any) => {
                  const align = (cell.column as any).align || 'right'
                  const alignClass = align ? 'text-' + align : ''
                  return (
                    <TableLayout.TD
                      className={cn(alignClass, td)}
                      {...cell.getCellProps([{ className: cell.column.className }])}
                    >
                      {cell.render('Cell')}
                    </TableLayout.TD>
                  )
                })}
              </TableLayout.TR>
            )
          })}
        </TableLayout.TBody>
        <tfoot className={tfoot}>
          {footerGroups.map((group) => (
            <TableLayout.TR {...group.getFooterGroupProps()} className={tr}>
              {group.headers.map((column) => {
                const align = (column as any).align || 'right'
                const alignClass = align ? 'text-' + align : ''
                return (
                  <TableLayout.TD
                    className={cn(alignClass, td)}
                    {...column.getFooterProps()}
                  >
                    {column.render('Footer')}
                  </TableLayout.TD>
                )
              })}
            </TableLayout.TR>
          ))}
        </tfoot>
      </TableLayout>
    </div>
  )
}
