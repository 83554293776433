import { Head } from '~/components/core/Head'
import { ProjectsTable } from '~/routes/projects/components/ProjectsTable'
import { useInitalProjectsFilters } from '~/routes/projects/projectsFilters'
import { Column } from '~/styles'

export function ProjectsPage() {
  useInitalProjectsFilters()

  return (
    <>
      <Head title='Projects' />
      <Column className={'h-[calc(100vh-60px)] overflow-auto'}>
        <ProjectsTable />
      </Column>
    </>
  )
}
