import { AvailableDatasetsIds } from '~/models/dataset'
import { LandProject, LandProjectSchedule, ProjectType } from '~/models/landProject'
import { ComputeModelInfo, LandProjectStatsByYear } from '~/models/landProjectStats'
import { landProjectStatToProjection } from '~/models/projection'
import { fetchDB } from '~/services/db'
import { DATAVIZ_COLOR_SCALE } from '~/styles/colors'
import { DEFAULT_PROJET_PERIOD } from '~/utils/constants'

type ReforestableAreaCalculations = {
  forestHa: number
  forestPercent: number
  nonForestHa: number
  nonForestPercent: number
  otherHa: number
  otherPercent: number
}

export type EligibilityStats = {
  computeModelInfo: ComputeModelInfo<AvailableDatasetsIds>
  reforestationEligibilityStats: any // TODO
  reforestableAreaResults: {
    endYear: ReforestableAreaCalculations
    reforestableArea: {
      nonReforestableHa: number
      nonReforestablePercent: number
      otherHa: number
      otherPercent: number
      reforestableHa: number
      reforestablePercent: number
    }
    startYear: ReforestableAreaCalculations
  }
  created_at: string
  endYear: number
  startYear: number
  landProjectId: string
  _id: string
}

export type ForestTypes = 'primary' | 'secondary'
export type Species = string[]

export type ArrComputeModel = {
  key: ArrComputeModelKeys
  label: string
  projectType?: ProjectType
  needsInputs?: boolean
  /** Will not render a min and max bounds on graphs */
  skipBounds?: boolean
  color?: string
  documentationLink?: string
  countries?: string[]
}

export enum ArrComputeModelKeys {
  arr_scoping = 'arr_scoping',
  reforestation_literature = 'reforestation_literature',
  reforestation_communityLandModel = 'reforestation_communityLandModel',
}

const baseArrComputeModels: ArrComputeModel[] = [
  {
    key: ArrComputeModelKeys.arr_scoping,
    projectType: 'arr',
    label: 'ARR Scoping',
  },
  {
    key: ArrComputeModelKeys.reforestation_literature,
    projectType: 'arr',
    label: 'Literature Based',
    needsInputs: true,
  },
  {
    key: ArrComputeModelKeys.reforestation_communityLandModel,
    projectType: 'arr',
    label: 'Community Land Model',
    skipBounds: true,
    needsInputs: true,
    documentationLink: 'https://www.cesm.ucar.edu/models/clm/',
  },
]

export const arrComputeModels = baseArrComputeModels.map((m, index) => ({
  ...m,
  color: DATAVIZ_COLOR_SCALE[index],
}))

export const modelsWithInputs = arrComputeModels.reduce((prev, curr) => {
  if (curr.needsInputs) {
    return { ...prev, [curr.key]: false }
  }
  return prev
}, {}) as { [key in ModelsWithInputs]: boolean }

export const modelsWithInputsKeys = Object.keys(
  modelsWithInputs
) as unknown as ModelsWithInputs

type ApplicableModelCriteria = {
  countryName: string
  projectType: ProjectType
}

export type ModelsWithInputs =
  | ArrComputeModelKeys.reforestation_literature
  | ArrComputeModelKeys.reforestation_communityLandModel

export function getApplicableModels(
  criteria: ApplicableModelCriteria
): ArrComputeModel[] {
  const { countryName, projectType } = criteria

  const applicableModels = arrComputeModels.filter((model) => {
    if (projectType && model.projectType && projectType !== model.projectType) {
      return false
    }

    if (countryName && model.countries) {
      return model.countries.includes(countryName)
    }

    return true
  })
  return applicableModels
}

export function getModelByKey(modelKey: string) {
  return arrComputeModels.find((m) => modelKey === m.key)
}

type ComputeByYearStatsWithScheduleParams = {
  byYear: LandProjectStatsByYear
  polygonAreaHa: number
  reforestablePercent: number
  schedule: LandProjectSchedule
}

// TODO REFACTOR move this computation to the backend
export function computeByYearStatsWithSchedule(
  params: ComputeByYearStatsWithScheduleParams
): LandProjectStatsByYear {
  const { byYear, polygonAreaHa, schedule } = params

  if (!byYear) return null

  // Prepare new by Year with tCO2PerHa number
  const newByYear: LandProjectStatsByYear = byYear?.map((i) => ({
    tCO2: 0,
    tCO2PerHa: i.tCO2 / polygonAreaHa,
    tCO2PerHaMin: i.tCO2PerHaMin,
    tCO2PerHaMax: i.tCO2PerHaMax,
    year: i.year,
  }))

  for (const scheduleYear in schedule) {
    const startYearOffset = parseInt(scheduleYear)
    const scheduledAreaPeriod = byYear.length - startYearOffset

    for (let year = 0; year < scheduledAreaPeriod; year++) {
      const stats = byYear[year]
      const tCO2PerHa = stats.tCO2PerHa || stats.tCO2 / polygonAreaHa

      const tCO2PerHaMin = stats.tCO2PerHaMin || (stats?.tCO2Min || 0) / polygonAreaHa
      const tCO2PerHaMax = stats.tCO2PerHaMax || (stats?.tCO2Max || 0) / polygonAreaHa
      const scheduledArea = schedule[scheduleYear].areaHa

      const tCO2 = tCO2PerHa * scheduledArea
      const tCO2Min = tCO2PerHaMin * scheduledArea
      const tCO2Max = tCO2PerHaMax * scheduledArea

      const startYear = year + startYearOffset
      const existingStats = newByYear[startYear]

      const cumulativeTCO2 = (existingStats?.tCO2 || 0) + tCO2
      const cumulativeTCO2min = (existingStats?.tCO2Min || 0) + tCO2Min
      const cumulativeTCO2max = (existingStats?.tCO2Max || 0) + tCO2Max

      const previousStats = newByYear[startYear - 1]
      const annualtCO2 = cumulativeTCO2 - (previousStats?.tCO2 || 0)

      newByYear[startYear] = {
        ...existingStats,
        tCO2: cumulativeTCO2,
        tCO2Min: cumulativeTCO2min,
        tCO2Max: cumulativeTCO2max,
        annualtCO2,
      }
    }
  }

  return newByYear
}

export function getARRCarbonEstimates(landProject: LandProject) {
  const projection = landProjectStatToProjection(
    {
      ...landProject.xStats,
      snapshotMainInterventionKey: landProject.chosenModelKey,
    },
    landProject
  )
  return projection.byYear?.map((d) => d.annualtCO2).slice(0, DEFAULT_PROJET_PERIOD)
}

export async function fetchEligibilityStats(landProjectId: string) {
  const res = await fetchDB('getReforestationEligibilityStatsbyProjectId', landProjectId)

  if (res.valid === 0) console.error('issue fetching eligibility stats')

  return res.reforestationEligibilityStats as EligibilityStats
}
