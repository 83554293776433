import { useSnapshot } from 'valtio'
import { Button } from '~/components/atomic/Button'
import { ErrorBox } from '~/components/atomic/ErrorBoundary'
import { UploadPolygonLandProject } from '~/components/forms/UploadPolygonLandProject'
import { ProjectTab } from '~/components/layouts/ProjectTab'
import { LandMap } from '~/components/map/LandMap'
import { H2, SmallP, Title } from '~/components/profile/shared'
import { UploadPolygonGroup } from '~/components/project/UploadPolygonGroup'
import { AnnualDeforestationTable } from '~/components/reports/AnnualDeforestationTable'
import { AvoidedEmissionsGraph } from '~/components/reports/AvoidedEmissionsGraph'
import { DeforestationGraph } from '~/components/reports/DeforestationGraph'
import { StatsSection } from '~/components/reports/StatsSection'
import {
  getLandProjectREDDStats,
  parseREDDStatsToSectionsData,
} from '~/models/landProjectReddStats'
import { REDDInputs } from '~/routes/project/carbon/redd/REDDInputs'
import { REDDProgressBar } from '~/routes/project/carbon/redd/REDDProgressBar'
import {
  computeREDDScopingStatsFromStore,
  useInitREDDStore,
} from '~/routes/project/carbon/redd/reddStore'
import { state, useActiveLandProject } from '~/state/index'
import { Column } from '~/styles'
import { Card, Section } from '~/styles/main'

export function REDDCarbonView() {
  const snap = useSnapshot(state)
  const {
    landProject,
    parcels,
    referenceRegionCoordinates,
    referenceRegionCoordinates5km,
  } = useActiveLandProject()
  useInitREDDStore(landProject)
  const hasPolygons = parcels && parcels.length > 0
  const reddStats = getLandProjectREDDStats(landProject)
  const activeComputations = snap.activeComputations

  const sectionsData = parseREDDStatsToSectionsData(reddStats)
  let content

  if (!hasPolygons)
    content = (
      <div className='center relative'>
        <Card className='p-24'>
          <UploadPolygonGroup uploadButton={<UploadPolygonLandProject />} />
        </Card>
      </div>
    )
  else if (activeComputations.avoidedEmissions === true) content = <REDDProgressBar />
  else if (!reddStats)
    content = <ErrorBox error={Error('No Valid REDD Stats, please recomput')} />
  else {
    content = (
      <Column className='space-y-32'>
        <ReportSections
          sectionsData={sectionsData}
          landProject={landProject}
          parcels={parcels}
          reddStats={reddStats}
        />
      </Column>
    )
  }

  function computeREDDScoping() {
    computeREDDScopingStatsFromStore(
      landProject,
      parcels,
      referenceRegionCoordinates,
      referenceRegionCoordinates5km
    )
  }

  return (
    <ProjectTab title='Carbon'>
      <Section className='col relative flex space-x-40 lg:flex-row'>
        <Column className='xlg:fixed top-[80px] w-[320px]'>
          <Section.Title>REDD Carbon Assessment</Section.Title>

          {hasPolygons && (
            <div>
              <REDDInputs countryCode={landProject.countryCode} />
              <Button icon='refresh' onClick={computeREDDScoping}>
                Re-compute
              </Button>
            </div>
          )}
        </Column>

        <Column className='mx-auto w-full max-w-[1000px] space-y-40 pb-40'>
          {content}
        </Column>
      </Section>
    </ProjectTab>
  )
}

function ReportSections(props) {
  const { sectionsData, landProject, parcels, reddStats } = props
  return (
    <>
      <StatsSection {...sectionsData?.forestCover} />

      <StatsSection
        {...sectionsData?.biomassEstimates}
        description='*Total Biomass is the sum of Above Ground Biomass and Below Ground Biomass'
      />

      <H2 className='pt-40'>Historical Deforestation</H2>

      <StatsSection {...sectionsData?.deforestation} />

      <DeforestationGraph sectionData={sectionsData?.deforestation} />

      <H2 className='pt-40'>Avoided CO2 emissions from prevented deforestation</H2>

      <StatsSection {...sectionsData.referenceRegionBaselines} />
      <StatsSection {...sectionsData.referenceRegionBaselines5km} />

      <StatsSection
        {...sectionsData.avoidedEmissions}
        description='*Weighted average rate of project annual deforestation rate and reference region annual deforestation rate'
      />

      {/* conditional rendering for backward compatibility  */}
      {sectionsData.avoidedEmissionsUnderProjectAreaRate && (
        <StatsSection {...sectionsData.avoidedEmissionsUnderProjectAreaRate} />
      )}

      {sectionsData.avoidedEmissionsUnderReferenceRegionRate && (
        <StatsSection {...sectionsData.avoidedEmissionsUnderReferenceRegionRate} />
      )}

      {sectionsData.avoidedEmissionsUnderReferenceRegionRate5kmBuffer && (
        <StatsSection
          {...sectionsData.avoidedEmissionsUnderReferenceRegionRate5kmBuffer}
        />
      )}

      <div>
        <AnnualDeforestationTable sectionData={sectionsData.avoidedEmissions} />
        <SmallP className='mt-50'>
          Table 1. Forest cover and loss (in ha), and annual and cummulative emissions (in
          tCO2) for average (italicized), project area (orange), and reference region
          (purple) deforestation rates
        </SmallP>
      </div>

      <AvoidedEmissionsGraph sectionData={sectionsData.avoidedEmissions} />

      <div>
        <Title className='mt-50'>Project Location and reference region</Title>

        <LandMap
          landProject={landProject}
          parcels={parcels}
          referenceRegionCoordinates={reddStats.combinedReferenceRegion?.coordinates}
          className='h-[600px] w-full cursor-default'
          mapProps={{ interactive: false }}
          withLegend
        />
      </div>
    </>
  )
}
