import {
  CarbonPriceScenarios,
  getPredictedCarbonPrice,
} from '~/data/financials/carbonPricePrediction'
import { VCUS } from '~/types/vcus'
import { ProjectType } from '~/models/landProject'
import { DEFAULT_PROJET_PERIOD } from '~/utils/constants'

export type ProponentCashflow = {
  data: CashflowData[]
  totals: ProponentCashflowData
}

type CommonCashflowData = {
  year: number
  calendarYear: number
}

export type ProponentCashflowData = {
  shareOfCredits: number
  revenueFromCredits: number
  revenueFromPrePurchaseAgreement: number
  income: number
  revenueSharedWithCommunity: number
  baseCosts: number
  costAfterInflation: number
  costs: number
  profitBeforeTax: number
  tax: number
  profitAfterTax: number
}

type CashflowData = CommonCashflowData & ProponentCashflowData

type CommonParams = {
  projectType: ProjectType
  startYear: number
  vcus: VCUS
  carbonPriceScenario: CarbonPriceScenarios
  investment: number
  investmentDistributions: number[]

  annualCosts: number[]
  proponentCreditSharePercent: number
  communityRevenueSharePercent: number
  inflationPercent: number
  taxRate: number
}

export function calculateProponentCashflow(params: CommonParams): ProponentCashflow {
  const {
    projectType,
    startYear,
    vcus,
    annualCosts,
    carbonPriceScenario,
    investment,
    investmentDistributions,
    proponentCreditSharePercent,
    communityRevenueSharePercent,
    inflationPercent,
    taxRate,
  } = params

  const data: CashflowData[] = []
  let totals = {} as ProponentCashflowData

  for (let year = 0; year < DEFAULT_PROJET_PERIOD; year++) {
    const calendarYear = year + startYear
    const carbonPrice = getPredictedCarbonPrice(
      calendarYear,
      projectType,
      carbonPriceScenario
    )

    // Income ------
    // Sell of Credits (spot market sales)
    const vcusIssued = vcus[year]?.issued || 0
    const shareOfCredits = vcusIssued * proponentCreditSharePercent
    const revenueFromCredits = shareOfCredits * carbonPrice

    // Revenue from pre-pruchase agreement aka the investment from the investor
    const revenueFromPrePurchaseAgreement =
      (investmentDistributions[year] || 0) * investment

    const income = revenueFromCredits + revenueFromPrePurchaseAgreement

    // Costs
    const revenueSharedWithCommunity = revenueFromCredits * communityRevenueSharePercent
    const baseCosts = annualCosts[year]
    const costAfterInflation = baseCosts * inflationPercent
    const costs = revenueSharedWithCommunity + costAfterInflation

    // Profit
    const profitBeforeTax = income - costs
    const tax = Math.max(0, profitBeforeTax * taxRate)
    const profitAfterTax = profitBeforeTax - tax

    const calculations: ProponentCashflowData = {
      shareOfCredits,
      revenueFromCredits,
      revenueFromPrePurchaseAgreement,
      income,
      revenueSharedWithCommunity,
      baseCosts,
      costAfterInflation,
      costs,
      profitBeforeTax,
      tax,
      profitAfterTax,
    }

    data.push({
      year,
      calendarYear,
      ...calculations,
    })

    totals = addToTotals(totals, calculations)
  }

  return {
    totals,
    data,
  }
}

// This function takes totals and mutates the reference variable by adding the corresponding value
// This is an abstraction to make the process of tallying totals a lot easier en less error prone.
function addToTotals(totals, newValues: ProponentCashflowData) {
  Object.keys(newValues).map((key) => {
    totals[key] = totals[key] || 0 + newValues[key] || 0
  })
  return totals
}
