import React from 'react'
import { NumericValueType } from '~/types'
import { isNil } from '~/utils'
import { abbrebiateNumber, formatNumber, formatPercent } from '~/utils/units'

type Props = {
  value: number | string
  unitLeft?: string
  unitRight?: string
  className?: string
  decimals?: number
  abbreviated?: boolean // eg. 10k
  style?: React.CSSProperties
  type?: NumericValueType
  valueClassName?: string
}

export function Numerical(props: Props) {
  const {
    type = 'number',
    value,
    className = '',
    decimals,
    valueClassName,
    abbreviated,
    style,
  } = props

  let unitLeft = props.unitLeft
  let unitRight = props.unitRight

  if (type === 'area') unitRight = 'ha'
  if (type === 'currency') unitLeft = '$'
  if (type === 'percent') unitRight = '%'

  let parsedValue
  if (isNil(value)) parsedValue = '-'
  else if (type === 'unformatted') parsedValue = value
  else if (abbreviated) parsedValue = abbrebiateNumber(value, decimals)
  else if (type === 'percent') parsedValue = formatPercent(Number(value) * 100, decimals)
  else parsedValue = formatNumber(value, decimals)

  return (
    <span style={style} className={`text-number ${className}`}>
      {unitLeft && <span className='opacity-60 mr-2'>{unitLeft}</span>}
      <span className={valueClassName}>{parsedValue}</span>
      {unitRight && <span className='opacity-60 ml-2'>{unitRight}</span>}
    </span>
  )
}
