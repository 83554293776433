import { TooltipProvider } from '@radix-ui/react-tooltip'
import { useEffect } from 'react'
import { Toaster } from 'react-hot-toast'
import { RouterProvider } from 'react-router-dom'
import { ErrorBoundary } from '~/components/atomic/ErrorBoundary'
import { ConfirmDialog } from '~/components/dialogs/ConfirmDialog'
import { SupportDialog } from '~/components/dialogs/SupportDialog'
import { router } from '~/router'
import { initAnalytics } from '~/services/analytics'
import { checkAndLogin } from '~/state/auth'
import ESSocket from '~/utils/ESSocket'

checkAndLogin()

export default function App() {
  useEffect(() => {
    ESSocket.connect(process.env.REACT_APP_WEBSOCKET_URL)
    initAnalytics()

    return () => {
      ESSocket.disconnect()
    }
  }, [])

  return (
    <ErrorBoundary>
      <TooltipProvider disableHoverableContent>
        <>
          <RouterProvider router={router} />
          <Toaster position='top-right' />
          <ConfirmDialog />
          <SupportDialog />
        </>
      </TooltipProvider>
    </ErrorBoundary>
  )
}
