import { cn } from '~/utils/cn'

import { Pressable } from '~/components/atomic/Pressable'
import { NavAuthButton } from '~/components/auth/NavAuthButton'
import { EarthshotLogo } from '~/components/core/EarthshotLogo'
import { useActiveLandProject } from '~/state'

import { Row } from '~/styles'

type Props = {
  className?: string
}

export function PortfolioNav(props: Props) {
  const { className } = props
  const { landProject } = useActiveLandProject()
  const canEdit = landProject?.permissions?.includes('edit')

  return (
    <Row
      className={cn(
        'fixed top-0 z-50 w-full justify-between border-b border-ink-150 bg-white px-20 py-12',
        className
      )}
    >
      <Row className='items-center space-x-20'>
        <EarthshotLogo />
        <Pressable to='/portfolio' className='font-space-grotesk text-20 text-ink-600'>
          Projects
        </Pressable>
      </Row>

      <Row className='row-vcenter'>
        {canEdit && (
          <>
            <Pressable
              href={`/project/${landProject.uName}/data/short-profile`}
              openInNewTab
              icon='edit'
              className='ml-0 rounded-full'
            >
              Edit Page
            </Pressable>
            <div className='mx-20 h-20 w-[1px] bg-ink-200' />
          </>
        )}
        <NavAuthButton hideName />
      </Row>
    </Row>
  )
}
