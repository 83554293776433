import { Numerical } from '~/components/core/Numerical'
import { Row } from '~/styles'
import { ResponsiveRow } from '~/styles/main'

export const HeaderSection = ({ totalVCUs }) => {
  return (
    <ResponsiveRow className='gap-x-40 gap-y-12 mt-12 text-14'>
      {totalVCUs?.map((item) => (
        <Row className='flex-wrap'>
          <span className='mr-8'>{item.label}</span>
          <Numerical
            value={item?.value}
            unitRight={item.unitRight}
            decimals={item.decimals}
            valueClassName='font-bold'
          />
        </Row>
      ))}
    </ResponsiveRow>
  )
}
