import { Badge, Props as BadgeProps } from '~/components/atomic/Badge'
import { getProjectTypeLabel, ProjectType } from '~/models/landProject'
import { projectTypeToColorVariant } from '~/models/landProject'

type Props = {
  projectType: ProjectType
} & BadgeProps

export function ProjectTypeBadge(props: Props) {
  const { projectType, ...rest } = props

  if (!projectType) return null

  return (
    <Badge variant={projectTypeToColorVariant[projectType]} {...rest}>
      {getProjectTypeLabel(projectType)}
    </Badge>
  )
}
