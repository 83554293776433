import { ColorVariants } from '~/styles/colors'

export const defaultBackgroundImage =
  'https://images.unsplash.com/photo-1583470790878-4f4f3811a01f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2476&q=90'

/********************** Routes *****************************/
export const DEFAULT_START_YEAR = new Date().getFullYear()

export const DEFAULT_PROJET_PERIOD = 30

export const REGION_DESCRIPTION_IS_TOO_LONG = 25

export const enum PATH {
  '/' = '/',

  // Shared
  'new' = 'new',

  // Projects
  '/projects' = '/projects',
  'map' = 'map',
  'list' = 'list',
  'summaries' = 'summaries',
  'stats' = 'stats',

  '/project' = '/project',
  ':project_slug' = ':project_slug',

  //Initial Information
  'project-boundries' = 'project-boundries',

  // Pre-Diligence
  'discounts' = 'discounts',

  // Project Tabs
  'summary' = 'summary',
  'land' = 'land',
  'carbon' = 'carbon',
  'vcus' = 'vcus',
  'financials' = 'financials',
  'assessments' = 'assessments',
  'data' = 'data',
  'settings' = 'settings',

  // VCUS tab
  'inputs' = 'inputs',
  'efficiency' = 'efficiency',
  'carbon-overrides' = 'carbon-overrides',
  'vcus-calculations' = 'vcus-calculations',
  'vcus-calculations-before-discounts' = 'vcus-calculations-before-discounts',
  'permanence' = 'permanence',
  'permanence-assessment' = 'permanence-assessment',
  'issued' = 'issued',

  // Financials tab
  'explorer' = 'explorer',
  'investor-cashflows' = 'investor-cashflows',
  'carbon-price-scenarios' = 'carbon-price-scenarios',
  'project-cashflows' = 'project-cashflows',
  'earthshot-cashflows' = 'earthshot-cashflows',
  'costs' = 'costs',
  'stakeholder-returns' = 'stakeholder-returns',
  'optimal-financial-model' = 'optimal-financial-model',

  // Land Parcels
  // 'map' = 'map',
  'area' = 'area',
  'baseline' = 'baseline',
  'reforestation-eligibility' = 'reforestation-eligibility',
  'country-regulation' = 'country-regulation',
  'parcels' = 'parcels',
  'biodiversity' = 'biodiversity',
  'parcel' = 'parcel',
  ':parcel_id' = ':parcel_id',
  'trees' = 'trees',
  'scoping' = 'scoping',

  // Proponents
  'intake' = 'intake',
  'polygons' = 'polygons',

  // Assessments Tab
  'basic-info' = 'basic-info',
  'initial-questionnaire' = 'initial-questionnaire',
  'scoping-assessment' = 'scoping-assessment',

  // Printable Reports
  '/project/:project_slug/reports' = '/project/:project_slug/reports',
  'carbon-report' = 'carbon-report',
  'short-profile' = 'short-profile',
  '/project/:project_slug/carbon-report' = '/project/:project_slug/carbon-report',

  '/intake/:project_slug' = '/intake/:project_slug',

  'investment-case/:project_slug' = 'investment-case/:project_slug',
  'profile/:project_slug' = 'profile/:project_slug',
  '/profile' = '/profile',

  //Categories & Sections
  'photos' = 'photos',
  'recommendations' = 'recommendations',
  'project-info' = 'project-info',
  ':formId' = ':formId',
  ':categoryId' = ':categoryId',

  // PORTFOLIO
  '/portfolio' = '/portfolio',
  'profile' = 'profile',
  '/investment-case' = '/investment-case',

  // AUTH
  '/signup' = '/signup',
  '/logout' = '/logout',
  '/login' = '/login',
  '/email-verify' = '/email-verify',
  '/forgot-password' = '/forgot-password',
  '/password-reset' = '/password-reset',

  // OTHER
  '/workbench' = '/workbench',
  '/parcelize' = '/parcelize',

  // SCIENCE DATABASE
  '/science-database' = '/science-database',
  '/science-database/publication' = 'publication',
  '/science-database/publication/:id' = ':id',

  // Admin / Internal
  '/tools' = '/tools',
  'form-builder' = 'form-builder',
  'polygons-tool' = 'polygons-tool',
  'country-regulation-map' = 'country-regulation-map',
  'users' = 'users',
  'arr-scoping-comparison' = 'arr-scoping-comparison',

  // Biome
  '/biome' = '/biome',
  'trees-map' = 'trees-map',
  'image-files' = 'image-files',
}

export const enum SECTION_CATEGORIES {
  'proejct-info' = 'project-info',
  'overview' = 'overview',
  'financials' = 'financials',
  'implementation' = 'implementation',
  'community' = 'community',
  'carbon' = 'carbon',
  'regulatory' = 'regulatory',
  'certification' = 'certification',
  'sdg-benefits' = 'sdg-benefits',
  'partners' = 'partners',
  'photos' = 'photos',
}

/********************** Enums *****************************/
export enum ProcessingState {
  PROCESSING = 'processing...',
  SUCCESS = 'successful',
  FAILED = 'something went wrong',
}

export namespace ProcessingState {
  export function badgeStyle(processingState: ProcessingState): ColorVariants {
    switch (processingState) {
      case ProcessingState.PROCESSING: {
        return 'yellow'
      }
      case ProcessingState.FAILED: {
        return 'red'
      }
      case ProcessingState.SUCCESS: {
        return 'green'
      }
      default:
        return 'default'
    }
  }
}

export enum Position {
  TOP = 'top',
  BOTTOM = 'bottom',
}

/******************************* Options ***********************************/

export const allPermissionOptions = [
  { label: 'Viewer', value: 'view' },
  { label: 'Editor', value: 'edit' },
  { label: 'Admin', value: 'editUser' },
  { label: 'Project Lead', value: 'lead' },
  { label: 'Proponent', value: 'proponent' },
  { label: 'Investor', value: 'investor' },
]

export const invitePermissionOptions = [
  { label: 'Project Lead', value: 'lead' },
  { label: 'Proponent', value: 'proponent' },
  { label: 'Investor', value: 'investor' },
]

/********************************** Extensions ****************************************/

export const mustHaveShapFileExtensions = ['shp', 'shx', 'prj']
