import { Outlet, useParams } from 'react-router-dom'
import { CenteredLoader } from '~/components/atomic/CenteredLoader'
import { LayoutWithSideMenu } from '~/components/core/LayoutWithSideMenu'
import { ProjectTab } from '~/components/layouts/ProjectTab'
import { ES_Form, getFormsByTag, getScopingAssessmentForms } from '~/models/form'
import { useFetchLandProject } from '~/services/db'
import { useActiveLandProject } from '~/state'
import { PATH } from '~/utils/constants'

type Props = {
  isIntake?: boolean
}

const sidebarOptions = [
  { to: PATH['short-profile'], label: 'Short Profile', icon: 'insert_drive_file' },
  { to: PATH.photos, label: 'Project Photos', icon: 'photo' },
  {
    to: PATH['recommendations'],
    label: 'Scoping recommendations',
    icon: 'lightbulb_outline',
  },

  { type: 'title', label: 'Scoping Assessment' },
  ...formsToOptions(getScopingAssessmentForms()),

  { type: 'title', label: 'Certification' },
  ...formsToOptions(getFormsByTag('certification')),
  { type: 'title', label: 'Legacy Asessment' },
  ...formsToOptions(getFormsByTag('legacy')),
]

export function DataViewLayout(props: Props) {
  const { isIntake } = props
  const params = useParams()
  // TODO refactor so there's an IntakeViewLayout @bernat. That will simplify things
  // If is a data intake view, get the project slug and fetch the project
  const uName = isIntake && params.project_slug
  useFetchLandProject(uName)
  const { landProject } = useActiveLandProject()

  if (!landProject) return <CenteredLoader />

  return (
    <ProjectTab title='Data'>
      <LayoutWithSideMenu title='Data' menu={sidebarOptions}>
        <Outlet />
      </LayoutWithSideMenu>
    </ProjectTab>
  )
}

function formsToOptions(forms: ES_Form[]) {
  return forms.map((form) => ({ label: form.label, to: form.id }))
}
