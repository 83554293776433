import { Layer } from 'react-map-gl'

type Props = {
  countries
}

export function HighlightCountriesLayer(props: Props) {
  const { countries } = props
  if (!countries?.length) return null

  return (
    <>
      <Layer
        id='Project Country Fill'
        type='fill'
        source='countryBoundaries'
        source-layer='country_boundaries'
        filter={['match', ['get', 'name_en'], countries, true, false]}
        paint={{ 'fill-color': '#F1E5CC' }}
      />
      <Layer
        id='Project Country Line'
        type='line'
        source='countryBoundaries'
        source-layer='country_boundaries'
        filter={['match', ['get', 'name_en'], countries, true, false]}
        paint={{ 'line-color': '#000000', 'line-opacity': 0.3 }}
      />
    </>
  )
}
