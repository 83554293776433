import React from 'react'

import * as TabsPrimitive from '@radix-ui/react-tabs'
import { ErrorBoundary } from '~/components/atomic/ErrorBoundary'
import { cn } from '~/utils/cn'

type Props = {
  tabs: { value: string; label: string | React.ReactNode }[]
  content: any
  className?: string
  contentClassName?: string
}

export function Tabs(props: Props) {
  const { tabs, content, className, contentClassName } = props

  return (
    <TabsPrimitive.Root defaultValue={tabs[0].value}>
      <TabsPrimitive.List
        className={cn(
          'row overflow-x-scroll justify-space-between w-full items-stretch rounded-t-lg',
          'shadow-[0_1px_0_0] shadow-ink-200 ring-1 ring-ink-200 divide-x',
          className
        )}
        aria-label='Manage your account'
      >
        {tabs.map(({ value, label }) => (
          <TabsPrimitive.Trigger
            key={value}
            value={value}
            className={cn(
              'group',
              'first:rounded-tl-lg last:rounded-tr-lg',
              'border-b ',
              'border-ink-200',
              'radix-state-active:border-b-gray-700 focus-visible:radix-state-active:border-b-transparent radix-state-inactive:bg-gray-50 focus-visible:dark:radix-state-active:border-b-transparent',
              'flex-1 px-12 py-10',
              'focus:radix-state-active:border-b-red'
            )}
          >
            {label}
          </TabsPrimitive.Trigger>
        ))}
      </TabsPrimitive.List>

      {content.map((content, index) => (
        <ErrorBoundary key={tabs[index].value + 'content'}>
          <TabsPrimitive.Content
            className={cn(
              'rounded-b-lg bg-white px-6 py-4 dark:bg-gray-800 overflow-x-scroll',
              contentClassName
            )}
            value={tabs[index].value}
          >
            {content}
          </TabsPrimitive.Content>
        </ErrorBoundary>
      ))}
    </TabsPrimitive.Root>
  )
}
