import React from 'react'
import { CopyToClipboardButton } from '~/components/core/CopyToClipboardButton'
import { LandProject } from '~/models'

import { Column, Row } from '~/styles'

type Props = {
  landProject: LandProject
  className?: string
}

export function CopyIntakeLinkButton(props: Props) {
  const { landProject, className = '' } = props
  const intakeLink = `${window.location.origin}/project/${landProject.uName}/intake`

  return (
    <CopyToClipboardButton
      variant='primary'
      text='Copy link to Scoping Assessment'
      copiedText='Copied to clipboard'
      textToCopy={intakeLink}
      className={className}
    />
  )
}
