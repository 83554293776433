import React, { useEffect, useState } from 'react'
import { Dialog } from '~/components/atomic/Dialog'

import { Column, Row } from '~/styles'
import { PropsWithChildren } from 'react'
import Input from '~/components/atomic/Input'
import { updateAuthedUserData, useAuthedUser } from '~/state/auth'
import { Button } from '~/components/atomic/Button'
import { getFormData } from '~/utils'
import Avatar from '~/components/atomic/Avatar'
import { state, toggleUserProfileDialog, useSnapshot } from '~/state'
import ESSocket from '~/utils/ESSocket'
import { logger } from '~/services/logger'
import { ErrorMessage } from '~/components/atomic/ErrorMessage'

type Props = PropsWithChildren<{}>

export function UserProfileDialog(props: Props) {
  const { children } = props
  const user = useAuthedUser()
  const [error, setError] = useState()
  const [submitting, setSubmitting] = useState(false)
  const snap = useSnapshot(state)
  const open = snap.userProfileDialog
  const _routeIds = []

  useEffect(() => {
    _routeIds['updateFirstLastName'] = ESSocket.onRoute('updateFirstLastName', (res) => {
      setSubmitting(false)
      if (res.data.valid === 1) {
        updateAuthedUserData(res.data.user)
      } else {
        logger.error(`updateFirstLastName -> ${res.data.msg}`)
        setError(res.data.msg)
      }
    })

    return function cleanup() {
      ESSocket.offRouteIds(_routeIds)
    }
  }, [])

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    const currentFirstName = user.first_name
    const currentLastName = user.last_name
    const formData: any = getFormData(e.currentTarget)

    if (
      currentFirstName !== formData.first_name ||
      currentLastName !== formData.last_name
    ) {
      setSubmitting(true)
      ESSocket.emit('updateFirstLastName', {
        ...formData,
        user,
      })
    } else {
      logger.log('no name changes made')
      return null
    }
  }

  return (
    <Dialog
      open={open}
      triggerComponent={children}
      onClickOutside={toggleUserProfileDialog}
    >
      <Dialog.CloseButton onClick={toggleUserProfileDialog} />
      <Dialog.Title>Profile</Dialog.Title>
      <Dialog.Divider />

      <Row className='row-vcenter mb-20 space-x-12'>
        <Avatar name={user.first_name} />
        <Column>
          <div className='font-bold'>
            {user.first_name} {user.last_name}
          </div>
          <div>{user.email}</div>
        </Column>
      </Row>

      <form className='space-y-16' onSubmit={onSubmit}>
        <Input name='first_name' defaultValue={user.first_name} label='First name' />
        <Input name='last_name' label='Last name' defaultValue={user.last_name} />
        <Button submitting={submitting} variant='primary'>
          Save
        </Button>
        <ErrorMessage error={error} />
      </form>
    </Dialog>
  )
}
