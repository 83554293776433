import React from 'react'
import { cn } from '~/utils/cn'

import Input from '~/components/atomic/Input'
import { Option } from '~/types'

export type Props = {
  label?: string
  options: Option[]
  selectClasses?: string
} & React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
>

// TODO migrate to radix Select

export default function Select(props: Props) {
  const {
    name,
    options,
    label,
    className,
    selectClasses,
    placeholder,
    defaultValue,
    ...rest
  } = props

  return (
    <div className={cn(`flex flex-col`, className)}>
      {label && (
        <Input.Label htmlFor={name} className='mb-8'>
          {label}
        </Input.Label>
      )}
      <select
        className={cn(
          'block w-full rounded-md border border-ink-300 p-8 text-14 shadow-sm focus:border-indigo-500 focus:ring-indigo-500',
          selectClasses
        )}
        defaultValue={defaultValue}
        name={name}
        {...rest}
      >
        <option value={''} disabled hidden>
          {placeholder || 'Choose one'}
        </option>
        {options.map((option, optionIndex) => (
          <option {...option} key={`${option.value as string | number}-${optionIndex}`} />
        ))}
      </select>
    </div>
  )
}
