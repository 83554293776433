import { Fragment } from 'react'
import { Label } from '~/components/profile/shared'
import { FormFieldKeys } from '~/models/formField'
import { getLandProjectDatums, parseValueOptionsAsString } from '~/models/projectDatum'
import { useActiveLandProject } from '~/state'

import { Column } from '~/styles'

type Props = {
  datumKeys: FormFieldKeys[]
}

export function ProjectDetails(props: Props) {
  const { datumKeys } = props
  const { landProject } = useActiveLandProject()

  const datumFields = getLandProjectDatums(landProject, datumKeys)

  return (
    <Column className='grid-cols[36vw,1fr] grid gap-x-16  gap-y-12 sm:grid-cols-[max-content,1fr] sm:gap-x-32'>
      {datumFields.map((datumField, i) => {
        if (!datumField.value) return null

        const stringValue = parseValueOptionsAsString(datumField)

        return (
          <Fragment key={i}>
            <Label>{datumField.label}</Label>
            <div className='text-16 font-medium'>{stringValue}</div>
            <div className='col-span-2 border-b last:hidden' />
          </Fragment>
        )
      })}
    </Column>
  )
}
