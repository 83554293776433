import { cn } from '~/utils/cn'
import { Command } from 'cmdk'
import Fuse from 'fuse.js'

import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Icon from '~/components/atomic/Icon'
import { inputTextClasses } from '~/components/atomic/Input'
import { ProjectTypeBadge } from '~/components/core/ProjectTypeBadge'
import { LandProject } from '~/models/landProject'
import { getCountryNameByCode } from '~/services/countries'
import { fetchDB } from '~/services/db'
import { loadFromStorage, saveToStorage } from '~/services/localStorage'
import { Column, Row } from '~/styles'

const RECENT_SEARCHES_STORAGE_KEY = 'recentSearches'

// TODO cmkd has some strange filtering logic and sometimes the results are strange
// It'd be better to use shouldFilter=false and handle sortring/filtering ourselves
// with fuse.js. For now we're going to live with a few edge cases.

export function SearchDialog() {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [landProjects, setLandProjects] = useState([])
  const [suggestions, setSuggestions] = useState([])
  const [query, setQuery] = useState('')

  const fuse = new Fuse(landProjects, { keys: ['name'] })
  const filteredItems = query
    ? fuse.search(query).map((item) => item.item)
    : landProjects.slice(0, 10)

  useEffect(() => {
    fetchAndSetProjects()
    processAndSetSuggestions()

    function down(e) {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) setOpen((open) => !open)
    }

    document.addEventListener('keydown', down)
    return () => document.removeEventListener('keydown', down)
  }, [])

  useEffect(() => {
    processAndSetSuggestions()
    fetchAndSetProjects()
  }, [open])

  return (
    <Command.Dialog
      open={open}
      onOpenChange={setOpen}
      className={cn(
        'flex flex-col fixed z-50',
        'rounded-xl md:w-full h-[390px]',
        'w-[95%] max-w-[600px] max-h-[95%] top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] shadow-2xl overflow-hidden',
        'bg-white ring-1 ring-ink-200 ',
        'cmdk-overlay:inset-0 cmdk-overlay:bg-black cmdk-overlay:absolute',
        'border-0 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75'
      )}
    >
      <Row className='row-vcenter h-[52px] border-b border-ink-200 relative'>
        <Icon size={16} icon='search' className='absolute left-16 text-ink-700' />

        <Command.Input
          onValueChange={setQuery}
          autoFocus
          placeholder='Search projects...'
          className={cn(
            inputTextClasses,
            'py-0 !ring-0 !border-0 !outline-none rounded-none w-full p-20 pl-40 h-full'
          )}
        />
      </Row>

      <Column className='flex-1 overflow-hidden'>
        <Command.List className='p-8 overflow-y-scroll'>
          <Command.Empty className='p-8 text-13 italic text-ink-600'>
            No projects found with this name
          </Command.Empty>

          {query === '' && (
            <Command.Group
              heading='Recent searches & suggestions'
              className='cmdk-group-heading:text-13 cmdk-group-heading:text-ink-600 cmdk-group-heading:p-8'
              hidden={true}
              aria-hidden={true}
            >
              {suggestions.map((lp) => {
                return <Item landProject={lp} key={lp._id} onSelect={onSelect} />
              })}
            </Command.Group>
          )}

          {query !== '' && (
            <Command.Group
              heading='All projects'
              className='cmdk-group-heading:text-13 cmdk-group-heading:text-ink-600 cmdk-group-heading:p-8'
            >
              {filteredItems.map((lp) => {
                return <Item landProject={lp} key={lp._id} onSelect={onSelect} />
              })}
            </Command.Group>
          )}
        </Command.List>
      </Column>
    </Command.Dialog>
  )

  function onSelect(landProject) {
    navigate(`/project/${landProject.uName}`)
    setOpen(false)
    addToRecentSearches(landProject)
  }

  function processAndSetSuggestions() {
    const recentProjects = loadFromStorage(RECENT_SEARCHES_STORAGE_KEY) || []
    const recentProjectIds = recentProjects.map((lp) => lp._id)
    const otherProjects = landProjects
      .filter((lp) => !recentProjectIds.includes(lp._id))
      .sort((a, b) => {
        if (a.updated_at > b.updated_at) return -1
        if (a.updated_at < b.updated_at) return 1
        return 0
      })

    const newSuggestions = [
      ...recentProjects.slice(0, 9),
      ...otherProjects.slice(0, 9),
    ].slice(0, 9)

    setSuggestions(newSuggestions)
  }

  async function fetchAndSetProjects() {
    const res = await fetchDB('searchLandProjects', { search: '' })
    setLandProjects(res.landProjects || [])
  }
}

function addToRecentSearches(landProject: LandProject) {
  const recent = loadFromStorage(RECENT_SEARCHES_STORAGE_KEY) || []
  const newRecent = [
    landProject,
    ...recent.filter((lp) => lp._id !== landProject._id),
  ].slice(0, 10)
  saveToStorage(RECENT_SEARCHES_STORAGE_KEY, newRecent)
}

function Item(props) {
  const { landProject, onSelect } = props
  const { uName, name, projectType, countryCode } = landProject
  const countryName = getCountryNameByCode(countryCode)

  return (
    <Command.Item
      key={uName}
      value={name?.toLowerCase() + ' ' + countryName}
      onSelect={() => onSelect(landProject)}
      className='row row-vcenter text-ink aria-selected:bg-ink-100 py-6 px-8 rounded-md gap-x-12'
    >
      <div className='text-14 font-medium flex-1'>{name}</div>
      <div className='text-13 text-ink-600'>{countryName}</div>
      <ProjectTypeBadge projectType={projectType} />
    </Command.Item>
  )
}
