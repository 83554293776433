import { BarGraph } from '~/components/core/Graphs'
import { ReportSectionData } from '~/types'

type Props = {
  sectionData: ReportSectionData
}

export function DeforestationGraph(props: Props) {
  const { sectionData } = props

  return (
    <div>
      <div className='mb-12 text-16 font-bold'>{sectionData.graph.title}</div>
      <BarGraph {...sectionData.graph} />
    </div>
  )
}
