import { Numerical } from '~/components/core/Numerical'
import { NumericValueType } from '~/types'
import { cn } from '~/utils/cn'
import classed from '~/styles/classed'

export type RowData<Accessors = any> = {
  label: string
  accessor?: keyof Accessors | ((datum: Accessors) => void)
  amount?: number
  type?: NumericValueType
  className?: string
  valueClassName?: string
  summaryClassName?: string
}

type Props = {
  rows: RowData[]
  data: any
}

export function AccountingSheet(props: Props) {
  const { rows, data } = props

  return (
    <div className='relative grid grid-cols-[max-content,max-content,repeat(30,minmax(100px,max-content))]'>
      {rows.map((row, key) => (
        <Row item={row} data={data} key={key + row.label} />
      ))}
    </div>
  )
}

function Row(props) {
  const { item, data } = props
  const isHeader = Object.keys(item).length == 1 && item.label
  if (isHeader) return <HeaderRow label={item.label} />
  return <ValueRow item={item} data={data} />
}

function ValueRow(props) {
  const { item, data } = props
  const { label, amount, type, accessor, className, summaryClassName, valueClassName } =
    item

  return (
    <>
      <Label className={cn('pr-24', className)}>{label}</Label>
      {typeof amount != 'number' ? (
        <div className={cn(horizontalBorder, rightBorder)} />
      ) : (
        <Numerical
          value={amount}
          type={type}
          className={cn(
            horizontalBorder,
            rightBorder,
            'py-8 px-12 text-right',
            summaryClassName
          )}
        />
      )}
      {!accessor && (
        <div className={cn(horizontalBorder)} style={{ gridColumn: 'span 30' }} />
      )}
      {accessor &&
        data.map((d, i) => {
          const value = typeof accessor == 'function' ? accessor(d) : d[accessor]
          return (
            <div
              key={i}
              className={cn(
                horizontalBorder,
                valueClassName,
                'text-ink-700 py-8 px-16 text-right'
              )}
            >
              {value == 0 ? <span>-</span> : <Numerical type={type} value={value} />}
            </div>
          )
        })}
    </>
  )
}

function HeaderRow(props) {
  const { label } = props
  return (
    <>
      <Label className='text-12 font-bold text-ink-700 uppercase tracking-wider pt-20'>
        {label}
      </Label>
      <div className={cn(horizontalBorder, rightBorder, 'pt-20')} />
      <div className={cn(horizontalBorder, ' pt-20')} style={{ gridColumn: 'span 30' }} />
    </>
  )
}

const horizontalBorder = 'border-b'
const rightBorder = 'border-r'

const Label = classed('div', cn(horizontalBorder, 'py-8 bg-white'))
