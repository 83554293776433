import { useRef, useState, useEffect } from 'react'

import { Column } from '~/styles'
import Select from '~/components/atomic/Select'
import { Button } from '~/components/atomic/Button'
import { InputConfigItem } from '~/routes/admin/formBuilder/InputConfigItem'
import { useTimedToggle } from '~/utils/useTimedToggle'

import { forms } from '~/data/forms'
import { Pressable } from '~/components/atomic/Pressable'
import ObjectId from 'bson-objectid'
import {
  FormField,
  FormFieldOption,
  getIsNonAnswerableFieldType,
} from '~/models/formField'
import slugify from 'slugify'
import { ProjectType } from '~/models/landProject'
import { ContextMenu } from '~/components/atomic/ContextMenu'
import { arrayInsert, isNil } from '~/utils'
import { useParams, useNavigate } from 'react-router-dom'

type Props = {}

export function FormBuilderPage(props: Props) {
  const params = useParams()
  const navigate = useNavigate()
  const formId = params.formId
  const [isCopied, toggle] = useTimedToggle(() => {})
  const activeForm = forms.find((form) => form.id === formId)
  const [fields, setFields] = useState<FormField[]>(activeForm.fields)

  useEffect(() => {
    setFields(activeForm.fields as FormField[])
  }, [formId])

  const formRef = useRef()

  function onSubmit() {
    const form = formRef.current as HTMLFormControlsCollection
    const formFields = []

    for (const el of form) {
      if (el.tagName == 'FIELDSET' && el.classList.contains('fieldset')) {
        const name = el.querySelector<any>('input[name="name"]')?.value.trim()
        const label = el.querySelector<any>('textarea[name="label"]').value.trim()
        const description = el
          .querySelector<any>('textarea[name="description"]')
          ?.value.trim()
        const type = el.querySelector<any>('select[name="type"]').value

        const exclusiveToProjectType = el.getAttribute(
          'data-exclusive-to-project-type'
        ) as ProjectType
        const condition = el.getAttribute('data-condition') as ProjectType
        const id = getIsNonAnswerableFieldType(type) ? null : el.id

        const withNotes = el.querySelector<any>('input[name="with-notes"]')?.checked

        const optionElements = el.querySelectorAll<any>('.options .option')
        const options = []

        for (const optionEl of optionElements as any) {
          const value = optionEl.getAttribute('data-value')
          const score = parseFloat(optionEl.getAttribute('data-score'))
          const label = optionEl.textContent

          const option: FormFieldOption = {
            value: value || slugify(label.toLowerCase()),
            label,
          }
          if (score) option.score = score
          options.push(option)
        }

        const inputConfig: FormField = {
          name,
          label,
          description,
          type,
        }

        if (exclusiveToProjectType)
          inputConfig.exclusiveToProjectType = exclusiveToProjectType

        if (condition) {
          inputConfig.condition = JSON.parse(condition)
        }

        if (id) inputConfig.id = id
        if (withNotes) inputConfig.withNotes = withNotes
        if (options.length > 0) inputConfig.options = options
        formFields.push(inputConfig)
      }
    }
    const formData = {
      ...activeForm,
      fields: formFields,
    }

    toggle()
    navigator.clipboard.writeText(JSON.stringify(formData)).then((_) => {
      toggle()
    })
  }

  function onChangeForm(e) {
    navigate(`/admin/form-builder/${e.currentTarget.value}`)
  }

  function onAdd(index?: number) {
    const newField = {
      name: ObjectId().toHexString(),
      type: 'paragraph',
    }
    const newFields = arrayInsert(fields, !isNil(index) ? index : fields.length, newField)
    setFields(newFields)
  }

  function onDelete(field: FormField) {
    const newFields = fields.filter((f) => f.name !== field.name)
    setFields(newFields)
  }

  const header = (
    <header className='row row-vcenter fixed top-0 z-50 h-60 w-full border-b border-ink-200 bg-white px-40'>
      <div className='mr-40 text-16 font-bold'>Form Builder</div>
      <Select
        defaultValue={formId}
        options={forms.map((f) => ({ value: f.id, label: f.label }))}
        onChange={onChangeForm}
        className='w-[300px]'
      />
      <div className='flex-1' />
      <Button onClick={onSubmit} variant={isCopied ? 'secondary' : 'primary'}>
        {isCopied ? 'Copied' : 'Copy Data'}
      </Button>
    </header>
  )

  const getContextMenu = (field: FormField, index: number) => [
    {
      label: 'Insert after',
      icon: 'add',
      onSelect: () => onAdd(index + 1),
    },
    {
      label: 'Delete',
      icon: 'delete',
      onSelect: () => onDelete(field),
    },
  ]

  const formContent = (
    <Column className='m-80 w-full max-w-[1000px]'>
      <div className='mb-24 text-24 font-bold'>{activeForm.label}</div>

      <div className='rounded-lg'>
        <Pressable
          icon='add'
          className='center -mx-0 mt-24 w-full rounded-lg border border-dashed border-ink-400 !p-20 text-20 font-medium'
          onClick={() => onAdd(0)}
        >
          Add Item
        </Pressable>

        {fields.map((field, index) => (
          <ContextMenu
            items={getContextMenu(field, index)}
            key={field.name || field.label || index}
          >
            <InputConfigItem {...field} />
          </ContextMenu>
        ))}

        <Pressable
          icon='add'
          className='center -mx-0 mt-24 w-full rounded-lg border border-dashed border-ink-400 !p-20 text-20 font-medium'
          onClick={() => onAdd()}
        >
          Add Item
        </Pressable>
      </div>
    </Column>
  )

  return (
    <div className='min-h-full pb-40'>
      <form
        ref={formRef}
        className='col flex w-full items-center'
        onSubmit={(e) => e.preventDefault()}
      >
        {header}
        <div className='h-60 w-full' />
        {formContent}
      </form>
    </div>
  )
}

const insert = (arr, index, newItem) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted item
  newItem,
  // part of the array after the specified index
  ...arr.slice(index),
]
