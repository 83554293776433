import { PropsWithChildren } from 'react'
import { Column } from '~/styles/box'
import classed from './classed'
import { cn } from '~/utils/cn'

export const cardClasses = 'bg-white rounded-md ring-1 ring-ink-200 shadow-md'
export const cardHoverClasses = 'hover:bg-ink-50 cursor-pointer hover:ring-highlight'

export const ResponsiveRow = classed('div', 'flex flex-col md:flex-row')
export const Content = classed('div', 'max-w-[1440px] p-16 md:p-20')
export const ViewTitle = classed('div', 'text-20 font-medium font-space-grotesk')

export const Card = classed('div', `${cardClasses}`)
export const CardHoverable = classed('div', `${cardClasses} ${cardHoverClasses}`)
export const MapOverlaidCard = classed(
  'div',
  `${cardClasses} col absolute center pointer-events-auto top-[6%] p-24`
)

export const mapOverlayClasses = `absolute center pointer-events-auto top-[6%]`

export const twEmptyMessage = `text-center text-14 text-ink-600`

export const ScrollableContent = classed('div', 'h-full overflow-y-auto')

export function Section(
  props: PropsWithChildren<{ title?: string; className?: string }>
) {
  const { children, title, className, ...rest } = props
  return (
    <Column
      style={{ '--container-padding': 20 }}
      className={cn('relative p-20 pb-60', className)}
      {...rest}
    >
      {title && <SectionTitle>{title}</SectionTitle>}
      {children}
    </Column>
  )
}

export const classesFullBleedContainer =
  '-mx-[var(--container-padding)px] px-[var(--container-padding)px]'

const SectionTitle = classed('div', 'text-28 font-bold mb-20 font-space-grotesk')
const SectionSubtitle = classed('div', 'text-18 font-semibold mb-16 leading-none')

Section.Title = SectionTitle
Section.Subtitle = SectionSubtitle

export const Divider = classed(
  'div',
  'w-auto border-b my-32 border-inka-100 -mx-[calc(var(--container-padding),32px)]'
)

export const PageTitle = ({
  className,
  ...p
}: PropsWithChildren<{ className?: string }>) => (
  <div className={`mb-20 text-36 font-bold ${className}`} {...p} />
)
