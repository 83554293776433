import { createColumnHelper } from '@tanstack/react-table'
import { useEffect, useMemo, useState } from 'react'
import { CenteredLoader } from '~/components/atomic/CenteredLoader'
import { ComplexTable } from '~/components/atomic/table/ComplexTable'
import { Numerical } from '~/components/core/Numerical'
import { Column, Row } from '~/styles'
import { Section } from '~/styles/main'
import { lazyJSONImport } from '~/utils'

type Props = {
  countryName: string
  className?: string
}

type BerkeleyProject = any // TOD improve types

const columnHelper = createColumnHelper<BerkeleyProject>()

export function BerkeleyProjects(props: Props) {
  const { countryName, className } = props
  const [projects, setProjects] = useState(null)

  useEffect(() => {
    async function loadJsonAsync() {
      const projects = await lazyJSONImport('berkeley')
      const projectsInCountry = projects.filter(
        (project: any) => project['Country'] === countryName
      )
      setProjects(projectsInCountry)
    }
    loadJsonAsync()
  }, [countryName])

  const columns = useMemo(
    () =>
      [
        {
          accessor: 'Project Name',
          className: 'w-[300px] py-8 font-semibold',
        },
        { accessor: 'Project Developer' },
        { accessor: 'Voluntary Registry' },
        { accessor: 'Type' },
        { accessor: 'Project Type' },
        { accessor: 'State' },
        { accessor: 'Voluntary Status' },
        { accessor: 'Methodology / Protocol' },
        { accessor: 'Project Site Location' },
        {
          accessor: 'Total Credits Issued',
          meta: { align: 'right' },
          cell: (props) => <Numerical value={props.getValue()} />,
        },
        {
          accessor: 'Total Credits Retired',
          meta: { align: 'right' },
          cell: (props) => <Numerical value={props.getValue()} />,
        },
        {
          accessor: 'Total Credits Remaining',
          meta: { align: 'right' },
          cell: (props) => <Numerical value={props.getValue()} />,
        },
        { accessor: 'First Year of Project' },
        { accessor: 'Registry Documents' },
        { accessor: 'Project Website' },
      ].map((i) =>
        columnHelper.accessor(i.accessor, {
          id: i.accessor,
          header: i.accessor,
          meta: { align: 'left' },
          ...i,
        })
      ),
    []
  )

  return (
    <Column className={className}>
      <Row className='gap-20'>
        <Section.Subtitle>Other Projects</Section.Subtitle>
        <div>Total Projects: {projects?.length}</div>
        <div>Last Updated: January 26th, 2023</div>
      </Row>

      {projects ? (
        <ComplexTable data={projects} columns={columns} />
      ) : (
        <CenteredLoader>Loading Projects</CenteredLoader>
      )}
    </Column>
  )
}
