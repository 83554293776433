import { Dialog } from '~/components/atomic/Dialog'
import { UploadPolygonButton } from '~/components/forms/UploadPolygonButton'
import { MapControls } from '~/components/map/MapControls'
import { UploadPolygonGroup } from '~/components/project/UploadPolygonGroup'
import { recomputeAvoidedEmissions } from '~/models/landProjectReddStats'
import { fetchDB } from '~/services/db'
import { logger } from '~/services/logger'
import { storeLandProjectStatsData } from '~/state'
import { useActiveLandProject } from '~/state'
import { Column } from '~/styles'
import { RadioGroup } from '../atomic/RadioGroup'
import { useState } from 'react'

// TODO: Improve type usage
export function ReferenceRegionUploadDialog() {
  const {
    landProject,
    parcels,
    referenceRegionCoordinates,
    referenceRegionCoordinates5km,
  } = useActiveLandProject()
  const [referenceRegionType, setReferenceRegionType] = useState(
    'combinedReferenceRegion'
  )

  function onUpload(updatedLandProjectStats) {
    const { combinedReferenceRegion, combinedReferenceRegion5km } =
      updatedLandProjectStats

    recomputeAvoidedEmissions(
      landProject,
      parcels,
      combinedReferenceRegion.coordinates,
      combinedReferenceRegion5km.coordinates
    )
    Dialog.close()
  }

  return (
    <Dialog
      triggerComponent={
        <div>
          <MapControls.CardButton icon='add_circle_outline'>
            Add Reference Region
          </MapControls.CardButton>
        </div>
      }
      className='col center'
    >
      <h3 className='mb-20 text-18 font-bold'>
        Upload custom reference region polygon/s
      </h3>

      <Column className='space-around'>
        <RadioGroup
          onChange={(option) => setReferenceRegionType(option.value)}
          {...optionsProps}
        />

        <UploadPolygonGroup
          title={''}
          uploadButton={
            <UploadPolygonReferenceRegion
              landProjectId={landProject._id}
              onUpload={onUpload}
              referenceRegionType={referenceRegionType}
              currentReferenceRegionCoordinates={referenceRegionCoordinates}
              currentReferenceRegionCoordinates5km={referenceRegionCoordinates5km}
            />
          }
        />
      </Column>
    </Dialog>
  )
}

export function UploadPolygonReferenceRegion(props) {
  const {
    landProjectId,
    onUpload,
    referenceRegionType,
    currentReferenceRegionCoordinates,
    currentReferenceRegionCoordinates5km,
  } = props

  // Uploading one RR per time.
  async function saveCustomReferenceRegion(allParcels) {
    const customCoordinates = []
    allParcels.forEach((parcel) => {
      customCoordinates.push(parcel.coordinates)
    })

    // Use existing Reference Region or update with custom uploaded coordinates
    const customReferenceRegionCoordinates =
      referenceRegionType === 'combinedReferenceRegion'
        ? customCoordinates
        : currentReferenceRegionCoordinates

    // Use existing 5km Reference Region or update with custom uploaded coordinates
    const customReferenceRegionCoordinates5km =
      referenceRegionType === 'combinedReferenceRegion5km'
        ? customCoordinates
        : currentReferenceRegionCoordinates5km

    const data = {
      landProjectId,
      referenceRegionCoordinates: customReferenceRegionCoordinates,
      referenceRegionCoordinates5km: customReferenceRegionCoordinates5km,
    }

    const res = await fetchDB('saveCustomReferenceRegionToREDDStats', data)
    if (res.valid) {
      if (
        res.landProjectStats.combinedReferenceRegion &&
        res.landProjectStats.combinedReferenceRegion5km
      ) {
        storeLandProjectStatsData(res.landProjectStats)
        onUpload(res.landProjectStats)
      } else {
        logger.log(
          "error on upload, combinedReferenceRegion doesn't exist -> ",
          res.landProjectStats
        )
      }
    } else {
      logger.log('saveCustomReferenceRegionToREDDStats error -> ', res)
    }
  }

  return <UploadPolygonButton onUploaded={saveCustomReferenceRegion} />
}

const options = [
  {
    value: 'combinedReferenceRegion5km',
    label: '5km Reference Region',
    checked: false,
  },
  {
    value: 'combinedReferenceRegion',
    label: '20km Reference Region',
    checked: true,
  },
]

const optionsProps = {
  name: 'combinedReferenceRegion',
  options: options,
  defaultValue: 'combinedReferenceRegion',
}
