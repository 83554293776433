import * as ContextMenuPrimitive from '@radix-ui/react-context-menu'

import { cn } from '~/utils/cn'
import { PropsWithChildren, ReactNode } from 'react'
import Icon from '~/components/atomic/Icon'
import Link from '~/components/atomic/Link'
import { menuContainer } from '~/components/atomic/styles'

export type MenuItemProps = {
  label?: string
  shortcut?: string
  icon?: ReactNode
  type?: 'item' | 'separator' | 'title'
  onSelect?: (event) => void
  to?: string
}

type Props = PropsWithChildren<{
  items: MenuItemProps[]
}>

export function ContextMenu(props: Props) {
  const { children, items } = props

  return (
    <ContextMenuPrimitive.Root>
      <ContextMenuPrimitive.Trigger>{children}</ContextMenuPrimitive.Trigger>

      <ContextMenuPrimitive.Portal>
        <ContextMenuPrimitive.Content
          className={cn(
            'radix-side-top:animate-slide-up radix-side-bottom:animate-slide-down',
            menuContainer,
            'min-w-[200px] bg-white dark:bg-ink-800'
          )}
        >
          {items?.map((item, i) => (
            <Item key={`${item?.label}-${i}-${item?.type}`} {...item} />
          ))}
        </ContextMenuPrimitive.Content>
      </ContextMenuPrimitive.Portal>
    </ContextMenuPrimitive.Root>
  )
}

function Item(props) {
  const { label, icon, shortcut, type, onSelect, to } = props
  if (type === 'separator')
    return (
      <ContextMenuPrimitive.Separator className='my-4 -mx-4 h-px bg-ink-200 dark:bg-ink-700' />
    )

  if (type === 'title')
    return (
      <ContextMenuPrimitive.Label className='select-none p-8 text-12 text-ink-700 dark:text-ink-200'>
        {label}
      </ContextMenuPrimitive.Label>
    )

  const item = (
    <ContextMenuPrimitive.Item
      className={cn(
        'group flex cursor-pointer select-none items-center rounded-md p-8 text-13 outline-none',
        'text-ink-600 focus:bg-ink-150 dark:text-ink-500 dark:focus:bg-ink-900'
      )}
      onSelect={onSelect}
    >
      <Icon
        icon={icon}
        size={16}
        className='text-ink-500 group-hover:text-ink-900 mr-6'
      />
      <span className='flex-grow text-ink-700 dark:text-ink-300'>{label}</span>
      {shortcut && (
        <div className='ml-8 shrink-0 row space-x-2 font-medium items-baseline '>
          {shortcut.split('').map((i) => (
            <kbd className='text-13 font-sans min-w-[1em] uppercase'>{i}</kbd>
          ))}
        </div>
      )}
    </ContextMenuPrimitive.Item>
  )

  return to ? <Link>{item}</Link> : item
}
