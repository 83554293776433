import { useEffect } from 'react'
import { createBrowserRouter } from 'react-router-dom'
import { routes } from '~/routes'
import { logger } from '~/services/logger'

export type BlockingListener = () => boolean | Promise<boolean>

export const router = createBrowserRouter(routes)

// All the code below is an interim solution to handle navigation blocking
// It's based on this code from reacr-router-dom issues: https://github.com/remix-run/react-router/issues/8139#issuecomment-1361787824
// react router is working as of Jan 2023 on an official solution to handle navigation blocking
// We need a blocking solution because users might be filling data and navigate a way without saving their changes
// An alternative approach would be to always save their changes, but there are tradeoffs to that approach

const _navigate = router.navigate.bind(router)

const listeners: BlockingListener[] = []

router.navigate = async (...args) => {
  const params = args as [any]

  if (listeners.length > 0) {
    const promises = listeners.map((fn) => fn())
    const values = await Promise.all(promises).catch((e) => logger.warn(e))
    const allowed = values?.every(Boolean)

    if (!allowed) return
  }

  return _navigate(...params)
}

export function useNavigationBlocker(dirty: boolean, blocker: BlockingListener) {
  useEffect(() => {
    if (!dirty) return
    return blockNavigation(blocker)
  }, [blocker, dirty])
}

function blockNavigation(fn: BlockingListener) {
  if (listeners.length === 0) {
    window.addEventListener('beforeunload', beforeUnload, { capture: true })
  }

  listeners.push(fn)

  return () => {
    const index = listeners.indexOf(fn)
    listeners.splice(index, 1)
    if (listeners.length === 0) {
      window.removeEventListener('beforeunload', beforeUnload, { capture: true })
    }
  }
}

function beforeUnload(event: BeforeUnloadEvent) {
  // Cancel the event.
  event.preventDefault()
  // Chrome (and legacy IE) requires returnValue to be set.
  event.returnValue = ''
}
