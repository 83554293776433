import { Outlet, useParams } from 'react-router-dom'
import { CenteredLoader } from '~/components/atomic/CenteredLoader'
import { LayoutWithSideMenu } from '~/components/core/LayoutWithSideMenu'
import { ProjectTab } from '~/components/layouts/ProjectTab'
import {
  getFormById,
  getInitalAssessmentForms,
  getScopingAssessmentForms,
} from '~/models/form'
import { useFetchLandProject } from '~/services/db'
import { useActiveLandProject } from '~/state'
import { PATH } from '~/utils/constants'

type Props = {
  isIntake?: boolean
}

const basicInfoForm = getFormById('basic-info')

const sidebarOptions = [
  { type: 'title', label: 'Initial Assessment' },
  { to: basicInfoForm.name, label: 'Basic Information' },

  ...getInitalAssessmentForms().map((form) => ({
    to: form.name,
    label: form.label,
  })),

  { type: 'title', label: 'Scoping Assessment' },

  ...getScopingAssessmentForms().map((form, index) => ({
    to: form.name,
    label: form.label,
    index: index + 1,
  })),
  { type: 'title', label: 'Evaluation' },

  {
    to: PATH['scoping-assessment'],
    label: 'Scoping Assessment Scoring',
    icon: 'format_list_numbered',
  },
]

export function AssessmentsTabLayout(props: Props) {
  const { isIntake } = props
  const params = useParams()

  // TODO refactor so there's an IntakeViewLayout @bernat. That will simplify things
  // If is a data intake view, get the project slug and fetch the project
  const uName = isIntake && params.project_slug
  useFetchLandProject(uName)
  const { landProject } = useActiveLandProject()

  if (!landProject) return <CenteredLoader />

  return (
    <ProjectTab title='Assessments'>
      <LayoutWithSideMenu title='Assessments' menu={sidebarOptions}>
        <Outlet />
      </LayoutWithSideMenu>
    </ProjectTab>
  )
}
