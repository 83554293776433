import { cn } from '~/utils/cn'
import { Fragment } from 'react'
import { SimpleTable } from '~/components/atomic/table/SimpleTable'
import { Tooltip } from '~/components/atomic/Tooltip'
import { Numerical } from '~/components/core/Numerical'
import { transposeProjectCashflowForTable } from '~/data/financials/projectCashflows'
import { BreakdownTooltip } from '~/routes/project/financials/BreakdownTooltip'
import { useFinancialsStore } from '~/routes/project/financials/financialsStore'
import { Column } from '~/styles'
import { Section } from '~/styles/main'
import { getSteppedRange } from '~/utils'
import { formatCurrency } from '~/utils/units'

export function ProjectCashflows() {
  const { variables, projectCashflows } = useFinancialsStore()
  const { startYear, costsRange } = variables

  const costAmounts = getSteppedRange(costsRange[0], costsRange[1], 10)

  const getColumnsInvestorCashflows = (permutationTableIdx) => [
    { Header: 'Year#', accessor: 'year', align: 'center' },
    {
      Header: 'Year',
      accessor: 'yearDate',
      align: 'center',
      Footer: (
        <Column>
          <span className='py-8'>Project Balance</span>
          <span className='py-8'>Proponent Balance</span>
          <span className='py-8'>Community Balance</span>
        </Column>
      ),
    },

    ...costAmounts.map((amount, i) => {
      return {
        Header: `Yearly cost ${formatCurrency(amount * 1_000)}`,
        id: `${i}`,
        accessor: (d) => {
          return d[`cost${i}`]
        },
        Cell: (d) => {
          const investmentGroup = 0
          const costColumn = parseInt(d.column.id)
          const row = d.row.index
          const breakdown = projectCashflows[investmentGroup][costColumn].data[row]

          const tooltip = <BreakdownTooltip values={breakdown} />

          return (
            <Tooltip content={tooltip} delayDuration={0}>
              <div className='py-2 px-4 hover:bg-ink-150'>
                <Numerical
                  className={cn({ 'text-red-500': d.value < 0 })}
                  value={d.value}
                />
              </div>
            </Tooltip>
          )
        },
        Footer: (table) => {
          const cashflow = projectCashflows[permutationTableIdx][i]
          return (
            <Column>
              <Numerical
                className='py-8'
                type='currency'
                value={cashflow.projectBalance}
              />
              <Numerical
                className='py-8'
                type='currency'
                value={cashflow.proponentBalance}
              />
              <Numerical
                className='py-8'
                type='currency'
                value={cashflow.communityBalance}
              />
            </Column>
          )
        },
      }
    }),
  ]

  return (
    <Section title='Project Cashflows'>
      {projectCashflows.map((row, i) => {
        const data = transposeProjectCashflowForTable(row, startYear)
        return (
          <Fragment key={i}>
            <Section.Subtitle className='mt-40'>
              Investment: <Numerical type='currency' value={row[0].investment} />
            </Section.Subtitle>
            <SimpleTable data={data} columns={getColumnsInvestorCashflows(i)} />
          </Fragment>
        )
      })}
    </Section>
  )
}
