import { useEffect } from 'react'
import { proxy, useSnapshot } from 'valtio'
import { getLandProjectCountry, LandProject } from '~/models/landProject'
import { LandProjectStats } from '~/models/landProjectStats'
import {
  ArrComputeModelKeys,
  ForestTypes,
  getApplicableModels,
  getModelByKey,
  ArrComputeModel,
  ModelsWithInputs,
  modelsWithInputs,
  modelsWithInputsKeys,
} from '~/models/landProjectArrStats'
import { fetchDB, fetchLandProjectStats, saveProjectData } from '~/services/db'
import { logger } from '~/services/logger'
import { toast } from '~/services/toaster'
import {
  selectLandProjectOrActive,
  state,
  storeLandProjectData,
  useActiveLandProject,
} from '~/state'
import { isNil } from '~/utils'
import { landProjectStatToProjection, Projection } from '~/models/projection'

let _applicableLandProjectStats

export type CarbonModelWithDialogKey = ModelsWithInputs

type State = {
  projections: Projection[]
  applicableProjectionKeys: string[]
  activeProjectionKeys: string[]
  loadingProjectionKeys: string[]
  applicableModels: ArrComputeModel[]
  dialogs: {
    [key in CarbonModelWithDialogKey]: boolean
  }
  ecozone: string
  continent: string
}

export const carbonState = proxy<State>({
  projections: [],
  applicableProjectionKeys: [],
  activeProjectionKeys: [],
  loadingProjectionKeys: [],
  applicableModels: [],
  dialogs: modelsWithInputs,
  ecozone: '',
  continent: '',
})

export const useArrCarbonStore = () => {
  const snap = useSnapshot(carbonState) as typeof carbonState
  return snap
}

export async function useInitArrCarbonStore() {
  const { landProject } = useActiveLandProject()

  useEffect(() => {
    async function get() {
      resetState()

      // Get the list of models that are applicable to the land project
      const countryName = getLandProjectCountry(landProject)
      const projectType = landProject.projectType
      const applicableModels = getApplicableModels({ countryName, projectType })
      carbonState.applicableModels = applicableModels

      const promises = await Promise.all([
        fetchLandProjectStats(state.activeLandProjectId),
        fetchLandProjectEcozone(state.activeLandProjectId),
      ])
      const [landProjectStats, landProjectEcozone] = promises

      carbonState.ecozone = landProjectEcozone.ecozone
      carbonState.continent = landProjectEcozone.continent

      const applicableModelsKeys = applicableModels.map((m) => m.key)
      carbonState.applicableProjectionKeys = applicableModelsKeys
      _applicableLandProjectStats = landProjectStats.filter((p) =>
        applicableModelsKeys.includes(
          p.snapshotMainInterventionKey as ArrComputeModelKeys
        )
      )

      logger.info(
        'fetchLandProjectStats -> applicable landProjectStats:',
        _applicableLandProjectStats
      )

      const projections = _applicableLandProjectStats.map((stats) =>
        landProjectStatToProjection(stats, landProject)
      )
      logger.info('useInitCarbonState -> Projections:', projections)

      carbonState.projections = projections
      carbonState.activeProjectionKeys = projections.map(
        (p) => p.snapshotMainInterventionKey
      )
    }

    get()
  }, [landProject])
}

type ProjectionCalcuationData = { continent: string; ecozone: string } & (
  | { species: string }
  | { forestType: ForestTypes }
)

export async function calculateProjection(
  modelKey: ArrComputeModelKeys,
  data?: ProjectionCalcuationData,
  recompute?: boolean
) {
  const model = getModelByKey(modelKey)
  const payload = {
    landProjectId: state.activeLandProjectId,
    snapshotMainInterventionKey: model.key,
    forceRecompute: recompute ? 1 : 0,
    ...data,
  }
  addLoadingProjection(modelKey)

  logger.info('fetchProjectionCalculation -> ', modelKey, payload)
  const landProjectStats = await fetchProjectionCalculation(payload)
  logger.info('fetched ProjectionCalculation -> landProjectStats:', landProjectStats)

  if (landProjectStats) {
    const landProject = selectLandProjectOrActive()
    const projection = landProjectStatToProjection(landProjectStats, landProject)
    logger.info('calculated Projection -> ', projection)

    if (!projection.areaHa) {
      toast.warning('This model was calculated with an invalid area')
    } else {
      storeProjection(projection)
    }
  }

  removeLoadingProjection(modelKey)
}

async function fetchProjectionCalculation(
  data: {
    landProjectId: string
    snapshotMainInterventionKey: string
  } & ProjectionCalcuationData
) {
  try {
    const res = await fetchDB('computeProjectStatsInterventionOverride', data)
    if (
      !res.valid ||
      !res.landProjectStats ||
      Object.keys(res.landProjectStats).length === 0
    ) {
      logger.error(res.msg)
      toast.warning(res.msg)
      return null
    }
    return res.landProjectStats
  } catch (error) {
    logger.error(error)
  }
}

export async function markProjectionAsChosen(modelKey: ArrComputeModelKeys) {
  const activeLandProject = selectLandProjectOrActive()
  const newChosenModelKey =
    activeLandProject.chosenModelKey === modelKey ? null : modelKey
  const chosenLandProjectStats = _applicableLandProjectStats.find(
    (landProjectStats) =>
      landProjectStats.snapshotMainInterventionKey === newChosenModelKey
  ) as LandProject['xStats']
  const landProjectData = {
    chosenModelKey: newChosenModelKey,
    xStats: chosenLandProjectStats,
  }
  storeLandProjectData(landProjectData, activeLandProject._id)
  saveProjectData(landProjectData, activeLandProject._id)
}

export async function saveCustomProjection(
  data: Partial<LandProjectStats>,
  landProject: LandProject
) {
  logger.info('saveCustomProjection-> ', data)

  addLoadingProjection(data.snapshotMainInterventionKey)

  const res = await fetchDB<{ landProjectStats: LandProjectStats }>(
    'saveUploadedLandProjectStats',
    data
  )
  if (res.valid && res.landProjectStats) {
    const projection = landProjectStatToProjection(res.landProjectStats, landProject)
    storeProjection(projection)
  }

  toggleDialog(data.snapshotMainInterventionKey as ModelsWithInputs, false)
  removeLoadingProjection(data.snapshotMainInterventionKey)

  return res
}

export function enableProjectionAndCalculate(modelKey: ArrComputeModelKeys) {
  const alreadyCalculated = Boolean(getProjectionByKey(modelKey))

  if (alreadyCalculated) {
    enableProjectionByKey(modelKey)
  } else {
    const modelNeedsInputs = modelsWithInputsKeys.includes(modelKey)
    if (modelNeedsInputs) {
      toggleDialog(modelKey as ModelsWithInputs)
    } else {
      calculateProjection(modelKey)
    }
  }
}

export function storeProjection(projection: Projection) {
  logger.info('addProjection -> ', projection)

  console.log('carbonState.projections: ', carbonState.projections)
  if (carbonState.projections.length === 0) {
    carbonState.projections = [projection]
  }

  const existingProjectionIndex = carbonState.projections.findIndex(
    (p) => p.snapshotMainInterventionKey === projection.snapshotMainInterventionKey
  )

  if (existingProjectionIndex > -1) {
    carbonState.projections[existingProjectionIndex] = projection
  } else {
    carbonState.projections.push(projection)
  }

  enableProjectionByKey(projection.snapshotMainInterventionKey)
}

export function enableProjectionByKey(key: string) {
  carbonState.activeProjectionKeys = [...carbonState.activeProjectionKeys, key]
}

export function disableProjectionByKey(key: string) {
  carbonState.activeProjectionKeys = carbonState.activeProjectionKeys.filter(
    (k) => k !== key
  )
}

function addLoadingProjection(projectionKey: string) {
  carbonState.loadingProjectionKeys = [
    ...carbonState.loadingProjectionKeys,
    projectionKey,
  ]
}

function removeLoadingProjection(projectionKey: string) {
  carbonState.loadingProjectionKeys = carbonState.loadingProjectionKeys.filter(
    (key) => key !== projectionKey
  )
}

function resetState() {
  carbonState.projections = []
  carbonState.activeProjectionKeys = []
  carbonState.loadingProjectionKeys = []
}

export function toggleDialog(modelDialogKey: CarbonModelWithDialogKey, toggle?: boolean) {
  const newState = isNil(toggle) ? !carbonState.dialogs[modelDialogKey] : toggle
  carbonState.dialogs[modelDialogKey] = newState
}

// ----------------------------------
// Helpers --------------------------
// ----------------------------------

function getProjectionByKey(key: string) {
  return carbonState.projections.find((p) => p.snapshotMainInterventionKey === key)
}

// -----------------------------------
// Fetchers --------------------------
// -----------------------------------

export function filterLandProjectStatsByInUseModels(
  landProjectStats: LandProjectStats[]
) {
  return landProjectStats.filter((p) =>
    Object.values(ArrComputeModelKeys).includes(
      p.snapshotMainInterventionKey as ArrComputeModelKeys
    )
  )
}

async function fetchLandProjectEcozone(landProjectId: string) {
  const res = await fetchDB('getComputeEcozoneContinentLandProject', {
    landProjectId,
  })
  return res
}
