import { LineGraph } from '~/components/core/Graphs'
import { Title } from '~/components/profile/shared'
import { Column } from '~/styles'
import { colorScales } from '~/styles/colors'
import { ReportSectionData } from '~/types'

type Props = {
  sectionData: ReportSectionData
  withTitle?: boolean
  className?: string
  description?: string
}

export function AvoidedEmissionsGraph(props: Props) {
  const { className, withTitle = true } = props
  const { graph } = props.sectionData

  return (
    <Column className={className}>
      {withTitle && <Title className='mt-50'>{graph.title}</Title>}

      <LineGraph
        colors={colorScales.spectral4}
        {...graph}
        lineWidth={1.2}
        data={[
          {
            id: 'project area',
            data: graph.data.map((i, index) => ({
              x: index + 1,
              y: i.tCO2UnderProjectAreaRate ? i.tCO2UnderProjectAreaRate : null,
            })),
          },
          {
            id: '5km reference region',
            data: graph.data.map((i, index) => ({
              x: index + 1,
              y: i.tCO2UnderReferenceRegionRate5km
                ? i.tCO2UnderReferenceRegionRate5km
                : null,
            })),
          },
          {
            id: '20km region',
            data: graph.data.map((i, index) => ({
              x: index + 1,
              y: i.tCO2UnderReferenceRegionRate ? i.tCO2UnderReferenceRegionRate : null,
            })),
          },
          {
            id: 'average',
            data: graph.data.map((i, index) => ({
              x: index + 1,
              y: i.tCO2,
            })),
          },
        ]}
      />
    </Column>
  )
}
