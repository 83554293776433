import { cn } from '~/utils/cn'

import Icon from '~/components/atomic/Icon'

import { Row } from '~/styles'

export type ToastVariants = 'error' | 'warning' | 'info'

type Props = {
  text: string
  visible: boolean
  variant?: ToastVariants
}

export function Toast(props: Props) {
  const { text, visible, variant } = props

  const variantStyle = variants[variant]

  return (
    <div
      className={cn(
        'pointer-events-auto flex w-full max-w-md rounded-lg bg-white shadow-xl ring-1 ring-black ring-opacity-5',
        variantStyle.bg,
        { 'animate-enter': visible },
        { 'animate-leave': !visible }
      )}
    >
      <Row className='p-20'>
        <div
          className={cn(
            'center min-w-28 h-28 w-28 rounded-full shrink-0',
            variantStyle.icon
          )}
        >
          <Icon icon={variantStyle.iconName} size={18} />
        </div>
        <div className='mt-2 ml-12 font-medium'>{text}</div>
      </Row>
    </div>
  )
}

const variants: {
  [key in ToastVariants]: { bg: string; icon: string; iconName: string }
} = {
  error: {
    bg: '!bg-red-100 shadow-red-500/20 !ring-red-500/20',
    icon: '!bg-red-200 !text-red-800',
    iconName: 'error',
  },
  warning: {
    bg: '!bg-yellow-100 shadow-yellow-500/20 !ring-yellow-600/30',
    icon: '!bg-amber-300 !text-amber-800',
    iconName: 'warning',
  },
  info: {
    bg: '',
    icon: '!bg-blue-200 !text-blue-800',
    iconName: 'info_outline',
  },
}
