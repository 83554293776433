import { Coordinate } from '~/types'
import { RandomString } from '~/services/lodash'

export class Intervention {
  id: string
  interventionProfileId: string
  coordinates: Coordinate[]

  constructor(data: Partial<Intervention>) {
    return Object.assign(this, {
      id: RandomString(10),
      interventionProfileId: RandomString(10),
      ...data,
    })
  }
}
