import React, { PropsWithChildren, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { ErrorBoundary } from '~/components/atomic/ErrorBoundary'
import { SideMenu, SideOptions } from '~/components/core/SideMenu'

import { Row } from '~/styles'
import { Section } from '~/styles/main'

type Props = PropsWithChildren<{
  title?: string
  titleComponent?: React.ReactNode
  menu?: SideOptions
  sidebar?: React.ReactNode
}>

export function LayoutWithSideMenu(props: Props) {
  const { title, children, menu, sidebar, titleComponent } = props
  const scrollRef = React.useRef<HTMLDivElement>(null)

  const { pathname } = useLocation()

  useEffect(() => {
    scrollRef.current?.scrollTo(0, 0)
  }, [pathname])

  const sidebarComponent = sidebar || (
    <>
      <SidebarContainer>
        {titleComponent
          ? titleComponent
          : title && <SideMenu.Title>{title}</SideMenu.Title>}
        <SideMenu options={menu} />
      </SidebarContainer>
      <Row className='flex w-full md:hidden border-b border-ink-100'>
        <SideMenu options={menu} className='px-16 py-8' />
      </Row>
    </>
  )

  return (
    <div className='flex flex-col md:flex-row h-full'>
      <ErrorBoundary>
        {sidebarComponent}
        {/* <ProjectNavOverlay menus={}>{sidebarComponent}</ProjectNavOverlay> */}
      </ErrorBoundary>

      <ErrorBoundary>
        <div
          ref={scrollRef}
          className='flex h-full flex-1 flex-col overflow-scroll overflow-x-hidden relative'
        >
          {children}
        </div>
      </ErrorBoundary>
    </div>
  )
}

const SidebarContainer = ({
  className,
  ...rest
}: PropsWithChildren<{ className?: string }>) => (
  <div
    style={{ '--container-padding': '20px' } as any}
    className={`hidden md:flex md:flex-col w-[280px] flex-shrink-0 border-r h-full p-20 overflow-y-scroll ${className}`}
    {...rest}
  />
)

LayoutWithSideMenu.Container = SidebarContainer
LayoutWithSideMenu.Title = Section.Subtitle
