import mixpanel from 'mixpanel-browser'
import { AuthedUser } from '~/models/authedUser'
import { logger } from '~/services/logger'
import { isDev } from '~/utils'

const REACT_APP_MIXPANEL_TOKEN_PROD = process.env.REACT_APP_MIXPANEL_TOKEN_PROD
const REACT_APP_MIXPANEL_TOKEN_DEV = process.env.REACT_APP_MIXPANEL_TOKEN_DEV

const projectToken = isDev()
  ? REACT_APP_MIXPANEL_TOKEN_DEV
  : REACT_APP_MIXPANEL_TOKEN_PROD

export function initAnalytics() {
  if (!projectToken) throw 'Mixpanel .env token is missing'

  mixpanel.init(projectToken, { debug: isDev() })
}

export function identify(user: AuthedUser) {
  mixpanel.identify(user._id)
  mixpanel.people.set({
    id: user._id,
    $created_at: user.created_at,
    $updated_at: user.updated_at,
    $email: user.email,
    $name: `${user.first_name} ${user.last_name}`,
    roles: user.roles,
  })
}

export function track(eventName: string, properties?: any) {
  logger.info('analytics - track', eventName)
  mixpanel.track(eventName, properties)
}

export function trackView(viewName: string) {
  track(`View: ${viewName}`)
}
