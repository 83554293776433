const types = {
  tree: {
    opaque: 'tree',
    alpha: 'tree_alpha',
  },
}

export const mapLayers = [
  {
    id: 'forestLoss',
    label: 'Forest Loss [2010 - 2011]',
    metadataUrl:
      'https://data-api.globalforestwatch.org/dataset/umd_tree_cover_loss/latest',
    source: {
      tileSize: 256,
      type: 'raster',
      tiles: [
        'https://tiles.globalforestwatch.org/umd_tree_cover_loss/latest/dynamic/{z}/{x}/{y}.png?start_year=2010&end_year=2011',
      ],
    },
    layer: {
      type: 'raster',
    },
  },
  {
    id: 'forestLoss_latest',
    label: 'Forest Loss [2011 - 2021]',
    metadataUrl:
      'https://data-api.globalforestwatch.org/dataset/umd_tree_cover_loss/latest',
    source: {
      tileSize: 256,
      type: 'raster',
      tiles: [
        'https://tiles.globalforestwatch.org/umd_tree_cover_loss/latest/dynamic/{z}/{x}/{y}.png?start_year=2011&end_year=2021',
      ],
    },
    layer: {
      type: 'raster',
    },
  },
  {
    id: 'forestLoss_progression',
    label: 'Forest Loss Progression [2000 - 2021]',
    metadataUrl: 'https://glad.earthengine.app/view/global-forest-change#dl=3',
    metadata: {
      source: 'Hansen, Potapov, Moore, Hancher et al.',
      description: `Results from time-series analysis of Landsat images characterizing forest extent and change.
Trees are defined as vegetation taller than 5m in height and are expressed as a percentage per output grid cell as 2000 Percent Tree Cover. Forest Cover Loss is defined as a stand-replacement disturbance, or a change from a forest to non-forest state, during the period 2000–2021. Forest Cover Gain is defined as the inverse of loss, or a non-forest to forest change entirely within the period 2000-2012. Forest Loss Year is a disaggregation of total Forest Loss to annual time scales.
Reference 2000 and 2021 imagery are median observations from a set of quality assessment-passed growing season observations.`,
    },
    legend: {
      type: 'discrete',
      items: [
        { color: '#EA3323', text: '2021', id: '2021' },
        { color: '#EF8733', text: '2000-2021', id: '2000-2021' },
        { color: '#FFFF54', text: '2000', id: '2000' },
        { color: '#000000', text: 'No loss', id: 'No loss' },
        { color: '#404040', text: 'Water or no data', id: 'Water or no data' },
      ],
    },
    source: {
      tileSize: 256,
      type: 'raster',
      tiles: [
        'https://storage.googleapis.com/earthenginepartners-hansen/tiles/gfc_v1.9/loss_year_alpha/{z}/{x}/{y}.png',
      ],
    },
    layer: {
      type: 'raster',
    },
  },
  // {
  //   id: 'land_cover_esa',
  //   label: 'ESA',
  //   visible: false,
  //   source: {
  //     tileSize: 512,
  //     type: 'raster',
  //     tiles: [`https://s3.amazonaws.com/wri-tiles/global-landcover-2015/{z}/{x}/{y}.png`],
  //   },
  //   layer: {
  //     type: 'raster',
  //   },
  //   legend: esaLegend
  // },

  {
    id: 'tree_cover',
    label: 'Percent Tree Cover - 2000',
    visible: false,
    source: {
      tileSize: 256,
      type: 'raster',
      tiles: [
        `https://storage.googleapis.com/earthenginepartners-hansen/tiles/gfc_v1.4/${types.tree.opaque}/{z}/{x}/{y}.png`,
      ],
    },
    metadata: {
      description:
        "Trees are defined as vegetation taller than 5m in height and are expressed as a percentage per output grid cell as '2000 Percent Tree Cover'",
      source: 'Hansen',
    },
    layer: {
      type: 'raster',
    },
    legend: {
      type: 'discrete',
      items: [
        { color: '#00FF00', text: '75–100%', id: '75–100%' },
        { color: '#00AA00', text: '50–75%', id: '50–75%' },
        { color: '#005500', text: '25–50%', id: '25–50%' },
        { color: '#000000', text: '0–25%', id: '0–25%' },
        { color: '#404040', text: 'Water or no data', id: 'Water or no data' },
      ],
    },
  },
  {
    id: 'gfw_mining_concessions',
    label: 'Mining Concessions - 2022',
    visible: false,
    source: {
      tileSize: 512,
      type: 'vector',
      tiles: [
        'https://tiles.globalforestwatch.org/gfw_mining_concessions/v202203/default/{z}/{x}/{y}.pbf',
      ],
    },
    layer: {
      type: 'fill',
      'source-layer': 'gfw_mining_concessions',
      paint: { 'fill-color': 'rgb(199, 161, 237)', 'fill-opacity': 0.6 },
    },
    legend: {
      type: 'discrete',
      items: [
        {
          color: 'rgb(199, 161, 237)',
          id: 'mining-concessions',
          text: 'Mining concessions',
        },
      ],
    },
  },
  {
    id: 'gfw_managed_forests',
    label: 'Logging Concessions - 2021',
    visible: false,
    metadataUrl:
      'https://data-api.globalforestwatch.org/dataset/gfw_managed_forests/latest',
    source: {
      tileSize: 512,
      type: 'vector',
      tiles: [
        'https://tiles.globalforestwatch.org/gfw_managed_forests/v202106/default/{z}/{x}/{y}.pbf',
      ],
    },
    layer: {
      type: 'fill',
      'source-layer': 'gfw_managed_forests',
      paint: { 'fill-color': 'rgb(235, 205, 183)', 'fill-opacity': 0.6 },
    },
    legend: {
      type: 'discrete',
      items: [
        {
          color: 'rgb(235, 205, 183)',
          id: 'logging-concessions',
          text: 'Logging concessions',
        },
      ],
    },
  },
  {
    id: 'gfw_oil_palm',
    label: 'Palm Oil Concessions',
    metadataUrl: 'https://data-api.globalforestwatch.org/dataset/gfw_oil_palm/latest',
    source: {
      tileSize: 512,
      type: 'vector',
      tiles: [
        'https://tiles.globalforestwatch.org/gfw_oil_palm/v20191031/default/{z}/{x}/{y}.pbf',
      ],
    },
    layer: {
      type: 'fill',
      'source-layer': 'gfw_oil_palm',
      paint: { 'fill-color': 'rgba(126, 223, 223, 1)', 'fill-opacity': 0.6 },
    },
    legend: {
      type: 'discrete',
      items: [
        {
          color: 'rgba(126, 223, 223, 1)',
          id: 'palm-oil-concessions',
          text: 'Palm Oil concessions',
        },
      ],
    },
  },
  {
    id: 'global_population_jrc',
    label: 'Population Density - 2015',
    visible: false,
    metadataUrl: 'https://www.globalforestwatch.org/api/metadata/global_population_jrc/',
    source: {
      tileSize: 512,
      type: 'raster',
      tiles: [
        'https://api.resourcewatch.org/v1/layer/24aaef77-3cee-4bdd-b6c9-2f5ab147db7d/tile/gee/{z}/{x}/{y}',
      ],
    },
    layer: {
      type: 'raster',
    },
    legend: {
      type: 'discrete',
      items: [
        { color: '#32095d', text: '≤25', id: '≤25' },
        { color: '#781c13', text: '≤100', id: '≤100' },
        { color: '#ba3655', text: '≤1k', id: '≤1k' },
        { color: '#ed6825', text: '≤5k', id: '≤5k' },
        { color: '#fbb318', text: '≤10k', id: '≤10k' },
        { color: '#fdfea4', text: '>10k', id: '10k' },
      ],
    },
  },
  {
    id: 'wdpa_protected_areas',
    label: 'Protected Areas - 2021',
    visible: false,
    metadataUrl:
      'https://data-api.globalforestwatch.org/dataset/wdpa_protected_areas/latest',
    source: {
      tileSize: 512,
      type: 'vector',
      tiles: [
        'https://tiles.globalforestwatch.org/wdpa_protected_areas/v202208/default/{z}/{x}/{y}.pbf',
      ],
    },
    layer: {
      type: 'fill',
      'source-layer': 'wdpa_protected_areas',
      paint: {
        'fill-color': [
          'match',
          ['upcase', ['get', 'iucn_cat']],
          'IA',
          'rgba(92, 162, 209, 0.8)',
          'IB',
          'rgba(62, 123, 182, 0.8)',
          'II',
          'rgba(62, 123, 182, 0.8)',
          'III',
          'rgba(15, 59, 130, 0.8)',
          'IV',
          'rgba(185, 178, 161, 0.8)',
          'V',
          'rgba(174, 132, 126, 0.8)',
          'VI',
          'rgba(218, 168, 155, 0.8)',
          'NOT APPLICABLE',
          'rgba(238, 213, 76, 0.8)',
          'NOT ASSIGNED',
          'rgba(231, 171, 54, 0.8)',
          'NOT REPORTED',
          'rgba(250, 137, 75, 0.8)',
          'rgba(255, 255, 255, 0.8)',
        ],
      },
    },
    legend: {
      type: 'discrete',
      items: [
        {
          color: 'rgb(92, 162, 209)',
          id: 'Ia',
          text: 'strict nature reserve',
        },
        {
          color: 'rgb(62, 123, 182)',
          id: 'Ib',
          text: 'wildnerness area',
        },
        {
          color: 'rgb(15, 59, 130)',
          id: 'II',
          text: 'national park',
        },
        {
          color: 'rgb(185, 178, 161)',
          id: 'III',
          text: 'national monument or feature',
        },
        {
          color: 'rgb(185, 178, 161)',
          id: 'IV',
          text: 'habitat and species management area',
        },
        {
          color: 'rgb(174, 132, 126)',
          id: 'V',
          text: 'protected landscape or seascape',
        },
        {
          color: 'rgb(218, 168, 155)',
          id: 'VI',
          text: 'protected area with sustainable use of natural resources',
        },
        {
          color: 'rgb(238, 213, 76)',
          id: 'not-applicable',
          text: 'Not applicable',
        },
        {
          color: 'rgb(231, 171, 54)',
          id: 'not-assigned',
          text: 'Not assigned',
        },
        {
          color: 'rgb(250, 137, 75)',
          id: 'not-reported',
          text: 'Not reported',
        },
      ],
    },
  },
  {
    id: 'landmark_indigenous_and_community_lands',
    label: 'Indigenous and Community Lands',
    metadataUrl:
      'https://data-api.globalforestwatch.org/dataset/landmark_indigenous_and_community_lands/latest',
    source: {
      tileSize: 512,
      type: 'vector',
      tiles: [
        'https://tiles.globalforestwatch.org/landmark_indigenous_and_community_lands/latest/dynamic/{z}/{x}/{y}.pbf',
      ],
    },
    layer: {
      type: 'fill',
      'source-layer': 'landmark_indigenous_and_community_lands',
      paint: {
        'fill-color': [
          'case',
          [
            'all',
            ['==', ['get', 'identity'], 'Indigenous'],
            ['==', ['get', 'form_rec'], 'Acknowledged by govt'],
          ],
          'rgb(191, 105, 56)',
          [
            'all',
            ['==', ['get', 'identity'], 'Indigenous'],
            ['==', ['get', 'form_rec'], 'Unknown'],
          ],
          'rgb(191, 105, 56)',
          [
            'all',
            ['==', ['get', 'identity'], 'Indigenous'],
            ['==', ['get', 'form_rec'], 'Not acknowledged by govt'],
          ],
          'rgb(44, 86, 130)',
          [
            'all',
            ['==', ['get', 'identity'], 'Community'],
            ['==', ['get', 'form_rec'], 'Acknowledged by govt'],
          ],
          'rgb(243, 170, 114)',
          [
            'all',
            ['==', ['get', 'identity'], 'Community'],
            ['==', ['get', 'form_rec'], 'Not acknowledged by govt'],
          ],
          'rgb(64, 126, 190)',
          'rgb(255, 255, 255)',
        ],
      },
    },
    legend: {
      type: 'discrete',
      items: [
        {
          color: 'rgb(191, 105, 56)',
          id: 'indigenous-aknowledged',
          text: 'Indigenous Lands - Acknowledged by Government',
        },
        {
          color: 'rgb(250, 137, 75)',
          id: 'indigenous-not-aknowledged',
          text: 'Indigenous Lands - Not acknowledged by Government',
        },
        {
          color: 'rgb(44, 86, 130)',
          id: 'community-aknowledged',
          text: 'Community Lands - Acknowledged by Government',
        },
        {
          color: 'rgb(64, 126, 190)',
          id: 'community-not-aknowledged',
          text: 'Community Lands - Not acknowledged by Government',
        },
        {
          color: 'rgb(255, 255, 255)',
          id: 'unknown',
          text: 'Unknown',
        },
      ],
    },
  },
]

export function makeRasterLayer(id: string, label: string, url: string, rest) {
  return {
    id,
    label,
    source: {
      tileSize: 512,
      type: 'raster',
      tiles: [url],
    },
    layer: { type: 'raster' },
    ...rest,
  }
}
