import { useEffect, useRef } from 'react'
import ESSocket from '~/utils/ESSocket'

type SocketChannels = 'onReddScopingComputed' | 'onProjectAvoidedEmissionsComputed'

type Callbacks = {
  onRoute: (res: any) => void
  onCleanup: () => void
}
export function useSocket(channelName: SocketChannels, callbacks: Callbacks) {
  const { onRoute, onCleanup } = callbacks
  const _routeIds = useRef([])

  useEffect(() => {
    _routeIds.current.push(channelName)
    ESSocket.onRoute(channelName, (res) => {
      onRoute(res)
    })

    return function cleanup() {
      onCleanup()
      ESSocket.offRouteIds(_routeIds)
      _routeIds.current = []
    }
  }, [])
}
