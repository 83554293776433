export function timeDifference(current: number, previous: number) {
  const msPerMinute = 60 * 1000
  const msPerHour = msPerMinute * 60
  const msPerDay = msPerHour * 24
  const msPerMonth = msPerDay * 30

  const elapsed = current - previous

  if (elapsed < msPerMinute) {
    return Math.round(elapsed / 1000) + ' sec ago'
  } else if (elapsed < msPerHour) {
    return Math.round(elapsed / msPerMinute) + ' min ago'
  } else if (elapsed < msPerDay) {
    return Math.round(elapsed / msPerHour) + ' hrs ago'
  } else if (elapsed < msPerMonth) {
    return Math.round(elapsed / msPerDay) + ' d ago'
  } else {
    return new Date(previous).toLocaleDateString()
  }
}

export function getRelativeTime(date: string) {
  return timeDifference(new Date().getTime(), new Date(date).getTime())
}
