import { cn } from '~/utils/cn'

import { Numerical } from '~/components/core/Numerical'
import { WorkInProgressBanner } from '~/components/core/WorkInProgressBanner'
import { FinancialInputs } from '~/routes/project/financials/FinancialInputs'
import {
  useRecomputeFinancials,
  useFinancialsStore,
} from '~/routes/project/financials/financialsStore'
import { getIRRBackground, spreadsheetColors } from '~/routes/project/financials/styles'
import { Column } from '~/styles'
import { Section } from '~/styles/main'
import { useActiveLandProject } from '~/state'

export function FinancialsExplorer() {
  const { landProject } = useActiveLandProject()
  const projectType = landProject.projectType
  const projectTypeTitle = projectType === 'redd' ? 'REDD' : 'ARR'

  return (
    <Section
      title={`${projectTypeTitle} Financial Estimates Explorer`}
      className='space-y-32'
    >
      {projectType === 'arr' && <WorkInProgressBanner projectType={projectType} />}
      <FinancialInputs />
      <ProjectBalancesGrid />
    </Section>
  )
}

function ProjectBalancesGrid() {
  const { projectCashflows, investorCashflows } = useFinancialsStore()
  useRecomputeFinancials()

  const header = (
    <>
      <span className='text-center py-4 text-ink-500'>IRR</span>
      <span></span>
      {projectCashflows.map((_, i) => {
        return (
          <Numerical
            type='currency'
            className='text-center py-4 border-r border-ink-150'
            key={i}
            abbreviated
            value={projectCashflows[0][i].cost}
          />
        )
      })}
    </>
  )

  const rows = projectCashflows.map((rowData, i) => {
    const irr = investorCashflows[i].irr
    const investment = investorCashflows[i].investment
    return (
      <ProjectBalancesRow
        key={'row' + i + investment || ''}
        rowIndex={i}
        irr={irr}
        investment={investment}
        data={rowData}
      />
    )
  })

  return (
    <Column>
      <div className='font-bold'>Total Investment vs Annual Cost grid </div>
      <div
        style={{ width: 1000 }}
        className='grid grid-cols-[repeat(12,minmax(80px,max-content))]'
      >
        {header}
        {rows}
      </div>
    </Column>
  )
}

function ProjectBalancesRow(props) {
  const { irr, investment, data, rowIndex } = props

  return (
    <>
      <Numerical
        type='percent'
        style={{ background: getIRRBackground(irr) }}
        className={cn('py-2 text-center px-8 ring-1 ring-white rounded-md')}
        value={irr}
      />

      <Numerical
        type='currency'
        className='px-12 border-b border-ink-150'
        value={investment}
      />

      {data.map((cashflow, i) => {
        const key = 'cell' + rowIndex + investment + i
        return (
          <div
            key={key}
            style={{
              background: cashflow.cashflowPositive
                ? spreadsheetColors.paleGreen
                : spreadsheetColors.red,
            }}
            className={cn('text-center ring-1 ring-white')}
          >
            {cashflow.cashflowPositive ? 'ok' : 'neg'}
          </div>
        )
      })}
    </>
  )
}
