// TODO improve text color
import { tailwindColors } from '~/utils/tailwindColors'

const tailwindColorKeys = [
  'red',
  'orange',
  'amber',
  'yellow',
  'lime',
  'green',
  'emerald',
  'teal',
  'cyan',
  'sky',
  'blue',
  'indigo',
  'violet',
  'purple',
  'fuchsia',
  'pink',
  'rose',
]

export const COLOR_SCALE = tailwindColorKeys.map((key) => {
  const color = tailwindColors[key]
  return {
    bg: color[200],
    text: color[800],
  }
})

export function getUniqueObjectForId(id = '') {
  const colorIndex = getHash(id) % COLOR_SCALE.length
  return COLOR_SCALE[colorIndex]
}

export const getHash = (str: string): number => Math.abs(hashCode(str))

function hashCode(s = '') {
  if (!s.split) return 0
  return s.split('').reduce((a, b) => {
    // tslint:disable-next-line
    a = (a << 5) - a + b.charCodeAt(0)
    // tslint:disable-next-line
    return a & a
  }, 0)
}

export function getRGBA(hexColor: string, opacity: number) {
  return `rgba(${hexToRgb(hexColor)},${opacity})`
}

function hexToRgb(hex) {
  return hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => '#' + r + r + g + g + b + b
    )
    .substring(1)
    .match(/.{2}/g)
    .map((x) => parseInt(x, 16))
}
