import { cn } from '~/utils/cn'
import { PropsWithChildren } from 'react'
import Icon from '~/components/atomic/Icon'
import { badgeNoTextVariants, badgeVariants, ColorVariants } from '~/styles/colors'

export type BadgeSizes = 'small' | 'default'

export type Props = PropsWithChildren<{
  className?: string
  variant?: ColorVariants
  icon?: any
  iconSize?: number
  iconClasses?: string
  size?: BadgeSizes
}>

export function Badge(props: Props) {
  const {
    className,
    children,
    icon,
    iconSize = 18,
    iconClasses,
    size = 'default',
    variant = 'default',
    ...rest
  } = props

  return (
    <span
      className={cn(
        'inline-flex items-center rounded-full px-10 py-2 font-medium focus:outline-none',
        { '!p-6': !children },
        { [badgeVariants[variant]]: children },
        sizeVariants[size],
        { [badgeNoTextVariants[variant]]: !children },
        className
      )}
      {...rest}
    >
      {icon && (
        <Icon
          icon={icon}
          size={iconSize}
          className={`-ml-2 ${children && `mr-6`} opacity-70 ${iconClasses}`}
        />
      )}
      {children}
    </span>
  )
}

const sizeVariants = {
  small: 'text-12 font-medium py-1 px-6',
  default: '',
}
