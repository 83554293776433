import React from 'react'

import { InfoBox } from '~/components/atomic/InfoBox'
import { getProjectTypeLabel, ProjectType } from '~/models/landProject'

// Usage Exaxmple:
// {projectType === 'arr' && <WorkInProgressBanner projectType={projectType} />}

type Props = {
  projectType: ProjectType
  className?: string
}

export function WorkInProgressBanner(props: Props) {
  const { projectType, className = '' } = props

  return (
    <div className={`my-12 ${className}`}>
      <InfoBox variant='red'>
        This section is a work in progress for {getProjectTypeLabel(projectType)}{' '}
        projects. Please ask a LandOS team member for advice before using this section.
      </InfoBox>
      <div className='inset-0 absolute bg-orange-100 z-[-1]' />
    </div>
  )
}
