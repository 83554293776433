import { useConfirmDialog } from '~/components/dialogs/ConfirmDialog'
import { Dropdown } from '~/components/ui-custom/Dropdown'
import { LandProject } from '~/models'
import { archiveProject } from '~/services/db'

export function ArchiveProjectItem(props: { landProject: LandProject }) {
  const { landProject } = props
  const hasArchivePermission = landProject.permissions?.includes('editUser')

  const { setConfirmDialog, closeConfirmDialog } = useConfirmDialog()

  const handleArchiveProject = async () => {
    await archiveProject({
      landProjectId: landProject._id,
    })
    closeConfirmDialog()
  }

  const onClickArchiveProject = () => {
    if (!hasArchivePermission) return
    setConfirmDialog({
      content: `Are you sure you want to archive ${landProject?.name}?`,
      onConfirm: () => handleArchiveProject(),
      confirmText: 'Archive',
      confirmVariant: 'secondary',
    })
  }

  return (
    <Dropdown.Item
      icon='archive'
      onClick={onClickArchiveProject}
      disabled={!hasArchivePermission}
    >
      Archive Project
    </Dropdown.Item>
  )
}
