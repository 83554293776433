import { HorizontalStats } from '~/components/core/Stat'

type Props = {
  eligibilityStats: any // TODo improve typings
  className?: string
}

export function EligibilityStats(props: Props) {
  const { eligibilityStats, className } = props
  const { reforestableArea } = eligibilityStats?.reforestableAreaResults

  return (
    <>
      {eligibilityStats && (
        <HorizontalStats
          className={className}
          stats={[
            {
              label: 'Reforestable Area',
              value: reforestableArea.reforestableHa,
              format: 'area',
              unit: 'ha',
            },
            {
              label: 'Reforestable Percent',
              value: reforestableArea.reforestablePercent,
              format: 'percent',
              decimals: 2,
            },

            {
              label: 'Non-Reforestable Area',
              value: reforestableArea.nonReforestableHa,
              format: 'area',
              unit: 'ha',
            },
            {
              label: 'Non-Reforestable Percent',
              value: reforestableArea.nonReforestablePercent,
              format: 'percent',
              decimals: 2,
            },

            {
              label: 'Other LandCover Area',
              value: reforestableArea.otherHa,
              format: 'area',
              unit: 'ha',
            },
            {
              label: 'Other LandCover Percent',
              value: reforestableArea.otherPercent,
              format: 'percent',
              decimals: 2,
            },
          ]}
        />
      )}
    </>
  )
}
