import { PATH } from '~/utils/constants'
import { useSnapshot } from 'valtio'
import { AuthedUser } from '~/models/authedUser'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import ESSocket from '~/utils/ESSocket'
import { logger } from '~/services/logger'
import { state } from '~/state'
import { fetchDB } from '~/services/db'
import * as analytics from '~/services/analytics'
import { useLocation } from '~/utils/useLocation'

export function useRequireAuth() {
  const navigate = useNavigate()
  const snap = useSnapshot(state)
  const location = useLocation()

  useEffect(() => {
    if (snap.authedUser === null) {
      const redirectUrl = location.pathname
      let to = '/'

      // Send user to login or sing up depending on locaton state
      if (PATH['/logout'].includes(location.state?.from)) {
        to = 'login'
      } else {
        to = 'signup'
      }

      // state helps send data across navigations. in this case redirectUrl and also search, aka urlParams
      navigate(to, { replace: true, state: { redirectUrl, search: location.search } })
    } else {
      // Investor redirect logic
      if (snap.authedUser?.isInvestor) {
        const isProjectOverviewUrl =
          location.pathname.includes('portfolio') &&
          location.pathname.split('/').length >= 3

        if (isProjectOverviewUrl) {
          // Intentionally empty. Placeholder for specific project overview url logic
        } else {
          navigate(`/portfolio`, { replace: true })
        }
      }
    }
  }, [snap.authedUser])
}

export function useAuthedRedirect(redirectTo: string) {
  const navigate = useNavigate()
  const snap = useSnapshot(state)

  useEffect(() => {
    if (snap.authedUser) {
      navigate(redirectTo, { replace: true })
    }
  }, [snap.authedUser])
}

export function setAuthedUser(authedUser: AuthedUser) {
  logger.info('setAuthedUser: ', authedUser)
  if (authedUser) {
    state.authedUser = new AuthedUser(authedUser)
    localStorage.setItem('authedUser', JSON.stringify(authedUser))
    ESSocket.setAuth(authedUser._id, authedUser.session_id)
    analytics.identify(authedUser)
  } else {
    state.authedUser = null
    localStorage.removeItem('authedUser')
    ESSocket.setAuth('', '')
  }
}

export function updateAuthedUserData(userData: Partial<AuthedUser>) {
  const newAuthedUser = { ...state.authedUser, ...userData }
  setAuthedUser(new AuthedUser(newAuthedUser))
}

export async function checkAndLogin() {
  let authedUser = localStorage.getItem('authedUser') as any
  logger.info('checkAndLogin', JSON.parse(authedUser))

  if (authedUser !== null) {
    authedUser = JSON.parse(authedUser)

    const data = {
      user_id: authedUser._id,
      session_id: authedUser.session_id,
    }

    const res = await fetchDB('getUserSession', data)
    const { valid, user, msg } = res
    logger.info('onGetuserSession', user)

    if (valid && user?._id) {
      setAuthedUser(user)
    } else {
      setAuthedUser(null)
    }
  } else {
    setAuthedUser(null)
  }
}

export function useAuthedUser() {
  const snap = useSnapshot(state)
  return snap.authedUser as AuthedUser
}
