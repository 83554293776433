import { ProjectType } from '~/models/landProject'
import JSON_carbonPriceScenarios from './carbonPriceScenarios.json'

// Carbon Price Predictions are a series of estimate carbon prices over the next few years
// These predictions are done by the BizDev and NatureOps team. These predictions come from the following spreadsheet
// https://docs.google.com/spreadsheets/d/1-_DVHHtCKi_YgGAT_gyiqOQfV5-AIpy1_Zxu74i65qM/edit#gid=1448288141
// For each projectType we'll have low, medium and high scenarios
// The data in the spreadsheet is shown to start at a year and end at another year.
// start and end year are useful values because some projects might end up startins soone, or later than the data we have
// To solve for that. If the year is earlier than the data, we use the first year's price.
// If it's later, we use the last year's price.

export type CarbonPriceScenarios = 'low' | 'medium' | 'high'

export type CarbonPricePrediction = Record<CarbonPriceScenarios, number[]>

type CarbonPricePredictions = Record<'redd' | 'arr', CarbonPricePrediction> & {
  startYear: number
  lastYear: number
}

export const carbonPricePrdictions: CarbonPricePredictions = JSON_carbonPriceScenarios
const { startYear, lastYear } = carbonPricePrdictions

export function getPredictionsForProjectType(projectType: ProjectType) {
  return carbonPricePrdictions[projectType] as CarbonPricePrediction
}

export function getCarbonScenarioPredictions(
  projectType: ProjectType,
  scenario: CarbonPriceScenarios
) {
  return carbonPricePrdictions[projectType][scenario] as number[]
}

export function getPredictedCarbonPrice(
  calendarYear: number,
  projectType: ProjectType,
  scenario: CarbonPriceScenarios
) {
  const prices = carbonPricePrdictions[projectType][scenario]

  if (calendarYear < startYear) {
    return prices[0]
  } else {
    return prices[calendarYear - startYear] || prices[prices.length - 1]
  }
}
