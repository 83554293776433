import { createColumnHelper } from '@tanstack/react-table'
import { useMemo, useRef, useState } from 'react'
import { Badge } from '~/components/atomic/Badge'
import { CenteredLoader } from '~/components/atomic/CenteredLoader'
import { ComboBox } from '~/components/atomic/ComboBox'
import Icon from '~/components/atomic/Icon'
import Input from '~/components/atomic/Input'
import Link from '~/components/atomic/Link'
import { Pressable } from '~/components/atomic/Pressable'
import { Tooltip } from '~/components/atomic/Tooltip'
import TableLayout from '~/components/atomic/table/TableLayout'
import { Numerical } from '~/components/core/Numerical'
import { PopoverWithSearchableSelect } from '~/components/core/PopoverWithSearchableSelect'
import { ProjectMenu } from '~/components/menus/ProjectMenu'
import { ProjectsTable as Table } from '~/components/project/ProjectsTable'
import { LeadKeys, UserLeadInput } from '~/components/project/UserLeadInput'
import { Dropdown } from '~/components/ui-custom/Dropdown'
import { calculateLandProjectVCUS } from '~/data/financials/vcus'
import { LandProject } from '~/models'
import { useGetCountryRegulatoryMap } from '~/models/countryRegulation'
import { getScopingAssessmentForms } from '~/models/form'
import {
  ProjectType,
  PublishStatus,
  calculateLandProjectNPV,
  financingTypeOptions,
  getEligibleArea,
  getFinancingTypeLabel,
  getLandProjectArea,
  getLandProjectAreaSource,
  getPriorityLevel,
  getPriorityString,
  getProjectStageLabel,
  getProjectTotalAnnual,
  getProjectTotalAnnualPerHa,
  getProjectTotalTCO2,
  getPublishStatusLabel,
  prioritiesOptions,
  projectStagesOptions,
} from '~/models/landProject'
import { TrendCell, getHistoricalTrend } from '~/models/landProjectReddStats'
import { CountryCell } from '~/routes/projects/components/CountryCell'
import { ProjectsEmptyState } from '~/routes/projects/components/EmptyState'
import { useLandProjectsContext } from '~/routes/projects/hooks'
import { getProjectsFiltersInUrl } from '~/routes/projects/projectsFilters'
import { getCountryNameByCode } from '~/services/countries'
import { saveProjectData } from '~/services/db'
import { storeLandProjectData } from '~/state'
import { Row } from '~/styles'
import { PATH } from '~/utils/constants'
import { getFormData, reduceData } from '~/utils/index'
import { getRelativeTime } from '~/utils/times'
import { formatPercent } from '~/utils/units'
import { useOnClickOutside } from '~/utils/useOnClickOutside'

const columnHelper = createColumnHelper<LandProject>()

export function ProjectsTable() {
  const { landProjects, loading } = useLandProjectsContext()

  const sorted = landProjects && [...landProjects].sort(sortLandProjects)
  const countryRegulatoryMap = useGetCountryRegulatoryMap()
  const goNoGoForms = getScopingAssessmentForms()
  const filters = getProjectsFiltersInUrl()

  // TODO improve filtering by filtering the list of projects from the server
  const filteredProjects = sorted?.filter((lp) => {
    if (!filters) return true
    if (filters.projectType && filters.projectType !== lp.projectType) return false
    if (filters.priority && filters.priority !== getPriorityString(lp.priority))
      return false
    if (
      filters.projectLead &&
      filters.projectLead.toLowerCase() !== lp.projectLead?.toLowerCase()
    )
      return false
    if (
      filters.carbonLead &&
      filters.carbonLead.toLowerCase() !== lp.carbonLead?.toLowerCase()
    )
      return false
    if (
      filters.umbrellaPartner &&
      !lp.umbrellaPartner?.toLowerCase().includes(filters.umbrellaPartner.toLowerCase())
    )
      return false
    if (filters.stage && filters.stage !== lp.stage) return false
    if (filters.countryCode && filters.countryCode !== lp.countryCode) return false
    if (filters.financingType && filters.financingType !== lp.financingType) return false
    return true
  })

  const columns = useMemo(
    () => [
      columnHelper.accessor('priority', {
        header: 'Priority',
        meta: { align: 'left' },
        cell: (props) => (
          <PopoverWithSearchableSelect
            side='bottom'
            align='start'
            ComboBoxComponent={(props) => (
              <ComboBox
                placeholder='choose priority'
                options={prioritiesOptions}
                {...props}
              />
            )}
            onSelect={(option) => {
              const data = { priority: getPriorityLevel(option.value) }
              const landProjectId = props.row.original._id
              storeLandProjectData(data, landProjectId)
              saveProjectData(data, landProjectId)
            }}
          >
            <Pressable className='w-full'>
              <Badge variant={priorityToBadgeVariant(Number(props.getValue()))}>
                {priorityValueToString(Number(props.getValue()))}
              </Badge>
            </Pressable>
          </PopoverWithSearchableSelect>
        ),
      }),
      columnHelper.accessor('projectType', {
        header: 'Type',
        meta: { align: 'center' },
        cell: (props) => (
          <span className='text-12 !font-semibold tracking-wider text-ink-500'>
            {props.getValue()?.toUpperCase() || '-'}
          </span>
        ),
      }),
      columnHelper.accessor('name', {
        header: 'Name',
        meta: { align: 'left' },
        cell: (props) => (
          <Pressable
            to={`/project/${props.row.original.uName}`}
            className='!block w-[240px] whitespace-nowrap overflow-hidden	text-ellipsis !font-bold'
          >
            {props.row.original.name || '-'}
          </Pressable>
        ),
      }),
      columnHelper.accessor('umbrellaPartner', {
        header: 'Umbrella Partner',
        meta: { align: 'left' },
        cell: (props) => <EditableCell {...props} />,
      }),
      columnHelper.accessor('countryCode', {
        header: 'Country',
        meta: { align: 'left' },
        cell: (props) => {
          const landProject = props.row.original
          const countryName = getCountryNameByCode(props.getValue())
          return (
            <CountryCell
              pathToCountryAssessment={`${PATH['/project']}/${landProject.uName}/${PATH.land}/${PATH['country-regulation']}`}
              countryName={countryName}
              countryAssessment={countryRegulatoryMap?.[countryName]}
            />
          )
        },
      }),
      columnHelper.accessor('stage', {
        header: 'Stage',
        meta: { align: 'left' },
        cell: (props) => {
          return (
            <PopoverWithSearchableSelect
              side='bottom'
              align='start'
              ComboBoxComponent={(props) => (
                <ComboBox
                  placeholder='type stage...'
                  options={projectStagesOptions}
                  {...props}
                />
              )}
              onSelect={(option) => {
                const data = {
                  stage: option.value,
                }
                const landProjectId = props.row.original._id
                storeLandProjectData(data, landProjectId)
                saveProjectData(data, landProjectId)
              }}
            >
              <Pressable className='w-full whitespace-nowrap'>
                {getProjectStageLabel(props.getValue()) || '-'}
              </Pressable>
            </PopoverWithSearchableSelect>
          )
        },
      }),
      leadColumHelper('Project Lead', 'projectLead'),
      // leadColumHelper('Partnership Lead', 'partnershipLead'),
      // leadColumHelper('Carbon Lead', 'carbonLead'),
      columnHelper.accessor('carbonStatus', {
        header: 'Carbon Status',
        meta: { align: 'left' },
        cell: (props) => <EditableCell {...props} />,
      }),
      columnHelper.accessor('financingType', {
        header: 'Financing',
        meta: { align: 'left' },
        cell: (props) => {
          return (
            <PopoverWithSearchableSelect
              side='bottom'
              align='start'
              ComboBoxComponent={(props) => (
                <ComboBox
                  placeholder='type financing type...'
                  options={financingTypeOptions}
                  {...props}
                />
              )}
              onSelect={(option) => {
                const data = {
                  financingType: option.value,
                }
                const landProjectId = props.row.original._id
                storeLandProjectData(data, landProjectId)
                saveProjectData(data, landProjectId)
              }}
            >
              <Pressable className='w-full'>
                {getFinancingTypeLabel(props.getValue()) || '-'}
              </Pressable>
            </PopoverWithSearchableSelect>
          )
        },
      }),
      columnHelper.accessor((lp) => getLandProjectArea(lp) || '0', {
        id: 'polygonArea',
        header: 'Polygon Area',
        cell: (props) => {
          return <AreaRowComponent landProject={props.row.original} />
        },
      }),
      columnHelper.accessor((lp) => getEligibleArea(lp), {
        header: 'Eligible Area',
        cell: (props) => <Numerical type='area' value={props.getValue()} />,
      }),
      columnHelper.accessor((lp: LandProject) => getProjectTotalTCO2(lp), {
        id: 'tCO2e',
        header: () => (
          <TableLayout.HeaderWithTooltip tooltip='Basline Emissions(REDD) or Project removals (ARR) in eligible area before any discounts.'>
            Total tCO2e
          </TableLayout.HeaderWithTooltip>
        ),
        meta: {
          header: 'tCO2e',
        },
        cell: (props) => <Numerical abbreviated value={props.getValue()} />,
      }),
      columnHelper.accessor((lp: LandProject) => getProjectTotalAnnual(lp), {
        id: 'tCO2e/y',
        header: () => (
          <TableLayout.HeaderWithTooltip tooltip='Yearly average of Basline Emissions(REDD) or Project removals (ARR) in eligible area before any discounts.'>
            tCO2e/y
          </TableLayout.HeaderWithTooltip>
        ),
        meta: {
          header: 'tCO2e/y',
        },
        cell: (props) => <Numerical abbreviated value={props.getValue()} />,
      }),
      columnHelper.accessor((lp: LandProject) => getProjectTotalAnnualPerHa(lp), {
        id: 'tCO2e/y/ha',
        header: () => (
          <TableLayout.HeaderWithTooltip tooltip='Yearly average of Basline Emissions(REDD) or Project removals (ARR) in eligible area, before any discounts,divided by eligible area'>
            tCO2e/y/ha
          </TableLayout.HeaderWithTooltip>
        ),
        meta: {
          header: 'tCO2e/y/ha',
        },
        cell: (props) => <Numerical value={props.getValue()} decimals={2} />,
      }),
      columnHelper.accessor(
        (landProject: LandProject) => {
          const vcus = calculateLandProjectVCUS(landProject)
          const totalVCUs =
            landProject.projectType === 'redd'
              ? reduceData(vcus, 'annualEmissionReductions')
              : reduceData(vcus, 'cumulativeNetGHGEmissionReductionsBuffer')

          return totalVCUs
        },
        {
          id: 'vcus',
          header: 'VCUs',
          cell: (props) => (
            <Numerical value={props.getValue()} type='number' decimals={0} />
          ),
        }
      ),
      columnHelper.accessor(
        (landProject: LandProject) => {
          const vcus = calculateLandProjectVCUS(landProject)

          const totalVCUs =
            landProject.projectType === 'redd'
              ? reduceData(vcus, 'annualEmissionReductions')
              : reduceData(vcus, 'cumulativeNetGHGEmissionReductionsBuffer')

          return totalVCUs / vcus?.length
        },
        {
          id: 'vcusPerYear',
          header: 'VCUs/y',
          cell: (props) => (
            <Numerical value={props.getValue()} type='number' decimals={0} />
          ),
        }
      ),
      columnHelper.accessor(
        (landProject: LandProject) => {
          const vcus = calculateLandProjectVCUS(landProject)
          const totalVCUsPotentialRevenue = reduceData(vcus, 'revenue')
          return totalVCUsPotentialRevenue
        },
        {
          id: 'potentialRevenue',
          header: 'Potential Revenue (WIP)',
          cell: (props) => (
            <Numerical value={props.getValue()} type='currency' decimals={0} />
          ),
        }
      ),
      columnHelper.accessor(
        (landProject: LandProject) => {
          const vcus = calculateLandProjectVCUS(landProject)
          if (!vcus) return
          const cashflows = vcus.map((i) => i.revenue)
          const npv = calculateLandProjectNPV(cashflows)
          return npv
        },
        {
          id: 'npv',
          meta: { align: 'right', header: 'NPV (WIP)' },
          header: () => (
            <TableLayout.HeaderWithTooltip tooltip='Net Present Value with a 20% disccount. This metric will show higher numbers for projects where the carbon outcomes are higher earlier on.'>
              NPV
            </TableLayout.HeaderWithTooltip>
          ),
          cell: (props) => (
            <Numerical value={props.getValue()} type='currency' decimals={0} />
          ),
        }
      ),
      columnHelper.accessor(
        (landProject: LandProject) => {
          const vcus = calculateLandProjectVCUS(landProject)
          const eligibleArea = getEligibleArea(landProject)
          if (!vcus || !eligibleArea) return null
          const cashflows = vcus.map((i) => i.revenue)
          const npv = calculateLandProjectNPV(cashflows)
          return npv / eligibleArea
        },
        {
          id: 'npvha',
          header: () => (
            <TableLayout.HeaderWithTooltip tooltip='Net Present Value with a 20% disccount in relationship to the eligibile area.'>
              NPV/ha
            </TableLayout.HeaderWithTooltip>
          ),
          meta: {
            header: 'NPV/ha (WIP)',
          },
          cell: (props) => {
            return <Numerical value={props.getValue()} type='number' decimals={2} />
          },
        }
      ),
      columnHelper.accessor((row) => getHistoricalTrend(row.xStats) || '-', {
        id: 'historicalTrend',
        header: 'Historical Trend',
        meta: { align: 'left' },
        cell: (props) => <TrendCell trend={props.getValue()} />,
      }),
      columnHelper.accessor((row) => row.xStats?.forestNonForest?.forestHa || 0, {
        id: 'forestCoverHa',
        meta: {
          header: 'Forest Cover',
        },
        header: () => (
          <TableLayout.HeaderWithTooltip tooltip='Forest cover today in project area'>
            Forest Cover
          </TableLayout.HeaderWithTooltip>
        ),
        cell: (props) =>
          props.getValue() ? <Numerical type='area' value={props.getValue()} /> : '-',
      }),
      columnHelper.accessor(
        (row) => row.xStats?.combinedReferenceRegion?.annualDeforestationRate || 0,
        {
          id: 'deforestationRate',
          meta: {
            header: 'Deforestation Rate',
          },
          header: () => (
            <TableLayout.HeaderWithTooltip tooltip='Deforestation rate in the Reference region area'>
              Deforestation Rate
            </TableLayout.HeaderWithTooltip>
          ),
          cell: (props) =>
            props.getValue() ? `${formatPercent(props.getValue(), 2)}%` : '-',
        }
      ),
      columnHelper.accessor(
        (row) =>
          row.xStats?.deforestation?.annualDeforestationRate *
            row.xStats?.forestNonForest?.forestHa || 0,
        {
          id: 'annualDeforestationHa',
          meta: {
            header: 'Annual Deforestation Hectare',
          },
          header: () => (
            <TableLayout.HeaderWithTooltip tooltip='Average annual deforestation in project area'>
              Annual Deforestation Hectare
            </TableLayout.HeaderWithTooltip>
          ),
          cell: (props) =>
            props.getValue() ? <Numerical type='area' value={props.getValue()} /> : '-',
        }
      ),
      columnHelper.accessor((row) => row.xStats?.biomass?.CO2Equivalent || 0, {
        id: 'tCO2e/ha',
        header: () => (
          <TableLayout.HeaderWithTooltip tooltip='Emission Factor for average (tCO2e/Ha) in project forest area'>
            tCO2e/ha
          </TableLayout.HeaderWithTooltip>
        ),
        meta: {
          header: 'tCO2e/ha',
        },
        cell: (props) => <Numerical value={props.getValue()} decimals={2} />,
      }),
      columnHelper.accessor(
        (row) => row?.xStats?.byYear?.[1].tCO2AnnualAvoidedEmissions,
        {
          id: 'avoidedEmissionsYearOne',
          header: () => (
            <TableLayout.HeaderWithTooltip tooltip='Avoided Emission from year one in eligible area before any discounts.'>
              Avoided Emissions Year One
            </TableLayout.HeaderWithTooltip>
          ),
          meta: {
            header: 'Avoided Emissions Year One',
          },
          cell: (props) => <Numerical abbreviated value={props.getValue()} />,
        }
      ),
      // columnHelper.accessor(
      //   (landProject: LandProject) => {
      //     const countryName = getCountryNameByCode(landProject.countryCode)
      //     const formCompletion = getProjectCompletion(
      //       landProject,
      //       countryRegulatoryMap?.[countryName]
      //     )
      //     return formCompletion
      //   },
      //   {
      //     header: 'Completion',
      //     cell: (props) => <CompletionCell {...props} />,
      //   }
      // ),
      columnHelper.accessor('created_at', {
        header: 'Created',
        cell: (props) => <span>{getRelativeTime(props.getValue())}</span>,
      }),
      columnHelper.accessor('publishState', {
        header: '',
        meta: {
          header: 'Publish State',
        },
        cell: (props) => <PublishStateCell publishState={props.getValue()} />,
      }),
      columnHelper.display({
        id: 'actions',
        meta: {
          header: 'Actions',
        },
        cell: (props) => {
          return (
            <Row className='space-x-12'>
              <Dropdown content={<ProjectMenu landProject={props.row.original} />}>
                <Pressable icon='more_horiz' />
              </Dropdown>
              <Tooltip content='Open report'>
                <Link to={`/project/${props.row.original.uName}/reports/carbon`}>
                  <Pressable icon='launch' />
                </Link>
              </Tooltip>
            </Row>
          )
        },
      }),
    ],
    [countryRegulatoryMap]
  )

  return (
    <>
      <Table columns={columns as any} data={filteredProjects} />
      {loading && <CenteredLoader>Loading Projects</CenteredLoader>}
      {!loading && filteredProjects?.length === 0 && (
        <ProjectsEmptyState
          projectType={filters?.projectType as ProjectType}
          className='mt-[10vh]'
        />
      )}
    </>
  )
}

const priorityToBadgeVariant = (priority) => {
  return priority === 3 ? 'red' : priority === 2 ? 'orange' : 'gray'
}

const priorityValueToString = (priority) => {
  return priority === 3 ? 'high' : priority === 2 ? 'medium' : 'low'
}

function PublishStateCell(props: { publishState: PublishStatus }) {
  const { publishState } = props
  const state = publishState || `unpublished`

  return (
    <div className='w-full h-full flex center'>
      <Tooltip content={getPublishStatusLabel(state)}>
        <div className='w-full h-full flex center cursor-help'>
          <Icon icon={publishStateToIconMap[state]} size={16} className='opacity-50' />
        </div>
      </Tooltip>
    </div>
  )
}

const AreaRowComponent = ({ landProject }) => {
  const area = getLandProjectArea(landProject)
  const areaSource = getLandProjectAreaSource(landProject)

  const areaSourceText =
    areaSource === 'manual' ? 'From project area override' : 'From uploaded polygon'
  const icon = areaSource === 'manual' ? 'radio_button_checked' : 'radio_button_unchecked'

  if (!area) {
    return <span>-</span>
  }
  return (
    <div className='flex justify-end'>
      <Numerical type='area' value={area} />
      <Tooltip content={areaSourceText}>
        <div className='rel left-4 cursor-help'>
          <Icon icon={icon} size={9} className='opacity-50' />
        </div>
      </Tooltip>
    </div>
  )
}

const publishStateToIconMap = {
  unpublished: 'lock_outline',
  unlisted: 'lock_open',
  published: 'visibility',
  public: 'public',
}

export function EditableCell(props) {
  const [editing, setEditing] = useState(false)
  const value = props.getValue()
  const key = props.column.id
  const formRef = useRef<HTMLFormElement>()

  useOnClickOutside(formRef, (e) => {
    save(formRef.current)
    setEditing(false)
    e.stopPropagation()
  })

  function save(form) {
    const projectId = props.row.original._id
    const formData = getFormData<{ value: any }>(form)
    saveProjectData({ [key]: formData.value }, projectId)
    setEditing(false)
  }

  const onSubmit = (e) => {
    e.preventDefault()
    save(e.currentTarget)
  }

  if (editing)
    return (
      <form ref={formRef} onSubmit={onSubmit}>
        <Input name='value' autoFocus defaultValue={value} />
      </form>
    )

  return (
    <Pressable className='w-full h-full' onClick={() => setEditing(true)}>
      {value || '-'}
    </Pressable>
  )
}

function sortLandProjects(a: LandProject, b: LandProject) {
  // TODO priority 1 = low, that should probably be taken care of at some point.
  // I assume we should use 0 = low or default and then it goes up
  const aPriority = a.priority || 1
  const bPriority = b.priority || 1

  if (aPriority === bPriority) {
    return a.name.localeCompare(b.name)
  }
  return bPriority - aPriority
}

function leadColumHelper(header: string, leadKey: LeadKeys) {
  return columnHelper.accessor(leadKey, {
    header: header,
    meta: { align: 'left' },
    cell: (props) => {
      const userName = props.row.original[leadKey]
      const id = props.row.original._id
      return (
        <UserLeadInput
          userName={userName}
          leadKey={leadKey}
          landProjectId={id}
          size={18}
        />
      )
    },
  })
}
