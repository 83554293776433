import React, { useEffect, useState } from 'react'

import { Column, Row } from '~/styles'
import { makeArray } from '~/utils'
import { cn } from '~/utils/cn'
import { Pressable } from '~/components/atomic/Pressable'
import classed from '~/styles/classed'

type Props = {
  slides: any[]
  initialSlide: number
}

export function SlideShow(props: Props) {
  const { initialSlide, slides } = props
  const [currentSlide, setCurrentSlide] = useState(initialSlide)

  const Slide = slides[currentSlide].slide

  return (
    <Column className='h-[100vh] w-full fixed bg-white z-50'>
      <Column className='w-full h-full flex-1 center'>
        <Slide currentSlide={currentSlide} slidesData={slides} />
      </Column>
      <ProgressBar
        slidesCount={slides.length}
        currentSlide={currentSlide}
        setCurrentSlide={setCurrentSlide}
      />
    </Column>
  )
}

function ProgressBar(props) {
  const { slidesCount, currentSlide, setCurrentSlide } = props

  function handlePreviousSlide() {
    setCurrentSlide((s) => {
      if (s <= 0) return s
      return s - 1
    })
  }

  function handleNextSlide() {
    setCurrentSlide((s) => {
      if (s >= slidesCount - 1) return s
      return s + 1
    })
  }

  function goToSlide(slideIndex) {
    setCurrentSlide(slideIndex)
  }

  useEffect(() => {
    function onKeyDown(e) {
      if (e.key === 'ArrowLeft') handlePreviousSlide()

      if (e.key === 'ArrowRight') handleNextSlide()
    }

    document.addEventListener('keydown', onKeyDown)
    return () => document.removeEventListener('keydown', onKeyDown)
  }, [])

  return (
    <Column className='fixed bottom-0 w-full p-20 pb-0 gap-y-8 text-white z-20'>
      <div className='absolute inset-0 bg-gradient-to-t gradient from-black to-transparent' />
      <div className='flex justify-between text-13 font-medium relative z-20 '>
        <PrevNextButton onClick={handlePreviousSlide} disabled={currentSlide === 0}>
          Previous
        </PrevNextButton>
        {currentSlide + 1}
        <PrevNextButton
          onClick={handleNextSlide}
          disabled={currentSlide === slidesCount - 1}
        >
          Next
        </PrevNextButton>
      </div>
      <Row className='w-full relative z-20 '>
        {makeArray(slidesCount).map((_, i) => (
          <div
            className='flex flex-1 bg-red h-full cursor-pointer pt-8 pb-16 group'
            key={i}
            onClick={() => goToSlide(i)}
          >
            <div
              className={cn(
                'w-full group-first:ml-0 group-last:mr-0 mx-4 h-10 bg-gray-300 rounded-full bg-white/20 hover:scale-105 group-hover:bg-white/70 transition-all duration-150',
                { '!bg-white': currentSlide === i }
              )}
            />
          </div>
        ))}
      </Row>
    </Column>
  )
}

const PrevNextButton = classed(
  Pressable,
  `bg-ink-300/10 backdrop-blur-md ring-1 shadow-md ring-white/10 text-15 mx-0
    active:bg-white/30 duration-100 transition-colors
  `
)

const MapPanel = classed('div', 'bg-white shadow-400 px-16 py-12 rounded')

SlideShow.MapPanel = MapPanel
