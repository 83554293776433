import { FillLayer, Layer, LineLayer, Source } from 'react-map-gl'
import { FeatureCollection } from '~/utils/geo'

type Props = {
  featureCollection: FeatureCollection
  fillPaint?: FillLayer
  linePaint?: LineLayer
}

export function ProjectPolygonAreaLayers(props: Props) {
  const { featureCollection, fillPaint, linePaint } = props

  return (
    <Source id='projectArea' type='geojson' data={featureCollection}>
      <Layer
        id='projectAreaLine'
        type='line'
        paint={{
          'line-color': '#F0C325',
          'line-opacity': 1,
          'line-width': 2,
          ...linePaint,
        }}
      />
      <Layer
        id='projectAreaFill'
        type='fill'
        paint={{
          'fill-color': '#F0C325',
          'fill-opacity': 0.2,
          ...fillPaint,
        }}
      />
    </Source>
  )
}
