import { useState } from 'react'
import { Button } from '~/components/atomic/Button'
import Icon from '~/components/atomic/Icon'
import { Pressable } from '~/components/atomic/Pressable'
import { NavAuthButton } from '~/components/auth/NavAuthButton'

import { Nav } from '~/components/layouts/Nav'
import { Dropdown } from '~/components/ui-custom/Dropdown'
import { DropdownMenuItem } from '~/components/ui/dropdown-menu'
import {
  getProjectsFiltersInUrl,
  getProjectsRedirectPath,
} from '~/routes/projects/projectsFilters'
import { useAuthedUser } from '~/state/auth'
import { Column, Row } from '~/styles'
import classed from '~/styles/classed'
import { ResponsiveRow } from '~/styles/main'
import { PATH } from '~/utils/constants'

type Props = {
  className?: string
}

const toolLinks = [
  { to: PATH['form-builder'], label: 'Form Builder' },
  { to: PATH['polygons-tool'], label: 'Polygons tool' },
  { to: PATH['country-regulation-map'], label: 'Country Regulation Map' },
  { to: PATH['users'], label: 'Users Administration' },
  { to: PATH['arr-scoping-comparison'], label: 'ARR Scoping Comparison' },
]

export default function ProjectsNav(props: Props) {
  const { className } = props
  const authedUser = useAuthedUser()
  const [isFlyoutMenuOpen, setOpenFlyoutMenu] = useState<boolean>(false)
  const toggleMenu = () => setOpenFlyoutMenu(!isFlyoutMenuOpen)

  const homeButtonLink = `${PATH['/projects']}/${
    authedUser.isAdmin ? PATH.list + getProjectsRedirectPath() : PATH.summaries
  }`

  const content = (
    <>
      <ResponsiveRow className='flex-1 gap-40'>
        <Nav.LogoButton to={homeButtonLink} />

        <ResponsiveRow className='md:items-center flex-1 gap-12 md:gap-40'>
          {authedUser.isAdmin && (
            <>
              <Pressable to={homeButtonLink} icon='home' activeMatch='exact'>
                All projects
              </Pressable>
              <Pressable to={PATH['summaries']} icon='map' activeMatch='exact'>
                Map
              </Pressable>
              <Pressable to={PATH['stats']} icon='poll' activeMatch='exact'>
                Stats
              </Pressable>
              <Pressable
                href='/portfolio'
                icon='import_contacts'
                activeMatch='exact'
                openInNewTab
              >
                Portfolio
              </Pressable>

              <Dropdown
                content={toolLinks.map((page) => (
                  <DropdownMenuItem key={page.to}>
                    <a
                      href={`/tools/${page.to}`}
                      target='_blank'
                      className='flex flex-row items-center'
                      rel='noreferrer'
                    >
                      <div className='flex-1 mr-12'>{page.label}</div>
                      <Icon size={16} icon='launch' className='text-ink-500' />
                    </a>
                  </DropdownMenuItem>
                ))}
              >
                <Pressable iconRight='keyboard_arrow_down'>Tools</Pressable>
              </Dropdown>
            </>
          )}
        </ResponsiveRow>

        <ResponsiveRow className='row-vcenter gap-24'>
          {authedUser.isAdmin && (
            <>
              <Button
                icon='search'
                variant='secondary-square'
                onClick={onClickSearchButton}
              >
                Search
                <KBD className='ml-8'>⌘</KBD>
                <KBD className='ml-4'>K</KBD>
              </Button>

              <Button
                to={`new`}
                icon='add'
                variant='secondary-square'
                state={{ projectType: getProjectsFiltersInUrl()?.projectType }}
              >
                New Project
              </Button>
            </>
          )}
          <NavAuthButton />
        </ResponsiveRow>
      </ResponsiveRow>
    </>
  )

  return (
    <Nav className={className}>
      <NavOverlay open={isFlyoutMenuOpen} content={content} onToggle={toggleMenu} />

      <div className='hidden md:flex flex-row w-full'>{content}</div>

      <Row className='md:hidden w-full'>
        <Pressable className='mr-16' onClick={toggleMenu}>
          <Icon icon='menu' />
        </Pressable>

        <Pressable to='/projects' icon='home' activeMatch='exact'>
          All projects
        </Pressable>

        <div className='flex-1' />

        {authedUser.isAdmin && (
          <Button to={`new`} icon='add'>
            New Project
          </Button>
        )}
      </Row>
    </Nav>
  )

  function onClickSearchButton() {
    var ev = new KeyboardEvent('keydown', {
      altKey: false,
      key: 'k',
      metaKey: true,
    })
    document.dispatchEvent(ev)
  }
}

const KBD = classed(
  'kbd',
  'font-inter text-12 text-ink-400 font-semibold inline-flex center uppercase'
)

export function NavOverlay(props) {
  const { open, content, onToggle } = props

  return (
    <>
      <div
        style={{ pointerEvents: open ? 'all' : 'none', opacity: open ? 1 : 0 }}
        className='absolute inset-0 bg-black/10 z-[200] transition-opacity'
        onClick={onToggle}
      />
      <Column
        style={{
          '--container-padding': '12px',
          transform: !open && 'translateX(-200px)',
        }}
        className='fixed left-0 top-0 p-12 bottom-0 bg-white w-[200px] z-[201] overflow-y-scroll transition-transform'
        onClick={onToggle}
      >
        {content}
      </Column>
    </>
  )
}
