import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from '~/components/atomic/Button'
import Input from '~/components/atomic/Input'
import AuthForm from '~/components/auth/AuthForm'
import AuthLayout from '~/components/auth/AuthLayout'
import { fetchDB } from '~/services/db'
import { setAuthedUser } from '~/state/auth'
import { AuthedUser } from '~/models/authedUser'
import { getFormData } from '~/utils'

type FormData = {
  email: string
  password: string
  password_reset_key: string
}

function PasswordReset() {
  const navigate = useNavigate()
  const [error, setError] = useState('')
  const [submitting, setSubmitting] = useState(false)

  async function resetPassword(e) {
    e.preventDefault()
    const data = getFormData<FormData>(e.currentTarget)

    if (data.email && data.password && data.password_reset_key) {
      setSubmitting(true)
      setError('')

      const res = await fetchDB<{ user: AuthedUser }>('resetPassword', data)
      const { user, msg, valid } = res

      if (valid === 1 && user?._id) {
        setAuthedUser(user)
        navigate('/')
      } else {
        setError(msg)
      }
      setSubmitting(false)
    } else {
      setError('Please fill out all fields and try again.')
    }
  }

  return (
    <AuthLayout title='Reset Password'>
      <AuthForm onSubmit={resetPassword}>
        <Input name='email' type='email' label='Email' placeholder='your@email.com' />
        <Input
          name='password_reset_key'
          type='text'
          label='Reset Key'
          placeholder='Reset Key'
        />
        <Input name='password' type='password' label='Password' placeholder='Password' />

        {error && <div className='text-14 font-medium text-red-500'>{error}</div>}

        <Button type='submit' variant='primary' submitting={submitting}>
          Reset Password
        </Button>
      </AuthForm>
    </AuthLayout>
  )
}

export { PasswordReset }
