import { useState, useEffect } from 'react'
import { Matrix } from 'react-spreadsheet'
import isEqual from 'lodash.isequal'
import Select from '~/components/atomic/Select'
import Input from '~/components/atomic/Input'
import { Spreadsheet } from '~/components/core/Spreadsheet'
import {
  LiteratureMeasurements,
  REFORESTATION_SUB_TYPE,
  REFORESTATION_TYPE,
  TREATMENT,
} from './types'
import { getSelectOptionsWithLabels } from './utils'
import { Section } from '~/styles/main'
import { Button } from '~/components/atomic/Button'

type LiteratureMeasurementsProp = {
  data: LiteratureMeasurements
}

const REFORESTATION_TYPE_LABELS = getSelectOptionsWithLabels({
  types: REFORESTATION_TYPE,
})
const REFORESTATION_SUB_TYPE_LABELS = getSelectOptionsWithLabels({
  types: REFORESTATION_SUB_TYPE,
})
const TREATMENT_LABELS = getSelectOptionsWithLabels({
  types: TREATMENT,
  isOptional: true,
})

const MEASUREMENT_COLUMNS = {
  standAge: 'Stand Age',
  individualAge: 'Individual Age',
  meanDBHCentimeter: 'DBH Mean (cm)',
  sdDBHCentimeter: 'DBH stdev (cm)',
  meanHeightMeter: 'Height Mean (m)',
  sdHeightMeter: 'Height stdev (m)',
  measuredTreeCount: 'Tree count',
  carbonPool: 'Carbon Pool',
  carbonPoolMeanMgPerHa: 'Carbon pool mean',
  species: 'List of Species (separate by commas)',
}

const DEFAULT_MEASUREMENT_ROWS = 10
const DEFAULT_TABLE_DATA = Array(DEFAULT_MEASUREMENT_ROWS).map(() => [])

const LiteratureMeasurementsComponent = (props: LiteratureMeasurementsProp) => {
  const { data } = props
  const [measurementByAge, setMeasurementByAge] = useState([])
  const [tableData, setTableData] = useState(DEFAULT_TABLE_DATA)
  const [reforestationType, setReforestationType] = useState(data.reforestationType)

  useEffect(() => {
    setReforestationType(data.reforestationType)
  }, [data.reforestationType])

  useEffect(() => {
    const newTableData = (data.measurementByAge || []).map((row) =>
      Object.keys(MEASUREMENT_COLUMNS).map((key) => {
        if (key === 'species') {
          return { value: (row[key] || []).join(',') }
        }
        return { value: row[key] }
      })
    )
    if (newTableData.length > 0) {
      setMeasurementByAge(data.measurementByAge)
      setTableData(newTableData)
    }
  }, [data.measurementByAge])

  const onSpreadsheetChange = (data: Matrix<any>) => {
    if (!isEqual(data, tableData)) {
      const newMeasurements = data
        .map((row) => {
          if (!row) {
            return {}
          }

          const newData = {}

          row.forEach((col, colIndex) => {
            if (col?.value) {
              const key = Object.keys(MEASUREMENT_COLUMNS)[colIndex]
              if (key === 'species') {
                newData[key] = col.value.split(',').map((value) => value.trim())
              } else {
                newData[key] = col.value
              }
            }
          })
          return newData
        })
        .filter((row) => Object.keys(row).length > 0)
      setMeasurementByAge(newMeasurements)
      setTableData(data)
    }
  }

  const onAddRowClick = (event) => {
    event.preventDefault()

    const newTableData = [...tableData, []]
    setTableData(newTableData)
  }

  return (
    <>
      <Section className='gap-y-16'>
        <Section.Title>Plot Information</Section.Title>
        <Input
          name='plotId'
          label='Plot ID'
          placeholder=''
          defaultValue={data.plotId}
          className='w-1/3'
        />
        <Input
          name='plotRemarks'
          label='Plot Remarks'
          placeholder=''
          defaultValue={data.plotRemarks}
          className='w-2/3'
        />
        <div className='group grid grid-cols-3 gap-16'>
          <Select
            name='reforestationType'
            label='Reforestation Type'
            value={reforestationType}
            options={REFORESTATION_TYPE_LABELS}
            onChange={(event) => {
              setReforestationType(REFORESTATION_TYPE[event.target.value])
            }}
          />
          {reforestationType === REFORESTATION_TYPE.Agroforestry && (
            <Select
              name='reforestationSubType'
              label='Reforestation Subtype'
              defaultValue={data.reforestationSubType}
              options={REFORESTATION_SUB_TYPE_LABELS}
            />
          )}
        </div>
        <Input
          className='w-2/3'
          name='reforestationRemarks'
          label='Reforestation Remarks'
          placeholder=''
          defaultValue={data.reforestationRemarks}
        />
        <Input
          name='standingDensityPerHa'
          label='Standing Density Per Ha'
          placeholder='0'
          defaultValue={data.standingDensityPerHa}
          className='w-1/3'
        />
        <Input
          name='plantingDensityPerHa'
          label='Planting Density Per Ha'
          placeholder='0'
          defaultValue={data.plantingDensityPerHa}
          className='w-1/3'
        />
        <Select
          name='treatment'
          label='Treatment'
          defaultValue={data.treatment}
          options={TREATMENT_LABELS}
          className='w-2/3'
        />
        <Input
          name='treatmentRemarks'
          label='Treatment Remarks'
          placeholder=''
          defaultValue={data.treatmentRemarks}
          className='w-2/3'
        />
      </Section>
      <Section>
        {/* need to fix the margins here */}
        <Section.Title>Measurements</Section.Title>
        <Spreadsheet
          className='my-16'
          columnLabels={Object.values(MEASUREMENT_COLUMNS)}
          data={tableData}
          onChange={onSpreadsheetChange}
        />
        <input
          className='hidden'
          name='measurementByAge'
          value={JSON.stringify(measurementByAge)}
          readOnly
        ></input>
        <Button variant='secondary' onClick={onAddRowClick}>
          Add a new row
        </Button>
      </Section>
    </>
  )
}

export { LiteratureMeasurementsComponent }
