import { useEffect, useState } from 'react'
import { Button } from '~/components/atomic/Button'
import { CenteredLoader } from '~/components/atomic/CenteredLoader'
import { ErrorMessage } from '~/components/atomic/ErrorMessage'
import { InfoBox } from '~/components/atomic/InfoBox'
import Select from '~/components/atomic/Select'
import { EligibilityStats } from '~/routes/project/land/sections/reforestationEligibility/EligibilityStats'
import { amazoniaCountries, getCountryNameByCode } from '~/services/countries'
import { fetchDB } from '~/services/db'
import { logger } from '~/services/logger'
import { state, useActiveLandProject } from '~/state'
import { Divider, Section } from '~/styles/main'

type AllLandCoverData = any

export const ReforestationEligibilitySection = () => {
  const { landProject, parcels } = useActiveLandProject()
  const hasLandProjectXStats = Boolean(landProject?.xStats?.areaHa)

  const [allLandCoverData, setAllLandCoverData] = useState(null)
  const [chosenDatasetKey, setChosenDatasetKey] = useState(null)
  const [eligibilityStats, setEligibilityStats] = useState(null)

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      const getEligibilityStats = await fetchDB(
        'getReforestationEligibilityStatsbyProjectId',
        landProject['_id']
      )
      setEligibilityStats(getEligibilityStats?.reforestationEligibilityStats || null)
    }
    fetchData()
  }, [landProject])

  useEffect(() => {
    const fetchData = async () => {
      const getAllLandCoverData = await fetchDB('getAllLandCoverDataInfo')
      setAllLandCoverData(getAllLandCoverData?.data)
    }
    fetchData()
  }, [])

  if (!allLandCoverData) return <CenteredLoader />

  const availableDatasets = getDefaultLandCoverDatasetByCountry(
    landProject.countryCode,
    allLandCoverData['landEligibilityDataInfo']
  )
  const availableDatasetsOptions = getLandEligilityDatasets(availableDatasets)
  const defaultDatasetKey = chosenDatasetKey || availableDatasetsOptions?.[0]?.value
  const { startYear, endYear } = getStartAndEndYear(defaultDatasetKey, allLandCoverData)

  const getApplicableLandCover = () => {
    const applicableLandCovers =
      allLandCoverData.landEligibilityDataInfo[defaultDatasetKey].forestLandcover
    return applicableLandCovers?.map((lc) => lc.name).join('; ')
  }

  return (
    <Section title='Reforestation Eligibility (WIP)'>
      {!hasLandProjectXStats && <ProjectionInfoBoxError />}

      <Section.Subtitle>Calculation specifications</Section.Subtitle>
      <Select
        className='mb-10 w-[400px]'
        name='landcoverDataset'
        key='landcoverDataset'
        label='Select landcover dataset'
        options={availableDatasetsOptions}
        defaultValue={defaultDatasetKey}
        onChange={(e) => setChosenDatasetKey(e.target.value)}
      />

      <>
        <ul>
          <li>Start Year: {startYear}</li>
          <li>End Year: {endYear}</li>
          <li>Reforestable landcover classes: {getApplicableLandCover()}</li>
        </ul>

        <Button
          disabled={!hasLandProjectXStats || loading}
          type='submit'
          variant='primary'
          submitting={loading}
          className={'mt-10'}
          onClick={() => onComputeReforestableArea()}
        >
          Compute reforestable area
        </Button>
      </>

      {loading && (
        <div className='my-16'>Analyzing, this may take a minute or more...</div>
      )}
      {error && <ErrorMessage error={error} className='mt-8' />}

      {eligibilityStats && (
        <>
          <Divider />

          <Section.Subtitle>
            Eligible Area using: {eligibilityStats.computeModelInfo.dataset}
          </Section.Subtitle>
          <EligibilityStats eligibilityStats={eligibilityStats} />
        </>
      )}
    </Section>
  )

  async function onComputeReforestableArea() {
    setError(null)
    setLoading(true)

    const computationPayload = {
      landProjectId: state.activeLandProjectId,
      computationMethodKey: 'default_landcover_values', // alternative == 'custom_landcover_values', when user selections enabled
      startYear: startYear,
      endYear: endYear,
      areaHa: landProject.xStats['areaHa'],
      interventionZones: parcels,
      computeModelInfo: {
        computeMethod: 'reforestationEligibility',
        dataset: defaultDatasetKey,
      },
      nonForestClassType: 'reforestationEligible', // change if user should have control over landcovers used in calculation
    }

    const res = await fetchDB('computeReforestationEligibility', computationPayload)
    logger.info('On computeReforestationEligibility', res)

    if (res.valid) {
      const eligibilityStats = res['data']
      setEligibilityStats(eligibilityStats)

      const reforestationEligibilityStatsPayload = {
        ...computationPayload,
        reforestableAreaResults: {
          startYear: res['data']['reforestableAreaResults']['startYear'],
          endYear: res['data']['reforestableAreaResults']['endYear'],
          reforestableArea:
            eligibilityStats['reforestableAreaResults']['reforestableArea'],
        },
      }

      const savedReforestationEligibilityStats = await fetchDB(
        'saveReforestationEligibilityStatsbyProjectId',
        reforestationEligibilityStatsPayload
      )
      logger.info(
        'On saveReforestationEligibilityStatsbyProjectId',
        savedReforestationEligibilityStats
      )
    } else {
      setError(res?.msg || 'Something went wrong.')
    }

    setLoading(false)
  }
}

const getLandEligilityDatasets = (data) => {
  const results = []
  for (var key in data) {
    results.push({ value: key, label: key })
  }
  return results
}

const getDefaultLandCoverDatasetByCountry = (countryCode, data) => {
  const countryName = getCountryNameByCode(countryCode)
  var defaultDatasetsByCountry = {}
  var amazoniaDatasets = ['landcover_mapbiomas_amazonia', 'worldcover_dynamicworld']

  if (countryName === 'Brazil') {
    defaultDatasetsByCountry['landcover_mapbiomas_brazil'] =
      data['landcover_mapbiomas_brazil']
  } else if (amazoniaCountries.includes(countryName)) {
    defaultDatasetsByCountry = Object.keys(data)
      .filter((key) => amazoniaDatasets.includes(key))
      .reduce((obj, key) => {
        obj[key] = data[key]
        return obj
      }, {})
  } else {
    const regionSpecificDatasets = [
      'landcover_mapbiomas_brazil',
      'landcover_mapbiomas_amazonia',
    ]
    for (let i = 0; i < regionSpecificDatasets.length; i++) {
      delete data[regionSpecificDatasets[i]]
    }
    defaultDatasetsByCountry = data
  }
  return defaultDatasetsByCountry
}

const getStartAndEndYear = (landCoverKey: string, data: AllLandCoverData) => {
  const availableYears = data.landEligibilityDataInfo[landCoverKey].availableYears
  let startYear = Number(availableYears.split('-')[0])
  let endYear = Number(availableYears.split('-')[1])

  // Set start year to 10 years prior to end year, if possible
  if (endYear - startYear > 8) {
    startYear = endYear - 9
  }
  return { startYear, endYear }
}

const ProjectionInfoBoxError = () => {
  return (
    <div className='w-[600px] mb-20 '>
      <InfoBox variant='red'>
        No projection found for this project. The project needs to have xStats available
        to proceed with the calculations, please go to Carbon tab and choose a projection
      </InfoBox>
    </div>
  )
}
