import React, { PropsWithChildren } from 'react'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import classed from '~/styles/classed'
import { ErrorBoundary } from '~/components/atomic/ErrorBoundary'
import { Pressable } from '~/components/atomic/Pressable'
import { cn } from '~/utils/cn'

type Props = PropsWithChildren<{
  open?: boolean
  onClickOutside?: () => void
  className?: string
  triggerComponent?: React.ReactNode
  onOpenChange?: () => void
}>

export function Dialog(props: Props) {
  const {
    open,
    children,
    triggerComponent,
    onClickOutside,
    onOpenChange,
    className,
    ...rest
  } = props

  const portal = (
    <DialogPrimitive.Portal>
      <DialogPrimitive.Overlay className='fixed inset-0 z-20 bg-black/50' />
      <DialogPrimitive.Content
        style={{ '--dialogPadding': '24px' } as any}
        className={cn(
          'fixed z-50',
          'w-[95vw] max-w-md rounded-lg p-24 md:w-full',
          'top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]',
          'bg-white dark:bg-gray-800',
          'focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75',
          className
        )}
        onPointerDownOutside={onClickOutside}
        {...rest}
      >
        <ErrorBoundary>{children}</ErrorBoundary>
      </DialogPrimitive.Content>
    </DialogPrimitive.Portal>
  )

  const openProp = triggerComponent ? {} : { open }
  return (
    <DialogPrimitive.Root {...openProp} modal open={open} onOpenChange={onOpenChange}>
      {triggerComponent && (
        <DialogPrimitive.Trigger asChild>{triggerComponent}</DialogPrimitive.Trigger>
      )}
      {portal}
    </DialogPrimitive.Root>
  )
}

Dialog.Title = classed('div', 'text-20 font-bold mb-20')

Dialog.Description = classed('div', 'text-14 text-ink-800 -mt-12 mb-12')

Dialog.Divider = classed(
  'div',
  cn('border-b border-ink-150 mb-12 -mx-[var(--dialogPadding)]')
)

Dialog.CloseButton = (p) => (
  <DialogPrimitive.Close asChild>
    <Pressable
      className='absolute top-12 right-16 text-ink-600 hover:text-ink-900'
      iconSize={24}
      variant='default'
      icon='close'
      {...p}
    />
  </DialogPrimitive.Close>
)

Dialog.CloseIcon = (p) => (
  <Pressable className='absolute top-12 right-16' iconSize={24} icon='close' {...p} />
)

// Radix listens to the Escape key to close the dialog. Also, Radix marks the dialog as focused, so pressing
// Escape will trigger the closing.

Dialog.close = () =>
  document.dispatchEvent(new KeyboardEvent('keydown', { key: 'Escape' }))
