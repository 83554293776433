import { useEffect, useState } from 'react'
import { CenteredLoader } from '~/components/atomic/CenteredLoader'
import { TreesMap } from '~/components/firebaseBiome/TreesMap'

import { Column } from '~/styles'
import { fetchDB } from '~/services/db'
import { TreesData } from '~/models/biome'

type Props = {}

export function TreesMapSection(props: Props) {
  const {} = props
  const [treesData, setTreesData] = useState<TreesData>({ total: 0, treeInfos: null })
  treesData.treeInfos?.map((i) => console.log(i.uuid))

  useEffect(() => {
    async function get() {
      setTreesData({ total: 0, treeInfos: null })
      const res = await fetchDB('getTrees')
      setTreesData(res)
    }

    get()
  }, [])

  if (!treesData.treeInfos) return <CenteredLoader />

  return (
    <Column className='h-full'>
      {/* <Content className="row row-vcenter border-b space-x-40">
        <Row className="row-vcenter space-x-12">
          <span>Filter by Collection/project</span>
          <Input name="collectionFilter" />
        </Row>

        <Row className="row-vcenter space-x-12">
          <span>Filter by user email</span>
          <Input name="userFilter" />
        </Row>
      </Content> */}

      <Column className='w-full flex-1'>
        <TreesMap treesData={treesData} />
        {/* <Trees treesData={treesData} /> */}
      </Column>
    </Column>
  )
}

function Trees(props) {
  const { treesData } = props
  const eles = treesData.treeInfos.map((treeInfo, index) => {
    return (
      <div
        key={index}
        className='margin inline-block'
        style={{ maxWidth: '250px', verticalAlign: 'top' }}
      >
        <Tree tree={treeInfo} />
      </div>
    )
  })
  return (
    <div>
      <div className='margin-b'>{treesData.total} trees</div>
      <div>{eles}</div>
    </div>
  )
}

function Tree(props) {
  const eles = []
  for (const key in props.tree) {
    eles.push(
      <div>
        {key}: {props.tree[key]}
      </div>
    )
  }
  return <div>{eles}</div>
}
