import { saveAs } from 'file-saver'

import { useParams } from 'react-router-dom'
import { Button } from '~/components/atomic/Button'
import Icon from '~/components/atomic/Icon'
import Input from '~/components/atomic/Input'
import { Pressable } from '~/components/atomic/Pressable'
import Select from '~/components/atomic/Select'
import { formatArea, formatCO2 } from '~/utils/units'

import { useActiveLandProject, useParcel } from '~/state'
import { Column, Row } from '~/styles'
import ESHttp from '~/utils/ESHttp'

export function ParcelView() {
  const params = useParams()
  const { parcel_id } = params
  const { landProject } = useActiveLandProject()
  const { parcel } = useParcel(parcel_id)

  if (!parcel) return null

  const projectSlug = landProject.uName
  const { name } = parcel

  const totalCO2 = formatCO2(parcel?.xStats?.byYear?.[29].tCO2)
  const area = formatArea(parcel?.xStats?.areaHa)

  const onClickDownloadParcel = (evt) => {
    evt.preventDefault()
    const data = { parcels: [parcel] }
    ESHttp.Go('POST', 'generateGeojson', data)
      .then((res) => res.json())
      .then((url) => {
        if (url['valid'] === 1) {
          saveAs(url['fileUrl'])
        }
      })
  }

  return (
    <form className='space-y-20 p-20'>
      <Column className='text-14'>
        <Pressable
          to={`/project/${projectSlug}/land/parcels`}
          className='row row-vcenter self-start !text-ink-600'
        >
          <Icon icon='arrow-backwards' className='mr-8 opacity-60' size={18} />
          All Parcels
        </Pressable>

        <Row className='row-vcenter text-20 font-medium'>{name}</Row>
      </Column>

      <div>
        <Input.Label>Parcel Carbon Projection</Input.Label>
        {totalCO2} / 30 yrs
      </div>

      <div>
        <Input.Label>Parcel Area</Input.Label>
        {area}
      </div>

      <Select
        id='interventionType'
        name='interventionType'
        label='Intervention Type'
        options={[
          { value: 'unr', label: 'Unassisted Natural Regeneration' },
          { value: 'reforestation', label: 'Reforestation' },
        ]}
      />
      <Button onClick={onClickDownloadParcel}>Download Parcel</Button>
    </form>
  )
}
