import { useEffect, useState } from 'react'
import Input, { inputBoxClasses } from '~/components/atomic/Input'
import Link from '~/components/atomic/Link'
import { LandProjectAutoSavingInput } from '~/components/core/LandProjectAutoSavingInput'
import { LandProjectAutoSavingSelect } from '~/components/core/LandProjectAutoSavingSelect'
import { UserLeadInput } from '~/components/project/UserLeadInput'
import {
  getProjectStageLabel,
  getProjectTypeLabel,
  getProjectTypeOptions,
  projectStagesOptions,
} from '~/models/landProject'
import { LandMapSection } from '~/routes/project/land/sections/LandMapSection'
import { Documents } from '~/routes/project/summary/Documents'
import { getCountriesOptions, getCountryNameByCode } from '~/services/countries'
import { fetchDB } from '~/services/db'
import { useActiveLandProject } from '~/state'
import { Column, Row } from '~/styles'
import { Section } from '~/styles/main'
import { cn } from '~/utils/cn'
import { DEFAULT_START_YEAR, PATH } from '~/utils/constants'

export function SummarySection() {
  const { landProject } = useActiveLandProject()

  return (
    <Section className='min-h-full pb-20'>
      <div className='min-h-full gap-40 flex flex-wrap flex-row flex-grow'>
        <Column className='w-full max-w-[400px] gap-y-8 relative pb-40'>
          <Section.Subtitle>Project Info</Section.Subtitle>

          <InputItem label='Project Name'>
            <LandProjectAutoSavingInput
              valueKey='name'
              defaultValue={landProject.name}
              landProjectId={landProject._id}
            />
          </InputItem>

          <InputItem label='Project Type'>
            <LandProjectAutoSavingSelect
              options={getProjectTypeOptions()}
              valueKey='projectType'
              defaultValue={landProject.projectType}
              valueLabel={(value) => getProjectTypeLabel(value)}
              landProjectId={landProject._id}
            />
          </InputItem>

          <InputItem label='Project Start Year' type='number'>
            <LandProjectAutoSavingInput
              type='number'
              valueKey='startYear'
              defaultValue={Number(landProject?.startYear) || DEFAULT_START_YEAR}
              landProjectId={landProject._id}
            />
          </InputItem>

          <InputItem label='Project Stage'>
            <LandProjectAutoSavingSelect
              valueKey='stage'
              options={projectStagesOptions}
              defaultValue={landProject.stage}
              landProjectId={landProject._id}
              valueLabel={(value) => getProjectStageLabel(value)}
            />
          </InputItem>

          <InputItem label='Country'>
            <LandProjectAutoSavingSelect
              options={getCountriesOptions()}
              valueKey='countryCode'
              defaultValue={landProject.countryCode}
              valueLabel={(value) => getCountryNameByCode(value)}
              landProjectId={landProject._id}
            />
          </InputItem>

          <InputItem label='Project lead'>
            <UserLeadInput
              leadKey='projectLead'
              userName={landProject.projectLead}
              landProjectId={landProject._id}
              className={`${inputBoxClasses} !m-0`}
            />
          </InputItem>

          <InputItem label='Umbrella partner'>
            <LandProjectAutoSavingInput
              valueKey='umbrellaPartner'
              defaultValue={landProject.umbrellaPartner}
              landProjectId={landProject._id}
            />
          </InputItem>

          <InputItem label='Local partner'>
            <LandProjectAutoSavingInput
              valueKey='localPartner'
              defaultValue={landProject.localPartner}
              landProjectId={landProject._id}
            />
          </InputItem>

          <ProjectCreator userId={landProject.userIdOwner} className='mt-20' />
        </Column>

        <Link
          to={`../${PATH.land}`}
          className='max-h-full  min-h-[700px] min-w-[400px] flex-1 overflow-hidden rounded-lg cursor-zoom-in'
        >
          <div className='w-full h-full rounded-lg bg-emerald-100'>
            <LandMapSection
              hidePolygonUpload={true}
              showReferenceRegionUpload={false}
              hideControls
              interactive={false}
            />
          </div>
        </Link>
        <Column className='w-[280px]'>
          <Documents googleDriveFolderUrl={landProject?.googleDriveFolderUrl} />
        </Column>
      </div>
    </Section>
  )
}

export function InputItem(props) {
  const { label, children, className } = props
  return (
    <Row
      className={cn(
        'row-vcenter justify-between gap-12 [&>div]:w-full [&>div]:flex-1',
        className
      )}
    >
      <Input.Label className='w-full max-w-[140px]'>{label}</Input.Label>
      {children}
    </Row>
  )
}

function ProjectCreator(props: { userId: string; className?: string }) {
  const { userId, className } = props
  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')

  useEffect(() => {
    async function get() {
      const res = await fetchDB('getUserById', { userId })
      if (!res) return
      setName(` ${res.first_name} ${res.last_name} `)
      setEmail(res.email)
    }

    get()
  }, [userId, name])

  if (!name) return null

  return (
    <Row className={cn('row-vcenter space-x-8 text-13 text-ink-400', className)}>
      <span>Created by:</span>
      <span className={cn('ml-8 ')}>
        {name} ({email})
      </span>
    </Row>
  )
}
