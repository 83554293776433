import { Fragment } from 'react'
import { Circle } from '~/components/atomic/Circle'
import { Label } from '~/components/profile/shared'
import { getLandProjectDatum } from '~/models/projectDatum'
import { useActiveLandProject } from '~/state'
import { Column, Row } from '~/styles'

type Props = {
  className?: string
}

export function Timeline(props: Props) {
  const { className } = props
  const { landProject } = useActiveLandProject()
  const timeline = getLandProjectDatum(landProject, 'implementation-timeline').value

  if (!timeline) return null

  const pieces = timeline.split('\n')

  return (
    <div className='relative'>
      <Column className='relative z-10 grid sm:grid-cols-[max-content,1fr] sm:gap-20'>
        {pieces.map((i, key) => {
          const [label, text] = i.split(' - ')
          return (
            <Fragment key={key}>
              <Row className='row-vcenter'>
                <Circle size={10} className='mr-12 !bg-brand' />
                <Label>{label}</Label>
              </Row>
              <div className='ml-20 mb-20 text-16 sm:mb-0 sm:ml-0'>{text}</div>
            </Fragment>
          )
        })}
      </Column>
      <div className='absolute left-[5px] top-10 bottom-10 w-1 bg-ink-200' />
    </div>
  )
}
