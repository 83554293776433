import { useState, useEffect } from 'react'
import Select from '~/components/atomic/Select'
import { SegmentedControl } from '~/components/atomic/SegmentedControl'
import { LiteratureSite, SITE_INFORMATION_TYPES } from './types'
import { DecimalDegrees } from './siteInformation/DecimalDegrees'
import { DegreesMinutesSeconds } from './siteInformation/DegreesMinutesSeconds'
import { getCountriesOptions } from '~/services/countries'
import { getSelectOptionsWithLabels } from './utils'
import { Section } from '~/styles/main'
import Input from '../atomic/Input'
import { LiteratureMeasurementsComponent } from './LiteratureMeasurements'

const SITE_INFORMATION_TYPE_LABELS = getSelectOptionsWithLabels({
  types: SITE_INFORMATION_TYPES,
})

type LiteratureSiteProps = {
  data: LiteratureSite
}

const LiteratureSiteComponent = (props: LiteratureSiteProps) => {
  const { data } = props
  const [country, setCountry] = useState(data.country)
  const [type, setType] = useState(data.type)

  useEffect(() => {
    setCountry(data.country)
  }, [data.country])

  const location =
    SITE_INFORMATION_TYPES[type] === SITE_INFORMATION_TYPES.DecimalDegree ? (
      <DecimalDegrees data={data} />
    ) : (
      <DegreesMinutesSeconds data={data} />
    )

  return (
    <>
      <Section>
        <Section.Title>Site Information</Section.Title>
        <Input
          name='siteId'
          label='Site ID'
          placeholder=''
          defaultValue={data.siteId}
          className='w-1/3 my-8'
        />
        <Select
          name='country'
          label='Country'
          options={getCountriesOptions()}
          value={country}
          onChange={(event) => setCountry(event.target.value)}
          className='w-1/3 my-8'
        />
        <div className='group my-16'>
          <SegmentedControl
            name='siteType'
            options={SITE_INFORMATION_TYPE_LABELS}
            value={type.toString()}
            onValueChange={(value) => setType(SITE_INFORMATION_TYPES[value])}
          />
        </div>
        <div className='group mt-16 grid grid-cols-3 gap-16'>
          <div className='text-18 font-semibold'>Centroid</div>
        </div>
        {location}
      </Section>
      {data.literatureMeasurements.map((literatureMeasurement, index) => (
        <LiteratureMeasurementsComponent
          data={literatureMeasurement}
          key={`literatureMeasurements-${index}`}
        />
      ))}
    </>
  )
}

export { LiteratureSiteComponent }
