import { useState } from 'react'
import { Button } from '~/components/atomic/Button'
import Input from '~/components/atomic/Input'
import { VerticalStat } from '~/components/core/Stat'
import { InterventionScheduleSheet } from '~/components/land/InterventionScheduleSheet'
import { getLandProjectAreaInput, getLandProjectPolygonArea } from '~/models/landProject'
import { DatumKey, LandProjectDatums } from '~/models/projectDatum'
import { saveProjectDatums } from '~/services/db'
import { state, useActiveLandProject, useSnapshot } from '~/state'
import { Column, Row } from '~/styles'
import { Divider, Section } from '~/styles/main'
import { getFormData } from '~/utils'
import { formatNumber, formatPercent } from '~/utils/units'

const projetAreaKey: DatumKey = 'project-area' // TODO improve this

export function AreaSection() {
  const { landProject } = useActiveLandProject()
  const polygonArea = getLandProjectPolygonArea(landProject)
  const formattedPolygonArea = polygonArea ? formatNumber(polygonArea) : 0

  return (
    <Section title='Area'>
      <Column>
        <Section.Subtitle>Project Area</Section.Subtitle>
        <Row className='space-x-40'>
          <VerticalStat label='Polygon Area' value={formattedPolygonArea} unit='ha' />

          <AdjustedProjectArea />
        </Row>
      </Column>

      {landProject.projectType === 'arr' && (
        <>
          <Divider />

          <InterventionScheduleSheet />
        </>
      )}
    </Section>
  )
}

function AdjustedProjectArea() {
  const { landProject } = useActiveLandProject()
  const area = getLandProjectAreaInput(landProject)

  const [saving, setSaving] = useState(false)

  async function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    setSaving(true)

    const formData = getFormData<{ projetAreaKey: number }>(e.currentTarget)
    const projectArea = formData[projetAreaKey]

    const newDatums: LandProjectDatums = {
      [projetAreaKey]: parseFloat(projectArea),
    }

    await saveProjectDatums(newDatums)

    setSaving(false)
  }

  return (
    <form onSubmit={onSubmit}>
      <Row className={'flex w-full flex-row space-x-20'}>
        <Input
          label='Adjusted Project Area'
          name={projetAreaKey}
          defaultValue={area}
          placeholder={'-'}
          type='number'
          unitRight='ha'
          step='any'
        />
        <SaveButton saving={saving} />
      </Row>
    </form>
  )
}

const SaveButton = ({ saving }) => (
  <Button variant='primary' className='self-end' submitting={saving}>
    Save
  </Button>
)
