import { isRouteErrorResponse, useRouteError } from 'react-router-dom'

export function DefaultErrorElement() {
  const error = useRouteError()
  const message = isRouteErrorResponse(error)
    ? `${error.status} ${error.statusText}`
    : error instanceof Error
    ? error.message
    : JSON.stringify(error)
  const stack = error instanceof Error ? error.stack : null

  return (
    <div
      role='alert'
      className='col roundedw-full gap-20 center h-full flex-1 border-2 border-red-400 bg-pink-200/80 p-24'
    >
      <h2 className='text-16 font-bold text-red-800'>Unhandled Thrown Error!</h2>

      <h3 className='italic text-red-800'>{message}</h3>

      {stack ? (
        <pre className='rounded-lg p-8 bg-pink-200/50 ring-2 ring-pink-400'>{stack}</pre>
      ) : null}
    </div>
  )
}
