import React from 'react'
import { Pressable } from '~/components/atomic/Pressable'
import Select, { Props as SelectProps } from '~/components/atomic/Select'
import { LandProject } from '~/models'
import { saveProjectData } from '~/services/db'
import { storeLandProjectData } from '~/state'

type Props = {
  valueKey: keyof LandProject
  landProjectId: string
  valueLabel: (value: any) => string
} & SelectProps

export function LandProjectAutoSavingSelect(props: Props) {
  const { valueKey, landProjectId, options, defaultValue, valueLabel } = props
  return (
    <Select
      options={options}
      value={defaultValue}
      onChange={(event) => {
        const value = event.currentTarget.value
        const data: Partial<LandProject> = {
          [valueKey]: value,
        }
        storeLandProjectData(data, landProjectId)
        saveProjectData(data, landProjectId)
      }}
    >
      <Pressable>{valueLabel(defaultValue)}</Pressable>
    </Select>
  )
}
