import React from 'react'
import Input, { Props as InputProps } from '~/components/atomic/Input'
import { LandProject } from '~/models'
import { saveProjectData } from '~/services/db'
import { storeLandProjectData } from '~/state'

type Props = {
  valueKey: keyof LandProject
  landProjectId: string
} & InputProps

export function LandProjectAutoSavingInput(props: Props) {
  const { valueKey, landProjectId, defaultValue, type, ...rest } = props
  return (
    <Input
      defaultValue={defaultValue}
      onChange={(event) => {
        const value = event.currentTarget.value
        const parsedValue = type === 'number' ? Number(value) : value
        const data: Partial<LandProject> = {
          [valueKey]: parsedValue,
        }
        storeLandProjectData(data, landProjectId)
        saveProjectData(data, landProjectId)
      }}
      {...rest}
    />
  )
}
