import Select from '~/components/atomic/Select'

import baseline from '~/assets/data/baseline.json'

import { useSnapshot } from 'valtio'
import Input from '~/components/atomic/Input'
import { SimpleTable } from '~/components/atomic/table/SimpleTable'
import { StaticTable } from '~/components/atomic/table/StaticTable'
import TableLayout from '~/components/atomic/table/TableLayout'
import { ecosystemsOptions } from '~/models/ecosystem'
import {
  baselineStore,
  calculateLandCoverCarbonPerHa,
  checkMatch,
  getApplicableDataFromFilters,
  setFilters,
} from '~/routes/project/land/sections/baseline/baselineStore'
import { Column, Row } from '~/styles'
import { Divider } from '~/styles/main'
import { Option } from '~/types'
import { getLandCoverTypeLabel, LandCover } from '~/models/landCover'

type Props = {
  landCoverType: LandCover
}

const columns = [
  { Header: 'Continent', accessor: 'Continent' },
  { Header: 'Country', accessor: 'Country' },
  { Header: 'Climate Region', accessor: 'Climate Region' },
  { Header: 'Min tC/ha', accessor: 'Min  tC/ha' },
  { Header: 'Max tC/ha', accessor: 'Max tC/ha' },
  {
    Header: 'Midpoint or mean reported tC/ha',
    accessor: 'Midpoint or mean reported tC/ha',
    Footer: (p) => (
      <Column>
        <TableLayout.AverageFooter {...p} />
      </Column>
    ),
  },
  { Header: 'Notes', accessor: 'Notes' },
  { Header: 'Reference', accessor: 'Reference' },
]

export function LandCoverTypeCarbonSelector(props: Props) {
  const { landCoverType } = props
  const snap = useSnapshot(baselineStore)

  const landCoverTypeData = snap[landCoverType]

  const ecosystem = landCoverTypeData['ecosystem']
  const continent = landCoverTypeData['continent']
  const country = landCoverTypeData['country']
  const climate = landCoverTypeData['climate']
  const filters = { ecosystem, continent, country, climate }
  const area = landCoverTypeData.area

  const applicableData = getApplicableDataFromFilters({
    ...filters,
  })

  const carbonData = calculateLandCoverCarbonPerHa(applicableData)

  function onChangeFilter(e) {
    const filterName = e.target.name
    const value = e.target.value
    const filters = { [filterName]: value }
    setFilters(landCoverType, filters)
  }

  function onChangeArea(e) {
    const value = e.target.value
    baselineStore[landCoverType].area = parseInt(value) || 0
  }

  return (
    <Column className='rounded-lg border border-ink-100 p-20 shadow-md gap-20'>
      <Row className='row-vcenter gap-20 mb-20'>
        <div className='text-18 font-semibold'>
          {getLandCoverTypeLabel(landCoverType)}
        </div>
        <CompactSelect
          label='Ecosystem'
          name='ecosystem'
          value={ecosystem}
          onChange={onChangeFilter}
          options={ecosystemsOptions}
          className='w-[200px]'
        />
        <Input
          name='area'
          placeholder='Area...'
          unitRight='ha'
          value={area}
          onChange={onChangeArea}
        />
      </Row>
      <Row className='gap-80 flex-1'>
        <StaticTable
          className='flex-1 self-start'
          headers={['', 'mean', 'sd', 'se']}
          rows={[
            [
              'Midpoint',
              carbonData.mid.mean,
              carbonData.mid.stDev,
              carbonData.mid.stError,
            ],
          ]}
        />
        <StaticTable
          className='flex-1'
          headers={['', 'mean', 'lower 95%CI', 'upper 95% CI']}
          rows={[
            [
              'Carbon (t/ha)',
              carbonData.carbonTPerHa.mean,
              carbonData.carbonTPerHa.lower_95Ci,
              carbonData.carbonTPerHa.upper_95Ci,
            ],
            [
              'tCO2e/ha',
              carbonData.tCO2PerHa.mean,
              carbonData.tCO2PerHa.lower_95Ci,
              carbonData.tCO2PerHa.upper_95Ci,
            ],
          ]}
        />
      </Row>
      <Divider className='my-8' />
      <Column>
        <Row className='mb-8 row-vcenter gap-x-20'>
          <div className='text-14 font-semibold'>Filtered studies</div>
          <CompactSelect
            name='continent'
            label='Continents'
            placeholder='All'
            value={continent}
            options={getUniqueOptions(filterByEcosystem(ecosystem), 'Continent')}
            onChange={onChangeFilter}
            className='flex-1'
          />
          <CompactSelect
            name='country'
            label='Country'
            placeholder='All'
            value={country}
            options={getUniqueOptions(
              filterByEcosystemAndContinent(ecosystem, continent),
              'Country'
            )}
            onChange={onChangeFilter}
            className='flex-1'
          />
          <CompactSelect
            name='climate'
            label='Climate'
            placeholder='All'
            value={climate}
            options={getUniqueOptions(
              filterByEcosystemAndContinentAndCountry(ecosystem, continent, country),
              'Climate'
            )}
            onChange={onChangeFilter}
            className='flex-1'
          />
        </Row>
        <SimpleTable columns={columns} data={applicableData} />
      </Column>
    </Column>
  )
}

function filterByEcosystem(ecosystem) {
  const filtered = baseline.filter((item) => {
    return ecosystem === item['Ecosystem type'].toLowerCase()
  })
  return filtered
}

function filterByEcosystemAndContinent(ecosystem, continent) {
  const filtered = baseline.filter((item) => {
    return (
      ecosystem === item['Ecosystem type'].toLowerCase() &&
      checkMatch(item, continent, 'Continent')
    )
  })
  return filtered
}

function filterByEcosystemAndContinentAndCountry(ecosystem, continent, country) {
  const filtered = baseline.filter((item) => {
    return (
      ecosystem === item['Ecosystem type'].toLowerCase() &&
      checkMatch(item, continent, 'Continent') &&
      checkMatch(item, country, 'Country')
    )
  })
  return filtered
}

function getUniqueOptions(applicableData, key): Option[] {
  const items = applicableData.map((item) => item[key])
  const unique = [...new Set(items)].filter((item) => item !== '')
  const options = unique.map((item) => ({ label: item, value: item })) as Option[]
  return [{ value: '', label: 'All' }, ...options] || []
}

function CompactSelect(props) {
  const { label, name, ...rest } = props
  return (
    <Row className='row-vcenter rounded-md px-8 py-4 border border-ink-150'>
      <Input.Label className='mr-8 font-semibold' htmlFor={name}>
        {label}:
      </Input.Label>
      <Select
        name={name}
        {...rest}
        className='min-w-[100px]'
        selectClasses='p-0 !border-none h-[28px] shadow-none'
      />
    </Row>
  )
}
