import { HorizontalStat } from '~/components/core/HorizontalStat'
import {
  getLandMeta,
  getLandProjectMetaStats,
  getLandProjectType,
  getProjectLatLong,
} from '~/models/landProject'
import { useActiveLandProject } from '~/state'
import { Column } from '~/styles'

export function ProjectionInputsStats() {
  const { landProject } = useActiveLandProject()

  const metaStats = getLandProjectMetaStats(landProject)
  const projectType = getLandProjectType(landProject)

  const { lat, long } = getProjectLatLong(landProject)

  const stats = [
    { label: 'Project Type', value: projectType.toUpperCase() },
    metaStats.country,
    metaStats.area,
    metaStats.reforestableArea,

    { label: 'Latitude', value: lat?.toFixed(5) || '-' },
    { label: 'Longitude', value: long?.toFixed(5) || '-' },
  ]

  return (
    <Column className='space-y-8'>
      {stats.map((stat, key) => (
        <HorizontalStat key={key} {...stat} />
      ))}
    </Column>
  )
}
