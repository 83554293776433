import { Column } from '~/styles'

type Props = {
  title?: string
  uploadButton: any
}

export function UploadPolygonGroup(props: Props) {
  const { title = 'Upload project boundries', uploadButton } = props

  // TODO polish allow drag and drop

  return (
    <Column className='center'>
      <div className='mb-20 text-18 font-bold '>{title}</div>
      {uploadButton}
      <div className='mt-12 text-14 opacity-70'>
        .kml, .kmz, .geojson, or shapefile .zip
      </div>
    </Column>
  )
}
