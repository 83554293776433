import React, { PropsWithChildren } from 'react'

import { Column, Row } from '~/styles'
import { cn } from '~/utils/cn'
import classed from '~/styles/classed'

type Props = PropsWithChildren<{ className?: string }>

export function Slide(props: Props) {
  const { children, className } = props

  return (
    <div
      className={cn('max-w-[1440px] max-h-[900px] py-20 px-40 h-full w-full', className)}
    >
      {children}
    </div>
  )
}

Slide.Title = classed('div', 'text-24 tracking-[-0.01em] mt-20 mb-32')
Slide.SubtTitle = classed('div', 'text-16 font-medium tracking-[-0.01em]')
