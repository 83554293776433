import { BarGraph } from '~/components/core/Graphs'
import {
  useFinancialsStore,
  useInitFinancialStore,
} from '~/routes/project/financials/financialsStore'

import { formatNumber } from '~/utils/units'

type Props = {}

export function CreditIssuanceBarGraph(props: Props) {
  useInitFinancialStore()
  const { vcus } = useFinancialsStore()

  const barGraphData = vcus.map((i) => ({
    year: i.year,
    id: i.year,
    xLabel: 'Year',
    unit: 'Issued credits',
    'Issued VCUs': i.issued,
  }))

  return (
    <BarGraph
      indexBy='year'
      data={barGraphData}
      keys={['Issued VCUs']}
      legends={[
        {
          dataFrom: 'keys',
          itemWidth: 140,
          itemHeight: 20,
          anchor: 'top-left',
          direction: 'column',
          justify: false,
          translateY: 20,
          translateX: 20,
        },
      ]}
      valueFormat={(d) => formatNumber(d)}
      axisLeft={{ legend: 'Credits Issued' }}
      axisBottom={{ legend: 'Year' }}
    />
  )
}
