import { StatItems, StatValues } from '~/components/core/Stat'
import { getLandProjectMetaStats } from '~/models/landProject'
import { useActiveLandProject } from '~/state'

import { Column, Row } from '~/styles'
import { REGION_DESCRIPTION_IS_TOO_LONG } from '~/utils/constants'

export function LandStats() {
  const { landProject } = useActiveLandProject()
  const { country, region, area, reforestableArea, expansionArea } =
    getLandProjectMetaStats(landProject)

  const regionText = region.value as string

  return (
    <Column>
      <Row className='-mx-20 flex-wrap'>
        <LandStat {...country} />
        {regionText && regionText.length <= REGION_DESCRIPTION_IS_TOO_LONG && (
          <LandStat {...region} />
        )}
        <LandStat {...area} />
        <LandStat {...reforestableArea} />
        {expansionArea && <LandStat {...expansionArea} />}
      </Row>
    </Column>
  )
}

function LandStat(props: { className?: string } & StatValues) {
  const { className, ...rest } = props
  return (
    <Column className='m-20'>
      <StatItems labelClasses='text-14' valueClasses='text-24' {...rest} />
    </Column>
  )
}
