import { useSnapshot } from 'valtio'
import { AccountingSheet, RowData } from '~/components/core/AccountingSheet'
import { financialsStore } from '~/routes/project/financials/financialsStore'
import { Column } from '~/styles'
import { Section } from '~/styles/main'
import { cn } from '~/utils/cn'
import { ProponentCashflowData } from '~/data/financials/proponentCashflows'
import { useActiveLandProject } from '~/state'
import { WorkInProgressBanner } from '~/components/core/WorkInProgressBanner'

type Props = {}

export function StakeHolderReturns(props: Props) {
  const {} = props

  return (
    <Section title='Stakeholder returns' className='overflow-x-scroll space-y-40 !pb-80'>
      <ProjectData />
      <InvestorReturns />
      <EarthshotReturns />
      <ProponentReturns />
    </Section>
  )
}

function ProjectData() {
  const { landProject } = useActiveLandProject()
  const { vcus } = useSnapshot(financialsStore)

  const rows: RowData[] = [
    { label: 'Credits' },
    {
      label: 'Year',
      accessor: 'yearDate',
    },
    {
      label: 'Credits Issued',
      amount: vcus.reduce((prev, curr) => prev + curr.issued, 0),
      accessor: 'issued',
    },
  ]
  return (
    <Column>
      <WorkInProgressBanner projectType={landProject.projectType} />

      <Section.Subtitle className='mb-0'>Project</Section.Subtitle>
      <AccountingSheet rows={rows} data={vcus} />
    </Column>
  )
}

function InvestorReturns() {
  const { variables, fixedInvestorCashflow: cashflow } = useSnapshot(financialsStore)

  const rows: RowData[] = [
    { label: 'Income' },
    { label: 'Year', accessor: 'calendarYear', type: 'unformatted' },
    {
      label: `Share of Credits ${variables.investorCreditShare * 100}%`,
      accessor: 'investorVCUs',
      amount: cashflow.totalInvestorVcus,
    },
    {
      label: 'Total value of credits',
      accessor: 'revenue',
      amount: cashflow.totalRevenue,
      type: 'currency',
    },
    { label: 'Costs' },
    {
      label: 'Investment',
      accessor: 'investment',
      amount: cashflow.investment,
      type: 'currency',
    },

    {
      label: 'Brokerage',
      type: 'currency',
      amount: cashflow.totalBrokerage,
      accessor: 'brokerage',
    },
    {
      label: 'Total Costs',
      type: 'currency',
      amount: cashflow.totalCosts,
      accessor: (d) => d.costs,
    },

    { label: 'Profit' },

    {
      label: 'Profits before Tax',
      type: 'currency',
      amount: cashflow.investment + cashflow.totalBrokerage,
      accessor: (d) => d.revenue + d.investment,
    },
    {
      label: 'Tax',
      type: 'percent',
      amount: 0,
    },
    {
      label: 'Profits after tax',
      type: 'currency',
      amount: cashflow.investment + cashflow.totalBrokerage,
      accessor: (d) => d.revenue + d.investment,
      className: 'font-semibold',
    },
    {
      label: `Investors' IRR`,
      amount: cashflow.irr,
      type: 'percent',
      className: 'font-semibold',
      summaryClassName: cn('font-semibold', cashflow.irr < 0 && 'text-red-500'),
    },
  ]
  return (
    <Column>
      <Section.Subtitle className='mb-0'>Investor</Section.Subtitle>
      <AccountingSheet rows={rows} data={cashflow.data} />
    </Column>
  )
}

function EarthshotReturns() {
  const { variables, fixedEarthshotCashflow: cashflow } = useSnapshot(financialsStore)

  const rows: RowData[] = [
    { label: 'Income' },
    {
      label: `Share of credits ${variables.earthshotCreditShare * 100}%`,
      amount: cashflow.totalShareOfCredits,
      accessor: 'creditsRevenue',
    },
    {
      label: `Credits Revenue`,
      amount: cashflow.totalCreditsRevenue,
      type: 'currency',
      accessor: 'creditsRevenue',
    },
    {
      label: 'Brokerage',
      accessor: 'brokerage',
      amount: cashflow.totalBrokerage,
      type: 'currency',
    },
    {
      label: 'Total revenue',
      accessor: 'income',
      amount: cashflow.totalIncome,
      type: 'currency',
    },

    { label: 'Profits' },
    {
      label: 'Profits before tax',
      accessor: 'income',
      amount: cashflow.totalIncome,
      type: 'currency',
    },
    { label: 'Tax' },
    {
      label: 'Profits after tax',
      accessor: 'income',
      amount: cashflow.totalIncome,
      type: 'currency',
    },
  ]

  return (
    <Column>
      <Section.Subtitle className='mb-0'>Earthshot</Section.Subtitle>
      <AccountingSheet rows={rows} data={cashflow.data} />
    </Column>
  )
}

function ProponentReturns() {
  const { variables, fixedProponentCashflow: cashflow } = useSnapshot(financialsStore)

  const rows: RowData<ProponentCashflowData>[] = [
    { label: 'Income' },
    {
      label: `Revenue from pre-purchase agreement`,
      amount: cashflow.totals.revenueFromPrePurchaseAgreement,
      accessor: 'revenueFromPrePurchaseAgreement',
      type: 'currency',
    },
    {
      label: `Share of credits ${Math.round(variables.projectCreditShare * 100)}%`,
      amount: cashflow.totals.shareOfCredits,
      accessor: 'shareOfCredits',
    },
    {
      label: `Revenue from spot market sales ${Math.round(
        variables.projectCreditShare * 100
      )}%`,
      amount: cashflow.totals.revenueFromCredits,
      accessor: 'revenueFromCredits',
      type: 'currency',
    },
    {
      label: `Total Income`,
      amount: cashflow.totals.revenueFromCredits,
      accessor: 'income',
      type: 'currency',
    },

    { label: 'Costs' },

    {
      label: `Revenue shared with community`,
      amount: cashflow.totals.revenueSharedWithCommunity,
      accessor: 'revenueSharedWithCommunity',
      type: 'currency',
    },

    {
      label: `Costs after inflation`,
      amount: cashflow.totals.costAfterInflation,
      accessor: 'costAfterInflation',
      type: 'currency',
    },
    {
      label: `Base Costs`,
      amount: cashflow.totals.baseCosts,
      accessor: 'baseCosts',
      type: 'currency',
    },
    {
      label: `Costs after inflation`,
      amount: cashflow.totals.costAfterInflation,
      accessor: 'costAfterInflation',
      type: 'currency',
    },
    {
      label: `Total Costs`,
      amount: cashflow.totals.costs,
      accessor: 'costs',
      type: 'currency',
    },

    { label: 'Profits' },

    {
      label: 'Profits before tax',
      amount: cashflow.totals.profitBeforeTax,
      accessor: 'profitBeforeTax',
      type: 'currency',
    },
    {
      label: `Tax (${variables.taxRate * 100}%)`,
      amount: cashflow.totals.profitBeforeTax,
      accessor: 'profitBeforeTax',
      type: 'currency',
    },
    {
      label: 'Profits after tax',
      accessor: 'income',
      amount: cashflow.totals.profitAfterTax,
      type: 'currency',
    },
  ]

  return (
    <Column>
      <Section.Subtitle className='mb-0'>Proponent</Section.Subtitle>
      <AccountingSheet rows={rows} data={cashflow.data} />
    </Column>
  )
}
