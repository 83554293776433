import { REFORESTATION_SUB_TYPE } from "./reforestationSubType"

enum SOURCE_TYPES {
  'Cook-Patton et al. 2020' = 'Cook-Patton et al. 2020',
  'Bukoski et al. 2022' = 'Bukoski et al. 2022',
  'internal' = 'internal',
  'outsideContributor' = 'outsideContributor',
}

type LiteraturePublication = {
  userName?: {
    firstName: string
    userId: string
  }
  source?: SOURCE_TYPES
  partnerName?: string
  partnerStudyID?: string
  authors: string
  title: string
  journal?: string
  year?: number
  sourceUrl: string
  openSource: boolean
}

const DEFAULT_LITERATURE_PUBLICATION: LiteraturePublication = {
  sourceUrl: '',
  openSource: true,
  title: '',
  authors: '',
}

enum REFORESTATION_TYPE {
  'Enhanced Natural' = 'Enhanced Natural',
  'Spontaneous Natural' = 'Spontaneous Natural',
  'Agroforestry' = 'Agroforestry',
  'Tree Monocrop' = 'Tree Monocrop',
  'Tree Polycrop' = 'Tree Polycrop',
  'Silvo Pastoral' = 'Silvo Pastoral',
  'Cropland' = 'Cropland',
  'Pasture' = 'Pasture',
}

enum TREATMENT {
  'fertilizer' = 'Fertilizer',
  'thinning' = 'Thinning',
  'soilAmmeded' = 'Soil Ammeded',
  'soilTilled' = 'Soil Tilled',
  'irrigation' = 'Irrigation',
  'custom' = 'Custom'
}

type LiteratureMeasurements = {
  plotId?: string
  plotRemarks?: string
  reforestationType: REFORESTATION_TYPE
  reforestationSubType: REFORESTATION_SUB_TYPE
  reforestationRemarks?: string
  standingDensityPerHa?: number
  plantingDensityPerHa?: number
  treatment?: TREATMENT
  treatmentRemarks?: string
  measurementByAge?: MeasurementByAge[]
}

type MeasurementByAge = {
  standAge?: number,
  individualAge?: number,
  meanDBHCentimeter?: number,
  sdDBHCentimeter?: number,
  meanHeightMeter?: number,
  sdHeightMeter?: number,
  measuredTreeCount?: number,
  carbonPool?: 'abovegroundBiomass', 'abovegroundCarbon', 'understoryBiomass', 'understoryCarbon', 'litterBiomass', 'litterCarbon', 'deadwoodBiomass', 'deadwoodCarbon', 'belowgroundBiomass', 'belowgroundCarbon', 'soilOrganicCarbon', 'pctSoilOrganicMatter', 'pctSoilOrganicCarbon',
  carbonPoolMeanMgPerHa?: number,
}

const DEFAULT_LITERATURE_MEASUREMENTS: LiteratureMeasurements = {
  plotId: '',
  plotRemarks: '',
  reforestationType: REFORESTATION_TYPE.Agroforestry,
  reforestationSubType: REFORESTATION_SUB_TYPE['Agroforestry Fallow (N fixing)'],
  reforestationRemarks: '',
  treatmentRemarks: '',
  measurementByAge: []
}

enum SITE_INFORMATION_TYPES {
  DecimalDegree = 'DecimalDegree',
  DegreesMinutesSeconds = 'DegreesMinutesSeconds',
}

enum LON_DIRECTIONS {
  E = 'E',
  W = 'W',
}

enum LAT_DIRECTIONS {
  N = 'N',
  S = 'S',
}

type LiteratureSite = {
  _id?: string
  siteId?: string
  country: string
  type: SITE_INFORMATION_TYPES

  // for decimal degrees
  centerLatDecimalDeg?: number
  centerLonDecimalDeg?: number

  // for degrees minutes seconds
  centerLonDegrees?: number
  centerLonMinutes?: number
  centerLonSeconds?: number
  centerLonDirection?: LON_DIRECTIONS
  centerLatDegrees?: number
  centerLatMinutes?: number
  centerLatSeconds?: number
  centerLatDirection?: LAT_DIRECTIONS

  literatureMeasurements?: LiteratureMeasurements[]
}

const DEFAULT_LITERATURE_SITE: LiteratureSite = {
  country: '',
  type: SITE_INFORMATION_TYPES.DecimalDegree,

  literatureMeasurements: [DEFAULT_LITERATURE_MEASUREMENTS]
}

type PublicationData = {
  literaturePublication: LiteraturePublication
  literatureSites: LiteratureSite[]
}

const DEFAULT_PUBLICATION_DATA: PublicationData = {
  literaturePublication: DEFAULT_LITERATURE_PUBLICATION,
  literatureSites: [DEFAULT_LITERATURE_SITE],
}

export {
  SOURCE_TYPES,
  LiteraturePublication,
  SITE_INFORMATION_TYPES,
  LON_DIRECTIONS,
  LAT_DIRECTIONS,
  LiteratureSite,
  REFORESTATION_TYPE,
  REFORESTATION_SUB_TYPE,
  TREATMENT,
  LiteratureMeasurements,
  MeasurementByAge,
  DEFAULT_PUBLICATION_DATA,
  PublicationData,
}
