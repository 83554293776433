import { useEffect, useState } from 'react'
import { state, useActiveLandProject } from '~/state'
import { useSnapshot } from 'valtio'
import { Column, Row } from '../../styles/box'
import Avatar from '../atomic/Avatar'
import Select from '../atomic/Select'
import { allPermissionOptions } from '~/utils/constants'

type LandProjectUser = {
  _id: string
  created_at: string
  entityId: string
  entityType: string
  landProjectId: string
  permissions: string[]
  updated_at: string
}

function LandProjectUsersList(props) {
  const {
    landProjectUsers,
    onLandProjectUserPermissionUpdate,
    onLandProjectUserRemoved,
  } = props

  function updateLandProjectUserPermission(landProjectUser, e) {
    e.preventDefault()
    if (e.target.value === 'delete') {
      landProjectUser.permissions = []
      onLandProjectUserRemoved(landProjectUser)
    } else {
      landProjectUser.permissions = [e.target.value]
      onLandProjectUserPermissionUpdate(landProjectUser)
    }
  }

  return (
    <div>
      <div className='mb-16 text-16 font-medium'>People with access:</div>
      <Column className='space-y-16'>
        {landProjectUsers &&
          landProjectUsers
            .filter((users) => users.entityId != 'earthshot.eco') // hide earthshot domain share
            .sort(sortLandProjectUsers)
            .map((landProjectUser) => {
              return (
                <LandProjectUserItem
                  key={landProjectUser._id + landProjectUser.permissions}
                  landProjectUser={landProjectUser}
                  onUpdatePermission={updateLandProjectUserPermission}
                />
              )
            })}
      </Column>
    </div>
  )
}

function sortLandProjectUsers(a: LandProjectUser, b: LandProjectUser) {
  // Sort by created at date
  return new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
}

function LandProjectUserItem(props) {
  const { landProjectUser, onUpdatePermission } = props
  const snap = useSnapshot(state)
  const authedUser = snap.authedUser
  const [mayEditUsers, setMayEditUsers] = useState(false)
  const { landProject } = useActiveLandProject()

  useEffect(() => {
    setMayEditUsers(landProject.permissions?.includes('editUser'))
  }, [landProject])

  const canDelete = landProjectUser.entityId !== authedUser._id && mayEditUsers

  const options = canDelete
    ? [...allPermissionOptions, { label: 'Delete', value: 'delete' }]
    : allPermissionOptions

  function getHighestPermission() {
    const permissionLevelBoard = {
      editUser: 3,
      lead: 3,
      edit: 2,
      proponent: 2,
      view: 1,
      investor: 1,
    }

    let maxRoleValue = -1
    let roleValue
    const permissions = landProjectUser.permissions
    permissions.forEach((permission) => {
      if (permissionLevelBoard[permission] > maxRoleValue) {
        maxRoleValue = permissionLevelBoard[permission]
        roleValue = permission
      }
    })
    return roleValue
  }

  return (
    <Row className='row-vcenter'>
      {landProjectUser.xUser && (
        <AvatarNameRow
          name={`${landProjectUser.xUser.first_name} ${landProjectUser.xUser.last_name}`}
          email={landProjectUser.xUser.email}
        />
      )}

      {landProjectUser.entityType === 'emailDomain' && (
        <AvatarNameRow
          name={`Everyone at`}
          email={landProjectUser.entityId}
          className='rounded-md'
        />
      )}
      {landProjectUser.entityType === 'invitedUser' && (
        <AvatarNameRow
          name={landProjectUser.entityId} // invited user email is in the name field for aesthetics @ Bernat feel free to style this section differently
          email={'Invited'}
          className='rounded-md'
        />
      )}
      <Select
        className='min-w-[116px]'
        options={options}
        defaultValue={getHighestPermission()}
        disabled={!mayEditUsers}
        onChange={(e) => onUpdatePermission(landProjectUser, e)}
      />
    </Row>
  )
}

function AvatarNameRow(props) {
  const { name, className, email } = props
  return (
    <Row className='row-vcenter flex-1 space-x-12'>
      <Avatar name={name} className={className} size={40} />
      <Column>
        <div className=''>{name}</div>
        {email && <div className='opacity-50'>{email}</div>}
      </Column>
    </Row>
  )
}

export { LandProjectUsersList as LandProjectUserList }
