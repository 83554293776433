import { Pressable } from '~/components/atomic/Pressable'
import { Tooltip } from '~/components/atomic/Tooltip'
import { StatusLight } from '~/components/core/StatusLight'
import {
  countryRatingToBadgeVariant,
  CountryRegulatoryAssessment,
} from '~/models/countryRegulation'
import { CountryAssessmentMeta } from '~/routes/projects/components/CountryAssessmentMeta'
import { Row } from '~/styles'

type Props = {
  countryName: string
  countryAssessment: CountryRegulatoryAssessment
  pathToCountryAssessment: string
}

export function CountryCell(props: Props) {
  const { countryName, countryAssessment, pathToCountryAssessment } = props

  const tooltip = (
    <Row className='row-vcenter'>
      <span className='mr-8'>Country Rating:</span>
      <CountryAssessmentMeta
        countryAssessment={countryAssessment}
        showLearnMore={false}
      />
    </Row>
  )

  return (
    <Tooltip side='top' align='start' content={tooltip}>
      <Pressable
        className='h-full whitespace-nowrap'
        to={pathToCountryAssessment}
        openInNewTab
      >
        {/* <StatusLight
          variant={countryRatingToBadgeVariant(countryAssessment?.rating)}
          size={4}
          className={`mr-8`}
        /> */}
        {countryName || '-'}
      </Pressable>
    </Tooltip>
  )
}
