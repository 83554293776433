import { earthshotUser } from '~/data/earthshotUsers'
import { cn } from '~/utils/cn'
import { getUniqueObjectForId } from '~/utils/color'

type Props = {
  handle?: string
  name?: string
  avatar_url?: string
  className?: string
  size?: number
} & React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>

export default function Avatar(props: Props) {
  const { handle, name, avatar_url, size = 32, className, ...rest } = props
  const staticAvatar = earthshotUser.find((i) => name?.toLowerCase().includes(i))
  const imageUrl = avatar_url || staticAvatar
  const color = getUniqueObjectForId(name)

  if (!imageUrl)
    return (
      <div
        className={cn(
          'flex items-center justify-center rounded-full font-medium ring-1 flex-shrink-0 ring-inset ring-[rgba(0,0,0,0.1)]',
          className
        )}
        style={{
          width: size,
          height: size,
          minWidth: size,
          background: color.bg,
          color: color.text,
          fontSize: Math.min(12, size * 1.5),
        }}
      >
        {(name || handle)?.charAt(0).toUpperCase()}
      </div>
    )
  return (
    <img
      alt={name}
      src={
        staticAvatar
          ? require(`./../../assets/images/avatar/${staticAvatar}.jpg`)
          : imageUrl
      }
      style={{ width: size, height: size, minWidth: size }}
      className={cn('rounded-full bg-ink-100  flex-shrink-0', className)}
      referrerPolicy='no-referrer'
      {...rest}
    />
  )
}
