class Logger {
  makeLogFunc = (method, ...args) => {
    if (Function.prototype.bind) {
      return Function.prototype.bind.call(console[method], console)
    } else {
      return Function.prototype.apply.call(console[method], console, args)
    }
  }

  log = this.makeLogFunc('log')
  error = this.makeLogFunc('error')
  warn = this.makeLogFunc('warn')
  info = this.makeLogFunc('info')
}

export const logger = new Logger()
// export const logger = Function.prototype.bind.call(console.info, console)
