import { ES_Form } from '~/models/form'

// Initial Assessment
import basicInfo from './initial_basic-info.json'
import initalQuestionnaire from './initial_questionnaire.json'

// Scoping Assessment
import projectInfo from './gng_project-info.json'
import projectProponent from './gng_project-proponent.json'
import projectArea from './gng_project-area.json'
import projectDesign from './gng_project-design-activities.json'
import regulatoryCompliance from './gng_regulatory-compliance.json'
import communityBiodiversity from './gng_community-biodiversity.json'
import financials from './gng_financials.json'

// Internal
import shortProfile from './short-profile.json'

// Certification
import baseline from './certification_baseline.json'
import leakage from './certification_leakage.json'
import additionality from './certification_additionality.json'
import permanence from './certification_permanence.json'

import dueDiligence from './due_diligence.json'

// Legacy
import projectSummary from './legacy_project-summary.json'
import carbon from './legacy_carbon.json'
import certification from './legacy_certification.json'
import community from './legacy_community.json'
import implementation from './legacy_implementation.json'
import regulatory from './legacy_regulatory.json'
import sdgBenefits from './legacy_sdg-benefits.json'
import scopingEvaluation from './scoping_evaluation.json'

// Assessment v2

export const forms = [
  // Initial Assessment
  basicInfo,
  initalQuestionnaire,

  // Scoping Assessment
  projectInfo,
  projectProponent,
  projectArea,
  financials,
  projectDesign,
  regulatoryCompliance,
  communityBiodiversity,

  // Internal
  shortProfile,

  // Certification
  baseline,
  leakage,
  additionality,
  permanence,

  //Other
  scopingEvaluation,
  dueDiligence,

  // Legacy
  sdgBenefits,
  projectSummary,
  carbon,
  certification,
  community,
  implementation,
  regulatory,
].map((form) => new ES_Form(form as ES_Form))

// Legacy list of engagmeent categories TODO deprecate
export const legacy_AssessmentCategories = [
  { to: 'regulatory', label: 'Regulatory Compliance' },
  { to: 'carbon', label: 'Carbon Outcomes' },
  { to: 'financials', label: 'Financial Viability' },
  { to: 'implementation', label: 'Implementation Viability' },
  { to: 'certification', label: 'Certification Compliance' },
  { to: 'community', label: 'Community Engagement' },
].map((c) => ({ ...c, id: c.to, name: c.to }))

export const allFields = forms.reduce((prev, curr) => {
  return [...prev, ...curr.fields]
}, [])

export function getAllFieldsInForms(forms) {
  return forms.reduce((prev, curr) => {
    return [...prev, ...curr.fields]
  }, [])
}
