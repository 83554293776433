// const startYear = 2023

import { calculateInvestorCashflow } from '~/data/financials/investorCashflows'
import {
  calculateProjectCashflow,
  ProjectCashflow,
} from '~/data/financials/projectCashflows'
import { VCUS } from '~/types/vcus'
import { ProjectType } from '~/models/landProject'
import { FinancialsVariables } from '~/routes/project/financials/financialsStore'

const TARGET_IRR = 0.2
const INITIAL_COSTS = 5_000
const COST_INCREMENT = 1_000
const INITIAL_INVESTOR_SHARE = 0.95
const INVESTOR_SHARE_DECREASE = 0.025
const investmentDistributions = [0.5, 0.5]

type Params = {
  vcus: VCUS
  projectType: ProjectType
  financialVariables: FinancialsVariables
}

export function optimalFinancialModel_experimental(params: Params) {
  const { vcus, financialVariables, projectType } = params
  console.log(`>>>>>>>>>------------------------`)

  let current_irr = Infinity
  let cost = INITIAL_COSTS
  let current_investorShare = INITIAL_INVESTOR_SHARE
  let current_investment = 0
  let curr_projectCashflow = null

  while (current_irr > TARGET_IRR) {
    console.log(`----------`)
    const { irr, cashflowPositive, investment, projectCashflow } = recursive({
      cost,
      vcus,
      projectType,
      financialVariables,
      investorCreditShare: current_investorShare,
    })

    console.log(`IRR`, current_irr)
    console.log(`investorCreditShare`, current_investorShare)
    console.log(`cashflowPositive`, cashflowPositive)
    console.log(`cost`, cost)

    if (cashflowPositive) {
      cost += COST_INCREMENT
      console.log(`incrementing cost`, cost)
    } else {
      current_investorShare -= INVESTOR_SHARE_DECREASE
      console.log(`incrementing investor share`, current_investorShare)
    }

    current_irr = irr
    current_investment = investment
    curr_projectCashflow = projectCashflow
  }

  return {
    irr: current_irr,
    investorCreditShare: current_investorShare,
    investment: current_investment,
    projectCashflow: curr_projectCashflow as ProjectCashflow,
    cost,
  }
}

type RecursiveParams = {
  projectType: ProjectType
  vcus: VCUS
  cost: number
  investorCreditShare: number
  financialVariables: FinancialsVariables
}
function recursive(params: RecursiveParams) {
  const { projectType, vcus, cost, financialVariables, investorCreditShare } = params

  const {
    startYear,
    operationsStartYear,
    landPurchase,
    carbonPriceScenario,
    communityRevenueShare,
    investorExitYear,
    investorCreditSharePostExit,
    brokerageFee,
  } = financialVariables

  const projectCreditShare = 1 - 0.05 - investorCreditShare

  const totalInvestment = cost * 2 * 2

  const investorCashflow = calculateInvestorCashflow({
    startYear,
    projectType,
    investment: totalInvestment,
    investorExitYear,
    investorCreditShare,
    investorCreditSharePostExit,
    vcus,
    brokerageFee,
    investmentDistributions,
    carbonPriceScenario,
    landPurchase,
  })

  const projectCashflow = calculateProjectCashflow({
    operationsStartYear,
    investorExitYear: null,
    startYear,
    investment: totalInvestment,
    cost,
    projectCreditShare,
    projectCreditSharePostExit: null,
    vcus,
    investmentDistributions,
    carbonPriceScenario,
    communityRevenueShare,
    projectType,
  })

  return {
    irr: investorCashflow.irr,
    investment: totalInvestment,
    cashflowPositive: projectCashflow.cashflowPositive,
    projectCashflow,
    investorCashflow,
  }
}
