import * as PopoverPrimitive from '@radix-ui/react-popover'
import { PopoverContentProps } from '@radix-ui/react-popover'
import React from 'react'
import { Pressable } from '~/components/atomic/Pressable'
import { menuContainer } from '~/components/atomic/styles'
import classed from '~/styles/classed'

export type Props = {
  content: any
  open?: boolean
  asChild?: boolean
  onOpenChange?: (open: boolean) => void
} & Omit<PopoverContentProps, 'content'> &
  React.RefAttributes<HTMLDivElement>
export default function Popover(props: Props) {
  const { content, children, open, asChild = true, onOpenChange, ...rest } = props
  return (
    <PopoverPrimitive.Root open={open} onOpenChange={onOpenChange}>
      <PopoverPrimitive.Trigger asChild={asChild}>{children}</PopoverPrimitive.Trigger>
      <PopoverPrimitive.Portal>
        <PopoverPrimitive.Content sideOffset={2} className='z-100 outline-none' {...rest}>
          {content}
        </PopoverPrimitive.Content>
      </PopoverPrimitive.Portal>
    </PopoverPrimitive.Root>
  )
}

// TODO deprecate the idea of popover having menus, A context menu should be used for htat.
// TODO deprecate using "menu container" here. Maybe dialog container, or something like that

const PopoverMenu = classed('div', `flex flex-col ${menuContainer} divide-y`)
const PopoverContainer = classed(
  'div',
  `flex flex-col bg-white p-12 rounded-md shadow-lg border-[0.5px] border-ink-200`
)
const PopoverDivider = classed('div', 'h-1 bg-ink-200 my-8 -mx-20')

const PopoverMenuItem = classed(Pressable, '!mx-0 !px-[14px] !py-8 flex-1 rounded-none')

Popover.Menu = PopoverMenu
Popover.Container = PopoverContainer
Popover.Divider = PopoverDivider
Popover.MenuItem = PopoverMenuItem
Popover.Close = PopoverPrimitive.Close
