import { Row } from '~/styles'

type Props = {
  value: number | string
  label?: string
  unit?: string
}

export function HorizontalStat(props: Props) {
  const { label, value, unit, ...rest } = props
  return (
    <div className='grid grid-cols-[1.2fr_1fr] gap-12' {...rest}>
      {label && <div className='text-14 font-medium'>{label}</div>}
      <Row className='text-14'>
        <div className='weight-semibold'>{value}</div>
        {unit && <div className='ml-8 opacity-50'>{unit}</div>}
      </Row>
    </div>
  )
}
