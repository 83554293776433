import { Layer } from 'react-map-gl'

type Props = {}

export function CountryLabelsLayer(props: Props) {
  const {} = props
  const layerProps: any = {
    id: 'country-label',
    type: 'symbol',
    source: 'composite',
    'source-layer': 'place_label',
    minzoom: 1,
    maxzoom: 10,
    filter: [
      'match',
      ['get', 'class'],
      'country',
      ['match', ['get', 'worldview'], ['all', 'US'], true, false],
      'disputed_country',
      [
        'all',
        ['==', ['get', 'disputed'], 'true'],
        ['match', ['get', 'worldview'], ['all', 'US'], true, false],
      ],
      false,
    ],
    layout: {
      'icon-image': '',
      'text-field': ['coalesce', ['get', 'name_en'], ['get', 'name']],
      'text-line-height': 1.1,
      'text-max-width': 6,
      'text-font': ['DIN Pro Medium', 'Arial Unicode MS Regular'],
      'text-radial-offset': ['step', ['zoom'], 0.6, 8, 0],
      'text-size': [
        'interpolate',
        ['cubic-bezier', 0.2, 0, 0.7, 1],
        ['zoom'],
        1,
        ['step', ['get', 'symbolrank'], 11, 4, 9, 5, 8],
        9,
        ['step', ['get', 'symbolrank'], 22, 4, 19, 5, 17],
      ],
    },
    paint: {
      'icon-opacity': ['step', ['zoom'], ['case', ['has', 'text_anchor'], 1, 0], 7, 0],
      'text-halo-color': [
        'interpolate',
        ['linear'],
        ['zoom'],
        2,
        'hsla(40, 53%, 100%, 0.75)',
        3,
        'hsl(40, 53%, 100%)',
      ],
      'text-halo-width': 1,
      'text-color': 'hsl(40, 34%, 19%)',
    },
  }
  return <Layer {...layerProps} />
}
