import { landProjectStatToProjection } from '~/models/projection'
import {
  calculateProjectCashflows,
  ProjectCashflows,
} from '~/data/financials/projectCashflows'
import { proxy, useSnapshot } from 'valtio'
import { CarbonPriceScenarios } from '~/data/financials/carbonPricePrediction'
import {
  calculateInvestorCashflow,
  calculateInvestorCashflows,
  InvestorCashflow,
} from '~/data/financials/investorCashflows'
import { calculateLandProjectVCUS, getDefaultVCUSRates } from '~/data/financials/vcus'
import { VCUS, VCUSRates } from '~/types/vcus'

import { fillArray, getSteppedRange, simpleClone, sumArray } from '~/utils'
import { useEffect } from 'react'
import { state, useActiveLandProject } from '~/state'
import { saveProjectData } from '~/services/db'
import { NumericValueType } from '~/types'
import { getREDDGreenHouseGasBaseline } from '~/models/landProjectReddStats'
import {
  calculateEarthshotCashflow,
  EarthshotCashflow,
} from '~/data/financials/earthshotCashflows'
import {
  calculateProponentCashflow,
  ProponentCashflow,
} from '~/data/financials/proponentCashflows'
import { LandProject, ProjectType } from '~/models/landProject'
import { getLandProjectArea } from '~/models/landProject'
import { DEFAULT_PROJET_PERIOD } from '~/utils/constants'

// TODO: Clean-Up
// This factor front-loads the cost calculation
// TODO allow users to adjust the yearly costs
export const adjustedCostFactors = [
  1.772525849, 1.550960118, 1.373707533, 1.285081241, 1.196454948, 1.107828656,
  1.06351551, 1.019202363, 0.9748892171, 0.9305760709, 0.8862629247, 0.8862629247,
  0.8862629247, 0.8862629247, 0.8862629247, 0.8862629247, 0.8862629247, 0.8862629247,
  0.8862629247, 0.8862629247, 0.8862629247, 0.8862629247, 0.8862629247, 0.8862629247,
  0.8862629247, 0.8862629247, 0.8862629247, 0.8862629247, 0.8862629247, 0.8862629247,
]

// TODO these are placeholders we'll use "real fixed" costs later that are saved into the project
export const placeholderFixedCosts = [
  3471833.178, 3970973.178, 4470113.178, 4969253.178, 5468393.178, 2659380, 2476780,
  2294180, 2111580, 1928980, 1586660, 1384260, 1181860, 979460, 777060, 777060, 777060,
  777060, 777060, 777060, 777060, 777060, 777060, 777060, 777060, 777060, 777060, 777060,
  777060, 777060,
]

const EXPLORER_STEPS = 10

export type FinancialsStore = {
  state: 'loading' | 'needs-carbon-estimates' | 'ready'
  variables?: FinancialsVariables
  vcus?: VCUS
  investorCashflows?: InvestorCashflow[]
  projectCashflows?: ProjectCashflows
  investmentAmounts?: number[]

  // Fixed
  fixedInvestorCashflow: InvestorCashflow
  fixedEarthshotCashflow: EarthshotCashflow
  fixedProponentCashflow: ProponentCashflow
}

export type FinancialsVariables = VCUSRates & {
  // Carbon
  carbonEstimates: number[]
  carbonPriceScenario: CarbonPriceScenarios

  // Playground Inputs
  // Project Config
  withVintages: boolean
  startYear: number
  operationsStartYear: number
  brokerageFee: number
  taxRate: number

  projectCreditShare: number
  investorCreditShare: number
  earthshotCreditShare: number

  investorCreditSharePostExit: number
  projectCreditSharePostExit: number
  earthshotCreditSharePostExit: number

  communityRevenueShare: number
  projectRevenueShare: number

  // Investment
  investorExitYear: number
  investmentRange: [number, number]
  investmentDistributions: number[]
  landPurchase: number

  // Costs
  costsRange: [number, number]

  fixedCosts: number[]
  fixedInvestment: number
}

function getDefaultVariables(
  projectType: ProjectType,
  startYear: number
): FinancialsVariables {
  return {
    carbonEstimates: null,
    carbonPriceScenario: 'medium' as CarbonPriceScenarios,

    // VCUs
    ...getDefaultVCUSRates(projectType),

    // Project Config
    withVintages: false,
    startYear,
    operationsStartYear: startYear,
    brokerageFee: 0.05,
    taxRate: 0.05,

    investorCreditShare: 0.4,
    projectCreditShare: 0.55,
    earthshotCreditShare: 0.05,

    investorCreditSharePostExit: 0,
    projectCreditSharePostExit: 0.95,
    earthshotCreditSharePostExit: 0.05,

    communityRevenueShare: 0,
    projectRevenueShare: 1,

    // Investment
    investorExitYear: null,
    investmentRange: [1.0, 2.2],
    investmentDistributions: [0.5, 0.5, 0, 0, 0],
    landPurchase: 0,

    //Costs
    costsRange: [100, 200],

    fixedCosts: placeholderFixedCosts,
    fixedInvestment: 27_000_000,
  }
}

export const financialsStore = proxy<FinancialsStore>({
  state: 'loading',

  variables: null,
  vcus: [],
  investorCashflows: [],
  projectCashflows: [],
  investmentAmounts: [],
  fixedInvestorCashflow: null,
  fixedEarthshotCashflow: null,
  fixedProponentCashflow: null,
})

export function useFinancialsStore() {
  const snap = useSnapshot(financialsStore)
  return snap as FinancialsStore
}

export function getProjectFinancialVariables(landProject: LandProject) {
  const { projectType, startYear } = landProject
  const financialVariables: FinancialsVariables = {
    ...getDefaultVariables(projectType, startYear),
    ...simpleClone(landProject?.financials),
  }
  return financialVariables
}

export function useInitFinancialStore() {
  const { landProject } = useActiveLandProject()

  useEffect(() => {
    const financialVariables = getProjectFinancialVariables(landProject)
    const hasManualCarbonEstimates =
      !!financialVariables.carbonEstimates &&
      sumArray(financialVariables.carbonEstimates) > 0
    const hasAutomaticCarbonEstimates = !!landProject.xStats

    // TODO: Clean-up manual vrs automatic estimates implementation
    if (hasManualCarbonEstimates || hasAutomaticCarbonEstimates) {
      if (hasAutomaticCarbonEstimates && landProject.projectType === 'arr')
        initialARRFinancialComputation(financialVariables, landProject)
      if (hasAutomaticCarbonEstimates && landProject.projectType === 'redd')
        initialREDDFinancialComputation(financialVariables, landProject)

      financialsStore.state = 'ready'
      compute(financialVariables, landProject)
    } else {
      financialsStore.state = 'needs-carbon-estimates'
    }

    financialsStore.variables = financialVariables
  }, [landProject])
}

// TODO: Clean-up manual vrs automatic estimates implementation
export function getProjectCarbonEstimates(landProject: LandProject) {
  if (landProject.projectType === 'arr') {
    // computedProjection -> applies intervention schedule areas
    const computedProjection = landProjectStatToProjection(
      landProject.xStats,
      landProject
    )
    return (
      computedProjection?.byYear.slice(0, DEFAULT_PROJET_PERIOD) ||
      fillArray(DEFAULT_PROJET_PERIOD, (i) => 0)
    )
  } else if (landProject.projectType === 'redd') {
    return (
      financialsStore?.variables?.carbonEstimates ||
      getREDDGreenHouseGasBaseline(landProject)
    )
  }
}

function initialARRFinancialComputation(financialVariables, landProject) {
  if (!financialVariables?.carbonEstimates?.length) {
    financialVariables.carbonEstimates = landProject.xStats.byYear
      .map((d) => d.tCO2)
      .slice(0, DEFAULT_PROJET_PERIOD)
  }
  const projectArea = getLandProjectArea(landProject)
  if (projectArea) {
    const costsRange = getCostsRangeBasedOnForestHa(projectArea)
    financialVariables.costsRange = costsRange || [100, 500]
  }
}

function initialREDDFinancialComputation(financialVariables, landProject) {
  if (!financialVariables?.carbonEstimates?.length) {
    // If we have xStats AND we haven't set baselineValues yet
    const initialBaselineGHGEmissions = getREDDGreenHouseGasBaseline(landProject)
    financialVariables.carbonEstimates = initialBaselineGHGEmissions
  }

  if (!financialVariables.costsRange.length) {
    const forestHA = landProject.xStats?.forestNonForest?.forestHa
    if (forestHA) {
      const costsRange = getCostsRangeBasedOnForestHa(forestHA)
      financialVariables.costsRange = costsRange || [100, 500]
    }
  }
}

export function useRecomputeFinancials() {
  const { landProject } = useActiveLandProject()
  const { state, variables } = useFinancialsStore()

  useEffect(() => {
    if (state !== 'ready' || !landProject) return
    compute(variables, landProject)
  }, [variables, landProject, state])
}

function compute(variables: FinancialsVariables, landProject: LandProject) {
  const { projectType } = landProject

  const {
    startYear,
    carbonPriceScenario,
    investorCreditSharePostExit,
    projectCreditSharePostExit,
    investmentRange,
    costsRange,
    investorCreditShare,
    investmentDistributions,
    projectCreditShare,
    earthshotCreditShare,
    brokerageFee,
    landPurchase,
    investorExitYear,
    operationsStartYear,
    communityRevenueShare,
    fixedCosts,
    fixedInvestment,
    taxRate,
  } = variables

  let vcus = calculateLandProjectVCUS(landProject)

  const investmentAmounts = getSteppedRange(
    Number(investmentRange[0]) * 1_000_000, // We display values as units of millions
    Number(investmentRange[1]) * 1_000_000,
    EXPLORER_STEPS
  )

  const costAmounts = getSteppedRange(
    Number(costsRange[0]) * 1_000, // We display values as units of thousands
    Number(costsRange[1]) * 1_000,
    EXPLORER_STEPS
  )

  // Cashflows
  const investorCashflows = calculateInvestorCashflows({
    projectType,
    investorExitYear,
    investorCreditSharePostExit,
    startYear,
    carbonPriceScenario,
    investorCreditShare,
    investmentDistributions,
    brokerageFee,
    landPurchase,
    vcus,
    investmentAmounts,
  })

  const projectCashflows = calculateProjectCashflows({
    projectType,
    operationsStartYear,
    investorExitYear,
    startYear,
    carbonPriceScenario,
    investmentDistributions,
    projectCreditShare,
    projectCreditSharePostExit,
    communityRevenueShare,
    vcus,
    investmentAmounts,
    costAmounts,
  })

  // Fixed calculations
  const fixedInvestorCashflow = calculateInvestorCashflow({
    projectType,
    investorExitYear,
    investorCreditSharePostExit,
    startYear,
    carbonPriceScenario,
    investorCreditShare,
    investmentDistributions,
    brokerageFee,
    landPurchase,
    vcus,
    investment: fixedInvestment,
  })

  const fixedEarthshotCashflow = calculateEarthshotCashflow({
    projectType,
    startYear,
    carbonPriceScenario,
    earthshotCreditShare,
    investmentDistributions,
    brokerageFee,
    vcus,
    investment: fixedInvestment,
  })

  const fixedProponentCashflow = calculateProponentCashflow({
    projectType,
    startYear,
    vcus,
    annualCosts: fixedCosts,
    carbonPriceScenario,
    investment: fixedInvestment,
    investmentDistributions,
    proponentCreditSharePercent: projectCreditShare, // TODO refactor to factor with "percent"
    communityRevenueSharePercent: communityRevenueShare, // TODO refactor to factor with "percent"
    inflationPercent: 0,
    taxRate,
  })

  financialsStore.vcus = vcus
  financialsStore.investmentAmounts = investmentAmounts
  financialsStore.fixedInvestorCashflow = fixedInvestorCashflow
  financialsStore.fixedEarthshotCashflow = fixedEarthshotCashflow
  financialsStore.fixedProponentCashflow = fixedProponentCashflow

  financialsStore.investorCashflows = investorCashflows
  financialsStore.projectCashflows = projectCashflows
}

export async function saveFinancialEstimates() {
  const data = simpleClone(financialsStore.variables)
  await saveProjectData({ financials: data }, state.activeLandProjectId)
}

const COST_PER_HA_BENCHMARK = 116 // dollar per ha based on Cambodia, very rough benchmark

function getCostsRangeBasedOnForestHa(forestHA: number) {
  const costBenchmark = (forestHA * COST_PER_HA_BENCHMARK) / 30

  return [costBenchmark * 0.5, costBenchmark * 2].map((i) => Math.round(i / 1000)) as [
    number,
    number
  ]
}

export function updateStoreVariable(
  value,
  inputName: Extract<keyof FinancialsVariables, string>
) {
  financialsStore.variables[inputName as string] = value
  console.log('REDDFinancialsStore.variables: ', financialsStore.variables)
}

export function getFiancialValueType(name: string): NumericValueType {
  //prettier-ignore
  switch (name) {
    case 'calendarYear': return 'unformatted'
    case 'carbonPrice': return 'currency'
    case 'revenue': return 'currency'
    case 'cost': return 'currency'
    case 'balance': return 'currency'
    case 'investmentDistribution': return 'currency'
    case 'communityShare': return 'currency'
    case 'creditShare': return 'percent'
    case 'outcome': return 'currency'
    case 'soyBalance': return 'currency'
    case 'earningsBeforeTax': return 'currency'
    case 'eoyBalance': return 'currency'
    default: return 'number'
  }
}
