import { HorizontalStats, StatValues } from '~/components/core/Stat'
import { SmallP, Title } from '~/components/profile/shared'
import { Column } from '~/styles'

type Props = {
  title: string
  stats: StatValues[]
  description?: string
  className?: string
}

export function StatsSection(props: Props) {
  const { title, stats, description, className = '' } = props

  return (
    <Column className={className}>
      <Title>{title}</Title>

      <HorizontalStats stats={stats} />
      {description && <SmallP className='mt-20'>{description}</SmallP>}
    </Column>
  )
}
