import { landProjectStatToProjection } from '~/models/projection'
import { projectionToGraphSerie } from '~/routes/project/carbon/arr/ARRCarbonView'
import { MultiProjectionsGraph } from '~/routes/project/carbon/arr/components/MultiProjectionsGraph'
import { useActiveLandProject } from '~/state'

type Props = {
  hideBoundsCheckbox?: boolean
  showBounds?: boolean
  className?: string
}

export function ProjectCarbonProjection(props: Props) {
  const { className, hideBoundsCheckbox, showBounds } = props
  const { landProject } = useActiveLandProject()

  const chosenProjection = landProjectStatToProjection(
    {
      ...landProject.xStats,
      snapshotMainInterventionKey: landProject.chosenModelKey,
    },
    landProject
  )

  const serie = projectionToGraphSerie(chosenProjection)
  serie.color = '#419d84'

  return (
    <MultiProjectionsGraph
      series={[serie]}
      hideBoundsCheckbox={hideBoundsCheckbox}
      showBounds={showBounds}
      className={className}
    />
  )
}
