import { Layer } from 'react-map-gl'

type Props = {
  fill?: string
}

export function BackgroundLayer(props: Props) {
  const { fill = '#FDF9F4' } = props

  return <Layer id='background' type='background' paint={{ 'background-color': fill }} />
}
