import React, { useEffect } from 'react'
import { createRoutesFromElements, Navigate, Route, useNavigate } from 'react-router-dom'
import { EmailVerify } from '~/components/auth/EmailVerify'
import { ForgotPassword } from '~/components/auth/ForgotPassword'
import { LogIn } from '~/components/auth/LogIn'
import { LogOut } from '~/components/auth/LogOut'
import { PasswordReset } from '~/components/auth/PasswordReset'
import { SignUp } from '~/components/auth/SignUp'
import { DefaultErrorElement } from '~/components/DefaultErrorElement'
import { FirebaseBiome } from '~/components/firebaseBiome/FirebaseBiome'
import { ParcelAnalyze } from '~/components/parcelAnalyze/ParcelAnalyze'
import { ScienceDatabase, ScienceDatabaseEntry } from '~/components/scienceDatabase'
import { CountryRegulationMap } from '~/routes/admin/formBuilder/CountryRegulationMap'
import { FormBuilderPage } from '~/routes/admin/formBuilder/FormBuilderPage'
import { PolygonsToolPage } from '~/routes/admin/formBuilder/polygons/PolygonsToolPage'
import { UsersAdmin } from '~/routes/admin/UsersAdmin'
import { PortfolioPage } from '~/routes/portfolio/PortfolioPage'
import { ShortProfile } from '~/routes/portfolio/ShortProfile'
import { AssessmentsTabLayout } from '~/routes/project/assessments/AssessmentsTabLayout'
import { ScopingAssessment } from '~/routes/project/assessments/ScopingAssessment'
import { CarbonTab } from '~/routes/project/carbon/CarbonTab'
import { DataEngagementView } from '~/routes/project/data/DataEngagementView'
import { DataShortProfile } from '~/routes/project/data/DataShortProfile'
import { DataViewLayout } from '~/routes/project/data/DataViewLayout'
import { FinancialsTabLayout } from '~/routes/project/financials/layout'
import { CarbonPriceScenariosSection } from '~/routes/project/financials/sections/CarbonPriceScenariosSection'
import { FinancialsExplorer } from '~/routes/project/financials/sections/FinancialsExplorer'
import { FixedCosts } from '~/routes/project/financials/sections/FixedCosts'
import { InvestorCashflows } from '~/routes/project/financials/sections/InvestorCashflows'
import { ProjectCashflows } from '~/routes/project/financials/sections/ProjectCashflows'
import { StakeHolderReturns } from '~/routes/project/financials/sections/StakeHolderReturns'
import { ProjectIntakeLayout } from '~/routes/project/intake/ProjectIntakeLayout'
import { LandTabLayout } from '~/routes/project/land/LandTabLayout'
import { ParcelView } from '~/routes/project/land/parcel/ParcelView'
import { AreaSection } from '~/routes/project/land/sections/AreaSection'
import { CountryRegulationSection } from '~/routes/project/land/sections/countryRegulation/CountryRegulationSection'
import { LandMapSection } from '~/routes/project/land/sections/LandMapSection'
import { ProjectPage } from '~/routes/project/ProjectPage'
import { CarbonReport } from '~/routes/project/reports/CarbonReport'
import { SummarySection } from '~/routes/project/summary/SummarySection'
import { DisccountsQuestionnaires } from '~/routes/project/vcus/sections/DisccountsQuestionnaires'
import { PermanenceAssessment } from '~/routes/project/vcus/sections/PermanenceAssessment'
import { VCUsCalculations } from '~/routes/project/vcus/sections/VCUsCalculations'
import { VCUsInputs } from '~/routes/project/vcus/sections/VCUsInputs'
import { getProjectsRedirectPath } from '~/routes/projects/projectsFilters'
import { ProjectsLayout } from '~/routes/projects/ProjectsLayout'
import { ProjectsNewPage } from '~/routes/projects/ProjectsNewPage'
import { ProjectsPage } from '~/routes/projects/ProjectsPage'
import { ProjectsStats } from '~/routes/projects/ProjectsStats'
import { ProjectsSummaries } from '~/routes/projects/ProjectsSummaries'
import { Root } from '~/routes/Root'
import { Workbench } from '~/routes/Workbench'
import { useAuthedUser } from '~/state/auth'
import { PATH } from '~/utils/constants'
import { TreesMapSection } from './components/firebaseBiome/sections/TreesMapSection'
import { DataPhotoView } from './routes/project/data/DataPhotoView'
import { EarthshotCashflows } from './routes/project/financials/sections/EarthshotCashflows'
import { ProjectIntakeView } from './routes/project/intake/ProjectIntakeView'
import { BiodiversitySection } from './routes/project/land/sections/BiodiversitySection'
import { ParcelsSection } from './routes/project/land/sections/ParcelsSection'
import { ArrModelsComparisonPage } from '~/routes/admin/ArrModelsComparisonPage'

import { E2EUser } from '~/components/e2e/E2EUser'
import { OptimalFinancialModel } from '~/routes/project/financials/OptimalFinancialModel'
import { VCUsTabLayout } from '~/routes/project/vcus/VCUsTabLayout'
import { BaselineSection } from '~/routes/project/land/sections/baseline/BaselineSection'
import { DataScopingRecommendations } from '~/routes/project/data/DataScopingRecommendations'
import { ReforestationEligibilitySection } from '~/routes/project/land/sections/reforestationEligibility/ReforestationEligibilitySection'
import { IssuedVCUsSection } from '~/routes/project/vcus/sections/IssuedVCUsSection'

export const routes = createRoutesFromElements(
  <>
    <Route path={PATH['/']} element={<Root />} errorElement={<DefaultErrorElement />}>
      <Route index element={<Navigate to={PATH['/projects']} />} />

      <Route path={PATH['/projects']} element={<ProjectsLayout />}>
        <Route index element={<ProjectsRedirect />} />

        <Route path={PATH['stats']} element={<ProjectsStats />} />
        <Route path={PATH['list']} element={<ProjectsPage />} />
        <Route path={PATH['summaries']} element={<ProjectsSummaries />} />
        <Route path={PATH['new']} element={<ProjectsNewPage />} />
      </Route>

      <Route path={PATH['/project']} element={<ProjectPage />}>
        <Route path={PATH[':project_slug']}>
          <Route index element={<ProjectRedirect />} />

          <Route path={PATH['summary']} element={<SummarySection />} />

          {/* 1. Initial Information */}
          <Route
            path={PATH['basic-info']}
            element={<DataEngagementView formId='basic-info' />}
          />
          <Route
            path={PATH['initial-questionnaire']}
            element={<DataEngagementView formId='initial-questionnaire' />}
          />
          <Route path={PATH['project-boundries']} element={<LandMapSection />} />
          <Route
            path={PATH['country-regulation']}
            element={<CountryRegulationSection />}
          />

          {/* 2. Scoping */}

          <Route path={PATH['scoping-assessment']} element={<ScopingAssessment />} />

          {/* 3. Pre-diligence */}

          <Route path={PATH['vcus']} element={<VCUsTabLayout />}>
            <Route index element={<Navigate to={PATH['vcus-calculations']} />} />

            <Route path={PATH['vcus-calculations']} element={<VCUsCalculations />} />

            <Route path={PATH['inputs']} element={<VCUsInputs />} />
            <Route path={PATH['issued']} element={<IssuedVCUsSection />} />

            <Route
              path={PATH['permanence-assessment']}
              element={<PermanenceAssessment />}
            />
            <Route path={PATH[':formId']} element={<DisccountsQuestionnaires />} />
          </Route>

          <Route path={PATH['photos']} element={<DataPhotoView />} />
          <Route path={PATH['short-profile']} element={<DataShortProfile />} />

          {/* Other */}

          <Route path={PATH['land']} element={<LandTabLayout />}>
            <Route index element={<Navigate to={PATH['map']} replace />} />

            <Route path={PATH['map']} element={<LandMapSection />} />
            <Route path={PATH['area']} element={<AreaSection />} />
            <Route path={PATH['baseline']} element={<BaselineSection />} />
            <Route
              path={PATH['reforestation-eligibility']}
              element={<ReforestationEligibilitySection />}
            />
            <Route
              path={PATH['country-regulation']}
              element={<CountryRegulationSection />}
            />

            <Route path={PATH['trees']} element={<TreesMapSection />} />
            <Route path={PATH['biodiversity']} element={<BiodiversitySection />} />
            <Route path={PATH['parcels']} element={<ParcelsSection />} />
            <Route
              path={PATH['parcels'] + '/' + PATH[':parcel_id']}
              element={<ParcelView />}
            />
          </Route>

          <Route path={PATH['financials']} element={<FinancialsTabLayout />}>
            <Route index element={<Navigate to={PATH['explorer']} />} />
            <Route path={PATH['explorer']} element={<FinancialsExplorer />} />
            <Route path={PATH['earthshot-cashflows']} element={<EarthshotCashflows />} />
            <Route
              path={PATH['carbon-price-scenarios']}
              element={<CarbonPriceScenariosSection />}
            />
            <Route path={PATH['project-cashflows']} element={<ProjectCashflows />} />
            <Route path={PATH['investor-cashflows']} element={<InvestorCashflows />} />
            <Route path={PATH['costs']} element={<FixedCosts />} />
            <Route path={PATH['stakeholder-returns']} element={<StakeHolderReturns />} />
            <Route
              path={PATH['optimal-financial-model']}
              element={<OptimalFinancialModel />}
            />
          </Route>

          <Route path={PATH['assessments']} element={<AssessmentsTabLayout />}>
            <Route index element={<Navigate to={PATH['project-info']} replace />} />
            <Route path={PATH[':formId']} element={<DataEngagementView />} />

            <Route path={PATH['scoping-assessment']} element={<ScopingAssessment />} />
          </Route>

          <Route path={PATH['data']} element={<DataViewLayout />}>
            <Route index element={<Navigate to={PATH['project-info']} />} />
            <Route path={PATH[':formId']} element={<DataEngagementView />} />
            <Route path={PATH['photos']} element={<DataPhotoView />} />
            <Route
              path={PATH['recommendations']}
              element={<DataScopingRecommendations />}
            />
            <Route path={PATH['short-profile']} element={<DataShortProfile />} />
          </Route>

          <Route path={PATH['intake']} element={<ProjectIntakeLayout />}>
            <Route index element={<Navigate to={PATH['project-info']} replace />} />
            <Route path={PATH[':formId']} element={<ProjectIntakeView />} />
            <Route path={PATH['photos']} element={<DataPhotoView />} />
            <Route path={PATH['polygons']} element={<LandMapSection />} />
          </Route>

          <Route path={PATH['carbon']} element={<CarbonTab />} />
        </Route>
      </Route>

      <Route path={PATH['/portfolio']}>
        <Route index element={<PortfolioPage />} />
        <Route path={PATH[':project_slug']}>
          <Route index element={<ShortProfile />} />
        </Route>
      </Route>

      <Route path={PATH['/logout']} element={<LogOut />} />

      <Route path={PATH['/tools']}>
        <Route path={PATH['form-builder']}>
          <Route index element={<Navigate to={PATH['project-info']} replace />} />
          <Route path={PATH[':formId']} element={<FormBuilderPage />} />
        </Route>
        <Route path={PATH['polygons-tool']} element={<PolygonsToolPage />} />
        <Route path={PATH['country-regulation-map']} element={<CountryRegulationMap />} />
        <Route path={PATH['users']} element={<UsersAdmin />} />
        <Route
          path={PATH['arr-scoping-comparison']}
          element={<ArrModelsComparisonPage />}
        />
      </Route>

      <Route path={PATH['/science-database']}>
        <Route index element={<ScienceDatabase />} />
        <Route path={PATH['/science-database/publication']}>
          <Route index element={<ScienceDatabaseEntry />} />
          <Route
            path={PATH['/science-database/publication/:id']}
            element={<ScienceDatabaseEntry />}
          />
        </Route>
      </Route>
    </Route>

    <Route path={PATH['/project/:project_slug/reports']}>
      <Route path={PATH['carbon']} element={<CarbonReport />} />
    </Route>

    <Route path={PATH['/signup']} element={<SignUp />} />
    <Route path={PATH['/login']} element={<LogIn />} />
    <Route path={PATH['/email-verify']} element={<EmailVerify />} />
    <Route path={PATH['/forgot-password']} element={<ForgotPassword />} />
    <Route path={PATH['/password-reset']} element={<PasswordReset />} />

    <Route path={PATH['/workbench']} element={<Workbench />} />

    <Route path={PATH['/parcelize']} element={<ParcelAnalyze />} />
    <Route path={PATH['/biome'] + '/*'} element={<FirebaseBiome />} />

    <Route path={'/e2e-user'} element={<E2EUser />} />

    <Route path='*' element={<Navigate to={PATH['/']} replace={false} />} />
  </>
)
function ProjectRedirect() {
  const authedUser = useAuthedUser()
  const navigate = useNavigate()

  useEffect(() => {
    if (authedUser.isAdmin) {
      navigate(PATH['summary'], { replace: true })
    } else {
      navigate(PATH['intake'], { replace: true })
    }
  }, [authedUser])

  return null
}

function ProjectsRedirect() {
  const authedUser = useAuthedUser()
  const navigate = useNavigate()

  useEffect(() => {
    if (authedUser.isAdmin) {
      navigate(PATH['list'] + getProjectsRedirectPath())
    } else {
      navigate(PATH['summaries'])
    }
  }, [authedUser])

  return null
}
