import Select from '~/components/atomic/Select'
import { getCountriesOptions } from '~/services/countries'

export default function SelectCountry(props) {
  return (
    <Select
      options={getCountriesOptions()}
      placeholder='Choose a country...'
      {...props}
    />
  )
}
