import { useEffect, useState } from 'react'
import { CenteredLoader } from '~/components/atomic/CenteredLoader'
import { Pressable } from '~/components/atomic/Pressable'
import { fetchDB } from '~/services/db'
import { logger } from '~/services/logger'
import { useActiveLandProject } from '~/state'

import { Column, Row } from '~/styles'
import { Content, Section } from '~/styles/main'
import { groupArrayOfObjects } from '~/utils'

type EndangeredSpeciesResponse = {
  speciesCount: number
  speciesList: SpeciesList
}

type SpeciesList = EndengeredSpecies[]

type IUCNCatgoryLabelKey = typeof IUCNCatgoryLabels

type EndengeredSpecies = {
  binomial: string
  taxonomyGroup: string
  IUCNCatgeory: IUCNCatgoryLabelKey
}

const IUCNCatgoryLabels = {
  EX: 'Extinct',
  WE: 'Extinct in the wild',
  CR: 'Critically endangered',
  EN: 'Endangered',
  VU: 'Vulnerable',
  NT: 'Near threatened',
  LC: 'Least concern',
  DD: 'Data deficient',
  NE: 'Not evaluated',
}

export function BiodiversitySection() {
  const [endangeredSpecies, setEndangeredSpecies] = useState<SpeciesList>(null)
  const [selectedSpeciesBinomial, setSelectedSpeciesBinomial] = useState<string>(null)
  const [speciesInformation, setSpeciesInformation] = useState<WikiMeta>(null)

  const { landProject } = useActiveLandProject()

  useEffect(() => {
    async function get() {
      const res = await fetchDB<EndangeredSpeciesResponse>('getEndangeredSpecies', {
        landProjectId: landProject._id,
      })
      logger.info('getEndangeredSpecies', res)
      const { valid, speciesList = [] } = res

      setEndangeredSpecies(speciesList)

      if (speciesList?.[0]) {
        await fetchAndSetSpeciesMeta(speciesList[0].binomial)
      }
    }

    get()
  }, [])

  async function fetchAndSetSpeciesMeta(binomial: string) {
    setSelectedSpeciesBinomial(binomial)
    setSpeciesInformation(null)
    const wikiMeta = await fetchWikipedia(binomial)
    setSpeciesInformation(wikiMeta)
  }

  if (!endangeredSpecies) return <CenteredLoader />

  if (endangeredSpecies.length == 0) return <Content>No species found</Content>

  return (
    <Section title='Biodiversity'>
      <Row className='space-x-80'>
        <Column className='min-w-[180px] space-y-24'>
          <EndangeredSpeciesGroups
            endangeredSpecies={endangeredSpecies}
            selectedSpeciesBinomial={selectedSpeciesBinomial}
            onClickSpecies={(binomial) => fetchAndSetSpeciesMeta(binomial)}
          />
        </Column>

        {speciesInformation ? (
          <SpeciesMeta speciesMeta={speciesInformation} />
        ) : (
          <CenteredLoader />
        )}
      </Row>
    </Section>
  )
}

type EndangeredSpeciesGroupsProps = {
  endangeredSpecies: EndengeredSpecies[]
  onClickSpecies: (binomial: string) => void
  selectedSpeciesBinomial: string
}

function EndangeredSpeciesGroups(props: EndangeredSpeciesGroupsProps) {
  const { endangeredSpecies, onClickSpecies, selectedSpeciesBinomial } = props
  const groupedList = groupArrayOfObjects(endangeredSpecies, 'IUCNCatgeory')

  const groups = Object.keys(groupedList).map((IUCNCatgory) => {
    const speciesList = groupedList[IUCNCatgory]
    return (
      <Column key={IUCNCatgory}>
        <div className='text-16 font-bold'>{IUCNCatgoryLabels[IUCNCatgory]}</div>

        {speciesList.map((es: EndengeredSpecies) => (
          <EndengeredSpeciesItem
            onClick={() => onClickSpecies(es.binomial)}
            endengeredSpecies={es}
            isActive={es.binomial === selectedSpeciesBinomial}
            key={es.binomial}
          />
        ))}
      </Column>
    )
  })

  return <Column className='min-w-[180px] space-y-24'>{groups}</Column>
}

function SpeciesMeta(props: { speciesMeta: WikiMeta }) {
  const { speciesMeta } = props
  const { binomial, extract, titles, originalimage } = speciesMeta

  const wikipediaUrl = `https://en.wikipedia.org/wiki/${binomial}`

  return (
    <Column className='max-w-[660px]'>
      <Row className='row-vcenter'>
        <div className='mr-16 text-16 font-bold'>{titles.normalized}</div>
        <Pressable icon='launch' href={wikipediaUrl} openInNewTab>
          Wikipedia
        </Pressable>
      </Row>
      <div className='italic'>{binomial}</div>
      <p className='mt-8'>{extract}</p>

      <img
        src={originalimage.source}
        style={{ aspectRatio: `${originalimage.width}/${originalimage.height}` }}
        className='mt-12 rounded-md ring-1 ring-ink-100'
      />
    </Column>
  )
}

function EndengeredSpeciesItem(props: {
  endengeredSpecies: EndengeredSpecies
  onClick
  isActive: boolean
}) {
  const { endengeredSpecies, onClick, isActive } = props
  const { binomial, taxonomyGroup } = endengeredSpecies

  return (
    <Pressable onClick={onClick} isActive={isActive}>
      {binomial} {taxonomyGroup}
    </Pressable>
  )
}

type WikiImage = {
  source: string
  height: number
  width: number
}

type WikiMeta = {
  content_urls: any
  description: string
  description_source: string
  dir: string
  displaytitle: string
  extract: string
  extract_html: string
  lang: string
  originalimage: WikiImage
  pageid: string
  thumbnail: WikiImage
  tid: string
  timestamp: string
  title: string
  titles: {
    canonical: string
    display: string
    normalized: string
  }
  wikibase_item: string
  binomial: string
}

async function fetchWikipedia(binomial: string): Promise<WikiMeta> {
  const url = `https://en.wikipedia.org/api/rest_v1/page/summary/${binomial}?redirect=true`
  const res = await fetch(url).then((res) => res.json())
  return {
    ...res,
    binomial,
  }
}
