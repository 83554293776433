import { Spreadsheet } from '~/components/core/Spreadsheet'
import { WorkInProgressBanner } from '~/components/core/WorkInProgressBanner'
import {
  placeholderFixedCosts,
  financialsStore,
} from '~/routes/project/financials/financialsStore'

import { Section } from '~/styles/main'
import { formatNumber, parseNumber } from '~/utils/units'
import { useActiveLandProject } from '~/state'

const data = placeholderFixedCosts.map((i) => [
  { value: formatNumber(i), className: 'text-right' },
])

const columns = ['costs']

type Props = {}

export function FixedCosts(props: Props) {
  const { landProject } = useActiveLandProject()

  return (
    <Section title='Fixed Costs'>
      <WorkInProgressBanner projectType={landProject.projectType} />

      <Spreadsheet columnLabels={columns} data={data} onChange={(data) => save(data)} />
    </Section>
  )
}

function save(data) {
  financialsStore.variables.fixedCosts = parseTableToArray(data)
}

function parseTableToArray(data) {
  return data.map((row) => {
    const cost = parseNumber(row[0]?.value)
    return cost
  })
}
