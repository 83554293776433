const formatPublication = ({ data, existingData }: { data: any; existingData?: any }) => {
  const publication: any = {
    literaturePublication: {
      authors: data.authors,
      journal: data.journal,
      source: data.source,
      sourceUrl: data.sourceUrl,
      title: data.title,
      year: data.year,
      // TODO: find a generic solution for this for all Checkboxes
      openSource: data.openSource === 'on',
      createdBy: data.createdBy,
    },
    literatureSites: [
      {
        siteId: data.siteId,
        country: data.country,
        centerLatDecimalDeg: data.centerLatDecimalDeg,
        centerLonDecimalDeg: data.centerLonDecimalDeg,
        centerLonDegrees: data.centerLonDegrees,
        centerLonMinutes: data.centerLonMinutes,
        centerLonSeconds: data.centerLonSeconds,
        centerLonDirection: data.centerLonDirection,
        centerLatDegrees: data.centerLatDegrees,
        centerLatMinutes: data.centerLatMinutes,
        centerLatSeconds: data.centerLatSeconds,
        centerLatDirection: data.centerLatDirection,
        literatureMeasurements: [
          {
            plotId: data.plotId,
            plotRemarks: data.plotRemarks,
            reforestationType: data.reforestationType,
            reforestationSubType: data.reforestationSubType,
            reforestationRemarks: data.reforestationRemarks,
            standingDensityPerHa: data.standingDensityPerHa,
            plantingDensityPerHa: data.plantingDensityPerHa,
            treatment: data.treatment,
            treatmentRemarks: data.treatmentRemarks,
            measurementByAge: JSON.parse(data.measurementByAge).filter(
              (measurement) => measurement
            ),
          },
        ],
      },
    ],
  }

  if (existingData) {
    const { literaturePublication, literatureSites } = existingData
    publication.literaturePublication._id = literaturePublication._id
    // TODO: update this to take into account multiple sites
    publication.literatureSites[0]._id = literatureSites[0]._id
    publication.literatureSites[0].literaturePublicationId =
      literatureSites[0].literaturePublicationId
  }

  return publication
}

const savePublication = async (data: any) => {
  const formattedPublication = formatPublication({ data })

  const result = await fetch(
    `${process.env.REACT_APP_URL_SIMEARTH}web/scienceDatabase/publications`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formattedPublication),
    }
  ).then(async (response) => {
    const textResponse = await response.text()
    if (response.status !== 200) {
      throw new Error(textResponse)
    }
    return JSON.parse(textResponse)
  })

  return result
}

const updatePublicationById = async ({
  existingData,
  updatedData,
  publicationId,
}: {
  existingData: any
  updatedData: any
  publicationId: string
}) => {
  const formattedPublication = formatPublication({ data: updatedData, existingData })

  const result = await fetch(
    `${process.env.REACT_APP_URL_SIMEARTH}web/scienceDatabase/publications/${publicationId}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formattedPublication),
    }
  ).then(async (response) => {
    const textResponse = await response.text()
    if (response.status !== 200) {
      throw new Error(textResponse)
    }
    return JSON.parse(textResponse)
  })

  return result
}

const getPublicationById = async (id: string) => {
  return await fetch(
    `${process.env.REACT_APP_URL_SIMEARTH}web/scienceDatabase/publications/${id}`
  ).then(async (response) => {
    const textResponse = await response.text()
    if (response.status !== 200) {
      throw new Error(textResponse)
    }
    return JSON.parse(textResponse)
  })
}

const getPublications = async () => {
  return await fetch(
    `${process.env.REACT_APP_URL_SIMEARTH}web/scienceDatabase/publications`
  ).then(async (response) => {
    const textResponse = await response.text()
    if (response.status !== 200) {
      throw new Error(textResponse)
    }
    return JSON.parse(textResponse)
  })
}

// TODO: the following utils should probably be in a separate file?

const getSourceUrl = (source: string) => {
  return source.includes('http') ? source : `https://doi.org/${source}`
}

const getSelectOptionsWithLabels = ({
  types,
  isOptional,
}: {
  types: Object
  isOptional?: boolean
}) => {
  let options = []

  if (isOptional) {
    options.push({ value: undefined, label: 'Choose one' })
  }

  options = options.concat(
    Object.keys(types)
      .filter((key) => isNaN(Number(key)))
      .map((key) => ({
        value: key,
        label: isNaN(types[key]) ? types[key] : key,
      }))
      .sort((a, b) => {
        if (a.label > b.label) {
          return 1
        } else if (a.label < b.label) {
          return -1
        }
        return 0
      })
  )

  return options
}

export {
  savePublication,
  updatePublicationById,
  getPublicationById,
  getPublications,
  getSourceUrl,
  getSelectOptionsWithLabels,
}
