import { PropsWithChildren } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import { cn } from '~/utils/cn'

type Props = PropsWithChildren<{
  href?: string
  className?: string
  prefetch?: 'intent' | 'render' | 'none'
  onClick?: (e) => void
  to?: any
  openInNewTab?: boolean
  target?: string
}>

export default function Link(props: Props) {
  const { href, to, children, onClick, openInNewTab, className, ...rest } = props

  if (to)
    return (
      <ReactRouterLink to={to} className={className} {...rest}>
        {children}
      </ReactRouterLink>
    )

  const targetProps = openInNewTab ? { target: '_blank' } : {}

  return (
    <a
      href={href || to}
      {...targetProps}
      className={cn('!cursor-pointer', className)}
      {...rest}
    >
      {children}
    </a>
  )
}
