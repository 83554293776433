import { proxy, useSnapshot } from 'valtio'
import { devtools } from 'valtio/utils'
import { ConfirmDialogProps } from '~/components/dialogs/ConfirmDialog'
import { LandProject, Parcel } from '~/models'
import { logger } from '~/services/logger'
import { AuthedUser } from '~/models/authedUser'
import { ProcessingState } from '~/utils/constants'
import { Coordinates } from '~/types/index'
import { LandProjectStats } from '~/models/landProjectStats'
import { REDDLandProjectStats } from '~/models/landProjectReddStats'

type State = {
  landProjects: LandProject[]
  activeLandProjectId: string
  activeParcelId: string
  parcels: Parcel[]
  authedUser: AuthedUser
  confirmDialogData: ConfirmDialogProps
  landProjectProcessing: ProcessingState
  userProfileDialog: boolean
  referenceRegionCoordinates: Coordinates
  referenceRegionCoordinates5km: Coordinates
  activeComputations: {
    avoidedEmissions: boolean
    reddStats: boolean
  }
}

const defaultState = {
  landProjects: [],
  parcels: null,
  activeLandProjectId: null,
  activeParcelId: null,
  authedUser: undefined,
  confirmDialogData: null,
  landProjectProcessing: null,
  userProfileDialog: false,
  referenceRegionCoordinates: null,
  referenceRegionCoordinates5km: null,
  activeComputations: {
    avoidedEmissions: false,
    reddStats: false,
  },
}

const state = proxy<State>(defaultState)

const unsub = devtools(state, { name: 'store' })

export { state, useSnapshot }

/**
 * Store land project data to the global state
 */
export function storeLandProjectData(data: Partial<LandProject>, landProjectId: string) {
  const landProject = state.landProjects.find((lp) => lp._id === landProjectId)
  if (!landProject) throw new Error('landProject not found')

  const newLandProject = { ...landProject, ...data }
  storeLandProject(newLandProject)
}

export function storeLandProjectStatsData(data: LandProjectStats & REDDLandProjectStats) {
  const landProject = state.landProjects.find((lp) => lp._id === data.landProjectId)
  if (!landProject) throw new Error('landProject not found')
  const newLandProject = { ...landProject, xStats: data }
  storeLandProject(newLandProject)
}

/**
 * Store project data to the global state
 */
export function storeLandProject(landProject: LandProject) {
  if (!state.landProjects) state.landProjects = [landProject]

  const currentProjectIndex = state.landProjects?.findIndex(
    (p) => p._id === landProject._id
  )
  if (currentProjectIndex >= 0) {
    const newLandProject = {
      ...state.landProjects[currentProjectIndex],
      ...landProject,
    }
    state.landProjects[currentProjectIndex] = newLandProject
    logger.info('storeLandProject -> update:', newLandProject)
  } else {
    state.landProjects.push(landProject)
    logger.info('storeLandProject -> add: ', landProject)
  }
  state.referenceRegionCoordinates =
    landProject?.xStats?.combinedReferenceRegion?.coordinates
  state.referenceRegionCoordinates5km =
    landProject?.xStats?.combinedReferenceRegion5km?.coordinates
}

export function useActiveLandProject(uName?: string): {
  landProject: LandProject
  parcels: Parcel[]
  referenceRegionCoordinates?: Coordinates
  referenceRegionCoordinates5km?: Coordinates
} {
  const snap = useSnapshot(state)
  let landProject: LandProject = null

  if (snap.activeLandProjectId) {
    landProject = snap.landProjects?.find(
      (p) => p._id === snap.activeLandProjectId
    ) as LandProject
  } else if (uName) {
    landProject = snap.landProjects?.find((p) => p.uName === uName) as LandProject
  }

  const parcels = snap.parcels as Parcel[]
  const referenceRegionCoordinates = snap.referenceRegionCoordinates as Coordinates
  const referenceRegionCoordinates5km = snap.referenceRegionCoordinates5km as Coordinates

  if (landProject) state.activeLandProjectId = landProject._id

  return {
    landProject,
    parcels,
    referenceRegionCoordinates,
    referenceRegionCoordinates5km,
  }
}

export function selectLandProjectOrActive(landProjectId?: string) {
  landProjectId = landProjectId || state.activeLandProjectId
  const landProject = state.landProjects.find((p) => p._id === landProjectId)
  if (!landProject) throw 'land project not found'
  return landProject
}

export function useParcel(parcelId: string) {
  const snap = useSnapshot(state)

  const parcels = snap.parcels as Parcel[]
  const activeParcel = parcels.find((p) => p._id == parcelId)

  return {
    parcel: activeParcel,
  }
}

export function resetStateStore() {
  for (const key in state) {
    state[key] = defaultState[key]
  }
}

export function toggleUserProfileDialog() {
  state.userProfileDialog = !state.userProfileDialog
}

export function resetProcessingState() {
  setTimeout(() => {
    state.landProjectProcessing = null
  }, 1000)
}
