import Icon from '~/components/atomic/Icon'
import Input from '~/components/atomic/Input'
import { Switch } from '~/components/atomic/Switch'
import { Tooltip } from '~/components/atomic/Tooltip'
import { Numerical } from '~/components/core/Numerical'
import { getLandProjectArea } from '~/models/landProject'
import { CarbonScenarioSelector } from '~/routes/project/financials/CarbonScenarioSelector'
import {
  FinancialsVariables,
  updateStoreVariable,
  useFinancialsStore,
} from '~/routes/project/financials/financialsStore'
import { InvestmentDistributions } from '~/routes/project/financials/InvestmentDistributions'
import { useActiveLandProject } from '~/state'
import { Column, Row } from '~/styles'
import { classesFullBleedContainer, Divider } from '~/styles/main'
import { getDiscounts } from '~/routes/project/vcus/components/DiscountsSection'

export type InputItem = {
  name: keyof FinancialsVariables
  label: string
  type: string
  value: any
  tooltip?: string
  isDisabled?: boolean
}

export function FinancialInputs() {
  const { landProject } = useActiveLandProject()
  const { variables } = useFinancialsStore()
  const { investmentRange, costsRange, withVintages } = variables

  const creditShares = [
    {
      name: 'investorCreditShare',
      label: 'Investor',
      type: 'percent',
      value: variables.investorCreditShare,
    },
    {
      name: 'projectCreditShare',
      label: 'Project',
      type: 'percent',
      value: variables.projectCreditShare,
    },
    {
      name: 'earthshotCreditShare',
      label: 'Earthshot',
      type: 'percent',
      value: variables.earthshotCreditShare,
    },
  ]

  const creditSharesPostExit = [
    {
      name: 'investorCreditSharePostExit',
      label: 'Investor',
      type: 'percent',
      value: variables.investorCreditSharePostExit,
    },
    {
      name: 'projectCreditSharePostExit',
      label: 'Project',
      type: 'percent',
      value: variables.projectCreditSharePostExit,
    },
    {
      name: 'earthshotCreditSharePostExit',
      label: 'Earthshot',
      type: 'percent',
      value: variables.earthshotCreditSharePostExit,
    },
  ]

  const revenueShares = [
    {
      name: 'communityRevenueShare',
      label: 'Community',
      type: 'percent',
      value: variables.communityRevenueShare,
    },
  ]

  const otherInputs = [
    {
      name: 'startYear',
      label: 'Start Year',
      value: variables.startYear,
    },
    {
      name: 'operationsStartYear',
      label: 'Operations Start Year',
      value: variables.operationsStartYear,
    },
    {
      name: 'landPurchase',
      label: 'Land Purchase Price',
      value: variables.landPurchase,
    },
    {
      name: 'brokerageFee',
      label: 'Brokerage Fee',
      type: 'percent',
      value: variables.brokerageFee,
    },
  ]
  if (withVintages) {
    otherInputs.push({
      name: 'investorExitYear',
      label: 'Investor Exit Year',
      value: variables.investorExitYear,
    })
  }

  const discounts = getDiscounts(variables, landProject.projectType)
  const projectArea = getLandProjectArea(landProject)

  return (
    <Column className='space-y-20'>
      <Column>
        <div className='font-bold mb-8'>Scenarios</div>
        <Row className='row-vcenter space-x-20'>
          <CarbonScenarioSelector />
          <Row className='space-x-8'>
            <span>Project area:</span>
            <Numerical type='area' value={projectArea} />
          </Row>
          <Tooltip
            content={`Based on Cambodia project where total project cost per ha is $116`}
          >
            <div className='row space-x-8'>
              <span>Yearly Cost benchmark:</span>
              <Numerical type='currency' value={(projectArea * 116) / 30} />
            </div>
          </Tooltip>
          <Switch
            name='withVintages'
            label='With Vintages'
            checked={variables.withVintages}
            onCheckedChange={(checked) => updateStoreVariable(checked, 'withVintages')}
          />
        </Row>
      </Column>
      <div
        className={`grid grid-cols-[repeat(5,fit-content(500px))] gap-x-40 overflow-x-scroll ${classesFullBleedContainer}`}
      >
        <Column className='space-y-12'>
          <InputsGroup
            title={`Credit Sharing ${withVintages ? '(preexit)' : ''}`}
            items={creditShares}
          >
            <span className='opacity-50 text-right'>
              {creditShares.reduce((prev, curr) => prev + curr.value, 0) * 100}%
            </span>
          </InputsGroup>

          <InputsGroup title='Revenue Shares' items={revenueShares} />
        </Column>
        {withVintages && (
          <InputsGroup title='Credit Sharing (postexit)' items={creditSharesPostExit} />
        )}

        <InputsGroup title='Other' items={otherInputs} />
        <InvestmentDistributions />

        <DisplayVariablesGroup title='Discounts' items={discounts} />
      </div>

      <Divider />

      <Row className='row-vcenter space-x-40'>
        <RangeInput
          label='Investment Range'
          name='investmentRange'
          value={investmentRange}
          unit='Total Millions'
          onValueChange={(value) => updateStoreVariable(value, 'investmentRange')}
        />
        <RangeInput
          label='Cost Range'
          name='costsRange'
          unit='Thousands $ / year'
          value={costsRange}
          onValueChange={(value) => updateStoreVariable(value, 'costsRange')}
        />
      </Row>
    </Column>
  )
}

function RangeInput(props) {
  const { label, value, onValueChange, unit } = props

  function onChange(e) {
    const newValue = [...value]
    const index = Number(e.currentTarget.name)
    newValue[index] = e.currentTarget.value
    onValueChange(newValue)
  }

  return (
    <Row className='row-vcenter space-x-12'>
      <Input.Label>{label}</Input.Label>
      <Input
        className='w-80'
        name='0' // This will serve as the array index
        value={value[0]}
        onChange={onChange}
        onFocus={(e) => e.currentTarget.select()}
      />
      <Input
        className='w-80'
        name='1' // This will serve as the array index
        value={value[1]}
        onChange={onChange}
        onFocus={(e) => e.currentTarget.select()}
      />
      {unit && <div className='text-13 text-ink-400'>{unit}</div>}
    </Row>
  )
}

export function InputsGroup(props) {
  const { title, items, children } = props

  return (
    <Column>
      {title && <div className='font-bold mb-8'>{title}</div>}
      <div className='grid grid-cols-[max-content_1fr] gap-x-12 gap-y-8 items-center'>
        {items.map((item, i) => (
          <InputGroup {...item} key={item.name} />
        ))}
      </div>
      {children}
    </Column>
  )
}

function InputGroup(props: InputItem) {
  const { name, label, type, tooltip, isDisabled } = props

  const value = type === 'percent' ? Math.floor(props.value * 100) : props.value

  function onChange(e) {
    let newValue = Number(e.currentTarget.value)
    if (type === 'percent') newValue /= 100
    updateStoreVariable(newValue, name)
  }

  const labelComponent = <Input.Label htmlFor={name}>{label}</Input.Label>

  return (
    <>
      {tooltip ? (
        <Tooltip content={<p className='text-12'>{tooltip}</p>}>
          <div className='row row-vcenter space-x-4 !cursor-help'>
            {labelComponent}{' '}
            <Icon size={16} icon='help_outline' className='text-ink-400' />
          </div>
        </Tooltip>
      ) : (
        labelComponent
      )}
      <Input
        disabled={isDisabled}
        className='w-80 row row-vcenter'
        value={value}
        name={name}
        onChange={onChange}
        onFocus={(e) => e.currentTarget.select()}
        type='number'
        unitRight={type === 'percent' ? '%' : ''}
        inputClassName='py-4 text-right'
      />
    </>
  )
}

function DisplayVariablesGroup(props) {
  const { title, items, children } = props

  return (
    <Column>
      {title && <div className='font-bold mb-8'>{title}</div>}
      <Column className='divide-y'>
        {items.map((item, i) => (
          <Row key={i} className='grid grid-cols-2 gap-12 py-8'>
            <div className='font-medium'>{item.label}</div>
            <Numerical type='percent' value={item.value} />
          </Row>
        ))}
      </Column>
      {children}
    </Column>
  )
}
