import { ArchiveProjectItem } from '~/components/menus/ArchiveProjectItem'
import { DeleteProjectItem } from '~/components/menus/DeleteProjectItem'
import { LandProject } from '~/models'

type Props = {
  landProject: LandProject
}

export function ProjectMenu(props: Props) {
  const { landProject } = props

  return (
    <>
      <ArchiveProjectItem landProject={landProject} />
      <DeleteProjectItem landProject={landProject} />
    </>
  )
}
