import { cn } from '~/utils/cn'
import React from 'react'
import { ProgressRing } from '~/components/atomic/ProgressRing'
import { ES_Form, getScopingAssessmentForms } from '~/models/form'
import {
  DatumField,
  getChosenOption,
  getDatumFieldsTotalScore,
  getFormsCompletion,
  getProjectDataFieldsByFormId,
  getScoreForOption,
  getTotalPossibleScore,
} from '~/models/projectDatum'
import { useActiveLandProject } from '~/state'
import { Column, Row } from '~/styles'
import { Section } from '~/styles/main'

type Props = {}

export function ScopingAssessment(props: Props) {
  const goNoGoForms = getScopingAssessmentForms()

  return (
    <Section title='Scoping Assessment Scoring' className='gap-y-40'>
      <Column>
        <Section.Subtitle>Completion</Section.Subtitle>

        <AssessmentsSummary />
      </Column>

      <Column>
        <Section.Subtitle>Score Summary</Section.Subtitle>
        {goNoGoForms.map((form) => {
          return <Group form={form} key={form.id} withDatums={false} />
        })}
      </Column>

      <Column className='gap-y-20 '>
        <Section.Subtitle className='mb-8'>Specific Answers</Section.Subtitle>
        <Column className='divide-y-2'>
          {goNoGoForms.map((form) => {
            return (
              <div className='py-8'>
                <Group form={form} key={form.id} withDatums />
              </div>
            )
          })}
        </Column>
      </Column>
    </Section>
  )
}

export function AssessmentsSummary() {
  const { landProject } = useActiveLandProject()
  const goNoGoForms = getScopingAssessmentForms()
  const formsCompletion = getFormsCompletion(goNoGoForms, landProject)

  return (
    <Row className='gap-x-40'>
      <div className='grid grid-cols-[max-content,1fr] gap-x-16 gap-y-8'>
        {formsCompletion.forms.map(({ id, label, total, percent, completed }) => {
          return (
            <React.Fragment key={id}>
              <div>{label}</div>
              <Row className='row-vcenter'>
                <ProgressRing progress={percent} className='mr-8' />
                <span className='text-number'>
                  {completed}/{total}
                </span>
              </Row>
            </React.Fragment>
          )
        })}
      </div>
    </Row>
  )
}

function Group(props: { form: ES_Form; withDatums?: boolean }) {
  const { form, withDatums } = props
  const { landProject } = useActiveLandProject()

  const datumFields = getProjectDataFieldsByFormId(form.id, landProject)
  const { min, max } = getTotalPossibleScore(datumFields)
  const totalScore = getDatumFieldsTotalScore(datumFields)

  return (
    <Column className='py-4'>
      <Row className='row-vcenter gap-12 mb-12'>
        <Score className='font-bold w-[28px]' score={totalScore} />
        <div className='text-15 font-semibold'>{form.label}</div>
        <span className='text-12'>{min}</span>
        <Row
          className='row-vcenter h-6 relative'
          style={{ width: (Math.abs(min) + max) * 10 }}
        >
          <div
            className='h-full bg-red-300 rounded-l-full'
            style={{ width: Math.abs(min) * 10 }}
          />
          <div
            className='h-full bg-green-300  rounded-r-full'
            style={{ width: max * 10 }}
          />
          <div
            className='w-[2px] bg-black absolute -top-8 -bottom-8 rounded-full'
            style={{ left: Math.abs(min) * 10 + totalScore * 10 - 2 }}
          />
        </Row>
        <span className='text-12'>{max}</span>
      </Row>
      {withDatums && (
        <Column className='divide-y mt-8'>
          {datumFields.map(
            (datumField) =>
              datumField.options && <Item datumField={datumField} key={datumField.id} />
          )}
        </Column>
      )}
    </Column>
  )
}

function Item(props: { datumField: DatumField }) {
  const { datumField } = props
  const { label, value } = datumField

  const parsedValue = (datumField.options && getChosenOption(datumField)?.label) || value

  const score = datumField.options && getScoreForOption(datumField)

  return (
    <Row className='grid grid-cols-[28px,1fr,1fr] gap-x-8 py-4'>
      {score ? <Score score={score} /> : <div />}
      <div>{label}</div>
      <div>{parsedValue}</div>
    </Row>
  )
}

function Score(props) {
  const { score, className } = props
  return (
    <div
      className={cn(
        'bg-ink-200 px-6 py-2 rounded-md self-start text-center',
        score < 0 && 'bg-red-200 text-red-900',
        score > 0 && 'bg-green-200 text-green-900',
        className
      )}
    >
      {score}
    </div>
  )
}
