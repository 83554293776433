import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { CenteredLoader } from '~/components/atomic/CenteredLoader'
import { fetchDB } from '~/services/db'
import { logger } from '~/services/logger'
import { resetStateStore } from '~/state'
import { setAuthedUser } from '~/state/auth'

export function LogOut() {
  const navigate = useNavigate()

  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState('')

  useEffect(() => {
    logOut()
  }, [])

  return (
    <>
      {submitting && <CenteredLoader />}
      {error && <div className='margin-b center min-h-full'>{error}</div>}
    </>
  )

  async function logOut() {
    const authedUser = JSON.parse(localStorage.getItem('authedUser') as any)
    logger.info('logging out -> authedUser: ', authedUser)

    if (authedUser) {
      setSubmitting(true)
      setAuthedUser(authedUser)

      const data = {
        user_id: authedUser._id,
        session_id: authedUser.session_id,
      }

      const res = await fetchDB('logout', data)

      onLogout(res)
    } else {
      navigate('/')
    }
  }

  function onLogout(res) {
    logger.info('onLogout', res)
    const { valid, msg } = res

    resetStateStore()
    setSubmitting(false)
    if (valid) {
      setAuthedUser(null)
      navigate('/', { state: { from: 'logout' } })
    } else {
      setError(msg || 'Log out error, please try again.')
    }
  }
}
