import { LandProject } from '~/models/landProject'
import {
  ComputeModelInfo,
  LandProjectStats,
  LandProjectStatsYearly,
} from '~/models/landProjectStats'
import { amazoniaCountries, getCountryNameByCode } from '~/services/countries'
import { state } from '~/state'
import { Override, ReportSectionData } from '~/types'
import { Row } from '~/styles'
import Icon from '~/components/atomic/Icon'
import { DEFAULT_PROJET_PERIOD } from '~/utils/constants'
import ESSocket from '~/utils/ESSocket'
import { fillArray } from '~/utils'
import { line } from 'd3-shape'

export const REDDChosenProjectionKeys = {
  average: 'byYear',
  projectArea: 'byYearUnderProjectAreaRate',
  referenceRegion20km: 'byYearUnderReferenceRegionRate',
  referenceRegion5km: 'byYearUnderReferenceRegionRate5kmBuffer',
}

export type REDDChosenProjectionKey = keyof typeof REDDChosenProjectionKeys

export const estimatesExplanationMap = {
  average: 'Average of the reference region and project area',
  projectArea: 'Project area',
  referenceRegion20km: 'Reference region (20km)',
  referenceRegion5km: 'Reference Region (5km)',
}

export const estimatesBaseKeysOptions = [
  { label: 'Average', value: 'average' },
  { label: 'Project Area', value: 'projectArea' },
  { label: 'Reference Region (20km)', value: 'referenceRegion20km' },
  { label: 'Reference Region (5km)', value: 'referenceRegion5km' },
]

export enum REDDComputeModelKeys {
  vm0007 = 'redd_vm0007',
}

export type REDDAssumptions = {
  forestDefinitionTreeCoverPercent: number
}

export type CombinedReferenceRegion = {
  startingYear: number
  yearDate: number
  coordinates: any[]
  areaHa: number
  initialForestCoverHa: number
  totalDeforestationHa: number
  percentDeforestation: number
  annualDeforestationRate: number
}

export type REDDLandProjectYearlyAllScenarios = {
  year: number
  yearDate: number
  annualForestCoverHa?: number
  annualDeforestationHa?: number
  tCO2AnnualAvoidedEmissions?: number
  tCO2?: number
  annualForestCoverHaUnderProjectAreaRate?: number
  annualDeforestationHaUnderProjectAreaRate?: number
  tCO2AnnualAvoidedEmissionsUnderProjectAreaRate?: number
  tCO2UnderProjectAreaRate?: number
  annualForestCoverHaUnderReferenceRegionRate?: number
  annualForestCoverHaUnderReferenceRegionRate5km?: number
  annualDeforestationHaUnderReferenceRegionRate?: number
  annualDeforestationHaUnderReferenceRegionRate5km?: number
  tCO2AnnualAvoidedEmissionsUnderReferenceRegionRate?: number
  tCO2AnnualAvoidedEmissionsUnderReferenceRegionRate5km?: number
  tCO2UnderReferenceRegionRate?: number
  tCO2UnderReferenceRegionRate5km?: number
}

export type REDDLandProjectYearly = {
  annualForestCoverHa?: number
  annualDeforestationHa?: number
  tCO2AnnualAvoidedEmissions?: number
  tCO2?: number
} & LandProjectStatsYearly

export type REDDLandProjectByYear = REDDLandProjectYearly[]

export type REDDLandProjectStats = Override<
  LandProjectStats,
  {
    byYear: REDDLandProjectByYear
    byYearUnderProjectAreaRate?: REDDLandProjectByYear
    byYearUnderReferenceRegionRate?: REDDLandProjectByYear
    byYearUnderReferenceRegionRate5kmBuffer?: REDDLandProjectByYear
    deforestation?: {
      totalDeforestationHa: number
      annualDeforestationHa: number
      totalDeforestationPercent: number
      annualDeforestationRate: number
      byYear: {
        // calendar year for bar graph
        year: number
        historicalDeforestationHa: number
        trendPoint: number
      }[]
      computeModelInfo: ComputeModelInfo
    }

    biomass?: {
      aboveGroundBiomass?: number
      belowGroundBiomass?: number
      totalBiomass: number
      CO2Equivalent: number
      computeModelInfo: ComputeModelInfo
    }
    combinedReferenceRegion?: CombinedReferenceRegion
    combinedReferenceRegion5km?: CombinedReferenceRegion
    assumptions: REDDAssumptions
    computeModelsInfo: ComputeModelInfo[]
  }
>

/* REDD MODELS*/
export enum REDDModelsKeys {
  biomass = 'biomass',
  forestNonForest = 'forestNonForest',
  deforestation = 'deforestation',
}

export type REDDModelsDataset = {
  [REDDModelsKeys.forestNonForest]: string
  [REDDModelsKeys.biomass]: string
  [REDDModelsKeys.deforestation]: string
}

export type REDDModels =
  | REDDModelsKeys.biomass
  | REDDModelsKeys.deforestation
  | REDDModelsKeys.forestNonForest

export const defaultREDDAssumptions = {
  forestDefinitionTreeCoverPercent: 30,
}

// todo: fetch datasets value from backend so it's not harcoded in the frontend
export const reddModelsDataset = {
  [REDDModelsKeys.forestNonForest]: {
    datasets: [
      { label: 'ESA World Cover', value: 'worldcover_esa' },
      { label: 'Dynamic World', value: 'worldcover_dynamicworld' },
      { label: 'Palsar', value: 'worldcover_palsar' },
      { label: 'Panama Regional', value: 'landcover_panama' },
      { label: 'Brazil MapBiomas', value: 'landcover_mapbiomas_brazil' },
      { label: 'Amazonia MapBiomas', value: 'landcover_mapbiomas_amazonia' },
      { label: 'JRC Tropical Anual Change', value: 'landcover_jrcAnnualChange_tropical' },
    ],
  },
  [REDDModelsKeys.biomass]: {
    datasets: [
      { label: 'Walker', value: 'worldBiomass_walker' },
      { label: 'Spawn', value: 'worldBiomass_spawn' },
    ],
  },
  [REDDModelsKeys.deforestation]: {
    datasets: [
      { label: 'Hansen', value: 'worldDeforestation_hansen' },
      {
        label: 'JRC Tropical Deforestation',
        value: 'landDeforestation_jrc_tropical',
      },
      {
        label: 'Brazil MapBiomas',
        value: 'landDeforestation_mapbiomas_brazil',
      },
      { label: 'Amazonia MapBiomas', value: 'landDeforestation_mapbiomas_amazonia' },
    ],
  },
}

export const defaultWorldDatasets: REDDModelsDataset = {
  [REDDModelsKeys.forestNonForest]: 'worldcover_esa',
  [REDDModelsKeys.biomass]: 'worldBiomass_walker',
  [REDDModelsKeys.deforestation]: 'worldDeforestation_hansen',
}

// not used for now because regional datasets are not country specific
export function getREDDDefaultDatasetsByCountry(countryCode) {
  const country = getCountryNameByCode(countryCode)
  const defaultDatasetsByCountry = defaultWorldDatasets
  if (country === 'Brazil') {
    defaultDatasetsByCountry[REDDModelsKeys.forestNonForest] =
      'landcover_mapbiomas_brazil'
    defaultDatasetsByCountry[REDDModelsKeys.deforestation] =
      'landDeforestation_mapbiomas_brazil'
  } else if (country === 'Panama') {
    defaultDatasetsByCountry[REDDModelsKeys.forestNonForest] = 'landcover_panama'
  } else if (amazoniaCountries.includes(country)) {
    defaultDatasetsByCountry[REDDModelsKeys.forestNonForest] =
      'landcover_mapbiomas_amazonia'
    defaultDatasetsByCountry[REDDModelsKeys.deforestation] =
      'landDeforestation_mapbiomas_amazonia'
  }

  return defaultDatasetsByCountry
}

export function getREDDDatasetLabelFromValue(
  model: REDDModelsKeys,
  chosenDatasetValue: string
) {
  const chosenDataset = reddModelsDataset[model].datasets.find(
    (labelValuePair) => labelValuePair.value === chosenDatasetValue
  )
  if (chosenDataset) {
    return chosenDataset.label
  } else {
    // backward compatibility for old single dataset redd stats without computeModelInfo
    if (model === REDDModelsKeys.forestNonForest) {
      return 'ESA World Cover'
    } else if (model === REDDModelsKeys.deforestation) {
      return 'Hansen'
    } else if (model === REDDModelsKeys.biomass) {
      return 'Spawn'
    }
  }
}

export function getREDDGreenHouseGasBaseline(landProject: LandProject) {
  const REDDLandProjectStats: REDDLandProjectStats = landProject?.xStats
  const chosenProjectionKey =
    landProject?.chosenREDDProjectionKey || REDDChosenProjectionKeys.average

  const projectionKey = REDDChosenProjectionKeys[chosenProjectionKey]
  return (
    REDDLandProjectStats?.[projectionKey]?.map((d) => d.tCO2AnnualAvoidedEmissions) ||
    fillArray(30, (i) => 0)
  )
}

export function parseREDDStatsToSectionsData(data: REDDLandProjectStats) {
  if (!data?.forestNonForest) return null

  const referenceRegions = data.combinedReferenceRegion
  const referenceRegions5km = data.combinedReferenceRegion5km

  const averageAvoidedEmissions = parseAvoidedEmissionsStats(data.byYear)
  const avoidedEmissionsUnderProjectArea = parseAvoidedEmissionsStats(
    data.byYearUnderProjectAreaRate
  )
  const avoidedEmissionsUnderReferenceRegion = parseAvoidedEmissionsStats(
    data.byYearUnderReferenceRegionRate
  )
  const avoidedEmissionsUnderReferenceRegion5km = parseAvoidedEmissionsStats(
    data.byYearUnderReferenceRegionRate5kmBuffer
  )
  const avoidedEmissionsTableData = reformatTableData(
    data.byYear,
    data.byYearUnderProjectAreaRate,
    data.byYearUnderReferenceRegionRate,
    data.byYearUnderReferenceRegionRate5kmBuffer
  )

  const forestNonForestDatasetLabel = getREDDDatasetLabelFromValue(
    REDDModelsKeys.forestNonForest,
    data.forestNonForest.computeModelInfo?.dataset
  )
  const forestCover: ReportSectionData = {
    title: `Native forest cover and reforestable area information (${forestNonForestDatasetLabel})`,
    stats: [
      { label: 'Project Area', value: data.areaHa, unit: 'ha', format: 'area' },
      {
        label: 'Forested Area',
        value: data.forestNonForest.forestHa,
        unit: 'ha',
        format: 'area',
      },
      {
        label: 'Percent Forest',
        value: data.forestNonForest.forestPercent,
        format: 'percent',
      },
      {
        label: 'Non Forest Area',
        value: data.forestNonForest.nonForestHa,
        unit: 'ha',
        format: 'area',
      },
      {
        label: 'Percent Non Forest',
        value: data.forestNonForest.nonForestPercent,
        format: 'percent',
      },
    ],
  }

  const deforestationDatasetLabel = getREDDDatasetLabelFromValue(
    REDDModelsKeys.deforestation,
    data.deforestation.computeModelInfo?.dataset
  )

  // Trendline layer - see https://codesandbox.io/s/91l6kj5wop?file=/src/index.js:511-520
  const DeforestationTrend = ({ bars, xScale, yScale }) => {
    bars = bars.slice(-10) // Just use the last 10 years
    const lineGenerator = line()
      .x((bar) => xScale(bar.data.indexValue) + bar.width / 2)
      .y((bar) => yScale(bar.data.data.trendPoint))
    return <path d={lineGenerator(bars)} fill='none' stroke='rgba(60, 60, 60, 1)' />
  }

  const deforestation: ReportSectionData = {
    title: `Historical deforestation within the project area (${deforestationDatasetLabel})`,
    stats: [
      {
        label: 'Total Deforestation',
        value: data.deforestation.totalDeforestationHa,
        unit: 'ha',
        format: 'area',
        decimals: 2,
      },
      {
        label: 'Annual Rate',
        value: data.deforestation.annualDeforestationRate,

        format: 'percent',
        decimals: 2,
      },
      {
        label: 'Percent Total',
        value: data.deforestation.totalDeforestationPercent,
        format: 'percent',
      },
    ],
    graph: {
      margin: {
        top: 12,
        right: 20,
        bottom: 70,
        left: 60,
      },
      title: `Historical Deforestation 2001-2021 (${deforestationDatasetLabel})`,
      axisBottom: {
        legend: 'Year',
        legendPosition: 'middle',
        legendOffset: 50,
        tickRotation: 270,
        tickPadding: 35,
      },
      axisLeft: {
        legend: 'Deforestation (ha)',
      },
      layers: ['axes', 'bars', 'markers', DeforestationTrend],
      data: data.deforestation.byYear.map((i) => ({
        id: i.year,
        value: i.historicalDeforestationHa,
        trendPoint: i.trendPoint,
      })),
      description: 'Historical deforestation in the project area for 2001-2021.',
    },
  }

  const biomassDatasetLabel = getREDDDatasetLabelFromValue(
    REDDModelsKeys.biomass,
    data.biomass.computeModelInfo?.dataset
  )
  const biomassEstimates: ReportSectionData = {
    title: `Biomass estimates from forest cover within project area (${biomassDatasetLabel})`,

    stats: [
      {
        label: 'Total Biomass*',
        value: data.biomass.totalBiomass,
        unit: 'tC/ha',
        format: 'number',
        decimals: 2,
      },
      {
        label: 'CO2 Equivalent',
        value: data.biomass.CO2Equivalent,
        unit: 'tCO2/ha',
        format: 'number',
        decimals: 2,
      },
    ],
  }

  const referenceRegionBaselines: ReportSectionData = {
    title: '20km Reference region baselines:',
    stats: [
      {
        label: 'Reference Region Area',
        value: referenceRegions?.areaHa,
        unit: 'ha',
        format: 'number',
      },
      {
        label: 'Initial Forest Cover in Reference Region',
        value: referenceRegions?.initialForestCoverHa,
        unit: 'ha',
        format: 'number',
      },
      {
        label: 'Total Deforestation in Reference Region',
        value: referenceRegions?.totalDeforestationHa,
        unit: 'ha',
        format: 'number',
      },
      {
        label: 'Percent Total Deforestation in Reference Region',
        value: referenceRegions?.percentDeforestation,
        format: 'percent',
      },
      {
        label: 'Annual Deforestation Rate to be applied to project area',
        value: referenceRegions?.annualDeforestationRate,
        unit: '%/yr',
        format: 'percent',
        decimals: 2,
      },
    ],
  }

  const referenceRegionBaselines5km: ReportSectionData = {
    title: '5km Reference region baselines:',
    stats: [
      {
        label: 'Reference Region Area',
        value: referenceRegions5km?.areaHa,
        unit: 'ha',
        format: 'number',
      },
      {
        label: 'Initial Forest Cover in Reference Region',
        value: referenceRegions5km?.initialForestCoverHa,
        unit: 'ha',
        format: 'number',
      },
      {
        label: 'Total Deforestation in Reference Region',
        value: referenceRegions5km?.totalDeforestationHa,
        unit: 'ha',
        format: 'number',
      },
      {
        label: 'Percent Total Deforestation in Reference Region',
        value: referenceRegions5km?.percentDeforestation,
        format: 'percent',
      },
      {
        label: 'Annual Deforestation Rate to be applied to project area',
        value: referenceRegions5km?.annualDeforestationRate,
        unit: '%/yr',
        format: 'percent',
        decimals: 2,
      },
    ],
  }

  const avoidedEmissions: ReportSectionData = {
    title:
      'Projected avoided CO2 emissions from prevented deforestation under average deforestation rate*',

    stats: [
      {
        label: 'Average annual CO2 avoided emissions',
        value: averageAvoidedEmissions.avgAnnualCO2AvoidedEmissions,
        unit: 'tCO2',
        format: 'number',
        decimals: 2,
      },
      {
        label: 'Total avoided CO2 emissions after 30 years',
        value: averageAvoidedEmissions.totalAvoidedCO2Emissions,
        unit: 'tCO2',
        format: 'number',
      },
    ],

    table: {
      data: avoidedEmissionsTableData,
    },

    graph: {
      title: 'Avoided CO2 emissions from preventing deforestation (deforestation rate)',
      axisBottom: { legend: 'Years' },
      axisLeft: { legend: 'Avoided CO2 emissions (tons CO2)' },
      data: avoidedEmissionsTableData,
      description:
        'Avoided CO2 emissions from preventing deforestation in the project area for a 30 years period. ',
    },
  }

  const avoidedEmissionsUnderProjectAreaRate: ReportSectionData =
    !avoidedEmissionsUnderProjectArea
      ? null
      : {
          title:
            'Projected avoided CO2 emissions from prevented deforestation under project area deforestation rate',

          stats: [
            {
              label: 'Average annual CO2 avoided emissions',
              value: avoidedEmissionsUnderProjectArea.avgAnnualCO2AvoidedEmissions,
              unit: 'tCO2',
              format: 'number',
              decimals: 2,
            },
            {
              label: 'Total avoided CO2 emissions after 30 years',
              value: avoidedEmissionsUnderProjectArea.totalAvoidedCO2Emissions,
              unit: 'tCO2',
              format: 'number',
            },
          ],
        }

  const avoidedEmissionsUnderReferenceRegionRate: ReportSectionData =
    !avoidedEmissionsUnderReferenceRegion
      ? null
      : {
          title:
            'Projected avoided CO2 emissions from prevented deforestation under 20km reference region deforestation rate',

          stats: [
            {
              label: 'Average annual CO2 avoided emissions',
              value: avoidedEmissionsUnderReferenceRegion.avgAnnualCO2AvoidedEmissions,
              unit: 'tCO2',
              format: 'number',
              decimals: 2,
            },
            {
              label: 'Total avoided CO2 emissions after 30 years',
              value: avoidedEmissionsUnderReferenceRegion.totalAvoidedCO2Emissions,
              unit: 'tCO2',
              format: 'number',
            },
          ],
        }

  const avoidedEmissionsUnderReferenceRegionRate5kmBuffer: ReportSectionData =
    !avoidedEmissionsUnderReferenceRegion5km
      ? null
      : {
          title:
            'Projected avoided CO2 emissions from prevented deforestation under 5km buffer reference region deforestation rate',

          stats: [
            {
              label: 'Average annual CO2 avoided emissions',
              value: avoidedEmissionsUnderReferenceRegion5km.avgAnnualCO2AvoidedEmissions,
              unit: 'tCO2',
              format: 'number',
              decimals: 2,
            },
            {
              label: 'Total avoided CO2 emissions after 30 years',
              value: avoidedEmissionsUnderReferenceRegion5km.totalAvoidedCO2Emissions,
              unit: 'tCO2',
              format: 'number',
            },
          ],
        }

  const reddData = {
    forestCover,
    deforestation,
    biomassEstimates,
    referenceRegionBaselines,
    referenceRegionBaselines5km,
    avoidedEmissions,
    avoidedEmissionsUnderProjectAreaRate,
    avoidedEmissionsUnderReferenceRegionRate,
    avoidedEmissionsUnderReferenceRegionRate5kmBuffer,
  }

  return reddData
}

function parseAvoidedEmissionsStats(byYear) {
  if (!byYear) return
  const lastYear = byYear[DEFAULT_PROJET_PERIOD - 1]
  const totalAvoidedCO2Emissions = lastYear.tCO2
  const avgAnnualCO2AvoidedEmissions = totalAvoidedCO2Emissions / DEFAULT_PROJET_PERIOD
  const avoidedEmissions = { totalAvoidedCO2Emissions, avgAnnualCO2AvoidedEmissions }
  return avoidedEmissions
}

function reformatTableData(
  averageAvoidedEmissions: REDDLandProjectByYear,
  avoidedEmissionsUnderProjectArea: REDDLandProjectByYear,
  avoidedEmissionsUnderReferenceRegion: REDDLandProjectByYear,
  avoidedEmissionsUnderReferenceRegion5km: REDDLandProjectByYear
) {
  if (
    !avoidedEmissionsUnderProjectArea ||
    !avoidedEmissionsUnderReferenceRegion ||
    !avoidedEmissionsUnderReferenceRegion5km
  )
    return averageAvoidedEmissions
  let tableData = []

  for (var i = 0; i < averageAvoidedEmissions.length; i++) {
    const yearStats = {
      year: averageAvoidedEmissions[i].year,
      yearDate: averageAvoidedEmissions[i].yearDate,
      annualForestCoverHa: averageAvoidedEmissions[i].annualForestCoverHa,
      annualDeforestationHa: averageAvoidedEmissions[i].annualDeforestationHa,
      tCO2AnnualAvoidedEmissions: averageAvoidedEmissions[i].tCO2AnnualAvoidedEmissions,
      tCO2: averageAvoidedEmissions[i].tCO2,

      annualForestCoverHaUnderProjectAreaRate:
        avoidedEmissionsUnderProjectArea[i].annualForestCoverHa,
      annualDeforestationHaUnderProjectAreaRate:
        avoidedEmissionsUnderProjectArea[i].annualDeforestationHa,
      tCO2AnnualAvoidedEmissionsUnderProjectAreaRate:
        avoidedEmissionsUnderProjectArea[i].tCO2AnnualAvoidedEmissions,
      tCO2UnderProjectAreaRate: avoidedEmissionsUnderProjectArea[i].tCO2,

      annualForestCoverHaUnderReferenceRegionRate:
        avoidedEmissionsUnderReferenceRegion[i].annualForestCoverHa,
      annualDeforestationHaUnderReferenceRegionRate:
        avoidedEmissionsUnderReferenceRegion[i].annualDeforestationHa,
      tCO2AnnualAvoidedEmissionsUnderReferenceRegionRate:
        avoidedEmissionsUnderReferenceRegion[i].tCO2AnnualAvoidedEmissions,
      tCO2UnderReferenceRegionRate: avoidedEmissionsUnderReferenceRegion[i].tCO2,

      annualForestCoverHaUnderReferenceRegionRate5km:
        avoidedEmissionsUnderReferenceRegion5km[i].annualForestCoverHa,
      annualDeforestationHaUnderReferenceRegionRate5km:
        avoidedEmissionsUnderReferenceRegion5km[i].annualDeforestationHa,
      tCO2AnnualAvoidedEmissionsUnderReferenceRegionRate5km:
        avoidedEmissionsUnderReferenceRegion5km[i].tCO2AnnualAvoidedEmissions,
      tCO2UnderReferenceRegionRate5km: avoidedEmissionsUnderReferenceRegion5km[i].tCO2,
    }
    tableData.push(yearStats)
  }
  return tableData
}

export function getLandProjectREDDStats(landProject: LandProject): REDDLandProjectStats {
  const isValidREDDStats =
    landProject?.xStats?.snapshotMainInterventionKey === REDDComputeModelKeys.vm0007 &&
    landProject?.xStats?.forestNonForest

  const reddStats = isValidREDDStats
    ? (landProject?.xStats as REDDLandProjectStats)
    : null
  return reddStats
}

export function recomputeAvoidedEmissions(
  landProject: LandProject,
  parcels,
  referenceRegionCoordinates,
  referenceRegionCoordinates5km
) {
  const storedREDDStats = landProject.xStats
  const chosenDatasets = {
    [REDDModelsKeys.forestNonForest]:
      storedREDDStats?.forestNonForest?.computeModelInfo.dataset ||
      defaultWorldDatasets.forestNonForest,
    [REDDModelsKeys.biomass]:
      storedREDDStats?.biomass?.computeModelInfo.dataset || defaultWorldDatasets.biomass,
    [REDDModelsKeys.deforestation]:
      storedREDDStats?.deforestation?.computeModelInfo.dataset ||
      defaultWorldDatasets.deforestation,
  }
  const computeModelsInfo = Object.entries(chosenDatasets).map((chosenDataset) => {
    const computeModelInfo = {
      computeMethod: chosenDataset[0],
      dataset: chosenDataset[1],
    }
    return computeModelInfo
  })

  const data = {
    landProjectId: landProject._id,
    startYear: landProject.startYear,
    parcels,
    referenceRegionCoordinates,
    referenceRegionCoordinates5km,
    computeModelsInfo,
    snapshotMainInterventionKey: REDDComputeModelKeys.vm0007,
  }
  state.activeComputations.avoidedEmissions = true
  ESSocket.emit('computeProjectAvoidedEmissions', data)
}

export function getHistoricalTrend(landProjectStats: REDDLandProjectStats) {
  let historicalTrend = '-'

  const deforestationByYear = landProjectStats?.deforestation?.byYear
  const trendPoint =
    deforestationByYear &&
    deforestationByYear[deforestationByYear.length - 1]['trendPoint']

  if (trendPoint) {
    const last10TrendPoints = landProjectStats.deforestation.byYear
      .slice(-10)
      .map((point) => point.trendPoint)
    const trendLineSlope =
      (last10TrendPoints[last10TrendPoints.length - 1] - last10TrendPoints[0]) /
      last10TrendPoints.length

    if (trendLineSlope < 0) {
      historicalTrend = 'Decreasing'
    } else if (trendLineSlope > 0.3) {
      // this number for classifying increase/neutral is arbitrary right now. ask Dani about it
      historicalTrend = 'Increasing'
    } else {
      historicalTrend = 'Neutral'
    }
  }
  return historicalTrend
}

export function TrendCell(props) {
  const { trend } = props
  const iconAttributes = {
    increasing: { icon: 'trending_up', className: 'text-green-500 mr-8' },
    decreasing: { icon: 'trending_down', className: 'text-red-500 mr-8' },
    neutral: { icon: 'trending_flat', className: 'text-ink-500 mr-8' },
  }

  return (
    <Row>
      <Icon {...(iconAttributes[trend?.toLowerCase()] || '-')} />
      {trend}
    </Row>
  )
}
