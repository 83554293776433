import React, { Fragment, useEffect, useState } from 'react'
import { Numerical } from '~/components/core/Numerical'
import { WorkInProgressBanner } from '~/components/core/WorkInProgressBanner'
import { optimalFinancialModel_experimental } from '~/data/financials/optimalFinancialModel_experimental'
import { calculateLandProjectVCUS } from '~/data/financials/vcus'
import { getProjectFinancialVariables } from '~/routes/project/financials/financialsStore'

import { useActiveLandProject } from '~/state'
import { Column, Row } from '~/styles'

export function OptimalFinancialModel() {
  const { landProject } = useActiveLandProject()
  const [data, setData] = useState<any>()

  useEffect(() => {
    const financialVariables = getProjectFinancialVariables(landProject)

    const vcus = calculateLandProjectVCUS(landProject)
    setData({
      ...optimalFinancialModel_experimental({
        vcus,
        projectType: landProject.projectType,
        financialVariables,
      }),
      vcus,
    })
  }, [])

  return (
    <div className='p-80 '>
      <WorkInProgressBanner projectType={landProject.projectType} />
      <Row className='gap-x-20'>
        <Column className='mr-80'>
          <strong className='text-right'>VCUs:</strong>
          <Column>
            {data?.vcus.map((d, key) => (
              <Numerical
                type='currency'
                key={key}
                value={d.issued}
                className='text-right'
              />
            ))}
          </Column>
        </Column>

        <div className='grid grid-cols-2 gap-y-20 gap-x-12 flex-shrink-0 self-start text-right place-items-start'>
          <strong>IRR:</strong> <Numerical value={data?.irr} type='percent' />
          <strong>Investment:</strong>{' '}
          <Numerical value={data?.investment} type='currency' />
          <strong>Investor Credit Share:</strong>{' '}
          <Numerical value={data?.investorCreditShare} type='percent' decimals={2} />
          <strong>Yearly Average cost:</strong>{' '}
          <Numerical value={data?.cost} type='currency' />
        </div>

        <div className='grid grid-cols-3 place-items-end gap-x-8 flex-shrink-0'>
          <strong>Year</strong>
          <strong>Cost</strong>
          <strong>Balance</strong>

          {data?.projectCashflow?.data.map((d, index) => (
            <Fragment key={index}>
              <Numerical value={index} />
              <Numerical value={d.cost} type='currency' />
              <Numerical value={d.balance} type='currency' />
            </Fragment>
          ))}
        </div>
      </Row>
    </div>
  )
}
