// import { w3cwebsocket as W3CWebSocket } from "websocket";

let _socketClient = null
const _callbacksByRoute = {}
const _auth = {
  user_id: '',
  session_id: '',
}
let _queuedEmits = []
let _connected = false
let _url = ''
// let _connecting = false;
let _timeoutReconnect = null
let _connectingTimeStart = null

function connect(url) {
  // _connecting = true;
  // _socketClient = new W3CWebSocket(url);
  _socketClient = new WebSocket(url)
  _url = url

  _socketClient.onopen = () => {
    console.log('websocket connected', url)
    // _connecting = false;
    _connected = true
    emitQueue()
  }
  _socketClient.onmessage = (message) => {
    handleMessage(message)
  }
  _socketClient.onclose = () => {
    console.log('websocket disconnected', url)
    disconnect()
    _connected = false
    reconnectLoop()
  }
  _socketClient.onerror = (evt) => {
    console.log('websocket error', evt)
  }
}

function reconnectLoop() {
  const readyState = _socketClient !== null ? _socketClient.readyState : -1
  console.log('reconnectLoop', readyState)
  // if (readyState >= 0 && readyState < 3) {
  //   disconnect();
  // }
  if (_timeoutReconnect) {
    clearTimeout(_timeoutReconnect)
  }
  _timeoutReconnect = setTimeout(() => {
    if (!_connected && _url) {
      if (readyState === 3) {
        _connectingTimeStart = new Date().getTime()
        console.log('trying to connect', readyState)
        connect(_url)
        // } else if (readyState >= 0 && _connectingTimeStart && (new Date().getTime() - _connectingTimeStart > 5000)) {
        //   console.log('disconnecting');
        //   disconnect();
        //   _socketClient = null;
      }
      reconnectLoop()
    }
  }, 1000)
}

function handleMessage(message) {
  message.data.text().then((resString) => {
    const res = JSON.parse(resString)
    if (res['route'] && _callbacksByRoute[res['route']]) {
      for (const id in _callbacksByRoute[res['route']]) {
        _callbacksByRoute[res['route']][id]['callback'](res)
      }
    }
  })
}

function disconnect() {
  _socketClient.close()
}

function emitQueue() {
  for (let ii = 0; ii < _queuedEmits.length; ii++) {
    const emitInfo = _queuedEmits[ii]
    emit(emitInfo.route, emitInfo.data)
  }
  _queuedEmits = []
}

function emit(route, data) {
  if (!_connected) {
    _queuedEmits.push({ route, data })
  } else {
    const message = JSON.stringify({
      route: route,
      auth: _auth,
      data: data,
    })
    _socketClient.send(message)
  }
}

function onRoute(route, callback) {
  if (!_callbacksByRoute[route]) {
    _callbacksByRoute[route] = {}
  }
  const id = Math.random().toString(36).substring(7)
  _callbacksByRoute[route][id] = {
    callback: callback,
  }
  return id
}

function offRoute(route, id) {
  if (_callbacksByRoute[route]) {
    delete _callbacksByRoute[route]
  }
}

function offRouteIds(routeIds) {
  for (let ii = 0; ii < routeIds.length; ii++) {
    const routeId = routeIds[ii]
    for (const route in _callbacksByRoute) {
      let found = false
      for (const id in _callbacksByRoute[route]) {
        if (id === routeId) {
          delete _callbacksByRoute[route].id
          found = true
          break
        }
      }
      if (found) {
        break
      }
    }
  }
}

function setAuth(userId, sessionId) {
  _auth['user_id'] = userId
  _auth['session_id'] = sessionId
}

const ESSocket = {
  connect,
  disconnect,
  emit,
  onRoute,
  offRoute,
  offRouteIds,
  setAuth,
}
export default ESSocket
