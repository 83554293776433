import { AnySourceData } from 'mapbox-gl'
import React from 'react'
import { Layer, Source } from 'react-map-gl'

type Props = {
  layers: {
    id: string
    label: string
    visible: boolean
    metadataUrl?: string
    source: AnySourceData
    layer: any
  }[]
}

export function RasterLayers(props: Props) {
  const { layers } = props

  return (
    <>
      <Layer
        id='base'
        source='composite'
        type='background'
        paint={{ 'background-opacity': 0 }}
      />

      {layers?.map((layer) => {
        return (
          <React.Fragment key={layer.id}>
            <Source id={layer.id} {...layer.source} />

            <Layer
              beforeId='base'
              source={layer.id}
              layout={{ visibility: layer.visible ? 'visible' : 'none' }}
              {...layer.layer}
            />
          </React.Fragment>
        )
      })}
    </>
  )
}
