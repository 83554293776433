import { useEffect, useRef } from 'react'
import { BaseMap } from '~/components/map/BaseMap'
import { MaskedCountryFillLayer } from '~/components/map/layers/CountriesBounds'
import { MapSources } from '~/components/map/layers/MapSources'
import { getLandProjectCountry } from '~/models/landProject'
import { SlideShow } from '~/routes/project/reports/SlideShow'
import {
  fitMap,
  landProjectParcelsToMapLayer,
  referenceRegionToMapLayer,
  useMapLayers,
} from '~/services/mapbox'
import { useActiveLandProject } from '~/state'

import * as turf from '@turf/turf'
import { MapRef } from 'react-map-gl'
import { StaticTable } from '~/components/atomic/table/StaticTable'
import { Numerical } from '~/components/core/Numerical'
import { GradientLegend, MapLegend } from '~/components/map/MapControls'
import { RasterLayers } from '~/components/map/layers/RasterLayers'
import { WaterLayer } from '~/components/map/layers/WaterLayer'
import { H2, Title } from '~/components/profile/shared'
import { AvoidedEmissionsGraph } from '~/components/reports/AvoidedEmissionsGraph'
import { DeforestationGraph } from '~/components/reports/DeforestationGraph'
import { StatsSection } from '~/components/reports/StatsSection'
import { getLandProjectArea } from '~/models/landProject'
import {
  estimatesExplanationMap,
  getLandProjectREDDStats,
  parseREDDStatsToSectionsData,
} from '~/models/landProjectReddStats'
import {
  useFinancialsStore,
  useInitFinancialStore,
} from '~/routes/project/financials/financialsStore'
import {
  fetchAndStoreRasterMapLayers,
  makeLayerSoloVisible,
  useLandStore,
} from '~/routes/project/land/landStore'
import { getCountryCode3ByName, getCountryGeometryByCode3 } from '~/services/countries'
import { Column, Row } from '~/styles'
import { defaultBackgroundImage } from '~/utils/constants'

import { ProjectPolygonAreaLayers } from '~/routes/project/reports/components/ProjectPolygonAreaLayers'
import { RecommendationsSlide } from '~/routes/project/reports/components/RecommendationsSlide'
import { ReferenceRegionPolygonLayers } from '~/routes/project/reports/components/ReferenceRegionPolygonLayers'
import { Slide } from '~/routes/project/reports/components/Slide'
import { getDiscounts } from '~/routes/project/vcus/components/DiscountsSection'
import { VCUsREDDTable } from '~/routes/project/vcus/components/VCUsREDDTable'
import {
  getFeatureCollectionFromParcels,
  getFeatureCollectionFromReferenceRegionCoordinates,
} from '~/utils/geo'
import { Citation } from './components/shared'
import { calculateLandProjectVCUStats } from '~/data/financials/vcus'
import { CreditIssuanceBarGraph } from '~/components/graphs/CreditIssuanceBarGraph'
import { capitalizeFirstLetter } from '~/utils'

export function REDDSlideShowReport() {
  const { landProject, parcels, referenceRegionCoordinates } = useActiveLandProject()
  const countryName = getLandProjectCountry(landProject)
  const countryCode3 = getCountryCode3ByName(countryName)
  const countryGeometry = getCountryGeometryByCode3(countryCode3)

  const projectArea = getLandProjectArea(landProject)
  const reddStats = getLandProjectREDDStats(landProject)

  const biomassDataset = reddStats?.biomass?.computeModelInfo?.dataset
  const biomassDatasetLabel = capitalizeFirstLetter(biomassDataset?.split('_')?.[1])
  const sectionsData = parseREDDStatsToSectionsData(reddStats)

  const landCoverDataset = reddStats?.forestNonForest?.computeModelInfo?.dataset
  const landCoverDatasetLabel = capitalizeFirstLetter(landCoverDataset?.split('_')?.[1])

  const projetPolygonFeatureCollection = getFeatureCollectionFromParcels(parcels)

  const referenceRegionFeatureCollection =
    getFeatureCollectionFromReferenceRegionCoordinates(referenceRegionCoordinates)

  const buffered = turf.buffer(projetPolygonFeatureCollection, 20, { units: 'miles' })

  useEffect(() => {
    const rasterLayersToFetch = [
      { datasetId: biomassDataset as any },
      { datasetId: landCoverDataset as any },
    ]
    fetchAndStoreRasterMapLayers(rasterLayersToFetch)
  }, [])

  function onShowSideMapFit(map, layers) {
    fitMap(map, referenceRegionFeatureCollection, {
      padding: { left: 400, right: 140, bottom: 140, top: 140 },
    })
  }

  function onShowPaddedFit(map, layers) {
    fitMap(map, buffered, {
      padding: { left: 400, right: 140, bottom: 140, top: 140 },
    })
  }

  const slides = [
    {
      slide: MapSlide,
      slideOverlay: Cover,
      onShow: (map) => {
        fitMap(map, countryGeometry, { padding: 200, duration: 0 })
        setTimeout(() => {
          fitMap(map, countryGeometry)
        }, 20)
      },
    },
    {
      slide: MapSlide,
      title: `Global context`,
      content: <div>Country: {countryName}</div>,
      onShow: (map) => {
        fitMap(map, countryGeometry)
      },
    },
    {
      slide: MapSlide,
      title: 'Project context',
      content: (
        <div>
          <Row className='row-vcenter'>
            <div className='h-12 w-12 rounded-sm bg-yellow-500 mr-8' />
            Project Polygon area:{' '}
            <Numerical value={projectArea} type='area' className=' ml-4' />
          </Row>

          <Row className='row-vcenter'>
            <div className='h-12 w-12 rounded-sm border border-dashed border-orange-500 mr-8' />
            Reference Region (20km buffer)
          </Row>
        </div>
      ),
      onShow: onShowSideMapFit,
    },

    {
      slide: MapSlide,
      title: 'Concessions',
      layerId: ['gfw_mining_concessions', 'gfw_managed_forests', 'gfw_oil_palm'],
      onShow: onShowPaddedFit,
    },
    {
      slide: MapSlide,
      title: 'Protected areas',
      layerId: 'wdpa_protected_areas',
      onShow: onShowPaddedFit,
    },
    {
      slide: MapSlide,
      title: 'Population Density',
      layerId: 'global_population_jrc',
      onShow: onShowPaddedFit,
    },

    {
      slide: MapSlide,
      title: 'Forest Cover',
      layerId: 'tree_cover',
      layers: {
        projectArea: { paint: { 'fill-opacity': 0 } },
      },
      content: <StatsSection {...sectionsData.forestCover} />,
      onShow: onShowSideMapFit,
    },
    {
      slide: MapSlide,
      title: 'Biomass',
      content: (
        <>
          <StatsSection
            {...{
              ...sectionsData.biomassEstimates,
              title: `Average Biomass estimates* from forest cover within project area`,
            }}
          />
          <Citation className='mt-12'>*Source: {biomassDatasetLabel}</Citation>
        </>
      ),
      layerId: biomassDataset,
      onShow: onShowSideMapFit,
    },
    {
      slide: MapSlide,
      title: `Land Use (${landCoverDatasetLabel})`,
      layerId: landCoverDataset,
      layers: {
        projectArea: { paint: { 'fill-opacity': 0 } },
      },
      onShow: onShowSideMapFit,
    },
    {
      slide: MapSlide,
      title: 'Forest Loss Progression (Hansen)',
      layerId: 'forestLoss_progression',
      layers: {
        countriesBounds: { layout: { visibility: 'none' } },
        satellite: { paint: { 'raster-saturation': -1 } },
        projectArea: { paint: { 'fill-opacity': 0 } },
      },
      onShow: onShowSideMapFit,
    },
    {
      slide: MapSlide,
      title: 'Historical Deforestation in project area',
      layerId: 'forestLoss_progression',
      hideLegend: true,
      onShow: (map) => {
        fitMap(map, projetPolygonFeatureCollection, { padding: 100 })
      },
      layers: {
        countriesBounds: { layout: { visibility: 'none' } },
        satellite: { paint: { 'raster-saturation': -1 } },
        projectArea: { paint: { 'fill-opacity': 0 } },
        projectAreaLine: {
          paint: { 'line-color': 'white', 'line-opacity': 1, 'line-width': 4 },
        },
      },
      rightContent: (
        <>
          <StatsSection {...sectionsData?.deforestation} />

          <DeforestationGraph sectionData={sectionsData?.deforestation} />
        </>
      ),
    },
    {
      slide: MapSlide,
      title: 'Deforestation in Reference Region',
      layerId: 'forestLoss_progression',
      hideLegend: true,
      onShow: (map) => {
        fitMap(map, referenceRegionFeatureCollection, { padding: 100 })
      },
      layers: {
        countriesBounds: { layout: { visibility: 'none' } },
        satellite: { paint: { 'raster-saturation': -1 } },
        projectArea: { paint: { 'fill-opacity': 0 } },
        projectAreaLine: { paint: { 'line-color': 'white', 'line-opacity': 1 } },
        referenceAreaLine: {
          paint: { 'line-color': 'white', 'line-width': 4, 'line-dasharray': [1, 0] },
        },
      },
      rightContent: (
        <>
          <StatsSection {...sectionsData.referenceRegionBaselines} />
          <StatsSection {...sectionsData.referenceRegionBaselines5km} />
        </>
      ),
    },
    {
      slide: MapSlide,
      slideOverlay: ExAnte,
    },
    {
      slide: MapSlide,
      slideOverlay: VCUsSlide,
    },
    {
      slide: MapSlide,
      slideOverlay: VCUsTableSlide,
    },
  ]

  const hasRecommendations = landProject.data?.['scoping-recommendations']?.value
  if (hasRecommendations) {
    slides.push({ slide: MapSlide, slideOverlay: RecommendationsSlide })
  }

  return <SlideShow initialSlide={0} slides={slides} />
}

function Cover() {
  const { landProject } = useActiveLandProject()
  return (
    <div className='w-full h-full center'>
      <img
        alt='Background'
        className='absolute inset-0 h-full w-full bg-green-100 object-cover'
        src={defaultBackgroundImage}
      />
      <Column className='relative center z-10'>
        <div className='font-space-grotesk text-[72px] font-bold text-white leading-none'>
          {landProject.name}
        </div>
        <div className='font-space-grotesk text-40 font-bold text-white mt-60'>
          REDD+ Scoping Report
        </div>
      </Column>
    </div>
  )
}

function MapSlide(props) {
  const { currentSlide, slidesData } = props
  const mapRef = useRef<MapRef>()
  const { landProject, parcels, referenceRegionCoordinates } = useActiveLandProject()

  const landStoreSnap = useLandStore()

  const countryName = getLandProjectCountry(landProject)
  const countryCode3 = getCountryCode3ByName(countryName)
  const countryGeometry = getCountryGeometryByCode3(countryCode3)

  const currentSlideData = slidesData[currentSlide]
  const SlideOverlay = currentSlideData?.slideOverlay

  const projetPolygonFeatureCollection = getFeatureCollectionFromParcels(parcels)

  const referenceRegionFeatureCollection =
    getFeatureCollectionFromReferenceRegionCoordinates(referenceRegionCoordinates)

  const initialLayers = []
  if (parcels?.length)
    initialLayers.push({ ...landProjectParcelsToMapLayer(parcels), theme: 'white' })
  if (referenceRegionCoordinates?.length)
    initialLayers.push(referenceRegionToMapLayer(referenceRegionCoordinates))

  const { layers } = useMapLayers(initialLayers)

  const currentLayerId = currentSlideData?.layerId
  const currentLayer = landStoreSnap.mapLayers.find((i) => i.id === currentLayerId)

  const center = turf.centroid(countryGeometry.geometry)
  const coordinates = center.geometry.coordinates

  const initialViewState = {
    latitude: coordinates[1],
    zoom: currentSlide === 1 ? 4 : 8,
  }

  useEffect(() => {
    // Layer Visibility Controls

    function func(map) {
      // Alwayas resize map on slide change
      makeLayerSoloVisible(currentLayer)
      map.resize()

      // on Show custom logic per slide
      if (currentSlideData.onShow) {
        currentSlideData.onShow(map, layers)
      }
    }

    if (mapRef.current) func(mapRef.current)
    else setTimeout(() => func(mapRef.current), 100)
  }, [currentSlide])

  return (
    <>
      {SlideOverlay && (
        <Column className='absolute inset-0 z-10 bg-white flex-1 center'>
          <SlideOverlay />
        </Column>
      )}
      <Column className='absolute max-w-[320px] left-24 top-24 z-10 space-y-8'>
        {currentSlideData?.title && (
          <SlideShow.MapPanel>
            <div className='text-18 font-semibold'>{currentSlideData.title}</div>
          </SlideShow.MapPanel>
        )}
        {currentSlideData?.content && (
          <SlideShow.MapPanel>{currentSlideData.content}</SlideShow.MapPanel>
        )}
        {currentLayer?.legend && !currentSlideData.hideLegend && (
          <SlideShow.MapPanel>
            <div className='text-12 font-bold uppercase tracking-wide text-ink-400 mb-8'>
              Legend:
            </div>
            {currentLayer.legend.type === 'discrete' && (
              <MapLegend layer={currentLayer} />
            )}
            {currentLayer.legend.type === 'gradient' && (
              <GradientLegend layer={currentLayer} />
            )}
          </SlideShow.MapPanel>
        )}
      </Column>
      <Row className='w-full h-full'>
        <BaseMap
          ref={mapRef}
          initialCountryCode3={countryCode3}
          interactive={false}
          initialViewState={initialViewState}
          maxZoom={12}
        >
          <MapSources composite countryBoundaries />
          <MapSources.Satellite {...currentSlideData?.layers?.satellite} />
          <WaterLayer fill='rgba(0,0,0,0.8)' />
          <RasterLayers layers={landStoreSnap.mapLayers} />

          <MaskedCountryFillLayer code3={countryCode3} color='rgba(0,0,0,0.5)' />

          <ReferenceRegionPolygonLayers
            featureCollection={referenceRegionFeatureCollection as any}
            fillPaint={currentSlideData?.layers?.referenceArea?.paint}
            linePaint={currentSlideData?.layers?.referenceAreaLine?.paint}
          />

          <ProjectPolygonAreaLayers
            featureCollection={projetPolygonFeatureCollection}
            fillPaint={currentSlideData?.layers?.projectArea?.paint}
            linePaint={currentSlideData?.layers?.projectAreaLine?.paint}
          />
        </BaseMap>

        {currentSlideData?.rightContent && (
          <Column className='h-full p-20 w-1/2 flex-shrink-0 space-y-20'>
            {currentSlideData.rightContent}
          </Column>
        )}
      </Row>
    </>
  )
}

function ExAnte() {
  const { landProject } = useActiveLandProject()
  const reddStats = getLandProjectREDDStats(landProject)
  const sectionsData = parseREDDStatsToSectionsData(reddStats)

  return (
    <Slide className=''>
      <Slide.Title>Avoided CO2 emissions from prevented deforestation:</Slide.Title>

      <Column className='items-start'>
        <StaticTable
          headers={['', 'avg annual', 'Total avoided']}
          rows={[
            [
              'under 20km reference region deforestation rate',
              sectionsData.avoidedEmissionsUnderReferenceRegionRate?.stats?.[0].value,
              sectionsData.avoidedEmissionsUnderReferenceRegionRate?.stats?.[1].value,
            ],
            [
              'under 5km reference region deforestation rate',
              sectionsData.avoidedEmissionsUnderReferenceRegionRate5kmBuffer?.stats?.[0]
                .value,
              sectionsData.avoidedEmissionsUnderReferenceRegionRate5kmBuffer?.stats?.[1]
                .value,
            ],
            [
              'under project area deforestation rate',
              sectionsData.avoidedEmissionsUnderProjectAreaRate?.stats?.[0].value,
              sectionsData.avoidedEmissionsUnderProjectAreaRate?.stats?.[1].value,
            ],
            [
              'under average deforestation rate',
              sectionsData.avoidedEmissions?.stats?.[0].value,
              sectionsData.avoidedEmissions?.stats?.[1].value,
            ],
          ]}
        />
      </Column>
      <AvoidedEmissionsGraph
        sectionData={sectionsData.avoidedEmissions}
        withTitle={false}
        className='flex-1'
      />
    </Slide>
  )
}

function VCUsSlide() {
  useInitFinancialStore()
  const { landProject } = useActiveLandProject()
  const { vcus, variables } = useFinancialsStore()

  const discounts = getDiscounts(variables, 'redd')
  const vcusStats = calculateLandProjectVCUStats(landProject, vcus)

  return (
    <Slide className=''>
      <Slide.Title>
        VCUs based on {estimatesExplanationMap[landProject.chosenREDDProjectionKey]}
      </Slide.Title>
      <Row className='gap-x-80 gap-y-80 mt-40 text-14'>
        {vcusStats.map((item) => (
          <Column className='flex-wrap'>
            <Title className='mb-20'>{item.label}</Title>
            <Numerical
              value={item.value}
              unitRight={item.unitRight}
              decimals={item.decimals}
              valueClassName='font-bold text-40 tracking-tighter'
            />
          </Column>
        ))}
      </Row>

      <Row className='mt-80 gap-x-[200px]'>
        <Column className='shrink-0'>
          <H2>Discounts</H2>
          {discounts.map((discount) => (
            <Row className='row-vcenter'>
              <Title className='mr-8 mb-0'>{discount.label}: </Title>
              <Numerical
                value={discount.value * 100}
                unitRight={'%'}
                valueClassName='font-bold text-20 tracking-tighter'
              />
            </Row>
          ))}
        </Column>

        <CreditIssuanceBarGraph />
      </Row>
    </Slide>
  )
}

function VCUsTableSlide() {
  return (
    <Slide className='h-full'>
      <Slide.Title className='!mb-16'>
        VCUs Table based on Project area and reference region average
      </Slide.Title>
      <VCUsREDDTable withColumnVisiblity={false} />
    </Slide>
  )
}
