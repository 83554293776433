import { Stat } from '~/components/core/Stat'
import { Row } from '~/styles'
import classed from '~/styles/classed'
import { ResponsiveRow } from '~/styles/main'

const h3Text = 'text-16 font-bold tracking-[-0.01em]'

export const P = classed('p', 'text-16 leading-[170%] tracking-[0.01em] text-ink-700')
export const SmallP = classed('p', 'text-14 leading-[160%] text-ink-600')
export const Title = classed('div', 'text-16 font-semibold mb-8')

export const H2 = classed('h2', 'text-24 tracking-[-0.01em] mt-40 mb-16')
export const H3 = classed('h2', `${h3Text} mb-8`)

export const SectionHeading = classed(
  'h2',
  'text-18 uppercase font-bold tracking-widest mt-40 mb-16'
)

export const Heading = classed('h3', 'text-[22px] font-bold font-space-grotesk mb-24')

export const WideSection = classed(
  'section',
  'flex w-full max-w-[1200px] px-20 mb-60 last:mb-0'
)
export const TextSection = classed(
  'section',
  'w-full max-w-[1020px] px-20 mb-60 last:mb-0 self-center'
)
export const FullBleedSection = classed(
  'section',
  'w-full  center dark bg-[#242B29] text-white py-60 mb-60'
)

export const Section = classed(
  'section',
  'flex flex-col w-full px-20 sm:px-48 pt-32 pb-40'
)

export const Label = classed('div', 'text-16 opacity-60')

export function VerticalStatsRow({ stats }) {
  return (
    <ResponsiveRow className='space-y-24 sm:space-y-0 sm:space-x-48'>
      {stats?.map((stat, key) => (
        <Stat.Vertical key={key} {...stat} />
      ))}
    </ResponsiveRow>
  )
}

export function HorizontalFact(props) {
  const { label, value } = props
  return (
    <Row className='row-vecenter my-8 text-15'>
      <div className={`${h3Text} mr-12 shrink-0`}>{label}</div>
      {value && <div>{value}</div>}
    </Row>
  )
}

export const PanoImage = classed('img', 'w-full max-h-[50vh] object-cover bg-center')
