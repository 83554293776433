import { Intervention } from '~/models'
import { Coordinates } from '~/types'

const defaults: Partial<Parcel> = {
  name: '',
  uName: '',
  userIdOwner: '',
  projectId: '',
  coordinates: [],
  interventions: [],
  costs: {
    landAcquisitionPerHa: 2000,
    landLeasePerHa: 200,
    landYears: 5,
    communityBenefitPercent: 10,
  },
  xStats: {
    areaHa: 0,
    byYear: [],
  },
}

export type ParcelXStats = {
  areaHa: 0
  byYear: {
    costs: {
      communityBenefit: number
      land: number
      total: number
    }
    revenue: number
    tCO2: number
    year: number
  }[]
}

export type ParcelStats = {
  parcelId?: string
  landProjectId?: string
  interventions: Intervention[]
  created_at: string
} & ParcelXStats

export class Parcel {
  _id: string
  created_at: string
  updated_at: string
  uName: string
  name: string
  projectId: string
  userIdOwner: string
  landProjectId: string
  coordinates: Coordinates
  type: string
  interventions: Intervention[]
  costs: {
    landAcquisitionPerHa: number
    landLeasePerHa: number
    landYears: number
    communityBenefitPercent: number
  }
  xStats: ParcelXStats

  constructor(data: Partial<Parcel>) {
    const parcel = { ...defaults, ...data }

    if (!parcel.interventions || parcel.interventions.length == 0) {
      parcel.interventions = [new Intervention({ coordinates: parcel.coordinates })]
    }

    return Object.assign(this, parcel)
  }
}

export function buildParcelsWithStats(parcels: Parcel[], parcelStats: ParcelStats[]) {
  return parcels.map((parcel) => {
    const stats = parcelStats.find((ps) => ps.parcelId === parcel._id)
    return {
      ...parcel,
      xStats: stats,
    }
  })
}

export class Bounds {
  min: [number, number]
  max: [number, number]
}
