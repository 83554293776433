import { cn } from '~/utils/cn'

import { ProjectTypeBadge } from '~/components/core/ProjectTypeBadge'
import { StatItems, StatValues } from '~/components/core/Stat'
import { LandProject } from '~/models'
import { calculateCarbonStats, getLandMeta } from '~/models/landProject'
import { Column, Row } from '~/styles'
import { cardClasses, cardHoverClasses } from '~/styles/main'
import { formatArea, formatNumber } from '~/utils/units'

const DEFAULT_COVER_URL =
  'https://images.unsplash.com/photo-1583470790878-4f4f3811a01f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2476&q=90'

type Props = {
  landProject: LandProject
  noStats?: boolean
}

export function ProjectCard(props: Props) {
  const { landProject, noStats } = props

  const { name, country, area } = getLandMeta(landProject)

  const cover = getFeaturedImageOrFirst(landProject)

  const { totalProduction30yrs } = calculateCarbonStats(landProject)

  return (
    <div
      className={cn(
        cardClasses,
        cardHoverClasses,
        'md:grid min-h-[180px] w-full flex flex-col md:grid-cols-[200px,1fr] items-stretch overflow-hidden',
        noStats && 'min-h-[100px] '
      )}
    >
      <Column className='w-full md:w-[200px] h-full bg-green-100'>
        <img src={cover} className='w-full  flex-1 object-cover' alt='cover' />
      </Column>

      <Column className='flex-1 overflow-hidden p-16 pl-20'>
        <Row className='mb-4 justify-between'>
          <span className='text-16 text-ink-600'>{country}</span>
          <ProjectTypeBadge projectType={landProject.projectType} />
        </Row>

        <div className='mb-12 w-full font-space-grotesk text-20 font-bold leading-[1.2] line-clamp-2'>
          {name}
        </div>
        {!noStats && (
          <>
            <div className='flex-1' />

            <Row className='space-x-20'>
              <CardStat label='Area' value={formatArea(area, false)} unit='ha' />
              <CardStat
                label='Total Carbon'
                value={formatNumber(totalProduction30yrs)}
                unit='tCO2eq'
              />
            </Row>
          </>
        )}
      </Column>
    </div>
  )
}

function CardStat(props: StatValues) {
  return (
    <Column>
      <StatItems labelClasses='!text-16' valueClasses='text-20' {...props} />
    </Column>
  )
}

function getFeaturedImageOrFirst(landProject: LandProject) {
  const images = landProject?.images
  if (!images?.length) return DEFAULT_COVER_URL

  const featuredImage = images.find((i) => i.tag === 'Featured')

  return featuredImage?.url || images[0].url
}
