import { cn } from '~/utils/cn'
import React from 'react'
import { PropsWithChildren } from 'react'
import { Link } from 'react-router-dom'
import Icon from '~/components/atomic/Icon'
import { Loader } from '~/components/atomic/Loader'

export type ButtonVariants =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'destructive'
  | 'disabled'
  | 'success'
  | 'secondary-square'

export type Props = PropsWithChildren<{
  className?: string
  primary?: boolean
  secondary?: boolean
  onClick?: (event) => void
  type?: 'submit'
  variant?: ButtonVariants

  to?: string
  state?: any // TODO

  href?: string
  openInNewTab?: boolean
  download?: string
  title?: string

  icon?: any
  iconRight?: any
  iconSize?: number
  iconClasses?: string

  disabled?: boolean
  autoFocus?: boolean

  submitting?: boolean
  loaderColor?: string
}>

type Ref = HTMLButtonElement
export const Button = React.forwardRef<Ref, Props>((props: Props, ref: any) => {
  const {
    className,
    to,
    href,
    openInNewTab,
    download,
    submitting,
    children,
    icon,
    iconSize = 18,
    iconClasses,
    iconRight,
    variant = 'secondary',
    loaderColor,
    ...rest
  } = props

  const loadColorWithVariant =
    loaderColor || props.variant === 'secondary' ? 'black' : 'white'

  const classes = cn(
    'row row-vcenter focus:outline-none',
    variants[variant],
    { 'pointer-events-none select-none': submitting },
    className
  )

  const content = (
    <>
      {icon && (
        <Icon
          icon={icon}
          size={iconSize}
          className={`${children && `mr-6`} opacity-50 ${iconClasses}`}
        />
      )}
      {typeof children == 'string' ? <div>{children}</div> : children}
      {submitting && (
        <Loader
          color={loadColorWithVariant}
          bg={loadColorWithVariant}
          size={14}
          className='ml-8'
        />
      )}
      {iconRight && (
        <Icon
          icon={iconRight}
          size={iconSize}
          className={`${children && `ml-6`} opacity-50 ${iconClasses}`}
        />
      )}
    </>
  )

  if (href) {
    const anchorProps = openInNewTab ? { href, target: '_blank' } : { href }
    return (
      <a ref={ref} {...anchorProps} className={classes} {...rest} download={download}>
        {content}
      </a>
    )
  } else if (to || href) {
    return (
      <Link ref={ref} to={to} className={classes} {...rest}>
        {content}
      </Link>
    )
  } else {
    return (
      <button ref={ref} className={classes} {...rest}>
        {content}
      </button>
    )
  }
})

const base = 'font-medium sm:w-fit rounded-md text-14 px-12 py-8'

const variants = {
  default: `${base}`,
  primaryBig: `${base} !px-20 !py-16 !text-15 font-bold bg-brand text-white hover:bg-brand-hover`,
  primary: `${base} bg-brand text-white hover:bg-brand-hover border border-black/10`,
  secondary: `${base} bg-ink-900 bg-opacity-10 hover:bg-opacity-20`,
  destructive: `${base} bg-red-500 text-white hover:bg-red-600`,
  disabled: `${base} opacity-75`,
  success: `${base} bg-green-100 text-green-900 pointer-events-none select-none`,
  'secondary-square': `font-medium px-8 py-4 shadow rounded-md ring-1 ring-ink-200 hover:bg-ink-100 hover:shadow-sm`,
}
