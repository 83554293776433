import { useSnapshot } from 'valtio'
import { fetchDB } from '~/services/db'
import { state } from '~/state'
import { Column } from '~/styles'
import { getFormData } from '~/utils'
import { Button } from '~/components/atomic/Button'
import Input from '~/components/atomic/Input'
import Select from '~/components/atomic/Select'
import { toast } from '~/services/toaster'
import { invitePermissionOptions } from '~/utils/constants'

type Invite = {
  email: string
  url: string
}

type FormData = {
  emailOrDomain: string
  permission: string
}

export function ShareProjectInvite(props) {
  const { landProjectUsers, onInviteSuccess, onLandProjectUserRemoved } = props
  const snap = useSnapshot(state)
  const authedUser = snap.authedUser
  const landProjectId = snap.activeLandProjectId

  async function onInvite(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    const newInvite = getFormData<FormData>(e.currentTarget)
    const emailOrDomain = newInvite.emailOrDomain.trim()

    if (!emailOrDomain.includes('@')) return toast.warning('Please enter a valid email')

    const newLandProjectUser = {
      landProjectId,
      permissions: [newInvite.permission],
    }

    const data = {
      emailOrDomain: emailOrDomain,
      landProjectUser: newLandProjectUser,
      authUserId: authedUser._id,
      skipPermissions: 1,
    }

    e.currentTarget.reset()
    const res = await fetchDB('saveLandProjectUser', data)
    if (res.valid) {
      onInviteSuccess(res.landProjectUser)
    } else {
      return toast.warning(res.msg)
    }
  }

  return (
    <Column className='space-y-20'>
      <InviteForm onInvite={onInvite} />
    </Column>
  )
}

function InviteForm(props) {
  const { onInvite } = props

  return (
    <form onSubmit={onInvite} className='row space-x-6'>
      <Input
        name='emailOrDomain'
        required
        placeholder='Type a single email or domain'
        className='flex-[4]'
      />
      <Select
        name='permission'
        options={invitePermissionOptions}
        required
        defaultValue={'proponent'}
        className='flex-[2]'
      />
      <Button className='center flex-1' variant={'primary'} type={'submit'}>
        Invite
      </Button>
    </form>
  )
}
