import {
  CheckboxGroup,
  Props as CheckboxGroupProps,
} from '~/components/atomic/CheckboxGroup'

export default function RolesCheckboxGroup(props: CheckboxGroupProps) {
  // TODO: Create User Enums/Consts
  const roles = [
    { label: 'Investor', value: 'investor' },
    { label: 'Proponent', value: 'proponent' },
  ]

  return (
    <CheckboxGroup
      options={roles}
      name='Choose a role...'
      defaultValue={props.defaultValue}
      onClickOption={props.onClickOption}
    />
  )
}
