import { forms } from '~/data/forms'
import { LandProject } from '~/models'
import { DatumField, getScoreForOption, ProjectDatum } from '~/models/projectDatum'
import { getCountryGovernanceScore } from '~/services/countries'
import { DEFAULT_PROJET_PERIOD } from '~/utils/constants'

type Datums = {
  [key: string]: DatumField
}

export function assessPermanence(landProject: LandProject) {
  const permanenceForm = forms.find((f) => f.id === 'permanence')
  const datums: Datums = {}

  const issues = []

  permanenceForm.fields
    .filter((f) => f.id)
    .forEach((field) => {
      const datum = landProject.data[field.name] as ProjectDatum

      if (datum) {
        datums[field.name] = {
          ...field,
          ...datum,
        }
      }
    })

  const internalRiskPctBiomassScore =
    datums['internal-risk-pct-biomass-non-native']?.value > 25 ? 2 : 0

  const projectManagementRiskScore =
    getScoreForOptions(
      [
        'internal-risk-ongoing-enforcement-prevent-encroachment',
        'management-team-individuals-significant-experience',
        'management-team-presence-in-country',
        'management-team-located-more-thann-day-of-travel',
        'management-team-incldues-individuals-with-design-implementation-carbon',
        'adaptive-management-plan-implemented',
      ],
      datums
    ) + internalRiskPctBiomassScore

  // Financial Viability -----------------------------------

  const financialViabilityScore = getScoreForOptions(
    [
      'years-until-cash-flow-breakeven',
      'funding-secured-until-cash-flow-breakeve',
      'project-financial-resources-50-percent-total-cash',
    ],
    datums
  )

  // Opportunity Cost -----------------------------------
  const opportunityCostScore = getScoreForOptions(
    [
      'how-profitable-are-project-activities',
      'proponent-is-ngo',
      'project-protected-by-legally-binding-commitment',
    ],
    datums
  )

  const bindingCommitment =
    datums['project-protected-by-legally-binding-commitment']?.value
  const bindingCommitmentYes = bindingCommitment && bindingCommitment.includes('yes')

  // Project Longevity Risk  ----------------------------

  let projectLongevityScore
  const yearsWillActivitiesBeMantained = Number(
    datums['years-will-activities-be-mantained']?.value
  )

  if (yearsWillActivitiesBeMantained < DEFAULT_PROJET_PERIOD)
    issues.push(
      `The project is less than 30 years. The project fails the risk assessment and it is not eligible for crediting.`
    )

  if (yearsWillActivitiesBeMantained === undefined) projectLongevityScore = 0
  else if (bindingCommitmentYes) {
    projectLongevityScore = 30 - yearsWillActivitiesBeMantained / 2
  } else {
    projectLongevityScore = 24 - yearsWillActivitiesBeMantained / 5
  }

  // Land Tenure Risk  ----------------------------
  let landTenureScore = getScoreForOptions(
    [
      'ownership-and-rights-held-by-same-entity',
      'disputes-over-land-tenure',
      'disputes-over-access-use-rights',
      'disputes-over-land-tenure-documented',
    ],
    datums
  )

  if (bindingCommitmentYes) landTenureScore -= 2

  // Community Engagment Risk ----------------------------
  const communityEngagementScore = getScoreForOptions(
    [
      'percent-household-living-in-project-area',
      'percent-household-living-within-20km',
      'project-generate-positive-social-economic-impact',
    ],
    datums
  )

  // Political Risk ----------------------------
  const governanceScore = getCountryGovernanceScore(landProject.countryCode)
  if (!governanceScore) throw `please review current country's governance score data`
  // - Then we transfer that governance score average to the score used in the questionnaire
  const governanceScoreAsQuestionnaireScore =
    getGovernanceScoreToQuestionnaireScore(governanceScore)

  const REDDReadinessScore = getScoreForOptions(
    ['political-risk-country-redd-readiness'],
    datums
  )
  const politicalRiskScore = governanceScoreAsQuestionnaireScore + REDDReadinessScore

  // Natural Risk ----------------------------
  const fireRiskScore = getNaturalRiskData(datums, 'fire')
  const pestDiseaseRiskScore = getNaturalRiskData(datums, 'pest-disease')
  const extremeWeatherRiskScore = getNaturalRiskData(datums, 'extreme-weather')
  const geologicalRiskScore = getNaturalRiskData(datums, 'geological')

  const naturalRiskScore =
    fireRiskScore + pestDiseaseRiskScore + extremeWeatherRiskScore + geologicalRiskScore

  const totalScore =
    projectManagementRiskScore +
    financialViabilityScore +
    opportunityCostScore +
    projectLongevityScore +
    landTenureScore +
    communityEngagementScore +
    politicalRiskScore +
    naturalRiskScore

  return {
    totalScore,
    projectManagementRiskScore,
    financialViabilityScore,
    opportunityCostScore,
    projectLongevityScore,
    landTenureScore,
    communityEngagementScore,
    politicalRiskScore,
    naturalRiskScore,
    issues,
  }
}

type NaturalRisk = 'fire' | 'geological' | 'extreme-weather' | 'pest-disease'

function getNaturalRiskData(datums: Datums, naturalRiskName: NaturalRisk) {
  const significance = datums[`${naturalRiskName}-significance`]?.value
  const likelihood = datums[`${naturalRiskName}-likelihood`]?.value
  const withMeasures = datums[`${naturalRiskName}-measures`]?.value == 'yes'
  const withProvenMeasures = datums[`${naturalRiskName}-measures-proven`]?.value == 'yes'

  const score = calculateNaturalRisk({
    significance,
    likelihood,
    withMeasures,
    withProvenMeasures,
  })
  return score
}

const likelihoods = [
  'less-than-10',
  'every-10',
  'every-25',
  'every-50',
  'once-every-100',
] as const

type Likelihood = (typeof likelihoods)[number]

type Significance = 'catastrophic' | 'major' | 'minor' | 'insignificant' | 'no-loss'

function calculateNaturalRisk(values: {
  likelihood: Likelihood
  significance: Significance
  withMeasures: boolean
  withProvenMeasures: boolean
}) {
  const { likelihood, significance, withMeasures, withProvenMeasures } = values
  // table by significance, each array has 5 values mapping to:
  // lessThan10, every10, every25, every50, onceEvery100

  const table = {
    catastrophic: [100, 30, 20, 5, 0],
    devastating: [30, 20, 5, 2, 0],
    major: [20, 5, 2, 1, 0],
    minor: [5, 2, 1, 1, 0],
    insignificant: [2, 1, 1, 0, 0],
    ['no-loss']: [0, 0, 0, 0, 0],
  }

  const likelihoodIndex = likelihoods.indexOf(likelihood)
  if (likelihoodIndex < 0) return 0
  const row = table[significance]
  const value = row[likelihoodIndex]
  const measuresFactor =
    withMeasures && withProvenMeasures
      ? 0.25
      : withMeasures || withProvenMeasures
      ? 0.5
      : 1

  return value * measuresFactor
}

function getScoreForOptions(fieldKeys: string[], datums: Datums) {
  let score = 0

  fieldKeys.forEach((fieldKey) => {
    const datum = datums[fieldKey]
    if (!datum) return
    const datumScore = getScoreForOption(datum)
    score += datumScore
  })

  return score
}

function getGovernanceScoreToQuestionnaireScore(governanceScore) {
  if (governanceScore < -0.79) return 6
  else if (governanceScore < -0.32) return 4
  else if (governanceScore < 0.19) return 2
  else if (governanceScore < 0.82) return 2
  else return 0
}
