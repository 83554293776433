import { useSnapshot } from 'valtio'
import { ErrorBoundary } from '~/components/atomic/ErrorBoundary'
import { ProjectionInputsDialogs } from '~/routes/project/carbon/arr/dialogs/ProjectionInputsDialogs'
import { MultiProjectionsGraph } from '~/routes/project/carbon/arr/components/MultiProjectionsGraph'
import { MultiProjectionsTables } from '~/routes/project/carbon/arr/components/MultiProjectionsTables'
import { ProjectionInputsStats } from '~/routes/project/carbon/arr/components/ProjectionInputsStats'
import { ProjectionsList } from '~/routes/project/carbon/arr/components/ProjectionsList'
import { ProjectTab } from '~/components/layouts/ProjectTab'
import { Projection } from '~/models/projection'
import {
  carbonState,
  useInitArrCarbonStore,
} from '~/routes/project/carbon/arr/arrCarbonStore'
import { Column } from '~/styles'
import { Section } from '~/styles/main'
import { getModelByKey } from '~/models/landProjectArrStats'
import { Serie } from '@nivo/line'

export type ProjectionTableData = {
  label: string
  color: string
  data: {
    year: number
    tCO2: number
  }[]
} & any

type Props = {}

export function ARRCarbonView(props: Props) {
  const {} = props

  useInitArrCarbonStore()

  return (
    <ProjectTab title='Carbon'>
      <Section className='flex column md:flex-row relative gap-40'>
        <Column className='w-[320px] space-y-40 self-start'>
          <Column>
            <Section.Subtitle>Carbon Inputs</Section.Subtitle>
            <ProjectionInputsStats />
          </Column>

          <ProjectionsList />
        </Column>

        <GraphsAndTables />

        <ProjectionInputsDialogs />
      </Section>
    </ProjectTab>
  )
}

function GraphsAndTables() {
  const { projections, activeProjectionKeys } = useSnapshot(carbonState)
  const activeProjections = projections.filter((p) => {
    return activeProjectionKeys.includes(p.snapshotMainInterventionKey)
  }) as Projection[]

  const tables = projectionsToTables(activeProjections)
  const series = projectionsToGraphSeries(activeProjections)

  return (
    <ErrorBoundary>
      <Column className='flex-1 space-y-40'>
        <MultiProjectionsGraph series={series} className='h-[480px]' />
        <MultiProjectionsTables tables={tables} />
      </Column>
    </ErrorBoundary>
  )
}

export function projectionsToTables(projections: Projection[]): ProjectionTableData[] {
  const tables = projections.map((projection) => projectionToTable(projection))
  return tables
}

export function projectionToTable(projection: Projection): ProjectionTableData {
  const model = getModelByKey(projection.snapshotMainInterventionKey)

  const data = projection.byYear.map((d) => ({
    ...d,
  }))

  return {
    label: model?.label,
    modelKey: projection.snapshotMainInterventionKey,
    color: model?.color,
    data: data,
  }
}

export function projectionsToGraphSeries(projections: Projection[]): Serie[] {
  const series = projections
    .filter((p) => p.byYear)
    .map((projection) => projectionToGraphSerie(projection))

  return series
}

export function projectionToGraphSerie(projection: Projection): Serie {
  const model = getModelByKey(projection.snapshotMainInterventionKey)

  const baseProjectionDataPoint = { x: 0, y: 0, min: 0, max: 0 }
  const projectionbyYear = projection.byYear.map((d, index) => ({
    x: d.year || index,
    y: d.tCO2,
    min: d.tCO2Min,
    max: d.tCO2Max,
  }))

  return {
    id: model?.label,
    modelKey: projection.snapshotMainInterventionKey,
    color: model?.color,
    data: [baseProjectionDataPoint, ...projectionbyYear],
  }
}
