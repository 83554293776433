import { getModelByKey } from '~/models/landProjectArrStats'
import {
  getLandProjectArea,
  getLandProjectReforestablePercent,
  LandProject,
} from '~/models/landProject'
import { computeByYearStatsWithSchedule } from '~/models/landProjectArrStats'
import { ComputeModelKeys, LandProjectStats } from '~/models/landProjectStats'
import { computeReforestablePercent } from '~/utils/units'

export type Projection = {
  label: string
  loading?: boolean
  active?: boolean
  color?: string
} & Partial<LandProjectStats>

export function landProjectStatToProjection(
  landProjectStats: LandProjectStats,
  landProject: LandProject
): Projection {
  let projection: Projection = {
    ...landProjectStats,
    ...getModelDataForProjection(landProjectStats.snapshotMainInterventionKey),
  }

  projection = computeLandProjectProjectionStats(projection, landProject)
  return projection
}

export function getModelDataForProjection(carbonModelKey: ComputeModelKeys) {
  const model = getModelByKey(carbonModelKey)
  return {
    active: true,
    label: model?.label,
    color: model?.color,
  }
}

export function computeLandProjectProjectionStats(
  projection: Projection,
  landProject: LandProject
): Projection {
  const newProjection: Projection = { ...projection }

  // TODO REFACTOR with new financial section we should move this out of the core carbon projection calculation
  let schedule = landProject.schedule

  // TODO REFACTOR we should improve
  const reforestablePercent = getLandProjectReforestablePercent(landProject)

  const area = getLandProjectArea(landProject)

  if (!schedule || Object.keys(schedule).length === 0) {
    schedule = { 0: { areaHa: computeReforestablePercent(area, reforestablePercent) } }
  }

  // TODO REFACTOR move plantation schedule computation to the backend
  newProjection.byYear = computeByYearStatsWithSchedule({
    schedule: schedule,
    byYear: projection.byYear,
    polygonAreaHa: projection.areaHa,
    reforestablePercent,
  })

  return newProjection
}
