import { Button } from '~/components/atomic/Button'
import Icon from '~/components/atomic/Icon'
import { ProjectType } from '~/models/landProject'

type Props = {
  projectType?: ProjectType
  className?: string
}
export function ProjectsEmptyState(props: Props) {
  const { projectType, className = '' } = props

  return (
    <div className={`center col flex h-full ${className}`}>
      <h3 className='mt-8 text-16 font-bold text-ink-900'>No projects found</h3>
      <p className='mt-4 text-14 text-ink-500'>Get started by creating a new project.</p>
      <div className='mt-24'>
        <Button variant='primary' to='/projects/new' state={{ projectType }}>
          <Icon icon='add' />
          New Project
        </Button>
      </div>
    </div>
  )
}
