import Map, { Marker } from 'react-map-gl'
import { CountriesBounds } from '~/components/map/layers/CountriesBounds'
import { CountryHighlight } from '~/components/map/layers/CountryHighlight'
import { BackgroundLayer } from '~/components/map/layers/BackgroundLayer'
import WaterMask from '~/components/map/WaterMask'
import {
  getLandProjectCountry,
  getProjectLatLong,
  LandProject,
} from '~/models/landProject'
import { MAPBOX_TOKEN } from '~/services/mapbox'

type Props = {
  className?: string
  landProject: LandProject
}

export function CountryMap(props: Props) {
  const { className, landProject } = props

  const countryName = getLandProjectCountry(landProject)
  const { lat, long } = getProjectLatLong(landProject)

  function onLoad(ev) {
    const map = ev.target
    map.setZoom(1.6)
    map.setCenter([long, lat])
  }

  return (
    <div className={`overflow-hidden ${className}`}>
      <Map
        id='main'
        attributionControl={false}
        mapboxAccessToken={MAPBOX_TOKEN}
        style={{ width: '100%', height: '100%', opacity: 1 }}
        mapStyle={style}
        interactive={false}
        onLoad={onLoad}
      >
        <BackgroundLayer />
        <WaterMask />
        <CountriesBounds />
        <CountryHighlight countryName={countryName} fill='#C3CDA9' />

        <Marker key={`marker`} longitude={long} latitude={lat} anchor='center'>
          <svg height={60} viewBox='0 0 60 60' fill='none'>
            <circle cx='30' cy='30' r='1' fill={'black'} />
            <circle
              cx='30'
              cy='30'
              r='28'
              stroke={'black'}
              strokeWidth={1}
              opacity={0.4}
            />
          </svg>
        </Marker>
      </Map>
    </div>
  )
}

const style: any = {
  version: 8,
  name: 'Basic',
  center: [-15.753670708773114, 10.948921270934065],
  zoom: 0.8691960892230212,
  bearing: 0,
  pitch: 0,
  sprite:
    'mapbox://sprites/bernatfortet/cl44rh4q8000115qssaruqtde/18a6bzgehyhy9w3m1axdpxwm7',
  glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
  sources: {
    composite: {
      url: 'mapbox://mapbox.mapbox-streets-v8,mapbox.country-boundaries-v1',
      type: 'vector',
    },
  },
  layers: [
    {
      id: 'land',
      type: 'background',
      paint: { 'background-color': '#eaefe9' },
    },
    {
      id: 'admin-0-boundary',
      type: 'line',
      source: 'composite',
      'source-layer': 'admin',
      filter: [
        'all',
        ['==', ['get', 'admin_level'], 0],
        ['==', ['get', 'disputed'], 'false'],
        ['==', ['get', 'maritime'], 'false'],
        ['match', ['get', 'worldview'], ['all', 'US'], true, false],
      ],
      layout: { 'line-cap': 'round' },
      paint: {
        'line-color': 'hsla(76, 57%, 13%, 0.10)',
        'line-dasharray': [10, 0],
        'line-width': 0.5,
      },
    },
  ],
  created: '2022-06-07T22:56:28.997Z',
  modified: '2022-06-07T23:00:21.349Z',
  id: 'cl44rh4q8000115qssaruqtde',
  owner: 'bernatfortet',
  visibility: 'private',
  protected: false,
  draft: false,
}
