import { LandFooter } from '~/components/land/LandFooter'

import { useLocation, useParams } from 'react-router-dom'
import { Pressable } from '~/components/atomic/Pressable'
import { formatArea } from '~/utils/units'
import { useActiveLandProject } from '~/state'
import { Section } from '~/styles/main'

type Props = {}

export function ParcelsSection(props: Props) {
  const { parcels } = useActiveLandProject()

  return (
    <div>
      <ParcelsList parcels={parcels}></ParcelsList>
      <LandFooter />
    </div>
  )
}

export function ParcelsList({ parcels }) {
  return (
    <Section>
      <Section.Title>Parcels</Section.Title>
      <div className='grid w-full grid-cols-[2fr_1fr_2fr] text-13 opacity-50'>
        <div className='text-left'>Parcel</div>
        <div className='text-right'>Area</div>
        <div className='text-right'>Intervention Type</div>
      </div>

      {parcels &&
        parcels.map((parcel, index) => (
          <ParcelItem parcel={parcel} key={index} index={index} />
        ))}
    </Section>
  )
}

function ParcelItem(props) {
  const { landProject } = useActiveLandProject()
  const { project_id, parcel_id } = useParams()
  const location = useLocation()

  const { index, parcel } = props
  const { _id, name } = parcel

  const area = formatArea(parcel?.xStats?.areaHa)
  const parcelName = name || _id || `Parcel ${index}`
  const interventionProfileId = parcel?.interventions?.[0].interventionProfileId
  const interventionProfile = landProject.interventionProfiles?.find(
    (ip) => ip.id === interventionProfileId
  )

  const isActive = location.pathname == `/project/${project_id}/parcel/${_id}`

  return (
    <Pressable
      to={`${_id}`}
      isActive={isActive}
      className='!grid w-full grid-cols-[2fr_1fr_2fr] text-14'
    >
      <div className='text-left font-semibold'>{parcelName}</div>
      <div className='text-right'>{area}</div>
      <div className='text-right'>{interventionProfile?.name}</div>
    </Pressable>
  )
}
