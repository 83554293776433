import { cn } from '~/utils/cn'
import React from 'react'

type Props = {
  id?: string
  name?: string
  label?: string
  value?: string
  checked?: boolean
  defaultChecked?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onClick?: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void
  className?: string
}

export function Checkbox(props: Props) {
  const { name, value, label, className = '', ...rest } = props

  // Conditionally ading the value prop, if it exists but it's undefined/null FormData will not catpure the value
  // With the code below we make sure not to add an empty value prop
  const valueProp = value ? { value } : {}

  const checkbox = (
    <input
      name={name}
      id={value}
      type='checkbox'
      {...valueProp}
      className={cn(
        `mr-8 h-16 w-16 !cursor-pointer rounded border-ink-300 text-brand hover:border-ink-500 focus:ring-brand`,
        !label && className
      )}
      {...rest}
    />
  )
  if (label) {
    return (
      <label
        htmlFor={value}
        className={`row row-vcenter cursor-pointer select-none text-14 font-medium ${className}`}
      >
        {checkbox}
        {label}
      </label>
    )
  } else {
    return checkbox
  }
}
