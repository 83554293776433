import React, { PropsWithChildren } from 'react'
import { saveProjectByParcelBulks, saveProjectWithParcels } from '~/services/db'
import { logger } from '~/services/logger'
import { useActiveLandProject } from '../../state/index'
import { UploadPolygonButton } from './UploadPolygonButton'
import { state } from '~/state'
import { ArrComputeModelKeys } from '~/models/landProjectArrStats'
import { computeREDDScopingStatsWithDefaults } from '~/routes/project/carbon/redd/reddStore'
import { calculateProjection } from '~/routes/project/carbon/arr/arrCarbonStore'

type Props = PropsWithChildren<{
  className?: string
}>

export function UploadPolygonLandProject(props: Props) {
  const { className, ...rest } = props
  const { landProject } = useActiveLandProject()
  const [parcelUploadProgress, setParcelUploadProgress] = React.useState(0.0)

  async function save(allParcels) {
    const parcelCount = allParcels.length
    const maxCoordinatesCount = 5000
    let startIdx = 0
    const lastIdx = parcelCount - 1
    const totalCoordinates = allParcels.reduce(
      (previousValue, currentValue) => previousValue + currentValue.coordinates.length,
      0
    )
    if (totalCoordinates > maxCoordinatesCount) {
      let bulkCoordinatesCount = 0
      for (let i = 0; i < parcelCount; i++) {
        bulkCoordinatesCount += allParcels[i].coordinates.length
        setParcelUploadProgress(i / parcelCount)
        if (bulkCoordinatesCount > maxCoordinatesCount || i === lastIdx) {
          if (i == lastIdx) {
            await saveProjectByParcelBulks(landProject, true, allParcels.slice(startIdx))
          } else {
            await saveProjectByParcelBulks(
              { _id: landProject._id },
              false,
              allParcels.slice(startIdx, i)
            )
          }

          logger.log(
            'saved ',
            allParcels.slice(startIdx, i).length,
            'parcels with',
            bulkCoordinatesCount - allParcels[i].coordinates.length,
            'coordinates',
            'progress ',
            parcelUploadProgress
          )
          startIdx = i
          bulkCoordinatesCount = 0
        }
      }
    } else {
      saveProjectWithParcels(landProject, allParcels)
    }
    state.parcels = allParcels
    // auto compute redd stats on upload polygon
    if (landProject.projectType === 'redd') {
      computeREDDScopingStatsWithDefaults(landProject, allParcels)
    } else if (landProject.projectType === 'arr') {
      calculateProjection(ArrComputeModelKeys.arr_scoping)
    }
  }

  return <UploadPolygonButton onUploaded={save} />
}
