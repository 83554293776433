import { useActiveLandProject } from '~/state'

import { Column } from '~/styles'
import { Section } from '~/styles/main'

export function RecommendationsSlide() {
  const { landProject } = useActiveLandProject()

  const recommendations = landProject.data['scoping-recommendations']
  if (!recommendations?.value) return <div>No recommendations</div>

  let content = recommendations.value.split('\n')

  return (
    <Section>
      <Column className='max-w-[75vw]'>
        <Section.Title>Recommendations</Section.Title>
        <ul className='list-disc ml-16 max-w-prose space-y-12 text-16 text-ink-800'>
          {content.map((item, i) => (
            <li className='list-item' key={i}>
              {item}
            </li>
          ))}
        </ul>
      </Column>
    </Section>
  )
}
