const sdgKeys = [
  'affordable-clean-energy',
  'clean-water',
  'climate-action',
  'decent-work-economic-growth',
  'gender-equality',
  'good-health',
  'industry-innovation-infrastructure',
  'life-below-water',
  'life-on-land',
  'no-poverty',
  'partnerships-goals',
  'peace-justice',
  'quality-education',
  'reduced-inequalities',
  'responsible-consumption-production',
  'sustainable-cities-communities',
  'zero-hunger',
] as const

export type SDGKey = typeof sdgKeys[number]

type Props = {
  sdgKey: SDGKey
}

export function SDGImage(props: Props) {
  const { sdgKey } = props

  const hasSVG = sdgKeys.includes(sdgKey)
  if (!hasSVG) return null

  return (
    <img src={require(`~/assets/images/sdgs/${sdgKey}.svg`)} width={120} height={120} />
  )
}
