import { ComboBox } from '~/components/atomic/ComboBox'
import { Pressable } from '~/components/atomic/Pressable'
import { AvatarAndName } from '~/components/core/AvatarAndName'
import { PopoverWithSearchableSelect } from '~/components/core/PopoverWithSearchableSelect'
import { getEarthshotUsersOptions } from '~/data/earthshotUsers'
import { saveProjectData } from '~/services/db'
import { storeLandProjectData } from '~/state'
import { capitalizeFirstLetter } from '~/utils'
import { cn } from '~/utils/cn'

export type LeadKeys = 'projectLead' | 'carbonLead' | 'partnershipLead'

type Props = {
  userName: string
  landProjectId: string
  leadKey: LeadKeys
  size?: number
  className?: string
}

export function UserLeadInput(props: Props) {
  const { userName = '', leadKey, landProjectId, size = 24, className } = props

  function onChooseProjectLead(option) {
    const data = {
      [leadKey]: option.label,
    }
    storeLandProjectData(data, landProjectId)
    saveProjectData(data, landProjectId)
  }

  return (
    <PopoverWithSearchableSelect
      ComboBoxComponent={(props) => (
        <ComboBox
          placeholder='Set project lead...'
          options={getEarthshotUsersOptions()}
          components={{
            OptionValue: ({ option }) => (
              <AvatarAndName name={capitalizeFirstLetter(option.label)} />
            ),
          }}
          {...props}
        />
      )}
      onSelect={onChooseProjectLead}
    >
      <Pressable className={cn('row-vcenter self-start', className)}>
        <AvatarAndName size={size} name={capitalizeFirstLetter(userName)} />
      </Pressable>
    </PopoverWithSearchableSelect>
  )
}
