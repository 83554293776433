import { useRef, useState } from 'react'
import Map, { Layer, Source } from 'react-map-gl'

import { MAPBOX_TOKEN } from '~/services/mapbox'
import { fillStyle, lineStyle } from './style'
import { getFeatureCollectionFromParcels } from '~/utils/geo'
import { Parcel, Bounds } from '~/models'

type Props = {
  polygons: any[]
  bounds: Bounds
}

export default function MapPolygons(props: Props) {
  const { polygons, bounds } = props
  const parcels = polygons.map((polygon) => {
    return new Parcel({ coordinates: polygon })
  })
  const [isLoaded, setIsLoaded] = useState(false)

  const mapRef = useRef(null)

  const parcelsGeoJSON = getFeatureCollectionFromParcels(parcels)
  const parcelGeoJSON = null

  const initialViewState = {
    bounds: [bounds.min[0], bounds.min[1], bounds.max[0], bounds.max[1]] as [
      number,
      number,
      number,
      number
    ],
    fitBoundsOptions: { padding: 40 },
  }

  const onLoad = () => {
    if (!mapRef.current) return
    setIsLoaded(true)
    // mapRef.current.fitBounds([bounds.min[0], bounds.min[1], bounds.max[0], bounds.max[1]], {
    //   padding: 20,
    //   animate: false,
    // })
  }

  // let interactiveLayerIds = ['parcels-fill']

  return (
    <Map
      ref={mapRef}
      id='main'
      attributionControl={false}
      fadeDuration={100}
      mapboxAccessToken={MAPBOX_TOKEN}
      initialViewState={initialViewState}
      style={{ width: '100%', height: '100%' }}
      onLoad={onLoad}
      cursor={'pointer'}
      mapStyle='mapbox://styles/mapbox/satellite-v9'
    >
      <Source id='parcels' type='geojson' data={parcelsGeoJSON} />
      <>
        <Layer source='parcels' id='parcels-fill' {...fillStyle} />
        <Layer source='parcels' id='parcels-line' {...lineStyle} />
      </>
    </Map>
  )
}
