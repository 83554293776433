/*
  projectsFilters.ts attempts to control and model the filters used in the Projects Table
  Hositing state to the url:
  - We use a different state paradigm here: hoisting state to the url
  - The reason is that we want to allow users to copy url and share them with others.
  - To handle that we read the url before loading Projects. If there are filters they get applied
  - Any time the user change the filter inputs, the url udpates with te apprpriate url
  - When that happens the local storage gets updated.

  Local Storage:
  - In order to keep the state of the last projects list we store the filters to local storage
  - Then, next time we load the page, if there are no filters present in the url, we apply the stored ones
  - This also helps when navigating back to all projects (with or without a browing history)
*/

import { useEffect } from 'react'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { LandProject } from '~/models'
import { ProjectType } from '~/models/landProject'
import { loadFromStorage, saveToStorage } from '~/services/localStorage'
import { state } from '~/state'
import { PATH } from '~/utils/constants'

const DEFAULT_PROJECT_TYPE: ProjectType = 'arr'

const DEFAULT_FILTER: ProjectsFilters = {
  projectType: DEFAULT_PROJECT_TYPE,
}

// TODO make sure these are keys that belong to LandProject model
const projectFilterKeys: Extract<keyof Partial<LandProject>, string>[] = [
  'priority',
  'projectType',
  'projectLead',
  'carbonLead',
  'umbrellaPartner',
  'stage',
  'countryCode',
]

export type ProjectsFilterKeys = typeof projectFilterKeys[number]

export type ProjectsFilters = {
  [key in ProjectsFilterKeys]?: string
}

export function getProjectsRedirectPath(): string {
  // TODO improve. Don't use filters when the view is a proponent view.
  // We have to do a bit of an overhaul to separate the proponent from the
  // earthshot experience
  if (!state.authedUser?.isAdmin) return ''

  const projectsFiltersInUrl = getProjectsFiltersInUrl()
  const storedProjectsFilters = getStoredProjectsFilters()

  if (!projectsFiltersInUrl) {
    const projectsFilters = storedProjectsFilters || DEFAULT_FILTER
    return getProjectsFiltersSearchParamsString(projectsFilters)
  } else return ''
}

// If the url doesn't contain any filters, then use those stored
export function useInitalProjectsFilters() {
  const navigate = useNavigate()

  useEffect(() => {
    const redirectPath = getProjectsRedirectPath()
    if (redirectPath) {
      navigate({ search: redirectPath })
    }
  }, [])
}

// This hook handles the mutations to the projectsFilter url state
export function useProjectsFilters() {
  const navigate = useNavigate()

  return {
    setProjectsFilters: (key: ProjectsFilterKeys, value: string) => {
      const projectsFilters = getProjectsFiltersInUrl() || ({} as ProjectsFilters)

      if (value) projectsFilters[key] = value
      else delete projectsFilters[key]

      saveToStorage('projectsFilters', projectsFilters)
      navigate({ search: getProjectsFiltersSearchParamsString(projectsFilters) })
    },
    clear: () => {
      saveToStorage('projectsFilters', null)
      navigate({ search: null })
    },
  }
}

export function getProjectsFiltersInUrl(): ProjectsFilters {
  const searchParams = new URLSearchParams(window.location.search)
  const projectsFilters = Object.fromEntries(searchParams) as ProjectsFilters
  return Object.keys(projectsFilters).length ? projectsFilters : null
}

export function getProjectsPathWithStoredFilters() {
  return `${PATH['/projects']}/${PATH.list}${getStoredProjectsFiltersSearchParams()}`
}

// Helpers
function getStoredProjectsFiltersSearchParams() {
  const storedProjectsFilters = getStoredProjectsFilters()
  return getProjectsFiltersSearchParamsString(storedProjectsFilters)
}

function getStoredProjectsFilters() {
  return loadFromStorage('projectsFilters') as ProjectsFilters
}

function getProjectsFiltersSearchParamsString(projectsFilters: ProjectsFilters) {
  return projectsFilters ? `?${createSearchParams(projectsFilters)}` : ''
}
