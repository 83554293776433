import { cn } from '~/utils/cn'
import { useCombobox } from 'downshift'
import { useEffect, useState } from 'react'
import { inputClasses } from '~/components/atomic/Input'
import { Loader } from '~/components/atomic/Loader'
import { Option } from '~/types'

type Props = {
  loading?: boolean
  placeholder?: string
  options: Option[]
  searchKey?: string
  value?: string
  components?: {
    OptionValue?: (props: { option: Option }) => JSX.Element
  }
  onInputValueChange?: any
  onClickOption?: (option) => void
  onChange?: (option) => void
  onSearch?: (search) => void
  itemToString?: (option) => string
  onSelect?: (option: Option) => void
}

function defaultItemToString(item: Option) {
  return item ? item.label : ''
}

function defaultFilter(inputValue: string, option: Option) {
  return (
    !inputValue ||
    option.label?.toLowerCase().includes(inputValue) ||
    option.value?.toLowerCase().includes(inputValue)
  )
}

export function ComboBox(props: Props) {
  const {
    options,
    placeholder,
    loading,
    components,
    itemToString,
    onInputValueChange,
    onSelect,
  } = props

  const OptionValue = components?.OptionValue

  const [items, setItems] = useState(options)

  useEffect(() => {
    setItems(options)
  }, [options])

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    openMenu,
  } = useCombobox({
    items,
    onSelectedItemChange: (stateChange) => {
      onSelect && onSelect(stateChange.selectedItem)
    },
    onInputValueChange({ inputValue }) {
      onInputValueChange && onInputValueChange(inputValue)
      const filteredItems = options.filter((option) => defaultFilter(inputValue, option))
      setItems(filteredItems)
    },
    itemToString: itemToString || defaultItemToString,
  })

  return (
    <div className='relative'>
      <input
        name='search'
        placeholder={placeholder}
        className={cn(inputClasses, {
          'rounded-b-none outline-none ring-none': isOpen,
        })}
        {...getInputProps()}
        onClick={() => !isOpen && openMenu()}
      />
      {isOpen && loading && <Loader size={20} className='!absolute right-12 top-8' />}
      <ul
        className={cn({
          'absolute bg-white  border border-t-0 rounded-b-md shadow-lg border-black/10 w-full':
            isOpen,
        })}
        {...getMenuProps()}
      >
        {isOpen && items.length > 0 && (
          <div className='p-4'>
            {items.map((option, index) => {
              return (
                <div
                  index={index}
                  key={`${option.value}${index}`}
                  className={cn(
                    'row row-vcenter px-12 py-4 rounded-md  cursor-pointer border-b border-white',
                    highlightedIndex === index && 'bg-ink-150'
                  )}
                  {...getItemProps({ item: option, index })}
                >
                  {OptionValue ? <OptionValue option={option} /> : option.label}
                </div>
              )
            })}
          </div>
        )}
      </ul>
    </div>
  )
}
