import qs from 'qs'
import { Loader } from '~/components/atomic/Loader'
import { Pressable } from '~/components/atomic/Pressable'
import { Tooltip } from '~/components/atomic/Tooltip'
import { Button } from '~/components/atomic/Button'
import {
  ArrComputeModelKeys,
  ArrComputeModel,
  ModelsWithInputs,
  modelsWithInputsKeys,
} from '~/models/landProjectArrStats'
import {
  calculateProjection,
  disableProjectionByKey,
  enableProjectionAndCalculate,
  markProjectionAsChosen,
  toggleDialog,
  useArrCarbonStore,
} from '~/routes/project/carbon/arr/arrCarbonStore'
import { useActiveLandProject, state } from '~/state'
import { Column, Row } from '~/styles'
import { Section } from '~/styles/main'
import { cn } from '~/utils/cn'

export function ProjectionsList() {
  const { landProject } = useActiveLandProject()
  const chosenModelKey = landProject.chosenModelKey
  const store = useArrCarbonStore()
  const {
    projections,
    activeProjectionKeys,
    loadingProjectionKeys,
    applicableProjectionKeys,
    applicableModels,
  } = store
  const { activeLandProjectId } = state

  const exportProjectionsUrl = getExportProjectionsUrl()

  return (
    <Column>
      <Section.Subtitle>Projections</Section.Subtitle>
      {applicableModels.map((model) => {
        const { key: modelKey, label, color } = model
        const isActive = activeProjectionKeys.includes(modelKey)
        const isLoading = loadingProjectionKeys.includes(modelKey)
        const projection = projections.find(
          (p) => p.snapshotMainInterventionKey == modelKey
        )

        const documentationLink = projection?.documentationLink || model.documentationLink

        const updatedAt = projection?.updated_at
        const updatedByString = projection?.updatedBy
          ? ` by ${projection.updatedBy?.userFirstName}`
          : ''

        const updatedAtText = `Updated: ${new Date(
          updatedAt
        ).toLocaleString()}${updatedByString}`

        const legendCircle = isLoading ? (
          <Loader size={18} />
        ) : (
          <div
            className={cn('h-18 w-18 rounded-full border border-transparent', {
              'border border-dashed border-ink-400': !isActive,
            })}
            style={isActive ? { background: color } : {}}
          />
        )

        const chosenProjection = model.key === chosenModelKey

        return (
          <Row className='row-vcenter' key={modelKey}>
            <Tooltip content={updatedAt && updatedAtText} delayDuration={500}>
              <Pressable
                className='row-vcenter w-full text-14'
                onClick={() => onToggleModel(model)}
              >
                {legendCircle}
                <div className='ml-12 font-medium'>{label}</div>
              </Pressable>
            </Tooltip>

            <Row className='ml-12 space-x-4'>
              {documentationLink && (
                <IconButton
                  tooltip='Learn more about this model'
                  href={documentationLink}
                  openInNewTab
                  icon='info_outline'
                />
              )}

              {!!projection && (
                <IconButton
                  tooltip={!chosenProjection && 'Mark as chosen for profile'}
                  disabled={chosenProjection}
                  onClick={() => markProjectionAsChosen(modelKey)}
                  icon={chosenProjection ? 'flag' : 'outlined_flag'}
                  className={cn(chosenProjection && '!opacity-100')}
                />
              )}

              <IconButton
                tooltip={'Recompute Projection'}
                onClick={() => recompute(modelKey)}
                icon='refresh'
              />
            </Row>
          </Row>
        )
      })}
      <Row className='mt-16'>
        <Button
          variant='secondary'
          href={exportProjectionsUrl}
          download='projections.csv'
        >
          Export Projections
        </Button>
      </Row>
    </Column>
  )

  function getExportProjectionsUrl() {
    const queryString = qs.stringify({
      projectionKeys: applicableProjectionKeys.join(','),
      landProjectId: activeLandProjectId,
      withYears: 0,
    })
    return `${process.env.REACT_APP_URL_WEB}web/landProjectStats/projections?${queryString}`
  }

  function recompute(modelKey: ArrComputeModelKeys) {
    const modelNeedsInputs = modelsWithInputsKeys.includes(modelKey)
    if (modelNeedsInputs) {
      toggleDialog(modelKey as ModelsWithInputs)
    } else {
      calculateProjection(modelKey, null, true)
    }
  }

  function onToggleModel(model: ArrComputeModel) {
    if (loadingProjectionKeys.includes(model.key)) return

    if (activeProjectionKeys.includes(model.key)) {
      disableProjectionByKey(model.key)
    } else {
      enableProjectionAndCalculate(model.key)
    }
  }
}

function IconButton({ tooltip, className = '', ...rest }) {
  return (
    <Tooltip content={tooltip}>
      <Pressable className={`!m-0 ${className}`} {...rest} />
    </Tooltip>
  )
}
