import { useSearchParams } from 'react-router-dom'
import { ComboBox } from '~/components/atomic/ComboBox'
import { Pressable } from '~/components/atomic/Pressable'
import { AvatarAndName } from '~/components/core/AvatarAndName'
import { PopoverWithSearchableSelect } from '~/components/core/PopoverWithSearchableSelect'
import { earthshotUser, getUserOptions } from '~/data/earthshotUsers'
import {
  FinancingType,
  getFinancingTypeLabel,
  getFinancingTypeOptions,
  getProjectStageLabel,
  getProjectTypeLabel,
  getProjectTypeOptions,
  LandProject,
  priorities,
  ProjectStage,
  projectStagesOptions,
  ProjectType,
} from '~/models/landProject'
import { useLandProjectsContext } from '~/routes/projects/hooks'
import { useProjectsFilters } from '~/routes/projects/projectsFilters'
import { getCountryCodesOptions } from '~/services/countries'
import classed from '~/styles/classed'
import { capitalizeFirstLetter, getUniques as getUniqueValues } from '~/utils'

type Props = {}

const allOption = { label: 'All', value: 'all' }

const prioritiesOptions = [allOption, ...priorities.map((p) => ({ label: p, value: p }))]
const projectTypeOptions = [allOption, ...getProjectTypeOptions()]
const peopleOptions = [
  { label: 'All', value: 'all' },
  ...earthshotUser.map((u) => ({
    label: u,
    value: u,
  })),
]
const financingTypeOptions = [allOption, ...getFinancingTypeOptions()]

export function ProjectsTableFilters(props: Props) {
  const { landProjects, loading, error } = useLandProjectsContext()

  const [searchParams] = useSearchParams()

  const presentLeads = getUserOptions(getPresentAttributes(landProjects, 'projectLead'))
  const presentCarbonLeads = getUserOptions(
    getPresentAttributes(landProjects, 'carbonLead')
  )
  const presentUmbrellaPartners = makeCapitalizedOptions(
    getPresentAttributes(landProjects, 'umbrellaPartner')
  )

  const presentCountries = getCountryCodesOptions(
    getPresentAttributes(landProjects, 'countryCode')
  )

  const selectedProjectType = searchParams.get('projectType') as ProjectType
  const selectedLead = searchParams.get('projectLead') || 'All'
  const selectedCarbonLead = searchParams.get('carbonLead') || 'All'
  const selectedPriority = searchParams.get('priority') || 'All'
  const selectedStage =
    getProjectStageLabel(searchParams.get('stage') as ProjectStage) || 'All'
  const selectedCountry = searchParams.get('countryCode') || 'All'
  const selectedUmbrellaPartner = searchParams.get('umbrellaPartner') || 'All'
  const selectedFinancingType = searchParams.get('financingType') as FinancingType

  const { setProjectsFilters, clear } = useProjectsFilters()

  return (
    <>
      <span className='text-ink-600 font-semibold'>Filters: </span>

      <FilterContainer>
        <span>Priority: </span>

        <PopoverWithSearchableSelect
          side='bottom'
          align='start'
          ComboBoxComponent={(props) => (
            <ComboBox
              placeholder='Set project lead...'
              options={prioritiesOptions}
              {...props}
            />
          )}
          onSelect={(option) =>
            setProjectsFilters('priority', option.value === 'all' ? null : option.value)
          }
        >
          <Pressable className={pressableClassNames}>
            {capitalizeFirstLetter(selectedPriority)}
          </Pressable>
        </PopoverWithSearchableSelect>
      </FilterContainer>

      <FilterContainer>
        <span>Project Type: </span>

        <PopoverWithSearchableSelect
          side='bottom'
          align='start'
          ComboBoxComponent={(props) => (
            <ComboBox
              placeholder='Choose type...'
              options={projectTypeOptions}
              {...props}
            />
          )}
          onSelect={(option) =>
            setProjectsFilters(
              'projectType',
              option.value === 'all' ? null : option.value
            )
          }
        >
          <Pressable className={pressableClassNames}>
            {getProjectTypeLabel(selectedProjectType)?.toUpperCase() || 'All'}
          </Pressable>
        </PopoverWithSearchableSelect>
      </FilterContainer>

      <FilterContainer>
        <span>Financing Type: </span>

        <PopoverWithSearchableSelect
          side='bottom'
          align='start'
          ComboBoxComponent={(props) => (
            <ComboBox
              placeholder='Choose type...'
              options={financingTypeOptions}
              {...props}
            />
          )}
          onSelect={(option) =>
            setProjectsFilters(
              'financingType',
              option.value === 'all' ? null : option.value
            )
          }
        >
          <Pressable className={pressableClassNames}>
            {getFinancingTypeLabel(selectedFinancingType) || 'All'}
          </Pressable>
        </PopoverWithSearchableSelect>
      </FilterContainer>

      <FilterContainer>
        <span>Project Lead: </span>
        <PopoverWithSearchableSelect
          ComboBoxComponent={(p) => (
            <PeopleComboBox options={[allOption, ...presentLeads]} {...p} />
          )}
          side='bottom'
          align='start'
          onSelect={(option) =>
            setProjectsFilters(
              'projectLead',
              option.value === 'all' ? null : option.value
            )
          }
        >
          <Pressable className={pressableClassNames}>
            <AvatarAndName size={16} name={capitalizeFirstLetter(selectedLead)} />
          </Pressable>
        </PopoverWithSearchableSelect>
      </FilterContainer>

      <FilterContainer>
        <span>Carbon Lead: </span>
        <PopoverWithSearchableSelect
          ComboBoxComponent={(p) => (
            <PeopleComboBox options={[allOption, ...presentCarbonLeads]} {...p} />
          )}
          side='bottom'
          align='start'
          onSelect={(option) =>
            setProjectsFilters('carbonLead', option.value === 'all' ? null : option.value)
          }
        >
          <Pressable className={pressableClassNames}>
            <AvatarAndName size={16} name={capitalizeFirstLetter(selectedCarbonLead)} />
          </Pressable>
        </PopoverWithSearchableSelect>
      </FilterContainer>

      <Filter
        title='Umbrella Partner'
        placeholder='Type umbrella partner...'
        options={[allOption, ...presentUmbrellaPartners]}
        name='umbrellaPartner'
        value={selectedUmbrellaPartner}
        onSelect={setProjectsFilters}
      />

      <Filter
        title='Stage'
        placeholder='Type stage...'
        options={[allOption, ...projectStagesOptions]}
        name='stage'
        value={selectedStage}
        onSelect={setProjectsFilters}
      />

      <Filter
        title='Country'
        placeholder='Type country...'
        options={[allOption, ...presentCountries]}
        name='countryCode'
        value={selectedCountry}
        onSelect={setProjectsFilters}
      />

      <FilterContainer>
        <Pressable
          icon='close'
          iconClasses='-mx-4 mr-4'
          className='!py-4 -ml-6'
          onClick={clear}
        >
          Clear
        </Pressable>
      </FilterContainer>
    </>
  )
}

function Filter(props) {
  const { title, value, name, onSelect, placeholder, options, useLabel } = props
  return (
    <FilterContainer>
      <span>{title}</span>

      <PopoverWithSearchableSelect
        side='bottom'
        align='start'
        ComboBoxComponent={(props) => (
          <ComboBox placeholder={placeholder} options={options} {...props} />
        )}
        onSelect={(option) => {
          return onSelect(name, option.value === 'all' ? null : option.value)
        }}
      >
        <Pressable className={pressableClassNames}>{value}</Pressable>
      </PopoverWithSearchableSelect>
    </FilterContainer>
  )
}

const PeopleComboBox = ({ options, ...rest }) => (
  <ComboBox
    placeholder='Set project lead...'
    options={options || peopleOptions}
    components={{
      OptionValue: ({ option }) => (
        <AvatarAndName name={capitalizeFirstLetter(option.label)} />
      ),
    }}
    {...rest}
  />
)

const pressableClassNames = 'row-vcenter !py-4 text-13 font-semibold rounded-[4px]'

const FilterContainer = classed(
  'div',
  'row row-vcenter gap-12 ring-1 ring-ink-200 py-2 px-8 rounded-md'
)

function getPresentAttributes(landProjects: LandProject[], key: string) {
  const attributes = landProjects.reduce((prev, curr) => {
    return curr[key] ? [...prev, curr[key]] : prev
  }, [])
  return getUniqueValues(attributes)
}

function makeCapitalizedOptions(values: string[]) {
  return values?.map((v) => ({ value: v, label: capitalizeFirstLetter(v) })) || []
}
