export const brandColors = {
  highlight: '#488877',
  highlightHover: '#488877',
}

export type ColorVariants =
  | 'red'
  | 'yellow'
  | 'green'
  | 'blue'
  | 'default'
  | 'orange'
  | 'gray'
  | 'violet'

export const DATAVIZ_COLOR_SCALE = [
  '#abdda4',
  '#66c2a5',
  '#4194C8',
  '#fdae61',
  '#f46d43',
  '#d53e4f',
  '#906DDA',
]

export const lowMedHighColors = ['#DD5F68', '#ECCD7C', '#7DC0A7']

export const spectral4 = ['#7DC0A7', '#F1B16E', '#4B86B8', '#C54A53']

export const badgeVariants = {
  default: `bg-ink-200 text-ink-800`,
  yellow: `bg-yellow-100 text-orange-900`,
  gray: `bg-ink-200 text-ink-800`,
  orange: `bg-orange-100 text-orange-800`,
  green: `bg-[#70A672]/30 text-[#1B4838]`,
  red: `bg-[#F79E9E]/30 text-[#562D2D]`,
  violet: `bg-[#d89ef7]/30 text-[#412d56]`,
  purple: `bg-[#d89ef7]/30 text-[#412d56]`,
  blue: `bg-[#77c7f6]/30 text-[#191c50]`,
}

export const badgeNoTextVariants = {
  default: `bg-ink-200`,
  yellow: `bg-yellow-400`,
  gray: `bg-ink-200`,
  orange: `bg-orange-100 text-orange-800`,
  green: `bg-lime-400`,
  red: `bg-rose-400`,
  violet: `bg-violet-400`,
  blue: `bg-blue-400`,
}

export const colorScales = {
  spectral4,
}
