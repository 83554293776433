import { useEffect, useState } from 'react'
import { Button } from '~/components/atomic/Button'
import Select from '~/components/atomic/Select'

import { Row } from '~/styles'
import { Section } from '~/styles/main'
import ESHttp from '~/utils/ESHttp'

const optsFolders = [
  { value: 'barknet', label: 'Barknet' },
  { value: 'leafnet', label: 'Leafnet' },
  { value: 'v0.41', label: 'v0.41' },
  { value: 'vdbh-image', label: 'vdbh-image' },
  { value: 'vdbh-mask', label: 'vdbh-mask' },
]

export function ImageFilesSection() {
  const [folderData, setFolderData] = useState({ total: 0, fileInfos: [] })

  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const [formVals, setFormVals] = useState({
    folder: 'barknet',
  })

  function onChangeFormVals(evt) {
    const value = evt.target.value
    setFormVals((formVals) => {
      formVals[evt.target.name] = value
      return formVals
    })
  }

  function getFolders() {
    setMessage('Loading..')
    setLoading(true)
    setFolderData({ total: 0, fileInfos: [] })

    ESHttp.Go('POST', 'getFirebaseStorageBiome', formVals)
      .then((res) => res.json())
      .then((res) => {
        setLoading(false)
        setMessage('')
        setFolderData(res)
      })
  }

  useEffect(() => {
    getFolders()
  }, [])

  return (
    <Section>
      <Row>
        <Select
          name='folder'
          defaultValue={formVals.folder}
          options={optsFolders}
          onChange={onChangeFormVals}
          className='mr-20 flex-1'
        />
        <Button
          disabled={loading}
          variant='primary'
          onClick={getFolders}
          submitting={loading}
        >
          Get Files
        </Button>
      </Row>

      {message && <div>{message}</div>}

      <FirebaseImages folderData={folderData} />
    </Section>
  )
}

function FirebaseImages(props) {
  const { folderData } = props
  if (!folderData?.fileInfos || !folderData?.total) {
    return null
  }
  const fileEles = folderData.fileInfos.map((fileInfo, index) => {
    return (
      <div
        key={index}
        className='margin inline-block'
        style={{ maxWidth: '250px', verticalAlign: 'top' }}
      >
        <div>
          <img
            loading='lazy'
            src={fileInfo.url}
            height={200}
            width={200}
            className='rounded-sm bg-ink-100 ring-1 ring-ink-200'
          />
        </div>
        <div>{fileInfo.name}</div>
      </div>
    )
  })
  return (
    <div>
      <div className='margin-b'>{folderData.total} items</div>
      <div>{fileEles}</div>
    </div>
  )
}
