import { proxy, useSnapshot } from 'valtio'
import { MapLayer } from '~/services/mapbox'
import { makeRasterLayer, mapLayers } from './mapLayers'
import { AvailableDatasetsIds, DatasetType, datasets } from '~/models/dataset'
import { snakeCaseToLabel } from '~/utils'

type LandStore = {
  polygons: any[]
  mapLayers: any[]
}

export const landStore = proxy<LandStore>({
  polygons: [],
  mapLayers,
})

type FetchMapParams = {
  type?: DatasetType
  name?: string
  urlParams?: string
}

export type RasterLayerToFetch = {
  datasetId: AvailableDatasetsIds
  layerId?: string
  fetchParams?: FetchMapParams
}

export async function fetchAndStoreRasterMapLayers(
  rasterLayersToFetch: RasterLayerToFetch[],
  extraRasterLayers: { id: string; label: string; mapUrl: string }[] = []
) {
  const promises = rasterLayersToFetch.map((mapLayerToFetch) => {
    if (!mapLayerToFetch.datasetId) throw 'DatasetId is required'
    const dataset = datasets[mapLayerToFetch.datasetId]

    const fetchUrl = makeFetchMapUrl({
      ...dataset.fetchParams,
      ...mapLayerToFetch.fetchParams,
    })
    return fetchMap(fetchUrl)
  })

  const mapUrls = await Promise.all(promises)

  const fetchedRasterLayers = mapUrls.map((mapUrl, index) => {
    const layer = rasterLayersToFetch[index]
    const dataset = datasets[layer.datasetId]

    // TODO think about how to add legend from returned data
    const legend = dataset['legend'] ? dataset['legend'] : {}

    const label = layer.layerId ? snakeCaseToLabel(layer.layerId) : dataset.label

    return makeRasterLayer(layer.layerId || layer.datasetId, label, mapUrl, {
      legend,
    })
  })

  // Extra allows the method to inject/overwrite any layer we want.
  const extraMapLayers = extraRasterLayers.map((layer: MapLayer) => {
    return makeRasterLayer(layer.id, layer.label, layer.url, {
      // TODO think about how to add legend from returned data
    })
  })

  console.log('fetchedRasterLayers: ', fetchedRasterLayers)
  const allMapLayers = mergeArray(mapLayers, fetchedRasterLayers, extraMapLayers)

  landStore.mapLayers = allMapLayers
}

const mergeArray = (...arrays: any[]) => {
  const merged = [].concat(...arrays)
  const unique = merged.filter(
    (item: any, index: any) => merged.findIndex((i: any) => i.id === item.id) === index
  )
  return unique
}

export function useLandStore() {
  const snap = useSnapshot(landStore)
  return snap as LandStore
}

export function onChangeRasterLayer(layer: MapLayer) {
  const layerIndex = landStore.mapLayers.findIndex((l) => l.id === layer.id)
  landStore.mapLayers[layerIndex] = layer
}

export function showOnlyLayersArray(layers: MapLayer[]) {
  if (!layers) return
  landStore.mapLayers.forEach((ml: MapLayer) => {
    ml.visible = false
  })

  layers.forEach((layer: MapLayer) => {
    const layerIndex = landStore.mapLayers.findIndex((l) => l.id === layer.id)
    landStore.mapLayers[layerIndex].visible = true
  })
}

export function makeLayerSoloVisible(layer: MapLayer) {
  landStore.mapLayers.forEach((ml: MapLayer) => {
    ml.visible = false
  })

  if (!layer) return
  const layerIndex = landStore.mapLayers.findIndex((l) => l.id === layer.id)
  landStore.mapLayers[layerIndex].visible = true
}

export function makeFetchMapUrl(params: FetchMapParams) {
  const { type, name, urlParams } = params
  const baseUrl = `${process.env.REACT_APP_URL_SIMEARTH}geo/mapid/`
  const mapRef = `${type}${name ? `/${name}` : ''}`
  const url = [baseUrl, mapRef, urlParams].join('')
  return url
}

export async function fetchMap(url: string) {
  const res = await fetch(url)
  const mapUrl = await res.json()
  return mapUrl
}
