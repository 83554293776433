import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button } from '~/components/atomic/Button'
import Input from '~/components/atomic/Input'
import { Loader } from '~/components/atomic/Loader'
import Select from '~/components/atomic/Select'
import SelectCountry from '~/components/core/SelectCountry'
import { LandProject } from '~/models'
import { useGetCountryRegulatoryAssessment } from '~/models/countryRegulation'
import { getProjectTypeOptions } from '~/models/landProject'
import { CountryAssessmentMeta } from '~/routes/projects/components/CountryAssessmentMeta'
import { getCountryNameByCode } from '~/services/countries'
import * as db from '~/services/db'
import { useAuthedUser } from '~/state/auth'
import { Column, Row } from '~/styles'
import { Content } from '~/styles/main'
import { getFormData } from '~/utils'
import { DEFAULT_START_YEAR } from '~/utils/constants'

export function ProjectsNewPage() {
  const authedUser = useAuthedUser()
  const location = useLocation()
  const [saving, setSaving] = useState(false)
  const [country, setCountry] = useState<string>(null)
  const navigate = useNavigate()

  const defaultProjectType = location.state?.projectType || 'arr'

  async function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    if (saving) return

    const formData = getFormData<Partial<LandProject>>(e.currentTarget)

    setSaving(true)

    const { landProject } = await db.saveProjectWithParcels(formData, null)
    // Simple hack to give earthshot.eco domain admin permission when new project is created
    if (landProject) {
      const data = {
        landProjectUser: {
          landProjectId: landProject._id,
          entityId: 'earthshot.eco',
          entityType: 'emailDomain',
          permissions: ['editUser'],
        },
        authUserId: authedUser._id,
      }
      await db.fetchDB('saveLandProjectUser', data)
    }

    navigate(authedUser.isAdmin ? `/project/${landProject.uName}` : `/projects`)
  }

  function onChangeCountry(e) {
    const countryName = getCountryNameByCode(e.currentTarget.value)
    setCountry(countryName)
  }

  return (
    <Content className='justify-center space-y-20'>
      <form method='post' className='m-auto max-w-xl' onSubmit={onSubmit}>
        <div className='mb-24 text-24 font-bold'>Create new project</div>

        <div className='space-y-24'>
          <Input
            label='Name'
            type='text'
            name='name'
            placeholder='Your project name...'
            autoFocus
            required
          />

          <Column>
            <SelectCountry
              label='Country'
              name='countryCode'
              required
              onChange={onChangeCountry}
            />
            <CountryRating country={country} className='mt-8' />
          </Column>

          <Select
            name='projectType'
            label='Project Type'
            defaultValue={defaultProjectType}
            options={getProjectTypeOptions()}
          />

          <Input
            label='Start Year'
            type='number'
            name='startYear'
            defaultValue={DEFAULT_START_YEAR}
            required
          />

          <Input
            label='Umbrella Partner (optional)'
            type='text'
            name='umbrellaPartner'
            placeholder={`Partner's name...`}
          />
        </div>

        <Button variant='primary' type='submit' className='mt-20' submitting={saving}>
          Create Project
        </Button>
      </form>
    </Content>
  )
}

function CountryRating(props) {
  const { country, className } = props

  const countryAssessment = useGetCountryRegulatoryAssessment(country)

  return (
    <Column className={className}>
      <Row className='row-vcenter h-32'>
        <span className='mr-8 text-ink-700'>Country Rating:</span>
        {countryAssessment ? (
          <CountryAssessmentMeta countryAssessment={countryAssessment} />
        ) : (
          country && <Loader size={16} />
        )}
      </Row>
    </Column>
  )
}
