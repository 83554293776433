import React from 'react'
import { CreditIssuanceBarGraph } from '~/components/graphs/CreditIssuanceBarGraph'
import { Section } from '~/styles/main'

type Props = {}

export function IssuedVCUsSection(props: Props) {
  const {} = props

  return (
    <Section title='Credit issuance'>
      <CreditIssuanceBarGraph />
    </Section>
  )
}
