import { cn } from '~/utils/cn'
import React, { useRef, useState } from 'react'
import { Button } from '~/components/atomic/Button'
import {
  focusClasses,
  inputBoxClasses,
  inputTextClasses,
} from '~/components/atomic/Input'
import { VerticalStat } from '~/components/core/Stat'
import {
  LandProjectSchedule,
  parseScheduleToInterventionAreaByYear,
  saveLandProjectRootValues,
  sumInterventionsArea,
} from '~/models/landProject'
import { useActiveLandProject } from '~/state'
import { Column } from '~/styles'
import classed from '~/styles/classed'
import { Section } from '~/styles/main'
import { getFormData, makeArray } from '~/utils'
import { DEFAULT_PROJET_PERIOD } from '~/utils/constants'
import { formatNumber } from '~/utils/units'

// TODO migrate this componen to use <Spreadsheet>
// TODO migrate interventionSchdule code to backend as much as possible

export function InterventionScheduleSheet() {
  const { landProject } = useActiveLandProject()
  const interventionAreaByYear = parseScheduleToInterventionAreaByYear(
    landProject.schedule
  )

  const [saving, setSaving] = useState(false)
  const [total, setTotal] = useState(sumInterventionsArea(interventionAreaByYear))

  function onChange(e: React.ChangeEvent<HTMLFormElement>) {
    const formData = getFormData<{ [key: string]: string }>(e.currentTarget)

    // Forms return data as strings. Parse it so it can be sumed with sumInterventionsArea
    const parsed = {}
    for (const key in formData) {
      const value = parseFloat(formData[key])
      if (value) parsed[key] = value
    }

    const sum = sumInterventionsArea(parsed)
    setTotal(sum)
  }

  async function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    setSaving(true)

    const formData = getFormData(e.currentTarget)

    const newSchedule: LandProjectSchedule = {}

    for (const [year, areaHa] of Object.entries(formData)) {
      newSchedule[year] = { areaHa: parseFloat(areaHa) || 0 }
    }

    await saveLandProjectRootValues(landProject, {
      schedule: newSchedule,
    })

    setSaving(false)
  }

  return (
    <Column>
      <Section.Subtitle>Interventions Schedule</Section.Subtitle>
      <form onSubmit={onSubmit} onChange={onChange} className='row flex space-x-40'>
        <Column className={cn(inputBoxClasses, 'flex w-[240px] flex-col p-0')}>
          <table className=' border-1 table-auto border-collapse border-slate-400'>
            <thead>
              <tr className='group'>
                <TH className=''>Year</TH>
                <TH className='text-right'>Planted hectares</TH>
              </tr>
            </thead>

            <tbody>
              {makeArray(DEFAULT_PROJET_PERIOD).map((_, i) => (
                <tr key={i} className='group'>
                  <TD className='text-center'>
                    <span className=' text-number group pointer-events-none'>
                      {i + 1}
                    </span>
                  </TD>
                  <TD className='!p-0'>
                    <CustomInput name={i} defaultValue={interventionAreaByYear?.[i]} />
                  </TD>
                </tr>
              ))}
            </tbody>
          </table>
        </Column>

        <Column className='space-y-24'>
          <VerticalStat
            label='Scheduled Area'
            value={formatNumber(total) || 0}
            unit='ha'
          />
          <Button variant='primary' className='mt-20' submitting={saving}>
            Save Schedule
          </Button>
        </Column>
      </form>
    </Column>
  )
}

const cellClasses = 'border border-slate-300 px-12 first:border-l-0 last:border-r-0'
const TH = classed('th', cn(cellClasses, 'group-first:border-t-0 py-4'))
const TD = classed('td', cn(cellClasses, 'group-last:border-b-0'))

function CustomInput(props) {
  const ref = useRef<HTMLInputElement>(null)

  function onFocus(e) {
    ref.current?.select()
  }

  return (
    <input
      ref={ref}
      tabIndex={0}
      type='number'
      step='0.00001'
      onFocus={onFocus}
      placeholder='0'
      className={cn(
        inputTextClasses,
        'text-number h-24 w-full appearance-none border-0 pl-16 text-right group-last:rounded-br-md',
        focusClasses.split('').map((i) => `focus:${i}`)
      )}
      {...props}
    />
  )
}
