import { useMemo } from 'react'
import { ComplexTable } from '~/components/atomic/table/ComplexTable'
import TableLayout from '~/components/atomic/table/TableLayout'
import { createColumnHelper } from '@tanstack/react-table'
import { useFinancialsStore } from '~/routes/project/financials/financialsStore'
import { VCUREDD } from '~/types/vcus'
import { Numerical } from '~/components/core/Numerical'
import { formatPercent } from '~/utils/units'

const columnHelperREDD = createColumnHelper<VCUREDD>()

export function VCUsREDDTable(props: { withColumnVisiblity?: boolean }) {
  const { withColumnVisiblity = true } = props
  const { vcus, variables } = useFinancialsStore()

  const columnsREDD = useMemo(
    () =>
      [
        columnHelperREDD.accessor('year', {
          header: 'Year',
        }),
        columnHelperREDD.accessor('yearDate', {
          id: 'yearDate',
          header: 'Year Date',
          cell: (props) => props.getValue(),
        }),
        columnHelperREDD.accessor('baselineEmissions', {
          header: 'Baseline - GHG Emissions (tCO2e)',
          footer: TableLayout.SumFooter,
        }),
        columnHelperREDD.accessor('uncertainityOnBaselineRatesPerYear', {
          header: 'Uncertainty on Baseline Rates',
          meta: { type: 'percent' },
        }),
        columnHelperREDD.accessor('baselineAfterUncertainty', {
          header: 'Baseline after Reassessment Uncertainty (tCO2e)',
          footer: TableLayout.SumFooter,
        }),
        columnHelperREDD.accessor('estimatesUncertainty', {
          header: `Estimates Uncertainty (${formatPercent(
            variables.estimatesUncertaintyRate * 100
          )}%)`,
          footer: TableLayout.SumFooter,
        }),
        columnHelperREDD.accessor('baselineAfterUncertaintyAndEmissions', {
          header:
            'Baseline after Uncertainty from Activity Data and Emission Factors (tCO2e)',
          meta: { width: 'min-w-[200px]' },
          footer: TableLayout.SumFooter,
        }),
        columnHelperREDD.accessor('projectEfficiency', {
          header: 'Project Efficiency Rate',
          meta: { type: 'percent' },
        }),
        columnHelperREDD.accessor('projectEmissions', {
          header: 'Project Emissions (tCO2e)',
          footer: TableLayout.SumFooter,
        }),
        columnHelperREDD.accessor('leakageDiscount', {
          header: `Leakage discount (${formatPercent(
            variables?.leakageDisccountRate * 100
          )}%)`,
          footer: TableLayout.SumFooter,
        }),
        columnHelperREDD.accessor('netGHGEmissionReductions', {
          header: 'Net GHG Emission Reductions (tCO2e)',
          footer: TableLayout.SumFooter,
        }),
        columnHelperREDD.accessor('riskBuffer', {
          header: `Risk Buffer (${formatPercent(variables?.riskBufferRate * 100)}%)`,
          footer: TableLayout.SumFooter,
        }),
        columnHelperREDD.accessor('annualEmissionReductions', {
          header: 'Total Emission Reductions (tCO2e) / potential VCUs',
          meta: { width: 'min-w-[150px]' },
          footer: TableLayout.SumFooter,
        }),
        columnHelperREDD.accessor('cumulativeEmissionReductions', {
          header: 'Cumulative Emission Reductions (tCO2e) / potential VCUs',
          meta: { width: 'min-w-[150px]' },
        }),
        columnHelperREDD.accessor('issued', {
          header: 'Issued VCUs per vintage (tCO2e)',
          footer: TableLayout.SumFooter,
        }),
      ].map((column) => ({
        ...column,
        cell: (props) => {
          const cellValue = props.getValue()
          if (column.cell) return cellValue
          return <Numerical type={column.meta && column.meta['type']} value={cellValue} />
        },
      })),
    [variables]
  )

  return (
    <ComplexTable
      data={vcus}
      columns={columnsREDD}
      withColumnVisiblity={withColumnVisiblity}
    />
  )
}
