export function getIRRBackground(irr: number | string) {
  if (irr >= 0.21) return spreadsheetColors.paleGreen
  else if (irr > 0.15) return spreadsheetColors.green
  else if (irr > 0) return spreadsheetColors.yellow
  else return spreadsheetColors.red
}

export const spreadsheetColors = {
  red: '#EECDCC',
  yellow: '#F3E3B8',
  green: '#9DC284',
  paleGreen: '#D5EBCF',
}
