import { useActiveLandProject } from '~/state'

import { Column } from '~/styles'
import { assessPermanence } from '~/data/assessements/permanence'
import { Section } from '~/styles/main'
import { ErrorMessage } from '~/components/atomic/ErrorMessage'
import { Numerical } from '~/components/core/Numerical'

type Props = {}

export function PermanenceAssessment(props: Props) {
  const { landProject } = useActiveLandProject()

  const {
    totalScore,
    issues,
    projectManagementRiskScore,
    financialViabilityScore,
    opportunityCostScore,
    projectLongevityScore,
    landTenureScore,
    communityEngagementScore,
    politicalRiskScore,
    naturalRiskScore,
  } = assessPermanence(landProject)
  console.log('issues: ', issues)

  return (
    <Column className='p-40'>
      <Section.Title>Permanence Assessment</Section.Title>
      <Column className='space-y-4'>
        <div>Project Management Risk: {projectManagementRiskScore}</div>
        <div>Financial Viability: {financialViabilityScore}</div>
        <div>Opportunity Cost: {opportunityCostScore}</div>
        <div>Project Longetivity: {projectLongevityScore}</div>
        <div>Land Tenure Score: {landTenureScore}</div>
        <div>Political Risk: {politicalRiskScore}</div>

        <div>Community Engagement: {communityEngagementScore}</div>
        <div>Natural Risk Score: {naturalRiskScore}</div>
        <div className='font-bold'>Total Score: {totalScore}</div>
        <div className='font-bold'>
          Risk Buffer: <Numerical type='percent' value={totalScore / 100} />
        </div>
        <div>
          {issues.map((i) => (
            <ErrorMessage error={i} />
          ))}
        </div>
      </Column>
    </Column>
  )
}
