import { Button } from '~/components/atomic/Button'
import { CenteredLoader } from '~/components/atomic/CenteredLoader'
import Icon from '~/components/atomic/Icon'
import Link from '~/components/atomic/Link'
import { Page } from '~/components/layouts/Page'
import { ProjectsMap } from '~/components/map/ProjectsMap'
import { ProjectCard } from '~/components/projects/ProjectCard'
import { useLandProjectsContext } from '~/routes/projects/hooks'
import { Column, Row } from '~/styles'
import { Card, CardHoverable, ViewTitle } from '~/styles/main'

const sortProjects = (a, b) => {
  return new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
}

export function ProjectsSummaries() {
  const context = useLandProjectsContext()
  const { landProjects, loading } = context

  if (loading) return <CenteredLoader />

  if (!loading && !landProjects?.length) return <NoProjects />

  const sorted = [...landProjects].sort(sortProjects)

  return (
    <Page title='Projects'>
      <Row className='h-full flex-1 overflow-hidden'>
        <Column className='relative h-full w-full max-w-[600px] gap-y-16 overflow-auto p-20 pb-80'>
          <Row className='row-vcenter justify-between'>
            <ViewTitle>Projects</ViewTitle>
            <Button to='/projects/new' icon='add' variant='secondary'>
              New Project
            </Button>
          </Row>
          {!loading &&
            sorted.map((landProject) => {
              return (
                <div key={landProject._id}>
                  <Link to={`/project/${landProject.uName}/intake`}>
                    <ProjectCard landProject={landProject} noStats />
                  </Link>
                </div>
              )
            })}
        </Column>

        <div className='hidden md:flex m-20 ml-0 flex-1 overflow-hidden rounded-lg border border-ink-200 bg-[#FCF9F4]'>
          <ProjectsMap landProjects={landProjects} hideTCO2 />
        </div>
      </Row>
    </Page>
  )
}

function NoProjects() {
  return (
    <div className={`items-center col flex h-full relative`}>
      <Column className='center mt-[13vh] relative z-10 bg-white rounded-lg px-32 py-40 md:shadow-xl md:ring-1 md:ring-ink-100'>
        <h3 className='text-20 mb-12 font-bold text-ink-900'>Welcome to LandOS!</h3>
        <p className='text-center'>
          To get started, please create a new project. <br /> Then, and fill the scoping
          assessment.
          <br />
          And, if you have polygons for your project please upload them.
        </p>
        <div className='mt-24'>
          <Button variant='primary' to='/projects/new'>
            <Icon icon='add' />
            Create new project
          </Button>
        </div>
      </Column>

      <img
        className='absolute top-0 bottom-0 -left-[400px] mix-blend-multiply pointer-events-none'
        src={require(`~/assets/images/empty-background.png`)}
        alt='background'
      />
      <img
        className='absolute top-0 bottom-0 -right-[400px] mix-blend-multiply pointer-events-none'
        src={require(`~/assets/images/empty-background.png`)}
        alt='background'
      />
    </div>
  )
}
