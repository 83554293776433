import React, { PropsWithChildren } from 'react'
import { Map, MapProps, MapProvider, MapRef } from 'react-map-gl'
import { MAPBOX_TOKEN, mapboxBaseStyle } from '~/services/mapbox'

import { easings } from '~/utils'
import { getCountryBoundsByCode3 } from '../../services/countries'

type Props = PropsWithChildren<{
  ref?: MapRef
  controls?: any // TODO type better?
  initialCountryCode3?: string
  className?: string
  styles?: React.CSSProperties
}> &
  MapProps

export const BaseMap = React.forwardRef<any, Props>((props: Props, ref: any) => {
  const { children, controls, initialCountryCode3, styles, ...rest } = props

  let initialViewState = {}

  const countryBounds = getCountryBoundsByCode3(initialCountryCode3)

  if (initialCountryCode3) {
    // TODO POLISH change duration based on country size
    initialViewState = {
      bounds: countryBounds,
      fitBoundsOptions: { duration: 1000, easing: easings.easeInQuart },
    }
  }

  return (
    <div className='relative h-full w-full [&>div>div>canvas]:!outline-none'>
      <MapProvider>
        {controls}
        <Map
          ref={ref}
          attributionControl={false}
          mapboxAccessToken={MAPBOX_TOKEN}
          style={{ width: '100%', height: '100%', ...styles }}
          mapStyle={mapboxBaseStyle}
          initialViewState={initialViewState}
          {...rest}
        >
          {children}
        </Map>
      </MapProvider>
    </div>
  )
})
