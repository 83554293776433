import React, { PropsWithChildren } from 'react'
import Icon from '~/components/atomic/Icon'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '~/components/ui/dropdown-menu'

type Props = PropsWithChildren<{
  content: React.ReactNode
}>

export function Dropdown(props: Props) {
  const { children, content } = props

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>{children}</DropdownMenuTrigger>
      <DropdownMenuContent side='bottom' align='start' sideOffset={4}>
        {content}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

Dropdown.Item = (props) => {
  const { children, icon, ...rest } = props
  return (
    <DropdownMenuItem {...rest}>
      {icon && <Icon icon={icon} className='mr-8' />}
      {children}
    </DropdownMenuItem>
  )
}
