import { Layer } from 'react-map-gl'

type Props = {
  color?: string
}

export function CountryBoundariesLinesLayer(props: Props) {
  const { color = '#cdc2ac' } = props

  return (
    <>
      <Layer
        id='country-boundaries-lines'
        type='line'
        source='countryBoundaries'
        source-layer='country_boundaries'
        layout={{
          'line-cap': 'square',
          'line-join': 'round',
          'line-round-limit': 2,
        }}
        paint={{ 'line-width': 0.5, 'line-opacity': 1, 'line-color': color }}
      />
      <Layer
        id='country-boundaries-fills'
        type='fill'
        source='countryBoundaries'
        source-layer='country_boundaries'
        paint={{ 'fill-opacity': 0 }}
      />
    </>
  )
}
