import { state } from '~/state'

const SUPER_ADMINS_USER_NAMES = ['luke', 'vera', 'natalie', 'karo', 'bernat', 'indira']
export class AuthedUser {
  email: string
  first_name: string
  last_name: string
  roles: ['investor' | 'admin' | unknown]
  session_id: string
  status: string
  username: string
  _id: string
  created_at: string
  updated_at: string

  constructor(user: Partial<AuthedUser>) {
    return Object.assign(this, user)
  }

  get isAdmin() {
    // TODO remove this after Eearthshot & LandOS's fate is decided
    // Flowcarbon is a potential investor for Earthshot/LandOS
    const isDemoUser = this.email.includes('flowcarbon.com')

    return this.email.includes('@earthshot.eco') || isDemoUser
  }

  get isEarthshotUser() {
    return this.email.includes('@earthshot.eco')
  }

  get isInvestor() {
    return this.roles?.includes('investor')
  }

  get isSuperAdmin() {
    // A Super Admin is a LandOS developer
    // This is an interim solution to being able to see Production data with WIP code
    // Other solutions might involve having earthshot.dev use production data
    const emailUserName = this.email.split('@')[0]
    return SUPER_ADMINS_USER_NAMES.includes(emailUserName)
  }
}

export type UpdatedBy = {
  timestamp: string
  userName: string // @deprecated TODO clean up in database
  userFirstName: string
  userId: string
}

export function getAuthedUserIsAdmin(authedUser: AuthedUser) {
  // TODO this is a bit scrappy
  return authedUser.email.includes('@earthshot.eco')
}

export function getUpdatedByMetaData(): UpdatedBy {
  return {
    timestamp: new Date().toUTCString(),
    userName: state.authedUser?.first_name, // @deprecated TODO clean up in database
    userFirstName: state.authedUser?.first_name,
    userId: state.authedUser?._id,
  }
}
