// @ts-nocheck

import { useEffect } from 'react'
import qs from 'qs'
import {
  resetProcessingState,
  selectLandProjectOrActive,
  state,
  storeLandProject,
} from '~/state'
import { LandProject } from '~/models'
import { logger } from '~/services/logger'
import { Parcel } from '~/models'
import { buildParcelsWithStats, ParcelStats } from '~/models/parcel'
import { buildLandProjectWithStats } from '~/models/landProject'
import { LandProjectStats } from '~/models/landProjectStats'
import ESHttp from '~/utils/ESHttp'
import { ProcessingState } from '../utils/constants'
import { LandProjectDatums } from '~/models/projectDatum'
import { useNavigate } from 'react-router-dom'
import { useLocation } from '~/utils/useLocation'
import { toast } from '~/services/toaster'

type FetchLandProjectResponse = {
  landProject: LandProject
  parcels?: Parcel[]
}

export function useFetchLandProject(
  uName: string,
  options?: {
    skipParcels?: boolean
  }
) {
  const navigate = useNavigate()
  useEffect(() => {
    if (!uName) return

    // Use existing project if available
    if (options?.skipParcels) {
      const storedLandProject = state.landProjects?.find((lp) => lp.uName === uName)
      if (storedLandProject) {
        state.activeLandProjectId = storedLandProject._id
        return
      }
    }

    state.activeLandProjectId = null
    state.parcels = null

    async function fetch() {
      const userId = state.authedUser?._id
      const payload = {
        uName,
        authUserId: userId,
        withStats: 1,
        withParcels: 1,
        withPermissions: 1,
      }
      logger.info('fetchingLandProject ->', payload)

      const data = await fetchDB<FetchLandProjectResponse>('getLandProject', payload)
      logger.info('onFetchLandProject', data)

      const { valid, msg, status, landProject, parcels } = data
      if (valid === 1) {
        state.activeLandProjectId = landProject._id
        storeLandProject(landProject)
        state.parcels = parcels
      } else {
        if (status === 403) {
          toast.error(
            `This account (${state.authedUser.email}) doesn't have access to this project (${uName}).`,
            { duration: 8_000 }
          )
        }
        if (status === 404) {
          toast.error(`This project (${uName}) couldn't be found.`)
        }
        navigate('/')
        logger.error('onGetLandProject', 'error loading project', msg)
      }
    }

    fetch()
  }, [uName])
}

type SaveProjectResponse = {
  landProject: LandProject
  landProjectStats: LandProjectStats
  parcels: Parcel[]
  parcelsStats: ParcelStats[]
}

export async function saveProjectWithParcels(
  landProjectData: Partial<LandProject>,
  parcelsData?: Partial<Parcel>[],
  isByParcelBulks?: boolean,
  isLastBatch?: boolean
) {
  const userId = state.authedUser?._id
  const landProject = new LandProject(landProjectData)
  const data: any = {
    landProject: landProject,
    userId: userId,
    parcels: parcelsData,
    isLastBatch,
  }

  logger.info('saveProject', data)
  let uploadMethod = 'saveProject'
  if (!parcelsData) uploadMethod = 'saveProjectWithoutParcels'
  if (isByParcelBulks) uploadMethod = 'saveProjectByParceBulks'

  try {
    state.landProjectProcessing = ProcessingState.PROCESSING
    const response = await fetchDB<SaveProjectResponse>(uploadMethod, data)

    if (response.valid === 1) {
      state.landProjectProcessing = ProcessingState.SUCCESS
      onProjectSaved(response)
    } else {
      state.landProjectProcessing = ProcessingState.FAILED
      logger.error('onProjectSaved', 'error Saving Project', response.msg)
    }

    resetProcessingState()

    return response
  } catch (error) {
    logger.error('saveProject', error)
    return null
  }
}

export async function saveProjectByParcelBulks(
  landProjectData: Partial<LandProject>,
  isLast: boolean,
  parcelsData?: Partial<Parcel>[]
) {
  try {
    const response = await saveProjectWithParcels(
      landProjectData,
      parcelsData,
      true,
      isLast
    )
    return response
  } catch (e) {
    logger.error(`saveProjectByParcelBulks -> Error save project by parcel bulk: ${e}`)
    return null
  }
}

export async function saveProjectDatums(
  datums: LandProjectDatums,
  landProjectId?: string
) {
  const landProject = selectLandProjectOrActive(landProjectId)

  const existingDatums = landProject?.data || {}
  const newLandProject: LandProject = {
    ...landProject,
    // TODO there's a typo in the db model: assessements => assessments
    data: {
      ...existingDatums,
      ...datums,
    },
  }

  return saveProjectData(newLandProject, landProjectId)
}

/**
 * Save a given project data to the db
 */
export async function saveProjectData(
  partialLandProject: Partial<LandProject>,
  landProjectId?: string
) {
  logger.info('saveProjectData', partialLandProject)

  const landProject = selectLandProjectOrActive(landProjectId)

  const userId = state.authedUser?._id

  const payload = {
    userId,
    landProject: {
      ...landProject,
      ...partialLandProject,
    },
  }

  try {
    // Optimistic save
    storeLandProject(payload.landProject)

    const res = await fetchDB<{ landProject: LandProject }>(
      'saveProjectWithoutParcels',
      payload
    )

    if (res.valid === 1) {
      if (partialLandProject.xStats) {
        const xStats = partialLandProject.xStats
        const landProjectWithStats = { ...res.landProject, xStats }
        logger.info('onProjectDataSavedWithStats', landProjectWithStats)
        storeLandProject(landProjectWithStats)
      } else {
        logger.info('onProjectDataSaved', res.landProject)
        storeLandProject(res.landProject)
      }
    } else {
      logger.error('onProjectDataSaved', 'error Saving Project', res.msg)
    }
  } catch (error) {
    toast.error(`Error saving project data: ${error}`)
    logger.error('saveProjectData error:', error)
  }
}

export function onProjectSaved(data: SaveProjectResponse) {
  // const landProject = new LandProject(data.landProject)
  const landProjectWithStats = buildLandProjectWithStats(
    data.landProject,
    data.landProjectStats
  )
  storeLandProject(landProjectWithStats)

  logger.info('onProjectSaved -> Land Project', landProjectWithStats)

  if (data.parcels && data.parcels.length > 0 && data.parcelsStats) {
    const parcels = buildParcelsWithStats(data.parcels, data.parcelsStats)
    state.parcels = parcels
    logger.info('onProjectSaved -> Parcels', data)
  }
}

export async function removeProject(landProjectId) {
  logger.info('removeProject', landProjectId)
  const res = await fetchDB('removeProject', { landProjectId })
  if (res.valid === 1) {
    state.landProjects = state.landProjects.filter(
      (landProject) => landProject._id !== landProjectId
    )
  }

  return res
}

export async function archiveProject(request) {
  logger.info('archiveProject', request['landProjectId'])
  const res = await fetchDB('archiveProject', request)
  if (res.valid === 1) {
    state.landProjects = state.landProjects.filter(
      (landProject) => landProject._id !== request['landProjectId']
    )
  }

  return res
}

export async function fetchLandProjectStats(landProjectId: string, withYears = true) {
  const res = await fetchDB<{ landProjectStats: LandProjectStats[] }>(
    'getLandProjectStatsCache',
    {
      landProjectId,
      withYears: withYears ? 1 : 0,
    }
  )
  return res.landProjectStats
}

export async function getLandProjectsStatsProjections({
  landProjectId,
  withYears,
}: {
  landProjectId: string
  withYears?: boolean
}) {
  const params = {
    landProjectId,
    withYears: withYears ? 1 : 0,
  }
  const res = await fetchDB(
    `landProjectStats/projections?${qs.stringify(params)}`,
    {},
    'GET'
  )
  return res
}

// Parcels
export function saveParcel() {}

export type DefaultReponse = {
  status?: number
  valid: number
  msg: string
}

export async function fetchDB<Response = any>(route, data = {}, method = 'POST') {
  try {
    if (!route) return null
    const response: Response & DefaultReponse = await ESHttp.Go(method, route, data).then(
      (res) => res.json()
    )
    return response
  } catch (error) {
    throw error
  }
}

export async function fileUpload<T = any>(route, data, method = 'POST') {
  try {
    if (!route) return null
    const response: T & DefaultReponse = await ESHttp.Upload(method, route, data).then(
      (res) => res.json()
    )
    return response
  } catch (error) {
    throw error
  }
}

export async function polygonFileUpload<T = any>(
  route,
  data,
  method = 'POST',
  baseUrl = process.env.REACT_APP_URL_SIMEARTH
) {
  try {
    if (!route) return null
    const response: T & DefaultReponse = await ESHttp.Upload(
      method,
      route,
      data,
      '',
      baseUrl
    ).then((res) => res.json())
    return response
  } catch (error) {
    throw error
  }
}

export async function useGetPortfolioLandProjects() {
  const navigate = useNavigate()

  const location = useLocation()

  useEffect(() => {
    const shouldReset =
      state.landProjects?.length === 1 &&
      location.state?.from === state.landProjects[0].uName

    if (shouldReset) state.landProjects = []

    fetch()

    async function fetch() {
      const userId = state.authedUser?._id
      const data = await fetchDB<{ landProjects: LandProject[] }>('getPortfolio', {
        userId,
      })
      const { valid, landProjects } = data

      logger.info('getPortfolio response -> ', data)

      if (!valid) {
        // Insufficent permissions or error. Send user to root
        // Core auth/navigation logic will decide where to send the user
        navigate('/')
      }

      state.landProjects = landProjects
    }
  }, [])
}
