import { useState } from 'react'
import mapboxgl, { PaddingOptions } from 'mapbox-gl'
import { MapRef } from 'react-map-gl'
import {
  FeatureCollection,
  getFeatureCollectionFromParcels,
  getFeatureCollectionFromReferenceRegionCoordinates,
  unionizeFeatureCollections,
} from '~/utils/geo'
import { bbox } from '@turf/turf'
import { logger } from '~/services/logger'
import slugify from 'slugify'

export const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN
mapboxgl.accessToken = MAPBOX_TOKEN

if (!MAPBOX_TOKEN) throw '.env Mapbox Access token is undefined'

export const mapboxBaseStyle = {
  version: 8,
  sources: {},
  glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
  layers: [],
}

export class MapLayer {
  id: string
  label: string
  theme?: 'green' | 'red' | 'blue'
  visible?: boolean
  featureCollection?: FeatureCollection

  // Raster Layer
  url?: string
  tileSize?: number

  constructor(data: {
    label: string
    featureCollection?: FeatureCollection
    theme?: string
  }) {
    return Object.assign(this, { ...data, id: slugify(data.label) })
  }
}

export function useMapLayers(initalLayers = []) {
  // TODO move map layer to this file
  const [layers, setLayers] = useState<MapLayer[]>(initalLayers)
  function upsertLayer(layer: MapLayer) {
    if (!layer) return
    const layerIndex = layers?.findIndex((l) => l.id === layer.id)
    if (layerIndex > -1) {
      setLayers([...layers.slice(0, layerIndex), layer, ...layers.slice(layerIndex + 1)])
    } else {
      setLayers((prev) => [...prev, layer])
    }
  }

  return { layers, upsertLayer }
}

export function fitMap(
  map: MapRef,
  featureCollection,
  fitBoundsOptions: mapboxgl.FitBoundsOptions = {}
) {
  if (!map) return logger.warn('no map ref')

  const boundingBox = bbox(featureCollection)
  const adjustedPadding = adjustPaddingForAspectRatio(fitBoundsOptions.padding || 100)

  map?.fitBounds(boundingBox as any, {
    ...fitBoundsOptions,
    padding: adjustedPadding,
  })
}

export function fitMapToLayers(map: MapRef, layers: MapLayer[]) {
  if (layers?.length == 0) return
  const unionized = unionizeFeatureCollections(
    layers.filter((l) => l.featureCollection).map((l) => l.featureCollection)
  )
  fitMap(map, unionized)
}

export function landProjectParcelsToMapLayer(parcels) {
  if (!parcels?.length) return null
  return new MapLayer({
    label: 'Project area',
    featureCollection: getFeatureCollectionFromParcels(parcels),
  })
}

export function referenceRegion5kmToMapLayer(referenceRegionCoordinates5km) {
  if (!referenceRegionCoordinates5km?.length) return null
  return new MapLayer({
    label: '5km Reference region',
    featureCollection: getFeatureCollectionFromReferenceRegionCoordinates(
      referenceRegionCoordinates5km
    ),
    theme: 'white',
  })
}

export function referenceRegionToMapLayer(referenceRegionCoordinates) {
  if (!referenceRegionCoordinates?.length) return null
  return new MapLayer({
    label: '20km Reference region',
    featureCollection: getFeatureCollectionFromReferenceRegionCoordinates(
      referenceRegionCoordinates
    ),
    theme: 'red',
  })
}

export function coordinatesToMapLayer(label, featureCollection, theme = 'green') {
  if (!featureCollection?.features?.length) return null
  return new MapLayer({
    label,
    featureCollection: featureCollection,
    theme: theme,
  })
}

export function adjustPaddingForAspectRatio(padding: number | PaddingOptions) {
  // Get the width and height of the screen
  const width =
    window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
  const height =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight

  // Calculate the aspect ratio
  const aspectRatio = width / height

  // Adjust the padding based on the aspect ratio
  let adjustedPadding: PaddingOptions = {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  }

  if (typeof padding === 'number') {
    // If padding is a number, set it as the top, bottom, left, and right padding
    adjustedPadding = {
      top: padding,
      bottom: padding,
      left: padding,
      right: padding,
    }
  } else {
    // If padding is PaddingOptions, use it as the padding
    adjustedPadding = padding
  }

  if (aspectRatio < 1) {
    // If the aspect ratio is less than 1, adjust the padding based on the height of the screen
    adjustedPadding.top *= aspectRatio
    adjustedPadding.bottom *= aspectRatio
  }

  // Pass the adjusted padding to the fitBounds function
  return adjustedPadding
}
