// @ts-nocheck

import { Helmet } from 'react-helmet'

type Props = {
  title: string
}

export function Head(props: Props) {
  const { title } = props

  return (
    <Helmet>
      <title>{title ? `${title} | LandOS` : 'LandOS'}</title>
    </Helmet>
  )
}
