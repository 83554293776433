import { useState, useEffect, useMemo } from 'react'
import { fetchDB } from '~/services/db'
import { ComplexTable } from '~/components/atomic/table/ComplexTable'
import { PageTitle } from '~/styles/main'
import { shortDateBuilder } from '~/utils/dates'
import { RolesInput } from '~/components/project/RolesInput'
import { createColumnHelper } from '@tanstack/react-table'
import { User } from '~/types/user'
import { logger } from '~/services/logger'
import { CenteredLoader } from '~/components/atomic/CenteredLoader'

const columnHelper = createColumnHelper<User>()

export function UsersAdmin(): JSX.Element {
  const columns = useMemo(
    () => [
      columnHelper.accessor('first_name', {
        header: 'Name',
      }),
      columnHelper.accessor('last_name', {
        header: 'Last Name',
      }),
      columnHelper.accessor('email', {
        header: 'Email',
      }),
      columnHelper.accessor('roles', {
        header: 'Roles',
        cell: (props) => {
          const roles = props.row.original.roles
          const id = props.row.original._id

          return <RolesInput roles={roles} userId={id} />
        },
      }),
      columnHelper.accessor('created_at', {
        header: 'Created At',
        cell: (info) => <span>{info ? shortDateBuilder(info.getValue()) : ''}</span>,
      }),
    ],
    []
  )

  const [filteredUsersData, setFilteredUsersData] = useState([])
  const [loading, setLoading] = useState(false)

  const fetchData = async () => {
    // TODO: Move pagination and search to server side.
    setLoading(true)
    const res = await fetchDB('searchUsers', {
      searchText: 'investor',
      searchFields: ['roles'],
    })

    setFilteredUsersData(res?.users)
    setLoading(false)

    if (res.valid) {
      setFilteredUsersData(res.users)
    } else {
      setFilteredUsersData([])
      logger.error('onFetchUsers', 'Unable to fecth user data')
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div className='m-40'>
      <PageTitle>Users Administration</PageTitle>
      {loading ? (
        <CenteredLoader>Loading Users Data</CenteredLoader>
      ) : (
        <ComplexTable
          data={filteredUsersData || {}}
          resultsPerPage={50}
          columns={columns}
          withGlobalFilter
          withPagination
        />
      )}
    </div>
  )
}
