import { toast as primitiveToast, ToastOptions } from 'react-hot-toast'
import { Toast } from '~/components/atomic/Toast'

export function toast(...args) {
  primitiveToast.apply(null, args)
}

toast.warning = (text: string, options?: ToastOptions) =>
  primitiveToast.custom(
    (props) => <Toast variant='warning' {...props} text={text} />,
    options
  )

toast.error = (text: string, options?: ToastOptions) =>
  primitiveToast.custom(
    (props) => <Toast variant='error' {...props} text={text} />,
    options
  )

toast.info = (text: string, options?: ToastOptions) =>
  primitiveToast.custom(
    (props) => <Toast variant='info' {...props} text={text} />,
    options
  )
