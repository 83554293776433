import { PropsWithChildren } from 'react'

import { Pressable } from '~/components/atomic/Pressable'
import { Head } from '~/components/core/Head'
import Logo from '~/components/core/Logo'

import { Column, Row } from '~/styles'
import { defaultBackgroundImage } from '~/utils/constants'

type Props = PropsWithChildren<{
  title: string
}>

export default function AuthLayout(props: Props) {
  const { children, title } = props

  return (
    <Row className='min-h-full self-stretch'>
      <Head title={title} />
      <img
        alt='Background'
        className='absolute inset-0 h-full w-full bg-green-100 object-cover'
        src={defaultBackgroundImage}
      />

      <Column className='center relative m-auto w-full max-w-[95vw] md:max-w-[400px] self-center rounded-lg bg-white p-20 md:p-40 shadow-lg'>
        <Pressable to='/' className='mb-[4vh]'>
          <Logo />
        </Pressable>
        {children}
      </Column>
    </Row>
  )
}
