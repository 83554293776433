import { useState } from 'react'
import Icon from '~/components/atomic/Icon'
import { InfoBox } from '~/components/atomic/InfoBox'
import { Loader } from '~/components/atomic/Loader'
import { Row } from '~/styles'
import classed from '~/styles/classed'

/**
 * Hook to handle saving state
 * @returns {Object} saving, saved, onSaving, onSaved
 * @example
 * const { saving, saved, onSaving, onSaved } = useSaving()
 */

export function useSaving() {
  // saving state
  const [saving, setSavingState] = useState(false)
  const [saved, setSuccess] = useState(false)

  function onSaving() {
    setSavingState(true)
  }

  function onSaved() {
    setSavingState(false)
    setSuccess(true)

    setTimeout(() => {
      setSuccess(false)
    }, 2000)
  }

  return {
    saving,
    saved,
    onSaving,
    onSaved,
  }
}

export function SavingComponent({ saving, saved }) {
  return (
    <>
      {saving && (
        <Container className='bg-amber-100  text-amber-900'>
          Saving <Loader size={16} />
        </Container>
      )}
      {saved && (
        <Container className='bg-highlight/10 text-highlight'>
          Saved <Icon icon='check-circle' className='text-highlight' />
        </Container>
      )}
    </>
  )
}

const Container = classed(
  'div',
  'flex flex-row row-vcenter gap-x-8 font-medium text-13 rounded-md px-8 py-4'
)
