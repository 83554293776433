import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Input from '~/components/atomic/Input'
import { Button } from '~/components/atomic/Button'
import { Pressable } from '~/components/atomic/Pressable'
import AuthLayout from '~/components/auth/AuthLayout'
import AuthForm from '~/components/auth/AuthForm'
import { setAuthedUser, useAuthedRedirect } from '~/state/auth'
import { getFormData } from '~/utils'
import { AuthedUser } from '~/models/authedUser'
import { fetchDB } from '~/services/db'
import { getSearchParamsMap } from '~/utils/getSearchParamsMap'
import { logger } from '~/services/logger'
import * as analytics from '~/services/analytics'
import { useLocation } from '~/utils/useLocation'

// Keys that offuscate the meaning for a naive security layer.
const obfuscatedParamKeys = {
  roles: 'r',
  investor: 'i',
}

type OnSignUpResponse = {
  valid: number
  user: AuthedUser
  msg: string
}

type SignUpData = {
  email: string
  password: string
  first_name: string
  last_name: string
  roles?: [string]
}

function SignUp() {
  const navigate = useNavigate()
  const location = useLocation()
  let redirectUrl = (location.state as any)?.redirectUrl || '/projects'

  const [error, setError] = useState('')
  const [submitting, setSubmitting] = useState(false)

  const searchParams = getSearchParamsMap(
    location.search || location?.state?.search || ''
  )

  useAuthedRedirect('/projects')
  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    const data = getFormData<SignUpData>(e.currentTarget)
    signUp(data)
  }

  async function signUp(data: SignUpData) {
    logger.info('signUp', data)

    if (searchParams['email']) data.email = searchParams['email']

    if (searchParams[obfuscatedParamKeys.roles] == obfuscatedParamKeys.investor) {
      data.roles = ['investor']
    }

    if (data.email && data.password && data.first_name && data.last_name) {
      setSubmitting(true)
      setError('')

      const response = await fetchDB<OnSignUpResponse>('signup', data)
      onSignUp(response)
    } else {
      setError('Please fill out all fields and try again.')
    }
  }

  async function onSignUp(data: OnSignUpResponse) {
    const { valid, user, msg } = data

    logger.info('onSignUp', data)

    if (valid === 1 && user._id) {
      const data = {
        authUserId: user._id,
      }
      const invitedLandProjectUserRes = await fetchDB('getInvitedLandProjectUser', data)

      if (invitedLandProjectUserRes.valid) {
        const invitedLandProjectUser = invitedLandProjectUserRes.landProjectUser
        const res = await updateLandProjectUser(user._id, invitedLandProjectUser)
      } else if (
        // For invite url backward compatibility with shared projects
        searchParams.email &&
        searchParams.landProjectId &&
        searchParams.permission
      ) {
        const res = await saveAsLandProjectUser(user._id)
        if (res.valid) {
          redirectUrl = `/intake/${searchParams.uName}`
        }
      }

      setAuthedUser(user)
      analytics.track('Sign Up')

      // Redirects
      if (user.roles?.includes('investor')) {
        return navigate('/portfolio')
      } else {
        navigate(redirectUrl)
      }
    } else {
      setError(msg || 'Invalid fields, please try again')
    }
    setSubmitting(false)
  }

  async function updateLandProjectUser(userId, invitedLandProjectUser) {
    const data = {
      landProjectUser: {
        ...invitedLandProjectUser,
        entityType: 'user',
        entityId: userId,
      },
      authUserId: userId,
      setCascadingPermissions: 0,
      skipPermissions: 1,
    }
    const res = await fetchDB('saveLandProjectUser', data)
    return res
  }

  // For invite url backward compatibility with shared projects
  async function saveAsLandProjectUser(userId) {
    const landProjectUser = {
      landProjectId: searchParams.landProjectId,
      entityId: userId,
      entityType: 'user',
      permissions: [searchParams.permission],
    }
    const data = {
      landProjectUser,
      authUserId: userId,
      skipPermissions: 1,
    }
    const res = await fetchDB('saveLandProjectUser', data)
    if (res.valid) {
      redirectUrl = `/intake/${searchParams.uName}`
      logger.info('new user added to as landProjectUser: ', landProjectUser)
    } else {
      setError(res.msg)
    }
    return res
  }

  return (
    <AuthLayout title='Sign Up'>
      <AuthForm onSubmit={onSubmit}>
        <Input
          name='email'
          type='email'
          label='Email'
          placeholder='your@email.com'
          defaultValue={searchParams ? searchParams.email : ''}
          disabled={searchParams.email ? true : false}
        />
        <Input name='password' type='password' label='Password' placeholder='Password' />
        <Input
          name='first_name'
          type='text'
          label='First Name'
          placeholder='First Name'
        />
        <Input name='last_name' type='text' label='Last Name' placeholder='Last Name' />

        <Button
          disabled={submitting}
          variant='primary'
          type='submit'
          submitting={submitting}
        >
          Sign Up
        </Button>

        {error && <div className='text-14 font-medium text-red-500'>{error}</div>}

        <span className='inline text-14'>
          Already have an account?{' '}
          <Pressable className='!inline' to='/login'>
            Log in.
          </Pressable>
        </span>
      </AuthForm>
    </AuthLayout>
  )
}

export { SignUp }
