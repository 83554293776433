import { useState } from 'react'
import Input from '~/components/atomic/Input'
import { Pressable } from '~/components/atomic/Pressable'
import { Row } from '~/styles'
import classed from '~/styles/classed'

export const GlobalFilter = ({ filterValue, handleFilter }) => {
  const [value, setValue] = useState(filterValue || '')

  const handleOnChange = (value) => {
    setValue(value)
    handleFilter(value)
  }

  return (
    <Row className='mb-8'>
      <Input
        label='Search Users'
        name='search'
        className='w-2/4 justify-start'
        onChange={(e) => {
          handleOnChange(e.target.value)
        }}
        value={value}
      />

      <FilterContainer>
        <Pressable
          icon='close'
          iconClasses='-mx-4 mr-4'
          className='py-4 -ml-6'
          onClick={() => handleOnChange('')}
        >
          Clear
        </Pressable>
      </FilterContainer>
    </Row>
  )
}

const FilterContainer = classed(
  'div',
  'row row-vcenter gap-12 py-2 px-8 pr-10 rounded-md self-end h-40'
)
