import { Outlet } from 'react-router-dom'
import { LayoutWithSideMenu } from '~/components/core/LayoutWithSideMenu'
import { ProjectTab } from '~/components/layouts/ProjectTab'
import {
  financialsStore,
  useInitFinancialStore,
} from '~/routes/project/financials/financialsStore'
import { PATH } from '~/utils/constants'
import { useSnapshot } from '~/state'
import { CenteredLoader } from '~/components/atomic/CenteredLoader'

const menu = [
  { to: PATH['vcus-calculations'], label: 'Calculations' },
  { to: PATH['inputs'], label: 'Inputs' },
  { to: PATH['issued'], label: 'Issued' },

  { type: 'title', label: 'Questionnaires' },

  { to: PATH['permanence'], label: 'Permanance' },

  { type: 'title', label: 'Assessments' },

  { to: PATH['permanence-assessment'], label: 'Permanance' },

  { type: 'title', label: 'Work In progress' },
  { to: 'leakage', label: 'Leakage (do not use)' },
  { to: 'baseline', label: 'Baseline Scenario (do not use)' },
]

export function VCUsTabLayout() {
  const storeState = useSnapshot(financialsStore).state
  useInitFinancialStore()

  const content = storeState === 'loading' ? <CenteredLoader /> : <Outlet />

  return (
    <ProjectTab title='VCUs'>
      <LayoutWithSideMenu title='VCUs' menu={menu}>
        {content}
      </LayoutWithSideMenu>
    </ProjectTab>
  )
}
