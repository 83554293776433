import { ComplexTable } from '~/components/atomic/table/ComplexTable'
import TableLayout from '~/components/atomic/table/TableLayout'
import { Numerical } from '~/components/core/Numerical'
import { useFinancialsStore } from '~/routes/project/financials/financialsStore'
import { createColumnHelper } from '@tanstack/react-table'
import { useMemo } from 'react'
import { VCUARR } from '~/types/vcus'
import { reduceData } from '~/utils/index'
import { formatPercent } from '~/utils/units'

const columnHelperARR = createColumnHelper<VCUARR>()

const defaultARRColumnVisibility = {
  baselineUncertainty: false,
}

export function VCUsARRTable(props) {
  const { withColumnVisiblity = true } = props
  const { vcus, variables } = useFinancialsStore()

  const columnsARR = useMemo(
    () =>
      [
        columnHelperARR.accessor('year', {
          header: 'Year',
        }),
        columnHelperARR.accessor('yearDate', {
          id: 'yearDate',
          header: 'Year Date',
          cell: (props) => props.getValue(),
        }),
        columnHelperARR.accessor('cumulativeCarbonProjection', {
          header: 'Ex-ante Cumulative Carbon Projection',
          footer: TableLayout.SumFooter,
        }),
        columnHelperARR.accessor('cumulativeCarbonProjectionPerYear', {
          header: 'Ex-ante Carbon Projection per Year',
          footer: TableLayout.SumFooter,
        }),
        columnHelperARR.accessor('lowerConfidenceInterval', {
          header: 'Ex-ante Lower Confidence Interval',
          footer: TableLayout.SumFooter,
        }),
        columnHelperARR.accessor('upperConfidenceInterval', {
          header: 'Ex-ante Upper Confidence Interval',
          footer: TableLayout.SumFooter,
        }),
        columnHelperARR.accessor('estimatesUncertaintyRate', {
          header: 'Uncertainty discount',
          meta: { type: 'percent' },
        }),
        columnHelperARR.accessor('leakageDisccountRate', {
          header: `Leakage discount (${formatPercent(
            variables?.leakageDisccountRate * 100
          )}%)`,
          meta: { type: 'percent' },
        }),
        columnHelperARR.accessor('baselineEmissions', {
          meta: {
            header: 'Baseline - GHG Emissions (tCO2)',
          },
          header: () => (
            <TableLayout.HeaderWithTooltip tooltip='Update the initial Baseline Emissions value in the Inputs section.'>
              Baseline - GHG Emissions (tCO2)
            </TableLayout.HeaderWithTooltip>
          ),
          footer: TableLayout.SumFooter,
        }),
        columnHelperARR.accessor('baselineUncertainty', {
          header: 'Baseline Uncertainty (tCO2)',
          footer: TableLayout.SumFooter,
        }),
        columnHelperARR.accessor('netGHGEmissionReductions', {
          header: 'Net GHG Emission Reductions per Year (tCO2e)',
          footer: TableLayout.SumFooter,
        }),
        columnHelperARR.accessor('cumulativeNetGHGEmissionReductions', {
          header: 'Cumulative Net GHG Emission Reductions (tCO2e)',
        }),
        columnHelperARR.accessor('riskBuffer', {
          header: `Risk Buffer (${formatPercent(variables?.riskBufferRate * 100)}%)`,
          footer: TableLayout.SumFooter,
        }),
        columnHelperARR.accessor('cumulativeNetGHGEmissionReductionsBuffer', {
          header: 'Cumulative Net GHG Emission Reductions (tCO2e) - Buffer',
        }),
      ].map((column) => ({
        ...column,
        cell: (props) => {
          const cellValue = props.getValue()
          if (column.cell) return cellValue

          const type = column.meta && column.meta['type']
          return (
            <Numerical
              type={type}
              value={cellValue}
              decimals={type === 'percent' ? 0 : 1}
            />
          )
        },
      })),
    [variables, vcus]
  )

  return (
    <ComplexTable
      data={vcus}
      columns={columnsARR}
      withColumnVisiblity={withColumnVisiblity}
      defaultColumnVisibility={defaultARRColumnVisibility}
    />
  )
}
