import React from 'react'

type Props = {
  height?: number
  color?: string
} & React.SVGProps<SVGSVGElement>

export function MapDot(props: Props) {
  const { height = 16, color, ...rest } = props

  return (
    <svg
      height={height}
      viewBox={`0 0 ${height} ${height}`}
      fill='none'
      {...rest}
      className='rounded-full shadow-sm ring-1 ring-ink-900/60'
    >
      <circle cx={height / 2} cy={height / 2} r={height / 2} fill={color} />
    </svg>
  )
}
