import { Option } from '~/types'
import { capitalizeFirstLetter } from '../utils/index'

export const earthshotUser = [
  'eric',
  'pedro',
  'nata',
  'olivia',
  'steve',
  'anika',
  'polly',
  'ezra',
  'lars',
  'ceci',
  'rafo',
  'lucia',
  'joe',
  'abel',
  'trevor',
  'dani',
  'camila',
  'andrew',
  'tito',
  'margaux',
].sort((a, b) => a.localeCompare(b))

const earthshotUsersOptions: Option[] = getUserOptions(earthshotUser)

const unassignedOption = { label: 'Unassigned', value: 'unassigned' }

export function getEarthshotUsersOptions(skipUnassigned = false) {
  return skipUnassigned
    ? earthshotUsersOptions
    : [unassignedOption, ...earthshotUsersOptions]
}

export function getUserOptions(users: string[]) {
  return (
    users?.map((u) => ({
      label: capitalizeFirstLetter(u),
      value: u,
    })) || []
  )
}
