import { logger } from '~/services/logger'

export function reduceData<TData extends any[], TKey extends keyof TData[number]>(
  data: TData,
  key: TKey
) {
  return data?.reduce((prev, curr) => prev + curr[key], 0)
}

export function unslugify(str: string) {
  const result = str.replace(/\-/g, ' ')
  return result.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

export function capitalizeFirstLetter(string): string {
  if (!string) return ''
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const isDev = () => process.env.NODE_ENV === 'development'

function escapeRegExp(str: string) {
  return str.replace(/[\\^$.*+?()[\]{}|]/g, '\\$&') // (A)
}

export function replaceAll(str, find, replace) {
  return str.replace(new RegExp(escapeRegExp(find), 'g'), replace)
}

export function groupArrayOfObjects(list, key) {
  return list.reduce(function (rv, x) {
    ;(rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})
}

export const easings = {
  easeInQuart: (x) => x * x * x * x,
}

export function clamp(value, min, max) {
  return Math.max(min, Math.min(max, value))
}

// form utils
export function getFormData<ReturnData>(form): ReturnData {
  const formData = new FormData(form)
  const data: ReturnData | any = {}

  const numberInputKeys = []
  const numberInputFields = form.querySelectorAll('input[type="number"]')
  numberInputFields.forEach((input) => {
    const { name } = input
    numberInputKeys.push(name)
  })

  formData.forEach((value, key) => {
    const parsedValue = numberInputKeys.includes(key) ? Number(value) : value

    if (isNil(parsedValue)) return

    if (data[key]) {
      // if item already exists
      // And it's not an array yet, make it an array and add the value
      if (typeof data[key] === 'string') {
        data[key] = [...[data[key]], parsedValue]
      } else {
        // Otherwise add the value to the array
        data[key].push(parsedValue)
      }
    } else {
      data[key] = parsedValue
    }
  })

  logger.info('getFormData -> data:', data)
  return data
}

export function getFormDataFromElements(elements) {
  const formData = []

  for (const el of elements) {
    if (el.name) formData.push({ value: el.value, name: el.name })
  }
  return formData
}

/**
 * creates an array and makes sure it doesn't break
 * mostly paired with makeArray(count).map(i => iterateOverSomething)
 */
export function makeArray(length) {
  const count = Math.max(0, Math.round(length)) || 0
  return new Array(count).fill(0)
}

export function fillArray(
  length: number,
  iterator: (value: any, index: number, array: any[]) => any
) {
  const count = Math.max(0, Math.round(length)) || 0
  return new Array(count).fill(0).map(iterator)
}

export const isNil = (input: any) => input === null || input === undefined
export const exists = (input: any) => !isNil(input)

export function pluralize(word: string, count: number) {
  return count === 1 || word[word.length - 1] == 's' ? word : `${word}s`
}

export function sumArray(array: any[]) {
  return array.reduce((prev, current) => prev + parseFloat(current), 0)
}

/**
 * returns 1 or 0 based on a boolean value, or return null if the value is not valid
 */
export function booleanToIntBool(value: boolean) {
  if (isNil(value) || typeof value !== 'boolean') return null
  return value ? 1 : 0
}

export const saveFile = (filename: string, dataObjToWrite: any) => {
  const blob = new Blob([JSON.stringify(dataObjToWrite)], { type: 'text/json' })
  const link = document.createElement('a')

  link.download = filename
  link.href = window.URL.createObjectURL(blob)
  link.dataset.downloadurl = ['text/json', link.download, link.href].join(':')

  const evt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  })

  link.dispatchEvent(evt)
  link.remove()
}

/**
 * @description
 * Takes an Array<V>, and a grouping function,
 * and returns a Map of the array grouped by the grouping function.
 *
 * @param list An array of type V.
 * @param keyGetter A Function that takes the the Array type V as an input, and returns a value of type K.
 *                  K is generally intended to be a property key of V.
 *
 * @returns Map of the array grouped by the grouping function.
 */
//export function groupBy<K, V>(list: Array<V>, keyGetter: (input: V) => K): Map<K, Array<V>> {
//    const map = new Map<K, Array<V>>();
export function groupBy(list, keyGetter) {
  const groups = {}
  list.forEach((item) => {
    const key = keyGetter(item)
    const collection = groups[key]
    if (!collection) {
      groups[key] = [item]
    } else {
      collection.push(item)
    }
  })
  return groups
}

export function getSteppedRange(start: number, end: number, steps = 10): number[] {
  const step = (end - start) / (steps - 1)
  const arr = []

  for (let i = 0; i < steps - 1; i++) {
    arr.push(step * i + start)
  }

  arr.push(end)

  return arr
}

export const average = (array: number[]) => array?.reduce((a, b) => a + b) / array?.length

export const simpleClone = (obj: any) => {
  if (!obj) return {}
  return JSON.parse(JSON.stringify(obj))
}

export const arrayInsert = (arr, index, newItem) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted item
  newItem,
  // part of the array after the specified index
  ...arr.slice(index),
]

// From Lodash
function isObjectLike(value) {
  return typeof value === 'object' && value !== null
}

// From Lodash
function getTag(value) {
  if (value == null) {
    return value === undefined ? '[object Undefined]' : '[object Null]'
  }
  return toString.call(value)
}

// From Lodash
export function isNumber(value) {
  return (
    typeof value === 'number' ||
    (isObjectLike(value) && getTag(value) == '[object Number]')
  )
}

export const getUniques = (arr: any[]) => [...new Set(arr)]

export function lazyJSONImport(fileName: string): Promise<any> {
  return new Promise((res, rej) => {
    import(`~/assets/data/${fileName}.json`)
      .then((data) => {
        res(data?.default)
      })
      .catch((err) => {
        throw Error(err)
      })
  })
}

export function standardDeviation(arr, usePopulation = false) {
  const mean = arr.reduce((acc, val) => acc + val, 0) / arr.length
  return Math.sqrt(
    arr
      .reduce((acc, val) => acc.concat((val - mean) ** 2), [])
      .reduce((acc, val) => acc + val, 0) /
      (arr.length - (usePopulation ? 0 : 1))
  )
}

export function snakeCaseToLabel(str: string) {
  if (!str) return ''
  return capitalizeFirstLetter(str.replace(/_/g, ' '))
}
