import * as turf from '@turf/turf'
import { countryBounds } from '~/data/countryBounds'

// Based on export from this internal Google Spredsheet
// See spreadsheet for more info on sources
// https://docs.google.com/spreadsheets/d/14eiUB0Lhss4vZ4Ib6-WYH4uSBMhpqRuvBeepPTxlMvA/edit#gid=479623475
import countries from '~/data/countries.json'

export function getCountryNameByCode(countryCode: string) {
  const countryName =
    countries.find((c) => c['code-2'] === countryCode)?.name || countryCode
  return countryName
}

export function getCountryCodeByName(countryName: string) {
  const countryCode = countries.find((c) => c.name === countryName)?.['code-2']
  return countryCode
}

export function getCountryCode3ByCode2(code2: string) {
  const countryCode = countries.find((c) => c['code-2'] === code2)?.['code-3']
  return countryCode
}

export function getCountryCode3ByName(countryName: string) {
  const countryCode = countries.find((c) => c.name === countryName)?.['code-3']
  return countryCode
}

type BBox2d = [number, number, number, number]

export function getCountryBoundsByCode(countryCode: string) {
  const countryName = getCountryNameByCode(countryCode)
  return getCountryBoundsByName(countryName)
}

export function getCountryBoundsByName(countryName: string) {
  if (!countryName) return null
  const countryFeature = countryBounds.features.find((f) =>
    f.properties.name.includes(countryName)
  )
  if (!countryFeature) return null
  const bounds = turf.bbox(countryFeature.geometry) as BBox2d
  return bounds
}

export function getCountryBoundsByCode3(code3: string) {
  if (!code3) return null
  const countryFeature = countryBounds.features.find((f) => f.id === code3)
  if (!countryFeature) return null
  const bounds = turf.bbox(countryFeature.geometry) as BBox2d
  return bounds
}

export function getCountryGeometryByName(countryName: string) {
  if (!countryName) return null
  const countryFeature = countryBounds.features.find((f) =>
    f.properties.name.includes(countryName)
  )

  return countryFeature
}

export function getCountryGeometryByCode3(countryCode3: string) {
  if (!countryCode3) return null
  const countryFeature = countryBounds.features.find((f) => f.id === countryCode3)

  return countryFeature
}

export function getCountriesOptions() {
  return countries.map((country) => ({ label: country.name, value: country['code-2'] }))
}

export function getCountryCodesOptions(countryCodes: string[]) {
  return countryCodes?.map((c) => ({ value: c, label: getCountryNameByCode(c) })) || []
}

export function getCountryGovernanceScore(codeAlpha2: string) {
  return countries.find((country) => country['code-2'] === codeAlpha2)?.governanceAvg
}

export const latAmCountries = [
  'Belize',
  'Costa Rica',
  'El Salvador',
  'Guatemala',
  'Honduras',
  'Mexico',
  'Nicaragua',
  'Panama',
  'Argentina',
  'Bolivia',
  'Brazil',
  'Chile',
  'Colombia',
  'Ecuador',
  'French Guiana',
  'Guyana',
  'Paraguay',
  'Peru',
  'Suriname',
  'Uruguay',
  'Venezuela',
  'Cuba',
  'Dominican Republic',
  'Haiti',
  'Guadeloupe',
  'Martinique',
  'Puerto Rico',
  'Saint Kitts and Nevis',
  'Saint Pierre and Miquelon',
  'Saint Vincent and the Grenadines',
  'Saint Lucia',
]

export const amazoniaCountries = [
  'Brazil',
  'Bolivia',
  'Peru',
  'Ecuador',
  'Colombia',
  'Venezuela',
  'Guyana',
  'Suriname',
]

export const jrcTropicalCountires = [
  "Lao People'S Democratic Republic",
  'Viet Nam',
  'Cambodia',
  'Thailand',
  'Myanmar',
  'Malaysia',
  'Singapore',
  'Indonesia',
  'Philippines',
  'Papua New Guinea',
  'Congo, The Democratic Republic of the',
  'Central African Republic',
  'Congo',
  'Gabon',
  'Equatorial Guinea',
  'Cameroon',
  'Nigeria',
  'Ghana',
  "Cote d'Ivoire",
  'Sierra Leone',
  'Trinidad and Tobago',
  'Belize',
  'Costa Rica',
  'El Salvador',
  'Guatemala',
  'Honduras',
  'Mexico',
  'Nicaragua',
  'Panama',
  'Bolivia',
  'Brazil',
  'Colombia',
  'Ecuador',
  'French Guiana',
  'Suriname',
  'Venezuela',
  'Guyana',
  'Cuba',
  'Panama',
  'Costa Rica',
  'Dominican Republic',
  'Haiti',
  'Honduras',
  'Puerto Rico',
  'Jamaica',
]
