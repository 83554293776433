import { SDGImage } from '~/components/core/SDGImage'
import { P } from '~/components/profile/shared'
import { parseValueOptionsAsOptions, getLandProjectDatum } from '~/models/projectDatum'
import { useActiveLandProject } from '~/state'

import { Column, Row } from '~/styles'
import { SDGKey } from '~/components/core/SDGImage'

export function SDGBenefits() {
  const { landProject } = useActiveLandProject()

  const sdgDescription = getLandProjectDatum(landProject, 'sdg-description')?.value
  const applicableSDGSDatum = getLandProjectDatum(landProject, 'applicable-sdgs')
  const sdgs = parseValueOptionsAsOptions(applicableSDGSDatum)

  return (
    <Column>
      <P className='mb-24'>{sdgDescription}</P>
      <Row className='flex-wrap gap-8'>
        {sdgs?.map((sdg, key) => {
          return <SDGImage sdgKey={sdg?.value as SDGKey} key={key} />
        })}
      </Row>
    </Column>
  )
}
