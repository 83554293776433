type Props = {
  className?: string
  fill?: string
  stroke?: string
}

export default function LogoIcon(props: Props) {
  const { className, fill = '#488877', stroke = '#488877' } = props

  return (
    <svg
      viewBox='0 0 512 512'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <circle
        cx='256'
        cy='256'
        r='241.778'
        fill={fill}
        fillOpacity='0.2'
        stroke={stroke}
        strokeWidth='28.4444'
      />
      <path
        d='M45.037 360.296C101.926 324.741 161.185 279.703 267.852 320C374.518 360.297 362.667 417.186 362.667 464.593'
        stroke={stroke}
        strokeWidth='28.4444'
      />
      <path
        d='M75.8518 424.295C118.518 369.777 227.556 338.962 244.148 381.629C260.741 424.296 199.111 440.889 177.778 483.556'
        stroke={stroke}
        strokeWidth='28.4444'
      />
      <path
        d='M23.7037 301.037C66.3704 274.963 118.519 234.667 232.296 244.148C346.074 253.629 405.333 315.26 478.815 360.297'
        stroke={stroke}
        strokeWidth='28.4444'
      />
      <path
        d='M469.333 137.481C417.185 137.481 355.556 142.222 355.556 189.63C355.556 237.037 445.63 260.74 497.778 251.259'
        stroke={stroke}
        strokeWidth='28.4444'
      />
      <path
        d='M417.185 80.5925C338.963 92.4444 301.037 149.333 220.444 180.148C139.852 210.963 56.8889 218.074 18.963 201.481'
        stroke={stroke}
        strokeWidth='28.4444'
      />
      <path
        d='M54.5186 137.482C111.407 158.815 149.333 149.333 194.37 132.741C239.407 116.148 301.037 71.1112 365.037 42.6667'
        stroke={stroke}
        strokeWidth='28.4444'
      />
      <path
        d='M113.778 63.9999C163.556 106.667 241.778 56.8888 286.815 21.3333'
        stroke={stroke}
        strokeWidth='28.4444'
      />
    </svg>
  )
}
