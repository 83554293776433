import * as turf from '@turf/turf'
import { cn } from '~/utils/cn'
import { useEffect, useRef, useState } from 'react'
import { MapRef, Marker } from 'react-map-gl'
import { Badge } from '~/components/atomic/Badge'
import Link from '~/components/atomic/Link'
import { StatItems, StatValues } from '~/components/core/Stat'
import { CountryBoundariesLinesLayer } from '~/components/map/layers/CountryBoundariesLinesLayer'
import { HighlightCountriesLayer } from '~/components/map/layers/HighlightCountriesLayer'
import { BackgroundLayer } from '~/components/map/layers/BackgroundLayer'
import { MapSources } from '~/components/map/layers/MapSources'
import { WaterLayer } from '~/components/map/layers/WaterLayer'
import { MapDot } from '~/components/map/MapDot'
import { MapPopup } from '~/components/map/MapPopup'
import { LandProject } from '~/models'
import {
  calculateCarbonStats,
  getColorByProjectType,
  getLandMeta,
  getLandProjectCountry,
  getProjectLatLong,
  ProjectType,
} from '~/models/landProject'
import { Column, Row } from '~/styles'
import { formatArea, formatNumber } from '~/utils/units'
import { BaseMap } from '~/components/map/BaseMap'

type Props = {
  landProjects: LandProject[]
  className?: string
  hideTCO2?: boolean
  controls?: React.ReactChild
}

export function ProjectsMap(props: Props) {
  const { className, landProjects, hideTCO2, controls } = props
  const [selectedLandProject, setSelectedLandProject] = useState(null)
  const { markers, countries } = getMarkersAndCountries(landProjects)
  const mapRef = useRef<MapRef>(null)

  const pins = markers.map((marker, index) => {
    return (
      <Marker
        key={`marker-${index}`}
        longitude={marker.long}
        latitude={marker.lat}
        anchor='center'
      >
        <MapDot
          color={marker.color}
          onMouseOver={(e) => {
            setSelectedLandProject({
              ...landProjects.find((lp) => lp._id == marker.id),
              lat: marker.lat,
              long: marker.long,
            })
          }}
        />
      </Marker>
    )
  })

  useEffect(() => {
    if (!mapRef.current?.loaded) return
    fitMapToMarkers(mapRef.current, markers)
  }, [landProjects])

  function onLoad() {
    setTimeout(() => {
      fitMapToMarkers(mapRef.current, markers)
    }, 1)
  }

  return (
    <div className={cn('h-full w-full bg-ink-200', className)}>
      <BaseMap ref={mapRef} controls={controls} onLoad={onLoad}>
        <MapSources composite countryBoundaries />
        <BackgroundLayer />
        <WaterLayer />
        <CountryBoundariesLinesLayer />
        <HighlightCountriesLayer countries={countries} />
        {pins}

        {selectedLandProject && (
          <MapPopup.WithAutoClose
            anchor='bottom'
            longitude={selectedLandProject.long}
            latitude={selectedLandProject.lat}
            closeButton={false}
            focusAfterOpen={false}
            onClose={() => setSelectedLandProject(null)}
            hideArrow
          >
            <LandProjectPopupContent
              landProject={selectedLandProject}
              onMouseLeave={() => setSelectedLandProject(null)}
              hideTCO2={hideTCO2}
            />
          </MapPopup.WithAutoClose>
        )}
      </BaseMap>
    </div>
  )
}

function getMarkersAndCountries(landProjects: LandProject[]) {
  const markers = []
  const countries = []

  landProjects.forEach((landProject) => {
    const latLong = getProjectLatLong(landProject)
    const country = getLandProjectCountry(landProject)

    if (latLong.lat && latLong.long) {
      const color = getColorByProjectType(landProject.projectType)
      markers.push({ ...latLong, id: landProject._id, color })
      if (country && !countries.includes(country)) countries.push(country)
    }
  })

  return { markers, countries }
}

function fitMapToMarkers(map: MapRef, markers) {
  if (!markers?.length) return
  const pointsPolygon = turf.featureCollection(
    markers.map((m) => turf.point([m.long, m.lat]))
  )
  const bufferedPolygon = turf.buffer(pointsPolygon, 20, { units: 'miles' })
  const bounds = turf.bbox(bufferedPolygon) as any

  map?.fitBounds(bounds as any, {
    padding: 200,
    maxZoom: 3,
  })
}

function LandProjectPopupContent(props: {
  landProject: LandProject
  onMouseLeave: any
  hideTCO2: boolean
}) {
  const { landProject, onMouseLeave, hideTCO2 } = props

  const { name, country, area } = getLandMeta(landProject)
  const { totalProduction30yrs } = calculateCarbonStats(landProject)

  return (
    <Link to={`/portfolio/${landProject.uName}`}>
      <MapPopup.Content
        className={cn('cursor-pointer p-16 py-12 hover:bg-ink-50 hover:ring-highlight')}
      >
        <Row className='mb-4 justify-between'>
          <span className='text-13 text-ink-600'>{country}</span>
          <ProjectTypeBadge projectType={landProject.projectType} />
        </Row>

        <div className='mb-4 w-full font-space-grotesk text-16 font-bold leading-[1.2] line-clamp-3'>
          {name}
        </div>

        <Row className='space-x-20'>
          <CardStat label='Area' value={formatArea(area, false)} unit='ha' />
          {!hideTCO2 && (
            <CardStat
              label='Total Carbon'
              value={formatNumber(totalProduction30yrs)}
              unit='tCO2eq'
            />
          )}
        </Row>
      </MapPopup.Content>
    </Link>
  )
}

function CardStat(props: StatValues) {
  return (
    <Column>
      <StatItems
        labelClasses='!text-12'
        valueClasses='text-16'
        unitClasses='text-12'
        {...props}
      />
    </Column>
  )
}

function ProjectTypeBadge({ projectType }: { projectType: ProjectType }) {
  const styles = { className: 'text-12' }
  if (projectType == 'redd')
    return (
      <Badge variant='red' {...styles}>
        REDD
      </Badge>
    )
  else
    return (
      <Badge variant='green' {...styles}>
        ARR
      </Badge>
    )
}
