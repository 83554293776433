import { useLocation as reactRouterUseLocation } from 'react-router-dom'
import { PATH } from '~/utils/constants'

interface TypedLocation extends Omit<Location, 'state'> {
  state?: {
    redirectUrl?: string
    from?: PATH
    /** aka, url params: like __url__?param=value */
    search?: string
  }
}

export function useLocation() {
  return reactRouterUseLocation() as unknown as TypedLocation
}
