import { cn } from '~/utils/cn'
import { Link } from 'react-router-dom'
import { PATH } from '~/utils/constants'

interface NavProps {
  subtitle?: string
  actions?: any
}

const Nav = (props: NavProps) => {
  const { subtitle, actions } = props

  const titleComponent = subtitle ? <span className='mx-32'>{subtitle}</span> : <></>
  return (
    <nav className='science-nav flex justify-between py-16 px-32 bg-neutral-100 border-b border-ink-200 sticky top-0 z-10'>
      <h1 className='text-20 font-bold'>
        <Link to={PATH['/science-database']} className={cn({ 'text-ink-500': subtitle })}>
          Science Database
        </Link>
        {titleComponent}
      </h1>
      {actions}
    </nav>
  )
}

export { Nav as ScienceNav }
