import { Pressable } from '~/components/atomic/Pressable'
import { NavAuthButton } from '~/components/auth/NavAuthButton'
import { Nav } from '~/components/layouts/Nav'
import { LandProject } from '~/models'
import { Row } from '~/styles'

type Props = {
  landProject: LandProject
}

export function ProjectNavProponents(props: Props) {
  const { landProject } = props

  return (
    <Nav>
      <Row className='hidden md:flex flex-row row-vcenter m-auto w-full justify-between'>
        <Row className='row-vcenter mr-20 flex-1 gap-x-8'>
          <Pressable to='/projects' icon='home'>
            All Projects
          </Pressable>

          <span className='!ml-12 mr-12 font-medium opacity-40'>|</span>

          <Nav.ProjectTitle landProject={landProject} />
        </Row>
      </Row>

      <NavAuthButton />
    </Nav>
  )
}
