import React, { useState } from 'react'
import { Button } from '~/components/atomic/Button'
import { Dialog } from '~/components/atomic/Dialog'
import { ErrorMessage } from '~/components/atomic/ErrorMessage'
import { Textarea } from '~/components/atomic/Input'
import { fetchDB } from '~/services/db'
import { useAuthedUser } from '~/state/auth'
import { Row } from '~/styles'

import { getFormData } from '~/utils'

const SUPPORT_EMAIL = 'support@earthshot.eco'

export function SupportDialog() {
  const trigger = (
    <Button
      icon='help'
      variant='default'
      className='fixed bottom-20 rounded-full right-20 bg-white hover:bg-ink-200 ring-1 ring-inka-100 hover:rink-inka-400 shadow-lg'
    >
      Support
    </Button>
  )

  return (
    <Dialog triggerComponent={trigger}>
      <Content />
    </Dialog>
  )
}

function Content() {
  const authedUser = useAuthedUser()
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [sent, setSent] = useState<boolean>(false)
  const [error, setError] = useState<string>(null)

  async function onSubmit(e) {
    e.preventDefault()
    setSubmitting(true)

    const formData = getFormData<{ explanation: string }>(e.currentTarget)
    const res = await fetchDB('supportMessage', {
      message: formData.explanation,
      user: authedUser,
    })
    setSubmitting(false)

    if (res.valid === 1) {
      setSent(true)
      setTimeout(() => {
        Dialog.close()
      }, 800)
    } else {
      setError(res.msg || 'Something wrong happened')
    }
  }
  return (
    <form onSubmit={onSubmit}>
      <Dialog.Title>Contact Support</Dialog.Title>
      <Dialog.CloseButton />
      <Textarea
        name='explanation'
        className='mb-20'
        textareaClassName='min-h-[300px]'
        placeholder='Please explain your problem, question, feedback...'
      />
      <Row className='justify-end'>
        {sent ? (
          <Button icon='check' variant='success'>
            Sent
          </Button>
        ) : (
          <Button icon='send' type='submit' variant='primary' submitting={submitting}>
            Send
          </Button>
        )}
      </Row>
      <ErrorMessage error={error} />
      {error && (
        <p>
          Please send an email to{' '}
          <a href={`mailto:${SUPPORT_EMAIL}`} title='Send an email to support'>
            {SUPPORT_EMAIL}
          </a>{' '}
          with your feedback.
        </p>
      )}
    </form>
  )
}
