import { cn } from '~/utils/cn'

type Props = {
  error: string
  className?: string
}

export function ErrorMessage(props: Props) {
  const { error, className } = props

  return <div className={cn('font-medium text-red-500', className)}>{error}</div>
}
