import React, { PropsWithChildren } from 'react'
import { Button } from '~/components/atomic/Button'
import { polygonFileUpload } from '~/services/db'
import { Parcel } from '~/models'
import { logger } from '~/services/logger'
import JSZip from 'jszip'
import { toast } from '~/services/toaster'
import { mustHaveShapFileExtensions } from '~/utils/constants'

type Props = PropsWithChildren<{
  className?: string
  onUploaded: any
}>

export function UploadPolygonButton(props: Props) {
  const { className, children, onUploaded, ...rest } = props
  const [uploading, setUploading] = React.useState(false)
  const inputRef = React.useRef<HTMLInputElement>(null)

  function onClickUpload(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault()
    inputRef.current.click()
  }

  function onUploadPolygon(e) {
    e.preventDefault()
    setUploading(true)

    const files = e.target.files
    const promises: Promise<any>[] = []
    let allParcels: Parcel[] = []
    Array.from(files).forEach((file: any) => {
      const promise = new Promise(async (resolve) => {
        const extension = getFileExtension(file.name)
        if (extension === 'zip') {
          const fileNeeded = await validShapefileCheck(file)
          const validShapefile = fileNeeded.length < 1
          if (!validShapefile) {
            setUploading(false)
            return toast.warning(`Missing ${fileNeeded.flat()} file in the shapefile zip`)
          }
        }

        const mime = getMimeType(extension)
        const formData = new FormData()
        formData.append('mime', mime)
        formData.append('keyType', 'polygonUpload')
        formData.append('file', file)

        const res = await polygonFileUpload('fileUpload', formData)
        if (res.valid === 1) {
          logger.log('onUploadPolygon ->', res)
          const newParcels = res.parcels.map((parcel) => new Parcel(parcel))
          allParcels = [...allParcels, ...newParcels]
          resolve('resloved')
        } else {
          toast.warning(res.msg)
          logger.error('uploadPolygon -> Error:', 'error uploading polygons', res.msg)
        }
        resolve('polygon uploaded')
      })

      promises.push(promise)
    })

    Promise.all(promises).then(() => {
      setUploading(false)
      onUploaded(allParcels)
    })
  }

  return (
    <>
      <input
        ref={inputRef}
        id='uploadGeoData'
        type='file'
        name='geoData'
        accept='.kmz, .geojson, .kml, .zip'
        hidden={true}
        onChange={(e) => onUploadPolygon(e)}
        multiple
        {...rest}
      />
      <Button
        icon='file_upload'
        variant='primary'
        submitting={uploading}
        className={className}
        onClick={(e) => onClickUpload(e)}
      >
        Upload files
      </Button>
    </>
  )
}

function getFileExtension(filename: string) {
  const pos = filename.lastIndexOf('.')
  const extension = filename.slice(pos + 1, filename.length)
  return extension
}

function getMimeType(extension: string) {
  if (extension === 'zip') {
    return 'application/zip'
  } else if (extension === 'kmz') {
    return 'application/vnd.google-earth.kmz'
  } else if (extension === 'kml') {
    return 'application/vnd.google-earth.kml+xml'
  } else if (extension === 'geojson') {
    return 'application/vnd.geo+json'
  } else {
    console.error('File type is no valid')
    throw new Error('File type is no valid')
  }
}

function validShapefileCheck(file: File) {
  const zip = new JSZip()
  const fileNeeded = zip.loadAsync(file).then((res) => {
    const hasExtensions = Object.keys(res.files).map((file) => {
      const lastDotIndex = file.lastIndexOf('.')

      return file.slice(lastDotIndex + 1)
    })
    const fileNeeded = mustHaveShapFileExtensions.filter(
      (f) => !hasExtensions.includes(f)
    )
    return fileNeeded
  })
  return fileNeeded
}
