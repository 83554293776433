import React, { useState } from 'react'
import Input from '~/components/atomic/Input'
import { Row } from '~/styles'
import { SegmentedControl } from '~/components/atomic/SegmentedControl'
import { LAT_DIRECTIONS, LiteratureSite, LON_DIRECTIONS } from '../types'

const LAT_DIRECTION_LABELS = [
  { value: LAT_DIRECTIONS.N, label: 'North' },
  { value: LAT_DIRECTIONS.S, label: 'South' },
]

const LON_DIRECTION_LABELS = [
  { value: LON_DIRECTIONS.E, label: 'East' },
  { value: LON_DIRECTIONS.W, label: 'West' },
]

type DegreesMinutesSecondsProps = {
  data: LiteratureSite
}

const DegreesMinutesSeconds = (props: DegreesMinutesSecondsProps) => {
  const { data } = props
  const [latDirection, setLatDirection] = useState(
    data.centerLatDirection || LAT_DIRECTIONS.N
  )
  const [lonDirection, setLonDirection] = useState(
    data.centerLonDirection || LON_DIRECTIONS.E
  )

  return (
    <>
      <Row className='mt-16'>Latitude</Row>
      <div className='mt-8 mb-16 grid grid-cols-6 gap-16'>
        <Input
          name='centerLatDegrees'
          placeholder='Degrees'
          defaultValue={data.centerLatDegrees}
          required
        />
        <Input
          name='centerLatMinutes'
          placeholder='Minutes'
          defaultValue={data.centerLatMinutes}
          required
        />
        <Input
          name='centerLatSeconds'
          placeholder='Seconds'
          defaultValue={data.centerLatSeconds}
          required
        />
        <SegmentedControl
          name='centerLatDirection'
          options={LAT_DIRECTION_LABELS}
          value={latDirection.toString()}
          onValueChange={(value) => setLatDirection(LAT_DIRECTIONS[value])}
        />
      </div>
      <Row>Longitude</Row>
      <div className='mt-8 mb-16 grid grid-cols-6 gap-16'>
        <Input
          name='centerLonDegrees'
          placeholder='Degrees'
          defaultValue={data.centerLonDecimalDeg}
          required
        />
        <Input
          name='centerLonMinutes'
          placeholder='Minutes'
          defaultValue={data.centerLonMinutes}
          required
        />
        <Input
          name='centerLonSeconds'
          placeholder='Seconds'
          defaultValue={data.centerLonSeconds}
          required
        />
        <SegmentedControl
          name='centerLonDirection'
          options={LON_DIRECTION_LABELS}
          value={lonDirection.toString()}
          onValueChange={(value) => setLonDirection(LON_DIRECTIONS[value])}
        />
      </div>
    </>
  )
}

export { DegreesMinutesSeconds }
