import { cn } from '~/utils/cn'

import { Button, Props as ButtonProps } from '~/components/atomic/Button'
import { useTimedToggle } from '~/utils/useTimedToggle'

type Props = {
  textToCopy: string
  className?: string
  copiedText?: string
  icon?: string
  text?: string
  onCopy?: () => void
} & ButtonProps

export function CopyToClipboardButton(props: Props) {
  const {
    className,
    text = 'Copy link',
    copiedText = 'Copied',
    icon = 'link',
    textToCopy,
    onCopy,
    ...rest
  } = props
  const [isCopied, toggle] = useTimedToggle(() => {})

  function onClick() {
    navigator.clipboard.writeText(textToCopy).then((_) => {
      if (onCopy) onCopy()
      toggle()
    })
  }

  return (
    <Button
      className={cn(
        'flex-20',
        { '!bg-green-500/20 text-emerald-900': isCopied },
        className
      )}
      icon={isCopied ? 'check' : icon}
      onClick={onClick}
      {...rest}
    >
      {isCopied ? copiedText : text}
    </Button>
  )
}
