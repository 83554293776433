import classed from '~/styles/classed'
import { PropsWithChildren } from 'react'
import { Numerical } from '~/components/core/Numerical'
import { Tooltip } from '~/components/atomic/Tooltip'
import Icon from '~/components/atomic/Icon'

type Props = PropsWithChildren<{
  children: any
  className?: string
}>

export default function TableLayout(props: Props) {
  const { children, className } = props
  return (
    <div className={`overflow-x-scroll ${className}`}>
      <TableLayoutStyles>{children}</TableLayoutStyles>
    </div>
  )
}

export const TableLayoutStyles = classed(
  'table',
  'relative min-w-full border-collapse rounded-md border'
)
export const THead = classed('thead', 'w-full relative z-10')
export const TH = classed(
  'th',
  'py-8 px-8 text-left text-13 text-ink-900 bg-white first:pl-20 border-b rounded-md'
)

export const TR = classed('tr', 'w-full font-normal hover:bg-ink-100')
export const TD = classed(
  'td',
  'border-t px-8 border-b vertical-middle items-center text-13 text-ink-800 font-normal first:pl-20'
)

export const TBody = classed('tbody')
export const TFooter = classed('tfoot')

function NumericalCell({ value, className = '', type }) {
  return (
    <Numerical
      value={value || '-'}
      type={value ? type : 'unformatted'}
      className={`${className} !text-right`}
    />
  )
}

function SumFooter(tableRawData) {
  const { column } = tableRawData

  // TODO: Remove conditional after table migration
  const type = column?.type || column.columnDef?.meta?.type
  const tableData = tableRawData?.rows || tableRawData?.table?.getRowModel().rows

  const sum =
    tableData.reduce(
      (prev, curr) => (prev += curr.original[tableRawData.column.id]),
      0
    ) || 0

  return <Numerical className='font-semibold' type={type} value={sum} />
}

function AverageFooter(tableRawData) {
  const { column } = tableRawData

  const type = column?.type || column.columnDef?.meta?.type
  const tableData = tableRawData?.rows || tableRawData?.table?.getRowModel().rows

  const sum =
    tableData.reduce(
      (prev, curr) => (prev += curr.original[tableRawData.column.id]),
      0
    ) || 0

  const average = sum / tableData.length

  return (
    <span>
      {' '}
      avg <Numerical className='font-semibold' type={type} value={average} decimals={2} />
    </span>
  )
}

function HeaderWithTooltip(props) {
  const { tooltip, children } = props
  return (
    <Tooltip content={tooltip}>
      <div className='row row-vcenter justify-end'>
        {children}
        <Icon icon='help_outline' className='opacity-50 ml-4 -mr-8' size={16} />
      </div>
    </Tooltip>
  )
}

TableLayout.TableLayoutStyles = TableLayoutStyles
TableLayout.THead = THead
TableLayout.TH = TH
TableLayout.TR = TR
TableLayout.TD = TD
TableLayout.TBody = TBody
TableLayout.TFooter = TFooter
TableLayout.NumericalCell = NumericalCell
TableLayout.SumFooter = SumFooter
TableLayout.AverageFooter = AverageFooter
TableLayout.HeaderWithTooltip = HeaderWithTooltip
