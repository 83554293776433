import React, { useState } from 'react'
import { Checkbox } from '~/components/atomic/Checkbox'
import { Option } from '~/types'

export type Props = {
  options: Option[]
  name?: string
  defaultValue?: string[]
  onChange?: any
  onClickOption?: (option: Option) => void
}

export function CheckboxGroup(props: Props) {
  const { options, defaultValue, name, onChange, onClickOption, ...rest } = props

  const [selectedValues, setSelectedValues] = useState(props.defaultValue)

  function onClickCheckbox(e: React.MouseEvent<HTMLInputElement, MouseEvent>) {
    const value = e.currentTarget.value
    let newValues
    if (selectedValues?.includes(value)) {
      newValues = selectedValues?.filter((existingValue) => existingValue !== value)
    } else {
      newValues = selectedValues?.concat(value)
    }
    setSelectedValues(newValues)
    onChange && onChange(newValues)

    const clickedOption: Option = {
      ...options.find((op) => op.value === value),
      checked: e.currentTarget.checked,
    }

    onClickOption && onClickOption(clickedOption)
  }

  return (
    <fieldset
      name={name}
      className='col border-1 flex divide-y overflow-hidden rounded-md border bg-white'
    >
      {options.map((option, key) => (
        <label
          className='row row-vcenter cursor-pointer select-none px-8 py-6 hover:bg-ink-100'
          key={key}
        >
          <Checkbox
            name={name}
            value={option.value}
            defaultChecked={selectedValues?.includes(option.value)}
            onClick={onClickCheckbox}
          />
          {option.label}
        </label>
      ))}
    </fieldset>
  )
}
