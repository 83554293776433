import React, { useEffect, useRef } from 'react'
import { Progress } from '~/components/atomic/Progress'

import { Column, Row } from '~/styles'

type Props = {
  className?: string
}

const steps = [
  'Initializing computational cluster',
  'Processing geospatial data',
  'Classifing land cover types',
  'Extracting current forest cover',
  'Evaluating past forest cover',
  'Processing historical deforestation',
  'Mapping biomass',
  'Calculating avoided emissions',
  'Processing data',
  'Almost there',
  'Hold on',
]

const increment = 100 / (steps.length + 5)

const BASE_DURATION = 3_000

export function REDDProgressBar(props: Props) {
  const { className, ...rest } = props

  const [progress, setProgress] = React.useState(0)
  const stepIndexRef = useRef<any>(0)
  const intervalRef = useRef<any>()

  function inc() {
    if (stepIndexRef.current >= steps.length - 1) {
      setProgress(100)
      return clearInterval(intervalRef.current)
    }
    setProgress((prog) => prog + increment)
    stepIndexRef.current += 1
  }

  useEffect(() => {
    setProgress((prog) => prog + increment)

    intervalRef.current = setInterval(() => {
      inc()
    }, BASE_DURATION - 1_000)
  }, [])

  return (
    <Column className={`center flex h-[30vh] w-full ${className}`} {...rest}>
      <Progress
        value={progress}
        duration={stepIndexRef.current >= steps.length - 2 ? 30_000 : BASE_DURATION}
        className='max-w-[50%]'
      />
      <div className='mt-20 mb-12 text-14 font-medium text-center'>
        {steps[stepIndexRef.current]}...
      </div>
    </Column>
  )
}
