import { H3, P } from '~/components/profile/shared'
import { getLandProjectDatum } from '~/models/projectDatum'
import { useActiveLandProject } from '~/state'

type Props = {}

export function ProjectSummary(props: Props) {
  const {} = props
  const { landProject } = useActiveLandProject()

  const summary = getLandProjectDatum(landProject, 'proj-summary').value

  return (
    summary && (
      <>
        <H3 className='!mb-0'>Project description</H3>
        <P className='!mt-8 gap-24 sm:columns-2'>{summary}</P>
      </>
    )
  )
}
