import { Badge } from '~/components/atomic/Badge'
import Popover from '~/components/atomic/Popover'
import { FormFieldCondition } from '~/models/formField'

import { Row } from '~/styles'

type Props = {
  condition: FormFieldCondition
}

export function ConditionalPopover(props: Props) {
  const { condition } = props

  const value = Array.isArray(condition.value)
    ? condition.value.join(', ')
    : condition.value

  const content = (
    <Popover.Container className='gap-4'>
      <div>Target Field ID: {condition.targetFieldId}</div>
      <Row className='row-vcenter font-mono text-12 gap-8'>
        <div className='p-2 ring-1 rounded-md ring-ink-200'>{condition.state}</div>
        <div className='p-2 ring-1 rounded-md ring-ink-200'>{value}</div>
      </Row>
    </Popover.Container>
  )

  return (
    <Popover content={content} asChild={false}>
      <Badge size='small' className='hover:bg-ink-300 cursor-pointer'>
        Conditional
      </Badge>
    </Popover>
  )
}
