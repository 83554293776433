import React, { PropsWithChildren } from 'react'

type Props = PropsWithChildren<
  {} & React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>
>

export default function AuthForm(props: Props) {
  const { children, ...rest } = props

  return (
    <form className='flex w-full max-w-[340px] flex-col space-y-12' {...rest}>
      {children}
    </form>
  )
}
