import { useEffect, useRef } from 'react'
import { CenteredLoader } from '~/components/atomic/CenteredLoader'
import { ReferenceRegionUploadDialog } from '~/components/dialogs/UploadReferenceRegionDialog'
import { UploadPolygonLandProject } from '~/components/forms/UploadPolygonLandProject'
import { BaseMap } from '~/components/map/BaseMap'
import { CountryAdminLayer } from '~/components/map/CountryAdmin'
import { CountriesBounds } from '~/components/map/layers/CountriesBounds'
import { CountryLabelsLayer } from '~/components/map/layers/CountryLabelsLayer'
import { MapSources } from '~/components/map/layers/MapSources'
import { PolygonLayers } from '~/components/map/layers/PolygonLayers'
import { RasterLayers } from '~/components/map/layers/RasterLayers'
import { MapControls } from '~/components/map/MapControls'
import { UploadPolygonGroup } from '~/components/project/UploadPolygonGroup'
import { datasets } from '~/models/dataset'
import { getLandProjectCountry } from '~/models/landProject'
import {
  fetchAndStoreRasterMapLayers,
  onChangeRasterLayer,
  RasterLayerToFetch,
  useLandStore,
} from '~/routes/project/land/landStore'
import { getCountryCode3ByName, getCountryNameByCode } from '~/services/countries'
import {
  fitMap,
  fitMapToLayers,
  landProjectParcelsToMapLayer,
  referenceRegion5kmToMapLayer,
  referenceRegionToMapLayer,
  useMapLayers,
} from '~/services/mapbox'
import { useActiveLandProject } from '~/state'
import { useAuthedUser } from '~/state/auth'
import { Column } from '~/styles'
import { MapOverlaidCard } from '~/styles/main'

type Props = {
  hidePolygonUpload?: boolean
  showReferenceRegionUpload?: boolean
  hideControls?: boolean
  interactive?: boolean
}

const mapLayerIdsToProjectTypeMap: { [key: string]: RasterLayerToFetch[] } = {
  redd: [
    { datasetId: 'worldBiomass_walker' },
    { datasetId: 'worldcover_esa' },
    { datasetId: 'worldcover_dynamicworld' },
  ],
  arr: [
    { datasetId: 'potential_biomass' },
    {
      datasetId: 'worldcover_dynamicworld',
      layerId: 'dynamic_world_2015',

      fetchParams: {
        urlParams: `?dateRange=${datasets['worldcover_dynamicworld'].priorDateRange}`,
      },
    },
    {
      datasetId: 'worldcover_dynamicworld',
      layerId: 'dynamic_world_2023',
      fetchParams: {
        urlParams: `?dateRange=${datasets['worldcover_dynamicworld'].currentDateRange}`,
      },
    },
  ],
}

export function LandMapSection(props: Props) {
  const {
    hidePolygonUpload,
    showReferenceRegionUpload = true,
    hideControls,
    interactive = true,
  } = props
  const {
    landProject,
    parcels,
    referenceRegionCoordinates,
    referenceRegionCoordinates5km,
  } = useActiveLandProject()

  const landStore = useLandStore()
  const authedUser = useAuthedUser()

  const initialLayers = []
  if (parcels?.length) initialLayers.push(landProjectParcelsToMapLayer(parcels))
  if (referenceRegionCoordinates5km?.length)
    initialLayers.push(referenceRegion5kmToMapLayer(referenceRegionCoordinates5km))
  if (referenceRegionCoordinates?.length)
    initialLayers.push(referenceRegionToMapLayer(referenceRegionCoordinates))

  const { layers, upsertLayer } = useMapLayers(initialLayers)
  const mapRef = useRef(null)

  const hasPolygons = parcels?.length > 0

  function onLoad(e) {
    fitMapToLayers(e.target, layers)
  }

  useEffect(() => {
    const mapLayersIds = mapLayerIdsToProjectTypeMap[landProject.projectType]
    fetchAndStoreRasterMapLayers(mapLayersIds)
  }, [landProject])

  useEffect(() => {
    upsertLayer(landProjectParcelsToMapLayer(parcels))
    upsertLayer(referenceRegionToMapLayer(referenceRegionCoordinates))
    upsertLayer(referenceRegion5kmToMapLayer(referenceRegionCoordinates5km))
  }, [parcels, referenceRegionCoordinates, referenceRegionCoordinates5km])

  useEffect(() => {
    if (layers.length === 0) return
    fitMap(mapRef.current, layers[0].featureCollection)
  }, [layers])

  const countryName = getLandProjectCountry(landProject)
  const countryCode3 = getCountryCode3ByName(countryName)
  const countryCode2 = getCountryNameByCode(countryName)

  const isREDD = landProject.projectType === 'redd'

  const controls = !hideControls && (
    <>
      <MapControls.Group top right className='col space-y-8'>
        <MapControls.Layers layers={layers} onChange={(layer) => upsertLayer(layer)} />
        <MapControls.RasterLayers
          layers={landStore.mapLayers}
          onChange={onChangeRasterLayer}
        />

        {showReferenceRegionUpload && hasPolygons && isREDD && authedUser.isAdmin && (
          <ReferenceRegionUploadDialog />
        )}
      </MapControls.Group>

      <MapControls.Group bottom left>
        <MapControls.Zoom />
      </MapControls.Group>
    </>
  )

  const uploadPolygonCard = !hidePolygonUpload && parcels && parcels.length === 0 && (
    <MapOverlaidCard>
      <UploadPolygonGroup uploadButton={<UploadPolygonLandProject />} />
    </MapOverlaidCard>
  )

  return (
    <Column className='center relative h-full w-full'>
      <BaseMap
        ref={mapRef}
        controls={controls}
        onLoad={onLoad}
        initialCountryCode3={countryCode3}
        interactive={interactive}
      >
        <MapSources satellite composite />
        <RasterLayers layers={landStore.mapLayers} />

        <CountryLabelsLayer />
        <CountriesBounds variant='satellite' />

        <CountryAdminLayer countryCode2={countryCode2} />
        <PolygonLayers layers={layers} />
      </BaseMap>
      {!parcels && (
        <MapOverlaidCard>
          <CenteredLoader className='h-auto'>Loading Polygons</CenteredLoader>
        </MapOverlaidCard>
      )}
      {uploadPolygonCard}
    </Column>
  )
}
