// From https://daily.dev/blog/my-tailwind-css-utility-function-for-creating-reusable-react-components-typescript-support
import React, {
  Attributes,
  ClassAttributes,
  ElementType,
  FunctionComponentElement,
  ReactElement,
} from 'react'
import { cn } from '~/utils/cn'

function classed<P extends Record<string, unknown>>(
  type: ElementType,
  ...className: string[]
): (props?: (Attributes & P) | null) => FunctionComponentElement<P>

function classed<
  T extends keyof JSX.IntrinsicElements,
  P extends JSX.IntrinsicElements[T]
>(
  type: keyof JSX.IntrinsicElements,
  ...className: string[]
): (props?: (ClassAttributes<T> & P) | null) => ReactElement<P, T>

function classed<P extends Record<string, unknown>>(
  type: ElementType | keyof JSX.IntrinsicElements,
  ...className: string[]
): (props?: (Attributes & P & { className?: string }) | null) => ReactElement<P> {
  return function Classed(props) {
    return React.createElement(type, {
      ...props,
      className: cn(...className, props?.className),
    })
  }
}

export default classed
