import Input from '~/components/atomic/Input'
import Select from '~/components/atomic/Select'
import { Checkbox } from '~/components/atomic/Checkbox'
import { Section } from '~/styles/main'
import { LiteraturePublication, SOURCE_TYPES } from './types'
import { getSelectOptionsWithLabels } from './utils'

const SOURCE_TYPE_LABELS = getSelectOptionsWithLabels({ types: SOURCE_TYPES })

type PublicationInformationProps = {
  data: LiteraturePublication
}

const LiteraturePublicationComponent = (props: PublicationInformationProps) => {
  const { data } = props

  return (
    <Section>
      <Section.Title>Publication Information</Section.Title>
      <div className='group my-16 grid grid-cols-3 gap-16'>
        <Input
          className='col-span-2'
          name='title'
          label='Title'
          placeholder=''
          defaultValue={data.title}
          required
        />
      </div>
      <div className='group my-16 grid grid-cols-3 gap-16'>
        <Input name='authors' label='Authors' defaultValue={data.authors} required />
        <Input
          name='year'
          label='Year'
          type='number'
          placeholder=''
          defaultValue={data.year}
          required
          pattern='(19|[2-9][0-9])\d{2}'
        />
      </div>
      <div className='group my-16 grid grid-cols-3 gap-16'>
        <Input
          className='col-span-2'
          name='journal'
          label='Journal'
          placeholder=''
          defaultValue={data.journal}
        />
      </div>
      <div className='group my-16 grid grid-cols-3 gap-16'>
        <Input
          name='sourceUrl'
          label='Source DOI or URL'
          placeholder='https://...'
          defaultValue={data.sourceUrl}
          required
        />
        <Select
          name='source'
          label='Source Type'
          options={SOURCE_TYPE_LABELS}
          defaultValue={data.source}
        />
        <Checkbox
          name='openSource'
          label='Open Source?'
          defaultChecked={data.openSource}
        />
      </div>
    </Section>
  )
}

export { LiteraturePublicationComponent }
