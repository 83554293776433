import { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { Dialog } from '~/components/atomic/Dialog'
import { Pressable } from '~/components/atomic/Pressable'
import { Tooltip } from '~/components/atomic/Tooltip'

type Metadata = {
  description: string
  function: string
  geographic_coverage: string
  source: string
}

const LayerInfoDialog = (props) => {
  const { layer } = props

  const triggerButton = (
    <div>
      <Tooltip content='Layer info'>
        <Pressable icon='info' className='invisible group-hover:visible' />
      </Tooltip>
    </div>
  )

  return (
    <Dialog triggerComponent={triggerButton} className='overflow-auto max-h-[80vh]'>
      <Content layer={layer} />
    </Dialog>
  )
}

function Content({ layer }) {
  const [metadata, setMetadata] = useState<Metadata>()

  useEffect(() => {
    const fetchMetadata = async function () {
      const response = await fetch(layer?.metadataUrl)
        .then((response) => response.json())
        .then((data) => data)

      setMetadata(response?.data?.metadata || response)
    }

    if (layer.metadataUrl) {
      fetchMetadata()
    } else {
      setMetadata(layer.metadata)
    }
  }, [layer.metadata, layer.metadataUrl])

  // Cleans-up legacy GFW data
  const regex = /(<([^>]+)>)/gi
  const description = metadata?.description || metadata?.function?.replace(regex, '')

  return (
    <>
      <div className='text-28 font-bold mb-20 font-space-grotesk'>{layer?.label}</div>

      <div className='text-18 font-bold font-space-grotesk'>Description</div>
      <ReactMarkdown children={description} remarkPlugins={[remarkGfm]} />

      {metadata?.geographic_coverage && (
        <>
          <div className='text-18 font-bold mt-10 font-space-grotesk'>
            Geographic Coverage
          </div>
          <ReactMarkdown
            children={metadata?.geographic_coverage?.replace(regex, '')}
            remarkPlugins={[remarkGfm]}
          />
        </>
      )}

      <div className='text-18 font-bold mt-10 font-space-grotesk'>Source</div>
      <ReactMarkdown
        children={metadata?.source?.replace(regex, '')}
        remarkPlugins={[remarkGfm]}
      />
    </>
  )
}

export default LayerInfoDialog
