import { useLayoutEffect, useRef, useState } from 'react'
import Input, { Textarea } from '~/components/atomic/Input'
import { Pressable } from '~/components/atomic/Pressable'
import ObjectId from 'bson-objectid'

import { Column, Row } from '~/styles'
import slugify from 'slugify'
import { cn } from '~/utils/cn'
import classed from '~/styles/classed'
import { useOnClickOutside } from '~/utils/useOnClickOutside'
import { FormFieldOption } from '~/models/formField'
import { Dialog } from '~/components/atomic/Dialog'
import { Button } from '~/components/atomic/Button'
import { getFormData } from '~/utils'
import { useConfirmDialog } from '~/components/dialogs/ConfirmDialog'

type Props = {
  multiple?: boolean
  options: FormFieldOption[]
}

export function OptionsList(props: Props) {
  const { multiple } = props
  const [options, setOptions] = useState(props.options || [])
  const { setConfirmDialog } = useConfirmDialog()

  // The block below will trigger the edit mode for the recently added option
  const newestOptionId = useRef<string>()
  useLayoutEffect(() => {
    if (newestOptionId.current) {
      const el = document.querySelector(`#${newestOptionId.current}`) as any
      el && el.click()
      newestOptionId.current = null
    }
  }, [options])

  function addOption() {
    const id = ObjectId().toHexString()
    const option: FormFieldOption = {
      id: `id-${id}`,
      label: '',
      value: '',
    }
    setOptions([...options, option])
    newestOptionId.current = option.id
  }

  function addTemplate(template: OptionsTemplatesKeys) {
    const newOptions = optionsTemplates[template]
    if (!options || options?.length) {
      setConfirmDialog({
        content: 'Are you sure you want to replace the existing options?',
        confirmText: 'Replace',
        closeAfterConfirm: true,
        onConfirm: () => {
          setOptions(newOptions)
        },
      })
    } else setOptions(newOptions)
  }

  function removeOption(optionIndex: number) {
    setOptions(options.filter((_, i) => i !== optionIndex))
  }

  function onPaste(optionLabels) {
    const newOptions: FormFieldOption[] = optionLabels.map((label) => ({
      label,
      value: parseLabelToKey(label),
    }))
    setOptions(newOptions)
  }

  return (
    <Column className='options'>
      {options?.map((op, index) => (
        <OptionItem
          option={op}
          multiple={multiple}
          index={index}
          onRemove={removeOption}
          key={op.id || op.value}
        />
      ))}

      <Row className='mt-4 row-vcenter gap-20 invisible group-hover:visible '>
        <Pressable icon='add' variant='small' onClick={addOption}>
          Add Option
        </Pressable>
        <Dialog
          triggerComponent={
            <Pressable variant='small' icon='content_paste'>
              Paste options
            </Pressable>
          }
        >
          <PasteDialog onPaste={onPaste} />
        </Dialog>
        <Row className='row-vcenter gap-12'>
          <span>Templates:</span>
          <Pressable variant='small' onClick={() => addTemplate('yes/no')}>
            Yes/No
          </Pressable>
          <Pressable variant='small' onClick={() => addTemplate('yes/no/na')}>
            Yes/No/Na
          </Pressable>
        </Row>
      </Row>
    </Column>
  )
}

function PasteDialog(props: { onPaste: (optionLabels) => void }) {
  function onSubmit(e) {
    e.preventDefault()
    const formData = getFormData<{ optionLabels: string }>(e.currentTarget)
    const optionLabels = formData.optionLabels.split('\n')
    props.onPaste(optionLabels)

    Dialog.close()
  }

  return (
    <div>
      <Dialog.CloseButton />
      <Dialog.Title>Paste options</Dialog.Title>
      <Dialog.Description>
        Paste or write the options you want to add.
        <br /> Separate by a new line.
      </Dialog.Description>
      <form onSubmit={onSubmit} className='flex flex-col gap-20'>
        <Textarea
          name='optionLabels'
          placeholder='paste options, separated by a new line...'
        />
        <Button type='submit' variant='primary'>
          Add options
        </Button>
      </form>
    </div>
  )
}

const smallInput = { className: '!py-4 flex-1', inputClassName: '!py-4 !text-12' }

const OptionLabel = classed('label', 'font-mono text-12 text-ink-600')

function OptionItem(props) {
  const { multiple, onRemove, index } = props
  const [editing, setEditing] = useState<boolean>(false)
  const [option, setOption] = useState<FormFieldOption>(props.option)
  const { id = ObjectId().toHexString(), label, value, score } = option
  const optionRef = useRef<any>()

  const onEdit = () => setEditing(true)

  function onFinishEditing(e) {
    e.stopPropagation()

    const newLabel = optionRef.current.querySelector('input[name="option-label"]').value
    const newValue = optionRef.current.querySelector('input[name="option-value"]').value
    const newScore = optionRef.current.querySelector('input[name="option-score"]').value

    const option = {
      label: newLabel.trim(),
      value: newValue.trim() || parseLabelToKey(newLabel),
      score: newScore,
    }

    setOption(option)
    setEditing(false)
  }

  useOnClickOutside(optionRef, (e) => {
    editing && onFinishEditing(e)
    e.stopPropagation()
  })

  return (
    <div
      ref={optionRef}
      className={cn(
        'row row-vcenter group -mx-8 flex cursor-pointer rounded-md px-8 py-4 hover:bg-ink-100',
        { 'bg-ink-100': editing }
      )}
      key={id || value}
      id={id || value}
      onClick={onEdit}
    >
      <div
        className={cn('mr-12 h-[14px] w-[14px] rounded-full border border-ink-400', {
          '!rounded-sm': multiple,
        })}
      />
      <Row className='flex-1'>
        {editing && (
          <>
            <Column className='flex-1'>
              <Row className='row-vcenter space-x-8'>
                <OptionLabel htmlFor='option-label'>Label:</OptionLabel>

                <Input
                  name='option-label'
                  placeholder='option label...'
                  defaultValue={label}
                  autoFocus
                  className='flex-1'
                />
              </Row>
              <Row className='space-x-40 '>
                <Row className='row-vcenter grow space-x-8'>
                  <OptionLabel htmlFor='option-value' className='mr-14'>
                    Key:
                  </OptionLabel>
                  <Input
                    name='option-value'
                    placeholder='option value...'
                    defaultValue={value}
                    className={`mr-8`}
                    {...smallInput}
                  />
                </Row>
                <Row className='row-vcenter grow space-x-8'>
                  <OptionLabel htmlFor='option-score' className='mr-14'>
                    Score:
                  </OptionLabel>
                  <Input
                    name='option-score'
                    type='number'
                    placeholder='0'
                    defaultValue={score}
                    className={`mr-8`}
                    {...smallInput}
                  />
                </Row>
              </Row>
            </Column>
          </>
        )}
        {!editing && (
          <>
            <span
              data-value={value}
              data-score={score}
              id={id || value}
              className='option text-ink-700'
            >
              {label}
            </span>
            {score && (
              <span className='ml-8 font-mono font-bold text-ink-400'>({score})</span>
            )}
          </>
        )}
      </Row>

      {editing && (
        <Row className='ml-16 space-x-12'>
          <Pressable
            icon='check'
            className='bg-lime-100 !px-8 '
            iconClasses='opacity-100 text-lime-500'
            onClick={onFinishEditing}
          />
          <Pressable
            icon='delete'
            className='mr-8 -ml-4 bg-red-100 !p-8 !px-8'
            iconClasses='opacity-100 text-red-500'
            onClick={(e) => onRemove(index)}
          />
        </Row>
      )}
    </div>
  )
}

function parseLabelToKey(optionLabel: string) {
  return slugify(optionLabel.toLowerCase())
}

const optionsTemplates = {
  'yes/no': [
    { value: 'yes', label: 'Yes ' },
    { value: 'no', label: 'No ' },
  ],
  'yes/no/na': [
    { value: 'yes', label: 'Yes ' },
    { value: 'no', label: 'No ' },
    { value: 'na', label: `Don't know ` },
  ],
}

type OptionsTemplatesKeys = keyof typeof optionsTemplates
