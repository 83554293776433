import { BarDatum, BarSvgProps, ResponsiveBar } from '@nivo/bar'
import { Theme } from '@nivo/core'
import { LineSvgProps, ResponsiveLine, Serie } from '@nivo/line'
import { Circle } from '~/components/atomic/Circle'
import { Row } from '~/styles'
import classed from '~/styles/classed'
import { abbrebiateNumber } from '~/utils/units'
import { cn } from '~/utils/cn'
import { capitalizeFirstLetter } from '~/utils'

type GraphProps = {
  title?: string
  className?: string
  colors?: any // TODO
}

type BarGraphProps = {
  valueFormat?: any // TODO
  data: BarDatum[]
  keys?: string[]
} & Omit<Partial<BarSvgProps<any>>, 'colors'> &
  GraphProps

export function BarGraph(props: BarGraphProps) {
  const { data, className, axisBottom, axisLeft, ...rest } = props

  return (
    <GraphWrapper className={className}>
      <ResponsiveBar
        data={data}
        theme={defaultGraphTheme}
        colors={['#488877']}
        margin={{ top: 12, right: 20, bottom: 50, left: 64 }}
        groupMode='grouped'
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: '',
          legendOffset: 36,
          legendPosition: 'end',
          ...axisBottom,
        }}
        enableLabel={false}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: '',
          legendOffset: -52,
          legendPosition: 'middle',
          format: (value) => abbrebiateNumber(value),
          ...axisLeft,
        }}
        legends={[
          {
            dataFrom: 'keys',
            itemWidth: 140,
            itemHeight: 40,
            anchor: 'top-left',
            direction: 'row',
            justify: false,
            translateY: 20,
            translateX: 20,
          },
        ]}
        layers={['grid', 'axes', 'bars', 'markers', 'legends']}
        isInteractive={true}
        tooltip={(p) => {
          const { id, data, indexValue, color, formattedValue } = p
          return (
            <div className='rounded-md border border-inka-150 bg-white px-12 py-8 text-14 shadow-md'>
              <div>
                <span className=''>{data.xLabel}:</span>{' '}
                <span className='font-bold'>{indexValue}</span>
              </div>
              <div>
                <span className=''>
                  {typeof id === 'string' ? capitalizeFirstLetter(id) : id}:
                </span>{' '}
                <span className='text-number font-bold'>{formattedValue}</span>{' '}
                <span className='opacity-60'>{data.unit}</span>
              </div>
            </div>
          )
        }}
        {...rest}
      />
    </GraphWrapper>
  )
}

type LineGraphProps = {} & GraphProps & LineSvgProps

export function LineGraph(props: LineGraphProps) {
  const { yScale, axisBottom, axisLeft, className, ...rest } = props

  return (
    <GraphWrapper className={className}>
      <ResponsiveLine
        theme={defaultGraphTheme}
        curve='catmullRom'
        margin={{ top: 12, right: 20, bottom: 60, left: 60 }}
        xScale={{ type: 'point' }}
        yScale={{
          type: 'linear',
          max: 'auto',
          min: 0,
          ...(yScale as any),
        }}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendOffset: 36,
          legendPosition: 'end',
          ...axisBottom,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendOffset: -48,
          legendPosition: 'middle',
          format: (value) => abbrebiateNumber(value),
          ...axisLeft,
        }}
        useMesh={true}
        isInteractive={true}
        enablePoints={false}
        pointLabelYOffset={-12}
        enableGridX={false}
        legends={[
          {
            itemWidth: 250,
            itemHeight: 40,
            anchor: 'bottom-left',
            direction: 'row',
            justify: false,
            translateY: 68,
            itemsSpacing: 0,
            translateX: 20,
          },
        ]}
        tooltip={({ point }) => {
          const { serieId, color } = point
          const data = point.data as any
          return (
            <div className='rounded-md border border-inka-150 bg-white px-12 py-8 text-13 shadow-md'>
              <Row className='row-vcenter'>
                <Circle color={color} className='mr-8' />
                <div className='font-bold'>{serieId}</div>
              </Row>
              <div>
                <span className='font-medium'>{data.xLabel}:</span>{' '}
                <span className='text-number'>{data.xFormatted}</span>
              </div>
              <div>
                <span className='font-medium'>{data.yLabel}:</span>{' '}
                <span className='text-number'>{data.yFormatted}</span>
              </div>
            </div>
          )
        }}
        {...rest}
      />
    </GraphWrapper>
  )
}

export const GraphWrapper = classed(
  'div',
  cn('w-full h-[400px] min-h-[400px]', 'font-14px font-family-[Inter,sans-serif]')
)

export const defaultGraphTheme: Theme = {
  grid: {
    line: { stroke: 'rgba(0,0,0,0.07)' },
  },
  labels: {
    text: {
      fontSize: 12,
    },
  },
  legends: {
    text: {
      fontSize: 12,
    },
  },
  axis: {
    ticks: {
      line: {
        stroke: 'rgba(0,0,0,0.5)',
      },
      text: {
        fontFeatureSettings: `'tnum' on`,
      },
    },
  },

  fontFamily: 'Inter, sans-serif',
  fontSize: 12,
}

export function getBarsYScaleMax(data: any, keys: string[]) {
  let max = 0
  data.forEach((datum) => {
    keys.forEach((key) => {
      if (datum[key] > max) max = datum[key]
    })
  })
  return max * 1.1
}

export function getLinesYScaleMax(series: Serie[]) {
  let max = 0
  series.forEach((serie) => {
    serie.data.forEach((d) => {
      // If we don't need to skips bound then see if max is available, otherwise default to the mean
      if ((d.y as any) > max) max = d.y as number
    })
  })

  return max
}
