import { Layer, Source } from 'react-map-gl'

type Props = {
  fill?: string
}

export default function WaterMask(props: Props) {
  const { fill = `#C8E1DA` } = props

  return (
    <>
      <Source
        id='mapbox-streets'
        type='vector'
        url='mapbox://mapbox.mapbox-streets-v8'
      ></Source>
      <Layer
        {...{
          id: 'water',
          type: 'fill',
          source: 'mapbox-streets',
          'source-layer': 'water',
          paint: { 'fill-color': fill },
        }}
      />
    </>
  )
}
