import * as turf from '@turf/turf'
import { area } from '@turf/turf'
import { Parcel } from '~/models'
import { getCountryBoundsByName } from '~/services/countries'

export const DEFAULT_POINT = [-38.3203125, 19.80805412808859]

export type FeatureCollection = GeoJSON.FeatureCollection<GeoJSON.Geometry>

export const emptyFetaureCollection: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
  type: 'FeatureCollection',
  features: [],
}

export function getArea(feature) {
  const area = Math.round(turf.area(feature) / 1000)
  return area
}

export function getAreaString(feature) {
  const area = Math.round(turf.area(feature) / 1000).toLocaleString()
  return `${area} ha`
}

export function getMaskAndBounds(area) {
  const mask = turf.mask(area)
  const maskBounds = turf.bbox(mask)
  return {
    maskPoly: mask,
    maskBounds,
  }
}

export function getFeatureCollectionFromParcels(
  parcels: Parcel[]
): GeoJSON.FeatureCollection<GeoJSON.Geometry> {
  if (!parcels || !parcels.length) return emptyFetaureCollection
  return {
    type: 'FeatureCollection',
    features: parcels.map((parcel, index) => {
      const id = parcel._id || index.toString()
      if (parcel.type === 'MultiPolygon') {
        return {
          type: 'Feature',
          id,
          geometry: {
            type: 'MultiPolygon',
            coordinates: [parcel['coordinates']],
          },
          properties: {
            id,
            name: parcel.name || id,
          },
        }
      } else {
        return {
          type: 'Feature',
          id,
          geometry: {
            type: 'Polygon',
            coordinates: [parcel['coordinates']],
          },
          properties: {
            id,
            name: parcel.name || id,
          },
        }
      }
    }),
  }
}

export function getFeatureCollectionFromReferenceRegionCoordinates(
  referenceRegionCoordinates: Array<any>
): GeoJSON.FeatureCollection<GeoJSON.Geometry> {
  if (!referenceRegionCoordinates || referenceRegionCoordinates.length < 1) {
    return emptyFetaureCollection
  }

  let geoJson: GeoJSON.FeatureCollection<GeoJSON.Geometry>
  // Choose how to structure FeatureCollection based on coordinates nest structure
  if (referenceRegionCoordinates[0][0][0][0] === undefined) {
    geoJson = referenceRegionToFeatureCollection(referenceRegionCoordinates)
  } else {
    geoJson = combinedReferenceRegionToFeatureCollection(referenceRegionCoordinates)
  }

  return geoJson
}

function combinedReferenceRegionToFeatureCollection(
  referenceRegionCoordinates: any[]
): GeoJSON.FeatureCollection<GeoJSON.Geometry> {
  const geoJson: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
    type: 'FeatureCollection',
    features: referenceRegionCoordinates.map((featureCoordinates, index) => {
      const id = index.toString()
      return {
        type: 'Feature',
        id: id,
        geometry: {
          type: 'Polygon',
          coordinates: featureCoordinates,
        },
        properties: {
          id: id,
          name: id,
        },
      }
    }),
  }
  return geoJson
}

function referenceRegionToFeatureCollection(
  referenceRegionCoordinates: any[]
): GeoJSON.FeatureCollection<GeoJSON.Geometry> {
  const geoJson: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        id: 0,
        geometry: {
          type: 'Polygon',
          coordinates: referenceRegionCoordinates,
        },
        properties: {
          id: 0,
          name: 'single polygon reference region',
        },
      },
    ],
  }
  return geoJson
}

export function getCountryBounds(countryName: string) {
  const countryBounds = getCountryBoundsByName(countryName)
  return countryBounds
}

export function unionizeFeatureCollection(featureCollection: FeatureCollection) {
  const unioned = featureCollection.features.reduce((prev, feature) => {
    return turf.union(prev as any, feature as any)
  }, featureCollection.features[0])

  return unioned
}

export function unionizeFeatureCollections(featureCollections: FeatureCollection[]) {
  const allFeatures = featureCollections.reduce((prev, featureCollection) => {
    return [...prev, ...featureCollection.features]
  }, [])

  return unionizeFeatureCollection({
    type: 'FeatureCollection',
    features: allFeatures,
  })
}

export function getAreaHa(featureCollection) {
  return area(featureCollection) / 10000
}
