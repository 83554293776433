import { logger } from '~/services/logger'

export type LocalStorageKeys = 'projectsFilters' | 'recentSearches'

export function loadFromStorage(key: LocalStorageKeys) {
  const data = JSON.parse(localStorage.getItem(key) as any)
  logger.info(`loadFromStorage: ${key}`, data)
  return data
}

export function saveToStorage(key: LocalStorageKeys, data: any) {
  logger.info(`saveToStorage: ${key}`, data)
  localStorage.setItem(key, JSON.stringify(data))
}
