import { FillLayer, Layer, Source } from 'react-map-gl'

type Props = {
  countryName: string
  fill?: string
}

export function CountryHighlight(props: Props) {
  const { countryName, fill = 'hsl(204, 85%, 15%)' } = props

  const data: FillLayer = {
    id: 'country-highlight',
    type: 'fill',
    source: 'country-boundaries',
    'source-layer': 'country_boundaries',
    filter: ['match', ['get', 'name_en'], [countryName], true, false],
    layout: {},
    paint: { 'fill-color': fill },
  }

  return (
    <>
      <Source
        id='country-boundaries'
        type='vector'
        url='mapbox://mapbox.country-boundaries-v1'
      />
      <Layer {...data} />
    </>
  )
}
