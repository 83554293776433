import { CountryRegulationInformation } from '~/components/countryRegulation/CountryRegulationInformation'
import { useGetCountryRegulatoryAssessment } from '~/models/countryRegulation'
import { getLandProjectCountry } from '~/models/landProject'
import { useActiveLandProject } from '~/state'
import { Section } from '~/styles/main'

export function CountryRegulationSection() {
  const { landProject } = useActiveLandProject()
  const country = getLandProjectCountry(landProject)
  const countryAssessment = useGetCountryRegulatoryAssessment(country)

  return (
    <Section title='Country Regulation' className='space-y-12'>
      <CountryRegulationInformation
        countryAssessment={countryAssessment}
        country={country}
      />
    </Section>
  )
}
