import { Outlet } from 'react-router-dom'
import { LayoutWithSideMenu } from '~/components/core/LayoutWithSideMenu'
import { LandPopover } from '~/components/land/LandPopover'
import { ProjectTab } from '~/components/layouts/ProjectTab'
import { getLandProjectCountry } from '~/models/landProject'
import { useActiveLandProject } from '~/state/index'
import { Column, Row } from '~/styles'
import { Divider, Section } from '~/styles/main'

type Props = {}
export function LandTabLayout(props: Props) {
  const { landProject, parcels } = useActiveLandProject()
  if (!landProject) return null

  const hasPolygons = parcels && parcels.length > 0
  const country = getLandProjectCountry(landProject)

  const menu = [
    { to: 'map', label: 'Map' },
    { to: 'area', label: 'Area' },
    ...[
      landProject.projectType === 'arr' && {
        to: 'reforestation-eligibility',
        label: 'Reforestation Eligibility (WIP)',
      },
      landProject.projectType === 'arr' && {
        to: 'baseline',
        label: 'Baseline (WIP)',
      },
    ],
    // { to: 'parcels', label: 'Parcels' },
    // { to: 'country-regulation', label: 'Country Regulation' },
    // { to: 'biodiversity', label: 'Biodiversity' },
    // { to: 'trees', label: 'Trees' },
  ]

  const titleComponent = (
    <>
      <Column>
        <Row className='row-vcenter mb-4 justify-between'>
          <Section.Subtitle className='mb-0 py-4'>Land</Section.Subtitle>
          {hasPolygons && <LandPopover className='ml-8' />}
        </Row>
        <div className='text-ink-600'>{country}</div>
        <Divider className='my-16 mb-12' />
      </Column>
    </>
  )

  return (
    <ProjectTab title='Land'>
      <LayoutWithSideMenu menu={menu} titleComponent={titleComponent}>
        <Outlet />
      </LayoutWithSideMenu>
    </ProjectTab>
  )
}
