import React from 'react'
import { Source } from 'react-map-gl'
import { PaintLayers } from '~/components/map/style'
import { MapLayer } from '~/services/mapbox'

type Props = {
  layers: MapLayer[]
}

export function PolygonLayers(props: Props) {
  const { layers } = props

  return (
    <>
      {layers?.map((layer) => {
        if (layer.visible === false) return null

        return (
          <React.Fragment key={layer.id}>
            <Source id={layer.id} type='geojson' data={layer.featureCollection} />
            <PaintLayers sourceId={layer.id} variant={layer.theme} />
          </React.Fragment>
        )
      })}
    </>
  )
}
