import { PropsWithChildren, useState } from 'react'
import Popover, { Props as PopoverProps } from '~/components/atomic/Popover'
import { Option } from '~/types'

type Props = PropsWithChildren<
  {
    onSelect: (option: Option) => void
    ComboBoxComponent: any // TODO improve typing
  } & Omit<PopoverProps, 'content'>
>

export function PopoverWithSearchableSelect(props: Props) {
  const { children, ComboBoxComponent, onSelect, ...rest } = props
  const [open, setOpen] = useState(false)

  return (
    <Popover
      open={open}
      onOpenChange={(newOpen) => setOpen(newOpen)}
      side='left'
      align='start'
      content={
        <ComboBoxComponent
          onSelect={(option) => {
            onSelect(option)
            setOpen(false)
          }}
        />
      }
      {...rest}
    >
      {children}
    </Popover>
  )
}
