import { cn } from '~/utils/cn'
import { SimpleTable } from '~/components/atomic/table/SimpleTable'
import { Tooltip } from '~/components/atomic/Tooltip'
import { Numerical } from '~/components/core/Numerical'
import { transposeInvestorCashflowForTable } from '~/data/financials/investorCashflows'
import { BreakdownTooltip } from '~/routes/project/financials/BreakdownTooltip'
import { CarbonScenarioSelector } from '~/routes/project/financials/CarbonScenarioSelector'
import {
  useRecomputeFinancials,
  useFinancialsStore,
} from '~/routes/project/financials/financialsStore'
import { getIRRBackground } from '~/routes/project/financials/styles'
import { Column } from '~/styles'
import { classesFullBleedContainer, Section } from '~/styles/main'
import { formatCurrency } from '~/utils/units'

type Props = {}

export function InvestorCashflows(props: Props) {
  const { variables, investmentAmounts, investorCashflows } = useFinancialsStore()
  const { startYear } = variables

  useRecomputeFinancials()

  const transposedInvestorCashflows = transposeInvestorCashflowForTable(
    investorCashflows,
    startYear
  )

  const columnsInvestorCashflows = [
    { Header: 'Year#', accessor: 'year', align: 'center' },
    {
      Header: 'Year',
      accessor: 'yearDate',
      align: 'center',
      Footer: (
        <Column>
          <span className='py-8'>Profit</span>
          <span className='py-8'>VCUs</span>
          <span className='py-8'>Indicative Price</span>
          <span className='py-8'>IRR</span>
        </Column>
      ),
    },

    ...investmentAmounts.map((amount, i) => ({
      id: `${i}`,
      Header: `Investment: ${formatCurrency(amount)}`,
      accessor: (d) => {
        return d[`investment${i}`]
      },
      Cell: (d) => {
        const column = parseInt(d.column.id)
        const row = d.row.index

        const breakdown = investorCashflows[column].data[row]
        const tooltip = <BreakdownTooltip values={breakdown} />

        return (
          <Tooltip content={tooltip}>
            <div className='py-2 px-4 hover:bg-ink-150'>
              <Numerical
                type='currency'
                className={cn({ 'text-red-500': d.value < 0 })}
                value={d.value}
              />
            </div>
          </Tooltip>
        )
      },
      Footer: (d) => {
        return (
          <Column>
            <Numerical
              className='py-8'
              type='currency'
              value={investorCashflows[i].profit}
            />

            <Numerical className='py-8' value={investorCashflows[i].totalInvestorVcus} />

            <Numerical
              className='py-8'
              type='currency'
              decimals={2}
              value={
                investorCashflows[i].investment / investorCashflows[i].totalInvestorVcus
              }
            />

            <Numerical
              type='percent'
              className='py-8 text-center rounded-md'
              style={{ background: getIRRBackground(investorCashflows[i].irr) }}
              value={investorCashflows[i].irr || '-'}
            />
          </Column>
        )
      },
    })),
  ]

  return (
    <Section title='Investor Cashflows'>
      <CarbonScenarioSelector />
      <SimpleTable
        data={transposedInvestorCashflows}
        columns={columnsInvestorCashflows}
        className={`overflow-x-scroll ${classesFullBleedContainer} pb-40 mt-20`}
      />
    </Section>
  )
}
