import { Layer, LayerProps, Source } from 'react-map-gl'

type Props = {
  countryBoundaries?: boolean
  composite?: boolean
  satellite?: boolean
}

export function MapSources(props: Props) {
  const { countryBoundaries, composite, satellite } = props
  return (
    <>
      {countryBoundaries && (
        <Source
          id='countryBoundaries'
          url='mapbox://mapbox.country-boundaries-v1'
          type='vector'
        />
      )}

      {composite && (
        <Source
          id='composite'
          url='mapbox://mapbox.mapbox-terrain-v2,mapbox.mapbox-streets-v8'
          type='vector'
        />
      )}

      {satellite && (
        <>
          <Source
            id='satellite'
            url='mapbox://mapbox.satellite'
            type='raster'
            tileSize={256}
          >
            <Layer id='satellite' type='raster' />
          </Source>
        </>
      )}
    </>
  )
}

function SatelliteLayer(props: Partial<LayerProps>) {
  return (
    <Source id='satellite' url='mapbox://mapbox.satellite' type='raster' tileSize={256}>
      <Layer id='satellite' type='raster' {...(props as any)} />
    </Source>
  )
}

MapSources.Satellite = SatelliteLayer
