import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'
import Icon from '~/components/atomic/Icon'
import classed from '~/styles/classed'
import { cn } from '~/utils/cn'

export type ToggleGroupOption = {
  value: string
  label: string
  icon?: string
  iconColor?: string
}

type Props = RadioGroupPrimitive.RadioGroupProps & {
  name: string
  options: ToggleGroupOption[]
  label?: string
  onValueChange(value: string): void
}

export function SegmentedControl(props: Props) {
  const { options, label, className, ...rest } = props

  return (
    <RadioGroupPrimitive.Root
      className={cn(
        'inline-flex bg-ink-900 bg-opacity-5 p-4 rounded-md space-x-4 relative',
        className
      )}
      aria-label={label}
      {...rest}
    >
      {options.map((option, key) => {
        const { value, label, icon, iconColor } = option
        return (
          <Item
            key={key}
            value={value}
            className='rounded last:border-r-0'
            aria-label={label}
          >
            {icon && <Icon icon={icon} size={16} className={cn('mr-6', iconColor)} />}
            {label}
          </Item>
        )
      })}
    </RadioGroupPrimitive.Root>
  )
}

const Item = classed(
  RadioGroupPrimitive.Item,
  cn(
    'row center flex-1 rounded-[4px] py-8 px-10 text-13 font-medium hover:bg-ink-500 hover:bg-opacity-10 duration-100',
    'data-[state="checked"]:bg-white data-[state="checked"]:shadow',
    'transition-all'
  )
)
