import { PropsWithChildren, useEffect } from 'react'
import { Head } from '~/components/core/Head'

import * as analytics from '~/services/analytics'

type Props = PropsWithChildren<{
  title: string
}>

export function Page(props: Props) {
  const { title, children } = props

  useEffect(() => {
    analytics.trackView(`Page: ${title}`)
  }, [])

  return (
    <>
      <Head title={title} />
      {children}
    </>
  )
}
