import { CommunityLandModelDialog } from '~/routes/project/carbon/arr/dialogs/CommunityLandModelDialog'
import {
  carbonState,
  CarbonModelWithDialogKey,
  toggleDialog,
} from '~/routes/project/carbon/arr/arrCarbonStore'
import { useSnapshot } from '~/state'
import { Dialog } from '~/components/atomic/Dialog'
import { ArrComputeModelKeys, arrComputeModels } from '~/models/landProjectArrStats'
import { LiteratureModelDialog } from '~/routes/project/carbon/arr/dialogs/LiteratureModelDialog'

export type DialogContentProps = {
  carbonModelKey: CarbonModelWithDialogKey
}

const dialogs: { [key in CarbonModelWithDialogKey]: any } = {
  [ArrComputeModelKeys.reforestation_literature]: LiteratureModelDialog,
  [ArrComputeModelKeys.reforestation_communityLandModel]: CommunityLandModelDialog,
}

const carbonModelKeys = Object.keys(dialogs) as CarbonModelWithDialogKey[]

export function ProjectionInputsDialogs() {
  const snap = useSnapshot(carbonState)

  const dialogComponents = carbonModelKeys.map((carbonModelKey) => {
    const isOpen = snap.dialogs[carbonModelKey]
    const DialogContent = dialogs[carbonModelKey]

    const dialogTitle = arrComputeModels.find((m) => m.key === carbonModelKey).label

    return (
      <Dialog
        open={isOpen}
        key={carbonModelKey}
        onClickOutside={() => toggleDialog(carbonModelKey, false)}
        className='!min-w-[500px] !max-w-[fit-content]'
      >
        <Dialog.Title>Compute {dialogTitle}</Dialog.Title>
        <Dialog.Divider />
        <Dialog.CloseButton onClick={() => toggleDialog(carbonModelKey, false)} />

        <DialogContent carbonModelKey={carbonModelKey} />
      </Dialog>
    )
  })

  return <>{dialogComponents}</>
}
