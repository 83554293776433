import { cn } from '~/utils/cn'
import { Fragment, useCallback, useState } from 'react'
import Map, { Layer } from 'react-map-gl'
import { CountryRegulationInformation } from '~/components/countryRegulation/CountryRegulationInformation'
import { BackgroundLayer } from '~/components/map/layers/BackgroundLayer'
import { CountryBoundariesLinesLayer } from '~/components/map/layers/CountryBoundariesLinesLayer'
import { CountryLabelsLayer } from '~/components/map/layers/CountryLabelsLayer'
import { MapSources } from '~/components/map/layers/MapSources'
import { WaterLayer } from '~/components/map/layers/WaterLayer'
import {
  CountryRegulatoryAssessment,
  useGetCountryRegulatoryMap,
} from '~/models/countryRegulation'
import { mapboxBaseStyle, MAPBOX_TOKEN } from '~/services/mapbox'
import { Column, Row } from '~/styles'
import { groupBy } from '~/utils'

export function CountryRegulationMap() {
  const [selectedCountry, setSelectedCountry] = useState(null)
  const countryRegulatoryData = useGetCountryRegulatoryMap()

  const onMouseDown = useCallback((event) => {
    const feature = event.features && event.features[0]
    if (!feature?.properties) return
    const country = feature.properties.name_en

    setSelectedCountry({
      longitude: event.lngLat.lng,
      latitude: event.lngLat.lat,
      name: country,
    })
  }, [])

  return (
    <Row className={cn('h-full w-full')}>
      <Map
        id='main'
        attributionControl={false}
        fadeDuration={100}
        mapboxAccessToken={MAPBOX_TOKEN}
        onMouseDown={onMouseDown}
        interactiveLayerIds={['country-boundaries-fills']}
        style={{ width: '50%', height: '100%', opacity: 1 }}
        mapStyle={mapboxBaseStyle as any}
      >
        <MapSources composite countryBoundaries />

        <BackgroundLayer />
        <WaterLayer />
        <CountryBoundariesLinesLayer />
        {makePaintLayers(countryRegulatoryData)}
        <CountryLabelsLayer />
      </Map>
      <CountryInformation
        countryName={selectedCountry?.name}
        countryRegulatoryData={countryRegulatoryData}
      />
    </Row>
  )
}

function CountryInformation(props) {
  const { countryName, countryRegulatoryData } = props
  const countryAssessment: CountryRegulatoryAssessment =
    countryRegulatoryData?.[countryName]

  if (!countryName)
    return <div className='w-[50%] h-full center font-semibold'>Choose a country</div>

  return (
    <Column className='w-[50%] h-full overflow-y-scroll p-20'>
      <CountryRegulationInformation
        countryAssessment={countryAssessment}
        country={countryName}
      />
    </Column>
  )
}

function makePaintLayers(countryRegulatoryData) {
  if (!countryRegulatoryData) return []

  const grouped = groupBy(Object.values(countryRegulatoryData), (d) => d?.rating)

  const layers = []

  for (const groupKey in grouped) {
    const group = grouped[groupKey]
    layers.push(makeFillAndLineLayers(groupKey, group?.map((v) => v.countryName) || []))
  }
  return layers
}

function makeFillAndLineLayers(rating: string, countries: string[]) {
  const color = countryRatingToColor(rating)
  return (
    <Fragment key={`project-country-${rating}`}>
      <Layer
        id={`project-country-fill-${rating}`}
        beforeId='country-label'
        type='fill'
        source='countryBoundaries'
        source-layer='country_boundaries'
        filter={['match', ['get', 'name_en'], countries, true, false]}
        paint={{ 'fill-color': color }}
      />
      <Layer
        id={`project-country-line-${rating}`}
        beforeId='country-label'
        type='line'
        source='countryBoundaries'
        source-layer='country_boundaries'
        filter={['match', ['get', 'name_en'], countries, true, false]}
        paint={{ 'line-opacity': 0.3, 'line-color': '#000000' }}
      />
    </Fragment>
  )
}

function countryRatingToColor(rating: string) {
  switch (rating?.toLowerCase()) {
    case 'high':
      return '#70a672'
    case 'medium':
      return '#f0cf29'
    case 'low':
      return '#f48888'
    case 'unclear':
      return '#9e9e9e'
  }
}
