import { logger } from '~/services/logger'

const _auth = {
  user_id: '',
  session_id: '',
}

function Go(method, route, data, url = '') {
  try {
    if (url === '') {
      url = `${process.env.REACT_APP_URL_WEB}web/${route}`
    }
    const init: any = {
      method,
    }
    if (method !== 'GET') {
      init.body = JSON.stringify(data)
    }
    return fetch(url, init)
  } catch (e) {
    logger.error(e)
  }
}

function SetAuth(userId, sessionId) {
  _auth['user_id'] = userId
  _auth['session_id'] = sessionId
}

function Upload(method, route, data, url = '', baseUrl = process.env.REACT_APP_URL_WEB) {
  try {
    if (url === '') {
      url = `${baseUrl}web/${route}`
    }
    const init: any = {
      method,
    }
    if (method != 'GET') {
      init.body = data
    }
    return fetch(url, init)
  } catch (e) {
    logger.error(e)
  }
}

const ESHttp = {
  Go,
  SetAuth,
  Upload,
}
export default ESHttp
