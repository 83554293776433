import { UpdatedBy } from '~/models/authedUser'
import { Ecosystem } from '~/models/ecosystem'

export type LandCoverCarbonBaselineData = {
  updatedBy: UpdatedBy
  landCovers: LandCoversData
}

export type LandCoverTypeFilters = {
  ecosystem: Ecosystem
  continent: string
  country: string
  climate: string
}

export type TotalBaselineCarbon = {
  [key in LandCover]: {
    area: number
    mean: number
    lower_95Ci: number
    upper_95Ci: number
  }
}

export type LandCoversData = { [key in LandCover]: LandCoverData }

export type LandCoverData = { area: number } & LandCoverTypeFilters

const landCoversMap = {
  degradedForest: 'Degraded Forest',
  agricultureGrassland: 'Agriculture / Grassland',
} as const

export type LandCover = keyof typeof landCoversMap
export const landCovers = Object.keys(landCoversMap) as LandCover[]
export const landCoversOptions = Object.entries(landCoversMap).map((entry) => ({
  value: entry[0] as LandCover,
  label: entry[1],
}))

export const getLandCoverTypeLabel = (entity: LandCover) => landCoversMap[entity]
