import { bboxPolygon, center } from '@turf/turf'
import { StatValues } from '~/components/core/Stat'
import { forms } from '~/data/forms'
import {
  CountryRegulatoryAssessment,
  getIsCountryAssessmentCompleted,
} from '~/models/countryRegulation'
import { getScopingAssessmentForms } from '~/models/form'
import { LandCoverCarbonBaselineData } from '~/models/landCover'
import { ArrComputeModelKeys, getARRCarbonEstimates } from '~/models/landProjectArrStats'
import {
  getREDDGreenHouseGasBaseline,
  REDDChosenProjectionKey,
  REDDLandProjectStats,
} from '~/models/landProjectReddStats'
import { LandProjectStats } from '~/models/landProjectStats'
import { Parcel } from '~/models/parcel'
import {
  getFormCompletionRate,
  getFormsCompletion,
  getLandProjectDatum,
  LandProjectDatums,
} from '~/models/projectDatum'
import { landProjectStatToProjection } from '~/models/projection'
import { FinancialsVariables } from '~/routes/project/financials/financialsStore'
import { getCountryBoundsByCode, getCountryNameByCode } from '~/services/countries'
import { saveProjectData } from '~/services/db'
import { logger } from '~/services/logger'
import { storeLandProject } from '~/state'
import { ColorVariants } from '~/styles/colors'
import { capitalizeFirstLetter, sumArray } from '~/utils'
import { NPV } from '~/utils/accounting/npv'
import { DEFAULT_PROJET_PERIOD, DEFAULT_START_YEAR } from '~/utils/constants'
import { formatArea, formatNumber, formatPercent } from '~/utils/units'
import { InterventionProfile } from './interventionProfile'

// TODO: Improve handling.
// Defaults
const defaults: Partial<LandProject> = {
  _id: '',
  name: '',
  uName: '',
  countryCode: '',
  startYear: DEFAULT_START_YEAR,
  province: '',
  reforestablePercent: 100,
  durationYears: 30,
  publishState: 'unpublished',
  financingType: 'fullFinance',
  stage: 'scoping',
  costs: {
    contingencyBufferPercent: 25,
    governmentPercent: 10,
  },

  carbonPrice: 20,
  interventionProfiles: [],
  images: [],
  data: {},

  xStats: null,
}

export class LandProject {
  // Server set
  created_at?: string
  updated_at?: string
  startYear: number
  userIdOwner?: string
  publicName?: string
  permissions?: 'view' | 'edit' | 'editUser' | 'lead' | 'proponent' | 'investor'
  projectLead?: string
  partnershipLead?: string
  carbonLead?: string
  carbonStatus?: string
  stage?: ProjectStage
  financingType?: FinancingType
  financialStatus?: string
  priority?: number
  projectType?: ProjectType
  landCoverCarbonBaseline?: LandCoverCarbonBaselineData
  googleDriveFolderUrl: string

  umbrellaPartner?: string
  localPartner?: string
  site?: string
  habitat?: string

  data?: LandProjectDatums
  schedule?: LandProjectSchedule

  financials?: FinancialsVariables

  durationYears?: number
  costs: {
    contingencyBufferPercent: number
    governmentPercent: number
  }

  chosenModelKey?: ArrComputeModelKeys
  chosenREDDProjectionKey?: REDDChosenProjectionKey

  publishState?: PublishStatus

  countryCode: string
  province: string
  name: string
  uName: string
  interventionProfiles: InterventionProfile[] // TODO create intervention profile type/class
  images: LandProjectImage[]
  _id: string
  xStats: LandProjectStats & REDDLandProjectStats

  //@deprecated
  reforestableArea?: number
  //@deprecated
  reforestablePct?: number

  reforestablePercent: number // 0 - 100

  carbonPrice: number

  constructor(data: Partial<LandProject>) {
    const landProject = { ...defaults, ...data }
    landProject.interventionProfiles = [new InterventionProfile()]
    return Object.assign(this, landProject)
  }
}

// Types
// Image
export type LandProjectImageTags = 'People' | 'Nature' | 'Panorama' | 'Featured' | 'Other'
export type LandProjectImage = {
  url: string
  tag: LandProjectImageTags
}

// Priority
export const priorities = ['low', 'medium', 'high'] as const
export type Priority = (typeof priorities)[number]

// TODO REFACTOR migrate priority in the backend to use strings?
const priorityLevelToString = {
  1: 'low',
  2: 'medium',
  3: 'high',
}
export const getPriorityString = (priorityLevel: number) =>
  priorityLevelToString?.[priorityLevel]

const priorityStringToLevel = {
  low: 1,
  medium: 2,
  high: 3,
}
export const getPriorityLevel = (priorityString: Priority) =>
  priorityStringToLevel[priorityString]

export const prioritiesOptions = priorities.map((priority) => ({
  value: priority,
  label: priority,
}))

// ProjectType
const projectTypes = [
  'arr',
  'redd',
  'wrc-arr',
  'wrc-redd',
  'alm',
  'ifm',
  'acogs',
] as const
export type ProjectType = (typeof projectTypes)[number]

// Project Stage
const projectStagesMap = {
  'initial-engagement': 'Initial Engagement',
  scoping: 'Scoping',
  'pre-feasibility': 'Pre-Feasibility',
  feasibility: 'Feasibility',
  'due-diligence': 'Due Diligence',
  'investment-case': 'Investment Case',
  'out-for-investment': 'Out for Investment',
  'investment-offer': 'Investment offer',
  implementation: 'Implementation',
} as const

export type ProjectStage = keyof typeof projectStagesMap
export const projectStages = Object.keys(projectStagesMap)
export const projectStagesOptions = Object.entries(projectStagesMap).map((stage) => ({
  value: stage[0] as ProjectStage,
  label: stage[1],
}))
export const getProjectStageLabel = (stage: ProjectStage) => projectStagesMap[stage]

// FinancingType
export const financingTypeOptions = [
  { value: 'fullFinance', label: 'Full Finance' },
  { value: 'catalytic', label: 'Catalytic' },
]
const financingTypes = ['fullFinance', 'catalytic'] as const
export type FinancingType = (typeof financingTypes)[number]

export function getFinancingTypeLabel(financingType: FinancingType) {
  return financingTypeOptions.find((op) => op.value === financingType)?.label
}
export const getFinancingTypeOptions = () => {
  return financingTypes.map((type) => ({
    label: getFinancingTypeLabel(type),
    value: type,
  }))
}

// PublishStatus
const publishStatus = ['unpublished', 'published', 'unlisted', 'public'] as const

export type PublishStatus = (typeof publishStatus)[number]

export const publishStatusOptions = publishStatus.map((ps) => ({
  value: ps,
  label: capitalizeFirstLetter(ps),
}))

export function getPublishStatusLabel(publishStatus: PublishStatus) {
  return publishStatusOptions.find((ps) => ps.value === publishStatus)?.label
}

// InterventionAreaByYear
type Year = number
export type LandProjectSchedule = {
  [key: Year]: {
    areaHa: number
  }
}

export type InterventionAreaByYear = {
  [key: string]: number
}

// Methods
export function buildLandProjectWithStats(
  landProject: LandProject,
  landProjectStats: LandProjectStats
): LandProject {
  return {
    ...landProject,
    xStats: landProjectStats as any, // TODO improve this types
  }
}

export const parseLandProjectArea = (landProject: LandProject, withUnit?: boolean) => {
  return formatArea(landProject?.xStats?.areaHa, withUnit)
}

export const getLandProjectAreaSource = (landProject: LandProject) => {
  const inputArea = getLandProjectAreaInput(landProject)

  return inputArea ? 'manual' : 'polygon'
}

export const getLandProjectArea = (landProject: LandProject) => {
  const inputArea = getLandProjectAreaInput(landProject)
  const areaFromPolygon = getLandProjectPolygonArea(landProject)

  return inputArea || areaFromPolygon
}

export const getLandProjectAreaInput = (landProject: LandProject) => {
  const areaDatum = getLandProjectDatum(landProject, 'project-area')?.value
  return areaDatum ? parseFloat(areaDatum) : null
}

export const getLandProjectPolygonArea = (landProject: LandProject) => {
  return landProject?.xStats?.areaHa
}

export const getLandProjectReforestableArea = (landProject: LandProject) => {
  const projectArea = getLandProjectArea(landProject)
  const area = projectArea
  const reforestablePercent = getLandProjectReforestablePercent(landProject)
  return area * (reforestablePercent / 100)
}

export function getLandProjectReforestablePercent(landProject: LandProject) {
  // TODO eventually deprecate `reforestableArea` AND `reforestablePct`
  return (
    landProject.reforestablePercent ||
    landProject.reforestablePct ||
    landProject.reforestableArea ||
    100
  )
}

export const getLandProjectCountry = (landProject: LandProject) => {
  return getCountryNameByCode(landProject.countryCode)
}

export const getLandProjectCarbonPrice = (landProject: LandProject) => {
  return landProject.carbonPrice
}

export const getLandProjectType = (landProject: LandProject) => {
  return landProject.projectType || 'arr'
}

export const calculateCarbonStats = (landProject: LandProject) => {
  if (!landProject?.xStats?.byYear?.length) return {}

  const reforestablePercent = getLandProjectReforestablePercent(landProject)
  const carbonPrice = getLandProjectCarbonPrice(landProject)
  const reforestableArea = getLandProjectReforestableArea(landProject)

  const chosenCarbonProjection = landProjectStatToProjection(
    landProject.xStats,
    landProject
  )
  const byYear = chosenCarbonProjection.byYear

  // The following datum is a user input to override the total carbon
  const totalCarbonOverride = parseFloat(
    getLandProjectDatum(landProject, 'total-carbon-override').value
  )
  const lastYearTCO2e = totalCarbonOverride || byYear[byYear.length - 1].tCO2

  const totalProduction30yrs = lastYearTCO2e
  const averageAnual = lastYearTCO2e / DEFAULT_PROJET_PERIOD

  // The following datum is a user input to override tCO2/ha/yr value alone
  const avgTCO2PerHaPerYearOverride = parseFloat(
    getLandProjectDatum(landProject, 'avg-tco2-ha-year-override').value
  )
  const avgPerHaPerYear = avgTCO2PerHaPerYearOverride || averageAnual / reforestableArea

  // Revenue
  const totalRevenue = lastYearTCO2e * carbonPrice
  const avgAnnualRevenue = totalRevenue / DEFAULT_PROJET_PERIOD
  const avgAnnualRevenuePerHA = totalRevenue / DEFAULT_PROJET_PERIOD / reforestableArea

  return {
    totalProduction30yrs: totalProduction30yrs,
    averageAnual: averageAnual,
    avgPerHaPerYear: avgPerHaPerYear,
    totalRevenue,
    avgAnnualRevenue,
    avgAnnualRevenuePerHA,
    reforestablePercent,
  }
}

export const getProjectLatLong = (
  landProject: LandProject
): { lat: number; long: number } => {
  const { min, max } = landProject?.xStats?.bounds || { min: 0, max: 0 }
  const long = min[0] + (max[0] - min[0]) / 2
  const lat = min[1] + (max[1] - min[1]) / 2

  if (!lat && !long) {
    const bounds = getCountryBoundsByCode(landProject.countryCode)

    if (bounds) {
      const bbox = bboxPolygon(bounds)
      const [long, lat] = center(bbox).geometry.coordinates
      return { lat, long }
    }
  }

  return { lat: lat || null, long: long || null }
}

export function getLandMeta(landProject: LandProject) {
  const name = getLandProjectName(landProject)
  const country = getLandProjectCountry(landProject)

  const area = getLandProjectArea(landProject)
  const reforestablePercent = getLandProjectReforestablePercent(landProject)
  const reforestableArea = getLandProjectReforestableArea(landProject)

  const durationYears = landProject.durationYears
  const carbonPrice = landProject.carbonPrice
  const publicName = landProject.publicName
  const projectLead = landProject.projectLead
  const projectType = landProject.projectType
  const region = getLandProjectDatum(landProject, 'region')?.value

  const expansionArea = getLandProjectDatum(
    landProject,
    'potential-expansion-area'
  )?.value

  return {
    name,
    type: (landProject.projectType || 'arr').toLocaleUpperCase(),
    country,
    region,
    area,
    reforestablePercent,
    reforestableArea,
    expansionArea,
    durationYears,
    carbonPrice,
    publicName,
    projectLead,
    projectType,
  }
}

export function getLandProjectName(landProject: LandProject) {
  const formProjectName = getLandProjectDatum(landProject, 'project-name')?.value
  return formProjectName || landProject?.name
}

export function getImagesByTag(landProject: LandProject, tag: LandProjectImageTags) {
  return landProject?.images.filter((image) => image.tag === tag)
}

export async function saveLandProjectRootValues(
  landProject: LandProject,
  values: Partial<LandProject>
) {
  const newLandProject = {
    ...landProject,
    ...values,
  }

  logger.info('saveLandProjectRootValues', newLandProject)

  await saveProjectData(newLandProject)
  storeLandProject(newLandProject)
}

export function getLandProjectMetaStats(landProject: LandProject): {
  [key: string]: StatValues
} {
  const meta = getLandMeta(landProject)
  const countryName = getCountryNameByCode(landProject?.countryCode)
  const region = getLandProjectDatum(landProject, 'region')?.value

  return {
    country: { label: 'Country', value: countryName },
    region: { label: 'Region', value: region },
    area: {
      label: 'Total area',
      value: formatNumber(meta.area),
      unit: 'ha',
    },
    reforestableArea: {
      label: 'Reforestable Area',
      value: `${formatPercent(meta.reforestablePercent)}%`,
      unit:
        meta.reforestablePercent !== 100 && `(${formatNumber(meta.reforestableArea)} ha)`,
    },
    expansionArea: meta.expansionArea && {
      label: 'Potential Expansion area',
      value: formatNumber(meta.expansionArea),
      unit: `ha`,
    },
  }
}

export const getColorByProjectType = (projectType: ProjectType) =>
  projectType === 'redd' ? '#F9A8A8' : '#70A672'

export const landProjectHasPolygon = (
  landProject: LandProject,
  parcels: Parcel[]
): boolean => !!(landProject && parcels && parcels.length > 0)

export function parseScheduleToInterventionAreaByYear(schedule: LandProjectSchedule) {
  if (!schedule) return null
  const interventionAreaByYear: { [key: string]: number } = {}

  for (const [year, area] of Object.entries(schedule)) {
    interventionAreaByYear[year] = area.areaHa
  }

  return interventionAreaByYear
}

export function getLandProjectCarbonEstimates(landProject: LandProject) {
  switch (landProject.projectType) {
    case 'redd':
      return getREDDGreenHouseGasBaseline(landProject)
    case 'arr':
      return getARRCarbonEstimates(landProject)
  }
}

export function sumInterventionsArea(schedule: InterventionAreaByYear) {
  if (!schedule) return 0
  return sumArray(Object.values(schedule))
}

const projectTypeLabels: Record<ProjectType, string> = {
  redd: 'REDD+',
  arr: 'ARR',
  'wrc-redd': 'WRC REDD+',
  'wrc-arr': 'WRC ARR',
  alm: 'ALM',
  acogs: 'AcoGS',
  ifm: 'IFM',
}
export function getProjectTypeLabel(projectType: ProjectType) {
  if (Array.isArray(projectType))
    return projectType.map((pt) => projectTypeLabels[pt]).join(', ')
  return projectTypeLabels[projectType]
}

export const projectTypeToColorVariant: Record<ProjectType, ColorVariants> = {
  redd: 'red',
  arr: 'green',
  'wrc-redd': 'blue',
  'wrc-arr': 'blue',
  alm: 'yellow',
  ifm: 'orange',
  acogs: 'violet',
}

export const getProjectTypeOptions = () => {
  return projectTypes.map((type) => ({ label: getProjectTypeLabel(type), value: type }))
}

export function getEligibleArea(landProject: LandProject) {
  if (!landProject?.xStats?.byYear) return null

  if (landProject.projectType === 'redd') {
    const forestHa =
      landProject?.xStats?.forestNonForest?.forestHa || landProject?.xStats.areaHa

    return forestHa
  } else {
    // Assume for now the rest of projects are ARR
    const nonForestHa =
      landProject?.xStats?.forestNonForest?.nonForestHa || landProject?.xStats.areaHa
    return nonForestHa
  }
}

export function getProjectDuration(landProject: LandProject) {
  if (!landProject?.xStats?.byYear) return null
  // TODO at some point we should define this by project start and end date
  const projectDuration = landProject?.xStats.byYear.length
  return projectDuration
}

export function getProjectTotalTCO2(landProject: LandProject) {
  if (!landProject?.xStats?.byYear) return null
  // TODO at some point we should define this by project start and end date
  const projectDuration = getProjectDuration(landProject)
  const totalTCO2 = landProject?.xStats.byYear?.[projectDuration - 1].tCO2
  return totalTCO2
}

export function getProjectTotalAnnual(landProject: LandProject) {
  if (!landProject?.xStats?.byYear) return null
  const totalTCO2 = getProjectTotalTCO2(landProject)
  const projectDuration = getProjectDuration(landProject)
  return totalTCO2 / projectDuration
}

export function getProjectTotalAnnualPerHa(landProject: LandProject) {
  if (!landProject?.xStats?.byYear) return null
  const totalAnnual = getProjectTotalAnnual(landProject)
  const eligibleArea = getEligibleArea(landProject)

  return totalAnnual / eligibleArea
}

const DEFAULT_LAND_PROJECT_NPV_DISCOUNT = 0.2

export function calculateLandProjectNPV(cashflow: number[]) {
  return NPV(cashflow, DEFAULT_LAND_PROJECT_NPV_DISCOUNT)
}

export function getProjectCompletion(
  landProject: LandProject,
  countryAssessment: CountryRegulatoryAssessment
) {
  // Pre-scoping
  const isCountryAssessmentCompleted = getIsCountryAssessmentCompleted(countryAssessment)

  const basicInfoCompletion = getFormCompletionRate(
    forms.find((i) => i.id === 'basic-info'),
    landProject
  )
  const isBasicInfoCompleted = basicInfoCompletion.completed === basicInfoCompletion.total

  const initalQuestionnaireCompletion = getFormCompletionRate(
    forms.find((i) => i.id === 'initial-questionnaire'),
    landProject
  )
  const isInitalQuestionnaireCompletted =
    initalQuestionnaireCompletion.completed === initalQuestionnaireCompletion.total

  const isPolygonUploaded = !!getLandProjectArea(landProject)

  const preScopingCompletions = [
    isBasicInfoCompleted,
    isInitalQuestionnaireCompletted,
    isPolygonUploaded,
    isCountryAssessmentCompleted,
  ]

  const preScopingPercent =
    (preScopingCompletions.filter(Boolean).length * 100) / preScopingCompletions.length
  const preScopingCompleted = preScopingPercent === 100

  // Scoping Assessment
  const goNoGoForms = getScopingAssessmentForms()
  const goNoGoFormScopingCompletion = getFormsCompletion(goNoGoForms, landProject)

  const scopingCompleted = goNoGoFormScopingCompletion.percent === 100

  let nextStep
  if (!preScopingCompleted) nextStep = 'pre-scoping'
  else if (!scopingCompleted) nextStep = 'scoping'
  else nextStep = 'diligence'

  return {
    nextStep,

    // Pre-Scoping
    preScoping: preScopingCompleted,
    preScopingPercent,
    basicInfo: isBasicInfoCompleted,
    initalQuestionnaire: isInitalQuestionnaireCompletted,
    polygonUploaded: isPolygonUploaded,
    countryAssessment: isCountryAssessmentCompleted,

    //Scoping
    scoping: scopingCompleted,
    scopingPercent: goNoGoFormScopingCompletion.percent,
  }
}
