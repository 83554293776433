import Select from '~/components/atomic/Select'
import { Column } from '~/styles'
import {
  defaultWorldDatasets,
  reddModelsDataset,
  REDDModelsKeys,
} from '~/models/landProjectReddStats'
import {
  getCountryNameByCode,
  amazoniaCountries,
  jrcTropicalCountires,
} from '~/services/countries'
import { reddCarbonStore } from '~/routes/project/carbon/redd/reddStore'
import Input from '~/components/atomic/Input'
import { useSnapshot } from 'valtio'

type Props = {
  countryCode: string
}

export function REDDInputs(props: Props) {
  const { countryCode } = props

  const snap = useSnapshot(reddCarbonStore)

  const applicableREDDModelsDataset =
    getApplicableREDDModelsDatasetByCountryCode(countryCode)

  return (
    <form>
      <Column className='mb-32 space-y-16'>
        {Object.entries(applicableREDDModelsDataset).map(([modelKey, model]) => {
          return (
            <Select
              name={modelKey}
              key={modelKey}
              label={`${model.label} model`}
              value={snap.chosenDatasets[modelKey]}
              options={model.datasets}
              onChange={(e) => {
                reddCarbonStore.chosenDatasets[modelKey] = e.target.value
              }}
            />
          )
        })}

        <Input
          name={'forestDefinitionTreeCoverPercent'}
          label={`Forest Definition in Tree Cover Percent`}
          unitRight={'%'}
          value={snap.assumptions.forestDefinitionTreeCoverPercent}
          onChange={(e) =>
            (reddCarbonStore.assumptions.forestDefinitionTreeCoverPercent = Number(
              e.target.value
            ))
          }
        />
      </Column>
    </form>
  )
}

function getReddModelDataset(REDDModel, countryCode) {
  const country = getCountryNameByCode(countryCode)
  const applicableDatasets = reddModelsDataset[REDDModel].datasets.filter((dataset) => {
    const datasetStringSplit = dataset.value.split('_')
    let filterDataset: boolean
    if (datasetStringSplit.at(0).includes('world')) {
      filterDataset = true
    } else if (datasetStringSplit.at(-1) === 'amazonia') {
      filterDataset = amazoniaCountries.includes(country)
    } else if (datasetStringSplit.at(-1) === 'tropical') {
      filterDataset = jrcTropicalCountires.includes(country)
    } else {
      filterDataset = datasetStringSplit.at(-1) === country.toLowerCase()
    }
    return filterDataset
  })
  return applicableDatasets
}

function getApplicableREDDModelsDatasetByCountryCode(countryCode: string) {
  const applicableREDDModelsDataset = {
    [REDDModelsKeys.forestNonForest]: {
      label: 'Land Cover',
      datasets: getReddModelDataset(REDDModelsKeys.forestNonForest, countryCode),
    },
    [REDDModelsKeys.biomass]: {
      label: 'Biomass',
      datasets: getReddModelDataset(REDDModelsKeys.biomass, countryCode),
    },
    [REDDModelsKeys.deforestation]: {
      label: 'Deforestation',
      datasets: getReddModelDataset(REDDModelsKeys.deforestation, countryCode),
    },
  }

  return applicableREDDModelsDataset
}
