import { Outlet } from 'react-router-dom'
import { Button } from '~/components/atomic/Button'
import { LayoutWithSideMenu } from '~/components/core/LayoutWithSideMenu'
import { SideMenu } from '~/components/core/SideMenu'
import { ProjectTab } from '~/components/layouts/ProjectTab'
import {
  saveFinancialEstimates,
  useInitFinancialStore,
} from '~/routes/project/financials/financialsStore'
import { PATH } from '~/utils/constants'
import { useActiveLandProject, useSnapshot } from '~/state'
import { CenteredLoader } from '~/components/atomic/CenteredLoader'
import { financialsStore } from './financialsStore'
import { useState } from 'react'
import { ProjectType } from '~/models/landProject'

const getMenu = (projectType: ProjectType) => [
  { type: 'title', label: 'Estimates' },
  { to: PATH['explorer'], label: 'Explorer' },
  { to: PATH['investor-cashflows'], label: 'Investor Cashflows' },
  { to: PATH['project-cashflows'], label: 'Project Cashflows' },
  { to: PATH['carbon-price-scenarios'], label: 'Carbon Price Scenarios' },

  { type: 'title', label: 'Fixed Model (WIP)' },

  { to: PATH['costs'], label: 'Fixed Costs' },

  { to: PATH['stakeholder-returns'], label: 'Stakeholder Returns' },

  { type: 'divider' },

  { to: PATH['optimal-financial-model'], label: 'Optimal Financial Model (WIP)' },
]

export function FinancialsTabLayout() {
  const projectType = useActiveLandProject().landProject.projectType
  const storeState = useSnapshot(financialsStore).state
  useInitFinancialStore()

  const sidebar = (
    <LayoutWithSideMenu.Container className='!w-[240px]'>
      <LayoutWithSideMenu.Title>Financials</LayoutWithSideMenu.Title>
      <SideMenu options={getMenu(projectType)} />
      <SaveEstimatesButton />
    </LayoutWithSideMenu.Container>
  )

  const content = storeState == 'loading' ? <CenteredLoader /> : <Outlet />

  return (
    <ProjectTab title='Financials'>
      <LayoutWithSideMenu sidebar={sidebar}>{content}</LayoutWithSideMenu>
    </ProjectTab>
  )
}

function SaveEstimatesButton() {
  const [submitting, setsubmitting] = useState<boolean>(false)
  async function onSave() {
    setsubmitting(true)
    await saveFinancialEstimates()
    setsubmitting(false)
  }
  return (
    <Button variant='primary' submitting={submitting} onClick={onSave} className='mt-20'>
      {submitting ? 'Saving...' : 'Save estimates'}
    </Button>
  )
}
