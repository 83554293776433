import { getLandProjectPolygonArea } from '~/models/landProject'
import { useActiveLandProject } from '~/state'
import { Row } from '~/styles'
import { formatNumber } from '~/utils/units'
import { HorizontalStat } from '~/components/core/HorizontalStat'
import { pluralize } from '~/utils'

export function LandFooter() {
  const { parcels, landProject } = useActiveLandProject()
  const area = getLandProjectPolygonArea(landProject)

  if (!parcels) return null

  return (
    <div className='w-full border-l bg-ink-100 px-20 py-10'>
      <Row className='space-x-48 text-14'>
        <HorizontalStat
          label={pluralize('Parcel', parcels.length) + ':'}
          value={parcels.length}
        />
        <HorizontalStat label='Polygon area:' value={formatNumber(area)} unit={'ha'} />
      </Row>
    </div>
  )
}
